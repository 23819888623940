import React from 'react';

import { Box } from '@material-ui/core';

import { Icon, Typography } from '@crpt-ui/core';

import { Modal } from '../../../../common_components/Modal/Modal';
import { Link } from '../../../../common_components/Link/Link';
import { createStatic } from '../../../../services/utils';

import { DOCUMENT_TEMPLATES_LIST } from './DocumentTemplatesDialog.constants';

export const DocumentTemplatesDialogView = ({ open, onClose }) => (
  <Modal open={open} title="Шаблоны документов" onClose={onClose}>
    {DOCUMENT_TEMPLATES_LIST.map((group) => (
      <Box key={group.label} mb={4}>
        <Typography variant="h5">{group.label}</Typography>

        {group.list.map((item) => (
          <Box key={item.label} mb={1}>
            <Link
              href={createStatic(item.label)}
              icon={<Icon name="Attachment" />}
              router={false}
              target="_blank"
            >
              {item.label}
            </Link>
          </Box>
        ))}
      </Box>
    ))}
  </Modal>
);
