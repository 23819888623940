import { styled } from '@mui/material/styles';

export const ErrorNotification = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    color: theme.palette.error.main,
  };
});
