import React, { FC, useCallback, useEffect, useMemo, memo } from 'react';
import moment from 'moment';
import { DatepickerInput as DatepickerInputBase } from '@crpt-ui/lab';
import FieldHoc from '../FieldHoc';

import type { FieldRenderProps } from 'react-final-form';
import type { DatepickerInputProps } from '@crpt-ui/lab/lib/Datepicker/DatepickerInput';

export const isNoEmptyString = (value: unknown): boolean =>
  typeof value === 'string' && value.length > 0;

interface DatepickerInputAdapterProps
  extends FieldRenderProps<any, HTMLInputElement>,
    DatepickerInputProps {
  date?: Date;
}

const DatepickerInputAdapter: FC<DatepickerInputAdapterProps> = ({
  meta,
  input,
  date,
  ...fieldRest
}) => {
  const { value } = input;

  useEffect(() => {
    if (date) {
      input.onChange(date);
    }

    return () => {
      input.onChange('');
    };
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // нужен для корректной работы при typeof value === 'string'
  useEffect(() => {
    if (isNoEmptyString(value)) {
      const momentDate = moment(value);

      if (momentDate.isValid()) {
        input.onChange(momentDate.toDate());
      } else {
        input.onChange('');
      }
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const isError = useMemo(() => {
    if (meta.initial) {
      return meta.invalid;
    }

    return !meta.touched && !meta.modified ? false : meta.invalid;
  }, [meta.initial, meta.invalid, meta.modified, meta.touched]);

  const preparedValue = useMemo(() => {
    if (isNoEmptyString(value)) {
      const momentDate = moment(value);
      return momentDate.isValid() ? momentDate.toDate() : null;
    }

    return value || null;
  }, [value]);

  const onChangeHandle = useCallback((date) => {
    if (date === null) {
      input.onChange('');
      return;
    }

    input.onChange(moment(date).toDate());
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DatepickerInputBase
      {...fieldRest}
      error={isError}
      helperText={isError ? meta.error : undefined}
      //eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      onBlur={input.onBlur}
      onChange={onChangeHandle}
      //eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      onFocus={input.onFocus}
      value={preparedValue}
    />
  );
};

DatepickerInputAdapter.defaultProps = {
  placeholder: 'ДД.ММ.ГГГГ',
};

export default memo(FieldHoc(DatepickerInputAdapter));
