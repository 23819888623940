/* eslint-disable @typescript-eslint/ban-ts-comment */
import { PopoverProps, TextFieldProps } from '@material-ui/core';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps as KeyboardDatePickerBaseProps,
} from '@material-ui/pickers';
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
import { Icon, Input } from '@crpt-ui/core';
import React, { FC } from 'react';

import DatepickerToolbar from './DatepickerToolbar';
import { renderDay } from './utils';

const popoverProps: Omit<PopoverProps, 'open'> = {
  anchorOrigin: {
    horizontal: 'left',
    vertical: 'bottom',
  },
  marginThreshold: 0,
  transformOrigin: {
    horizontal: 'left',
    vertical: -4,
  },
  transitionDuration: 0,
};

export type DatepickerInputProps = {
  loading?: boolean;
  toolbarLabelFormat?: string;
} & KeyboardDatePickerBaseProps;

const TextFieldComponent: FC<TextFieldProps> = ({
  error,
  helperText,
  ...rest
}) => {
  // @ts-ignore
  return <Input {...rest} error={error && helperText} />;
};

const DatepickerInput: FC<DatepickerInputProps> = ({
  maxDate,
  minDate,
  onChange,
  toolbarLabelFormat,
  disabled,
  clearable,
  ...rest
}) => (
  <KeyboardDatePicker
    TextFieldComponent={TextFieldComponent}
    ToolbarComponent={(props) => (
      <DatepickerToolbar
        {...props}
        maxDate={maxDate}
        minDate={minDate}
        toolbarLabelFormat={toolbarLabelFormat}
      />
    )}
    maxDate={maxDate}
    minDate={minDate}
    onChange={onChange}
    renderDay={renderDay}
    disabled={disabled}
    clearable={disabled ? false : clearable}
    {...rest}
  />
);

DatepickerInput.defaultProps = {
  ...datePickerDefaultProps,
  PopoverProps: popoverProps,
  clearable: true,
  format: 'DD.MM.YYYY',
  invalidDateMessage: 'Неверный формат даты',
  keyboardIcon: <Icon name="Calendar" />,
  maxDateMessage: 'Дата не должна быть позже максимальной даты',
  minDateMessage: 'Дата не должна быть раньше минимальной даты',
  variant: 'inline',
};

export default DatepickerInput;
