import React, { useContext, useRef } from 'react';

import PropTypes from 'prop-types';
import { flatten as flat, unflatten } from 'flat';
import {
  get,
  isArray,
  isEmpty,
  isEqual,
  isNull,
  isObject,
  isString,
  isUndefined,
  transform,
  unset,
} from 'lodash';
import { FormSpy } from 'react-final-form';

import { Form } from './form/form';
import { FormContext } from './Form.constants';
import { check, each, exist } from './Form.validations';

export const withForm =
  ({ disabled = false, validate, decorators } = {}) =>
  (Component) => {
    let validateTimeout;
    let mutators;
    let oldValues = {};
    let oldErrors = {};

    const validateFn = (values) =>
      new Promise((resolve) => {
        const fn = () => {
          const errors = {};

          const validators = {
            each: each(values),
            exist: exist(values),
            check: check(values, oldValues, errors, oldErrors, mutators),
          };

          oldErrors = validate(values, errors, mutators, validators);
          oldValues = values;
          resolve(oldErrors);
        };

        if (validateTimeout) {
          validateTimeout();
        }

        const timeoutId = setTimeout(() => {
          fn();
          validateTimeout = undefined;
        }, 200);

        validateTimeout = () => {
          clearTimeout(timeoutId);
          resolve(oldErrors);
        };
      });

    const Wrapper = (props) => {
      const { data } = props;

      return (
        <Form
          data={data}
          decorators={decorators}
          disabled={disabled}
          validate={validate ? validateFn : undefined}
        >
          <FormContext.Consumer>
            {({ mutators: mt }) => {
              mutators = mt;
            }}
          </FormContext.Consumer>
          <Component {...props} />
        </Form>
      );
    };

    Wrapper.displayName = 'WithFormWrapper';

    Wrapper.propTypes = {
      data: PropTypes.shape({}),
    };

    Wrapper.defaultProps = {
      data: undefined,
    };

    return Wrapper;
  };

export const flatten = (source, flattened = {}, key = '') => {
  const nextKey = (k) => `${key}${key ? '.' : ''}${k}`;

  if (typeof source === 'object') {
    if (!isEmpty(source)) {
      Object.keys(source).forEach((k) =>
        flatten(source[k], flattened, nextKey(k))
      );
    } else {
      // eslint-disable-next-line no-param-reassign
      flattened[key] = source;
    }
  } else {
    // eslint-disable-next-line no-param-reassign
    flattened[key] = source;
  }

  return flattened;
};

export const cleanErrors = (obj) => {
  if (!isObject(obj)) {
    return obj;
  }

  const cleaned = flat(obj);

  Object.keys(cleaned).forEach((key) => {
    const val = cleaned[key];
    if (isUndefined(val) || isNull(val) || isEmpty(val)) {
      unset(cleaned, key);
    }
  });

  return unflatten(cleaned);
};

export const withFormValues = (configSpy) => (Component) => (props) =>
  (
    <FormSpy subscription={{ values: true, errors: true }} {...configSpy}>
      {(spyProps) => <Component {...props} {...spyProps} />}
    </FormSpy>
  );

export const withFormContext = (params) => (Component) => (props) => {
  const formContext = useContext(FormContext);
  const { values, errors, mutators, disabled, form } = formContext;

  const errorsRef = useRef();
  if (!isEqual(errorsRef.current, errors)) {
    errorsRef.current = errors;
  }
  const val = params.name ? get(values, params.name) : undefined;

  return (
    <Component
      form={params.form && form}
      formDisabled={params.disabled && disabled}
      formErrors={params.errors && errorsRef.current}
      formMutators={params.mutators && mutators}
      formValues={params.values && values}
      isFormErrors={
        params.errors &&
        Boolean(
          !isUndefined(errorsRef.current) &&
            Object.keys(errorsRef.current).length
        )
      }
      value={val}
      {...props}
    />
  );
};

export const deepOmit = (object, keysToOmit) => {
  const keysToOmitPrepared = isArray(keysToOmit) ? keysToOmit : [keysToOmit];

  const omitFromObject = (obj) =>
    transform(obj, (result, value, key) => {
      let omited = false;

      if (isString(key)) {
        keysToOmitPrepared.forEach((k) => {
          if (key.match(k)) {
            omited = true;
          }
        });
      }

      if (omited) {
        return;
      }

      // Исключение надо для IE11, иначе регистрация не проходит
      if (key === 'cert' || value instanceof Blob) {
        // eslint-disable-next-line no-param-reassign
        result[key] = value;
      } else {
        // eslint-disable-next-line no-param-reassign
        result[key] = isObject(value) ? omitFromObject(value) : value;
      }
    });

  return omitFromObject(object);
};

export const deepRangePickerPrepare = (object, rpPrename) => {
  const recursivePrepare = (obj) =>
    transform(obj, (result, value, key) => {
      if (isString(key)) {
        if (key.match(rpPrename)) {
          const { from, to } = value;
          const preparedKey = key.replace('__form_RangePicker_', '');

          // eslint-disable-next-line no-param-reassign
          result[`${preparedKey}From`] = from;
          // eslint-disable-next-line no-param-reassign
          result[`${preparedKey}To`] = to;
          return;
        }
      }

      // Исключение надо для IE11, иначе регистрация не проходит
      if (key === 'cert' || value instanceof Blob) {
        // eslint-disable-next-line no-param-reassign
        result[key] = value;
      } else {
        // eslint-disable-next-line no-param-reassign
        result[key] = isObject(value) ? recursivePrepare(value) : value;
      }
    });

  return recursivePrepare(object);
};

export const composeValidations = (validations) => (value) => {
  return validations.some((validation) => validation(value) === 'Error');
};
