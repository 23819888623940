import { call, put, takeLatest } from 'redux-saga/effects';

import {
  displayDataLimit,
  requestLimit,
} from '../../../../../constants/requests';
import { Api } from '../../../../../common_components/Api/Api';
import {
  filters2params,
  filters2url,
  updateUrlParams,
} from '../../../../../utils/url-utils';

import * as actions from './Receipts.actions';

function* fetchSaga(action) {
  const { payload = {} } = action;
  const {
    filters = [],
    page = 1,
    pathname = '',
    paginationPagesParams,
    updateHistory = false,
  } = payload;
  const filterParams = filters2params(filters);
  const { dateFrom, dateTo, senderInn } = filterParams;

  // Для запроса используется requestLimit = 11 (на +1 элемент больше, чем отображется на странице),
  // чтобы узнать, находимся мы на последней странице или нет
  let paginationParams = {
    limit: requestLimit,
  };

  const requestPayload = {
    url: '/api/v3/facade/receipt/search',
    method: 'post',
    data: {
      receipt: {
        senderInn,
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
      pagination: {
        ...paginationParams,
        ...paginationPagesParams,
      },
    },
  };

  // Error field does not used here cause there are no designs for catching error in datatable. Instead of it we have global error catching - toasts.
  const [success] = yield call(Api.request, requestPayload);

  if (success) {
    if (page) {
      yield put(actions.updatePage(page));
    }

    const requestResults = success.data || [];
    const results = requestResults.slice(0, displayDataLimit);
    const last = requestResults.length < requestLimit;
    const resultData = {
      results,
      last,
    };

    if (updateHistory) {
      const filtersUrl = filters2url(filters);
      const urlString =
        pathname + '?' + (filtersUrl ? filtersUrl + '&' : '') + `page=${page}`;
      yield call(updateUrlParams, {
        urlString,
      });
    }

    if (page === 1) {
      yield put(actions.firstLoaded(resultData));
    } else {
      yield put(actions.loaded(resultData));
    }
  }
}

export const saga = function* watch() {
  yield takeLatest(actions.fetch, fetchSaga);
};
