import { Icon } from '@crpt-ui/core';
import React, { ReactNode } from 'react';

export interface ProductGroup {
  code: string;
  env?: string;
  icon?: ReactNode;
  id: number;
  name: string;
}

export class ProductGroup {
  constructor(options: ProductGroup) {
    Object.assign(this, options);
  }
}

export enum ProductGroupIdEnum {
  ANTISEPTIC = 19,
  BEER = 15,
  BICYCLE = 9,
  BIO = 17,
  ELECTRONICS = 6,
  FURS = 14,
  LP = 1,
  MEAT = 25,
  MILK = 8,
  NABEER = 22,
  NCP = 16,
  OTP = 12,
  PERFUMERY = 4,
  PETFOOD = 20,
  PHARMA = 7,
  SEAFOOD = 21,
  SHOES = 2,
  SOFTDRINKS = 23,
  TIRES = 5,
  TOBACCO = 3,
  VEGS = 24,
  WATER = 13,
  WHEELCHAIRS = 10,
}

export enum ProductGroupCodeEnum {
  ANTISEPTIC = 'antiseptic',
  BEER = 'beer',
  BICYCLE = 'bicycle',
  BIO = 'bio',
  ELECTRONICS = 'electronics',
  FURS = 'furs',
  LP = 'lp',
  MEAT = 'meat',
  MILK = 'milk',
  NABEER = 'nabeer',
  NCP = 'ncp',
  OTP = 'otp',
  PERFUMERY = 'perfumery',
  PETFOOD = 'petfood',
  PHARMA = 'pharma',
  SEAFOOD = 'seafood',
  SHOES = 'shoes',
  SOFTDRINKS = 'softdrinks',
  TIRES = 'tires',
  TOBACCO = 'tobacco',
  VEGS = 'vegs',
  WATER = 'water',
  WHEELCHAIRS = 'wheelchairs',
}

export interface ProductGroupDTO {
  id: ProductGroupIdEnum;
  code: ProductGroupCodeEnum;
}

export enum PRODUCT_GROUP_ENUM {
  ANTISEPTIC = 'ANTISEPTIC',
  BEER = 'BEER',
  BICYCLE = 'BICYCLE',
  BIO = 'BIO',
  ELECTRONICS = 'ELECTRONICS',
  FURS = 'FURS',
  LP = 'LP',
  MEAT = 'MEAT',
  MILK = 'MILK',
  NABEER = 'NABEER',
  NCP = 'NCP',
  OTP = 'OTP',
  PERFUMERY = 'PERFUMERY',
  PETFOOD = 'PETFOOD',
  PHARMA = 'PHARMA',
  SEAFOOD = 'SEAFOOD',
  SHOES = 'SHOES',
  SOFTDRINKS = 'SOFTDRINKS',
  TIRES = 'TIRES',
  TOBACCO = 'TOBACCO',
  VEGS = 'VEGS',
  WATER = 'WATER',
  WHEELCHAIRS = 'WHEELCHAIRS',
}

export const PRODUCT_GROUP = {
  [PRODUCT_GROUP_ENUM.BEER]: new ProductGroup({
    id: ProductGroupIdEnum.BEER,
    code: ProductGroupCodeEnum.BEER,
    name: 'Пиво, напитки, изготавливаемые на основе пива, и слабоалкогольные напитки',
    env: 'REACT_APP_PG_BEER_URL',
    icon: <Icon name="beer" />,
  }),
  [PRODUCT_GROUP_ENUM.BICYCLE]: new ProductGroup({
    id: ProductGroupIdEnum.BICYCLE,
    code: ProductGroupCodeEnum.BICYCLE,
    name: 'Велосипеды и велосипедные рамы',
    env: 'REACT_APP_PG_BICYCLE_URL',
    icon: <Icon name="bicycles" />,
  }),
  [PRODUCT_GROUP_ENUM.ELECTRONICS]: new ProductGroup({
    id: ProductGroupIdEnum.ELECTRONICS,
    code: ProductGroupCodeEnum.ELECTRONICS,
    name: 'Фотокамеры (кроме кинокамер), фотовспышки и лампы-вспышки',
    env: 'REACT_APP_PG_ELECTRONICS_URL',
    icon: <Icon name="electronics" />,
  }),
  [PRODUCT_GROUP_ENUM.FURS]: new ProductGroup({
    id: ProductGroupIdEnum.FURS,
    code: ProductGroupCodeEnum.FURS,
    name: 'Товары из натурального меха',
    env: 'REACT_APP_PG_FURS_URL',
    icon: <Icon name="furs" />,
  }),
  [PRODUCT_GROUP_ENUM.LP]: new ProductGroup({
    id: ProductGroupIdEnum.LP,
    code: ProductGroupCodeEnum.LP,
    name: 'Предметы одежды, белье постельное, столовое, туалетное и кухонное',
    env: 'REACT_APP_PG_CLOTHES_URL',
    icon: <Icon name="clothes" />,
  }),
  [PRODUCT_GROUP_ENUM.MILK]: new ProductGroup({
    id: ProductGroupIdEnum.MILK,
    code: ProductGroupCodeEnum.MILK,
    name: 'Молочная продукция',
    env: 'REACT_APP_PG_MILK_URL',
    icon: <Icon name="milk" />,
  }),
  [PRODUCT_GROUP_ENUM.NCP]: new ProductGroup({
    id: ProductGroupIdEnum.NCP,
    code: ProductGroupCodeEnum.NCP,
    name: 'Никотиносодержащая продукция',
    env: 'REACT_APP_PG_NCP_URL',
    icon: <Icon name="ncp" />,
  }),
  [PRODUCT_GROUP_ENUM.OTP]: new ProductGroup({
    id: ProductGroupIdEnum.OTP,
    code: ProductGroupCodeEnum.OTP,
    name: 'Альтернативная табачная продукция',
    env: 'REACT_APP_PG_OTP_URL',
    icon: <Icon name="tobaccoAlternative" />,
  }),
  [PRODUCT_GROUP_ENUM.PERFUMERY]: new ProductGroup({
    id: ProductGroupIdEnum.PERFUMERY,
    code: ProductGroupCodeEnum.PERFUMERY,
    name: 'Духи и туалетная вода',
    env: 'REACT_APP_PG_PERFUMERY_URL',
    icon: <Icon name="perfumery" />,
  }),
  [PRODUCT_GROUP_ENUM.PHARMA]: new ProductGroup({
    id: ProductGroupIdEnum.PHARMA,
    code: ProductGroupCodeEnum.PHARMA,
    name: 'Лекарственные препараты для медицинского применения',
    icon: <Icon name="medicines" />,
  }),
  [PRODUCT_GROUP_ENUM.SHOES]: new ProductGroup({
    id: ProductGroupIdEnum.SHOES,
    code: ProductGroupCodeEnum.SHOES,
    name: 'Обувные товары',
    env: 'REACT_APP_PG_SHOES_URL',
    icon: <Icon name="shoes" />,
  }),
  [PRODUCT_GROUP_ENUM.TIRES]: new ProductGroup({
    id: ProductGroupIdEnum.TIRES,
    code: ProductGroupCodeEnum.TIRES,
    name: 'Шины и покрышки пневматические резиновые новые',
    env: 'REACT_APP_PG_TIRES_URL',
    icon: <Icon name="tires" />,
  }),
  [PRODUCT_GROUP_ENUM.TOBACCO]: new ProductGroup({
    id: ProductGroupIdEnum.TOBACCO,
    code: ProductGroupCodeEnum.TOBACCO,
    name: 'Табачная продукция',
    env: 'REACT_APP_PG_TOBACCO_URL',
    icon: <Icon name="tobacco" />,
  }),
  [PRODUCT_GROUP_ENUM.WATER]: new ProductGroup({
    id: ProductGroupIdEnum.WATER,
    code: ProductGroupCodeEnum.WATER,
    name: 'Упакованная вода',
    env: 'REACT_APP_PG_WATER_URL',
    icon: <Icon name="water" />,
  }),
  [PRODUCT_GROUP_ENUM.WHEELCHAIRS]: new ProductGroup({
    id: ProductGroupIdEnum.WHEELCHAIRS,
    code: ProductGroupCodeEnum.WHEELCHAIRS,
    name: 'Медицинские изделия',
    env: 'REACT_APP_PG_WHEELCHAIRS_URL',
    icon: <Icon name="wheelchairs" />,
  }),
  [PRODUCT_GROUP_ENUM.BIO]: new ProductGroup({
    id: ProductGroupIdEnum.BIO,
    code: ProductGroupCodeEnum.BIO,
    name: 'Биологически активные добавки к пище',
    env: 'REACT_APP_PG_BIO_URL',
    icon: <Icon name="bio" />,
  }),
  [PRODUCT_GROUP_ENUM.ANTISEPTIC]: new ProductGroup({
    id: ProductGroupIdEnum.ANTISEPTIC,
    code: ProductGroupCodeEnum.ANTISEPTIC,
    name: 'Антисептики и дезинфицирующие средства',
    env: 'REACT_APP_PG_ANTISEPTIC_URL',
    icon: <Icon name="antiseptic" />,
  }),
  [PRODUCT_GROUP_ENUM.PETFOOD]: new ProductGroup({
    id: ProductGroupIdEnum.PETFOOD,
    code: ProductGroupCodeEnum.PETFOOD,
    name: 'Корма для домашних животных (кроме сельскохозяйственных)',
    env: 'REACT_APP_PG_PETFOOD_URL',
    icon: <Icon name="petfood" />,
  }),
  [PRODUCT_GROUP_ENUM.SEAFOOD]: new ProductGroup({
    id: ProductGroupIdEnum.SEAFOOD,
    code: ProductGroupCodeEnum.SEAFOOD,
    name: 'Морепродукты',
    env: 'REACT_APP_PG_SEAFOOD_URL',
    icon: <Icon name="seafood" />,
  }),
  [PRODUCT_GROUP_ENUM.NABEER]: new ProductGroup({
    id: ProductGroupIdEnum.NABEER,
    code: ProductGroupCodeEnum.NABEER,
    name: 'Безалкогольное пиво',
    env: 'REACT_APP_PG_NABEER_URL',
    icon: <Icon name="nabeer" />,
  }),
  [PRODUCT_GROUP_ENUM.SOFTDRINKS]: new ProductGroup({
    id: ProductGroupIdEnum.SOFTDRINKS,
    code: ProductGroupCodeEnum.SOFTDRINKS,
    name: 'Соковая продукция и безалкогольные напитки',
    env: 'REACT_APP_PG_SOFTDRINKS_URL',
    icon: <Icon name="softdrinks" />,
  }),
  [PRODUCT_GROUP_ENUM.VEGS]: new ProductGroup({
    id: ProductGroupIdEnum.VEGS,
    code: ProductGroupCodeEnum.VEGS,
    name: 'Плодоовощная продукция',
    env: 'REACT_APP_PG_VEGS_URL',
    icon: <Icon name="vegs" />,
  }),
  [PRODUCT_GROUP_ENUM.MEAT]: new ProductGroup({
    id: ProductGroupIdEnum.MEAT,
    code: ProductGroupCodeEnum.MEAT,
    name: 'Мясо',
    env: 'REACT_APP_PG_MEAT_URL',
    icon: <Icon name="meat" />,
  }),
};

export const productGroupStatus = {
  VIP_ENABLING: 14,
  VIP_SIGNER: 13,
  VIP_PROFILED: 12,
  VIP_REGISTERED: 11,
  VIP_REVOKED: 10,
  CHANGING_TYPE: 6,
  ACTIVE: 5,
  ENABLING: 4,
  SIGNER: 3,
  PROFILED: 2,
  REGISTERED: 1,
  REVOKED: 0,
  PRECESS_TERMINATED: 15,
  BLOCKED: 16,
  TERMINATED: 17,
};

const {
  ENABLING,
  ACTIVE,
  CHANGING_TYPE,
  VIP_ENABLING,
  VIP_SIGNER,
  VIP_PROFILED,
  VIP_REGISTERED,
  VIP_REVOKED,
  PRECESS_TERMINATED,
  BLOCKED,
  TERMINATED,
} = productGroupStatus;

export const allowedCategoryStatuses = [
  ENABLING,
  ACTIVE,
  CHANGING_TYPE,
  VIP_ENABLING,
  VIP_SIGNER,
  VIP_PROFILED,
  VIP_REGISTERED,
  VIP_REVOKED,
  PRECESS_TERMINATED,
  BLOCKED,
  TERMINATED,
];
