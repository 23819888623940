import { call, put, takeEvery } from 'redux-saga/effects';

import { toast } from '@crpt/shared/toast';

import { HttpMethodEnum } from '../../../constants';
import { Api } from '../../../common_components/Api/Api';
import { history } from '../../../store';
import { prepareParticipant } from '../utils';

import { create, setLoading } from './CreateParticipant.actions';

function* createParticipantSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const data = prepareParticipant(payload);

    const [success, error] = yield call(Api.request, {
      url: '/api/v3/org/registration/participant',
      method: HttpMethodEnum.POST,
      data,
    });

    if (success) {
      yield put(setLoading(false));
      toast.success('УОТ успешно зарегистрирован!');
      history.push('/participants/list');
    }
    if (error) {
      yield put(setLoading(false));
    }
  } catch (e) {
    yield put(setLoading(false));
    toast.success('Ошибка при регистрации УОТ!');
    history.push('/participants/list');
    console.error(e);
  }
}

export const saga = function* watch() {
  yield takeEvery(create, createParticipantSaga);
};
