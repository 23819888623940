import React, { useCallback, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Button, Modal, Tooltip } from '@crpt-ui/core';

import { getCurrentCertificate } from '../../../common_components/Cades/Cades.selectors';

import * as actions from './ducks/SignDocuments.actions';
import * as selectors from './ducks/SignDocuments.selectors';
import { SignDocumentsInfoConnected } from './Info/SignDocumentsInfo';
import { useClasses } from './SignDocuments.styles';
import { getSignDocumentsInfo } from './ducks/SignDocuments.selectors';

const SignDocumentsComp = ({
  open,
  signing,
  onMount,
  onSign,
  onDownloadAll,
  onClose,
  signDocumentsInfo,
  certificateData,
}) => {
  const classes = useClasses();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const handleSign = useCallback(() => {
    onSign();
  }, [onSign]);

  const handleDownloadAll = useCallback(() => {
    onDownloadAll(signDocumentsInfo.id);
  }, [onDownloadAll, signDocumentsInfo.id]);

  const handleCloseModal = useCallback(() => {
    setChecked(false);
    onClose();
  }, [onClose]);

  const onChangeCheckbox = useCallback(() => {
    setChecked((check) => !check);
  }, [certificateData]);

  return (
    <Modal open={open} onClose={handleCloseModal}>
      <Grid container direction="column">
        <Grid item className={classes.documentInfo}>
          <SignDocumentsInfoConnected
            checked={checked}
            onChangeCheckbox={onChangeCheckbox}
            onDownloadAll={handleDownloadAll}
          />
        </Grid>

        <Box mt={4}>
          <Grid container item alignItems="center" spacing={2}>
            <Grid item>
              <Tooltip
                disableFocusListener={checked}
                disableHoverListener={checked}
                title="Для подписания и отправки вам необходимо согласиться с условиями договоров и заявлений"
              >
                <div>
                  <Button
                    className="whiteColor"
                    disabled={!checked || signing}
                    loading={signing}
                    onClick={handleSign}
                  >
                    Подписать и отправить
                  </Button>
                </div>
              </Tooltip>
            </Grid>

            <Grid item>
              <Button variant="outlined" onClick={handleCloseModal}>
                Закрыть
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

SignDocumentsComp.propTypes = {
  signDocumentsInfo: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  signing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
  onSign: PropTypes.func.isRequired,
  onDownloadAll: PropTypes.func.isRequired,
  setCertificate: PropTypes.func.isRequired,
  certificateData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  open: selectors.open(state),
  signing: selectors.signing(state),
  signDocumentsInfo: getSignDocumentsInfo(state),
  certificateData: getCurrentCertificate(state),
});

const mapDispatchToProps = {
  onMount: actions.mount,
  onClose: actions.close,
  onSign: actions.sign,
  onDownloadAll: actions.downloadAllDocs,
};

export const SignDocuments = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignDocumentsComp);
