import React from 'react';
import { Button, Dialog, Typography } from '@crpt-ui/core';
import { Grid } from '@crpt/material';
import { Form } from 'react-final-form';
import { ParticipantAutocompleteFormField } from './ParticipantAutocompleteFormField';
import { useTranslation } from 'react-i18next';

export interface ParticipantSelectionDialogProps {
  isOpen: boolean;
  onSelect: (inn: string) => void;
}

export const ParticipantSelectionDialog: React.VFC<ParticipantSelectionDialogProps> =
  (props) => {
    const { t } = useTranslation();
    return (
      <Dialog open={props.isOpen}>
        <Form<{ inn: string }> onSubmit={({ inn }) => props.onSelect(inn)}>
          {({ handleSubmit, submitting, pristine }) => (
            <Grid container direction="column" spacing={5}>
              <Grid item>
                <Typography variant="h4">
                  {t(
                    'participantSelectionDialog.title',
                    'Для продолжения работы введите или выберите из списка наименование'
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <ParticipantAutocompleteFormField
                  name="inn"
                  placeholder={t(
                    'participantSelectionDialog.participantField',
                    'Наименование участника'
                  )}
                  required
                />
              </Grid>
              <Grid item>
                <Button
                  disabled={submitting || pristine}
                  onClick={() => handleSubmit()}
                >
                  {t('participantSelectionDialog.applyButton', 'Применить')}
                </Button>
              </Grid>
            </Grid>
          )}
        </Form>
      </Dialog>
    );
  };
