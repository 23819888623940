import React from 'react';

import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Input } from '../../../../common_components/Form/Input';

type ParticipantForm = {};

export const ParticipantForm: React.FC<ParticipantForm> = () => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">{t('Участие в системе')}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Input disabled fullWidth label={t('ID участника')} name="id" />
      </Grid>
      <Grid item xs={6}>
        <Input disabled fullWidth label={t('omsId')} name="omsId" />
      </Grid>
      <Grid item xs={6}>
        <Input
          disabled
          fullWidth
          format={(value) => t(value)}
          label={t('Статус участника')}
          name="statusName"
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <Input
          disabled
          fullWidth
          label={t('Дата регистрации')}
          name="registrationDate"
        />
      </Grid>
    </Grid>
  );
};
