import { createSelector } from 'reselect';

export const getLoginFormKepState = (state) => state.LoginFormKep;

export const getErrors = createSelector(
  [getLoginFormKepState],
  (state) => state.errors
);

export const getLoading = createSelector(
  [getLoginFormKepState],
  (state) => state.isLoading
);
