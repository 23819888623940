import {
  get,
  has,
  isArray,
  isNull,
  isNumber,
  isObject,
  isString,
  isUndefined,
  set,
  isEmpty,
} from 'lodash';
import i18n from 'i18next';

export const req = (val) => {
  if (isUndefined(val) || isNull(val)) {
    return i18n.t('Обязательное поле');
  }

  if (val instanceof Blob) {
    if (val.size === 0) {
      return i18n.t('Обязательное поле');
    }
  } else if (isObject(val) && isEmpty(val)) {
    return i18n.t('Обязательное поле');
  }

  if (isString(val) && val === '') {
    return i18n.t('Обязательное поле');
  }

  return undefined;
};

export const checkTypesSelect = (val) => {
  if (!val) {
    return 'Error';
  }

  if (isObject(val) && isEmpty(val)) {
    return 'Error';
  }

  let hasRoles = 0;
  let roles = ['PRODUCER', 'IMPORTER', 'WHOLESALER', 'RETAIL'];

  // if (initial.omsId && initial.omsId !== '—') {
  //   roles = ['PRODUCER', 'IMPORTER']
  // }

  hasRoles = val.reduce((sum, el) => {
    if (roles.includes(el) || roles.includes(el?.value)) {
      sum = sum + 1;
    }
    return sum;
  }, 0);

  if (hasRoles === 0) {
    return 'Error';
  }

  return undefined;
};

export const check =
  (values, oldValues, errors, oldErrors, mutators) =>
  (key, ...cbs) => {
    const newVal = get(values, key);
    const oldVal = get(oldValues, key);

    if (!has(oldValues, key) || oldVal !== newVal || cbs.indexOf(req) !== -1) {
      const err = cbs.reduce(
        (res, cur) => res || cur(newVal, oldVal, key, values, mutators),
        undefined
      );

      set(errors, key, err);
    } else {
      set(errors, key, get(oldErrors, key));
    }
  };

export const exist = (values) => (key, cb) => {
  if (has(values, key) && !isNull(get(values, key))) {
    cb(key);
  }
};

export const each = (values) => (key, cb) => {
  const value = get(values, key);
  if (isArray(value)) {
    value.forEach((_, i) => cb(`${key}.${i}`));
  }
};

export const number = (value) =>
  isUndefined(value) || isNull(value) || isNumber(value) ? undefined : 'Error';
