import React, { FC, useMemo } from 'react';
import {
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { Icon, Tooltip } from '@crpt-ui/core';
import AggregationTooltipStyles from './AggregationTooltip.styles';

type AggregationTooltipProps = {
  code: string;
} & WithStyles<typeof AggregationTooltipStyles>;

const AggregationTooltip: FC<AggregationTooltipProps> = ({ classes, code }) => {
  const title = useMemo(
    () => (
      <div className={classes.title}>
        В вышестоящую групповую упаковку с кодом <span className={classes.code}>{code}</span>
      </div>
    ),
    [classes.code, classes.title, code],
  );

  return (
    <Tooltip interactive placement="bottom" title={title}>
      <div className={classes.root}>
        <Icon name="Information" />
      </div>
    </Tooltip>
  );
};

export default withStyles(AggregationTooltipStyles)(AggregationTooltip);
