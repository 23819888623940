import React, { FC, useEffect, useMemo } from 'react';
import { Select as SelectCRPT } from '@crpt-ui/core';
import FieldHoc from '../FieldHoc';

import type { FieldRenderProps } from 'react-final-form';
import {isArray} from "lodash";

export interface SelectAdapterProps
  extends FieldRenderProps<any, HTMLInputElement> {
  keepValue?: boolean;
  placeholder?: string;
  multiple?: boolean;
  options: {
    label: string;
    value: string;
  }[];
}

export const SelectAdapter: FC<SelectAdapterProps> = ({
  input,
  meta,
  keepValue,
  ...fieldRest
}) => {
  const { placeholder, ...rest } = fieldRest;

  useEffect(() => {
    if (!keepValue) {
      return () => {
        input.onChange('');
      };
    }
  }, []);

  const error = useMemo(() => {
    if ((!meta.initial || (isArray(meta.initial) && meta.initial.length === 0)) && !meta.touched) {
      return undefined;
    }

    return meta.error;
  }, [meta.error, meta.touched, meta.initial]);

  return (
    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    <SelectCRPT {...input} error={error} label={placeholder} {...rest} />
  );
};

export default FieldHoc(SelectAdapter);
