import { yup } from '@crpt-ui/form';
import { commonErrorMessages } from '../../../constants';
import { FieldIds, MetaFieldIds } from './AcsDocuments.types';
import { testInns } from '../AcsUnloads.utils';
import defaultSchema from '../AcsUnloads.schema';

import type { BooleanSchema, StringSchema } from 'yup';

const status = yup.object().shape(
  {
    [FieldIds.STATUS_CHECKED_OK]: yup
      .boolean()
      .when([FieldIds.STATUS_CHECKED_NOT_OK, FieldIds.STATUS_CANCELLED], {
        is: (statusCheckedNotOk, statusCancelled) =>
          !statusCheckedNotOk && !statusCancelled,
        then: (schema: BooleanSchema) => schema.oneOf([true]),
      }),
    [FieldIds.STATUS_CHECKED_NOT_OK]: yup
      .boolean()
      .when([FieldIds.STATUS_CHECKED_OK, FieldIds.STATUS_CANCELLED], {
        is: (statusCheckedOk, statusCancelled) =>
          !statusCheckedOk && !statusCancelled,
        then: (schema: BooleanSchema) => schema.oneOf([true]),
      }),
    [FieldIds.STATUS_CANCELLED]: yup
      .boolean()
      .when([FieldIds.STATUS_CHECKED_OK, FieldIds.STATUS_CHECKED_NOT_OK], {
        is: (statusCheckedOk, statusCheckedNotOk) =>
          !statusCheckedOk && !statusCheckedNotOk,
        then: (schema: BooleanSchema) => schema.oneOf([true]),
      }),
  },
  [
    [FieldIds.STATUS_CHECKED_OK, FieldIds.STATUS_CHECKED_NOT_OK],
    [FieldIds.STATUS_CHECKED_OK, FieldIds.STATUS_CANCELLED],
    [FieldIds.STATUS_CHECKED_NOT_OK, FieldIds.STATUS_CANCELLED],
  ]
);

export default defaultSchema.shape(
  {
    [FieldIds.SENDER_INN]: yup
      .string()
      .when([`meta.${MetaFieldIds.IS_OPERATOR}`, FieldIds.RECEIVER_INN], {
        is: (isOperator, receiverInn) => isOperator && !receiverInn,
        then: (schema: StringSchema) =>
          schema.required(commonErrorMessages.REQUIRED),
      })
      .test(FieldIds.SENDER_INN, commonErrorMessages.INNS_TYPE, testInns),
    [FieldIds.RECEIVER_INN]: yup
      .string()
      .when([`meta.${MetaFieldIds.IS_OPERATOR}`, FieldIds.SENDER_INN], {
        is: (isOperator, senderInn) => isOperator && !senderInn,
        then: (schema: StringSchema) =>
          schema.required(commonErrorMessages.REQUIRED),
      })
      .test(FieldIds.RECEIVER_INN, commonErrorMessages.INNS_TYPE, testInns),
    [FieldIds.STATUS]: status,
  },
  [[FieldIds.SENDER_INN, FieldIds.RECEIVER_INN]]
);
