import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Autocomplete as AutocompleteCRPT,
  Input as InputCRPT,
} from '@crpt-ui/core';
import FieldHoc from '../FieldHoc';

import type { FieldRenderProps } from 'react-final-form';
import type { InputProps } from '@material-ui/core';
import type { AutocompleteInputChangeReason } from '@material-ui/lab';
import type { AutocompleteProps } from '@crpt-ui/core/src/Autocomplete/Autocomplete';

interface Option {
  label: string;
  value: string;

  [k: string]: string | number;
}

interface AutocompleteAdapterProps
  extends FieldRenderProps<any, HTMLInputElement>,
    Omit<AutocompleteProps, 'onChange' | 'onInputChange'> {
  keepValue?: boolean;
  placeholder?: string;
  options: Option[];
  onInputChange?: (
    event: ChangeEvent<Record<string, unknown>>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void;
  onChange?: (event: ChangeEvent, option: Option) => void;
  inputProps: InputProps;
}

export const AutocompleteAdapter: FC<AutocompleteAdapterProps> = (props) => {
  const {
    input,
    meta,
    options,
    onChange,
    onInputChange,
    placeholder,
    loading,
    inputProps,
    required = false,
    label,
    keepValue,
    ...rest
  } = props;

  const validOption = useMemo(
    () => options.find(({ value }) => String(value) === String(input.value)),
    [input.value, options]
  );

  const defaultOption = validOption || {
    label: input.value,
    value: input.value,
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const [option, setOption] = useState<Option | null>(defaultOption);

  useEffect(() => {
    if (!keepValue) {
      return () => {
        input.onChange('');
      };
    }
  }, []);

  useEffect(() => {
    if (inputRef.current?.defaultValue && !input.value) {
      setOption({
        label: inputRef.current?.defaultValue,
        value: inputRef.current?.defaultValue,
      });
    } else if (input.value && validOption && !meta.active) {
      setOption(validOption);
    } else if (!input.value) {
      setOption(null);
    }
  }, [input.value, options]);

  const onChangeHandle = useCallback(
    (event: ChangeEvent, option: Option) => {
      if (typeof onChange === 'function') {
        onChange(event, option);
      }

      if (option) {
        setOption(option);
        input.onChange(option.value);
      } else {
        input.onChange('');
      }
    },
    [input, onChange]
  );

  const onInputChangeHandle = (
    event: ChangeEvent<Record<string, unknown>>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (typeof onInputChange === 'function') {
      onInputChange(event, value, reason);
    }

    if (['clear'].includes(reason)) {
      input.onChange('');
      setOption(null);
    }

    if (['input'].includes(reason)) {
      if (value === '') {
        setOption(null);
      }
    }
  };

  const error = useMemo(() => {
    if (!meta.touched && meta.pristine) {
      return undefined;
    }

    return meta.error;
  }, [meta.error, meta.touched]);

  return (
    <AutocompleteCRPT
      {...input}
      clearText=""
      closeText=""
      disableClearable={false}
      openText=""
      {...rest}
      //eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      onChange={onChangeHandle}
      onInputChange={onInputChangeHandle}
      options={options}
      renderInput={(params) => (
        <InputCRPT
          ref={inputRef}
          {...params}
          //eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          error={error}
          inputProps={{
            maxLength: 1000,
            ...params.inputProps,
            ...inputProps,
          }}
          label={placeholder || label}
          loading={loading}
          required={required}
          value={input.value}
        />
      )}
      value={option}
    />
  );
};

export default FieldHoc(AutocompleteAdapter);
