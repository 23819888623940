import type { TestFunction } from 'yup';
import { FormValues } from '../../CisUnloads.types';

export const testIsGtinUniq: TestFunction<
  string | undefined | null,
  FormValues
  > = function (gtin) {
  const { options } = this;

  const duplicate: number = options.context?.data.reduce((acc, curr) => {
    return curr.gtin === gtin ? ++acc : acc;
  }, 0);

  return duplicate < 2;
};