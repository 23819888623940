import { createActions } from 'redux-actions';

export const {
  checkingSystemRequirements,
  setRequirementsChecked,
  setLoading,
  clearSystemRequirements,
} = createActions(
  {
    CHECKING_SYSTEM_REQUIREMENTS: (payload) => payload,

    SET_REQUIREMENTS_CHECKED: (payload) => payload,
    SET_LOADING: (payload) => payload,

    CLEAR_SYSTEM_REQUIREMENTS: (payload) => payload,
  },
  {
    prefix: 'SYSTEM_REQUIREMENTS',
  }
);
