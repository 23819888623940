import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { productGroupService, unloadTasksService } from '@crpt/shared/services';
import { usersListSelect } from './AcsUnloads.utils';
import { MAX_YEARS_AGO } from './AcsUnloads.constants';
import { User } from './AcsUnloads.types';

export interface AcsUnloadsViewModelOptions {
  availablePGIdsCurrentUser?: number[];
  taskTypeId: string;
}

const AcsUnloadsViewModel = ({
  availablePGIdsCurrentUser = [],
  taskTypeId,
}: AcsUnloadsViewModelOptions) => {
  const [usersList, setUsersList] = useState<User[]>([]);
  const [availablePGIdsTask, setAvailablePGIdsTask] = useState<number[]>([]);

  const maxDate = useMemo(() => new Date(), []);
  const minDate = useMemo(
    () => moment().subtract(MAX_YEARS_AGO, 'years').toDate(),
    []
  );

  const productGroupCodeOptions = useMemo(() => {
    return availablePGIdsTask
      .filter((id) => availablePGIdsCurrentUser.includes(id))
      .map((id) => ({
        value: id,
        label: productGroupService.getName(id) ?? id,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
  }, [availablePGIdsCurrentUser, availablePGIdsTask]);

  useEffect(() => {
    Promise.all([
      unloadTasksService.getUsersList(),
      unloadTasksService.getTaskTypes(),
    ]).then(([usersList, taskTypes]) => {
      setUsersList(usersListSelect(usersList.data));

      const availablePGIds = taskTypes.data.list
        .find((item) => item.id === taskTypeId)
        ?.productGroups.map(({ id }) => id);
      setAvailablePGIdsTask(availablePGIds ?? []);
    });
  }, [taskTypeId]);

  return {
    usersList,
    maxDate,
    minDate,
    productGroupCodeOptions,
  } as const;
};

export default AcsUnloadsViewModel;
