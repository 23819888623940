import { withStyles, WithStyles } from '@material-ui/core';
import React, { FC } from 'react';
import VersionStyles from './Version.styles';
import moment from 'moment';
import clsx from 'clsx';

type VersionProps = {
  isLastVersion?: boolean;
  isSelected?: boolean;
  onClick: () => void;
  version?: number;
} & WithStyles<typeof VersionStyles>;

const Version: FC<VersionProps> = ({
  classes,
  isLastVersion = false,
  isSelected = false,
  onClick,
  version,
}) => {
  const formattedVersion = version ? moment(version).format('DD.MM.YYYY, HH:mm:ss') : null;

  return (
    <div
      className={clsx(classes.root, {
        [classes.lastVersion]: isLastVersion,
        [classes.selected]: isSelected,
      })}
      onClick={onClick}
    >
      {isLastVersion && (
        <div className={classes.titleLastVersion}>
          Последняя версия
        </div>
      )}
      <div className={classes.timestamp}>
        {formattedVersion}
      </div>
    </div>
  );
};

export default withStyles(VersionStyles)(Version);
