import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Icons from '@crpt-ui/icons';
import { Box, Grid, useTheme } from '@material-ui/core';

import { TableCell } from './components';
import DatatableFilter from './DatatableFilter';

const DatatableHeaderCell = ({ column, ...rest }) => {
  const theme = useTheme();

  const columnTitleProps = useMemo(() => {
    if (column.canSort) {
      return column.getSortByToggleProps({
        title: column.title,
        style: {
          cursor: 'pointer',
          userSelect: 'none',
        },
      });
    }
  }, [column]);

  const renderSortDirection = useCallback(() => {
    if (column.isSorted) {
      return React.createElement(
        column.isSortedDesc ? Icons.SortDescending : Icons.SortAscending,
        {
          style: {
            color: theme.palette.text.primary,
          },
        }
      );
    }

    return React.createElement(Icons.SortDescending, {
      style: {
        color: theme.palette.text.disabled,
      },
    });
  }, [column]);

  return (
    <TableCell {...rest}>
      <Grid container wrap="nowrap" alignItems="center">
        <Grid item container alignItems="center" {...columnTitleProps}>
          {column.canSort && (
            <Grid item>
              <Box ml={-1}>{renderSortDirection()}</Box>
            </Grid>
          )}
          <Grid item>{column.render('Header')}</Grid>
        </Grid>
        {column.canFilter ? (
          <Grid item style={{ marginLeft: 'auto' }}>
            <DatatableFilter column={column} />
          </Grid>
        ) : null}
      </Grid>
    </TableCell>
  );
};

DatatableHeaderCell.propTypes = {
  column: PropTypes.object.isRequired,
};

export default DatatableHeaderCell;
