import React from 'react';

import NumberFormat from 'react-number-format';

export const NumberFormatCustom = ({ inputRef, onChange, ...restProps }) => (
  <NumberFormat
    isNumericString
    allowedDecimalSeparators={['.', ',']}
    getInputRef={inputRef}
    thousandSeparator=" "
    onClick={(event) => event.target.select()}
    onValueChange={({ value }) => {
      onChange({
        target: {
          value,
        },
      });
    }}
    {...restProps}
  />
);
