import React from 'react';

import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import classes from './index.module.scss';

export const Separator = ({ mt, mb }) => {
  return React.createElement(Box, {
    className: classes.separator,
    mb,
    mt,
    mx: -4,
  });
};

Separator.propTypes = {
  mt: PropTypes.number,
  mb: PropTypes.number,
};

Separator.defaultProps = {
  mt: 0,
  mb: 2,
};
