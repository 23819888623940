export const crytoproErrorsDataList = {
  '0x800B010A':
    'Не удается построить цепочку сертификатов до доверенного корневого центра, убедитесь что установлены все корневые и промежуточные сертификаты [0x800B010A]',
  '0x80090020':
    'Внутренняя ошибка [0x80090020]. Если используется внешний токен, убедитесь, что ввели корректный PIN-код',
  '0x8007065B': 'Истекла лицензия на КриптоПро CSP [0x8007065B]',
  '0x800B0109':
    'Отсутствует сертификат УЦ в хранилище корневых сертификатов [0x800B0109]',
  '0x8009200C':
    'Не удается найти сертификат и закрытый ключ для расшифровки [0x8009200C]',
  '0x80090008':
    'Указан неверный алгоритм (используется устаревшая версия КриптоПро CSP или КриптоПро ЭЦП Browser plug-in) [0x80090008]',
  '0x000004C7': 'Операция отменена пользователем [0x000004C7]',
  '0x8009000D':
    'Нет доступа к закрытому ключу. Ввод пароля отменен или произошел сбой в запомненных паролях [0x8009000D]',
  '0x800B0101':
    'Истек/не наступил срок действия требуемого сертификата [0x800B0101]',
  '0x8009200B':
    'Не удается найти закрытый ключ для подписи, убедитесь что сертификат установлен правильно [0x8009200B]',
  '0x8010006E': 'Действие отменено пользователем [0x8010006E]',
};
