import React, { memo } from 'react';

import clsx from 'clsx';

import styles from './index.module.scss';

type GroupInfoItemProp = {
  title: string;
  value: string;
  classes?: string;
  options?: Record<string, boolean>;
};

export const GroupInfoItem = memo(
  ({ title, value = '—', classes = '', options = {} }: GroupInfoItemProp) => {
    return (
      <div className={`${styles.wrapper} ${classes}`}>
        <div className={`${styles.contentPart} ${styles.titlePart}`}>
          {title}
        </div>
        <div
          className={clsx(
            styles.contentPart,
            { [styles.disabled]: options.disabledValue },
            { [styles.error]: options.hasValueError },
            { [styles.textWithEllipsis]: options.textWithEllipsis }
          )}
        >
          {value}
        </div>
      </div>
    );
  }
);
