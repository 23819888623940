import React, { useMemo } from 'react';

import { PrimitiveStatus } from '../../../../common_components/PrimitiveStatus/PrimitiveStatus';
import { getPrepareDocumentStatus } from '../../ServiceDeskDocument/ServiceDeskDocument.utils';

export const StatusCell = ({ cell, row }) => {
  const { color, icon, label } = useMemo(
    () => getPrepareDocumentStatus(cell.value, row.original.status, true),
    [cell.value, row.original.status]
  );

  return React.createElement(
    PrimitiveStatus,
    {
      color,
      icon,
    },
    label
  );
};
