import React, { FC, useEffect } from 'react';
import { Checkbox } from '@crpt-ui/core';
import FieldHoc from '../FieldHoc';

import type { FieldRenderProps } from 'react-final-form';
import type { CheckboxProps } from '@crpt-ui/core/src/Checkbox/Checkbox';

interface CheckboxAdapterProps
  extends FieldRenderProps<any, HTMLLabelElement>,
    CheckboxProps {
  keepValue?: boolean;
}

const CheckboxAdapter: FC<CheckboxAdapterProps> = ({
  input,
  keepValue,
  meta,
  ...fieldRest
}) => {
  useEffect(() => {
    if (!keepValue) {
      return () => {
        input.onChange('');
      };
    }
  }, []);

  return <Checkbox {...input} {...fieldRest} />;
};

export default FieldHoc(CheckboxAdapter, {
  type: 'checkbox',
});
