import { createSelector } from 'reselect';

import { getClosingDocumentState } from '../../ducks/ClosingDocument.selectors';

/**
 * Cancellation data
 * @param state
 * @returns {{}} - Cancellation data
 */
export const getCancellationState = createSelector(
  [getClosingDocumentState],
  (state) => state.EventModal
);

/**
 * Returns is open Cancellation of the modal
 * @returns {boolean} - Is open Cancellation of the modal
 */
export const getOpenModal = createSelector(
  [getCancellationState],
  (state) => state.isOpenModal
);

export const getLoading = createSelector(
  [getCancellationState],
  (state) => state.loading
);

export const getCancellationLoading = createSelector(
  [getCancellationState],
  (state) => state.cancellationLoading
);

export const getData = createSelector(
  [getCancellationState],
  (state) => state.data
);
