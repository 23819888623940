import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { Button } from '@crpt-ui/core';

import { openPaymentDialog } from '../../common_components/PaymentDialog/ducks/PaymentDialog.actions';
import { getSoleProprietorship } from '../_Profile/Company/ducks/Company.selectors';

import { PaymentStatusTemplate } from './PaymentStatusTemplate';

const PaymentStatusError = ({
  openPaymentDialog,
  history,
  isSoleProprietorship,
}) => {
  useEffect(() => {
    if (!isSoleProprietorship) {
      history.push('/dashboard');
    }
  });

  return React.createElement(PaymentStatusTemplate, {
    icon: 'Cancel',
    title: 'Платеж не принят',
    text: (
      <Button
        className="whiteColor"
        style={{ textTransform: 'inherit', fontSize: 16, fontWeight: 600 }}
        onClick={() => openPaymentDialog()}
      >
        Попробовать ещё раз
      </Button>
    ),
    isError: true,
  });
};

const mapDispatchToProps = { openPaymentDialog };

const mapStateToProps = (state) => ({
  isSoleProprietorship: getSoleProprietorship(state),
});

PaymentStatusError.propTypes = {
  openPaymentDialog: PropTypes.func.isRequired,
  isSoleProprietorship: PropTypes.bool.isRequired,
};

export const PaymentStatusErrorConnected = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentStatusError);
