import DataTable from '@crpt-ui/datatable';
import { Content, NoInfo, Preloader } from '@crpt/shared/components';
import { Box } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { RecordsCount } from './components';
import { useColumns } from './ViolationsRegistryTable.constants';
import { getCurrentTableName } from './ViolationsRegistryTable.utils';

import type { match as Match } from 'react-router-dom';
import type {
  TableNamesEnum,
  ProjectName,
  MatchParams,
} from './ViolationsRegistryTable.types';
export interface ViolationsRegistryTableProps {
  data: any;
  isLoading: boolean;
  fetchData: (apiRequestUrl: string) => void;
  resetData: () => void;
  baseRoute: string;
  projectName?: ProjectName;
}

export const ViolationsRegistryTable = ({
  data,
  isLoading = true,
  fetchData,
  resetData,
  baseRoute = '/violations',
}: ViolationsRegistryTableProps) => {
  const [currentTableName, setTableName] =
    useState<TableNamesEnum | undefined>(undefined);
  const [isAwaitForRequest, setAwaitForRequest] = useState<boolean>(true);

  const instance = useRef(null);

  const match: Match<MatchParams> = useRouteMatch();

  useEffect(() => {
    resetData();
    setAwaitForRequest(true);

    setTableName(getCurrentTableName(match.params));
  }, [match.params, resetData]);

  useEffect(() => {
    if (currentTableName) {
      const { type, inn } = match.params;
      let apiRequestUrl = baseRoute;

      if (type) {
        apiRequestUrl += `/${type}`;
      }
      if (inn) {
        apiRequestUrl += `/${inn}`;
      }

      setAwaitForRequest(false);
      fetchData(apiRequestUrl);
    }
  }, [currentTableName]);

  const columns = useColumns({
    name: currentTableName,
    data: data,
  });

  return (
    <>
      <Preloader isOpen={isLoading} />
      {Boolean(data.length) && (
        <Box display="flex" justifyContent="flex-end" py={2}>
          <RecordsCount count={data.length} />
        </Box>
      )}
      <Content>
        {!isLoading && Boolean(data.length) && (
          <DataTable
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            columns={columns}
            data={data}
            manualFilters={false}
            ref={instance}
            useFilters
            useSort
          />
        )}
        {!isLoading && !isAwaitForRequest && Boolean(!data.length) && (
          <NoInfo />
        )}
      </Content>
    </>
  );
};
