import { createActions } from 'redux-actions';

export const {
  setCertificate,
  setLoading,
  closeAddingUserDialog,
  openAddingUserDialog,
  openCertificateSelectionDialog,
  closeCertificateSelectionDialog,
  sendNewUser,
} = createActions(
  {
    SET_CERTIFICATE: (payload) => payload,
    SET_LOADING: (payload) => payload,

    OPEN_ADDING_USER_DIALOG: () => {},
    CLOSE_ADDING_USER_DIALOG: () => {},

    OPEN_CERTIFICATE_SELECTION_DIALOG: () => {},
    CLOSE_CERTIFICATE_SELECTION_DIALOG: () => {},

    SEND_NEW_USER: (payload) => payload,
  },
  {
    prefix: 'ADDING_USER_DIALOG',
  }
);
