import {
  PackageTypeEnum,
  CisStatusEnum,
  EliminationReasonEnum,
  OldCisPackageTypeEnum,
} from '../constants';

export interface CisDocumentDTO {
  agentInn: string;
  applicationDate: string;
  brand: string;
  children: string[];
  cis: string;
  documentId: string;
  eliminationReason: EliminationReasonEnum;
  emissionDate: string;
  emissionType: number;
  expirationDate: string;
  extendedPackageType: PackageTypeEnum;
  extendedStatus: number;
  gtin: string;
  manualCorrected: boolean;
  operationDate: string;
  operationType: CisDocumentOperationTypeEnum;
  orderId: string;
  ownerInn: string;
  packageType: OldCisPackageTypeEnum;
  paid: string;
  parent: string;
  payDate: string;
  producerInn: string;
  productGroup: number;
  productName: string;
  productionDate: string;
  receiverId?: string;
  receiverInn?: string;
  receiverName?: string;
  senderId?: string;
  senderInn?: string;
  senderName?: string;
  sold: string;
  status: CisStatusEnum;
  tariff: number;
  timestamp: string;
  tnv10: string;
  total: number;
  vsd: string;
}

export enum CisDocumentOperationTypeEnum {
  EMISSION = 0,
  APPLIED = 1,
  OWNER_CHANGING = 2,
  AGGREGATION = 3,
  DISAGGREGATION = 4,
  AUTODISAGGREGATION = 5,
  WITHDRAW = 6,
  RETURN_TO_TURNOVER = 8,
  CANCELLATION = 9,
  DOCUMENT_FIX = 10,
  DOCUMENT_CORRECTION = 11,
  TRADE_STARTING = 12,
  SHIPMENT = 13,
  NOT_ACCEPTED = 14,
  REMARK = 15,
  ORDERED = 17,
  PAYMENT = 19,
  UNIVERSAL_CANCEL = 20,
  KM_CANCELLATION = 21,
  TRADE_STARTING_INDI = 22,
  ACCEPTED = 31,
  ACCEPTED_INDIVIDUAL = 32,
  ROLLBACK_PARTIALLY_PROCESSED = 33,
  CANCELLATION_NOT_APPLIED = 103,
  CANCELLATION_APPLIED = 104,
  TRANSFORMATION = 105,
  RETURN = 106,
  AGENT_CHANGING = 107,
  CROSSBORDER = 108,
  FTS_REQUEST_1 = 111,
  FTS_REQUEST_3 = 112,
  FTS_RESPOND_OK = 113,
  FTS_RESPOND_NOT_OK = 114,
  CROSSBORDER_CSD = 115,
  GRAY_ZONE_FORMED = 116,
  SHIPMENT_CANCELLATION = 117,
  SALE_ATTEMPT = 118,
  FTS_RESPOND_ERROR = 119,
  DOC_CANCELLATION = 120,
  VSD_STATUS_REQUEST = 121,
  VSD_RESPONSE_NOT_OK = 122,
  VSD_RESPONSE_OK = 123,
  MANUAL_FIX = 124,
  CIRCULATION_INFORMATION = 127,
  EAS_REQUEST_3 = 128,
  EAS_RESPOND_OK = 129,
  EAS_RESPOND_NOT_OK = 130,
}
export enum OperationTypeEnum {
  UNKNOWN = 0,
  UNIVERSAL_TRANSFER_DOCUMENT = 1,
  AGGREGATION_DOCUMENT = 2,
  DISAGGREGATION_DOCUMENT = 3,
  UNIVERSAL_CORRECTION_DOCUMENT = 7,
  WRITE_OFF = 9,
  UNIVERSAL_TRANSFER_DOCUMENT_FIX = 10,
  UNIVERSAL_CORRECTION_DOCUMENT_FIX = 11,
  LP_INTRODUCE_GOODS = 27,
  LP_SHIP_GOODS = 28,
  LP_ACCEPT_GOODS = 29,
  LP_INTRODUCE_GOODS_CSV = 30,
  LP_INTRODUCE_GOODS_XML = 31,
  LP_SHIP_GOODS_CSV = 32,
  IMPORT = 34,
  LP_SHIP_GOODS_XML = 36,
  LP_ACCEPT_GOODS_XML = 37,
  LK_REMARK = 38,
  LK_REMARK_CSV = 39,
  LK_REMARK_XML = 40,
  LK_RECEIPT_XML = 49,
  LK_RECEIPT_CSV = 52,
  LK_RECEIPT = 54,
  LP_GOODS_IMPORT = 61,
  LP_GOODS_IMPORT_CSV = 62,
  LP_GOODS_IMPORT_XML = 63,
  LP_CANCEL_SHIPMENT = 72,
  LK_KM_CANCELLATION = 76,
  LK_KM_CANCELLATION_CSV = 77,
  LK_KM_CANCELLATION_XML = 78,
  LK_APPLIED_KM_CANCELLATION = 79,
  LK_APPLIED_KM_CANCELLATION_CSV = 80,
  LK_APPLIED_KM_CANCELLATION_XML = 81,
  LK_CONTRACT_COMMISSIONING = 82,
  LK_CONTRACT_COMMISSIONING_CSV = 83,
  LK_CONTRACT_COMMISSIONING_XML = 84,
  LK_INDI_COMMISSIONING = 85,
  LK_INDI_COMMISSIONING_CSV = 86,
  LK_INDI_COMMISSIONING_XML = 87,
  AGGREGATION_DOCUMENT_CSV = 96,
  AGGREGATION_DOCUMENT_XML = 97,
  DISAGGREGATION_DOCUMENT_CSV = 98,
  DISAGGREGATION_DOCUMENT_XML = 99,
  IMPORT_TRANSIT = 99,
  REAGGREGATION_DOCUMENT = 100,
  REAGGREGATION_DOCUMENT_CSV = 101,
  REAGGREGATION_DOCUMENT_XML = 102,
  LP_SHIP_RECEIPT = 103,
  LP_SHIP_RECEIPT_CSV = 104,
  LP_SHIP_RECEIPT_XML = 105,
  OST_DESCRIPTION = 106,
  OST_DESCRIPTION_CSV = 107,
  OST_DESCRIPTION_XML = 108,
  CROSSBORDER = 112,
  CROSSBORDER_CSV = 113,
  CROSSBORDER_XML = 114,
  LP_INTRODUCE_OST = 115,
  LP_INTRODUCE_OST_CSV = 116,
  LP_INTRODUCE_OST_XML = 117,
  LP_RETURN = 118,
  LP_RETURN_CSV = 119,
  LP_RETURN_XML = 120,
  LP_SHIP_GOODS_CROSSBORDER = 121,
  LP_INTRODUCE_GOODS_AUTO = 124,
  LP_GOODS_IMPORT_AUTO = 125,
  LP_CANCEL_SHIPMENT_CROSSBORDER = 126,
  ATK_DISAGGREGATION = 127,
  ATK_DISAGGREGATION_CSV = 128,
  ATK_DISAGGREGATION_XML = 129,
  ATK_TRANSFORMATION = 130,
  ATK_TRANSFORMATION_CSV = 131,
  ATK_TRANSFORMATION_XML = 132,
  LP_FTS_INTRODUCE = 133,
  LP_FTS_INTRODUCE_CSV = 134,
  LP_FTS_INTRODUCE_XML = 135,
  ATK_AGGREGATION = 136,
  ATK_AGGREGATION_CSV = 137,
  ATK_AGGREGATION_XML = 138,
  LP_FTS_INTRODUCE_REQUEST = 140,
  LP_FTS_INTRODUCE_RESPONSE = 141,
  LP_INTRODUCE_GOODS_CROSSBORDER_CSD = 142,
  LP_INTRODUCE_GOODS_CROSSBORDER_CSD_CSV = 143,
  LP_INTRODUCE_GOODS_CROSSBORDER_CSD_XML = 144,
  GRAY_ZONE = 145,
  FTS_INTRODUCE = 146,
  UNIVERSAL_CANCEL_DOCUMENT = 149,
  IMPORT_TRANSIT_ = 150,
  CROSSBORDER_EXPORT_REGISTRY = 153,
  EAS_GTIN_CROSSBORDER_IMPORT = 154,
  EAS_GTIN_CROSSBORDER_EXPORT = 155,
  EAS_GTIN_CROSSBORDER_EXPORT_CSV = 156,
  VSD_STATUS_RESPONSE = 157,
  SETS_AGGREGATION = 161,
  SETS_AGGREGATION_CSV = 162,
  SETS_AGGREGATION_XML = 163,
  SURPLUS_POSTING = 170,
  SURPLUS_POSTING_CSV = 171,
  SURPLUS_POSTING_XML = 172,
  CIRCULATION_INFORMATION = 173,
  EAS_CROSSBORDER = 174,
  VSD_BULK_STATUS_RESPONSE = 185,
  EAS_GTIN_CROSSBORDER_ACCEPTANCE = 186,
  EAS_GTIN_CROSSBORDER_ACCEPTANCE_CSV = 187,
  LP_ACCEPT_GOODS_CSV = 188,
  LK_INDIVIDUALIZATION = 191,
  LK_INDIVIDUALIZATION_CSV = 192,
  LK_INDIVIDUALIZATION_XML = 193,
  FURS_FTS_INTRODUCE = 194,
  FURS_FTS_INTRODUCE_XML = 195,
  FURS_FTS_INTRODUCE_CSV = 196,
  FURS_FTS_INTRODUCE_REQUEST = 197,
  FURS_FTS_INTRODUCE_RESPONSE = 198,
  FURS_CROSSBORDER = 199,
  FURS_CROSSBORDER_CSV = 200,
  FURS_CROSSBORDER_XML = 201,
  EAS_CROSSBORDER_EXPORT = 202,
  EAS_CROSSBORDER_EXPORT_CSV = 203,
  EAS_CROSSBORDER_OUT = 204,
  EAS_CROSSBORDER_ACCEPTANCE = 205,
  GENERATED = 214,
  UTILISED = 215,
  DROPPED_OUT = 216,
  ELIMINATED = 217,
}
