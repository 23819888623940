import React from 'react';

import moment from 'moment';
import { get } from 'lodash';

import { Icon } from '@crpt-ui/core';

import { Currency } from '../common_components/Currency/Currency';
import { PrimitiveStatus } from '../common_components/PrimitiveStatus/PrimitiveStatus';
import { getValue } from '../common_components/Document/Document.utils';
import { Link } from '../common_components/Link/Link';

export const TextWrapper = ({ children }) => (
  <div
    style={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }}
  >
    {children}
  </div>
);

export const makeMoneyCell =
  ({ ...rest }) =>
  ({ cell: { value } }) =>
    value >= 0 ? (
      <span
        style={{
          fontWeight: 600,
        }}
      >
        <Currency {...rest}>{value}</Currency>
      </span>
    ) : (
      '—'
    );

export const makeCell =
  ({ colors, icons, values }) =>
  ({ cell: { value } }) =>
    React.createElement(
      PrimitiveStatus,
      {
        color: getValue(colors, value),
        icon: getValue(icons, value),
      },
      getValue(values, value)
    );

/**
 * Returns a formatted date.
 * If the value is missing or the date is incorrect it is returned "-"
 *
 * @param {string} format - Date format
 * @param {boolean} unix - Allowed to use the unix
 * @returns {string} - Returns a formatted date
 */
export const makeDateCell =
  ({ format, unix = false }) =>
  ({ cell: { value } }) => {
    if (!value || (value && !moment(value).isValid())) {
      return '—';
    }

    if (unix) {
      return moment.unix(value).format(format);
    }

    return moment(value).format(format);
  };

export const makeBoldCell =
  () =>
  ({ cell: { value } }) => {
    return value ? (
      <span
        style={{
          fontWeight: 600,
        }}
      >
        {value}
      </span>
    ) : (
      '—'
    );
  };

export const makeTextCell =
  () =>
  ({ cell: { value } }) => {
    return <TextWrapper>{value}</TextWrapper>;
  };

export const makeFilterOptions = (ObjEnum) => ({
  options: Object.keys(ObjEnum).map((key) => ({
    label: ObjEnum[key],
    value: key,
  })),
});

export const defaultCheckboxFilter = (rows, key, value) => {
  return rows.filter((row) => {
    return value.includes(row.values[key]);
  });
};

/**
 * Returns the link component
 * If the cell value is missing or not found accessor id returns null
 *
 * @param {string} to - Link without id
 * @param {string} accessorId - The way, where you could find id
 * @param {string} iconName - The name of the icons
 * @param {function} gettingValue - Function for getting a value
 * @param rest
 * @returns {Node}
 */
export const makeLinkCell =
  ({ to, accessorId, iconName, gettingValue, ...rest }) =>
  ({ cell, row }) => {
    const id = get(row, accessorId);
    const icon = iconName ? <Icon name={iconName} /> : undefined;

    if (!id || !cell.value) {
      return null;
    }

    return (
      <Link icon={icon} to={`${to}/${id}`} {...rest}>
        {gettingValue ? gettingValue(cell.value) : cell.value}
      </Link>
    );
  };
