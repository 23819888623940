import React, { memo, useCallback, useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import printJS from 'print-js';
import { saveAs } from 'file-saver';

import { zoomParameters } from './PDFViewer.constants';
import PDFViewerView from './PDFViewer.view';
import type { PDFViewerViewProps } from './PDFViewer.view';
import { Error, NoData } from './Banner';

pdfjs.GlobalWorkerOptions.workerSrc =
  process.env.REACT_APP_NAME === '@crpt/elk'
    ? '/pdf.worker.min.js'
    : `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export type PDFViewerProps = {
  pdfData: ArrayBufferLike;
  onDownloadFile?: VoidFunction;
} & Pick<
  PDFViewerViewProps,
  'title' | 'error' | 'zoomRatio' | 'pagination' | 'onClose'
>;

type OnLoadSuccess = PDFViewerViewProps['onLoadSuccess'];

const PDFViewer: React.FC<PDFViewerProps> = (props) => {
  const {
    title,
    pdfData: pdfDataProps,
    zoomRatio: zoomRatioProps = 1.5,
    pagination = false,
    onClose,
    onDownloadFile: onDownloadFileProps = null,
    error,
  } = props;

  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [pdfData, setPdfData] = useState(pdfDataProps);
  const [zoomRatio, setZoomRatio] = useState(zoomRatioProps);

  useEffect(() => {
    pdfDataProps && setPdfData(pdfDataProps);
  }, [pdfDataProps]);

  const onLoadSuccess: OnLoadSuccess = ({ numPages }) => {
    setPages(numPages);
  };

  const onPageIncrement = () => {
    setPage((state) => state + 1);
  };

  const onPageDecrement = () => {
    setPage((state) => state - 1);
  };

  const onZoomIn = () => {
    if (zoomRatio >= zoomParameters.maxRatio) {
      return;
    }

    let updatedZoomValue = zoomRatio * zoomParameters.changeRatio;

    if (updatedZoomValue > zoomParameters.maxRatio) {
      updatedZoomValue = zoomParameters.maxRatio;
    }

    setZoomRatio(updatedZoomValue);
  };

  const onZoomOut = () => {
    if (zoomRatio <= zoomParameters.minRatio) {
      return;
    }

    let updatedZoomValue = zoomRatio / zoomParameters.changeRatio;

    if (updatedZoomValue < zoomParameters.minRatio) {
      updatedZoomValue = zoomParameters.minRatio;
    }

    setZoomRatio(updatedZoomValue);
  };

  const onDownloadFile = useCallback(() => {
    if (onDownloadFileProps) {
      onDownloadFileProps();
    } else {
      const blob = new Blob([pdfData], {
        type: 'application/pdf',
      });
      const fileName = title.replace(/ /g, '_');
      saveAs(blob, `${fileName}.pdf`);
    }
  }, [onDownloadFileProps, pdfData, title]);

  const onPrintFile = useCallback(() => {
    const url = window.URL.createObjectURL(
      new Blob([pdfData], {
        type: 'application/pdf',
      })
    );

    printJS(url);
  }, [pdfData]);

  if (error) {
    return <Error />;
  }

  if (!pdfData) {
    return <NoData />;
  }

  return (
    <PDFViewerView
      data={pdfData}
      error={error}
      onClose={onClose}
      onDownload={onDownloadFile}
      onLoadSuccess={onLoadSuccess}
      onPageDecrement={onPageDecrement}
      onPageIncrement={onPageIncrement}
      onPrint={onPrintFile}
      onZoomIn={onZoomIn}
      onZoomOut={onZoomOut}
      page={page}
      pages={pages}
      pagination={pagination}
      title={title}
      zoomRatio={zoomRatio}
    />
  );
};

export default memo(PDFViewer);
