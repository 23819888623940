import { createSelector } from 'reselect';

import { UserRoles } from '../../../constants/index';

export const getAuth = (state) => state.COMMON.Auth;

export const getAuthState = createSelector([getAuth], (auth) => auth.authState);

export const getUserInfo = createSelector([getAuth], (auth) => auth.userInfo);

export const getUserAuthorities = createSelector(
  [getUserInfo],
  (userInfo) => userInfo.authorities || []
);

export const getUserInn = createSelector(
  [getUserInfo],
  (userInfo) => userInfo.inn
);

export const getUserPid = createSelector(
  [getUserInfo],
  (userInfo) => userInfo.pid
);

export const getProductGroupsDetails = createSelector(
  [getUserInfo],
  (userInfo) => userInfo.product_group_info || []
);

/**
 * Returns whether the user is an operator
 *
 * @type {function}
 * @returns {boolean}
 */
export const isOperator = createSelector(
  [getUserAuthorities],
  (userAuthorities) => {
    return (
      userAuthorities.includes(UserRoles.OPERATOR) ||
      userAuthorities.includes(UserRoles.IS_MP_OPERATOR)
    );
  }
);

export const isAdmin = createSelector(
  [getUserAuthorities],
  (userAuthorities) => {
    return userAuthorities.includes(UserRoles.ADMIN);
  }
);

export const isInActive = createSelector(
  [getUserAuthorities],
  (userAuthorities) => {
    return userAuthorities.includes(UserRoles.ROLE_INACTIVE);
  }
);

export const isOIV = createSelector([getUserAuthorities], (userAuthorities) => {
  return (
    userAuthorities.includes(UserRoles.OGV) ||
    userAuthorities.includes(UserRoles.ORG_OGV)
  );
});
