export const MAX_DATE_PERIOD_DAYS = 90;
export const MAX_YEARS_AGO = 5;
export const GTIN_LENGTH = 14;

export const pgsWithHint = [1, 2, 4, 5, 6, 8, 9, 13, 19,23]

export const validateStringRegExp = {
  inn: /^(\d{10}|\d{12})$/,
  gtin: /^\d{14}$/,
  noSpaces: /^\S+$/,
  digital: /^\d+$/,
};
