import instance from '../axios';

import type { AxiosRequestConfig } from 'axios';
import type {
  CreateTaskDataBase,
  CreateTaskResponse,
  UpdateTaskOptions,
  UpdateTaskResponse,
  SystemNamesResponse,
  TasksOptions,
  TasksResponse,
  TaskTypesResponse,
  UsersListResponse,
  GetTaskOptions,
  GetTaskResponse,
  GetTasksResultsOptions,
  GetTasksResultsResponse,
  GetTaskHistoryOptions,
  GetTaskHistoryResponse,
  UpdateResultOptions,
  UpdateResultResponse,
  DeleteResultOptions,
  DeleteResultResponse,
  DownloadResultOptions,
  DownloadResultResponse,
  GetPeriodsOptions,
  GetPeriodsResponse,
  GetTaskInfoOptions,
  GetTaskInfoResponse,
  GetJasperReportParamsOptions,
  GetJasperReportParamsResponse,
  GetTaskParamInfoOptions,
  GetRemainingCountOfReportOptions,
  GetRemainingCountOfReportResponse,
} from './UnloadTasksService.types';

class UnloadTasksService {
  getSystemNames(config?: AxiosRequestConfig) {
    return instance.get<SystemNamesResponse>(
      '/api/v3/facade/dispenser-api/tasks/systemnames',
      config
    );
  }

  getUsersList() {
    return instance.get<UsersListResponse>('/api/v3/facade/user/list/active');
  }

  getTaskTypes() {
    return instance.get<TaskTypesResponse>(
      '/api/v3/facade/dispenser-api/tasktypes',
      {
        params: {
          page: 0,
          size: 100,
        },
      }
    );
  }

  getTasks({ params, data }: TasksOptions) {
    return instance.post<TasksResponse>(
      `/api/v3/facade/dispenser-api/tasks/search`,
      data,
      {
 params 
}
    );
  }

  createTask<T = CreateTaskDataBase>(data: T) {
    return instance.post<CreateTaskResponse>('/api/v3/facade/dispenser-api/tasks', data);
  }

  createTaskV1<T = CreateTaskDataBase>(data: T) {
    return instance.post<CreateTaskResponse>('/dispenser/v1/tasks', data);
  }

  updateTask({ id, data, params }: UpdateTaskOptions) {
    return instance.put<UpdateTaskResponse>(
      `/api/v3/facade/dispenser-api/tasks/${id}`,
      data,
      {
        params,
      }
    );
  }

  getTask({ id, params }: GetTaskOptions) {
    return instance.get<GetTaskResponse>(
      `/api/v3/facade/dispenser-api/tasks/${id}`,
      {
 params 
}
    );
  }

  getTasksResults({ params }: GetTasksResultsOptions) {
    return instance.get<GetTasksResultsResponse>(
      `/api/v3/facade/dispenser-api/results`,
      {
 params 
}
    );
  }

  getTaskHistory({ id, params }: GetTaskHistoryOptions) {
    return instance.get<GetTaskHistoryResponse>(
      `/api/v3/facade/dispenser-api/tasks/history/${id}`,
      {
 params 
}
    );
  }

  updateResult({ id, data, params }: UpdateResultOptions) {
    return instance.put<UpdateResultResponse>(
      `/api/v3/facade/dispenser-api/results/${id}`,
      data,
      {
        params,
      }
    );
  }

  deleteResult({ id, params }: DeleteResultOptions) {
    return instance.delete<DeleteResultResponse>(
      `/api/v3/facade/dispenser-api/results/${id}`,
      {
        params,
      }
    );
  }

  downloadResult({ id, config }: DownloadResultOptions) {
    return instance.get<DownloadResultResponse>(
      `/api/v3/facade/dispenser-api/results/${id}/file`,
      config
    );
  }

  getPeriods({ reportId }: GetPeriodsOptions) {
    return instance.get<GetPeriodsResponse>(
      `/api/v3/facade/dispenser-api/tasktypes/reports/${reportId}/periods`
    );
  }

  getTaskInfo({ data }: GetTaskInfoOptions) {
    return instance.post<GetTaskInfoResponse>(
      `/dispenser/v1/results/info`,
      data
    );
  }

  getJasperReportParams({ params, reportId }: GetJasperReportParamsOptions) {
    return instance.get<GetJasperReportParamsResponse>(
      `/jasperserver/registry/v1/captures/${reportId}`,
      {
        params,
      }
    );
  }

  getTaskParamInfo({ taskId, params }: GetTaskParamInfoOptions ) {
    return instance.get(
      `/dispenser/v1/tasks/${taskId}/param_info`,
      {
        params,
    });
  }

  getRemainingCountOfReport({
    reportId,
    params,
  }: GetRemainingCountOfReportOptions) {
    return instance.get<GetRemainingCountOfReportResponse>(
      `/api/v3/facade/dispenser-api/tasktypes/reports/${reportId}/available_count`,
      {
        params,
      }
    );
  }
}

export const unloadTasksService = new UnloadTasksService();
