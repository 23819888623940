import React, { useCallback, useMemo } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { Icon } from '@crpt-ui/core';

import { ProfileHeader } from '../../components/ProfileHeader/ProfileHeader';
import { getRegistered } from '../../Company/ducks/Company.selectors';
import { openAddingUserDialog } from '../adding-user-dialog/ducks/AddingUserDialog.actions';
import { translate } from '../../../Translate/Translate';

import css from './header.module.scss';

const Header = ({
  openAddingUserDialog,
  history,
  isRegistered,
  isInActive,
  isAdmin,
}) => {
  const onOpenAddingUserDialog = useCallback(() => {
    openAddingUserDialog();
  }, [openAddingUserDialog]);

  const mainActions = useMemo(
    () => [
      {
        className: `${css.headerButton} ${css.weightGainSelector}`,
        label: translate('Добавить пользователя'),
        onClick: onOpenAddingUserDialog,
        isShow: isInActive || isAdmin,
        size: 'lg',
        icon: <Icon name="Add" />,
      },
    ],
    [history, isRegistered, onOpenAddingUserDialog, css]
  );

  return React.createElement(ProfileHeader, {
    mainActions,
    page: 'USERS_LIST',
    useSeparator: false,
  });
};

Header.propTypes = {};

const mapStateToProps = (state) => ({
  isRegistered: getRegistered(state),
});

const mapDispatchToProps = {
  openAddingUserDialog,
};

export const HeaderConnected = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Header);
