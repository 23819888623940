import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { history } from '../../store';
import { deleteAllCookies } from '../../utils/cookieHelpers';

import {
  clearLoginFormKep,
  mountedLoginKep,
} from './ducks/LoginFormKep.actions';
import { LoginFormKepView } from './LoginFormKep.view';
import { getErrors, getLoading } from './ducks/LoginFormKep.selectors';

export const LoginFormKep = () => {
  const nidKeys = useSelector((state) => state.LoginFormKep.nidKeys);
  const isLoading = useSelector((state) => getLoading(state));
  const errors = useSelector((state) => getErrors(state));

  const dispatch = useDispatch();
  useEffect(() => {
    const nidKeys = window.localStorage.getItem('nidKeys');
    if (window.location.pathname === '/nid_call_back_url/' && nidKeys) {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const code = params.get('code');
      const state = params.get('state');
      const nidKeysObj = JSON.parse(nidKeys);

      if (state && code && nidKeysObj.state === state) {
        history.push('/login-inn');
        window.localStorage.setItem('code', code);
      } else {
        deleteAllCookies();
        localStorage.clear();
        history.push('/login-kep');
      }
    } else {
      dispatch(mountedLoginKep());
    }

    return () => {
      dispatch(clearLoginFormKep());
    };
  }, [clearLoginFormKep, mountedLoginKep, window.localStorage]);

  const onSubmit = useCallback(() => {
    const nidKeys = JSON.parse(window.localStorage.getItem('nidKeys'));
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      response_type,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      redirect_uri,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id,
      nonce,
      state,
      scope,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      code_challenge,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      code_challenge_method,
    } = nidKeys;
    const requestUrl = `https://nid.e-gov.am/hy/auth?response_type=${response_type}&redirect_uri=${redirect_uri}&client_id=${client_id}&nonce=${nonce}&state=${state}&scope=${scope}&code_challenge=${code_challenge}&code_challenge_method=${code_challenge_method}`;
    window.location.href = requestUrl;
  }, []);

  return React.createElement(LoginFormKepView, {
    onSubmit,
    errors,
    isLoading,
    nidKeys,
  });
};
