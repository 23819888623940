import { get, isArray, isUndefined } from 'lodash';

import { toast } from '@crpt/shared/toast';

export const notifyDelay = () => {
  toast.error('Невозможно выполнить запрос. Внутренняя ошибка сервера.');
};

const notifyProcess = (message, status, _url, showBackendMessage = false) => {
  // 401 - ошибка авторизации, при ней перекидывает на логин
  if (
    typeof message === 'string' &&
    /^[34]/.test(`${status}`) &&
    status !== 401
  ) {
    // кроссбраузерность для IE
    const browser = window.navigator.userAgent.toLowerCase();
    if (/trident/gi.test(browser) || /msie/gi.test(browser)) {
      toast.error(message);
    }

    if (message.indexOf('Organisation with inn') !== -1 && status === 404) {
      toast.error(
        `2920: Организация ( ИНН ${message.match(/\d+/)} ) не найдена`
      );
    } else if (
      message.indexOf('Requested entity was not found') !== -1 &&
      status === 404
    ) {
      toast.error('2920: Организация не найдена');
    } else {
      toast.error(message);
      return;
    }
  }

  if (isUndefined(status)) {
    toast.error('Сервер временно недоступен.');
  }
  if (status === 503) {
    toast.error('Сервер недоступен');
  }
  if (status === 500 && showBackendMessage) {
    toast.error(message);
  }
  if (status === 403) {
    toast.error('Невозможно выполнить запрос. Доступ запрещен.');
  } else {
    toast.error('Невозможно выполнить запрос. Внутренняя ошибка сервера.');
  }
};

/**
 * Error handling helper
 * @param {{}} error - Error data
 * @param {boolean} showBackendMessage - Whether to show error messages from the backend
 * @param {boolean} withoutDescriptionOfError - To exclude the description of the error
 */
export const notifyMessageHelper = (
  error,
  showBackendMessage = false,
  withoutDescriptionOfError = false
) => {
  let message;
  const response = get(error, 'response');
  const data = get(response, 'data');
  const status = get(response, 'status', '');
  const url = get(response, 'request.responseURL');

  if (data instanceof Blob) {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      try {
        message = get(JSON.parse(fileReader.result), 'error_message', 'error');
      } catch (e) {
        message = 'wrong blob';
      }
      notifyProcess(message, status, url);
    };
    fileReader.onerror = () => {
      message = 'wrong blob';
      notifyProcess(message, status, url);
    };

    fileReader.readAsText(data);
  } else if (isArray(data)) {
    const errorArray = get(response, 'data');

    errorArray.forEach((item) => {
      message =
        get(item, 'error_message') ||
        get(item, 'errorMessage') ||
        get(item, 'message');

      notifyProcess(message, status, url, showBackendMessage);
    });
  } else {
    message =
      get(response, 'data.error_message') ||
      get(response, 'data.errorMessage') ||
      get(response, 'data.message');
    const description = get(response, 'data.description');

    if (description && !withoutDescriptionOfError) {
      message = description;
    }

    notifyProcess(message, status, url, showBackendMessage);
  }
};
