import React from 'react';

import { Typography } from '@material-ui/core';

import { StripeLayout } from '../../common_components/Layouts';

/**
 * View component for mobile device
 */
export const MobilePage = () => (
  <StripeLayout title="Мобильные устройства не поддерживают работу в системе">
    <StripeLayout.Content>
      <Typography
        component="h1"
        style={{
          fontSize: '24px',
          textAlign: 'center',
          fontWeight: '600',
        }}
      >
        Для входа используйте десктопную версию
      </Typography>
    </StripeLayout.Content>
  </StripeLayout>
);
