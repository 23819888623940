import { combineActions, handleActions } from 'redux-actions';

import {
  clearLoginFormKep,
  setErrors,
  setLoadingButton,
  setNidKeysResponse,
  submit,
} from './LoginFormKep.actions';

const errors = handleActions(
  {
    [combineActions(clearLoginFormKep, submit)]: () => null,
    [setErrors]: (_state, { payload }) => payload || null,
  },
  null
);

const isLoading = handleActions(
  {
    [setLoadingButton]: (_, { payload }) => payload,
    [clearLoginFormKep]: () => false,
  },
  false
);

const nidKeys = handleActions(
  {
    [setNidKeysResponse]: (_, { payload }) => payload,
  },
  null
);

export const reducer = {
  errors,
  isLoading,
  nidKeys,
};
