import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';

import { RequiredFieldsWarning } from '@crpt-ui/core';

import { Checkbox } from '../Form/Checkbox';
import { Select } from '../Form/Select';
import { Modal } from '../Modal/Modal';
import { Input } from '../Form/Input';
import { PhoneNumberInput } from '../PhoneNumberInput/phone-number-input';
import { NumberFormatCustom } from '../NumberFormatCustom/NumberFormatCustom';
import { Link } from '../Link/Link';
import { Separator } from '../Separator/Separator';
import { createStatic } from '../../services/utils';

import { useStyles } from './PaymentDialog.styled';
import VisaVerified from './svg/VisaVerified.png';
import MastercardSecureCode from './svg/MastercardSecureCode.png';
import MirAccept from './svg/MirAccept.png';

import { ReactComponent as Visa } from './svg/Visa.svg';
import { ReactComponent as MasterCard } from './svg/MasterCard.svg';
import { ReactComponent as Mir } from './svg/Mir.svg';

export const PaymentDialogView = ({
  open,
  onClose,
  accountOptions,
  modalActions,
  hasValidationErrors,
}) => {
  const classes = useStyles();

  return (
    <Modal
      actions={modalActions}
      maxWidth="lg"
      open={open}
      title="Оплата"
      onClose={onClose}
    >
      <Box mb={2} mt={2}>
        <RequiredFieldsWarning
          className={classes.title}
          error={hasValidationErrors}
        />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Select
            required
            classes={{
              root: classes.root,
            }}
            label="Выберите лицевой счет"
            name="contractId"
            options={accountOptions}
          />
        </Grid>

        <Grid item xs={12}>
          <Input
            required
            InputProps={{
              inputComponent: NumberFormatCustom,
              inputProps: {
                decimalScale: 2,
                isAllowed: ({ value }) => value.split('.')[0].length <= 5,
                allowNegative: false,
                onClick: () => {},
              },
            }}
            label="Сумма с НДС (от 100 до 50 000 драм.)"
            name="amount"
          />
        </Grid>

        <Grid item xs={6}>
          <Input
            required
            InputProps={{
              inputComponent: PhoneNumberInput,
            }}
            label="Номер телефона"
            name="phone"
          />
        </Grid>

        <Grid item xs={6}>
          <Input required label="Адрес электронной почты" name="email" />
        </Grid>
      </Grid>

      <Separator mt={2} />

      <Grid container alignItems="center" justify="space-between" spacing={0}>
        <Grid item xs={5}>
          <Checkbox
            label={
              <Fragment>
                Согласен(на) с{' '}
                <Link
                  href={createStatic(
                    'Правила оплаты услуг по предоставлению кодов маркировки посредством интернет-платежа.pdf'
                  )}
                  router={false}
                  target="_blank"
                >
                  правилами приёма платежей
                </Link>
              </Fragment>
            }
            name="agree"
          />
        </Grid>

        <Grid
          container
          item
          alignItems="center"
          justify="flex-end"
          spacing={1}
          xs={7}
        >
          <Grid item>
            <Visa />
          </Grid>
          <Grid item>
            <img alt="Visa Verified" height={28} src={VisaVerified} />
          </Grid>
          <Grid item>
            <MasterCard />
          </Grid>
          <Grid item>
            <img
              alt="Mastercard Secure Code"
              height={32}
              src={MastercardSecureCode}
            />
          </Grid>
          <Grid item>
            <Mir />
          </Grid>
          <Grid item>
            <img alt="Mir Accept" height={30} src={MirAccept} />
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

PaymentDialogView.propTypes = {
  accountOptions: PropTypes.shape({
    label: PropTypes.node,
    value: PropTypes.number,
  }),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  hasValidationErrors: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};
