import React from 'react';

import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import Datatable from '@crpt-ui/datatable';
import { Autocomplete, RequiredFieldsWarning } from '@crpt-ui/core';

import { Checkbox } from '../../../../common_components/Form/Checkbox';
import { Modal } from '../../../../common_components/Modal/Modal';
import { DatePickerInput } from '../../../../common_components/Form/DatePickerInput';
import { Separator } from '../../../../common_components/Separator/Separator';

import { ManuallyAgreementDocumentColumns } from './ManuallyAgreementDocument.constants';

export const ManuallyAgreementDocumentView = ({
  onCloseModal,
  onInputChange,
  onSetSelectedOrganizations,
  autocompleteList,
  datePickerProps,
  autocompleteValue,
  selectedOrganizations,
  datatableActions,
  modalActions,
  autocompleteProps,
  hasValidationErrors,
  isOpen,
  isLoadingAutocomplete,
  classes,
}) => (
  <Modal
    actions={modalActions}
    open={isOpen}
    title="Создание согласия (ручное добавление контрагентов)"
    onClose={onCloseModal}
  >
    <Box my={2}>
      <RequiredFieldsWarning error={hasValidationErrors} />
    </Box>

    <DatePickerInput
      {...datePickerProps}
      required
      label="Дата завершения действия документа согласия о предоставлении информации"
      name="expiration_date"
    />

    <Separator mt={2} />

    <Checkbox
      label="Предоставление возможности получения сведений по кодам идентификации всем зарегистрированным в ГИС МТ участникам оборота маркированной продукции"
      name="everyonePermitted"
    />

    <Box mt={2}>
      <Autocomplete
        {...autocompleteProps}
        clearOnEscape
        disableClearable
        freeSolo
        getOptionLabel={(option) =>
          option.name ? `${option.name} / ${option.inn}` : ''
        }
        label="Наименование / ИНН организации"
        loading={isLoadingAutocomplete}
        loadingText={null}
        options={autocompleteList}
        renderTags={() => null}
        value={autocompleteValue}
        onChange={onSetSelectedOrganizations}
        onInputChange={onInputChange}
      />
    </Box>

    {selectedOrganizations.length !== 0 && (
      <Box className={classes.datatable} mt={2}>
        <Datatable
          useSort
          actions={datatableActions}
          columns={ManuallyAgreementDocumentColumns}
          data={selectedOrganizations}
          manualSorting={false}
        />
      </Box>
    )}
  </Modal>
);

ManuallyAgreementDocumentView.defaultProps = {
  selectedOrganizations: [],
};

ManuallyAgreementDocumentView.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSetSelectedOrganizations: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  autocompleteList: PropTypes.array.isRequired,
  autocompleteValue: PropTypes.array.isRequired,
  selectedOrganizations: PropTypes.array,
  datatableActions: PropTypes.array.isRequired,
  modalActions: PropTypes.array.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isLoadingAutocomplete: PropTypes.bool.isRequired,
};
