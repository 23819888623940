import React from 'react';
import { Route as ReactRoute, RouteProps } from 'react-router-dom';
import RouteController from '../RouteController/RouteContoller';

export const Route: React.FC<RouteProps> = (props) => {
  return (
    <ReactRoute
      {...props}
      render={(renderProps) => (
        <RouteController {...props} renderProps={renderProps} />
      )}
    />
  );
};
