import React, { useCallback, useMemo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { get } from 'lodash';

import { Icon, Link } from '@crpt-ui/core';

import { Input } from '../../../../common_components/Form/Input';
import {
  checkCyrillic,
  isInn,
  isKPP,
  isOGRN,
  validatePhone,
} from '../../../../common_components/Form/utils/validations';
import { PhoneNumberInput } from '../../../../common_components/PhoneNumberInput/phone-number-input';
import { withFormContext } from '../../../../common_components/Form/Form.utils';
import * as companySelectors from '../../Company/ducks/Company.selectors';
import { commonInfoPaths } from '../EdoRegistration.constants';
import { translate } from '../../../Translate/Translate';

const CommonForm = ({ formValues, formMutators, isForeignLegalEntity }) => {
  const isKppVisible = useMemo(() => {
    return get(formValues, 'inn.length', 0) === 10;
  }, [formValues]);

  const clearCommonInfo = useCallback(() => {
    for (const path of commonInfoPaths) {
      formMutators.updateField(path, undefined);
    }
  }, [formMutators]);

  return (
    <Grid container item direction="row" spacing={2}>
      <Grid container item justify="space-between">
        <Grid item>
          <Typography variant="h3">{translate('Общие данные')}</Typography>
        </Grid>
        <Grid item>
          <Link icon={<Icon name="Close" />} onClick={clearCommonInfo}>
            Очистить все
          </Link>
        </Grid>
      </Grid>

      <Grid container item direction="column" spacing={2}>
        <Grid item>
          <Input required label="Наименование организации" name="orgName" />
        </Grid>

        <Grid container item direction="row" spacing={2}>
          <Grid item xs={isKppVisible ? 4 : 6}>
            <Input
              required
              label="ИНН организации"
              name="inn"
              validate={isInn}
            />
          </Grid>
          <Grid item xs={isKppVisible ? 4 : 6}>
            <Input
              label="ОГРН организации"
              name="ogrn"
              required={!isForeignLegalEntity}
              validate={isForeignLegalEntity ? null : isOGRN}
            />
          </Grid>
          {isKppVisible && (
            <Grid item xs={4}>
              <Input
                required
                label="КПП организации"
                name="kppElk"
                validate={isKPP}
              />
            </Grid>
          )}
        </Grid>

        <Grid item>
          <Input
            required
            label="Фамилия ответственного представителя организации"
            name="lastName"
            validate={checkCyrillic}
          />
        </Grid>
        <Grid item>
          <Input
            required
            label="Имя ответственного представителя организации"
            minLength={2}
            name="firstName"
            validate={checkCyrillic}
          />
        </Grid>
        <Grid item>
          <Input
            label="Отчество ответственного представителя организации"
            minLength={2}
            name="middleName"
            validate={checkCyrillic}
            xs={12}
          />
        </Grid>
        <Grid item>
          <Input
            InputProps={{
              inputComponent: PhoneNumberInput,
            }}
            label="Телефон"
            name="phone"
            validate={validatePhone}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  isForeignLegalEntity: companySelectors.getIsForeignLegalEntity(state),
});

export const CommonFormConnected = compose(
  connect(mapStateToProps),
  withFormContext({ values: true, mutators: true })
)(CommonForm);
