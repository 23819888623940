import React from 'react';

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Link } from '@crpt-ui/core';

import {
  ErrorCode,
  ErrorDescription,
  ErrorMessage,
  Root,
} from './ExceptionLayout.styled';

export const ExceptionLayoutView = (props) => {
  const { code, message, description } = props;
  const { t } = useTranslation();

  return (
    <Root>
      <ErrorCode>{code}</ErrorCode>
      <ErrorMessage>{message}</ErrorMessage>
      {description && (
        <Box mt={2}>
          <ErrorDescription>{description}</ErrorDescription>
        </Box>
      )}
      <Box mt={4}>
        <ErrorDescription>
          {t('Попробуйте')}{' '}
          <Link component={RouterLink} to="/">
            {t('вернуться на главную')}
          </Link>
        </ErrorDescription>
      </Box>
    </Root>
  );
};

ExceptionLayoutView.propTypes = {
  code: PropTypes.node.isRequired,
  message: PropTypes.node.isRequired,
  description: PropTypes.node,
};
