import { createSelector } from 'reselect';

import { getUserAuthorities } from '../Auth/ducks/Auth.selectors';

export const getConfig = (state) => state.COMMON.App.config;
export const getLanguage = (state) => state.COMMON.App.language;
export const getLocation = (state) => state.router.location;

/**
 * Returns an array of routes depending on user authorities
 *
 * @type {function}
 * @returns {[]}
 */
export const getRoutes = createSelector(
  [getConfig, getUserAuthorities],
  (config, userAuthorities) => {
    return config.routes.filter(({ accessDenied = [], canAccess }) => {
      return canAccess
        ? canAccess?.find((item) => userAuthorities.includes(item))
        : !accessDenied.find((item) => userAuthorities.includes(item));
    });
  }
);
