export const UserStatusNameEnum = {
  BLOCKED: 'Заблокирован',
  NOT_ACTIVATED: 'Не активирована',
  NOT_REGISTERED: 'Незарегистрирован',
  PRE_REGISTERED_ISSUER: 'Предрегистрация производителя',
  PRE_REGISTERED_TRADER: 'Предрегистрация продавца',
  PRE_REGISTERED: 'Предрегистрация',
  REGISTERED: 'Зарегистрирован',
  REMOVED: 'Удален',
  RESTORED: 'Восстановлен',
};
