import { handleActions } from 'redux-actions';

import { setDocumentId } from './SignModal.actions';

const documentId = handleActions(
  {
    [setDocumentId]: (_, { payload }) => payload,
  },
  null
);

export const reducer = {
  documentId,
};
