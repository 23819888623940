import React from 'react';
import { Checkbox } from '@crpt-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import type { AutocompleteRenderOptionState } from '@material-ui/lab';

interface Option {
  additionalLabel: string;
  label: string;
  value: string;
}

interface TwoValuesOptionProps extends WithStyles<typeof styles> {
  selected: boolean;
  option: Option;
}

const styles = () =>
  createStyles({
    optionWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    checkbox: {
      marginRight: 8,
      paddingTop: 10,
    },
    label: {
      fontWeight: 600,
      fontSize: 14,
    },
    additionalLabel: {
      color: '#b2b3b5',
      fontSize: 14,
    },
    separator: {
      margin: '0 8px',
    },
  });

const TwoValuesOption = withStyles(styles)(
  ({ classes, option, selected }: TwoValuesOptionProps) => (
    <div className={classes.optionWrapper}>
      <Checkbox checked={selected} className={classes.checkbox} label="" />
      <span className={classes.label}>{option.label}</span>
      <span className={classes.separator}>/</span>
      <span className={classes.additionalLabel}>{option.additionalLabel}</span>
    </div>
  )
);

export const renderTwoValuesOption = (
  option: Option,
  { selected }: AutocompleteRenderOptionState
) => <TwoValuesOption option={option} selected={selected} />;
