import { call, put, select, take, takeEvery } from 'redux-saga/effects';

import { Api } from '../../../../../common_components/Api/Api';
import { getDocumentInfo } from '../../ducks/ClosingDocument.selectors';
import { setDocumentInfo } from '../../ducks/ClosingDocument.actions';
import { isActOfReconciliation } from '../../ClosingDocument.utils';
import { fetchDocumentsSaga } from '../../../../../common_components/Document/ducks/Document.saga';

import {
  fetchClosingDocument,
  setClosingDocumentContent,
  setLoading,
} from './ViewDocument.actions';

export function* getClosingDocument({ id, type, status }) {
  const printUrl = isActOfReconciliation(type, status)
    ? 'print-utoch'
    : 'print';

  const requestPayload = {
    url: `/edo-api/incoming-documents/${id}/${printUrl}`,
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
    },
  };

  return yield call(Api.request, requestPayload, {
    preloading: false,
  });
}

function* fetchClosingDocumentSaga({ payload: id }) {
  yield put(setLoading(true));

  yield take(setDocumentInfo);

  const { type, status } = yield select(getDocumentInfo);

  const [documentContent] = yield call(fetchDocumentsSaga, {
    hasEdo: true,
    id,
    type,
    status,
  });

  if (documentContent) {
    yield put(setClosingDocumentContent(documentContent.data));
  }

  yield put(setLoading(false));
}

export const saga = function* watch() {
  yield takeEvery(fetchClosingDocument, fetchClosingDocumentSaga);
};
