import { WithStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { ComponentProps, FC, ReactNode } from 'react';

import { ReactComponent as Logo } from '../../svg/Logo.svg';
import StripeLayoutStyles from './StripeLayout.styles';

export interface StripeLayoutProps
  extends ComponentProps<'div'>,
    WithStyles<typeof StripeLayoutStyles> {
  footer?: ReactNode;
  size: 'md' | 'lg';
  title?: string;
}

const StripeLayout: FC<StripeLayoutProps> = ({
  children,
  classes,
  className,
  footer,
  size = 'md',
  title,
  ...rest
}) => {
  return (
    <div
      className={clsx(
        classes.root,
        {
          [classes.sizeMd]: size === 'md',
          [classes.sizeLg]: size === 'lg',
        },
        className
      )}
      {...rest}
    >
      <div className={classes.header}>
        <div className={classes.container}>
          <div className={classes.logo}>
            <Logo />
          </div>
          {title ? <div className={classes.title}>{title}</div> : null}
        </div>
      </div>
      <div className={classes.container}>
        {children ? <div className={classes.content}>{children}</div> : null}
      </div>

      <div className={classes.container}>
        {footer ? <div className={classes.footer}>{footer}</div> : null}
      </div>
    </div>
  );
};

export default withStyles(StripeLayoutStyles)(StripeLayout);
