export enum DownloadFileAvailability {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export enum DownloadFileStatus {
  DELETED = 'DELETED',
  FAILED = 'FAILED',
  PREPARATION = 'PREPARATION',
  SUCCESS = 'SUCCESS',
}
