import React, { Fragment } from 'react';

import { Box } from '@material-ui/core';

import Datatable from '@crpt-ui/datatable';
import { NoInfo } from '@crpt/shared/components';
import { Pagination, Typography } from '@crpt-ui/core';

import { PreloaderWrapper } from '../../../common_components/PreloaderWrapper/PreloaderWrapper';
import { ContentWrapper } from '../../../common_components/styled';
import { PageActions } from '../../../common_components/PageActions/PageActions';

import { useStyles } from './ClosingDocumentsList.styled';
import { columns } from './ClosingDocumentsList.constants';

export const ClosingDocumentsListView = ({
  data,
  pagination,
  pageActions,
  datatableActions,
  isLoading,
  onRowClick,
  onRowSelect,
}) => {
  const classes = useStyles();

  return (
    <ContentWrapper className={classes.wrapper}>
      <Box mb={2} mx={4}>
        <PageActions mainActions={pageActions} />
      </Box>

      {data.length === 0 && !isLoading ? (
        <NoInfo>
          <Typography variant="h5">У&nbsp;вас еще нет документов</Typography>
        </NoInfo>
      ) : (
        <Fragment>
          <PreloaderWrapper isLoading={isLoading}>
            <Datatable
              useExpanded
              useRowSelect
              actions={datatableActions}
              columns={columns}
              data={data}
              onRowClick={onRowClick}
              onRowSelect={onRowSelect}
            />
          </PreloaderWrapper>

          {data.length > 0 && (
            <div className={classes.paginationWrapper}>
              <Pagination
                disabled={isLoading}
                page={pagination.page}
                pagesCount={pagination.pagesCount}
                onPageChange={pagination.onChangePage}
              />
            </div>
          )}
        </Fragment>
      )}
    </ContentWrapper>
  );
};
