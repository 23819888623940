import React, { FC } from 'react';
import { Button, Icon, Tooltip } from '@crpt-ui/core';
import { ButtonBaseProps } from '@material-ui/core';

export const VersionsButton: FC<ButtonBaseProps> = ({
  onClick,
}) => {
  return (
    <Tooltip
      title="История версий"
      enterDelay={700}
    >
      <Button
        onClick={onClick}
        startIcon={<Icon name="Version" />}
        variant="text"
      ></Button>
    </Tooltip>
  );
};
