export const oids = [
  {
    oid: '1.2.643.3.131.1.1',
    short: 'INN',
    full: 'ИНН',
  },
  {
    oid: '1.2.643.100.4',
    short: 'INNLE',
    full: 'ИНН ЮЛ',
  },
  {
    oid: '1.2.643.100.1',
    short: 'OGRN',
    full: 'ОГРН',
  },
  {
    oid: '1.2.643.100.5',
    short: 'OGRNIP',
    full: 'ОГРНИП',
  },
  {
    oid: '1.2.643.100.3',
    short: 'SNILS',
    full: 'СНИЛС',
  },
  {
    oid: '1.2.840.113549.1.9.1',
    short: 'E',
    full: 'emailAddress',
  },
  {
    oid: '2.5.4.3',
    short: 'CN',
    full: 'commonName',
  },
  {
    oid: '2.5.4.4',
    short: 'SN',
    full: 'surname',
  },
  {
    oid: '2.5.4.42',
    short: 'G',
    full: 'givenName',
  },
  {
    oid: '2.5.4.6',
    short: 'C',
    full: 'countryName',
  },
  {
    oid: '2.5.4.7',
    short: 'L',
    full: 'localityName',
  },
  {
    oid: '2.5.4.8',
    short: 'S',
    full: 'stateOrProvinceName',
  },
  {
    oid: '2.5.4.9',
    short: 'STREET',
    full: 'streetAddress',
  },
  {
    oid: '2.5.4.10',
    short: 'O',
    full: 'organizationName',
  },
  {
    oid: '2.5.4.11',
    short: 'OU',
    full: 'organizationalUnitName',
  },
  {
    oid: '2.5.4.12',
    short: 'T',
    full: 'title',
  },
] as const;

export type DistinguishedName = {
  [key in typeof oids[number]['short']]?: string;
};

export function convertSubjectToDistinguishedName(subject: string) {
  const distinguishedName: DistinguishedName = {};
  const pairs = subject.split(/, (?=[^,]+=)/);

  for (let index = 0; index < pairs.length; index++) {
    const d = pairs[index].match(/([^=]+)=(.*)/);
    if (d && d.length === 3) {
      const rdn = d[1].trim().replace(/^OID\./, '');
      const value = d[2]
        .trim()
        .replace(/^"(.*)"$/, '$1')
        .replace(/""/g, '"');

      const oid = oids.find((item) => item.oid == rdn || item.full == rdn);
      if (oid) {
        distinguishedName[oid.short] = value;
      } else {
        distinguishedName[rdn as typeof oids[number]['short']] = value;
      }
    }
  }

  return distinguishedName;
}
