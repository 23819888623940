import querystring from 'querystring';

import React, { useCallback, useEffect, useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';

import { RegistrationStatusEnum } from '../../constants/index';
import {
  withForm,
  withFormContext,
} from '../../common_components/Form/Form.utils';

import { registrationStatusSubmit } from './ducks/RegistrationStatus.actions';
import {
  getRegistrationDeclineReason,
  getRegistrationStatus,
} from './ducks/RegistrationStatus.selectors';
import { RegistrationStatusView } from './RegistrationStatus.view';

type RegistrationStatusProps = {
  formMutators: any;
  formValues: any;
  registrationDeclineReason: string;
  registrationStatus: string;
  isFormErrors?: boolean;
};

const RegistrationStatus = ({
  formMutators,
  formValues,
  registrationDeclineReason,
  registrationStatus,
  isFormErrors,
}: RegistrationStatusProps) => {
  const dispatch = useDispatch();

  const documentId = useMemo(() => {
    const query = window.location.search.replace(/\?/g, '');
    return _.get(querystring.parse(query), 'documentId', '');
  }, []);

  const onSubmit = useCallback(() => {
    dispatch(registrationStatusSubmit(formValues));
  }, [dispatch, formValues]);

  const isSignInButtonVisible = useMemo(() => {
    return [
      RegistrationStatusEnum.REGISTERED,
      RegistrationStatusEnum.CHECKED_OK,
    ].includes(registrationStatus);
  }, [registrationStatus]);

  useEffect(() => {
    formMutators.updateField('documentId', documentId);
  }, [documentId, formMutators]);

  return React.createElement(RegistrationStatusView, {
    onSubmit,
    registrationDeclineReason,
    registrationStatus,
    isFormErrors,
    isSignInButtonVisible,
  });
};

RegistrationStatus.propTypes = {
  registrationDeclineReason: PropTypes.string.isRequired,
  registrationStatus: PropTypes.string.isRequired,
  unmount: PropTypes.func.isRequired,
};

const mapStateToProps = (state: any) => ({
  registrationDeclineReason: getRegistrationDeclineReason(state),
  registrationStatus: getRegistrationStatus(state),
});

export const RegistrationStatusConnected = compose(
  withForm(),
  withFormContext({ mutators: true, values: true, errors: true }),
  connect(mapStateToProps, null)
)(RegistrationStatus);
