import { makeDateCell, makeMoneyCell } from '../../../utils/Table.utils';

import { makeStatusCell } from './StatusCell/StatusCell';
import { ExpandCell } from './ExpandCell/ExpandCell';

export const columns = [
  {
    accessor: 'type',
    Cell: ExpandCell,
    Header: 'Тип документа',
  },
  {
    accessor: 'number',
    Header: 'Номер документа',
    width: 240,
  },
  {
    accessor: 'create_time_stamp',
    Cell: makeDateCell({
      format: 'DD.MM.YYYY',
      unix: true,
    }),
    Header: 'Дата документа',
    width: 160,
  },
  {
    accessor: 'total_price',
    Cell: makeMoneyCell({
      isPenny: true,
    }),
    Header: 'Сумма',
    width: 160,
  },
  {
    accessor: 'status',
    Cell: makeStatusCell(),
    Header: 'Статус',
    width: 250,
  },
];
