import { createReducer } from '@reduxjs/toolkit';

import * as actions from './AutocompleteFilter.actions';

const loaded = createReducer(false, {
  [actions.mounted.type]: () => true,
  [actions.loaded.type]: (_state, { payload = [] }) => !!payload || false,
  [actions.unmounted.type]: () => false,
  [actions.error.type]: () => true,
});

const results = createReducer([], {
  [actions.mounted.type]: () => [],
  [actions.loaded.type]: (_state, { payload = [] }) => payload,
  [actions.unmounted.type]: () => [],
  [actions.error.type]: () => [],
});

const error = createReducer(null, {
  [actions.mounted.type]: () => null,
  [actions.loaded.type]: () => null,
  [actions.unmounted.type]: () => null,
  [actions.error.type]: (_state, { payload = 'error' }) => payload,
});

export const reducer = {
  loaded,
  results,
  error,
};
