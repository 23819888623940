import { handleActions } from 'redux-actions';

import {
  closeNotificationOfRejectionModal,
  openNotificationOfRejectionModal,
} from './NotificationOfRejection.actions';

const open = handleActions(
  {
    [openNotificationOfRejectionModal]: () => true,
    [closeNotificationOfRejectionModal]: () => false,
  },
  false
);

export const reducer = {
  open,
};
