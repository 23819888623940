import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { createForm } from '@crpt-ui/form';
import { unloadTasksService } from '../../../../services';
import { Format, Periodicity, TASK_TYPE } from '../../constants';
import { periodicitySelect } from './Canceled.utils';
import { createTask as createTaskAdapter } from './Canceled.adapters';
import schema from './Canceled.schema';

import type {
  CreateTaskResponse,
  CreateTaskDataCanceled,
} from '../../../../services/UnloadTasksService.types';
import { prepareUserListOptions } from '../../utils';

import type { UserListOption } from '../../utils';

interface Select {
  value: string;
  label: string;
  key: number;
}

interface State {
  isUploading: boolean;
  usersList: UserListOption[];
  periodicitySelect: Select[];
  result: {
    error?: any;
    data?: CreateTaskResponse;
  };
}

interface CanceledViewModelOptions {
  frontendTaskUrl: string;
  productGroupCode: string;
  participantInn?: string;
}

const CanceledViewModel = ({
  frontendTaskUrl,
  participantInn,
  productGroupCode,
}: CanceledViewModelOptions) => {
  const [state, setState] = useState<State>({
    isUploading: false,
    usersList: [],
    periodicitySelect: periodicitySelect,
    result: {},
  });

  const onSubmit = useCallback(
    async (values) => {
      setState((state) => ({
        ...state,
        result: {},
        isUploading: true,
      }));

      try {
        const success =
          await unloadTasksService.createTask<CreateTaskDataCanceled>({
            ...createTaskAdapter(values),
            format: Format.CSV,
            productGroupCode,
            frontendTaskUrl,
            systemName: TASK_TYPE.CANCELED.systemName,
            taskTypeId: TASK_TYPE.CANCELED.taskTypeId,
          });

        setState((state) => ({
          ...state,
          result: {
            data: success.data,
          },
          isUploading: false,
        }));
      } catch (error) {
        setState((state) => ({
          ...state,
          result: {
            error,
          },
          isUploading: false,
        }));
      }
    },
    [frontendTaskUrl, productGroupCode]
  );

  const formInstance = useMemo(
    () =>
      createForm({
        schema,
        initialValues: {
          name: `Даты аннулирования_${moment(new Date()).format('DD.MM.YYYY')}`,
          periodicity: Periodicity.SINGLE,
          participantInn,
        },
        onSubmit: onSubmit,
      }),
    [participantInn, onSubmit]
  );

  const reset = useCallback(() => formInstance.reset(), [formInstance]);

  useEffect(() => {
    unloadTasksService.getUsersList().then((response) => {
      setState((state) => ({
        ...state,
        usersList: prepareUserListOptions(response.data),
      }));
    });
  }, []);

  return {
    ...state,
    formInstance,
    reset,
  };
};

export default CanceledViewModel;
