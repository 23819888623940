import styled from 'styled-components';

import { ContentWrapper } from './ContentWrapper';
import { InnerContentWrapper } from './InnerContentWrapper';
import { PaginationWrapper } from './PaginationWrapper';
import { StickContentWrapper } from './StickContentWrapper';

const WithBorder = styled.div`
  border-top: 1px solid rgba(196, 196, 196, 0.25);
`;

const Height20px = styled.div`
  height: 20px;
`;

const Div = styled.div``;

const DialogWrapper = styled.div`
  & > div[class^='Overlay'] {
    position: absolute;
    z-index: 1000;
  }
`;

export {
  Div,
  Height20px,
  WithBorder,
  ContentWrapper,
  InnerContentWrapper,
  PaginationWrapper,
  StickContentWrapper,
  DialogWrapper,
};
