import React, { Fragment } from 'react';

import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { withContentWrapper } from '../../../common_components/hocs/withContentWrapper/withContentWrapper';
import { Input } from '../../../common_components/Form/Input';
import { Preloader } from '../../../common_components/Preloader/Preloader';
import {
  isBIC,
  isCorrAccount,
  isSettAccount,
} from '../../../common_components/Form/utils/validations';
import { translate } from '../../Translate/Translate';

import { HeaderConnected } from './Header/Header';

const RequisitesViewComp = ({ isLoading, isRequisitesRequired, isEditing }) => {
  if (isLoading) {
    return <Preloader isOpen />;
  }

  return (
    <Fragment>
      <HeaderConnected isRequisitesRequired={isRequisitesRequired} />

      <Box mt={3}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Input
              disabled={!isEditing}
              label={translate('Наименование банка')}
              name="bankName"
              required={isRequisitesRequired}
            />
          </Grid>

          <Grid item xs={4}>
            <Input
              InputProps={{
                inputProps: {
                  maxLength: 6,
                },
              }}
              disabled={!isEditing}
              label={translate('БИК')}
              name="bic"
              required={isRequisitesRequired}
              validate={isRequisitesRequired ? isBIC : null}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              InputProps={{
                inputProps: {
                  maxLength: 20,
                },
              }}
              disabled={!isEditing}
              label={translate('Корр. счет')}
              name="correspondingAccount"
              required={isRequisitesRequired}
              validate={isRequisitesRequired ? isCorrAccount : null}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              InputProps={{
                inputProps: {
                  maxLength: 16,
                },
              }}
              disabled={!isEditing}
              label={translate('Расчетный счет')}
              name="settlementAccount"
              required={isRequisitesRequired}
              validate={isRequisitesRequired ? isSettAccount : null}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

RequisitesViewComp.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChangeBicField: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  stepDescriptionData: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  isRegistered: PropTypes.bool.isRequired,
  isRequisitesRequired: PropTypes.bool.isRequired,
};

export const RequisitesView = withContentWrapper()(RequisitesViewComp);
