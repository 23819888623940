import { theme } from '@crpt-ui/core';
import { chain } from 'lodash';

import type { StatusProps } from '@crpt-ui/core';

export const commonErrorMessages = {
  REQUIRED: 'Поле не заполнено',
  DATE_TYPE: 'Неверный формат даты',
  NUMBER_TYPE: 'Значение должно быть числом',
  NUMBER_INTEGER: 'Значение должно быть целым числом',
  NUMBER_POSITIVE: 'Значение должно быть больше нуля',
  MIN_LENGTH: 'Значение должно содержать больше ${min} символов',
  MAX_LENGTH: 'Значение должно содержать меньше ${max} символов',
  MAX_NUMBER: 'Значение должно быть меньше ${max}',
  MIN_DATE: 'Дата меньше допустимой',
  MAX_DATE: 'Дата больше допустимой',
  TRIM: 'Значение не может начинаться/заканчиваться пробелами',
  INNS_TYPE: 'Один или более ИНН имеют некорректный формат',
  INN: 'Значение не корректно',
  GTIN_LENGTH: 'Недопустимое количество символов',
  GTIN_TRIM: 'Значение не может содержать пробелы',
  GTIN_UNIQ: 'GTIN должен быть уникальным',
  VALUE_NOT_DIGITAL: 'Значение должно содержать только цифры',
};

export enum Format {
  CSV = 'CSV',
}

export enum FileFormat {
  CSV = 'CSV',
  XLSX = 'XLSX',
}

export enum Periodicity {
  SINGLE = 'SINGLE',
  REGULAR = 'REGULAR',
}

export const periodicityTitle: Record<Periodicity, string> = {
  [Periodicity.SINGLE]: 'Однократное',
  [Periodicity.REGULAR]: 'Регулярное',
};

export const periodicitySelect = Object.entries(periodicityTitle).map(
  (item, index) => ({
    key: index,
    value: item[0],
    label: item[1],
  })
);

export enum TASK_TYPE_ENUM {
  ACS_DOCUMENTS = 'ACS_DOCUMENTS',
  CIS_ACS = 'CIS_ACS',
  AGGREGATION = 'AGGREGATION',
  BOX_CODES = 'BOX_CODES',
  CIS_CODES = 'CIS_CODES',
  CANCELED = 'CANCELED',
  REPORT = 'REPORT',
  CIS_UNLOADS = 'FILTERED_CIS_REPORT',
}

export const TASK_TYPE = {
  CANCELED: {
    systemName: 'Даты аннулирования',
    taskTypeId: '3a860c1a-7329-4898-aa47-1bf3d4e837e4',
  },
  REPORT: {
    systemName: 'Отчет',
    taskTypeId: '6f154990-796c-4eb9-b003-735ea9feb6c9',
  },
  AGGREGATION: {
    systemName: 'Ошибки агрегации',
    taskTypeId: 'f05dde7c-d110-450d-a3fc-e25adcbf2837',
  },
  BOX_CODES: {
    systemName: 'Коды коробов',
    taskTypeId: '0416bb48-e54a-44c5-b409-6d1c503ca03e',
  },
  CIS_CODES: {
    systemName: 'Коды маркировки',
    taskTypeId: 'be2aee10-8b54-43de-9956-098fce8cee5f',
  },
  ACS_DOCUMENTS: {
    systemName: 'Документы по агентско-комиссионной схеме',
    taskTypeId: 'd08e2ea4-5ddb-4955-a3a9-c5b30d2cdc52',
  },
  CIS_ACS: {
    systemName: 'Движение кодов по агентско-комиссионной схеме',
    taskTypeId: '21cace7c-9981-4ac0-b1b9-be89115845e2',
  },
  CIS_UNLOADES: {
    systemName: 'Список кодов идентификации по фильтрам',
    taskTypeId: '3eef0554-88c6-4e6c-8876-e9b53fd30d0b',
  },
};

export enum TaskTypeStatus {
  API = 'API',
  API_FRONT = 'API_FRONT',
  DISABLED = 'DISABLED',
}

export enum Period {
  HALF_MIN = 'HALF_MIN',
  TEN_MINUTES = 'TEN_MINUTES',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

export const periodTitle: Record<Period, string> = {
  [Period.HALF_MIN]: 'Каждые 30 секунд',
  [Period.TEN_MINUTES]: 'Каждые 10 минут',
  [Period.DAY]: 'Каждый день',
  [Period.WEEK]: 'Каждую неделю',
  [Period.MONTH]: 'Каждый месяц',
  [Period.QUARTER]: 'Каждый квартал',
  [Period.YEAR]: 'Каждый год',
};

interface PeriodShortTitle {
  addLabel: string;
  moveLabel: string;
}

export const periodShortTitle: Record<Period, PeriodShortTitle> = {
  [Period.HALF_MIN]: {
    addLabel: 'новые 30 секунд',
    moveLabel: '30 секунд',
  },
  [Period.TEN_MINUTES]: {
    addLabel: 'новые 10 минут',
    moveLabel: '10 минут',
  },
  [Period.DAY]: {
    addLabel: 'новый день',
    moveLabel: 'день',
  },
  [Period.WEEK]: {
    addLabel: 'новая неделя',
    moveLabel: 'неделя',
  },
  [Period.MONTH]: {
    addLabel: 'новый месяц',
    moveLabel: 'месяц',
  },
  [Period.QUARTER]: {
    addLabel: 'новый квартал',
    moveLabel: 'квартал',
  },
  [Period.YEAR]: {
    addLabel: 'новый год',
    moveLabel: 'год',
  },
};

export const periodSelect = Object.entries(periodTitle).map((item, index) => ({
  key: index,
  value: item[0],
  label: item[1],
}));

export enum Status {
  ARCHIVE = 'ARCHIVE',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PREPARATION = 'PREPARATION',
  PARTIALLY_COMPLETED = 'PARTIALLY_COMPLETED',
}

export const statusColor: Record<Status, StatusProps['color']> = {
  [Status.ARCHIVE]: 'grey',
  [Status.CANCELED]: 'secondary',
  [Status.COMPLETED]: 'success',
  [Status.FAILED]: 'error',
  [Status.PREPARATION]: 'warning',
  [Status.PARTIALLY_COMPLETED]: 'success',
};

export const statusColorTheme: Record<Status, string> = {
  [Status.ARCHIVE]: theme.palette.text.disabled,
  [Status.CANCELED]: theme.palette.text.secondary,
  [Status.COMPLETED]: theme.palette.success.main,
  [Status.FAILED]: theme.palette.error.main,
  [Status.PREPARATION]: theme.palette.warning.main,
  [Status.PARTIALLY_COMPLETED]: theme.palette.success.main,
};

export const statusTitle: Record<Status, string> = {
  [Status.ARCHIVE]: 'Архив',
  [Status.CANCELED]: 'Отменено',
  [Status.COMPLETED]: 'Выполнено',
  [Status.FAILED]: 'Ошибка',
  [Status.PREPARATION]: 'Подготовка',
  [Status.PARTIALLY_COMPLETED]: 'Выполнено частично',
};

export const statusIcon: Record<Status, string> = {
  [Status.ARCHIVE]: 'Archive',
  [Status.CANCELED]: 'Cancel',
  [Status.COMPLETED]: 'Success',
  [Status.FAILED]: 'Error',
  [Status.PREPARATION]: 'Refresh',
  [Status.PARTIALLY_COMPLETED]: 'Success',
};

export const statusSelect = chain(statusTitle)
  .map((label, value) => ({
    label: label,
    value: value,
  }))
  .value();

export enum DownloadStatus {
  SUCCESS = 'SUCCESS',
  PREPARATION = 'PREPARATION',
  FAILED = 'FAILED',
}

export const downloadStatusTitle: Record<DownloadStatus, string> = {
  [DownloadStatus.SUCCESS]: 'Успешно',
  [DownloadStatus.PREPARATION]: 'Подготовка',
  [DownloadStatus.FAILED]: 'Не успешно',
};

export const downloadStatusIcon: Record<DownloadStatus, string> = {
  [DownloadStatus.SUCCESS]: 'Success',
  [DownloadStatus.PREPARATION]: 'Time',
  [DownloadStatus.FAILED]: 'Information',
};

export const downloadStatusColor: Record<DownloadStatus, string> = {
  [DownloadStatus.SUCCESS]: theme.palette.success.main,
  [DownloadStatus.PREPARATION]: theme.palette.text.secondary,
  [DownloadStatus.FAILED]: theme.palette.error.main,
};

export enum Available {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export const availableTitle: Record<Available, string> = {
  [Available.AVAILABLE]: 'Да',
  [Available.NOT_AVAILABLE]: 'Нет',
};

export enum RelativeType {
  MOVE_BY_PERIOD = 'MOVE_BY_PERIOD',
  ADD_PERIOD = 'ADD_PERIOD',
}

export const relativeTypeTitle: Record<RelativeType, string> = {
  [RelativeType.MOVE_BY_PERIOD]: 'Сдвинуть на период',
  [RelativeType.ADD_PERIOD]: 'Добавить период',
};

export const hardcodeRoles = [
  { code: 'OGV', name: 'ФОИВ' },
  { code: 'IS_MP_OPERATOR', name: 'Оператор' },
  { code: 'PRODUCER', name: 'Производитель' },
  { code: 'IMPORTER', name: 'Импортер товара' },
  { code: 'WHOLESALER', name: 'Оптовая торговля' },
  { code: 'RETAIL', name: 'Розничная торговля' },
];

export enum ReportParams {
  SERVICE_IS_RELATIVE = '1302_Service_isRelative',
  SERVICE_RELATIVE_TYPE = '1303_Service_Relative_Type',
  RUSSIAN_COLUMNS = '1304_Russian_columns',
}

export enum ServiceRelativeType {
  MOVE = 'Move_by_period',
  ADD = 'Add_period',
  ALL = 'Add_period_Move_by_period',
}

export enum TypeOfMovement {
  SENT = 'SENT',
  RECEIVED = 'RECEIVED'
}

export const startDay = { hour: 0, minute: 0, second: 0 };
export const endDay = { hour: 23, minute: 59, second: 59 };

export const isoStringDateFormat = 'YYYY-MM-DD';
export const isoStringDateTimeFormat = 'YYYY-MM-DDTHH:mm:ssZ';
export const russianDateFormat = 'DD.MM.YYYY';
export const russianDateTimeFormat = 'DD.MM.YYYY в HH:mm';
