import React from 'react';

import PropTypes from 'prop-types';

import { Route as ReactRoute } from '@crpt/shared/components/Route/Route';

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';

export const Route = ({ component: Component, render, ...rest }) => {
  return (
    <ReactRoute
      {...rest}
      render={(routeProps) => (
        <ErrorBoundary>
          {Component ? <Component {...routeProps} /> : render(routeProps)}
        </ErrorBoundary>
      )}
    />
  );
};

Route.propTypes = {
  component: PropTypes.element,
  render: PropTypes.func,
};
