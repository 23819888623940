import { createActions } from 'redux-actions';

/**
 * Actions for document sign
 */
export const { startDocumentSignSaga, setSigningProcess } = createActions(
  {
    START_DOCUMENT_SIGN_SAGA: (payload) => payload,

    SET_SIGNING_PROCESS: (payload) => payload,
  },
  {
    prefix: 'DOCUMENT_SIGN',
  }
);
