import React, { useEffect, useMemo } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { HistoryType } from '../../../constants/PropTypes';
import { MatchType } from '../../../common_components/Document/DocumentType';
import * as preloaderSelectors from '../../../common_components/Preloader/ducks/Preloader.selectors';

import {
  clearAgreementDocument,
  startAgreementDocumentSaga,
} from './ducks/AgreementDocument.actions';
import {
  getAgreementDocumentContent,
  getAgreementDocumentInfo,
  getDocumentType,
} from './ducks/AgreementDocument.selectors';
import { AgreementDocumentView } from './AgreementDocument.view';
import { DocumentTitlesMap } from './AgreementDocument.constants';

export const AgreementDocument = ({
  startAgreementDocumentSaga,
  clearAgreementDocument,
  documentContent,
  documentInfo,
  documentType,
  isLoading,
  match,
  history,
}) => {
  const {
    params: { id: documentId },
  } = match;

  useEffect(() => {
    startAgreementDocumentSaga({
      id: documentId,
      pathname: history.location.pathname,
    });

    return () => {
      clearAgreementDocument();
    };
  }, [
    clearAgreementDocument,
    startAgreementDocumentSaga,
    documentId,
    history.location.pathname,
  ]);

  const documentTitle = useMemo(
    () =>
      `${DocumentTitlesMap[documentType]} ${documentInfo.documentRegNumber}`,
    [documentInfo, documentType]
  );

  const isLoadedPdf = useMemo(
    () => Boolean(get(documentContent, 'byteLength', false)),
    [documentContent]
  );

  return React.createElement(AgreementDocumentView, {
    documentContent,
    documentInfo,
    documentTitle,
    isLoading,
    isLoadedPdf,
  });
};

const mapStateToProps = (state) => ({
  documentContent: getAgreementDocumentContent(state),
  documentInfo: getAgreementDocumentInfo(state),
  documentType: getDocumentType(state),
  isLoading: preloaderSelectors.loading(state),
});

const mapDispatchToProps = {
  startAgreementDocumentSaga,
  clearAgreementDocument,
};

AgreementDocument.propTypes = {
  match: MatchType,
  history: HistoryType,
  startAgreementDocumentSaga: PropTypes.func.isRequired,
  clearAgreementDocument: PropTypes.func.isRequired,
  documentContent: PropTypes.object.isRequired,
  documentInfo: PropTypes.object.isRequired,
  documentType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export const AgreementDocumentConnected = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(AgreementDocument);
