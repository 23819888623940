import React, { memo } from 'react';

import Status from './Status';
import { Button, Icon } from '@crpt-ui/core';
import { Grid } from '@crpt/material';
import DocumentUnloadErrorsViewModel from './DocumentUnloadErrorsViewModel';
import { DocumentStatus } from '../../constants';

interface DocumentUnloadErrorsProps {
  documentId: string;
  documentStatus: DocumentStatus;
  productGroupId: number;
}

const DocumentUnloadErrors: React.FC<DocumentUnloadErrorsProps> = ({
  documentId,
  documentStatus,
  productGroupId,
}) => {
  const {
    viewStatus,
    reportStatus,
    documentHasErrors,
    createErrorsTask,
    fetchReportFile,
    taskStatus,
  } = DocumentUnloadErrorsViewModel({
    documentId,
    documentStatus,
    productGroupId,
  });

  return documentHasErrors && documentId ? (
    <Grid
      alignItems="center"
      columnSpacing={2}
      container
      rowSpacing={2}
      sx={{
        alignItems: 'center',
      }}
    >
      {viewStatus.reportHasStatusText && (
        <Grid item>
          <Status status={reportStatus.data?.status} />
        </Grid>
      )}

      {viewStatus.reportIsNotExist && (
        <Grid item>
          <Button
            color="secondary"
            icon={<Icon name="Add" />}
            loading={taskStatus.loading || reportStatus.loading}
            onClick={createErrorsTask}
            size="sm"
            variant="text"
          >
            Сформировать&nbsp;список&nbsp;ошибок
          </Button>
        </Grid>
      )}

      {viewStatus.reportIsFailedDownload && (
        <Grid item>
          <Button
            color="secondary"
            icon={<Icon name="Refresh" />}
            onClick={createErrorsTask}
            size="sm"
            variant="text"
          >
            Повторить
          </Button>
        </Grid>
      )}

      {viewStatus.reportIsReady && (
        <Grid item>
          <Button
            color="secondary"
            icon={<Icon name="Download" />}
            onClick={fetchReportFile}
            size="sm"
            variant="text"
          >
            Скачать&nbsp;список&nbsp;ошибок
          </Button>
        </Grid>
      )}
    </Grid>
  ) : null;
};

export default memo(DocumentUnloadErrors);
