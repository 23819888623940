import { ProductGroupEnum } from './ProductGroupEnum';

export const ProductGroupNamesMap = Object.freeze({
  [ProductGroupEnum.ALCOHOL]: 'Алкоголь',
  [ProductGroupEnum.BEER]: 'Пиво и пивные напитки',
  [ProductGroupEnum.BICYCLE]: 'Велосипеды и велосипедные рамы',
  [ProductGroupEnum.ELECTRONICS]:
    'Фотокамеры (кроме кинокамер), фотовспышки и лампы-вспышки',
  [ProductGroupEnum.FURS]: 'Товары из натурального меха',
  [ProductGroupEnum.LP]:
    'Предметы одежды, белье постельное, столовое, туалетное и кухонное',
  [ProductGroupEnum.MILK]: 'Молочная продукция',
  [ProductGroupEnum.NCP]: 'Никотиносодержащая продукция',
  [ProductGroupEnum.OTP]: 'Альтернативная табачная продукция',
  [ProductGroupEnum.PERFUMERY]: 'Духи и туалетная вода',
  [ProductGroupEnum.SHOES]: 'Обувные товары',
  [ProductGroupEnum.TIRES]: 'Шины и покрышки пневматические резиновые новые',
  [ProductGroupEnum.TOBACCO]: 'Табачная продукция',
  [ProductGroupEnum.WATER]: 'Упакованная питьевая вода',
  [ProductGroupEnum.WHEELCHAIRS]: 'Кресла-коляски',
  [ProductGroupEnum.LAPTOPS]: 'Ноутбуки',
  [ProductGroupEnum.TELEPHONES]: 'Телефонные аппараты',
  [ProductGroupEnum.CONSERVE]: 'Консервированная продукция',
  [ProductGroupEnum.CHEMISTRY]:
    'Парфюмерные и косметические средства и бытовая химия',
  [ProductGroupEnum.SOFTDRINKS]: 'Соковая продукция и безалкогольные напитки',
});
