import { handleActions } from 'redux-actions';

import {
  closeSignModal,
  openSignModal,
  setSigningProcess,
} from './DocumentSigningModal.actions';

const open = handleActions(
  {
    [openSignModal]: () => true,
    [closeSignModal]: () => false,
  },
  false
);

const additionalInformation = handleActions(
  {
    [openSignModal]: (_, { payload }) => payload,
    [closeSignModal]: () => null,
  },
  null
);

const isSigningInProcess = handleActions(
  {
    [setSigningProcess]: (_, { payload }) => payload,
    [closeSignModal]: () => false,
  },
  false
);

export const reducer = {
  open,
  isSigningInProcess,
  additionalInformation,
};
