import React from 'react';

import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Button, Icon } from '@crpt-ui/core';

import { ZoomStyles } from './Zoom.styles';

const ZoomComp = ({ classes, onZoomIn, onZoomOut, size }) => (
  <div className={classes.root}>
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Button
          icon={<Icon name="Plus" />}
          size={size}
          style={{ background: '#f7f7f7' }}
          variant="outlined"
          onClick={onZoomIn}
        />
      </Grid>
      <Grid item>
        <Button
          icon={<Icon name="Minus" />}
          size={size}
          style={{ background: '#f7f7f7' }}
          variant="outlined"
          onClick={onZoomOut}
        />
      </Grid>
    </Grid>
  </div>
);

ZoomComp.propTypes = {
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
};

export const Zoom = withStyles(ZoomStyles)(ZoomComp);
