import { styled } from '@mui/material';

export interface ContentProps {
  fullHeight?: boolean;
}

const Content = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullHeight',
})(({ fullHeight }: ContentProps) => ({
  backgroundColor: '#fff',
  borderRadius: 4,
  boxShadow: '0px 1px 8px rgba(41, 49, 53, 0.05)',
  overflow: 'hidden',
  position: 'relative',
  width: '100%',

  ...(fullHeight && {
    height: '100%',
  }),
}));

Content.defaultProps = {
  fullHeight: true,
};

export default Content;
