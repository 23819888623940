import { call, put, select, takeLatest } from 'redux-saga/effects';
import { chain } from 'lodash';

import { HttpMethodEnum } from '../../../constants/index';
import { Api } from '../../../common_components/Api/Api';
import { getCompanyId } from '../../_Profile/Company/ducks/Company.selectors';

import {
  fetchDocsForNotification,
  sendDocsForNotification,
  setDocsForNotification,
  setLoading,
  setSubmitting,
} from './Settings.actions';

function* sendDocsForNotificationSaga({ payload }) {
  yield put(setSubmitting(true));

  const id = yield select(getCompanyId);

  /**
   * Returns all object keys that have the value true
   * @type {[string]}
   */
  const data = chain(payload).pickBy().keys().value();

  const [success] = yield call(
    Api.request,
    {
      url: `/api/v3/facade/profile/docs-for-notification/${id}`,
      method: HttpMethodEnum.PUT,
      data,
    },
    { successMessage: 'Изменения успешно сохранены' }
  );

  if (success) {
    yield call(fetchDocsForNotificationSaga, { withoutLoading: true });
  }

  yield put(setSubmitting(false));
}

function* fetchDocsForNotificationSaga({ withoutLoading = false }) {
  if (!withoutLoading) {
    yield put(setLoading(true));
  }

  const id = yield select(getCompanyId);

  const [success] = yield call(Api.request, {
    url: `/api/v3/facade/profile/docs-for-notification/${id}`,
  });

  if (success) {
    const result = {};

    for (const key of success.data) {
      result[key] = true;
    }

    yield put(setDocsForNotification(result));
  }

  yield put(setLoading(false));
}

export const saga = function* watch() {
  yield takeLatest(fetchDocsForNotification, fetchDocsForNotificationSaga);
  yield takeLatest(sendDocsForNotification, sendDocsForNotificationSaga);
};
