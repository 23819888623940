import { handleActions } from 'redux-actions';

import * as actions from './Sidebar.actions';

const content = handleActions(
  {
    [actions.open]: (_state, { payload }) => payload,
    [actions.close]: () => null,
  },
  null
);

const active = handleActions(
  {
    [actions.open]: () => true,
    [actions.close]: () => false,
  },
  false
);

export const reducer = {
  content,
  active,
};
