export const AUTHORITY_OPTIONS = [
  {
    label: '1 – лицо, совершившее сделку, операцию',
    value: 1,
  },
  {
    label:
      '2 – лицо, совершившее сделку, операцию и ответственное за ее оформление',
    value: 2,
  },
  {
    label: '3 – лицо, ответственное за оформление свершившегося события',
    value: 3,
  },
];

export const AUTHORITY_UKD_VALUES = [
  {
    label: '0 — лицо, ответственное за подписание счетов-фактур',
    value: 0,
  },
  {
    label: '3 — лицо, ответственное за оформление свершившегося события',
    value: 2,
  },
  {
    label:
      '6 — лицо, ответственное за оформление свершившегося события и за подписание счетов-фактур',
    value: 6,
  },
  {
    label: '29 — лицо с иными полномочиями',
    value: 29,
  },
];

export const STATUS_VALUES = [
  {
    label: '1 - работник организации покупателя',
    value: 1,
  },
  {
    label: '2 - работник организации - составителя информации покупателя',
    value: 2,
  },
  {
    label: '3 – работник иной уполномоченной организации',
    value: 3,
  },
  {
    label:
      '4 – уполномоченное физическое лицо, в том числе индивидуальный предприниматель',
    value: 4,
  },
];

export const STATUS_UKD_VALUES = [
  {
    label: '3 — работник организации покупателя',
    value: 3,
  },
  {
    label:
      '4 — уполномоченное физическое лицо, в том числе индивидуальный предприниматель',
    value: 4,
  },
  {
    label: '5 — работник организации - покупателя',
    value: 5,
  },
  {
    label: '6 — работник организации - составителя информации покупателя',
    value: 6,
  },
];

export const UPD820_STATUS_VALUES = [
  {
    label: '3 — работник иной уполномоченной организации',
    value: 3,
  },
  {
    label:
      '4 — уполномоченное физическое лицо, в том числе индивидуальный предприниматель',
    value: 4,
  },
  {
    label: '5 — работник организации - покупателя',
    value: 5,
  },
  {
    label:
      '6 — работник организации - составителя файла обмена информации покупателя, если составитель файла обмена информации покупателя не является покупателем',
    value: 6,
  },
];

export const CONTENT_CODE_VALUES = [
  {
    label:
      '1 — товары (работы, услуги, права) приняты без расхождений (претензий)',
    value: 1,
  },
  {
    label:
      '2 — товары (работы, услуги, права) приняты с расхождениями (претензией)',
    value: 2,
  },
  {
    label: '3 — товары (работы, услуги, права) не приняты',
    value: 3,
  },
];

export const CONTENT_CODE_KIND_VALUES = [
  {
    label: '2 — документ о приемке с расхождениями',
    value: 2,
  },
  {
    label: '3 — документ о расхождениях',
    value: 3,
  },
];
