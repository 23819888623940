import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

const getLocalState = (state) =>
  get(state, 'ParticipantDetails.components.receipts', {});

export const loaded = createSelector(getLocalState, (state) => state.loaded);
export const lastSelector = createSelector(
  getLocalState,
  (state) => state.last
);
export const resultsSelector = createSelector(
  getLocalState,
  (state) => state.results
);
export const currentPageSelector = createSelector(
  getLocalState,
  (state) => state.currentPage
);
export const savedFiltersSelector = createSelector(
  getLocalState,
  (state) => state.savedFilters
);
export const historyFiltersSelector = createSelector(
  getLocalState,
  (state) => state.historyFilters
);
