import { yup } from '@crpt-ui/form';
import moment from 'moment';
import { commonErrorMessages } from '../../constants';
import {
  MAX_DATE_PERIOD_DAYS,
  MAX_YEARS_AGO,
  validateStringRegExp,
} from './CisUnloads.constants';
import { FieldIds } from './CisUnloads.types';
import { testStasus } from './CisUnloads.utils';

const dates = (start: string, end: string) => ({
  [start]: yup
    .date()
    .typeError(commonErrorMessages.DATE_TYPE)
    .max(moment().endOf('day').toDate(), commonErrorMessages.MAX_DATE)
    .min(
      moment().subtract(MAX_YEARS_AGO, 'years').startOf('day').toDate(),
      commonErrorMessages.MIN_DATE
    )
    .when(end, (date, schema) => {
      if (date && moment(date).isValid()) {
        const absoluteMinDate = 
        moment().subtract(MAX_YEARS_AGO, 'years').startOf('day').toDate();
        const absoluteMaxDate = moment().endOf('day').toDate();
        if (moment(date).isBetween(absoluteMinDate, absoluteMaxDate)) {
          const relativeMinDate = moment(date)
            .subtract(MAX_DATE_PERIOD_DAYS, 'days')
            .startOf('day')
            .toDate();

          const minDate =
            relativeMinDate < absoluteMinDate
              ? absoluteMinDate
              : relativeMinDate;

          return schema
            .min(minDate, commonErrorMessages.MIN_DATE)
            .max(
              moment(date).endOf('day').toDate(),
              commonErrorMessages.MAX_DATE
            );
        }
      }
    }),
  [end]: yup
    .date()
    .typeError(commonErrorMessages.DATE_TYPE)
    .max(moment().endOf('day').toDate(), commonErrorMessages.MAX_DATE)
    .min(
      moment().subtract(MAX_YEARS_AGO, 'years').startOf('day').toDate(),
      commonErrorMessages.MIN_DATE
    )
    .when(start, (date, schema) => {
      if (date && moment(date).isValid()) {
        const absoluteMinDate = 
        moment().subtract(MAX_YEARS_AGO, 'years').startOf('day').toDate();
        const absoluteMaxDate = moment().endOf('day').toDate();
        if (moment(date).isBetween(absoluteMinDate, absoluteMaxDate)) {
          const relativeMaxDate = moment(date)
            .add(MAX_DATE_PERIOD_DAYS, 'days')
            .endOf('day')
            .toDate();
          const maxDate =
            relativeMaxDate > absoluteMaxDate
              ? absoluteMaxDate
              : relativeMaxDate;

          return schema
            .min(
              moment(date).startOf('day').toDate(),
              commonErrorMessages.MIN_DATE
            )
            .max(maxDate, commonErrorMessages.MAX_DATE);
        }
      }
    }),
});

export default yup.object().shape(
  {
    [FieldIds.NAME]: yup
      .string()
      .trim()
      .min(10, commonErrorMessages.MIN_LENGTH)
      .max(255, commonErrorMessages.MAX_LENGTH)
      .required(commonErrorMessages.REQUIRED),
    ...dates(FieldIds.EMISSION_PERIOD_START, FieldIds.EMISSION_PERIOD_END),
    ...dates(FieldIds.APPLIED_PERIOD_START, FieldIds.APPLIED_PERIOD_END),
    ...dates(FieldIds.PRODUCTION_PERIOD_START, FieldIds.PRODUCTION_PERIOD_END),
    [FieldIds.PACKAGE_TYPE]: yup
      .array(yup.string())
      .required(commonErrorMessages.REQUIRED),
    [FieldIds.STATUS]: yup
      .string()
      .required(commonErrorMessages.REQUIRED)
      .test({
        name: 'checkAvaivablePackageType',
        test: testStasus,
      }),
    [FieldIds.INCLUDE_GTIN]: yup.array(yup.string()),
    [FieldIds.EXCLUDE_GTIN]: yup.array(yup.string()),
    [FieldIds.USERS]: yup.array(yup.string()),
    [FieldIds.PARTICIPANT_INN]: yup
      .string()
      .required(commonErrorMessages.REQUIRED)
      .matches(validateStringRegExp.inn, commonErrorMessages.INN),
  },
  [
    [FieldIds.EMISSION_PERIOD_START, FieldIds.EMISSION_PERIOD_END],
    [FieldIds.APPLIED_PERIOD_START, FieldIds.APPLIED_PERIOD_END],
    [FieldIds.PRODUCTION_PERIOD_START, FieldIds.PRODUCTION_PERIOD_END],
  ]
);
