import React, { useCallback, useEffect, useMemo } from 'react';

import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';

import { createForm } from '@crpt-ui/form';
import { Button, RequiredFieldsWarning } from '@crpt-ui/core';

import { Modal } from '../../../../common_components/Modal/Modal';
import { Input } from '../../../../common_components/Form/Input';
import { Textarea } from '../../../../common_components/Form/Textarea';
import { FormType } from '../../../../constants/PropTypes';

import { sendIncident } from './ducks/SupportModal.actions';
import { schema } from './SupportModal.schema';

export const SupportModalComponent = ({
  sendIncident,
  onCloseSupportModal,
  isOpenSupportModal,
}) => {
  useEffect(() => {
    form.reset();
  }, [isOpenSupportModal]);

  const onSubmit = useCallback((values) => {
    sendIncident(values);
    handleClose();
  }, []);

  const form = useMemo(
    () =>
      createForm({
        schema,
        subscribeFields: [],
        onSubmit,
      }),
    []
  );

  const handleClose = useCallback(() => {
    onCloseSupportModal();
  }, []);

  return (
    <Modal
      open={isOpenSupportModal}
      title="Обращение в&nbsp;техподдержку"
      transitionDuration={0}
      onClose={handleClose}
    >
      <Form form={form}>
        {({ hasValidationErrors, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box my={3}>
              <RequiredFieldsWarning error={hasValidationErrors} />
            </Box>
            <Box my={2}>
              <Input label="Тема обращения" name="caption" />
            </Box>
            <Box my={2}>
              <Textarea label="Описание проблемы" name="description" rows={5} />
            </Box>
            <Box mt={4}>
              <Grid container spacing={3}>
                <Grid item>
                  <Button
                    className="whiteColor"
                    disabled={hasValidationErrors}
                    size="lg"
                    type="submit"
                  >
                    Добавить
                  </Button>
                </Grid>
                <Grid item>
                  <Button size="lg" variant="outlined" onClick={handleClose}>
                    Отменить
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
      </Form>
    </Modal>
  );
};

SupportModalComponent.propTypes = {
  formValues: PropTypes.shape({}),
  form: FormType,
  isFormErrors: PropTypes.bool,
  isOpenSupportModal: PropTypes.bool,
  onCloseSupportModal: PropTypes.func,
  sendIncident: PropTypes.func,
};

const mapDispatchToProps = {
  sendIncident,
};

export const SupportModal = connect(
  null,
  mapDispatchToProps
)(SupportModalComponent);
