// import { EDOConnected } from './Tabs/EDO/EDO';
// import { GS1Connected } from './Tabs/GS1/GS1';
import { ProductGroupConnected } from './Tabs/ProductGroup/ProductGroup';
import { UserGroup } from './Tabs/UserGroup/UserGroup';

/**
 * Start page tabs
 */
export const StartPageTabs = [
  {
    name: 'USER_GROUP',
    component: UserGroup,
  },
  // {
  //   name: 'EDO',
  //   component: EDOConnected,
  // },
  {
    name: 'PRODUCT_GROUP',
    component: ProductGroupConnected,
  },
  // {
  //   name: 'GS1',
  //   component: GS1Connected,
  // },
];

/**
 * GS1 step index
 * @type {number} - index GS1 step
 */
export const GS1StepIndex = StartPageTabs.findIndex(
  (step) => step.name === 'GS1'
);

/**
 * User group step index
 * @type {number} - User group step index
 */
export const UserGroupStepIndex = StartPageTabs.findIndex(
  (step) => step.name === 'USER_GROUP'
);
