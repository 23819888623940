import React from 'react';

import PropTypes from 'prop-types';

import Datatable from '@crpt-ui/datatable';
import { NoInfo } from '@crpt/shared/components';
import { Typography } from '@crpt-ui/core';

import { Loader } from '../../../common_components/Preloader/Preloader';
import { ContentWrapper } from '../../../common_components/styled';
import { ServiceDeskDocumentSignModalConnected } from '../ServiceDeskDocument/ServiceDeskDocumentSignModal/ServiceDeskDocumentSignModal';

const DocumentsListViewComp = ({
  onRowClick,
  columns,
  data,
  datatableActions,
  isLoading,
}) => (
  <ContentWrapper
    style={{
      padding: 0,
      height: 'calc(100% - 70px)',
    }}
  >
    {isLoading ? (
      <Loader />
    ) : data.length ? (
      <>
        <Datatable
          useFilters
          useSort
          actions={datatableActions}
          columns={columns}
          data={data}
          manualFilters={false}
          manualSorting={false}
          onRowClick={onRowClick}
        />

        <ServiceDeskDocumentSignModalConnected />
      </>
    ) : (
      <NoInfo>
        <Typography variant="h5">У&nbsp;вас еще нет документов</Typography>
      </NoInfo>
    )}
  </ContentWrapper>
);

DocumentsListViewComp.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  datatableActions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
};

export const DocumentsListView = React.memo(DocumentsListViewComp);
