import React, { useCallback, useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { openSignModal } from '../../../common_components/DocumentSigningModal/ducks/DocumentSigningModal.actions';
import * as preloaderSelectors from '../../../common_components/Preloader/ducks/Preloader.selectors';
import {
  downloadDocument,
  downloadDocumentWorkflow,
} from '../../../common_components/Document/ducks';
import {
  API_ENVIRONMENT,
  definitionEnvironment,
} from '../ServiceDeskDocument/ServiceDeskDocument.utils';

import { DocumentsListView } from './DocumentsList.view';
import * as documentListActions from './ducks/DocumentsList.actions';
import * as documentListSelectors from './ducks/DocumentsList.selectors';
import { useColumns } from './DocumentsList.constants';

const DocumentList = ({
  downloadDocumentWorkflow,
  downloadDocument,
  onMount,
  openSignModal,
  data,
  history,
  isLoading,
}) => {
  const fetchDocumentList = useCallback(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    fetchDocumentList();
  }, [fetchDocumentList]);

  const onRowClick = useCallback(
    (_, row) => {
      const documentId = row.original.guid;

      history.push(`/documents/view/${documentId}`);
    },
    [history]
  );

  const columns = useColumns({
    onRowClick,
  });

  const datatableActions = useMemo(() => {
    return [
      {
        label: 'Скачать',
        callback: ({ original }) => {
          const { document_direction } = definitionEnvironment(original);

          downloadDocumentWorkflow({
            useFileQueue: true,
            id: original.edoDocumentId,
            name: original.docid,
            direction: document_direction.split('-')[0],
          });
        },
        disabled: ({ original }) => !original.edoDocumentId,
      },
      {
        label: 'Печать',
        callback: ({ original }) => {
          const { document_direction, document_source } =
            definitionEnvironment(original);
          const hasEdo =
            document_source === API_ENVIRONMENT.EDO &&
            Boolean(original.edoDocumentId);
          const id = hasEdo ? original.edoDocumentId : original.document;

          downloadDocument({
            useFileQueue: true,
            hasEdo,
            id,
            fileSize: original.fileSize,
            name: original.name,
            direction: document_direction.split('-')[0],
          });
        },
      },
      {
        label: 'Подписать',
        callback: ({ original }) => {
          openSignModal({
            guid: original.guid,
            onSuccess: fetchDocumentList,
          });
        },
        disabled: ({ original }) => original.status !== 1,
      },
    ];
  }, [
    downloadDocumentWorkflow,
    downloadDocument,
    openSignModal,
    fetchDocumentList,
  ]);

  return React.createElement(DocumentsListView, {
    onRowClick,
    data,
    columns,
    datatableActions,
    isLoading,
  });
};

DocumentList.propTypes = {
  onMount: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool,
  downloadDocument: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  data: documentListSelectors.data(state),
  isLoading: preloaderSelectors.loading(state),
});

const mapDispatchToProps = {
  onMount: documentListActions.mount,
  openSignModal,
  downloadDocumentWorkflow,
  downloadDocument,
};

export const DocumentListConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentList);
