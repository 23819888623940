import { Checkbox } from '@crpt-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

export const ToggleRowSelectedCell = ({ row }) => (
  <Checkbox
    {...row.getToggleRowSelectedProps({
      title: '',
      indeterminate: row.state && row.state.indeterminate,
      disabled: row.state && row.state.disabled,
      style: {
        marginLeft: -8,
      },
    })}
  />
);

export const makeToggleRowSelectedCell = () => (props) =>
  React.createElement(ToggleRowSelectedCell, props);

ToggleRowSelectedCell.propTypes = {
  row: PropTypes.shape({
    getToggleRowSelectedProps: PropTypes.func,
    state: PropTypes.object,
  }).isRequired,
};

export default ToggleRowSelectedCell;
