import { createSelector } from 'reselect';

import {
  AgreementDocumentStatusMap,
  DocumentTypesMap,
} from '../AgreementDocument.constants';

export const getAgreementDocument = (state) =>
  state.Documents.AgreementDocument;

export const getDocumentType = createSelector(
  [getAgreementDocument],
  (document) => document.pageType
);

export const getAgreementDocumentInfo = createSelector(
  [getAgreementDocument],
  (document) => document.info
);

export const getAgreementDocumentContent = createSelector(
  [getAgreementDocument],
  (document) => document.content
);

export const isCanSignDocument = createSelector(
  [getAgreementDocumentInfo],
  (documentInfo) =>
    documentInfo.statusCode === AgreementDocumentStatusMap.ESTABLISHED
);

export const isSignedDocument = createSelector(
  [getAgreementDocumentInfo],
  (documentInfo) =>
    documentInfo.statusCode === AgreementDocumentStatusMap.SIGNED
);

export const isAgreementDocumentType = createSelector(
  [getDocumentType],
  (documentType) => documentType === DocumentTypesMap.AGREEMENT
);

export const getAgreementDocumentId = createSelector(
  [getAgreementDocumentInfo],
  (documentInfo) => documentInfo.id
);
