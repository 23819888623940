import React from 'react';

import { Box } from '@material-ui/core';

import { PDFViewer } from '@crpt/shared/components';

import { Preloader } from '../../../common_components/Preloader/Preloader';

import { ServiceDeskDocumentSignModalConnected } from './ServiceDeskDocumentSignModal/ServiceDeskDocumentSignModal';
import { TopPanelConnected } from './TopPanel/TopPanel';

export const ServiceDeskDocumentView = ({
  PDFViewerProps,
  documentId,
  isLoading,
}) => {
  if (isLoading) {
    return <Preloader isOpen />;
  }

  return (
    <>
      <ServiceDeskDocumentSignModalConnected />

      <Box pb={2}>
        <TopPanelConnected documentId={documentId} />
      </Box>

      <PDFViewer {...PDFViewerProps} />
    </>
  );
};
