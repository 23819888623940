import Crypto, { Base64 } from '@crpt-ui/crypto';
import { toast } from '@crpt/shared/toast';

import { HttpMethodEnum } from '../constants/index';
import { CadesUtil } from '../utils/CadesUtil';

import { instance } from './instance';

// import axios from 'axios';

export class AuthService {
  static login(data) {
    return instance.request({
      timeout: 60000,
      url: '/api/v3/auth/login',
      method: HttpMethodEnum.POST,
      data: {
        ...data,
      },
    });
  }

  static getCertKey() {
    return instance
      .request({
        timeout: 60000,
        url: '/api/v3/auth/cert/key',
        headers: {
          Authorization: null,
          'Cache-Control': 'no-cache',
        },
      })
      .catch((error) => error.response);
  }

  static authEKENG(inn) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open(
        'POST',
        'https://eid.ekeng.am/authorize?token=57aefd14-a122-3466-88b1-8b73ba7675e5&opaque=6d7c9598f577394aa6f550a923bf567a',
        true
      );
      xhr.setRequestHeader('Origin', 'eid.ekeng.am');
      xhr.send();

      xhr.onreadystatechange = function () {
        if (xhr.status === 0) {
          return reject('connect error');
        }
        if (xhr.status === 200) {
          instance
            .request({
              timeout: 60000,
              url: '/api/v3/auth/login/eid',
              method: HttpMethodEnum.POST,
              headers: {
                Authorization: null,
              },
              data: {
                inn,
                encoded_data: JSON.parse(xhr.responseText).data,
              },
            })
            .then((answer) => {
              return resolve(answer);
            })
            .catch((err) => {
              return resolve(err);
            });
        } else {
          return reject(JSON.parse(xhr.responseText));
        }
      };
    });
  }

  static saveOivUserCertificate(params) {
    return instance.request({
      timeout: 60000,
      url: '/api/v3/org/registration/oiv/certificate',
      method: 'post',
      headers: {
        'Cache-Control': 'no-cache',
      },
      params,
    });
  }

  static checkToken(params) {
    return instance.request({
      timeout: 60000,
      url: '/api/v3/org/registration/tokeninfo',
      method: 'get',
      headers: {
        'Cache-Control': 'no-cache',
      },
      params,
    });
  }

  static loginCertParticipant(cert, isOivUser) {
    return new Promise((resolve) => {
      AuthService.getCertKey()
        .then((certAnswer) => {
          const { uuid, data } = certAnswer.data;

          Crypto.sign(Base64.encode(data), cert)
            .then((signed) => {
              instance
                .request({
                  timeout: 60000,
                  url: isOivUser
                    ? '/api/v3/auth/cert/oiv'
                    : '/api/v3/auth/cert/',
                  method: HttpMethodEnum.POST,
                  headers: {
                    Authorization: null,
                  },
                  data: {
                    uuid,
                    data: signed,
                  },
                })
                .then((answer) => resolve(answer))
                .catch((err) => resolve(err));
              return null;
            })
            .catch((err) => {
              resolve(err);
              const message = CadesUtil.prepareError(err);

              if (message) {
                toast.error(message);
              }
            });
          return null;
        })
        .catch((err) => resolve(err));
    });
  }

  static refreshToken() {
    return instance
      .request({
        timeout: 60000,
        url: '/api/v3/auth/login/refresh',
        method: HttpMethodEnum.POST,
      })
      .catch((error) => error.response);
  }
}
