import React, { useCallback, useMemo } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip } from '@mui/material';

import { Icons } from '@ibox/ui';

import { productGroupStatusEnum } from '../../../constants/index';
import { openSignDocumentsModalById } from '../../_ServiceDesk/SignDocuments/ducks/SignDocuments.actions';
import { translate } from '../../Translate/Translate';

import { ProductGroupIcon } from './product-group-icon';
import { ProductGroupStatus } from './product-group-status';

import classes from './DashboardTile.module.scss';

const productGroupStatusColorsMap = {
  [productGroupStatusEnum.REVOKED]: 'error',
  [productGroupStatusEnum.REGISTERED]: 'warning',
  [productGroupStatusEnum.PROFILED]: 'warning',
  [productGroupStatusEnum.SIGNER]: 'success',
  [productGroupStatusEnum.SIGNER_WITH_ACCESS_ENABLED]: 'success',
};

const productGroupStatusNamesMap = {
  [productGroupStatusEnum.REVOKED]: translate('Требует активации'),
  [productGroupStatusEnum.ACTIVE]: translate('Подключена'),
  [productGroupStatusEnum.ENABLING]: translate('Подключена'),
  [productGroupStatusEnum.REGISTERED]: translate('В процессе активации'),
  [productGroupStatusEnum.PROFILED]: translate('На рассмотрении'),
  [productGroupStatusEnum.SIGNER]: translate('Подтверждено'),
  [productGroupStatusEnum.SIGNER_WITH_ACCESS_ENABLED]:
    translate('Подтверждено'),
};

const DashboardTile = ({
  description,
  disabled,
  icon,
  className,
  name,
  productGroupStatus,
  status,
  id,
  openSignDocumentsModalById,
}) => {
  const shouldSignDocuments = useMemo(
    () =>
      [
        productGroupStatusEnum.SIGNER,
        productGroupStatusEnum.SIGNER_WITH_ACCESS_ENABLED,
      ].includes(status),
    [status]
  );

  const productGroupStatusVisible = useMemo(() => {
    return (
      (productGroupStatus === 'COMMERCIAL' ||
        productGroupStatus === 'EMISSION_BLOCKED') &&
      [
        productGroupStatusEnum.REVOKED,
        productGroupStatusEnum.REGISTERED,
        productGroupStatusEnum.PROFILED,
        productGroupStatusEnum.SIGNER,
        productGroupStatusEnum.SIGNER_WITH_ACCESS_ENABLED,
      ].includes(status)
    );
  }, [productGroupStatus, status]);

  const onSign = useCallback(
    (event) => {
      if (shouldSignDocuments) {
        event.stopPropagation();
        openSignDocumentsModalById({ id });
      }
    },
    [id, openSignDocumentsModalById, shouldSignDocuments]
  );

  return (
    <div className={classes.wrapper}>
      <div
        className={clsx(
          classes.root,
          {
            [classes.disabled]: disabled,
          },
          className
        )}
      >
        <div className={classes.head}>
          {icon ? (
            <ProductGroupIcon
              className={classes.icon}
              color={
                productGroupStatusVisible
                  ? productGroupStatusColorsMap[status]
                  : undefined
              }
            >
              {icon}
            </ProductGroupIcon>
          ) : null}

          {productGroupStatusVisible ? (
            <ProductGroupStatus
              className={classes.status}
              description={
                shouldSignDocuments ? 'Требуется подписать договор' : undefined
              }
              label={productGroupStatusNamesMap[status]}
              onClick={onSign}
            />
          ) : (
            <ProductGroupStatus
              className={classes.status}
              label={productGroupStatusNamesMap[status]}
              onClick={() => {}}
            />
          )}
        </div>

        <div className={classes.body}>
          <div className={classes.label}>{translate(name)}</div>
        </div>
      </div>

      {Boolean(description) && (
        <Tooltip
          arrow
          className={classes.description}
          placement="right"
          title={description}
        >
          <Icons.Information height="24" width="24" />
        </Tooltip>
      )}
    </div>
  );
};

DashboardTile.propTypes = {
  description: PropTypes.element,
  icon: PropTypes.element,
  name: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  openSignDocumentsModalById: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  productGroupStatus: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  status: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  openSignDocumentsModalById: openSignDocumentsModalById,
};

export const DashboardTileConnected = connect(
  null,
  mapDispatchToProps
)(DashboardTile);
