import { createStyles, Theme } from '@material-ui/core';

export default ({ palette }: Theme) => 
  createStyles({
    root: {
      padding: 16,
      boxSizing: 'border-box',
      borderBottom: `1px solid ${palette.grey[400]}`,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: palette.grey[300],
        fontWeight: 600,
      }
    },
    timestamp: {
      fontSize: 12,
      lineHeight: '16px',
    },
    titleLastVersion: {
      fontSize: 9,
      lineHeight: '12px',
      marginBottom: 8,
    },
    lastVersion: {
      fontWeight: 600,
    },
    selected: {
      backgroundColor: palette.primary.main,
    }
  });
