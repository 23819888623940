import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';

import { Autocomplete as CrptuiAutocomplete } from '@crpt-ui/core';

import { startSetCertificates } from '../../../../Cades/Cades.actions';
import { getPreparedCertificates } from '../../../../Cades/Cades.selectors';

import { CertMenuItem } from './MaterialCertSelect/CertMenuItem';

const MaterialCertSelectComp = ({
  values,
  startSetCertificates,
  onSelect,
  filterFn,
  ...rest
}) => {
  const [_value, _setValue] = useState(null);

  useEffect(() => {
    startSetCertificates();
  }, [startSetCertificates]);

  const preparedOptions = useMemo(() => {
    let data;

    data = values.sort((x, y) =>
      x.validTo === y.validTo ? 0 : x.validTo ? -1 : 1
    );

    if (filterFn) {
      data = data.filter(filterFn);
    }

    return data.map((c) => ({
      label: c.surname,
      value: c,
    }));
  }, [filterFn, values]);

  const handlerOnChange = (fn) => (_event, option) => {
    _setValue(option);
    fn(option.value);

    if (onSelect) {
      onSelect(option.value);
    }
  };

  const getOptionLabel = useCallback(({ value }) => {
    return `${value.fullName} ${value.position ? `· ${value.position}` : ''}`;
  }, []);

  return (
    <Field {...rest}>
      {({ input }) => {
        return (
          <CrptuiAutocomplete
            disableClearable
            getOptionDisabled={(option) => !option.value.valid}
            getOptionLabel={getOptionLabel}
            label={_value ? _value.value.company : 'Выберите сертификат'}
            options={preparedOptions}
            renderOption={({ value }) => <CertMenuItem {...value} />}
            value={_value}
            onChange={handlerOnChange(input.onChange)}
          />
        );
      }}
    </Field>
  );
};

MaterialCertSelectComp.propTypes = {
  classes: PropTypes.object.isRequired,
  filterFn: PropTypes.func,
  startSetCertificates: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  values: PropTypes.array,
};

const mapStateToProps = (state) => ({
  values: getPreparedCertificates(state),
});

const mapDispatchToProps = {
  startSetCertificates,
};

export const MaterialCertSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(MaterialCertSelectComp);
