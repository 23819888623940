/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Config,
  createForm as createFinalForm,
  FieldConfig,
  FieldState,
  FieldSubscription,
  FormApi,
  getIn,
  setIn,
} from 'final-form';
import * as yup from 'yup';
import _ from 'lodash';

export enum ErrorsNormalizationType {
  /** Option for backward compatibility */
  ShowLastOccurred = 0,
  ShowFirstOccurred = 1,
  ShowAll = 2,
}

// @ts-ignore
function normalizeValidationError(err, init) {
  // @ts-ignore
  return err.inner.reduce((errors, innerError) => {
    const { path = '', message } = innerError;
    errors = setIn(errors, path, [message]);
    return errors;
  }, init);
}

// @ts-ignore
function normalizeValidationErrors(
  err: any,
  normalizationType: ErrorsNormalizationType = ErrorsNormalizationType.ShowLastOccurred
) {
  // @ts-ignore
  return err.inner.reduce((errors, innerError) => {
    const { path, message } = innerError;

    if (_.has(errors, path)) {
      if (normalizationType === ErrorsNormalizationType.ShowAll) {
        const prev = getIn(errors, path);
        prev.push(message);
        errors = setIn(errors, path, prev);
      } else if (
        normalizationType === ErrorsNormalizationType.ShowLastOccurred
      ) {
        errors = setIn(errors, path, [message]);
      }
    } else {
      errors = setIn(errors, path, [message]);
    }
    return errors;
  }, {});
}

// @ts-ignore
function validate(field) {
  // @ts-ignore
  return (value, allValues) => {
    try {
      field.validateSync(value, {
        context: allValues,
        abortEarly: false,
      });
      return undefined;
    } catch (err) {
      return normalizeValidationError(err, field.type === 'array' ? [] : {});
    }
  };
}

// @ts-ignore
function makeValidate(
  validator: any,
  normalizationType = ErrorsNormalizationType.ShowLastOccurred
) {
  // @ts-ignore
  return (values) => {
    try {
      validator.validateSync(values, {
        context: values,
        abortEarly: false,
      });
      return {};
    } catch (err) {
      return normalizeValidationErrors(err, normalizationType);
    }
  };
}

// @ts-ignore
function makeDependencies(schema, path = [], deps = {}) {
  const fields = schema.fields;
  const keys = Object.keys(fields);
  for (const key of keys) {
    const field = fields[key];
    if (field.fields && Object.entries(field.fields).length !== 0) {
      // @ts-ignore
      deps = makeDependencies(field, [...path, key], deps);
    } else {
      const conditions = field._conditions;
      if (conditions) {
        for (const conditionKey in conditions) {
          if (Object.prototype.hasOwnProperty.call(conditions, conditionKey)) {
            const condition = conditions[conditionKey];
            for (const refKey in condition.refs) {
              if (
                Object.prototype.hasOwnProperty.call(condition.refs, refKey)
              ) {
                const ref = condition.refs[refKey];
                const current = getIn(deps, ref.path);
                const refPath = [...path, key].join('.');
                deps = setIn(
                  deps,
                  ref.path,
                  current ? [...current, refPath] : [refPath]
                );
              }
            }
          }
        }
      }
    }
  }
  return deps;
}

// @ts-ignore
function makeStructure(schema, form, dependencies, path = []) {
  const fields = schema.fields;
  const keys = Object.keys(fields);
  for (const key of keys) {
    const field = fields[key];
    if (field.fields && Object.entries(field.fields).length !== 0) {
      // @ts-ignore
      makeStructure(field, form, dependencies, [...path, key]);
    } else {
      const name = [...path, key].join('.');
      const validateFields = getIn(dependencies, name) || [];
      form.registerField(
        name,
        () => null,
        {
          error: true,
          modified: true,
          value: true,
        },
        {
          getValidator: () => validate(field),
          silent: true,
          validateFields,
        }
      );
    }
  }
}

export type SubscriberWithForm<V, F> = (value: V, form: F) => void;
export type FieldSubscriberWithForm<V> = SubscriberWithForm<
  FieldState<V>,
  FormApi
>;

export type createFormConfig = Config & {
  experimental?: boolean;
  schema?: yup.ObjectSchema;
  subscribeFields?: {
    name: string;
    subscriber: FieldSubscriberWithForm<unknown>;
    subscription?: FieldSubscription;
    config?: FieldConfig<unknown>;
  }[];
  errorNormalizationType?: ErrorsNormalizationType;
};

function createForm({
  experimental = false,
  schema,
  subscribeFields,
  errorNormalizationType = ErrorsNormalizationType.ShowLastOccurred,
  ...rest
}: createFormConfig) {
  const form = createFinalForm({
    ...rest,
    validate: experimental
      ? undefined
      : makeValidate(schema, errorNormalizationType),
  });

  if (experimental) {
    const dependencies = makeDependencies(schema);
    // eslint-disable-next-line
    console.info('experimental dependencies', dependencies);
    makeStructure(schema, form, dependencies);
  }

  if (subscribeFields?.length) {
    subscribeFields.forEach(({ name, subscriber, subscription, config }) => {
      form.registerField(
        // @ts-ignore
        name,
        // @ts-ignore
        (value) => subscriber(value, form),
        Object.assign(
          {
            value: true,
          },
          subscription
        ),
        config
      );
    });
  }

  // @ts-ignore
  const setInitialValues = (values) => {
    form.setConfig('initialValues', values);
  };

  // @ts-ignore
  const setValues = (values) => {
    Object.keys(values).forEach((destField) => {
      // @ts-ignore
      const fieldState = form.getFieldState(destField);
      if (!fieldState || fieldState.value !== values[destField]) {
        // @ts-ignore
        form.change(destField, values[destField]);
      }
    });
  };
  return {
    ...form,
    setValues,
    setInitialValues,
  };
}

export { createForm, yup };
