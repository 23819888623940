import React from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Icon } from '@ibox/ui';

import { Account } from './account/Account';

import css from './index.module.scss';

type BalanceProps = {
  accounts: any[];
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export const Balance = ({
  accounts = [],
  children,
  className,
  ...rest
}: BalanceProps) => {
  const { t } = useTranslation();

  return accounts.length ? (
    <div className={clsx(css.root, className)} {...rest}>
      <div className={css.head}>
        <div className={css.icon}>
          <Icon name="Wallet" />
        </div>
        <div className={css.title}>{t('Счета')}</div>
      </div>

      <div className={css.notification}>
        {t(
          'Обязательно указывайте номер лицевого счета в назначении платежа при оплате'
        )}
      </div>
      <div className={css.body}>
        {accounts.map((account, index) => (
          <Account account={account} key={index} />
        ))}

        {children ? <div className={css.children}>{children}</div> : null}
      </div>
    </div>
  ) : null;
};
