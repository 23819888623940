import React from 'react';

import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

import { Input } from '../../../../../common_components/Form/Input';
import { DatePickerInput } from '../../../../../common_components/Form/DatePickerInput';
import { isUkdDocuments } from '../../ducks/ClosingDocument.selectors';

const InfoAboutAcceptance = ({ isUkdDocuments }) => (
  <Grid
    container
    direction="column"
    spacing={2}
    style={{
      maxWidth: '710px',
    }}
  >
    {isUkdDocuments && (
      <Grid item>
        <Input
          required
          defaultValue="С изменением стоимости согласен"
          label="Содержание операции"
          name="content_operation"
        />
      </Grid>
    )}

    <Grid item>
      <DatePickerInput
        label="Дата получения"
        maxDate={new Date()}
        name="date"
      />
    </Grid>
    <Grid item>
      <Input required label="Должность" name="position" />
    </Grid>
    <Grid item>
      <Input required label="Фамилия" name="surname" />
    </Grid>
    <Grid item>
      <Input required label="Имя" name="name" />
    </Grid>
    <Grid item>
      <Input label="Отчество" name="patronymic" />
    </Grid>
  </Grid>
);

const mapStateToProps = (state) => ({
  isUkdDocuments: isUkdDocuments(state),
});

export const InfoAboutAcceptanceConnected =
  connect(mapStateToProps)(InfoAboutAcceptance);
