import instance from '../axios';
import {
  DownloadFileAvailability,
  DownloadFileStatus,
} from '@crpt/shared/services/DocService.types';

export interface FetchErrorsStatusParams {
  documentId: string;
  productGroupId: number;
}

export interface FetchErrorsStatusResponse {
  availability: DownloadFileAvailability;
  fileDownloadLink: string;
  fileLinesCount: number;
  fileSize: number;
  id: string;
  status: DownloadFileStatus;
}

export interface CreateErrorsTaskParams {
  documentId: string;
  notificationUrl: string;
  productGroupId: number;
}

export interface CreateErrorsTaskResponse {
  id: string;
}

export class DocService {
  path: string = '/api/v3/facade/doc';

  createErrorsTask = (params: CreateErrorsTaskParams) => {
    const { documentId, notificationUrl, productGroupId } = params;

    return instance.post<CreateErrorsTaskResponse>(`${this.path}/errors/task`, {
      documentId,
      notificationUrl,
      productGroupId,
    });
  };

  fetchErrorsStatus = (params: FetchErrorsStatusParams) => {
    const { documentId, productGroupId } = params;

    return instance.post<FetchErrorsStatusResponse>(
      `${this.path}/errors/result`,
      {
        documentId,
        productGroupId,
      }
    );
  };
}
