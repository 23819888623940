import React, { FC, useCallback, memo, useEffect } from 'react';

import { Box } from '@mui/material';
import { Button, Icon } from '@crpt-ui/core';
import { Input } from '@crpt/shared/components/Form/Fields';
import { useTranslation } from 'react-i18next';
import { FieldIds } from './GtinDialog.types';

interface GtinInputProps {
  deleteGtin: (index: number) => void;
  name: string;
  index: number;
  loadProduct: (value: string, name: string) => void;
  clearName: (name: string) => void;
  isLoading: boolean;
  error?: {
    [FieldIds.GTIN]?: string;
    [FieldIds.NAME]?: string;
  };
}

export const GtinInput: FC<GtinInputProps> = ({
  name,
  index,
  error,
  deleteGtin,
  loadProduct,
  clearName,
  isLoading,
}) => {
  const { t } = useTranslation();

  // FC-4684 Clearing name field if gtin invalid
  useEffect(() => {
    if (error && error[FieldIds.GTIN] && !error[FieldIds.NAME]) {
      clearName(`${name}.${FieldIds.NAME}`);
    }
  }, [error, clearName]);

  const callBackOnValid = useCallback((data: string) => {
    loadProduct(data, `${name}.${FieldIds.NAME}`);
  }, []);

  const onDeleteGtin = useCallback(() => {
    deleteGtin(index);
  }, [deleteGtin, index]);

  return (
    <Box display="flex" gap={2} py={1}>
      <Input
        name={`${name}.${FieldIds.GTIN}`}
        placeholder={t('UnloadService.Task.CisUnloads.GtinModal.GTIN')}
        callBackOnValid={callBackOnValid}
        disabled={isLoading}
        clearable
        required
      />
      <Input
        name={`${name}.${FieldIds.NAME}`}
        placeholder={t('UnloadService.Task.CisUnloads.GtinModal.name')}
        disabled
        required
      />
      <Button
        icon={<Icon name="trash" color="error" />}
        onClick={onDeleteGtin}
        size="md"
        variant="outlined"
        width="48px"
      />
    </Box>
  );
};

export default memo(GtinInput);