import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.text.primary,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    label: {
      color: theme.palette.common.white,
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 24 / 20,
      marginTop: theme.spacing(2),
    },
  });
