import styled from 'styled-components';
import { get } from 'lodash';

export const InnerContentWrapper = styled.div`
  position: relative;
  background: ${(props) =>
    get(props, 'theme.Content.backgroundColor', 'black')};
  margin-bottom: 0px;
  margin-top: ${(props) => get(props, 'theme.Content.marginTop', '100px')};
  min-height: ${({ minHeight }) => (minHeight ? '500px' : 'auto')};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
