import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash/fp';

import { TabsComponent } from '@ibox/ui';

import { tabs } from './RegistryOIVPage.constants';
import { OivData } from './OivData';
import { OivDataList } from './OivUsersList';
import * as actions from './ducks/RegistryOIVPage.actions';
import * as selectors from './ducks/RegistryOIVPage.selectors';

export const RegistryOIVPage = () => {
  const [activeTab, setActiveTab] = useState(0);

  const dispatch = useDispatch();

  const loaded = useSelector((state) => selectors.loaded(state));
  const usersLoaded = useSelector((state) => selectors.usersLoaded(state));
  const total = useSelector((state) => selectors.totalUsers(state));
  const currentPage = useSelector((state) => selectors.pageUsers(state));
  const users = useSelector((state) => selectors.users(state));
  const result = useSelector((state) => selectors.result(state));

  const pid = get('pid', result);

  useEffect(() => {
    dispatch(
      actions.fetchData({
        inn: location.pathname.split('/').slice(-1)[0],
      })
    );
    return dispatch(actions.onUnmount());
  }, [dispatch]);

  useEffect(() => {
    if (pid) {
      dispatch(
        actions.fetchGetUsers({
          id: pid,
          page: 0,
        })
      );
    }
  }, [pid]);

  const onChangePage = (page: number) => {
    dispatch(
      actions.fetchGetUsers({
        id: pid,
        page,
      })
    );
  };

  return (
    <>
      <TabsComponent
        items={tabs}
        variant="standard"
        onChangeTab={setActiveTab}
      />
      {activeTab === 0 && <OivData loaded={loaded} result={result} />}
      {activeTab === 1 && (
        <OivDataList
          currentPage={currentPage}
          last={users.length >= total}
          loaded={usersLoaded}
          pid={pid}
          results={users}
          onChangePage={onChangePage}
        />
      )}
    </>
  );
};
