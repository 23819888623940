import React, { Fragment, useEffect, useRef, useState } from 'react';

import { default as fp } from 'lodash/fp';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import {
  Button,
  LanguageSwitcher,
  TextMaskedFormFieldDigit,
  useEvent,
  useFlag,
} from '@ibox/ui';

import { StripeLayout } from '../../common_components/Layouts';
import { Form } from '../../common_components/Form/form/form';
import { FormContext } from '../../common_components/Form/Form.constants';
import { LanguageWrap } from '../../common_components/LanguageMenu/LanguageWrap';
import { getCookie, setLanguage } from '../../utils/cookieHelpers';
import { isValidInnForFinalForm } from '../../common_components/Form/utils/validations';

import { ErrorMessage } from './Fields/Fields.styled';

import css from './index.module.scss';

export const LoginFormInnView = ({ onSubmit, errors, isLoading }) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(getCookie('language') || 'ru');
  const [isFocused, setIsFocusedTrue, setIsFocusedFalse] = useFlag(false);
  const formRef = useRef();
  const formValuesRef = useRef();

  useEffect(() => {
    !isFocused &&
      formRef.current &&
      formValuesRef.current &&
      formRef.current?.reset(formValuesRef.current);
  }, [isFocused]);

  const languages = ['ru', 'hy'];

  const isValidInn = useEvent((value) => {
    const result = isValidInnForFinalForm(value);
    return isFocused ? result && true : result;
  });

  const changeLanguage = useEvent((language) => {
    i18n.changeLanguage(language.toUpperCase());
    setLang(language);
    setLanguage(language);
    localStorage.setItem('language', language);
  });

  useEffect(() => {
    const language = getCookie('language') || 'ru';

    changeLanguage(language);
  }, [changeLanguage]);

  const onLangSelect = useEvent((language) => {
    changeLanguage(language);
  });

  return (
    <Fragment>
      <StripeLayout enableMobileLinks title={t('Вход в систему')}>
        <Form style={{ width: '95%' }} onSubmit={onSubmit}>
          <FormContext.Consumer>
            {({ errors: formErrors, form, values }) => {
              formRef.current = form;
              formValuesRef.current = values;
              return (
                <>
                  <Field
                    fullWidth
                    required
                    className={css['form-field-inn']}
                    component={TextMaskedFormFieldDigit}
                    inputProps={{ maxLength: 8 }}
                    label={t('Введите ИНН участника оборота или ОИВ')}
                    name="inn"
                    validate={isValidInn}
                    onBlur={setIsFocusedFalse}
                    onFocus={setIsFocusedTrue}
                  />
                  <br />
                  <Box mt={4}>
                    <Grid container justify="space-between" spacing={2}>
                      <Grid item>
                        <ErrorMessage>{errors}</ErrorMessage>
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={!fp.isEmpty(formErrors) || isLoading}
                          type="submit"
                        >
                          {t('Войти')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              );
            }}
          </FormContext.Consumer>
        </Form>
      </StripeLayout>
      <LanguageWrap>
        <LanguageSwitcher
          className={css.langSwitcher}
          languages={languages}
          selected={lang}
          onChange={onLangSelect}
        />
      </LanguageWrap>
    </Fragment>
  );
};
