import React, { FC } from 'react';

import { FieldRenderProps } from 'react-final-form';

import { useEvent } from '../../utils/react.hooks';
import {
  TextFieldMasked,
  type TextFieldMaskedProps,
} from '../../mui/textfield-masked/textfield-masked';

export type TextMaskedFormFieldProps = TextFieldMaskedProps &
  FieldRenderProps<string>;

type Props = Required<TextMaskedFormFieldProps>;

export const TextMaskedFormField: FC<TextMaskedFormFieldProps> = (props) => {
  const {
    input: {
      name,
      value,
      type,
      onBlur: onBlurField,
      onChange: onChangeField,
      onFocus: onFocusField,
    },
    meta,
    onChange,
    onBlur,
    onFocus,
    ...textFieldProps
  } = props;

  const isVisibleError =
    !textFieldProps.disabled && (meta.touched || meta.modified);
  const hasError = isVisibleError && Boolean(meta.error);
  const helperText =
    hasError && typeof meta.error === 'string' ? meta.error : undefined;

  const handleBlur = useEvent<Props['onBlur']>((event) => {
    onBlurField(event);
    onBlur?.(event);
  });

  const handleFocus = useEvent<Props['onFocus']>((event) => {
    onFocusField(event);
    onFocus?.(event);
  });

  const handleChange = useEvent<Props['onChange']>((event) => {
    onChangeField(event);
    onChange?.(event);
  });

  return (
    <TextFieldMasked
      {...textFieldProps}
      error={hasError}
      helperText={helperText}
      name={name}
      type={type}
      value={String(value)}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
    />
  );
};

export const TextMaskedFormFieldDate: FC<TextMaskedFormFieldProps> = (
  props
) => <TextMaskedFormField {...props} mask={Date} />;

export const TextMaskedFormFieldNumber: FC<TextMaskedFormFieldProps> = (
  props
) => <TextMaskedFormField {...props} mask={Number} />;

export const TextMaskedFormFieldDigit: FC<TextMaskedFormFieldProps> = (
  props
) => <TextMaskedFormField {...props} mask={/^[0-9]*$/} />;

export const TextMaskedFormFieldInn: FC<TextMaskedFormFieldProps> = (props) => (
  <TextMaskedFormField {...props} mask="00000000" />
);
