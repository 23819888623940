import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Icon } from '@crpt-ui/core';
import clsx from 'clsx';

import { ReactComponent as Loader } from './Loader.svg';
import { useStyles } from './FileQueueMini.styles';
import type { FileQueueCollection } from '../FileQueue.types';

interface FileQueueMiniProps {
  collection: FileQueueCollection;
  closeViewMinimized: () => void;
}

export const FileQueueMini: FC<FileQueueMiniProps> = ({
  collection,
  closeViewMinimized,
}) => {
  const [pathEl, setPathEl] = useState<Element | null>(null);

  const loaderRef = useRef<SVGSVGElement | null>(null);
  const classes = useStyles();

  useEffect(() => {
    if (loaderRef.current === null) {
      return;
    }

    const loaderElement = loaderRef.current;
    setPathEl(loaderElement.querySelector('path'));
  }, []);

  const percent = useMemo(() => {
    let sum = 0;

    if (collection.length === 0) {
      return 0;
    }

    collection.forEach(({ value: item }) => {
      sum = sum + (item.loaded ? 100 : item.loadingPercent ?? 0);
    });

    return sum / collection.length;
  }, [collection]);

  useEffect(() => {
    if (!pathEl) {
      return;
    }

    pathEl.setAttribute('stroke-dasharray', `${170 * (percent / 100)} 250`);
  }, [pathEl, percent]);

  return (
    <div
      className={clsx(classes.wrapper, {
        [classes.open]: collection.length !== 0,
      })}
    >
      <div className={classes.loaderBackground} />
      <Loader className={classes.loader} ref={loaderRef} />
      <Button
        className={classes.button}
        icon={<Icon name="download" />}
        onClick={closeViewMinimized}
        variant="text"
      />
    </div>
  );
};
