import { AxiosRequestConfig } from 'axios';
import _ from 'lodash';
import instance from '../axios';

interface sendSignedDocumentOptions {
  document: string;
  signature: string;
  documentType: string;
  config?: AxiosRequestConfig;
}

class UploadReceiptService {
  transform(file: File, config?: AxiosRequestConfig) {
    const formData = new FormData();

    formData.append(
      'document_type',
      new Blob([JSON.stringify('FISCAL_DOCUMENT')], {
        type: 'application/json',
      })
    );

    formData.append('file', file, file.name);

    return instance.post(
      '/api/v3/facade/doc/transform',
      formData,
      _.defaults(config, {
        headers: {
          'Content-Type': 'multipart/form-data; charset=utf-8',
        },
      })
    );
  }

  sendSignedDocument({
    document,
    signature,
    documentType,
    config,
  }: sendSignedDocumentOptions) {
    return instance.post(
      '/api/v3/facade/doc/signed',
      {
        document: document,
        signature: signature,
        document_type: documentType,
      },
      config
    );
  }
}

export default new UploadReceiptService();
