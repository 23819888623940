import { call, put, select, takeEvery } from 'redux-saga/effects';

import { HttpMethodEnum } from '../../../../../constants/index';
import { Api } from '../../../../../common_components/Api/Api';
import { prepareSignFormData } from '../../../../_ServiceDesk/SignDocuments/ducks/sagas/SignDocuments.saga';
import {
  closeSignModal,
  setSigningProcess,
} from '../../../../../common_components/DocumentSigningModal/ducks/DocumentSigningModal.actions';
import { signContent } from '../../../../../common_components/Cades/Cades.saga';
import { getAdditionalInfoSigningModal } from '../../../../../common_components/DocumentSigningModal/ducks/DocumentSigningModal.selectors';
import {
  clearServiceDeskDocument,
  fetchServiceDeskDocumentSaga,
  getServiceDeskDocumentContent,
  getServiceDeskDocumentInfo,
} from '../../ducks';

import { signServiceDeskDocument } from './ServiceDeskDocumentSignModal.actions';

export function* signDocumentSaga() {
  yield put(setSigningProcess(true));

  let needToClear = false;
  let document = yield select(getServiceDeskDocumentInfo);
  let content = yield select(getServiceDeskDocumentContent);

  const additionalInfoSigningModal = yield select(
    getAdditionalInfoSigningModal
  );

  if (!document || !content) {
    needToClear = true;
    yield call(fetchServiceDeskDocumentSaga, {
      payload: additionalInfoSigningModal.guid,
    });

    document = yield select(getServiceDeskDocumentInfo);
    content = yield select(getServiceDeskDocumentContent);
  }

  const signature = yield call(signContent, content);

  const formData = yield call(prepareSignFormData, {
    document: {
      content,
      filename: document.fileName,
      doctype: document.doctype,
      signtype: document.signtype,
    },
    signature,
  });

  const requestPayload = {
    url: '/edo-api/outgoing-documents',
    method: HttpMethodEnum.POST,
    data: formData,
    timeout: 600000,
  };

  const [success] = yield call(Api.request, requestPayload, {
    preloading: false,
    errorNotify: false,
  });

  if (success) {
    if (needToClear) {
      yield put(clearServiceDeskDocument());
    }

    yield put(closeSignModal());
    yield call(additionalInfoSigningModal.onSuccess);
  }

  yield put(setSigningProcess(false));
}

export const saga = function* watch() {
  yield takeEvery(signServiceDeskDocument, signDocumentSaga);
};
