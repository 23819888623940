export interface Scenario {
  code: string;
  gtin: string;

  graph: {
    edges: Edge[];
    nodes: Node[];
  };

  versions: number[];
  version?: number;
}

export interface Node {
  buyer?: string;
  docDate: number;
  docId: string;
  docType: string;
  fake?: boolean;
  id: string;
  owner: string;
  seller?: string;
  type: NodeType;
}

export interface NodeWithChildren extends Node {
  children: NodeWithChildren[];
}

export interface Edge {
  source: string;
  target: string;
}

export enum NodeType {
  AGGREGATION= 'AGGREGATION',
  EMISSION = 'EMISSION',
  INTRODUCTION = 'INTRODUCTION',
  OWNER_CHANGE = 'OWNER_CHANGE',
  SOLD_OUT = 'SOLD_OUT',
  UTILISATION = 'UTILISATION',
}
