import { handleActions } from 'redux-actions';

import {
  closeModalNotification,
  setBalanceMinimum,
  setLoading,
  setNotificationRecipientsIds,
  setOpenNotificationBalanceModal,
  setSelectedAccount,
} from './NotificationBalance.actions';

const open = handleActions(
  {
    [setOpenNotificationBalanceModal]: () => true,
    [closeModalNotification]: () => false,
  },
  false
);

const loading = handleActions(
  {
    [setLoading]: (_, { payload }) => payload,
    [closeModalNotification]: () => false,
  },
  false
);

const selectedAccountDefaultState = {
  productGroupId: null,
  balance: 0,
  contractId: null,
  minBalance: 0,
};

const selectedAccount = handleActions(
  {
    [setSelectedAccount]: (_, { payload }) => payload,
    [closeModalNotification]: () => selectedAccountDefaultState,
  },
  selectedAccountDefaultState
);

const notificationRecipientsIds = handleActions(
  {
    [setNotificationRecipientsIds]: (_, { payload }) => payload,
    [closeModalNotification]: () => [],
  },
  []
);

const balanceMinimum = handleActions(
  {
    [setBalanceMinimum]: (_, { payload }) => payload,
    [closeModalNotification]: () => null,
  },
  null
);

export const reducer = {
  open,
  selectedAccount,
  loading,
  notificationRecipientsIds,
  balanceMinimum,
};
