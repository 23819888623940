import React from 'react';

import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Button, Dialog, Typography } from '@crpt-ui/core';

import { closePaymentDocumentSuccessModal } from './ducks/CreatePaymentDocumentSuccess.actions';
import { getOpenCreatePaymentSuccessModal } from './ducks/CreatePaymentDocumentSuccess.selectors';

export const CreatePaymentDocumentSuccess = ({ onClose, isOpen }) => (
  <Dialog open={isOpen} onClose={onClose}>
    <Typography variant="h5">
      Заявка на&nbsp;получение счета отправлена
    </Typography>
    <Box my={3}>
      <Typography variant="body1">
        Счет будет отправлен в&nbsp;ближайшее время
      </Typography>
    </Box>
    <Box mt={4}>
      <Button className="whiteColor" size="lg" onClick={onClose}>
        Закрыть
      </Button>
    </Box>
  </Dialog>
);

CreatePaymentDocumentSuccess.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isOpen: getOpenCreatePaymentSuccessModal(state),
});

const mapDispatchToProps = { onClose: closePaymentDocumentSuccessModal };

export const CreatePaymentDocumentSuccessConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePaymentDocumentSuccess);
