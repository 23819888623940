import { bool, element, number, shape, string } from 'prop-types';

export const MatchType = shape({
  params: shape({
    id: string,
  }),
}).isRequired;

export const DocumentInfoType = shape({
  type: number,
  number: string.isRequired,
  status: number.isRequired,
  color: string,
  statusIcon: element,
}).isRequired;

export const DocumentIncidentType = shape({
  description: string,
  refid: string,
  createtime: string,
});

export const SignFormValuesType = shape({
  authority: number,
  status: number,
  grounds: string,
  organization_grounds: string,
  date: string,
  position: string,
  surname: string,
  name: string,
  patronymic: string,
  isOpenAcceptance: bool,
});
