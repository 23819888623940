import { call, put, select, takeEvery } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import { HttpMethodEnum } from '../../../../constants/index';
import { Api } from '../../../../common_components/Api/Api';
import { adjustProgress } from '../../Profile.utils';
import { getShowProductionTab } from '../../Production/ducks/Production.selectors';
import { getRegistered } from '../../Company/ducks/Company.selectors';
import { sendFinalize } from '../../Company/ducks/Company.actions';

import {
  fetchRequisites,
  sendRequisitesData,
  setRequisitesData,
  fetchBankInfo,
} from './Requisites.actions';

function* fetchRequisitesData() {
  const requestPayload = {
    url: '/crm-api/participant',
  };

  const [success] = yield call(Api.request, requestPayload);
  if (success) {
    // A temporary solution, backend should modify the fields
    const requestData = success.data.response[0];
    const participantData = {
      bankName: requestData.bankName,
      bic: requestData.bankCode,
      correspondingAccount: requestData.correspondentAccount,
      settlementAccount: requestData.CheckingAccount,
      sfRequired: false,
    };

    yield put(setRequisitesData({ ...participantData }));
  }
}

function* submitSaga(action) {
  const { payload } = action;

  const data = {
    bankName: payload.bankName,
    bic: payload.bic,
    correspondingAccount: payload.correspondingAccount,
    settlementAccount: payload.settlementAccount,
    sfRequired: false,
  };
  const url = '/api/v3/facade/profile/bank/send';
  const requestPayload = {
    url,
    method: HttpMethodEnum.POST,
    data,
  };

  const [success] = yield call(Api.request, requestPayload, {
    artificialDelay: 5000,
  });

  const isRegistered = yield select(getRegistered);

  if (!isRegistered && success) {
    yield call(adjustProgress);
    const isShowProductionTab = yield select(getShowProductionTab);

    if (isShowProductionTab) {
      yield put(replace('/profile/production'));
    } else {
      yield put(sendFinalize());
      // yield put(replace('/profile/edo'));
    }
  }
}

function* fetchBankInfoData({ payload }) {
  const url = `/api/v3/nsi/bank-info/${payload}`;
  const requestPayload = {
    url,
    method: HttpMethodEnum.GET,
  };

  const [success, error] = yield call(Api.request, requestPayload, {
    preloading: false,
    showBackendMessage: true,
  });

  if (success) {
    const { data } = success;

    const prepareBankInfoData = {
      bankName: data.participant_info.name,
      correspondingAccount: data.accounts[0].account,
      bic: payload,
    };

    yield put(setRequisitesData(prepareBankInfoData));
  }

  if (error) {
    yield put(
      setRequisitesData({
        bankName: '',
        correspondingAccount: '',
        bic: payload,
      })
    );
  }
}

export const saga = function* watch() {
  yield takeEvery(fetchRequisites, fetchRequisitesData);
  yield takeEvery(sendRequisitesData, submitSaga);
  yield takeEvery(fetchBankInfo, fetchBankInfoData);
};
