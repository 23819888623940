import { format } from 'date-fns';

const addFilterForStorage = (newFiltersHistory, localStorageName) => {
  if (localStorage.getItem(localStorageName)) {
    const allHistory = JSON.parse(localStorage.getItem(localStorageName) || '');
    allHistory.push(newFiltersHistory);
    localStorage.setItem(localStorageName, JSON.stringify(allHistory));
  } else {
    localStorage.setItem(localStorageName, JSON.stringify([newFiltersHistory]));
  }
};

export function newFilter(value, localStorageName, filterName) {
  const pattern = 'dd.MM.yyyy HH:mm';
  const date = format(new Date(), pattern);

  const newSavedFilters = {
    id: new Date().getTime().toString(),
    name: filterName !== '' ? filterName : date,
    filters: value,
  };
  addFilterForStorage(newSavedFilters, localStorageName);
}

export const deleteSavedFilterFromStorage = (filterId, localStorageName) => {
  const savedFilters = JSON.parse(localStorage.getItem(localStorageName) || '');

  const filteredFilters = savedFilters.filter(
    (filter) => filter.id !== filterId
  );
  if (filteredFilters.length) {
    localStorage.setItem(localStorageName, JSON.stringify(filteredFilters));
  } else {
    localStorage.removeItem(localStorageName);
  }
};

export const editSavedFilterStorage = (changedFilter, localStorageName) => {
  const allSavedFilters = JSON.parse(
    localStorage.getItem(localStorageName) || ''
  );

  const filteredFilters = allSavedFilters.filter(
    (filter) => filter.id !== changedFilter.id
  );
  filteredFilters.push(changedFilter);
  localStorage.setItem(localStorageName, JSON.stringify(filteredFilters));
};
