export const getFloatRublesFromCoins = (
  value: string | number,
  returnFormat: 'float' | 'string' = 'float',
  needToRound = false
) => {
  const prepared = Number(String(value).replace(/,/g, '.'));
  const preparedNumber = needToRound
    ? prepared < 1
      ? Math.ceil(prepared)
      : Math.floor(prepared)
    : prepared;
  const result = (preparedNumber / 100).toFixed(2);

  if (returnFormat === 'string') {
    return result;
  }

  return Number(result);
};
