import React, { Fragment } from 'react';

import { Box, Grid } from '@material-ui/core';

import { Button, Icon, Input } from '@crpt-ui/core';
import { PDFViewer } from '@crpt/shared/components';

import { Modal } from '../../../../common_components/Modal/Modal';
import { SignatureDescriptionConnected } from '../../../../common_components/SignatureDescription/SignatureDescription';
import { translate } from '../../../Translate/Translate';

export const UploadNotificationOperatorDialogView = ({
  open,
  type,
  file,
  onClose,
  getRootProps,
  getInputProps,
  modalActions,
  setFile,
  onFileDialogOpen,
  hasFile,
}) => (
  <Modal
    actions={modalActions}
    open={open}
    title={translate(`Загрузка документа «${type.label}»`)}
    onClose={onClose}
  >
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Input
          disabled
          label="Получатель документа"
          value="ООО «Оператор ЦПРТ», ИНН: 7731376812"
        />
      </Grid>

      <Grid item xs={12}>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Input
            disabled
            InputProps={{
              endAdornment: (
                <Fragment>
                  {hasFile && (
                    <Button
                      color="error"
                      variant="text"
                      onClick={() => {
                        setFile({});
                      }}
                    >
                      <Icon name="Remove" />
                    </Button>
                  )}

                  <Button
                    className="whiteColor"
                    style={{ marginRight: -8, width: 48 }}
                    onClick={onFileDialogOpen}
                  >
                    <Icon name="Upload" />
                  </Button>
                </Fragment>
              ),
            }}
            label={hasFile ? '' : 'Выберите файл формата *.pdf'}
            value={hasFile ? file.file.name : ''}
          />
        </div>
      </Grid>

      {hasFile && (
        <Fragment>
          <Grid xs={12}>
            <PDFViewer
              pagination={true}
              pdfData={file.data}
              title={file.file.name}
              zoomRatio={0.7}
            />
          </Grid>
          <Box mx={-3} my={2}>
            <SignatureDescriptionConnected />
          </Box>
        </Fragment>
      )}
    </Grid>
  </Modal>
);
