import { createActions } from 'redux-actions';

export const {
  fetchDocsForNotification,
  sendDocsForNotification,
  setDocsForNotification,
  setLoading,
  setSubmitting,
} = createActions(
  {
    FETCH_DOCS_FOR_NOTIFICATION: () => {},
    SEND_DOCS_FOR_NOTIFICATION: (payload) => payload,

    SET_DOCS_FOR_NOTIFICATION: (payload) => payload,

    SET_LOADING: (payload) => payload,
    SET_SUBMITTING: (payload) => payload,
  },
  {
    prefix: 'SETTINGS',
  }
);
