import { call, put, takeEvery } from 'redux-saga/effects';

import {
  checkingSystemRequirements,
  setLoading,
  setRequirementsChecked,
} from './CheckingSystemRequirements.actions';

export function* checkingSystemRequirementsSaga({ payload: { items } }) {
  yield put(setLoading(true));
  const checked = [];

  for (let i = 0; i < items.length; i += 1) {
    checked[i] = yield call(items[i].check);
  }

  yield put(setRequirementsChecked(checked));
  yield put(setLoading(false));
}

export const saga = function* watch() {
  yield takeEvery(checkingSystemRequirements, checkingSystemRequirementsSaga);
};
