import React, { useMemo } from 'react';

import { connect } from 'react-redux';

import { Icon } from '@crpt-ui/core';

import { documentStatuses } from '../../../../../constants/index';
import { PageActions } from '../../../../../common_components/PageActions/PageActions';
import {
  isShowCancellation,
  isShowRejection,
  isShowRequestForCancellation,
  isShowRequestForClarification,
  isShowSignButton,
} from '../../ducks/ClosingDocument.selectors';
import { startDocumentSignSaga } from '../../SignClosingDocument/ducks/SignClosingDocument.actions';
import { getCancellationLoading } from '../../EventModal/ducks/EventModal.selectors';
import {
  openEventModal,
  sendCancellation,
} from '../../EventModal/ducks/EventModal.actions';

const ClosingDocumentActions = ({
  startDocumentSignSaga,
  sendCancellation,
  openEventModal,
  isShowSignButton,
  isShowRejection,
  isShowRequestForClarification,
  isShowRequestForCancellation,
  isShowCancellation,
  isCancellationLoading,
}) => {
  const mainDocumentActions = useMemo(
    () => [
      {
        label: 'Подписать с расхождениями',
        onClick: () => {
          openEventModal({
            status: documentStatuses.rejected,
          });
        },
        isShow: isShowRejection,
        variant: 'outlined',
      },
      {
        label: 'Запрос на уточнение',
        onClick: () => {
          openEventModal({
            status: documentStatuses.clarificationRequired,
            successMessage: 'Запрос на уточнение отправлен.',
          });
        },
        isShow: isShowRequestForClarification,
        variant: 'outlined',
      },
      {
        label: 'Аннулировать',
        onClick: sendCancellation,
        isShow: isShowCancellation,
        icon: <Icon name="DocumentCanceled" />,
        loading: isCancellationLoading,
        disabled: isCancellationLoading,
      },
      {
        label: 'Отказать в аннулировании',
        onClick: () => {
          openEventModal({
            status: documentStatuses.revocateDenied,
            successMessage: 'Отказ в аннулировании документа отправлен.',
          });
        },
        isShow: isShowCancellation,
        icon: <Icon name="Cancel" />,
        variant: 'outlined',
      },
      {
        label: 'Запросить аннулирование',
        onClick: () => {
          openEventModal({
            status: documentStatuses.revocationExpected,
            successMessage: 'Запрос на аннулирование документа отправлен.',
          });
        },
        isShow: isShowRequestForCancellation,
        icon: <Icon name="DocumentCanceled" />,
        variant: 'outlined',
      },
      {
        label: 'Подписать',
        onClick: () => startDocumentSignSaga(),
        isShow: isShowSignButton,
      },
    ],
    [
      isShowRejection,
      isShowRequestForClarification,
      sendCancellation,
      isShowCancellation,
      isCancellationLoading,
      isShowRequestForCancellation,
      isShowSignButton,
      openEventModal,
      startDocumentSignSaga,
    ]
  );

  return React.createElement(PageActions, {
    mainActions: mainDocumentActions,
  });
};

ClosingDocumentActions.propTypes = {};

const mapStateToProps = (state) => ({
  isShowRejection: isShowRejection(state),
  isShowSignButton: isShowSignButton(state),
  isShowRequestForClarification: isShowRequestForClarification(state),
  isShowRequestForCancellation: isShowRequestForCancellation(state),
  isShowCancellation: isShowCancellation(state),
  isCancellationLoading: getCancellationLoading(state),
});

const mapDispatchToProps = {
  startDocumentSignSaga,
  sendCancellation,
  openEventModal,
};

export const ClosingDocumentActionsConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClosingDocumentActions);
