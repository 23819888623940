import { makeStyles } from '@material-ui/core';

export const useSignatureDescriptionStyles = makeStyles(() => ({
  wrapper: {
    padding: '24px 24px 24px 64px',
    background: '#f9f9f9',
    fontSize: 13,
    position: 'relative',
  },
  icon: {
    color: '#48c834',
    marginRight: 8,
  },
  boldText: {
    fontWeight: 600,
    fontSize: 13,
  },
  line: {
    lineHeight: '24px',
  },
}));
