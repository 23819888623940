import React, { useCallback, useEffect, useMemo } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { ProfileHeader } from '../../components/ProfileHeader/ProfileHeader';
import { withFormContext } from '../../../../common_components/Form/Form.utils';
import { getRegistered } from '../../Company/ducks/Company.selectors';
import { getShowProductionTab } from '../../Production/ducks/Production.selectors';
import {
  sendEdoRegistrationData,
  setEditing,
} from '../ducks/EdoRegistration.actions';
import {
  getEdoRegistrationEditing,
  getSubmitting,
} from '../ducks/EdoRegistration.selectors';

const Header = ({
  setEditing,
  sendEdoRegistrationData,
  formValues,
  form,
  history,
  isRegistered,
  isEditing,
  isFormErrors,
  isShowProductionTab,
  submitting,
}) => {
  useEffect(() => {
    setEditing(!isRegistered);
  }, [isRegistered, setEditing]);

  const onSubmit = useCallback(() => {
    sendEdoRegistrationData(formValues);
  }, [formValues, sendEdoRegistrationData]);

  const setPreviousStep = useCallback(() => {
    const url = isShowProductionTab
      ? '/profile/production'
      : '/profile/requisites';

    history.replace(url);
  }, [isShowProductionTab, history]);

  const mainActions = useMemo(
    () => [
      {
        label: 'Сохранить изменения',
        onClick: onSubmit,
        isShow: isRegistered && isEditing,
        size: 'lg',
        disabled: isFormErrors || submitting,
        loading: submitting,
      },
      {
        label: 'Предыдущий шаг',
        onClick: setPreviousStep,
        isShow: !isRegistered,
        size: 'lg',
        variant: 'outlined',
      },
      {
        label: 'Отправить',
        onClick: onSubmit,
        isShow: !isRegistered,
        disabled: isFormErrors || submitting,
        loading: submitting,
        size: 'lg',
        tooltip: {
          isHidden: !isFormErrors,
          title: 'Для отправки данных заполните обязательные поля ',
        },
      },
    ],
    [
      isEditing,
      isFormErrors,
      isRegistered,
      onSubmit,
      setPreviousStep,
      submitting,
    ]
  );

  return React.createElement(ProfileHeader, {
    mainActions,
    isEditing,
    isFormErrors,
    form,
    setEditing,
    page: 'EDO',
    isEditable: true,
  });
};

Header.propTypes = {};

const mapStateToProps = (state) => ({
  isRegistered: getRegistered(state),
  isEditing: getEdoRegistrationEditing(state),
  isShowProductionTab: getShowProductionTab(state),
  submitting: getSubmitting(state),
});

const mapDispatchToProps = {
  setEditing,
  sendEdoRegistrationData,
};

export const HeaderConnected = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormContext({ values: true, errors: true, form: true }),
  withRouter
)(Header);
