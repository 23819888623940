import { handleActions } from 'redux-actions';

import {
  closeDemoMessageDialog,
  openDemoMessageDialog,
} from './DemoMessageDialog.actions';

const open = handleActions(
  {
    [closeDemoMessageDialog]: () => false,
    [openDemoMessageDialog]: () => true,
  },
  false
);

export const reducer = {
  open,
};
