import React, { useCallback } from 'react';

import { format } from 'date-fns';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import i18next, { TFunction } from 'i18next';
import { Link as LinkMUI } from '@mui/material';
import endOfDay from 'date-fns/endOfDay';

import { showSuccess, Icon, FilterType } from '@ibox/ui';

import { makeCell } from '../../../../common_components/CellHelpers/MakeCell';
import { cutStringUsingEllipsis } from '../../../../utils';
import { DataTooltip } from '../../../../fragments/Data/data-tooltip';
import {
  documentTypesEnum,
  documentTypesIcons,
  documentTypesValues,
} from '../../../../constants/documents';
import {
  START_YEAR,
  YEARS_AFTER,
} from '../../../../constants/date-picker-years';
import { translate } from '../../../Translate/Translate';

import { ReceiptSelect } from './filters/receipt-select/receipt-select';

import css from './receipt.module.scss';

const receiptTypesNames = {
  ...documentTypesValues,
  [documentTypesEnum.AUTO_DISAGGREGATION]: 'Автодезагрегация',
};

export type Filters = {
  id: string;
  value: string;
  filterLabel: string;
  filterValue: string;
};

export type AllFilters = {
  id: string;
  name: string;
  filters: Filters[];
};

export const limit = 10;
export const requestLimit = 11;

export const documentStatuses = {
  CHECKED_NOT_OK: 'CHECKED_NOT_OK',
  CHECKED_OK: 'CHECKED_OK',
  IN_PROGRESS: 'IN_PROGRESS',
  WAIT_FOR_CONTINUATION: 'WAIT_FOR_CONTINUATION',
  PROCESSING_ERROR: 'PROCESSING_ERROR',
  UNDEFINED: 'UNDEFINED',
  CANCELLED: 'CANCELLED',
};

export const documentStatusesNames = {
  [documentStatuses.CHECKED_NOT_OK]: 'Обработан с ошибками',
  [documentStatuses.CHECKED_OK]: 'Обработан успешно',
  [documentStatuses.IN_PROGRESS]: 'Проверяется',
  [documentStatuses.WAIT_FOR_CONTINUATION]: 'Проверяется',
  [documentStatuses.PROCESSING_ERROR]: 'Ошибка при обработке',
  [documentStatuses.UNDEFINED]: 'Не определен',
  [documentStatuses.CANCELLED]: 'Аннулирован',
};

export const documentStatusesColorsMap = {
  [documentStatuses.CHECKED_NOT_OK]: '#f34747',
  [documentStatuses.CHECKED_OK]: '#48c834',
  [documentStatuses.IN_PROGRESS]: '#f5a020',
  [documentStatuses.WAIT_FOR_CONTINUATION]: '#f5a020',
  [documentStatuses.PROCESSING_ERROR]: '#f34747',
  [documentStatuses.UNDEFINED]: '#868686',
  [documentStatuses.CANCELLED]: '#f34747',
};

export const documentStatusesIconsMap = {
  [documentStatuses.CHECKED_NOT_OK]: <Icon name="DocumentInvalid" />,
  [documentStatuses.CHECKED_OK]: <Icon name="DocumentPublished" />,
  [documentStatuses.IN_PROGRESS]: <Icon name="DocumentProcessing" />,
  [documentStatuses.WAIT_FOR_CONTINUATION]: <Icon name="DocumentProcessing" />,
  [documentStatuses.PROCESSING_ERROR]: <Icon name="Error" />,
  [documentStatuses.UNDEFINED]: <Icon name="DocumentUndefined" />,
  [documentStatuses.CANCELLED]: <Icon name="DocumentCanceled" />,
};

const onCopy = (receiptId: string) => {
  showSuccess({ content: `${receiptId} ${i18next.t('успешно скопирован')}` });
};

const handlerBodyClick = (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

const minDate = new Date('07-01-2021');
export const filtersHistoryName = 'participantsListFiltersHistory';
export const savedFiltersName = 'participantsListSavedFilters';

export const getFilters = (t: TFunction) => [
  {
    id: 'dateFrom',
    label: t('Дата обновления'),
    type: FilterType.DATE_RANGE,
    config: {
      startLabel: t('От'),
      endLabel: t('До'),
      keyFrom: 'dateFrom',
      keyTo: 'dateTo',
      minDate,
      minDateTo: minDate,
      maxDate: endOfDay(new Date((START_YEAR + YEARS_AFTER).toString())),
    },
  },
];

export const getFiltersTabs = ({ t }: { t: TFunction }) => [
  {
    label: t('Все фильтры'),
    value: 0,
  },
  {
    label: t('Избранные фильтры'),
    value: 1,
  },
  {
    label: t('История фильтров'),
    value: 2,
  },
];

export const columns = [
  {
    header: translate('Дата'),
    id: 'receiptDate',
    accessorKey: 'receiptDate',
    Cell: ({ row }) => {
      return (
        <div>
          {row.original.receiptDate &&
            `${format(new Date(row.original.receiptDate), 'dd.MM.yyyy')}`}
        </div>
      );
    },
    width: 120,
    enableSorting: false,
  },
  {
    header: translate('Признак расчета'),
    id: 'type',
    accessorKey: 'type',
    Cell: makeCell({
      icons: documentTypesIcons,
      values: receiptTypesNames,
    }),
    minWidth: 160,
    enableSorting: false,
  },
  {
    header: translate('Статус документа'),
    id: 'status',
    accessorKey: 'status',
    Cell: makeCell({
      colors: documentStatusesColorsMap,
      icons: documentStatusesIconsMap,
      values: documentStatusesNames,
    }),
    minWidth: 184,
    enableSorting: false,
  },
  {
    header: translate('Номер документа'),
    id: 'did',
    enableSorting: false,
    accessorKey: 'did',
    Cell: ({ row }) => {
      const receiptId = row.original.did || '';
      const pgUrl = window.env.REACT_APP_PG_URL;
      const renderValues = () => {
        return (
          <div className={css.wrapper}>
            <LinkMUI
              className={css.link}
              href={`${pgUrl}documents/receipts/${encodeURIComponent(
                receiptId
              )}/basic-info`}
            >
              {cutStringUsingEllipsis(receiptId, 36)}
            </LinkMUI>
          </div>
        );
      };
      const title = useCallback(
        () => (
          <div className={css.wrapperTitle} onClick={handlerBodyClick}>
            <div className={css.text}>{receiptId}</div>
            <CopyToClipboard text={receiptId} onCopy={() => onCopy(receiptId)}>
              <Icon className={css.iconDownload} name="Download" />
            </CopyToClipboard>
            <LinkMUI
              className={css.linkMail}
              href={`mailto:support@crpt.ru?subject=Обращение по документу ${receiptId}&body=${receiptId}`}
            >
              <Icon className={css.iconMessage} name="Message" />
            </LinkMUI>
          </div>
        ),
        [receiptId]
      );

      return (
        <DataTooltip
          alwaysDisplay
          interactive
          arrow={false}
          placement="bottom-start"
          render={renderValues}
          setMaxWidth="none"
          title={title()}
        />
      );
    },
    minWidth: 260,
  },
  {
    header: translate('Продавец'),
    id: 'senderInn',
    enableSorting: false,
    accessorKey: 'senderName',
    Cell: ({ row }) => {
      const renderValues = () => <div>{row.original.senderInn}</div>;
      return (
        <DataTooltip
          alwaysDisplay
          arrow={false}
          render={renderValues}
          title={row.original.senderInn}
        />
      );
    },
    minWidth: 500,
    Filter: ReceiptSelect,
  },
];
