import moment from 'moment';
import {
  FileFormat,
  Format,
  Periodicity,
  TASK_TYPE_ENUM,
  isoStringDateFormat,
} from '../../../constants';
import { getFrontendTaskUrl } from '../../../utils';
import { omit } from 'lodash';

import type { CreateTaskDataAcsUnloads } from '@crpt/shared/services/UnloadTasksService.types';
import type { FormValues } from './AcsCis.types';

interface CreateTaskExtraData {
  isOperator: boolean;
  participantInn?: string;
  pathToUnloadServiceRegistry: string;
}

const prepareInn = (value: string) =>
  value.split(',').map((item) => item.trim());

export const createTask = (
  data: FormValues,
  {
    isOperator,
    participantInn,
    pathToUnloadServiceRegistry,
  }: CreateTaskExtraData
): CreateTaskDataAcsUnloads => {
  const {
    dataStartDate,
    dataEndDate,
    inn,
    typeOfMovement,
    fileFormat,
    ...restData
  } = data;

  const params: any = {
    typeOfMovement,
  };

  if (inn) {
    params.inn = prepareInn(inn);
  } else if (!isOperator) {
    params.inn = [participantInn];
  }

  const adaptedData: any = {
    dataStartDate: moment(dataStartDate).format(isoStringDateFormat),
    dataEndDate: moment(dataEndDate).format(isoStringDateFormat),
  };

  if (fileFormat === FileFormat.XLSX) {
    adaptedData.extraFormats = [fileFormat];
  }

  return {
    ...omit(restData, ['meta']),
    ...adaptedData,
    params: JSON.stringify(params),
    format: Format.CSV,
    frontendTaskUrl: getFrontendTaskUrl(pathToUnloadServiceRegistry),
    periodicity: Periodicity.SINGLE,
    taskTypeShortName: TASK_TYPE_ENUM.CIS_ACS,
  };
};
