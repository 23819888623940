export const keysMap = {
  ИНН: ['INN', 'INNFL'],
  INN: ['INN', 'INNFL'],
  INNLE: 'INN',
  '1.2.643.100.4': 'INN',
  '1.2.643.3.131.1.1': 'INNFL',
  'ИНН ЮЛ': 'INN',
  ОГРН: 'OGRN',
  СНИЛС: 'SNILS',
  commonName: 'CN',
  countryName: 'C',
  emailAddress: 'E',
  givenName: 'G',
  GN: 'G',
  localityName: 'L',
  organizationalUnitName: 'OU',
  organizationName: 'O',
  serialNumber: 'SERIALNUMBER',
  stateOrProvinceName: 'S',
  streetAddress: 'STREET',
  surname: 'SN',
  title: 'T',
};

export const innFlKeysMap = {
  ИНН: 'INNFL',
  INN: 'INNFL',
};

export default class DN {
  keys = [];

  constructor(subject) {
    if (typeof subject === 'string' && subject.length) {
      this.fromString.call(this, subject);
    }
  }

  registerKey(key, value) {
    this.keys.push(key);
    this[key] = value;
  }

  toString() {
    return this.keys.map((key) => [key, this[key]].join('=')).join(', ');
  }

  createPairsMap(pairs) {
    const pairsMap = new Map();

    pairs.forEach((pair) => {
      const pairArray = pair.match(/([^=]+)=(.*)/);

      if (pairArray.length === 3) {
        const [, name, value] = pairArray;

        pairsMap.set(
          name.trim().replace(/^OID\./, ''),
          value.trim().replace(/^"(.*)"$/, '$1')
        );
      }
    });

    return pairsMap;
  }

  fromString(subject) {
    this.keys = [];

    const pairs = subject.split(/, (?=[^,]+=)/);
    const pairsMap = this.createPairsMap(pairs);

    /**
     * Добавлен innFlKeysMap
     * Создается поле INNFL для ИНН физ лица
     * Порядок ключей innFlKeysMap имеет значение
     * Наибольший приоритет у последнего доступного из innFlKeysMap
     * Пример: 'ИНН=000000000000, INN=000000000001' -> INN=000000000001
     * Логика поля INN остается прежней - ИНН физ лица || ИНН юр лица
     */
    Object.entries(innFlKeysMap).forEach(([keyMap, finalKey]) => {
      if (pairsMap.get(keyMap)) {
        this.registerKey(finalKey, pairsMap.get(keyMap));
      }
    });

    /**
     * Обеспечение правильного порядка ключей.
     * Важно для INN, значение должно быть из последнего доступного в keysMap.
     * Пример: 'ИНН ЮЛ=000000000001, ИНН=000000000000' -> INN=000000000001
     */
    Object.entries(keysMap).forEach(([keyMap, finalKey]) => {
      if (pairsMap.get(keyMap)) {
        if (Array.isArray(finalKey)) {
          finalKey.forEach((key) => {
            this.registerKey(key, pairsMap.get(keyMap));
          });
        } else {
          this.registerKey(finalKey, pairsMap.get(keyMap));
        }

        pairsMap.delete(keyMap);
      }
    });

    Array.from(pairsMap, ([key, value]) => {
      this.registerKey(key, value);
    });
  }
}
