import { createActions } from 'redux-actions';

export const { signServiceDeskDocument } = createActions(
  {
    SIGN_SERVICE_DESK_DOCUMENT: () => {},
  },
  {
    prefix: 'SERVICE_DESK_DOCUMENT',
  }
);
