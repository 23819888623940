import { documentStatuses, eventTypes } from '../../../../constants';

/**
 * Подписан с расхождениями
 */
export const rejectedEventIds = [eventTypes.DP_UVUTOCH, 120, 110120];
export const rejectedStatusIds = [
  documentStatuses.rejected,
  documentStatuses.viewedRejected,
];

/**
 * Аннулирован / Ожидает аннулирования
 */
export const revokeEventIds = [eventTypes.DP_PRANNUL, 114002];
export const revokeStatuses = [
  documentStatuses.revocationExpected,
  documentStatuses.revocationRequest,
  documentStatuses.revocated,
  documentStatuses.revocateDenied,
];

/**
 * Ожидается уточнение
 */
export const clarificationEventIds = [110019, eventTypes.DP_UVUTOCH];
