import { handleActions } from 'redux-actions';

import { AuthState } from '../Auth.constants';

import { clearAuth, setAuthState, setUserInfo } from './Auth.actions';

type ProductGroupInfoItem = {
  name: string;
  status: string;
  types: string[];
};

export type UserInfo = {
  authorities: string[];
  client_id: string;
  exp: number;
  full_name: string;
  id: number;
  inn: string;
  jti: string;
  organisation_status: string;
  pid: number;
  showDashboard: boolean;
  showProfileLink: boolean;
  showRMTLink: boolean;
  user_name: null | string;
  user_status: string;
  roles: string[];
  scope: string[];
  product_group_info: ProductGroupInfoItem[];
};

const authState = handleActions(
  {
    [setAuthState]: (_, { payload }) => payload,
  },
  AuthState.PRELOADING
);

const userInfo = handleActions(
  {
    [setUserInfo]: (_, { payload }) => (payload as UserInfo) || {},
    [clearAuth]: () => ({}),
  },
  {}
);

export const reducer = {
  authState,
  userInfo,
};
