import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { HttpMethodEnum } from '../../../../constants/index';
import { Api } from '../../../../common_components/Api/Api';
import { sendFinalize } from '../../Company/ducks/Company.actions';
import { getRegistered } from '../../Company/ducks/Company.selectors';
import { adjustProgress } from '../../Profile.utils';
import { parseEdoOperators } from '../EdoRegistration.utils';

import {
  sendEdoRegistrationData,
  setEditing,
  setEdoOperators,
  setEdoRegistrationData,
  setLoading,
  setSubmitting,
  startEdoRegistrationSaga,
} from './EdoRegistration.actions';

function* edoRegistrationSaga() {
  yield put(setLoading(true));
  yield all([call(fetchEdoDetails), call(fetchEdoOperators)]);
  yield put(setLoading(false));
}

function* fetchEdoDetails() {
  const [success] = yield call(
    Api.request,
    {
      url: '/api/v3/facade/profile/edo/get',
    },
    {
      preloading: false,
    }
  );

  if (success) {
    // edo lite (crpt) should be in first row
    const data = {
      ...success.data,
      edoOperators: success.data.edoOperators.sort((a) =>
        a.edoOperatorId === 16 ? -1 : 0
      ),
    };

    yield put(setEdoRegistrationData(data));
  }
}

function* fetchEdoOperators() {
  const [success] = yield call(
    Api.request,
    {
      cache: true,
      url: '/api/v3/org/edo/getOperators',
    },
    {
      preloading: false,
    }
  );

  if (success) {
    const payload = parseEdoOperators(success.data);
    yield put(setEdoOperators(payload));
  }
}

function* sendEdoRegistrationDataSaga({ payload }) {
  yield put(setSubmitting(true));

  const [success] = yield call(
    Api.request,
    {
      data: payload,
      method: HttpMethodEnum.POST,
      url: '/api/v3/facade/profile/edo/send',
    },
    { withoutDescriptionOfError: true }
  );

  const isRegistered = yield select(getRegistered);

  if (!isRegistered && success) {
    yield call(adjustProgress);
    yield put(sendFinalize());
  }

  if (isRegistered) {
    yield put(setEditing(false));
  }

  yield put(setSubmitting(false));
}

export const saga = function* watch() {
  yield takeLatest(startEdoRegistrationSaga, edoRegistrationSaga);
  yield takeLatest(sendEdoRegistrationData, sendEdoRegistrationDataSaga);
};
