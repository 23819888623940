import { FormRenderProps } from 'react-final-form';
import { DefaultFormValues } from '../AcsUnloads.types';

export enum TypeOfMovement {
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
}

export enum FieldIds {
  TYPE_OF_MOVEMENT = 'typeOfMovement',
  INN = 'inn',
}

export interface FormValues extends DefaultFormValues {
  [FieldIds.TYPE_OF_MOVEMENT]: TypeOfMovement;
  [FieldIds.INN]?: string;
}

export type FormErrors = FormRenderProps['errors'];
