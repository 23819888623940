import React, { useMemo } from 'react';
import { Tooltip } from '@crpt-ui/core';
import { valueWithEllipsis } from '@crpt/shared/utils/valueWithElipsis';

interface DocumentNumberTooltipProps {
  id: string;
}

const DocumentNumberTooltip: React.FC<DocumentNumberTooltipProps> = ({
  id,
}) => {
  const valueAfterEllipsis = useMemo(() => valueWithEllipsis(id), [id]);
  return (
    <Tooltip arrow placement="bottom-start" title={id}>
      <span>{valueAfterEllipsis}</span>
    </Tooltip>
  );
};

export default DocumentNumberTooltip;
