import React, { Fragment } from 'react';

import { useDispatch } from 'react-redux';
import { Box, Grid } from '@mui/material';

import { Button } from '@ibox/ui';

import { setNextStep, setSkipGs1Step } from '../../ducks/StartPage.actions';
import { TitleConnected } from '../Common/Title/Title';

export const UserGroup = () => {
  const dispatch = useDispatch();

  const onNotClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    dispatch(setSkipGs1Step(e));
  };

  const onYesClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    dispatch(setNextStep(e));
  };

  return (
    <Fragment>
      <TitleConnected title="Вы производитель / импортер товаров?" />

      <Box mt={4}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <Button variant="outlined" onClick={onNotClick}>
              Нет
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onYesClick}>Да</Button>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
