import React, { memo, useMemo } from 'react';

import { Document, Page } from 'react-pdf';
import { useStyles } from './PDFViewer.styles';
import Pagination from './Pagination/Pagination';

import { Box } from '@material-ui/core';
import { TopPanel } from './TopPanel';
import type { DocumentProps, PageProps } from 'react-pdf';
import type { ButtonProps } from '@crpt-ui/core';
import type { TopPanelProps } from './TopPanel';
import type { PaginationProps } from '@crpt/shared/components/PDFViewer/Pagination';
import type { ZoomProps } from '@crpt/shared/components/PDFViewer/TopPanel/Zoom';
import type { ActionsProps } from '@crpt/shared/components/PDFViewer/TopPanel/Actions';
import 'react-pdf/dist/Page/AnnotationLayer.css';

export type PDFViewerViewProps = {
  data: ArrayBufferLike;
  error?: boolean;
  zoomRatio?: PageProps['scale'];
  pagination?: boolean;
  onPageIncrement: ButtonProps['onClick'];
  onPageDecrement: ButtonProps['onClick'];
} & Pick<DocumentProps, 'onLoadSuccess' | 'onLoadError'> &
  Pick<PaginationProps, 'page' | 'pages'> &
  TopPanelProps &
  ZoomProps &
  ActionsProps;

const PDFViewerView: React.FC<PDFViewerViewProps> = (props) => {
  const {
    data,
    title,
    page,
    pages,
    zoomRatio,
    onZoomIn,
    onZoomOut,
    onPageIncrement,
    onPageDecrement,
    onLoadSuccess,
    onLoadError,
    onDownload,
    onClose,
    onPrint,
    pagination,
  } = props;

  const classes = useStyles();

  const file = useMemo(
    () => ({
      data,
    }),
    [data]
  );

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <TopPanel
        onClose={onClose}
        onDownload={onDownload}
        onPrint={onPrint}
        onZoomIn={onZoomIn}
        onZoomOut={onZoomOut}
        title={title}
      />

      <Box className={classes.documentWrapper}>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          paddingTop={4}
        >
          <Document
            externalLinkTarget="_blank"
            file={file}
            noData=""
            onLoadError={onLoadError}
            onLoadSuccess={onLoadSuccess}
          >
            {!pagination ? (
              Array.from(
                {
                  length: pages,
                },
                (_, key) => (
                  <Box mb={2}>
                    <Page key={++key} pageNumber={key} scale={zoomRatio} />
                  </Box>
                )
              )
            ) : (
              <Page pageNumber={page} scale={zoomRatio} />
            )}
          </Document>
        </Box>
      </Box>

      {pagination && (
        <Pagination
          onDecrement={onPageDecrement}
          onIncrement={onPageIncrement}
          page={page}
          pages={pages}
        />
      )}
    </Box>
  );
};

export default memo(PDFViewerView);
