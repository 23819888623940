export enum TurnoverTypeCodeEnum {
  AGENT = 'AGENT',
  COMMISSION = 'COMMISSION',
  CONTRACT = 'CONTRACT',
  CONTRACT_RETURN = 'CONTRACT_RETURN',
  SELLING = 'SELLING',
}

export enum TurnoverTypeIdEnum {
  SELLING = 1,
  COMMISSION = 2,
  AGENT = 3,
  CONTRACT = 4,
  CONTRACT_RETURN = 5,
  TRANSFER = 6,
}
