// TODO: Поменять на общий список ролей UserRoleNameMap
import { TFunction } from 'react-i18next';

export const userRoleOptionsAdminOperator = (t: TFunction<'translation'>) => [
  { label: t('Администратор'), value: 'ADMIN' },
  { label: t('Сотрудник'), value: 'USER' },
  { label: t('Модератор РМТ'), value: 'RMT_MODERATOR' },
  { label: t('Супермодератор РМТ'), value: 'RMT_SUPERMODERATOR' },
];

export const userRoleOptionsAdmin = (t: TFunction<'translation'>) => [
  { label: t('Администратор'), value: 'ADMIN' },
  { label: t('Сотрудник'), value: 'USER' },
  { label: t('Сотрудник с правом подтверждения'), value: 'COORDINATOR' },
  { label: t('Редактор РМТ'), value: 'RMT_USER' },
  { label: t('Публикатор РМТ'), value: 'RMT_PUBLISHER' },
];
