import React, { ReactNode } from 'react';

import { Grid } from '@mui/material';

export const SettingsGrid = ({ children }: { children: ReactNode }) => (
  <Grid container spacing={4}>
    {children}
  </Grid>
);

SettingsGrid.Left = ({ children }: { children: ReactNode }) => (
  <Grid item xs={4}>
    {children}
  </Grid>
);

SettingsGrid.Right = ({ children }: { children: ReactNode }) => (
  <Grid item xs={8}>
    {children}
  </Grid>
);
