import { Button, Icon, Tooltip } from '@crpt-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DocumentReprocessingViewModel from './DocumentReprocessing.viewmodel';

interface DocumentReprocessingProps {
  id: string;
  receivedAt: string;
}

const DocumentReprocessing: React.FC<DocumentReprocessingProps> = ({
  id,
  receivedAt,
}) => {
  const classes = useStyles();

  const { disabled, timeBeforeDocumentReprocessing, getButtonProps } =
    DocumentReprocessingViewModel({
      id,
      receivedAt,
    });

  return (
    <Tooltip
      arrow
      classes={classes}
      disableHoverListener={!disabled}
      title={
        <div className={classes.titleWrapper}>
          <div>Документ отправлен на повторную обработку.</div>
          <div>
            Повторить операцию возможно {timeBeforeDocumentReprocessing}
          </div>
        </div>
      }
    >
      <div className={classes.buttonWrapper}>
        <Button icon={<Icon name="Refresh" />} {...getButtonProps()}>
          Обработать повторно
        </Button>
      </div>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  buttonWrapper: {
    display: 'inline-block',
  },
  tooltip: {
    maxWidth: '100%',
  },
  titleWrapper: {
    textAlign: 'center',
    padding: '8px 0',
    lineHeight: '18px',
  },
});

export default DocumentReprocessing;
