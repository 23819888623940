/* eslint-disable */

export class CadesUtil {
  static init() {
    return new Promise((resolve, reject) => {
      if (window.cadesplugin.CreateObjectAsync) {
        window.cadesplugin.then(
          () => {
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        window.addEventListener(
          'message',
          (event) => {
            if (event.data === 'cadesplugin_loaded') {
              resolve();
            } else if (event.data === 'cadesplugin_load_error') {
              reject(event);
            }
          },
          false
        );
        window.postMessage('cadesplugin_echo_request', '*');
      }
    });
  }

  static async getVersion() {
    if (window.cadesplugin.CreateObjectAsync) {
      try {
        const info = {};
        await window.cadesplugin;
        const about = await window.cadesplugin.CreateObjectAsync(
          'CAdESCOM.About'
        );
        info.cadesVersion = await about.PluginVersion;
        info.cspVersion = await about.CSPVersion();
        if (!info.cadesVersion) {
          info.cadesVersion = await about.Version;
        }
        info.cadesVersion = await info.cadesVersion.toString();
        info.cspVersion = await info.cspVersion.toString();
        return info;
      } catch (e) {
        console.error('err', e);
      }
    } else {
      try {
        const info = {};
        const about = cadesplugin.CreateObject('CAdESCOM.About');
        info.cadesVersion = about.PluginVersion;
        if (!info.cadesVersion) {
          info.cadesVersion = about.Version;
        }
        info.cspVersion = about.CSPVersion();
        info.cadesVersion = info.cadesVersion.toString();
        info.cspVersion = info.cspVersion.toString();
        return info;
      } catch (e) {
        console.error('err', e);
      }
    }
  }

  static Base64 = {
    keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

    encode: (input) => {
      let output = '';

      const data = CadesUtil.Base64.utf8_encode(input);
      let i = 0;

      while (i < data.length) {
        const chr1 = data.charCodeAt(i++);
        const chr2 = data.charCodeAt(i++);
        const chr3 = data.charCodeAt(i++);

        const enc1 = chr1 >> 2;
        const enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        let enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        let enc4 = chr3 & 63;

        if (isNaN(chr2)) {
          enc3 = 64;
          enc4 = 64;
        } else if (isNaN(chr3)) {
          enc4 = 64;
        }

        output +=
          CadesUtil.Base64.keyStr.charAt(enc1) +
          CadesUtil.Base64.keyStr.charAt(enc2) +
          CadesUtil.Base64.keyStr.charAt(enc3) +
          CadesUtil.Base64.keyStr.charAt(enc4);
      }

      return output;
    },

    decode: (input) => {
      let output = '';
      let i = 0;

      // eslint-disable-next-line no-useless-escape
      const data = input.replace(/[^A-Za-z0-9+\/=]/g, '');

      while (i < data.length) {
        const enc1 = CadesUtil.Base64.keyStr.indexOf(data.charAt(i++));
        const enc2 = CadesUtil.Base64.keyStr.indexOf(data.charAt(i++));
        const enc3 = CadesUtil.Base64.keyStr.indexOf(data.charAt(i++));
        const enc4 = CadesUtil.Base64.keyStr.indexOf(data.charAt(i++));

        const chr1 = (enc1 << 2) | (enc2 >> 4);
        const chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        const chr3 = ((enc3 & 3) << 6) | enc4;

        output += String.fromCharCode(chr1);

        if (enc3 !== 64) {
          output += String.fromCharCode(chr2);
        }
        if (enc4 !== 64) {
          output += String.fromCharCode(chr3);
        }
      }

      output = CadesUtil.Base64.utf8_decode(output);

      return output;
    },

    utf8_encode: (string) => {
      const data = string.replace(/\r\n/g, '\n');
      let result = '';

      for (let n = 0; n < data.length; n++) {
        const c = data.charCodeAt(n);

        if (c < 128) {
          result += String.fromCharCode(c);
        } else if (c > 127 && c < 2048) {
          result += String.fromCharCode((c >> 6) | 192);
          result += String.fromCharCode((c & 63) | 128);
        } else {
          result += String.fromCharCode((c >> 12) | 224);
          result += String.fromCharCode(((c >> 6) & 63) | 128);
          result += String.fromCharCode((c & 63) | 128);
        }
      }

      return result;
    },

    utf8_decode: (utftext) => {
      let result = '';
      let i = 0;

      while (i < utftext.length) {
        const c = utftext.charCodeAt(i);

        if (c < 128) {
          result += String.fromCharCode(c);
          i++;
        } else if (c > 191 && c < 224) {
          const c2 = utftext.charCodeAt(i + 1);
          result += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
          i += 2;
        } else {
          const c2 = utftext.charCodeAt(i + 1);
          const c3 = utftext.charCodeAt(i + 2);
          result += String.fromCharCode(
            ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
          );
          i += 3;
        }
      }

      return result;
    },
  };

  static prepareError(error) {
    let code = null;
    const match = error.message.match(/\((.*?)\)$/);
    if (match) code = match[1];

    if (code) {
      switch (code) {
        case '0x8000FFFF':
          return 'Сертификат УКЭП не действителен';
        case '0x800B010A':
          return 'Не установлен корневой сертификат';
        case '0x800704C7':
          return 'Введен неправильный пароль';
        case '0x8010006E':
          return null;
        default:
          return error.message.replace(/\(.*?\)/g, '');
      }
    } else {
      return 'Введен неправильный пароль';
    }
  }
}
