/** Типы документов ЭДО */
export const EdoDocumentTypes: Record<string, number> = {
  unknown: 0,
  nf: -1,
  nfSD2: -2,
  nfSD3: -3,
  nfSD4: -4,
  nfSD5: -5,
  nfSD6: -6,
  nfSD7: -7,
  nfSD8: -8,
  nfSD9: -9,
  nfSD10: -10,
  nfSD11: -11,
  nfSD12: -12,
  nfSD13: -13,
  nfSD14: -14,
  nfSD15: -15,
  nfSD16: -16,
  nfSD17: -17,
  nfSD18: -18,
  nfSD19: -19,
  nfSD20: -20,
  nfSD21: -21,
  nfSD22: -22,
  nfSD23: -23,
  nfSD24: -24,
  nfSD25: -25,
  nfSD26: -26,
  nfSD27: -27,
  nfSD28: -28,
  nfSD29: -29,
  nfSD31: -31,
  nfSD32: -32,
  nfSD33: -33,
  nfSD34: -34,
  nfSD35: -35,
  nfSD36: -36,
  nfSD38: -38,
  nfSD39: -39,
  nfSD40: -40,
  nfSD41: -41,
  nfSD42: -42,
  nfSD43: -43,
  nfSD44: -44,
  nfSD45: -45,
  nfSD46: -46,
  nfSD47: -47,
  nfSD48: -48,
  nfSD100: -100,
  nfSD101: -101,
  nfBill: -10000,
  updDop: 100,
  updSf: 102,
  updSfDop: 104,
  updDopI: 300,
  updSfI: 302,
  updSfDopI: 304,
  upd820Dop: 500,
  upd820Sf: 502,
  upd820SfDop: 504,
  upd820DopI: 800,
  upd820SfI: 802,
  upd820SfDopI: 804,
  ukdDis: 200,
  ukdSf: 202,
  ukdSfDis: 204,
  ukdDisI: 400,
  ukdSfI: 402,
  ukdSfDisI: 404,
  ukd736Dis: 600,
  ukd736Sf: 602,
  ukd736SfDis: 604,
  ukd736DisI: 700,
  ukd736SfI: 702,
  ukd736SfDisI: 704,
};

export const EdoDocumentTypeTitles = {
  [EdoDocumentTypes.nf]: 'Неформализованный',
  [EdoDocumentTypes.nfSD2]: 'Договор о подключении к ГИС МТ',
  [EdoDocumentTypes.nfSD3]:
    'Заявление о присоединении к Договору безвозмездного пользования движимым имуществом РЭ',
  [EdoDocumentTypes.nfSD4]: 'Договор на услуги по предоставлению КМ',
  [EdoDocumentTypes.nfSD5]:
    'Заявление о присоединении к Договору безвозмездного пользования движимым имуществом РВ',
  [EdoDocumentTypes.nfSD6]: 'Договор ЭДО',
  [EdoDocumentTypes.nfSD7]:
    'Заявление о присоединении к Договору безвозмездного пользования движимым имуществом',
  [EdoDocumentTypes.nfSD8]: 'Договор по предоставлению РЭ УОТ путем УД',
  [EdoDocumentTypes.nfSD9]: 'Заявка Участника о предоставлении Оборудования РВ',
  [EdoDocumentTypes.nfSD10]:
    'Заявка Участника о предоставлении Оборудования РЭ',
  [EdoDocumentTypes.nfSD11]: 'Заявка УОТ о предоставлении РЭ путем УД',
  [EdoDocumentTypes.nfSD12]: 'Акт приема-передачи Оборудования',
  [EdoDocumentTypes.nfSD13]: 'Акт возврата Оборудования',
  [EdoDocumentTypes.nfSD14]:
    'Акт проверки состояния и условий эксплуатации Оборудования',
  [EdoDocumentTypes.nfSD15]: 'Акт выполненных работ',
  [EdoDocumentTypes.nfSD16]:
    'Уведомление о праве пользования РЭ посредством УД',
  [EdoDocumentTypes.nfSD17]: 'Дополнительное соглашение',
  [EdoDocumentTypes.nfSD18]: 'Дополнительное соглашение (нерезидент)',
  [EdoDocumentTypes.nfSD19]: 'Соглашение об электронном взаимодействии',
  [EdoDocumentTypes.nfSD20]: 'Письмо об осуществлении оплаты третьей стороной',
  [EdoDocumentTypes.nfSD21]:
    'Договор на оказание возмездных услуг по предоставлению КМ (с нерезидентом)',
  [EdoDocumentTypes.nfSD22]: 'Договор по предоставлению РЭ (с нерезидентом)',
  [EdoDocumentTypes.nfSD23]:
    'Договор по предоставлению РЭ УОТ путем УД (с нерезидентом)',
  [EdoDocumentTypes.nfSD24]: 'Информационное письмо',
  [EdoDocumentTypes.nfSD25]: 'Уведомление от Оператора',
  [EdoDocumentTypes.nfSD26]:
    'Заявка Участника о предоставлении Оборудования РЭ (нерезидент)',
  [EdoDocumentTypes.nfSD27]:
    'Заявка Участника о предоставлении РЭ путем УД (нерезидент)',
  [EdoDocumentTypes.nfSD28]: 'Уведомление УОТ о доставке РВ',
  [EdoDocumentTypes.nfSD29]: 'Акт о невозможности исполнения',
  [EdoDocumentTypes.nfSD31]:
    'Заявка на предоставление доступа к API СУЗ для резидентов',
  [EdoDocumentTypes.nfSD32]:
    'Заявка на предоставление доступа к API СУЗ для нерезидентов',
  [EdoDocumentTypes.nfSD33]: 'Уведомление об изменении реквизитов',
  [EdoDocumentTypes.nfSD34]: 'Уведомление о расторжении договора',
  [EdoDocumentTypes.nfSD35]: 'Предоставление доверенности',
  [EdoDocumentTypes.nfSD36]: 'Запрос на изменение данных в ГИС',
  [EdoDocumentTypes.nfSD38]: 'Досудебная претензия',
  [EdoDocumentTypes.nfSD39]: 'Уведомление о задолженности',
  [EdoDocumentTypes.nfSD40]: 'Уведомление о погашении задолженности',
  [EdoDocumentTypes.nfSD41]: 'Уведомление о разрешении разногласий',
  [EdoDocumentTypes.nfSD42]: 'Иное',
  [EdoDocumentTypes.nfSD43]: 'Уведомление на одностороннее расторжение',
  [EdoDocumentTypes.nfSD44]: 'Уведомление на двустороннее расторжение',
  [EdoDocumentTypes.nfSD45]: 'Договор поставки рассрочка',
  [EdoDocumentTypes.nfSD46]: 'Спецификация к договору поставки рассрочка',
  [EdoDocumentTypes.nfSD47]: 'Договор поставки предоплата',
  [EdoDocumentTypes.nfSD48]: 'Спецификация к договору поставки предоплата',
  [EdoDocumentTypes.nfSD100]: 'Счет на оплату',
  [EdoDocumentTypes.nfSD101]: 'Акт об утрате имущества',
  [EdoDocumentTypes.nfBill]: 'Акт сверки',
  [EdoDocumentTypes.updDop]: 'Накладная',
  [EdoDocumentTypes.updSf]: 'Счет-фактура',
  [EdoDocumentTypes.updSfDop]: 'УПД',
  [EdoDocumentTypes.upd820Sf]: 'Счет-фактура',
  [EdoDocumentTypes.upd820SfDop]: 'УПД',
  [EdoDocumentTypes.upd820Dop]: 'Накладная',
  [EdoDocumentTypes.ukdDis]: 'УКД (приказ 189)',
  [EdoDocumentTypes.ukdSf]: 'УКД (приказ 189)',
  [EdoDocumentTypes.ukdSfDis]: 'УКД (приказ 189)',
  [EdoDocumentTypes.updDopI]: 'УПД(и)',
  [EdoDocumentTypes.updSfI]: 'УПД(и)',
  [EdoDocumentTypes.updSfDopI]: 'УПД(и)',
  [EdoDocumentTypes.upd820SfI]: 'УПД(и)',
  [EdoDocumentTypes.upd820SfDopI]: 'УПД(и)',
  [EdoDocumentTypes.upd820DopI]: 'УПД(и)',
  [EdoDocumentTypes.ukdDisI]: 'УКД(и) (приказ 189)',
  [EdoDocumentTypes.ukdSfI]: 'УКД(и) (приказ 189)',
  [EdoDocumentTypes.ukdSfDisI]: 'УКД(и) (приказ 189)',
  [EdoDocumentTypes.ukd736Sf]: 'УКД (приказ 736)',
  [EdoDocumentTypes.ukd736Dis]: 'УКД (приказ 736)',
  [EdoDocumentTypes.ukd736SfDis]: 'УКД (приказ 736)',
  [EdoDocumentTypes.ukd736SfI]: 'УКД(и) (приказ 736)',
  [EdoDocumentTypes.ukd736DisI]: 'УКД(и) (приказ 736)',
  [EdoDocumentTypes.ukd736SfDisI]: 'УКД(и) (приказ 736)',
};

export type EdoDocumentTypesType =
  typeof EdoDocumentTypes[keyof typeof EdoDocumentTypes];
