import {
  PackageTypeEnum,
  CisStatusEnum,
  EliminationReasonEnum,
  OldCisPackageTypeEnum,
  TurnoverTypeCodeEnum,
  GeneralPackageTypeEnum,
  UnifiedCisStatusEnum,
} from '../constants';
import { ProductGroupIdEnum } from '../../../constants';
import { GroupedCisListDTO } from './GroupedCisListDTO';

export interface PermitDocDTO {
  permitDocNumber: string;
  permitDocType: PermitDocTypeEnum;
  permitDocDate: string;
  wellNumber?: string[];
}

export interface ExpirationDTO {
  storageConditionId?: number;
  expirationStorageDate?: string;
}

export enum PermitDocTypeEnum {
  CONFORMITY_CERTIFICATE = 1,
  CONFORMITY_DECLARATION = 2,
  CONFORMITY_CERTIFICATE_OR_DECLARATION = 3,
  STATE_REGISTRATION_CERTIFICATE = 4,
}

export enum ExtendedStatusEnum {
  NON_INDIVIDUAL = 2,
  EAS_RESPOND_WAITING = 3,
  EAS_RESPOND_NOT_OK = 4,
  FTS_RESPOND_WAITING_NON_INDIVIDUAL = 6,
  INDIVIDUAL_WAITING_NON_INDIVIDUAL = 7,
  WAIT_SHIPMENT = 8,
  FTS_RESPOND_NOT_OK_NON_INDIVIDUAL = 9,
  REMARK_RETIRED = 11,
  WAIT_TRANSFER_TO_OWNER = 12,
  WAIT_REMARK = 13,
  RETIRED_CANCELLATION = 14,
  FTS_RESPOND_NOT_OK = 27,
  FTS_RESPOND_WAITING = 28,
  FTS_CONTROL = 29,
  IN_GRAY_ZONE = 30,
}

export enum EmissionCountryCodeEnum {
  ARMENIA = 1,
  BELARUS = 2,
  KAZAKHSTAN = 3,
  KYRGYZ_REPUBLIC = 4,
  RUSSIAN_FEDERATION = 5,
}

export enum ReasonForRelabelingEnum {
  KM_SPOILED = 1,
  REMOTE_SALE_RETURN = 28,
  LEGAL_RETURN = 29,
  RETAIL_RETURN = 27,
  EEC_EXPORT_RETURN = 31,
  DESCRIPTION_ERRORS = 4,
  INTERNAL_RETURN = 30,
}

export enum EmissionTypesEnum {
  LOCAL = 1,
  FOREIGN = 2,
  REMAINS = 3,
  CROSSBORDER = 4,
  REMARK = 5,
  COMMISSION = 6,
}

export enum ExportCountryCodeEnum {
  BELARUS = '112',
  KYRGYZ_REPUBLIC = '417',
  KAZAKHSTAN = '398',
  ARMENIA = '051',
  RUSSIAN_FEDERATION = '643',
}

export interface ModInfoDTO {
  modId: number;
  name?: string;
  address?: string;
  kpp?: string;
}

export interface CisDTO {
  agentInn?: string;
  applicationDate?: string;
  capacity?: string;
  children?: string[];
  cis: string;
  correctRest?: boolean;
  country?: string;
  declarationDate?: string;
  declarationRegNumber?: string;
  eliminationReason?: EliminationReasonEnum;
  eliminationReasonOther?: string;
  emissionDate?: string;
  emissionType?: EmissionTypesEnum;
  expirations?: ExpirationDTO[];
  expirationDate?: string;
  exporterTaxpayerId?: string;
  exportCountryCode?: ExportCountryCodeEnum;
  extendedPackageType?: PackageTypeEnum;
  extendedStatus?: ExtendedStatusEnum;
  ftsDecisionCode?: string;
  gtin: string;
  generalPackageType?: GeneralPackageTypeEnum;
  introducedDate?: string;
  markWithdraw?: boolean;
  modInfo?: ModInfoDTO;
  mrp?: number;
  nextCises?: string[];
  ownerInn?: string;
  packageType?: OldCisPackageTypeEnum;
  parent?: string;
  permitDocs?: PermitDocDTO[];
  prevCises?: string[];
  previousAgentInns?: string[];
  previousCis?: string;
  producerInn?: string;
  productGroup?: ProductGroupIdEnum;
  productName?: string;
  productWeightGr?: string;
  productionDate?: string;
  pte?: number;
  remainsImport?: string;
  requestedCis: string;
  rest?: string;
  grayZone?: boolean;
  gzPacks?: string;
  gzBlocks?: string;
  retiredDate?: number;
  serialNumber?: string;
  soldUnitCount?: number;
  status?: CisStatusEnum;
  turnoverType?: TurnoverTypeCodeEnum;
  tnved?: string;
  tnved10?: string;
  typeOfEmission?: string;
  quantityInPack?: string;
  emissionCountryCode?: EmissionCountryCodeEnum;
  volumeSpecialPack?: string;
  vsd?: string;
  remarkingCause?: ReasonForRelabelingEnum;
  specialAttributes?: {
    brnc?: string;
    cpid?: string;
    cptype?: string;
    crt?: string;
    crtd?: string;
    crtn?: string;
    decdate?: string;
    decid?: string;
    decr?: string;
    dv?: string;
    elrOther?: string;
    en?: string;
    errors?: string;
    eti?: string;
    ftsDC?: string;
    hash?: string;
    ld?: string;
    ldt?: string;
    mrp?: string;
    nxc?: string;
    operatorId?: string;
    pag?: string;
    plid?: string;
    price?: string;
    pvc?: string;
    rett?: string;
    rimp?: string;
    rtd?: string;
    soldCount?: string;
    srid?: string;
    trn?: string;
    vat?: string;
    vsd?: string;
    ctr?: string;
    info_for_circulation: {
      permitDocDate?: number;
      permitDocNumber?: string;
      permitDocType?: PermitDocTypeEnum;
    };
  };
}

export interface CisSearchDTO {
  cis: string;
  gtin?: string;
  producerInn?: string;
  status?: UnifiedCisStatusEnum;
  statusExt?: ExtendedStatusEnum;
  emissionDate?: string;
  applicationDate?: string;
  packageType?: OldCisPackageTypeEnum;
  packageTypeExt?: PackageTypeEnum;
  ownerInn?: string;
  brand?: string;
  tnVed?: string;
  tnVed10?: string;
  productName?: string;
  serialNumber?: string;
  agentInn?: string;
  emissionType?: EmissionTypesEnum;
  productGroup?: ProductGroupIdEnum;
  paid?: string;
  childs?: string[];
  cisPrintView?: string;
  parent?: string;
  eliminationReason?: EliminationReasonEnum;
  expireDate?: string;
  prVetDocument?: string;
  prevCis?: string;
  prevCises?: string[];
  nextCises?: string[];
  remarks?: {
    curr?: string;
    prev?: string;
  };
  mrp?: number;
  introducedDate?: string;
  retiredDate?: string;
  exporterName?: string;
  exporterTaxpayerId?: string;
  previousAgentInn?: string;
  previousAgentsInns?: string[];
  turnoverType?: TurnoverTypeCodeEnum;
  color?: string;
  productSize?: string;
  country?: string;
  soldBy?: string;
  soldByOwner?: boolean;
  grayZone?: {
    inGrayZone?: boolean;
    aggCount?: number;
    gzPacks?: number;
    gzBlocks?: number;
  };
  serviceProviderId?: string;
  serviceProviderType?: string;
  permitDocs?: PermitDocDTO[];
  productionDate?: string;
  modid?: string;
  generalPackageType?: GeneralPackageTypeEnum;
  childsPrintView?: string[];
}

export interface CisSearchRequestDTO {
  filter: CisSearchRequestFilterDTO;
  pagination?: CisSearchRequestPaginationDTO;
}

export interface CisSearchRequestFilterDTO {
  codesPrintView?: string[];
  productGroups?: ProductGroupIdEnum[];
  applicationDateFrom?: string;
  applicationDateTo?: string;
  cises?: string[];
  generalPackageTypes?: GeneralPackageTypeEnum[];
  parent?: string;
  gtins?: string[];
  sn?: string;
  packageTypes?: OldCisPackageTypeEnum[];
  emissionTypes?: EmissionTypesEnum[];
  producerInns?: string[];
  turnoverTypes?: TurnoverTypeCodeEnum[];
  states?: {
    status?: number;
    statusExt?: number;
  }[];
  ownerInns?: string[];
  introducedDateFrom?: string;
  introducedDateTo?: string;
  productionDateFrom?: string;
  productionDateTo?: string;
  isAggregated?: boolean;
  emissionDateFrom?: string;
  emissionDateTo?: string;
  tnved?: string;
  tnved10?: string;
  eliminationReasons?: EliminationReasonEnum[];
  prVetDoc?: string;
  countChildren?: number;
  haveChildren?: boolean;
  paidPresented?: boolean;
  packageTypeExts?: PackageTypeEnum[];
  serviceProviderIdPresented?: boolean;
  serviceProviderTypes?: string[];
  hasParent?: boolean;
  packageTypesWithChild?: {
    generalPackageType?: GeneralPackageTypeEnum;
    haveChildren?: boolean;
  };
}

export interface CisSearchRequestPaginationDTO {
  pageDir: 'NEXT' | 'PREV';
  limit: number;
  emissionDate?: string;
  cis?: string;
}

export interface CisSearchResponseDTO {
  result: CisSearchDTO[];
}

export enum CisSearchModeEnum {
  STANDARD = 'standard',
  EMERGENCY = 'emergency',
}

export interface GroupedCisListResponse {
  results: GroupedCisListDTO[];
}

export const MAX_CIS_SEARCH_COUNT = 1000;

export interface CisSearchResponseDTO {
  result: CisSearchDTO[];
}
