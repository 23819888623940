import { Button, Icon } from '@crpt-ui/core';
import { Grid, Popover as MuiPopover, withStyles } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';

const Popover = withStyles({
  paper: {
    borderRadius: 4,
    boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.2)',
    maxWidth: '400px',
    minWidth: '320px',
    padding: 16,
  },
})(MuiPopover);

const FilterButton = withStyles((theme) => ({
  active: {},
  root: {
    '&$active': {
      color: theme.palette.text.primary,
    },
    color: '#b8b8b8',
  },
}))(Button);

const DatatableFilter = ({ column }) => {
  const initialValues = React.useMemo(
    () => ({
      value: column.filterValue,
    }),
    [column.filterValue]
  );

  const [anchorEl, setAnchorEl] = React.useState();

  const isOpen = React.useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleClose = React.useCallback(() => setAnchorEl(null), []);
  const handleClick = React.useCallback(
    (event) => setAnchorEl(event.currentTarget),
    []
  );

  const onSubmit = React.useCallback(
    (values) => {
      column.setFilter(values.value);
      handleClose();
    },
    [column, handleClose]
  );

  const onReset = React.useCallback(() => {
    column.setFilter(undefined);
    handleClose();
  }, [column, handleClose]);

  const popoverProps = {
    anchorEl: anchorEl,
    anchorOrigin: {
      horizontal: 'center',
      vertical: 'bottom',
    },
    marginThreshold: 8,
    onClose: handleClose,
    open: isOpen,
    transformOrigin: {
      horizontal: 'center',
      vertical: -12,
    },
    transitionDuration: 0,
  };

  const hasFilterValue = React.useMemo(
    () => !_.isEmpty(column.filterValue),
    [column.filterValue]
  );

  const active = React.useMemo(
    () => [isOpen, hasFilterValue].includes(true),
    [isOpen, hasFilterValue]
  );

  const canReset = React.useMemo(
    () => _.get(column, 'canResetFilter', true),
    [column]
  );

  return (
    <>
      <FilterButton
        active={active}
        icon={<Icon name={column.filterIconName || 'filter'} />}
        onClick={handleClick}
        size="sm"
        data-test={`button.filter-${column.id}`}
        style={{
          marginTop: -8,
          marginBottom: -8,
        }}
        variant="text"
      />
      <Popover {...popoverProps}>
        <Form initialValues={initialValues} onSubmit={onSubmit}>
          {({ handleSubmit, form, ...formState }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div
                  style={{
                    boxShadow: 'inset 0px -1px 0px #f2f2f2',
                    margin: '-16px -16px 0',
                    padding: '8px 16px',
                    overflow: 'hidden',
                  }}
                >
                  <Grid
                    alignItems="center"
                    container
                    justify="space-between"
                    spacing={2}
                    wrap="nowrap"
                  >
                    <Grid item>
                      <div
                        style={{
                          fontSize: 11,
                          fontWeight: 700,
                          letterSpacing: '.02em',
                          lineHeight: 16 / 11,
                          textTransform: 'uppercase',
                        }}
                      >
                        {column.filterHeader || column.render('Header')}
                      </div>
                    </Grid>
                    <Grid item>
                      <Button
                        icon={<Icon name="close" />}
                        onClick={handleClose}
                        size="sm"
                        data-test="button.close"
                        style={{
                          marginRight: -8,
                        }}
                        variant="text"
                      />
                    </Grid>
                  </Grid>
                </div>
                <div
                  style={{
                    margin: '8px -16px 8px',
                    padding: 16,
                  }}
                >
                  {column.render('Filter', {
                    form,
                    formState,
                  })}
                </div>
                <div
                  style={{
                    margin: '0 -16px -16px',
                    padding: '0 16px 16px',
                  }}
                >
                  <Grid container spacing={2} wrap="nowrap">
                    <Grid item>
                      <Button
                        disabled={
                          _.isEqual(
                            initialValues.value,
                            formState.values.value
                          ) || Object.keys(formState.errors).length > 0
                        }
                        size="sm"
                        data-test="button.submit"
                        type="submit"
                      >
                        Применить
                      </Button>
                    </Grid>
                    {canReset && (
                      <Grid item>
                        <Button
                          disabled={!hasFilterValue}
                          icon={<Icon name="remove" />}
                          onClick={onReset}
                          size="sm"
                          data-test="button.remove"
                        type="reset"
                        variant="text"
                      />
                    </Grid>)}
                  </Grid>
                </div>
              </form>
            );
          }}
        </Form>
      </Popover>
    </>
  );
};

DatatableFilter.propTypes = {
  column: PropTypes.object.isRequired,
};

export default DatatableFilter;
