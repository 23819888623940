import React, { memo } from 'react';
import { Form } from 'react-final-form';
import { Box } from '@crpt/material';
import { Typography, Icon, Divider, Button } from '@crpt-ui/core';
import { Content } from '@crpt/shared/components';
import CisUnloadsViewModel from './CisUnloads.viewmodel';
import {
  Header,
  AdditionalData,
  CommonInformation,
  Receiver,
} from './components';

import type { CisUnloadsViewModelOptions } from './CisUnloads.viewmodel';
import GtinDialog from './components/GtinDialog/GtinDialog';
import { PackageTypeOption } from './CisUnloads.types';

interface CisUnloadsProps
  extends Omit<CisUnloadsViewModelOptions, 'pathToUnloadServiceRegistry'> {
  pathToUnloadServiceRegistry?: string;
  packageTypeOptions: PackageTypeOption[];
}

const CisUnloads: React.FC<CisUnloadsProps> = ({
  pathToUnloadServiceRegistry = '/unload-service',
  packageTypeOptions,
  productGroupCode,
  ...restProps
}) => {
  const vm = CisUnloadsViewModel({
    pathToUnloadServiceRegistry,
    packageTypeOptions,
    productGroupCode,
    ...restProps,
  });

  return (
    <Box flexGrow={1}>
      <Content>
        <Form
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          form={vm.formInstance}
          onSubmit={vm.onSubmit}
          subscription={{
            dirty: true,
            hasValidationErrors: true,
          }}
        >
          {({ dirty, handleSubmit, hasValidationErrors }) => {
            return (
              <>
                <Header
                  hasErrors={hasValidationErrors}
                  loading={vm.isUploading}
                  onSubmit={handleSubmit}
                  pathToUnloadServiceRegistry={pathToUnloadServiceRegistry}
                />
                <Divider />
                <Box
                  sx={{
                    flexGrow: 1,
                    py: 4,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mb: 6,
                      mx: 4,
                    }}
                  >
                    <Typography
                      style={{
                        marginBottom: 0,
                      }}
                      variant="h3"
                    >
                      {vm.t('UnloadService.Task.CisUnloads.Title')}
                    </Typography>
                    <Button
                      color="secondary"
                      disabled={!(dirty || vm.isGtinsAdded)}
                      icon={<Icon name="cancel" />}
                      onClick={vm.resetForm}
                      variant="text"
                    >
                      {vm.t('UnloadService.Task.clear')}
                    </Button>
                  </Box>

                  <Box mb={4} mx={4}>
                    <CommonInformation />
                  </Box>
                  <Box mb={4}>
                    <Divider />
                  </Box>

                  <Box mb={4} mx={4}>
                    <Receiver usersList={vm.usersList} />
                  </Box>
                  <Box mb={4}>
                    <Divider />
                  </Box>

                  <Box mb={4} mx={4}>
                    <AdditionalData
                      packageTypeOptions={vm.filteredPackageTypeOptions}
                      statusOptions={vm.statusOptions}
                      handleEditGtinOpen={vm.handleEditGtinOpen}
                      isFoivOrOperator={vm.isFoivOrOperator}
                      includeGtins={vm.includeGtins}
                      productGroupCode={productGroupCode}
                    />
                  </Box>
                </Box>
              </>
            );
          }}
        </Form>
        <GtinDialog
          onClose={vm.closeEditGtinModal}
          isOpen={vm.isEditGtinModalOpen}
          onSubmit={vm.handleEditGtinSubmit}
          {...vm.editGtinsState}
        />
      </Content>
    </Box>
  );
};

export default memo(CisUnloads);
