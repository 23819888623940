import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

const getLocalState = (state) => get(state, 'ParticipantDetails');

const getProfileState = (state) => get(state, 'Profile');

export const results = createSelector(getLocalState, (state) =>
  get(state, 'results')
);

export const updated = createSelector(getLocalState, (state) =>
  get(state, 'updated')
);

export const loading = createSelector(getLocalState, (state) =>
  get(state, 'loading')
);

export const companyInfo = createSelector(getProfileState, (state) =>
  get(state, 'Company')
);
