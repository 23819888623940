import React from 'react';

import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { createForm } from '@crpt-ui/form';

export const withForm =
  ({ schema, subscribeFields = [] }) =>
  (Component) => {
    const formInstance = (props) => {
      return createForm({
        schema,
        subscribeFields,
        initialValues: props.initialValues,
        onSubmit: () => {},
        mutators: { ...arrayMutators },
      });
    };

    const FormWrapper = (props) => (
      <Form form={formInstance(props)}>
        {(formProps) => <Component {...props} {...formProps} />}
      </Form>
    );

    return FormWrapper;
  };
