import React, { useEffect, useCallback } from 'react';

import { connect } from 'react-redux';
import { debounce, get } from 'lodash';

import { Autocomplete } from '@crpt-ui/core';

import * as selectors from './ducks/AutocompleteFilter.selectors';
import * as actions from './ducks/AutocompleteFilter.actions';

const AutocompleteFilterComp = ({
  error,
  loaded,
  onMount,
  onUnmount,
  results,
  otherProps,
  prepareOptions,
  label,
  name,
  optionLabel = 'label',
}) => {
  const { onInputChange, form, formState, ...rest } = otherProps;

  const fieldName = name || 'value';

  useEffect(() => {
    onMount();
    return onUnmount;
  }, []);

  const options = prepareOptions(results);

  const memoizedInputChange = useCallback((_event, value, reason) => {
    if (value.trim() && reason === 'input') {
      onInputChange && onInputChange(value);
    }
  }, []);

  const value = () => {
    const formStateValue = get(formState.values, fieldName, '');

    return { value: formStateValue, [optionLabel]: formStateValue };
  };

  const onChange = useCallback((_event, option) => {
    form.change(fieldName, option?.value || '');
  }, []);

  return (
    <Autocomplete
      autoComplete
      autoSelect
      defaultValue={value()}
      error={error}
      label={label}
      loading={!loaded}
      name={fieldName}
      optionLabel={optionLabel}
      options={options}
      onChange={onChange}
      onInputChange={debounce(memoizedInputChange, 400)}
      {...rest}
    />
  );
};

const mapState = (state) => ({
  loaded: selectors.loaded(state),
  results: selectors.results(state),
  error: selectors.error(state),
});

const mapDispatch = {
  onMount: actions.mounted,
  onUnmount: actions.unmounted,
};

export const AutocompleteFilter = connect(
  mapState,
  mapDispatch
)(AutocompleteFilterComp);
