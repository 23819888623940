import axios from 'axios';
import { call, delay, put, race } from 'redux-saga/effects';

import { FileQueueManager } from '@crpt/shared/components/FileQueue';
import { toast } from '@crpt/shared/toast';

import { instance, instanceMulti, instanceZip } from '../../services/instance';
import * as preloaderActions from '../Preloader/ducks/Preloader.actions';

import { notifyDelay, notifyMessageHelper } from './Api.utils';

const CancelToken = axios.CancelToken;

export function* request(payload, meta = {}) {
  let axiosInstance = instance;

  if (payload.type === 'multipart') {
    axiosInstance = instanceMulti;
  }

  if (payload.type === 'zip') {
    axiosInstance = instanceZip;
  }

  const {
    preloading = true,
    errorNotify = true,
    timeout: time = 60000,
    artificialDelay = 0,
    showBackendMessage = false,
    withoutDescriptionOfError = true,
    successMessage = '',
  } = meta;

  if (preloading) {
    yield put(preloaderActions.start());
  }

  let success;
  let timeout;
  let error;

  try {
    [success, timeout] = yield race([
      call(axiosInstance.request, payload),
      delay(time),
    ]);

    if (success && successMessage) {
      yield call(toast.success, successMessage);
    }
  } catch (e) {
    error = e;

    if (errorNotify) {
      notifyMessageHelper(e, showBackendMessage, withoutDescriptionOfError);
    }
  }

  if (timeout) {
    notifyDelay();
  }

  if (artificialDelay) {
    yield delay(artificialDelay);
  }

  if (preloading) {
    yield put(preloaderActions.end());
  }

  return [success, error, timeout];
}

export function* fetchFile(
  url,
  { params = {}, fileQueue = null, requestConfiguration = {} }
) {
  let axiosInstance = instance;
  let prevLoaded = 0;
  let prevTimeStamp = 0;
  let deltaTime = 0;
  let phase = 0;
  let cancelRequest;
  let fileQueueData;
  const maxDeltaTime = 60000; // in millisecond
  const fileType = requestConfiguration.type ?? 'zip';

  if (fileQueue) {
    fileQueueData = FileQueueManager.create({
      label: `${fileQueue.name}.${fileType}`,
      fileSize: fileQueue.fileSize,
    });
  }

  const request = {
    url,
    method: 'GET',
    type: fileType,
    responseType: 'arraybuffer',
    params,
    onDownloadProgress: (progressEvent) => {
      if (fileQueue) {
        FileQueueManager.update(fileQueueData.fileQueueId, {
          loadedSize: progressEvent.loaded,
          cancelRequest,
        });
      }

      // progressEvent.loaded - количество загруженных байт
      // progressEvent.timeStamp - возвращает время (в миллисекундах), в котором было создано событие
      if (phase === 0) {
        prevTimeStamp = progressEvent.timeStamp;
        phase++;
      }

      if (progressEvent.loaded === prevLoaded) {
        deltaTime = progressEvent.timeStamp - prevTimeStamp;
        if (deltaTime > maxDeltaTime) {
          cancelRequest('timeout cancel request');
        }
      } else {
        prevLoaded = progressEvent.loaded;
        prevTimeStamp = progressEvent.timeStamp;
        deltaTime = 0;
      }
    },
    cancelToken: new CancelToken(function (c) {
      cancelRequest = c;
    }),
    timeout: 0,
    ...requestConfiguration,
  };

  let success;
  let error;

  try {
    success = yield call(axiosInstance, request);
    if (fileQueue) {
      FileQueueManager.success(fileQueueData.fileQueueId);
    }
  } catch (e) {
    FileQueueManager.remove(fileQueueData.fileQueueId);

    error = e;
  }

  return [success, error];
}
