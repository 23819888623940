import React, { useCallback, useEffect, useMemo } from 'react';

import { connect } from 'react-redux';

import { downloadDocumentWorkflow } from '../../../common_components/Document/ducks';
import { documentTypeValuesMap } from '../DocumentsList/DocumentsList.constants';

import { ServiceDeskDocumentView } from './ServiceDeskDocument.view';
import {
  clearServiceDeskDocument,
  fetchServiceDeskDocument,
  getLoading,
  getServiceDeskDocumentContent,
  getServiceDeskDocumentInfo,
} from './ducks';
import { definitionEnvironment } from './ServiceDeskDocument.utils';

export const ServiceDeskDocument = ({
  fetchServiceDeskDocument,
  clearServiceDeskDocument,
  downloadDocumentWorkflow,
  match,
  documentContent,
  documentInfo,
  isLoading,
}) => {
  const documentId = useMemo(() => {
    return match.params.id;
  }, [match.params.id]);

  useEffect(() => {
    fetchServiceDeskDocument(documentId);

    return () => {
      clearServiceDeskDocument();
    };
  }, [fetchServiceDeskDocument, clearServiceDeskDocument, documentId]);

  const documentTitle = useMemo(() => {
    return `${documentTypeValuesMap[documentInfo.doctype]} №${
      documentInfo.docid
    }`;
  }, [documentInfo.doctype, documentInfo.docid]);

  const onDownloadDocumentWorkflow = useCallback(() => {
    const { document_direction } = definitionEnvironment(documentInfo);

    downloadDocumentWorkflow({
      useFileQueue: true,
      id: documentInfo.edoDocumentId,
      name: documentInfo.docid,
      direction: document_direction.split('-')[0],
    });
  }, [documentInfo, downloadDocumentWorkflow]);

  const PDFViewerProps = useMemo(
    () => ({
      pdfData: documentContent,
      title: documentTitle,
      onDownloadFile: documentInfo.edoDocumentId
        ? onDownloadDocumentWorkflow
        : undefined,
    }),
    [
      documentContent,
      documentInfo.edoDocumentId,
      documentTitle,
      onDownloadDocumentWorkflow,
    ]
  );

  return React.createElement(ServiceDeskDocumentView, {
    PDFViewerProps,
    documentId,
    isLoading,
  });
};

const mapStateToProps = (state) => ({
  documentContent: getServiceDeskDocumentContent(state),
  documentInfo: getServiceDeskDocumentInfo(state),
  isLoading: getLoading(state),
});

const mapDispatchToProps = {
  fetchServiceDeskDocument,
  clearServiceDeskDocument,
  downloadDocumentWorkflow,
};

export const ServiceDeskDocumentConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceDeskDocument);
