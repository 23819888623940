import { useCallback, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { get } from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createForm } from '@crpt-ui/form';
import { unloadTasksService } from '@crpt/shared/services';
import toast from '@crpt/shared/toast';
import { useBooleanState } from '@crpt/shared/hooks';
import { russianDateFormat, TASK_TYPE } from '../../../constants';
import { createTask as createTaskAdapter } from './AcsDocuments.adapters';
import schema from './AcsDocuments.schema';
import { availableDocumentStatuses } from '../AcsUnloads.constants';
import { FieldIds, MetaFieldIds } from './AcsDocuments.types';
import { DefaultFieldIds } from '../AcsUnloads.types';

import type { CreateTaskDataAcsUnloads } from '@crpt/shared/services/UnloadTasksService.types';
import AcsUnloadsViewModel from '../AcsUnloads.viewmodel';

interface EditInnsState {
  fieldName: string;
  inns: string[];
  title: string;
}

export interface AcsDocumentsViewModelOptions {
  availablePGIdsCurrentUser?: number[];
  isOperator: boolean;
  pathToUnloadServiceRegistry: string;
  participantInn?: string;
}

const AcsDocumentsViewModel = ({
  availablePGIdsCurrentUser = [],
  isOperator,
  pathToUnloadServiceRegistry,
  participantInn,
}: AcsDocumentsViewModelOptions) => {
  const { t } = useTranslation();
  const history = useHistory();

  const data = AcsUnloadsViewModel({
    availablePGIdsCurrentUser,
    taskTypeId: TASK_TYPE.ACS_DOCUMENTS.taskTypeId,
  });

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [editInnsState, setEditInnsState] = useState<EditInnsState>({
    fieldName: FieldIds.SENDER_INN,
    inns: [],
    title: '',
  });

  const [
    isEditInnsModalOpen,
    { on: openEditInnsModal, off: closeEditInnsModal },
  ] = useBooleanState(false);

  const onSubmit = useCallback(
    async (values) => {
      setIsUploading(true);

      try {
        await unloadTasksService.createTask<CreateTaskDataAcsUnloads>(
          createTaskAdapter(values, {
            isOperator,
            participantInn,
            pathToUnloadServiceRegistry,
          })
        );

        history.push(pathToUnloadServiceRegistry);
      } catch (error) {
        toast.error(
          (error as AxiosError).message ??
            t('UnloadService.Task.AcsDocuments.Messages.createError')
        );
      } finally {
        setIsUploading(false);
      }
    },
    [history, isOperator, participantInn, pathToUnloadServiceRegistry, t]
  );

  const formInstance = useMemo(
    () =>
      createForm({
        schema,
        initialValues: {
          meta: {
            [MetaFieldIds.IS_OPERATOR]: isOperator,
          },
          [DefaultFieldIds.NAME]: `${t(
            'UnloadService.Task.AcsDocuments.TitleShort'
          )}_${moment(new Date()).format(russianDateFormat)}`,
          [FieldIds.STATUS]: Object.fromEntries(
            availableDocumentStatuses.map((item) => [item, true])
          ),
        },
        onSubmit,
      }),
    [isOperator, onSubmit, t]
  );

  const resetForm = useCallback(() => formInstance.reset(), [formInstance]);

  const handleEditInnsOpen = useCallback(
    (fieldName: string) => () => {
      const inns: string | undefined = get(formInstance.getState(), [
        'values',
        fieldName,
      ]);

      setEditInnsState({
        fieldName,
        inns: inns ? inns.split(',').map((value) => value.trim()) : [],
        title:
          fieldName === FieldIds.RECEIVER_INN
            ? t('UnloadService.Task.AcsDocuments.Fields.receiverInn')
            : t('UnloadService.Task.AcsDocuments.Fields.senderInn'),
      });
      openEditInnsModal();
    },
    [formInstance, openEditInnsModal, t]
  );

  return {
    ...data,
    isUploading,
    closeEditInnsModal,
    editInnsState,
    isEditInnsModalOpen,
    handleEditInnsOpen,
    formInstance,
    resetForm,
    t,
  } as const;
};

export default AcsDocumentsViewModel;
