import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    width: 'calc(100% - 16px)',
    height: 64,
    margin: '0 -8px',
    borderRadius: '4px 4px 0 0',
    boxSizing: 'border-box',
  },
  expandWrapper: {
    width: '100%',
    padding: '0 16px 8px',
    position: 'absolute',
    borderRadius: 4,

    '&.isOpen': {
      zIndex: 99,
      background: 'white',
      maxHeight: 159,
      boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.2)',
    },

    '&.canOpen': {
      cursor: 'pointer',
    },
  },
  label: {
    height: 64,
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    transition: '250ms color',
    fontWeight: 600,

    '&.isOpen': {
      color: theme.palette.text.primary,
    },

    '&.canOpen': {
      color: theme.palette.secondary.main,
    },
  },
  icon: {
    transition: '250ms transform, 250ms color',
    color: theme.palette.grey[600],

    '&.isOpen': {
      transform: 'rotate(-180deg)',
    },

    '&.canOpen': {
      cursor: 'pointer',
      color: theme.palette.grey[700],
    },
  },
  counter: {
    color: '#63666A',
    paddingLeft: 8,
  },
}));
