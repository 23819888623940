import React from 'react';

import Banner from './Banner';
import { ReactComponent as GearsSvg } from '@crpt/shared/svg/Gears.svg';

const NoData = () => {
  return (
    <Banner
      advice="Попробуйте обновить страницу позже."
      icon={<GearsSvg />}
      title="не удалось отобразить содержимое документа"
    />
  );
};

export default NoData;
