import React from 'react';

import { Icon } from '@crpt-ui/core';

export const incidentStatusEnum = {
  ACCEPTED: 0,
  PROCESSED: 1,
  CLOSED: 2,
};

export const incidentStatusValuesMap = {
  [incidentStatusEnum.ACCEPTED]: 'Принято',
  [incidentStatusEnum.CLOSED]: 'Завершено',
  default: 'В обработке',
};

export const incidentStatusColorsMap = {
  [incidentStatusEnum.ACCEPTED]: '#2fa93b',
  [incidentStatusEnum.CLOSED]: '#868686',
  default: '#ffa800',
};

export const incidentStatusIconsMap = {
  [incidentStatusEnum.ACCEPTED]: () => <Icon name="DocumentDraft" />,
  [incidentStatusEnum.CLOSED]: () => <Icon name="DocumentPublished" />,
  default: () => <Icon name="DocumentProcessing" />,
};
