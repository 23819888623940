import React, { Fragment, useEffect } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Preloader } from '../../common_components/Preloader/Preloader';
import { ContentWrapper } from '../../common_components/styled';
import { translate } from '../Translate/Translate';

import { PaymentStatusSuccess } from './PaymentStatusSuccess';
import { PaymentStatusErrorConnected } from './PaymentStatusError';
import {
  clearPaymentStatus,
  fetchPaymentStatus,
} from './ducks/PaymentStatus.actions';
import {
  getLoading,
  getPaymentStatusData,
} from './ducks/PaymentStatus.selectors';

const PaymentStatus = ({
  fetchPaymentStatus,
  clearPaymentStatus,
  match,
  history,
  paymentStatus,
  isSoleProprietorship,
  isLoading,
}) => {
  useEffect(() => {
    const { orderId } = match.params;

    if (!isSoleProprietorship && orderId) {
      fetchPaymentStatus(orderId);
    } else {
      history.push('/');
    }

    return () => {
      clearPaymentStatus();
    };
  }, [
    fetchPaymentStatus,
    clearPaymentStatus,
    match,
    history,
    isSoleProprietorship,
  ]);

  if (isLoading) {
    return (
      <ContentWrapper>
        <Preloader isOpen text={translate('Загрузка платежа')} />
      </ContentWrapper>
    );
  }

  return (
    <Fragment>
      {paymentStatus.status === 'CONFIRMED' ? (
        <PaymentStatusSuccess />
      ) : (
        <PaymentStatusErrorConnected />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  paymentStatus: getPaymentStatusData(state),
  isLoading: getLoading(state),
});

const mapDispatchToProps = { fetchPaymentStatus, clearPaymentStatus };

PaymentStatus.propTypes = {
  fetchPaymentStatus: PropTypes.func.isRequired,
};

export const PaymentStatusConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentStatus);
