import React, { useCallback, useMemo } from 'react';

import { connect } from 'react-redux';

import { Icon } from '@crpt-ui/core';

import { saveFile } from '../../../../../common_components/Document/Document.utils';
import { PageActions } from '../../../../../common_components/PageActions/PageActions';
import { documentTypeValuesMap } from '../../../DocumentsList/DocumentsList.constants';
import { downloadDocumentWorkflow } from '../../../../../common_components/Document/ducks';
import { getDocumentInfo } from '../../ducks/ClosingDocument.selectors';
import { getClosingDocumentContent } from '../ducks/ViewDocument.selectors';

const DocumentFooter = ({
  downloadDocumentWorkflow,
  documentContent,
  documentInfo,
}) => {
  const documentTitle = useMemo(
    () => documentTypeValuesMap[documentInfo.type],
    [documentInfo.type]
  );

  /**
   * Download the document workflow
   */
  const onClickPrint = useCallback(() => {
    saveFile({
      filename: documentTitle,
      content: documentContent,
      type: 'application/pdf',
    });
  }, [documentTitle, documentContent]);

  const mainDocumentActions = useMemo(
    () => [
      {
        label: 'Печать',
        icon: <Icon name="Print" />,
        onClick: onClickPrint,
        isShow: true,
        disabled: documentContent === '',
      },
      {
        label: 'Скачать',
        icon: <Icon name="Download" />,
        onClick: () =>
          downloadDocumentWorkflow({
            id: documentInfo.id,
          }),
        isShow: true,
        variant: 'outlined',
      },
    ],
    [downloadDocumentWorkflow, onClickPrint, documentContent, documentInfo]
  );

  return React.createElement(PageActions, {
    mainActions: mainDocumentActions,
  });
};

DocumentFooter.propTypes = {};

const mapStateToProps = (state) => ({
  documentContent: getClosingDocumentContent(state),
  documentInfo: getDocumentInfo(state),
});

const mapDispatchToProps = {
  downloadDocumentWorkflow,
};

export const DocumentFooterConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentFooter);
