import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';

import { Icon, Typography } from '@crpt-ui/core';

import { SignatureDescriptionLoader } from './SignatureDescriptionLoader/SignatureDescriptionLoader';
import { useSignatureDescriptionStyles } from './SignatureDescription.styled';
import { SignatureDescriptionLine } from './SignatureDescriptionLine';

export const SignatureDescriptionView = ({
  certificate,
  direction,
  isLoading,
}) => {
  const classes = useSignatureDescriptionStyles();

  const directionIsVertical = useMemo(
    () => direction === 'vertical',
    [direction]
  );

  const lineWidth = directionIsVertical ? 12 : 6;

  const { thumbprint, company, issuer, validDate, serialNumber } = useMemo(
    () => certificate,
    [certificate]
  );

  if (isLoading) {
    return (
      <Box className={classes.wrapper}>
        <SignatureDescriptionLoader lineWidth={lineWidth} />
      </Box>
    );
  }

  return (
    <Box className={classes.wrapper}>
      <Box mb={1}>
        <Typography variant="h6">
          <Icon className={classes.icon} name="Success" />
          {thumbprint}
        </Typography>
      </Box>

      <Grid container spacing={directionIsVertical ? 2 : 0}>
        <Grid item xs={lineWidth}>
          <SignatureDescriptionLine label="Владелец" value={company} />
          <SignatureDescriptionLine label="ИНН" value={serialNumber} />
        </Grid>

        <Grid item xs={lineWidth}>
          {Boolean(issuer) && (
            <SignatureDescriptionLine label="Выдан" value={issuer} />
          )}
          <SignatureDescriptionLine label="Действителен" value={validDate} />
        </Grid>
      </Grid>
    </Box>
  );
};

SignatureDescriptionView.propTypes = {
  certificate: PropTypes.shape({
    thumbprint: PropTypes.string,
    organization: PropTypes.string,
    organisation: PropTypes.string,
    issuer: PropTypes.string,
    validDate: PropTypes.string,
    serialNumber: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
};
