import React, { useCallback, useMemo } from 'react';

import * as _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withFormContext } from '../../../../common_components/Form/Form.utils';
import { OperatorsOption } from '../../../../common_components/OperatorsOption/OperatorsOption';
import { getRegistered } from '../../Company/ducks/Company.selectors';
import {
  getEdoRegistrationEditing,
  getEdoRegistrationOperators,
} from '../ducks/EdoRegistration.selectors';

import { OperatorsView } from './Operators.view';

const name = 'edoOperators';

const Operators = ({
  formMutators,
  operators,
  value,
  isEditing,
  isRegistered,
}) => {
  const onCreate = useCallback(() => {
    formMutators.push(name, {
      isMainOperator: false,
    });
  }, [formMutators]);

  const onRemove = useCallback(
    (index) => {
      formMutators.remove(name, index);
    },
    [formMutators]
  );

  const onMainOperatorChange = useCallback(
    (index) => {
      value.forEach((operator, i) => {
        formMutators.update(`${name}`, i, {
          ...operator,
          isMainOperator: i === index,
        });
      });
    },
    [value, formMutators]
  );

  const canRemove = useCallback((operator) => {
    const edoOperatorId = _.get(operator, 'edoOperatorId');
    return edoOperatorId !== 16;
  }, []);

  /**
   * Formatting the input value if edoOperatorId is equal to 'ЭДО лайт (ЦРПТ)'
   * @type {function}
   * @returns {string}
   */
  const formatParticipantEdoIdField = useCallback((inputValue, operator) => {
    return operator.prefixEdo && operator.edoOperatorId === 16
      ? `${operator.prefixEdo}-${inputValue}`
      : inputValue;
  }, []);

  const operatorsOptions = useMemo(() => {
    return operators.map((operator) => ({
      ...operator,
      label: (
        <OperatorsOption label={operator.label} status={operator.status} />
      ),
    }));
  }, [operators]);

  return React.createElement(OperatorsView, {
    onCreate,
    onRemove,
    onMainOperatorChange,
    canRemove,
    formatParticipantEdoIdField,
    operatorsOptions,
    value,
    isEditing,
    isRegistered,
  });
};

Operators.propTypes = {
  formMutators: PropTypes.object.isRequired,
  operators: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
};

Operators.defaultProps = {
  value: [],
  operators: [],
};

const mapStateToProps = (state) => ({
  operators: getEdoRegistrationOperators(state),
  isEditing: getEdoRegistrationEditing(state),
  isRegistered: getRegistered(state),
});

export const OperatorsConnected = compose(
  connect(mapStateToProps),
  withFormContext({
    mutators: true,
    name: 'edoOperators',
  })
)(Operators);
