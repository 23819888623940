import { makeStyles } from '@material-ui/core';

export const useErrorClasses = makeStyles(
  () => ({
    root: {
      background: '#ff4b4b',
      margin: '-10px -32px 21px',
      padding: '8px 40px',
      display: 'flex',
      alignItems: 'center',
      color: '#fff',
    },
    icon: {
      fill: '#fff',
    },
    iconWrap: {
      marginRight: '8px',
    },
  }),
  { name: 'SDSignErrorInfo' }
);
