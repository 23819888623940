/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import fp from 'lodash/fp';
import is from 'is_js';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import i18n from 'i18next';

import { isPhone } from '../utils/helpers';

type Value = string;
type EmptyValue = Value | null | undefined;
type Country = 'KZ' | 'UZ' | 'AM' | 'HY' | 'KG';
const integerMaxJava = 2147483647;

// const i18n.t = i18n.t;

export const validators = {
  pipe:
    (...args: any) =>
    (v: EmptyValue) =>
      args
        .flat()
        .filter(Boolean)
        .map((arg: any) => arg(v))
        .filter(Boolean)
        .shift(),
  required: (v: EmptyValue) =>
    fp.isNil(v) || v === '' ? i18n.t('Обязательное поле') : undefined,
  regexp: (regexp: string) => (value: string) =>
    value
      ? new RegExp(regexp)?.test(value)
        ? ''
        : `Неверный формат поля`
      : undefined,
  selectRequired: (v: EmptyValue) =>
    fp.isNil(v) || v === '' || v === 'empty'
      ? i18n.t('Обязательное поле')
      : undefined,
  pdfPrint: (count: number, max: number) => () =>
    count > max
      ? i18n.t('Превышено допустимое количество кодов маркировки для печати')
      : '',
  is: {
    regexp: (regexp: string) => (value: string) =>
      new RegExp(regexp)?.test(value)
        ? ''
        : i18n.t(
            `Значение должно соответствовать регулярному выражению: /${regexp}/`
          ),
    max: (max: number) => (value: number) =>
      value > max ? (
        <>
          {i18n.t('Не должно быть больше')} {max}
        </>
      ) : (
        ''
      ),
    min: (min: number) => (value: number) =>
      value < min ? (
        <>
          {i18n.t('Не должно быть меньше')} {min}
        </>
      ) : (
        ''
      ),
    time: (v: EmptyValue) =>
      format(parseISO(`2018-10-16T${v || '00:00'}`), 'HH:mm') === 'Invalid date'
        ? i18n.t('Неверный формат времени')
        : '',
    phone: (v: Value) => {
      const onlyNum = v ? `+7${v.replace(/[^\d]/g, '').slice(1)}` : v;
      return isPhone(onlyNum) ? undefined : i18n.t('Неверный формат телефона');
    },
    emailOrPhone: (v: Value) =>
      is.email(v) || isPhone(v)
        ? undefined
        : i18n.t('Введите email или телефон'),
    email: (v: EmptyValue) =>
      is.email(v) ? undefined : i18n.t('Введите email'),
    numeric: (v: EmptyValue) =>
      v ? (/^[0-9.,]+$/i.test(v) ? '' : i18n.t('Допустимы только цифры')) : '',
    integer: (v: EmptyValue) =>
      v
        ? /^[0-9]+$/i.test(v)
          ? ''
          : i18n.t('Допустимо только целое число')
        : '',
    integerJava: (v: EmptyValue) =>
      v ? (
        Number(v) <= integerMaxJava ? (
          ''
        ) : (
          <>
            {i18n.t('Не должно быть больше')} {integerMaxJava}
          </>
        )
      ) : (
        ''
      ),
    uuid4: (v: EmptyValue) =>
      v
        ? /^[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}$/i.test(
            v
          ) ||
          /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i.test(
            v
          )
          ? ''
          : i18n.t('Неверный формат uuid')
        : '',
    uuid: (v: EmptyValue) =>
      v
        ? /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i.test(
            v
          )
          ? ''
          : i18n.t('Неверный формат uuid')
        : '',
    latin: (v: Value) =>
      /^[a-z.\- ]+$/i.test(v) ? '' : i18n.t('Допустимы символы на латинице'),
    cyrillic: (v: Value) =>
      /^[а-я.\- ]+$/i.test(v) ? '' : 'Допустимы символы на кирилице',
    alphanumeric: (v: Value) =>
      /^[0-9a-z.\- ]+$/i.test(v)
        ? undefined
        : 'Допустимы только буквенно-цифровые символы на латинице',
    cyralphanumeric: (v: Value) =>
      /^[0-9a-zа-я.\- ]+$/i.test(v)
        ? undefined
        : 'Допустимы только буквенно-цифровые символы',
    password: (v: Value) =>
      /^[0-9a-z.@%!$*()\- ]+$/i.test(v)
        ? undefined
        : 'Допустимы A-Z, 0-9, @, %, !, $, *, (, )',
    emailSymbols: (v: Value) =>
      /^[0-9a-z._@\- ]+$/i.test(v)
        ? undefined
        : 'Допустимы A-Z, a-z, 0-9, . - _@',
    dateBefore: (date: Date) => (value: Date) =>
      isBefore(date, value)
        ? undefined
        : i18n.t('Дата окончания должна быть позже даты начала'),
    dateAfter: (date: Date) => (value: Date) =>
      isBefore(value, date)
        ? undefined
        : i18n.t('Дата начала должна быть раньше даты окончания'),
    innSymbols: (v: Value) =>
      /^[0-9]+$/i.test(v) ? undefined : 'Допустимы только цифры',
    inn: (country: Country) => (value: Value) => {
      if (value) {
        switch (country) {
          case 'KZ': {
            return validators.length.equal(12)(`${value}`);
          }
          case 'HY': {
            return validators.length.equal(8)(`${value}`);
          }
          case 'AM': {
            return validators.length.equal(8)(`${value}`);
          }
          case 'KG': {
            return validators.length.equal(14)(`${value}`);
          }
          case 'UZ': {
            return validators.length.equal(9)(`${value}`);
          }
          default:
            return undefined;
        }
      }
    },
    serialNumber: (value: EmptyValue) => {
      if (value) {
        const firstSymbols = value.match(/^([0-9]{18})/);
        return firstSymbols ? undefined : i18n.t('Некорректный формат кода');
      }
    },
    commonInn: (min: number, max: number) => (value: string) =>
      is.within(fp.size(value), min, max)
        ? undefined
        : i18n.t('Некорректная длина ИНН. Проверьте введённое значение'),
  },
  eq: (sample: EmptyValue) => (v: EmptyValue) =>
    sample === v ? undefined : i18n.t('Значения не совпадают'),
  has: {
    nospace: (v: Value) =>
      /\s/.test(v) ? 'Пробельные символы не допустимы' : undefined,
  },
  startWith: (start: string, position: number) => (value: EmptyValue) =>
    value && start && start.length === position ? (
      value.slice(0, position) === start ? (
        ''
      ) : (
        <>
          {i18n.t('Должно начинаться с')} {start}
        </>
      )
    ) : (
      ''
    ),
  length: {
    equal: (val: number) => (value: EmptyValue) => {
      return value
        ? val === fp.size(value.split(' ').join(''))
          ? ''
          : `${i18n.t('Длина должна быть равна')} ${val}`
        : '';
    },
    min: (min: number) => (value: EmptyValue) =>
      value ? (
        is.above(fp.size(value), min - 1) ? undefined : (
          <>
            {i18n.t('Длина не должна быть меньше')} {min}
          </>
        )
      ) : (
        ''
      ),
    minPwd: (min: number) => (value: EmptyValue) =>
      value
        ? is.above(fp.size(value), min - 1)
          ? undefined
          : `Длина пароля должна быть не менее ${min} символов`
        : '',
    max: (max: number) => (value: EmptyValue) =>
      is.under(fp.size(value), max + 1) ? undefined : (
        <>
          {i18n.t('Длина не должна превышать')} {max}
        </>
      ),
    within: (min: number, max: number) => (value: Value) =>
      is.within(fp.size(value), min, max)
        ? undefined
        : `Длина должна находиться в диапазоне [${min} ... ${max}]`,
  },
};
