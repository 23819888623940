import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  title: {
    margin: 0,
  },
  root: {
    '& label': {
      display: 'none',

      '&.Mui-error': {
        display: 'block',
      },
    },
    '& .MuiSelect-select': {
      paddingTop: '10px !important',
    },
  },
});
