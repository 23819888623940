import { handleActions } from 'redux-actions';

import {
  clearSystemRequirements,
  setLoading,
  setRequirementsChecked,
} from './CheckingSystemRequirements.actions';

const pluginChecked = handleActions(
  {
    [setRequirementsChecked]: (_, { payload }) => payload,
    [clearSystemRequirements]: () => [],
  },
  []
);

const loading = handleActions(
  {
    [setLoading]: (_, { payload }) => payload,
    [clearSystemRequirements]: () => false,
  },
  false
);

export const reducer = {
  pluginChecked,
  loading,
};
