/* eslint-disable no-shadow */

import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { AccountsType } from '../../constants/PropTypes';
import { withForm } from '../hocs/withForm';
import { getAccounts } from '../../components/_Profile/Company/ducks/Company.selectors';

import { closePaymentDialog, sendPayment } from './ducks/PaymentDialog.actions';
import {
  getFormData,
  getLoading,
  getOpenReceiptsDialog,
} from './ducks/PaymentDialog.selectors';
import { SelectOption } from './SelectOption';
import { PaymentDialogView } from './PaymentDialog.view';
import { schema } from './PaymentDialog.schema';

const PaymentDialog = ({
  open,
  form,
  closePaymentDialog,
  sendPayment,
  accounts,
  values,
  hasValidationErrors,
  isLoading,
}) => {
  const onClose = useCallback(() => {
    closePaymentDialog();
    form.reset();
  }, [closePaymentDialog, form]);

  const accountOptions = useMemo(() => {
    return accounts.map((item) => ({
      label: <SelectOption item={item} />,
      value: item.contractId,
    }));
  }, [accounts]);

  const onSubmit = useCallback(() => {
    sendPayment(values);
  }, [sendPayment, values]);

  /**
   * The button is blocked if there are errors in the form
   * or the user has not agreed to make the payment
   */
  const isDisabledSubmit = useMemo(() => {
    return hasValidationErrors || !values.agree;
  }, [hasValidationErrors, values.agree]);

  const modalActions = useMemo(
    () => [
      {
        label: 'Оплатить',
        callback: onSubmit,
        disabled: isDisabledSubmit,
        loading: isLoading,
      },
      {
        label: 'Отменить',
        callback: onClose,
        variant: 'outlined',
      },
    ],
    [onSubmit, onClose, isDisabledSubmit, isLoading]
  );

  return React.createElement(PaymentDialogView, {
    open,
    onClose,
    accountOptions,
    modalActions,
    hasValidationErrors,
  });
};

PaymentDialog.propTypes = {
  accounts: AccountsType,
  closePaymentDialog: PropTypes.func.isRequired,
  sendPayment: PropTypes.func.isRequired,
  values: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  hasValidationErrors: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  open: getOpenReceiptsDialog(state),
  accounts: getAccounts(state),
  initialValues: getFormData(state),
  isLoading: getLoading(state),
});

const mapDispatchToProps = {
  closePaymentDialog,
  sendPayment,
};

export const PaymentDialogConnected = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withForm({ schema })
)(PaymentDialog);
