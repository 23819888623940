import React from 'react';

import { TableScrollWrapper } from './TableScrollWrapper/TableScrollWrapper';

export const withScroll = (Component) => (props) =>
  (
    <TableScrollWrapper>
      <Component {...props} />
    </TableScrollWrapper>
  );
