export enum PackageTypeEnum {
  ATK = 3,
  BOX = 2,
  BUNDLE = 4,
  GROUP = 1,
  SET = 5,
  UNIT = 0,
}

export enum OldCisPackageTypeEnum {
  ATK = 100,
  LEVEL3 = 3,
  BOX = 2,
  BUNDLE = 111,
  GROUP = 1,
  SET = 112,
  UNIT = 0,
}

export enum GeneralPackageTypeEnum {
  ATK = 3,
  BOX = 2,
  BUNDLE = 4,
  GROUP = 1,
  SET = 5,
  UNIT = 0,
}
