import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Popover, Stack } from '@mui/material';
import { useSelector } from 'react-redux';

import { Button, Icon } from '@ibox/ui';

import { getUserInfo } from '../../../../common_components/Auth/ducks/Auth.selectors';

export const ActionPopover = ({
  results,
  cell,
  openBlockUserDialog,
  companyId,
  setSelectedUser,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const selectedUser = {
    assignedRoles: cell.row.original.roles,
    user: { ...cell.row.original },
  };

  const handleBlockDialog = () => {
    openBlockUserDialog(selectedUser);
  };

  const userInfo = useSelector((state) => getUserInfo(state));

  const ParticipantDetails = useSelector(
    (state) => state.ParticipantDetails.results
  );

  const isParticipantAdmin = useMemo(
    () =>
      userInfo.authorities.includes('ROLE_ADMIN') &&
      ParticipantDetails?.inn === userInfo?.inn,
    [ParticipantDetails, userInfo]
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClickActions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const adminsQuantity = useMemo(
    () =>
      results.reduce(
        (acc, item) => (item?.roles.includes('ADMIN') ? acc + 1 : acc),
        0
      ),
    [results]
  );

  const checkHiddenBlock =
    !(
      cell.row.original?.status === 'DRAFT' ||
      cell.row.original?.status === 'ACTIVE'
    ) ||
    adminsQuantity < 2 ||
    !isParticipantAdmin;

  const checkHiddenChange =
    !(
      cell.row.original?.status === 'DRAFT' ||
      cell.row.original?.status === 'ACTIVE'
    ) ||
    adminsQuantity < 2 ||
    companyId === cell.row.original?.id ||
    !isParticipantAdmin;

  return (
    <>
      <Button aria-describedby={id} variant="text" onClick={handleClickActions}>
        <Icon name="Dots" />
      </Button>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        id={id}
        open={open}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <Stack p={2} spacing={2}>
          <Button
            color="error"
            disabled={checkHiddenBlock}
            variant="text"
            onClick={handleBlockDialog}
          >
            {t('Блокировать')}
          </Button>
          <Button
            disabled={checkHiddenChange}
            variant="text"
            onClick={() => setSelectedUser(selectedUser)}
          >
            {t('Изменить')}
          </Button>
        </Stack>
      </Popover>
    </>
  );
};
