import { createAction } from '@reduxjs/toolkit';

const subject = 'UsersList';

export const getRequest = createAction(`${subject}/get request`);
export const changeUserStatus = createAction(`${subject}/changeUserStatus`);
export const blockUser = createAction(`${subject}/blockUser`);
export const loaded = createAction(`${subject}/loaded`);
export const updatePage = createAction(`${subject}/updatePage`);
export const unmounted = createAction(`${subject}/unmounted`);
