import { translate } from '../Translate/Translate';

export const tabs = [
  {
    id: 1,
    title: translate('Данные участника'),
    href: '/profile',
    required: true,
  },
  {
    id: 2,
    title: translate('Пользователи'),
    href: '/profile/users/list',
    check: ['/profile/users/list', '/profile/users/add'],
  },
  // {
  //   id: 3,
  //   title: 'Реквизиты',
  //   required: true,
  //   href: '/profile/requisites',
  // },
  // {
  //   id: 4,
  //   title: 'Производство',
  //   href: '/profile/production',
  //   required: false,
  // },
  // {
  //   id: 5,
  //   title: 'Операторы ЭДО',
  //   href: '/profile/edo',
  //   required: true,
  // },
];
