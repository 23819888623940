import React from 'react';

import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

import { Icon, Typography } from '@crpt-ui/core';

import { Link } from '../../common_components/Link/Link';
import { ContentWrapper } from '../../common_components/styled';

import { useStyles } from './PaymentStatus.styled';

export const PaymentStatusTemplate = ({ icon, title, text, isError }) => {
  const classes = useStyles();

  return (
    <ContentWrapper className={classes.wrapper}>
      <Icon
        className={clsx(classes.icon, {
          [classes.errorIcon]: isError,
        })}
        name={icon}
      />
      <Box my={3}>
        <div className={classes.wrapper}>
          <Typography className={classes.title} component="div" variant="h1">
            {title}
          </Typography>
          <Typography className={classes.text} component="div" variant="body1">
            {text}
          </Typography>
        </div>
      </Box>
      <Link className={classes.link} to="/dashboard">
        Перейти на главную
      </Link>
    </ContentWrapper>
  );
};

PaymentStatusTemplate.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.oneOfType(PropTypes.string, PropTypes.node).isRequired,
  title: PropTypes.oneOfType(PropTypes.string, PropTypes.node).isRequired,
  isError: PropTypes.bool,
};

PaymentStatusTemplate.defaultProps = {
  isError: false,
};
