import styled from 'styled-components';

export const ContentWrapper = styled.div`
  position: relative;
  background: #ffffff;
  margin-bottom: 0px;
  margin-top: 20px;
  min-height: ${({ minHeight }) => (minHeight ? '500px' : 'auto')};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 1px 6px rgba(41, 49, 53, 0.1);
`;
