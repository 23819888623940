export enum ParticipantStatus {
  REGISTERED = 'REGISTERED',
  NOT_REGISTERED = 'NOT_REGISTERED',
  REMOVED = 'REMOVED',
  RESTORED = 'RESTORED',
  BLOCKED = 'BLOCKED',
}

export interface ParticipantDTO {
  id?: number;
  inn: string;
  name: string;
  shortName?: string;
  fullName: string;
  type?: string;
  status?: ParticipantStatus;
  kpp?: string;
  ogrn?: string;
  head?: string[];
  legalAddress?: string;
  actualAddress?: string;
  email?: string;
  registrationDate?: number;
  organizationForm?: string;
  emissionRegistrars?: string;
  dissolved?: boolean;
  roles?: string[];
  phone?: string;
}
