import { createSelector } from 'reselect';

import { data as getUserList } from '../../../_Profile/UserList/ducks/UserList.selectors';

export const getNotificationBalance = (state) =>
  state.Accounts.NotificationBalance;

export const getOpenNotificationMinBalanceModal = createSelector(
  [getNotificationBalance],
  (notificationBalance) => notificationBalance.open
);

export const getSelectedAccount = createSelector(
  [getNotificationBalance],
  (notificationBalance) => notificationBalance.selectedAccount
);

export const getParticipantsList = createSelector(
  [getNotificationBalance],
  (notificationBalance) => notificationBalance.participantsList
);

export const getLoading = createSelector(
  [getNotificationBalance],
  (notificationBalance) => notificationBalance.loading
);

export const getUserListOptions = createSelector([getUserList], (users) =>
  users.map(({ user }) => ({
    label: user.fullName,
    email: user.email,
    value: user.id,
    selected: false,
  }))
);

export const getNotificationRecipientsIds = createSelector(
  [getNotificationBalance],
  (notificationBalance) => notificationBalance.notificationRecipientsIds
);

export const getBalanceMinimum = createSelector(
  [getNotificationBalance],
  (notificationBalance) => notificationBalance.balanceMinimum
);
