import React from 'react';
import { chain } from 'lodash';
import moment from 'moment';
import { Icon } from '@crpt-ui/core';
import {
  Role,
  UsersListResponse,
} from '../../services/UnloadTasksService.types';
import { largeDash } from '../../constants';
import { Period } from './constants';

export interface UserListOption {
  value: number;
  label: string;
  additionalLabel: string;
}

type Sort = {
  id: string;
  desc?: boolean;
}[];

type MakeIcons = (
  data: { [key in string]: string }
) => { [key in string]: React.ReactNode };

interface OrgRole {
  code: string;
  name: string;
}

export const getFrontendTaskUrl = (
  absolutePath: string,
  localhostAlias = 'https://qa05.gismt.crpt.tech'
) => {
  const locationOrigin = window.location.origin;
  return /localhost/.test(locationOrigin)
    ? `${localhostAlias}${absolutePath}/list/`
    : `${locationOrigin}${absolutePath}/list/`;
};

export const prepareSortObject = (sort: Sort) => {
  if (!sort || !sort.length) {
    return {};
  }

  const [{ id, desc }] = sort;

  if (!id) {
    return {};
  }

  return {
    columnName: id,
    orderDirection: desc ? 'DESC' : 'ASC',
  };
};

export const getSortData2url = (sort: Sort) => {
  if (!sort.length) {
    return [];
  }

  const [{ id, desc }] = sort;

  return [
    {
      id: 'orderColumn',
      value: id,
    },
    {
      id: 'orderDirection',
      value: desc ? 'desc' : 'asc',
    },
  ];
};

export const initFromUrl = (searchString = '', filterNames: string[] = []) => {
  const urlSearchParams = new URLSearchParams(searchString);

  const page = urlSearchParams.get('page');
  const pageInt = page ? Number(page) : undefined;

  const orderDirection = urlSearchParams.get('orderDirection');
  const orderColumn = urlSearchParams.get('orderColumn');
  const sort =
    orderDirection && orderColumn
      ? [
          {
            id: JSON.parse(orderColumn),
            desc: JSON.parse(orderDirection) === 'desc',
          },
        ]
      : [];

  const filters = filterNames
    .map((item) => {
      const value = urlSearchParams.get(item);

      return {
        id: item,
        value: value && JSON.parse(value),
      };
    })
    .filter(({ value }) => value);

  return {
    sort,
    page: pageInt,
    filters,
  };
};

export const makeIcons: MakeIcons = (data) =>
  chain(data)
    .toPairs()
    .map(([key, name]) => {
      return [key, <Icon key={key} name={name} />];
    })
    .fromPairs()
    .value();

export const getOrgRolesStr = (
  orgRoles: Role[] | undefined,
  allOrgRoles: OrgRole[]
) =>
  Array.isArray(orgRoles)
    ? orgRoles
        .map((role) => allOrgRoles.find((item) => item.code === role.role))
        .filter((item) => item)
        .map((item) => item?.name)
        .join(', ')
    : largeDash;

export const formatDate = (date?: string | Date) => {
  const localDate = date && moment.utc(date).local();

  const dateDMY = localDate && moment(localDate).format('DD.MM.YYYY');
  const dateHMS = localDate && moment(localDate).format('HH:mm');

  if (!dateDMY && !dateHMS) {
    return largeDash;
  }

  return `${dateDMY} в ${dateHMS}`;
};

export const formatPeriodDate = (
  data: any,
  dataStartName: string,
  dataEndName: string,
  zoneOffset?: string
) => {
  const zone = (zoneOffset && data[zoneOffset]) ?? '+03:00';
  const startDate =
    data[dataStartName] &&
    moment.utc(data[dataStartName]).zone(zone).format('DD.MM.YYYY');

  const endDate =
    data[dataEndName] &&
    moment.utc(data[dataEndName]).zone(zone).format('DD.MM.YYYY');

  return startDate && endDate
    ? `${startDate} ${largeDash} ${endDate}`
    : largeDash;
};

export const getStringForRelativeType = (
  relativeType: string,
  currentType: string
) => (relativeType === currentType ? 'Да' : 'Нет');

export const getDurationError = (period: Period) => {
  let moreThanPeriod = '';

  switch (period) {
    case Period.DAY:
      moreThanPeriod = 'двух дней';
      break;
    case Period.WEEK:
      moreThanPeriod = 'двух недель';
      break;
    case Period.MONTH:
      moreThanPeriod = 'двух месяцев';
      break;
    case Period.QUARTER:
      moreThanPeriod = 'шести месяцев';
      break;
    case Period.YEAR:
      moreThanPeriod = 'двух лет';
      break;
  }

  return `Указанный период не соответствует выбранной регулярности отправления. Выберите период более ${moreThanPeriod}`;
};

export const prepareUserListOptions = (data: UsersListResponse) =>
  data
    .filter(({ user }) => Boolean(user.email))
    .map(({ user }) => {
      return {
        value: user.id,
        label: user.fullName,
        additionalLabel: user.email,
      } as UserListOption;
    });
