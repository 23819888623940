import moment from 'moment';
import 'moment/locale/ru';

import { config } from './project/config';

moment.locale('ru');

export const projects = {
  COMMON: 'COMMON',
  LP: '',
};

const allSagas = [
  {
    id: projects.COMMON,
    data: require.context('./common_components', true, /\.saga.js|.saga.ts/),
  },
  {
    id: projects.LP,
    data: require.context('./components', true, /\.saga.js|.saga.ts/),
  },
];

const allStores = [
  {
    id: projects.COMMON,
    data: require.context('./common_components', true, /\.store.js|.store.ts/),
  },
  {
    id: projects.LP,
    data: require.context('./components', true, /\.store.js|.store.ts/),
  },
];

const order = [];

order.push(projects.COMMON, projects.LP);

export { order, config, allSagas, allStores };
