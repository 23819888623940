import React from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AutocompleteFilter } from '../../../../../../common_components/Filters/AutocompleteFilter/AutocompleteFilter';

import * as actions from './ducks/ReceiptSelect.actions';
import { prepareOptions } from './receipt-select-utils';

const ReceiptSelectComp = (props) => {
  const { t } = useTranslation();
  const label = 'Продавец';

  return (
    <AutocompleteFilter
      label={t(label)}
      optionLabel="name"
      otherProps={props}
      prepareOptions={prepareOptions}
    />
  );
};

const mapDispatch = (dispatch) => ({
  onInputChange: (val) => dispatch(actions.updated(val)),
});

export const ReceiptSelect = connect(null, mapDispatch)(ReceiptSelectComp);
