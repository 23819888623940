import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

const getLocalState = (state) => get(state, 'RegistryOIV', {});

export const loaded = createSelector(getLocalState, (state) => state.loaded);
export const results = createSelector(getLocalState, (state) => state.results);
export const total = createSelector(getLocalState, (state) => state.total);
export const page = createSelector(getLocalState, (state) => state.page);
export const last = createSelector(getLocalState, (state) => state.last);
export const addedOIV = createSelector(
  getLocalState,
  (state) => state.addedOIV
);
export const pagesCount = createSelector(total, (total) =>
  Math.ceil(total / 10)
);
