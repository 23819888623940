import { handleAction, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
  closeProductionSiteModal,
  openProductionSiteModal,
  setCountries,
  setLoading,
  setProductionData,
  setProductionLineId,
  setProductionPage,
  setSubmitting,
} from './Production.actions';

const data = handleAction(setProductionData, (_, { payload }) => payload, {
  last: false,
  result: [],
  total: 0,
});

const countries = handleAction(setCountries, (_, { payload }) => payload, []);

const isOpenModal = handleActions(
  {
    [openProductionSiteModal]: () => true,
    [closeProductionSiteModal]: () => false,
  },
  false
);

const isLoading = handleActions(
  {
    [setLoading]: (_, { payload }) => payload,
    [LOCATION_CHANGE]: () => false,
  },
  false
);

const submitting = handleActions(
  {
    [setSubmitting]: (_, { payload }) => payload,
    [LOCATION_CHANGE]: () => false,
  },
  false
);

const productionLineId = handleActions(
  {
    [setProductionLineId]: (_, { payload }) => payload,
    [closeProductionSiteModal]: () => null,
  },
  null
);

const page = handleActions(
  {
    [setProductionPage]: (_, { payload }) => payload,
    [LOCATION_CHANGE]: () => 0,
  },
  0
);

export const reducer = {
  data,
  countries,
  isOpenModal,
  isLoading,
  submitting,
  productionLineId,
  page,
};
