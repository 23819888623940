export enum OrganisationStatus {
  REGISTERED = 'REGISTERED',
  NOT_REGISTERED = 'NOT_REGISTERED',
  REMOVED = 'REMOVED',
  RESTORED = 'RESTORED',
  BLOCKED = 'BLOCKED',
}

export interface OrganisationDTO {
  id?: number;
  inn: string;
  name?: string;
  fullName: string;
  type?: string;
  status?: OrganisationStatus;
  kpp?: string;
  ogrn?: string;
  legalAddress?: string;
  actualAddress?: string;
  email?: string;
  registrationDate?: number;
  organisationForm?: string;
  dissolved?: boolean;
  roles?: unknown[];
  phone?: string;
  nza: string;
  okpo: string;
  fio: string;
  kppElk: string;
  heads: string[];
  dissolutionDate: number;
  dissolving: boolean;
  reorganizing: boolean;
  statusString: string;
  ifns: string;
  managementCompanies: unknown[];
  afp: boolean;
  delegation: boolean;
  contractNumber: string;
  opf: string;
  okopf: string;
  infoSource: string;
  edoConnectionDate: number;
  productGroups: number[];
  users: unknown[];
  addresses: unknown[];
  productGroupInfo: unknown[];
  manufactureInfo: unknown[];
  registeredInFns: boolean;
  headExt: unknown[];
  needCheckProfile: boolean;
  foreignOrganisation: unknown;
  hub: boolean;
  blocks: unknown[];
  gs1_list: unknown[];
  isSfRequired: boolean;
}

export interface OrganisationBriefDTO {
  name?: string;
  fullName?: string;
  surname?: string;
  firstname?: string;
  inn?: string;
  hub?: boolean;
  middle_name?: string;
}
