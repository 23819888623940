import { Icon } from '@crpt-ui/core';
import React from 'react';

export const FilterValidationErrors = ({ maxErrorCount, errors }) => {
  const errorList = Object.values(errors).filter(Boolean);
  if (!errorList.length) {
    return null;
  }
  const visibleErrors = errorList.slice(0, maxErrorCount);
  return (
    <>
      {visibleErrors.map((error, index) => (
        <div
          key={index}
          style={{
            background: '#fdf8c8ff',
            padding: '10px',
            borderRadius: '5px',
            maxWidth: '300px',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Icon style={{ padding: '3px' }} name="bell" />
          <div
            style={{
              fontSize: 12,
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            {error}
          </div>
        </div>
      ))}
    </>
  );
};
