import { createAction } from 'redux-actions';

export const subject = '[DocumentsList]';

export const mount = createAction(`${subject} mount`);
export const loaded = createAction(`${subject} loaded`);
export const params = createAction(`${subject} params`);
export const offset = createAction(`${subject} offset`);
export const filter = createAction(`${subject} filter`);
export const removeKeys = createAction(`${subject} remove keys`);
export const submit = createAction(`${subject} submit`);
