import React, { FC, memo } from 'react';

import { translate } from '../../../components/Translate/Translate';

import { PreloaderView } from './Preloader.view';

type PreloaderProps = {
  isOpen: boolean;
  text?: string;
};

export const Preloader: FC<PreloaderProps> = memo(
  ({ isOpen, text = translate('Загрузка списка') }) => {
    return <PreloaderView isOpen={isOpen} text={text} />;
  }
);
