import { RegistrationStatusConnected } from '../components/RegistrationStatus/RegistrationStatus';
import { SettingsConnected } from '../components/Settings/Settings';
import { PaymentStatusConnected } from '../components/PaymentStatus/PaymentStatus';
import { PaymentStatusErrorConnected } from '../components/PaymentStatus/PaymentStatusError';
import { UserStatusEnum, UserRoles } from '../constants/index';
import { AuthConnected } from '../common_components/Auth/Auth';
import { Dashboard } from '../components/_Dashboard/dashboard';
import { LoginForm } from '../components/_LoginForm/LoginForm';
import { LoginFormKep } from '../components/_LoginFormKep/LoginFormKep';
import { LoginFormInn } from '../components/_LoginFormKep/LoginFormInn';
import { MobilePage } from '../components/_Mobile/Mobile';
import { Profile } from '../components/_Profile/Profile';
import { StartPageConnected } from '../components/_StartPage/StartPage';
import { Documents } from '../components/Documents/Documents';
import { InformationPage } from '../components/InformationPage';
import { PageMessage } from '../components/PageMessage/PageMessage';
import { Support } from '../components/Support/Support';
import { ParticipantList } from '../components/ParticipantList/ParticipantList';
import { ParticipantDetails } from '../components/ParticipantDetails';
import { RegistryOIVComp } from '../components/RegistryOIV/RegistryOIV';
import { RegistryOIVPage } from '../components/RegistryOIVPage/RegistryOIVPage';
import { CreateParticipant } from '../components/ParticipantCreate/create-participant';

export const config = {
  docs: {
    reg: 'https://xn--80ajghhoc2aj1c8b.xn--p1ai/upload/%D0%A0%D0%B5%D0%B3%D0%B8%D1%81%D1%82%D1%80%D0%B0%D1%86%D0%B8%D1%8F_%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%BD%D0%B8%D0%BA%D0%B0..pdf',
    faq: '/docs/FAQ_Регистрация_участника_оборота_товаров_в_ИС_МП.pdf',
    letter: '/docs/информационное_письмо.pdf',
  },
  routes: [
    {
      route: '/check-status',
      exact: true,
      private: false,
      inLayout: false,
      component: RegistrationStatusConnected,
    },
    // {
    //   route: '/plugins',
    //   private: false,
    //   inLayout: false,
    //   component: SystemRequirements,
    // },
    {
      route: '/login',
      exact: true,
      private: false,
      inLayout: false,
      component: LoginForm,
    },
    {
      route: '/login-kep',
      exact: true,
      private: false,
      inLayout: false,
      component: LoginFormKep,
    },
    {
      route: '/nid_call_back_url',
      exact: true,
      private: false,
      inLayout: false,
      component: LoginFormKep,
    },
    {
      route: '/login-inn',
      exact: true,
      private: false,
      inLayout: false,
      component: LoginFormInn,
    },
    // {
    //   route: '/user-register',
    //   exact: true,
    //   private: false,
    //   inLayout: false,
    //   component: OIVUserRegister,
    // },
    {
      route: '/start-page',
      exact: true,
      private: false,
      inLayout: false,
      component: StartPageConnected,
    },
    {
      route: '/',
      private: false,
      exact: true,
      component: AuthConnected,
    },
    {
      route: '/information-page',
      private: false,
      exact: true,
      inLayout: false,
      component: InformationPage,
    },
    {
      route: '/home',
      private: true,
      exact: true,
      inLayout: true,
      component: (props) => {
        props.history.push('/profile');
        return null;
      },
    },
    {
      route: '/participants',
      private: true,
      exact: true,
      inLayout: true,
      component: (props) => {
        props.history.push('/participants/list');
        return null;
      },
    },
    {
      route: '/dashboard',
      private: true,
      exact: true,
      inLayout: true,
      component: Dashboard,
      accessDenied: [],
    },
    {
      route: '/oiv',
      private: true,
      exact: true,
      inLayout: true,
      component: RegistryOIVComp,
      canAccess: [UserRoles.IS_MP_OPERATOR, UserRoles.OPERATOR],
    },
    {
      route: '/oiv/:id',
      private: true,
      exact: true,
      inLayout: true,
      component: RegistryOIVPage,
      canAccess: [UserRoles.IS_MP_OPERATOR, UserRoles.OPERATOR],
    },
    {
      route: '/support',
      private: true,
      exact: true,
      inLayout: true,
      component: Support,
    },
    {
      route: '/documents',
      private: true,
      inLayout: true,
      component: Documents,
    },
    {
      route: '/profile',
      private: true,
      inLayout: true,
      component: Profile,
    },
    {
      route: '/message',
      exact: true,
      private: false,
      inLayout: false,
      component: PageMessage,
    },
    {
      route: '/mobile',
      exact: true,
      private: false,
      inLayout: false,
      component: MobilePage,
    },
    // {
    //   route: '/accounts',
    //   exact: true,
    //   private: true,
    //   inLayout: true,
    //   component: AccountsConnected,
    //   accessDenied: [UserRoles.OPERATOR, UserRoles.ORG_OGV],
    // },
    {
      component: SettingsConnected,
      exact: true,
      inLayout: true,
      private: true,
      route: '/settings',
      accessDenied: [UserRoles.OPERATOR],
    },
    {
      component: PaymentStatusConnected,
      exact: true,
      inLayout: true,
      private: true,
      route: '/payment-status/success/:orderId',
      accessDenied: [UserRoles.ORG_OGV],
    },
    {
      component: PaymentStatusErrorConnected,
      exact: true,
      inLayout: true,
      private: true,
      route: '/payment-status/error',
      accessDenied: [UserRoles.ORG_OGV],
    },
    {
      route: '/participants/list',
      private: true,
      exact: true,
      inLayout: true,
      component: ParticipantList,
      canAccess: [
        UserRoles.IS_MP_OPERATOR,
        UserRoles.OPERATOR,
        UserRoles.OGV,
        UserRoles.ORG_OGV,
      ],
    },
    {
      route: '/participants/list/create',
      private: true,
      exact: true,
      inLayout: true,
      component: CreateParticipant,
      accessDenied: [],
    },
    {
      route: '/participants/list/:inn',
      private: true,
      exact: false,
      inLayout: true,
      component: ParticipantDetails,
      accessDenied: [],
    },
  ],
  menuItems: [
    {
      applicableStatuses: [UserStatusEnum.REGISTERED],
      // icon: <Icon name="Home" />,
      route: '/dashboard',
      title: 'Главная',
    },
    {
      applicableStatuses: [UserStatusEnum.REGISTERED],
      // icon: <Icon name="Elk" />,
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        location.href = window.env.REACT_APP_PG_URL;
      },
      route: '/tg',
      title: 'Товарные группы',
    },
    {
      applicableStatuses: [UserStatusEnum.REGISTERED],
      // icon: <Users />,
      route: '/participants/list',
      orgRoles: [UserRoles.IS_MP_OPERATOR],
      title: 'Участники',
    },
    {
      applicableStatuses: [UserStatusEnum.REGISTERED],
      // icon: <RegistryAndDirectories />,
      route: '/oiv',
      title: 'ОИВ',
      orgRoles: [UserRoles.IS_MP_OPERATOR],
    },
    // {
    //   applicableStatuses: [UserStatusEnum.REGISTERED],
    //   icon: <Icon name="wallet" />,
    //   route: '/accounts',
    //   title: 'Счета',
    // },
  ],
};
