import { put, select, takeEvery } from 'redux-saga/effects';

import { getLocation } from '../../App/App.selectors';

import {
  openDemoMessageDialog,
  startDemoMessageSaga,
} from './DemoMessageDialog.actions';

function* demoMessageSaga() {
  const routerLocation = yield select(getLocation);
  const isHostDemo = window.env.PROJECT_ENV === 'int01';

  if (isHostDemo && routerLocation.pathname === '/login-kep') {
    yield put(openDemoMessageDialog());
  }
}

export const saga = function* watch() {
  yield takeEvery(startDemoMessageSaga, demoMessageSaga);
};
