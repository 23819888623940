import React from 'react';

import { ContentWrapper } from '../../styled';

/**
 * Creates a component wrapper as a content wrapper
 *
 * @return {function(*): function(*): *}
 */
export const withContentWrapper = (contentWrapperProps) => (Component) => {
  return (componentProps) => (
    <ContentWrapper {...contentWrapperProps}>
      <Component {...componentProps} />
    </ContentWrapper>
  );
};
