// TODO убрать из локалстораджа всё кроме токена, остальные данные хранить в стэйте

export class localStorageHelpers {
  static getRolesFromToken(token) {
    const payload = token.split('.')[1];
    const payloadObj = JSON.parse(b64DecodeUnicode(payload));
    payloadObj.roles = hexMaskToArrayItems(
      payloadObj.perms,
      Object.keys(ROLES)
    );

    return payloadObj;
  }
}

export const b64DecodeUnicode = (str) => {
  const base64 = str.replace(/-/g, '+').replace(/_/g, '/');
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(base64), (c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
};

function hexMaskToArrayItems(hex, arr) {
  const pickedItems = [];
  if (Array.isArray(arr) && arr.length > 0 && typeof hex === 'string') {
    for (let i = 0; i < hex.length; i++) {
      const mask = parseInt(hex.charAt(i), 16);
      if (!isNaN(mask)) {
        for (let j = 0; j < 4; j++) {
          if ((mask >>> j) % 2 === 1) {
            const pickedItem = arr[i * 4 + j];
            if (pickedItem) {
              pickedItems.push(pickedItem);
            }
          }
        }
      }
    }
  }
  return pickedItems;
}

const ROLES = {
  manageUsers: [
    {
      headline: 'Работа с кодами и заказами',
      list: [
        {
          access: true,
          type: 'Просмотр',
        },
        {
          access: true,
          type: 'Заказ кодов',
        },
        {
          access: true,
          type: 'Аннулирование заказов',
        },
        {
          access: true,
          type: 'Завершение заказов',
        },
        {
          access: true,
          type: 'Списание кодов',
        },
      ],
    },
    {
      headline: 'Настройки',
      list: [
        {
          access: true,
          type: 'Редактирование реквизитов заказов',
        },
        {
          access: true,
          type: 'Управление пользователям',
        },
        {
          access: true,
          type: 'Редактирование личного профиля',
        },
      ],
    },
    {
      headline: 'Работа с товарами',
      list: [
        {
          access: true,
          type: 'Продажа',
        },
        {
          access: true,
          type: 'Возврат',
        },
        {
          access: false,
          type: 'Вывод из оборота',
        },
      ],
    },
  ],
  participant_principal: [
    {
      headline: 'Работа с кодами и заказами',
      list: [
        {
          access: true,
          type: 'Просмотр',
        },
        {
          access: true,
          type: 'Заказ кодов',
        },
        {
          access: true,
          type: 'Аннулирование заказов',
        },
        {
          access: true,
          type: 'Завершение заказов',
        },
        {
          access: true,
          type: 'Списание кодов',
        },
      ],
    },
    {
      headline: 'Настройки',
      list: [
        {
          access: true,
          type: 'Редактирование реквизитов заказов',
        },
        {
          access: true,
          type: 'Управление пользователям',
        },
        {
          access: true,
          type: 'Редактирование личного профиля',
        },
      ],
    },
    {
      headline: 'Работа с товарами',
      list: [
        {
          access: true,
          type: 'Продажа',
        },
        {
          access: true,
          type: 'Возврат',
        },
        {
          access: true,
          type: 'Вывод из оборота',
        },
      ],
    },
  ],
  participant_employee: [
    {
      headline: 'Работа с кодами и заказами',
      list: [
        {
          access: false,
          type: 'Просмотр',
        },
        {
          access: true,
          type: 'Заказ кодов',
        },
        {
          access: false,
          type: 'Аннулирование заказов',
        },
        {
          access: true,
          type: 'Завершение заказов',
        },
        {
          access: true,
          type: 'Списание кодов',
        },
      ],
    },
    {
      headline: 'Настройки',
      list: [
        {
          access: false,
          type: 'Редактирование реквизитов заказов',
        },
        {
          access: true,
          type: 'Управление пользователям',
        },
        {
          access: false,
          type: 'Редактирование личного профиля',
        },
      ],
    },
    {
      headline: 'Работа с товарами',
      list: [
        {
          access: false,
          type: 'Продажа',
        },
        {
          access: true,
          type: 'Возврат',
        },
        {
          access: false,
          type: 'Вывод из оборота',
        },
      ],
    },
  ],
};
