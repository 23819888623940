import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Box, Grid } from '@crpt/material';
import { Button, Icon, Typography } from '@crpt-ui/core';
import { withFormValues } from '@crpt/shared/components/Form/FormSpy';
import { Checkbox, Input } from '@crpt/shared/components/Form/Fields';
import { documentStatusTitle } from '@crpt/shared/constants';
import { availableDocumentStatuses } from '../../AcsUnloads.constants';
import { FieldIds } from '../AcsDocuments.types';

import type { FormErrors, FormValues } from '../AcsDocuments.types';

interface UnloadFilterProps {
  errors: FormErrors;
  values: FormValues;
  isOperator: boolean;
  handleEditInnsOpen: (value: string) => () => void;
}

const UnloadFilter: FC<UnloadFilterProps> = ({
  errors,
  isOperator,
  handleEditInnsOpen,
  values,
}) => {
  const { t } = useTranslation();

  const isStatusDisabled = useCallback(
    (fieldName: string) => {
      return availableDocumentStatuses
        .filter((item) => item !== fieldName)
        .map((item) => get(values, [FieldIds.STATUS, item]))
        .every((item) => !item);
    },
    [values]
  );

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="h6">
          {t('UnloadService.Task.AcsDocuments.FieldGroup.unloadFilter')}
        </Typography>
      </Grid>
      <Grid item xs>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid columnSpacing={2} container>
              <Grid item xs>
                <Input
                  disabled={!isOperator && !!values[FieldIds.RECEIVER_INN]}
                  name={FieldIds.SENDER_INN}
                  placeholder={t(
                    'UnloadService.Task.AcsDocuments.Fields.senderInn'
                  )}
                  required={isOperator && !values[FieldIds.RECEIVER_INN]}
                />
              </Grid>
              {!!values[FieldIds.SENDER_INN] && !errors[FieldIds.SENDER_INN] && (
                <Grid item>
                  <Button
                    color="secondary"
                    onClick={handleEditInnsOpen(FieldIds.SENDER_INN)}
                    startIcon={<Icon name="document" />}
                    style={{
                      fontSize: 16,
                      textTransform: 'none',
                      fontWeight: 500,
                    }}
                    variant="text"
                  >
                    {t('UnloadService.Task.AcsDocuments.AllList')}
                  </Button>
                </Grid>
              )}
            </Grid>
            {isOperator ? (
              <Box height={16} />
            ) : (
              <Box
                sx={{
                  py: 1,
                  fontSize: 14,
                }}
              >
                {t('UnloadService.Task.AcsDocuments.Or')}
              </Box>
            )}
            <Grid columnSpacing={2} container>
              <Grid item xs>
                <Input
                  disabled={!isOperator && !!values[FieldIds.SENDER_INN]}
                  name={FieldIds.RECEIVER_INN}
                  placeholder={t(
                    'UnloadService.Task.AcsDocuments.Fields.receiverInn'
                  )}
                  required={isOperator && !values[FieldIds.SENDER_INN]}
                />
              </Grid>
              {!!values[FieldIds.RECEIVER_INN] &&
                !errors[FieldIds.RECEIVER_INN] && (
                  <Grid item>
                    <Button
                      color="secondary"
                      onClick={handleEditInnsOpen(FieldIds.RECEIVER_INN)}
                      startIcon={<Icon name="document" />}
                      style={{
                        fontSize: 16,
                        textTransform: 'none',
                        fontWeight: 500,
                      }}
                      variant="text"
                    >
                      {t('UnloadService.Task.AcsDocuments.AllList')}
                    </Button>
                  </Grid>
                )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                fontWeight: 600,
                fontSize: 16,
                mb: 3,
              }}
            >
              {t('UnloadService.Task.AcsDocuments.FieldGroup.documentStatuses')}
            </Box>
            <Grid columnSpacing={2} container rowSpacing={2}>
              {availableDocumentStatuses.map((status) => (
                <Grid item key={status} xs={12}>
                  <Checkbox
                    disabled={isStatusDisabled(status)}
                    label={documentStatusTitle[status]}
                    name={`${FieldIds.STATUS}.${status}`}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withFormValues(UnloadFilter, {
  subscription: {
    errors: true,
    values: true,
  },
});
