import React, { Fragment, useCallback, useState } from 'react';

import { Box } from '@material-ui/core';

import { Button, Icon, Link, Notification } from '@crpt-ui/core';

import { SupportListConnected } from './SupportList/SupportList';
import { SupportIncidentDialogConnected } from './SupportIncidentDialog/SupportIncidentDialog';
import { SupportModal } from './SupportList/Modal/SupportModal';

export const Support = () => {
  const [isOpenSupportModal, setOpenSupportModal] = useState(false);

  const onOpenSupportModal = useCallback(() => {
    setOpenSupportModal(true);
  }, []);

  const onCloseSupportModal = useCallback(() => {
    setOpenSupportModal(false);
  }, []);

  return (
    <Fragment>
      <SupportIncidentDialogConnected />

      {window.env.PROJECT_ENV === 'int01' && (
        <Box mb={2}>
          <Notification>
            Подача обращений в&nbsp;службу технической поддержки через личный
            кабинет Демо стенда временно недоступна. <br />
            При возникновении вопросов, пожалуйста, направьте письмо
            по&nbsp;адресу:{' '}
            <Link href="mailto:support@crpt.ru">support@crpt.ru</Link>, указав
            стенд, ИНН и&nbsp;товарную группу
          </Notification>
        </Box>
      )}

      <Box mb={2}>
        <Button
          className="whiteColor"
          icon={<Icon name="plus" />}
          onClick={onOpenSupportModal}
        >
          Создать обращение
        </Button>
        <SupportModal
          isOpenSupportModal={isOpenSupportModal}
          onCloseSupportModal={onCloseSupportModal}
        />
      </Box>

      <SupportListConnected />
    </Fragment>
  );
};
