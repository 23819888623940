export function getPreparedDocument(document: any): any {
  const parent =
    document.documents?.find((doc: any) => doc.id === document.id) || document;
  const children =
    document.documents?.filter((doc: any) => doc.id !== document.id) || [];
  const subRows = children.map((child: any) =>
    getPreparedDocument({
      ...document,
      documents: [],
      ...child,
    })
  );

  return {
    id: parent.id,
    subRows,
    product_group: parent.product_group,
    number: parent.number || parent.id,
    status: parent.status,
    type: parent.type,
    partner_id: document.sender || document.recipient,
    create_time_stamp: document.create_time_stamp,
    total_price: parent.total_price,
  };
}
