import { handleActions } from 'redux-actions';
import { isString, isArray } from 'lodash';
import { LOCATION_CHANGE } from 'connected-react-router';

import { RegistrationSteps } from '../Registration.constants';

import * as actions from './Registration.actions';

const errors = handleActions(
  {
    [actions.errors]: (_, { payload }) => {
      if (isString(payload)) {
        return [payload];
      }
      if (isArray(payload)) {
        return payload;
      }
      return [];
    },
  },
  []
);

const submitDisabled = handleActions(
  {
    [actions.submit]: () => true,
    [actions.errors]: () => false,
  },
  false
);

const step = handleActions(
  {
    [actions.step]: (_, { payload }) =>
      payload || RegistrationSteps.CERTIFICATE,
    [actions.setCompleteStep]: () => RegistrationSteps.COMPLETE,
  },
  RegistrationSteps.CERTIFICATE
);

/**
 * Registration document id
 */
const documentId = handleActions(
  {
    [actions.setDocumentId]: (_, { payload }) => payload,
  },
  0
);

const isLoading = handleActions(
  {
    [actions.setLoading]: (_, { payload }) => payload,
    [LOCATION_CHANGE]: () => false,
  },
  false
);

export const reducer = {
  documentId,
  errors,
  step,
  submitDisabled,
  isLoading,
};
