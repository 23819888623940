import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Typography } from '@crpt-ui/core';

import { Modal } from '../../../common_components/Modal/Modal';

import * as supportIncidentDialogActions from './ducks/SupportIncidentDialog.actions';
import {
  getSupportIncidentDialogData,
  getSupportIncidentDialogOpen,
} from './ducks/SupportIncidentDialog.selectors';

const SupportIncidentDialog = ({ open, data, onUnmount }) => {
  const handleClose = useCallback(() => {
    onUnmount();
  }, [onUnmount]);

  return (
    <Modal
      open={open}
      title={data.refid}
      useHeaderDivider={false}
      onClose={handleClose}
    >
      <Typography style={{ wordBreak: 'break-word' }} variant="h5">
        {data.caption}
      </Typography>

      <Typography
        dangerouslySetInnerHTML={{
          __html: data.description,
        }}
        style={{
          marginTop: 24,
          wordBreak: 'break-word',
        }}
        variant="body1"
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  data: getSupportIncidentDialogData(state),
  open: getSupportIncidentDialogOpen(state),
});

const mapDispatchToProps = {
  onUnmount: supportIncidentDialogActions.onUnmount,
};

export const SupportIncidentDialogConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportIncidentDialog);

SupportIncidentDialog.propTypes = {
  data: PropTypes.shape({
    refid: PropTypes.number,
    caption: PropTypes.string,
    description: PropTypes.string,
  }),
  onUnmount: PropTypes.func,
  open: PropTypes.bool,
};
