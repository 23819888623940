import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as preloaderSelectors from '../../../common_components/Preloader/ducks/Preloader.selectors';

import { openCreatePaymentModal } from './CreatePaymentDocument/ducks/CreatePaymentDocument.actions';
import {
  fetchPaymentDocuments,
  setDefaultAccount,
  setSelectedAccount,
} from './ducks/PaymentDocumentsList.actions';
import {
  getAccountsOptions,
  getDocuments,
  getSelectedAccount,
  getSelectedAccountBalance,
} from './ducks/PaymentDocumentsList.selectors';
import { usePaymentDocumentsListColumns } from './PaymentDocumentsList.utils';
import { PaymentDocumentsView } from './PaymentDocumentsList.view';

const PaymentDocumentsList = ({
  setDefaultAccount,
  fetchPaymentDocuments,
  accountOptions,
  setSelectedAccount,
  openCreatePaymentModal,
  ...rest
}) => {
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    setDefaultAccount();
  }, [setDefaultAccount]);

  const columns = usePaymentDocumentsListColumns();

  const onAccountChange = useCallback((event) => {
    fetchPaymentDocuments(event.target.value);
    setSelectedAccount(event.target.value);
  }, []);

  const onRowSelect = useCallback((event) => {
    setSelectedRows(event);
  }, []);

  const isActionsButtonDisabled = useMemo(
    () => selectedRows.length === 0,
    [selectedRows]
  );

  const onOpenCreatePaymentModal = useCallback(() => {
    openCreatePaymentModal();
  }, [openCreatePaymentModal]);

  return React.createElement(PaymentDocumentsView, {
    onAccountChange,
    onRowSelect,
    onOpenCreatePaymentModal,
    columns,
    accountOptions,
    isActionsButtonDisabled,
    ...rest,
  });
};

const mapStateToProps = (state) => ({
  accountOptions: getAccountsOptions(state),
  documents: getDocuments(state),
  selectedAccount: getSelectedAccount(state),
  selectedAccountBalance: getSelectedAccountBalance(state),
  loading: preloaderSelectors.loading(state),
});

const mapDispatchToProps = {
  setDefaultAccount,
  fetchPaymentDocuments,
  openCreatePaymentModal,
  setSelectedAccount: setSelectedAccount,
};

PaymentDocumentsList.propTypes = {
  fetchPaymentDocuments: PropTypes.func.isRequired,
  setDefaultAccount: PropTypes.func.isRequired,
  openCreatePaymentModal: PropTypes.func.isRequired,
  setSelectedAccount: PropTypes.func.isRequired,
  accountOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export const PaymentDocumentsListConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentDocumentsList);
