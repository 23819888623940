import { get } from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';

import * as autocompleteActions from '../../../../../../../common_components/Filters/AutocompleteFilter/ducks/AutocompleteFilter.actions';
import { ParticipantService } from '../../../../../../../services/ParticipantService';

import * as actions from './ReceiptSelect.actions';

function* loadSaga(action) {
  const { payload } = action;

  const response = yield call(ParticipantService.searchByInput, payload);

  if (get(response, 'data.error', false)) {
    yield put(autocompleteActions.error, get(response, 'data.error', 'error'));
  } else {
    yield put(autocompleteActions.loaded(response.data));
  }
}

export const saga = function* watch() {
  yield takeLatest(actions.updated, loadSaga);
};
