import { handleActions } from 'redux-actions';

import {
  clearServiceDeskDocument,
  setDocumentContent,
  setDocumentInfo,
  setLoading,
} from './ServiceDeskDocument.actions';

const documentContent = handleActions(
  {
    [setDocumentContent]: (_, { payload }) => payload,
    [clearServiceDeskDocument]: () => '',
  },
  ''
);

const documentInfo = handleActions(
  {
    [setDocumentInfo]: (_, { payload }) => payload,
    [clearServiceDeskDocument]: () => ({}),
  },
  {}
);

const loading = handleActions(
  {
    [setLoading]: (_, { payload }) => payload,
    [clearServiceDeskDocument]: () => false,
  },
  false
);

export const reducer = {
  documentContent,
  documentInfo,
  loading,
};
