import React, { useMemo } from 'react';

import { connect } from 'react-redux';
import { chain } from 'lodash';
import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { RequiredFieldsWarning } from '@crpt-ui/core';

import { Separator } from '../../../../common_components/Separator/Separator';
import { PageActions } from '../../../../common_components/PageActions/PageActions';
import { getRegistered } from '../../Company/ducks/Company.selectors';
import * as authSelectors from '../../../../common_components/Auth/ducks/Auth.selectors';
import { translate } from '../../../Translate/Translate';

export const ProfileHeaderComponent = ({
  isEditing,
  isFormErrors,
  form,
  isEditable,
  mainActions,
  setEditing,
  isRegistered,
  useSeparator,
  isRequisitesRequired,
  isAdmin,
}) => {
  const preparedMainActions = useMemo(() => {
    const _mainActions = chain(mainActions).value();

    if (isEditable) {
      _mainActions.unshift({
        label: translate('Отменить'),
        onClick: () => {
          form.reset();
          setEditing(false);
        },
        isShow: isRegistered && isEditing && isAdmin,
        size: 'lg',
        variant: 'outlined',
      });
    }

    return _mainActions;
  }, [form, isEditable, isEditing, isRegistered, mainActions, setEditing]);

  return (
    <Box mb={3}>
      <Grid container alignItems="center" justify="space-between" spacing={2}>
        <Grid xs={7}>
          <PageActions mainActions={preparedMainActions} />
        </Grid>
        <Grid xs={5}>
          <Grid container alignItems="center" justify="flex-end" spacing={2}>
            {isEditing && isRequisitesRequired && (
              <Grid item>
                <RequiredFieldsWarning error={isFormErrors} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {useSeparator && <Separator mt={3} />}
    </Box>
  );
};

ProfileHeaderComponent.propTypes = {
  isEditing: PropTypes.bool,
  isFormErrors: PropTypes.bool,
  page: PropTypes.string,
  mainActions: PropTypes.array,
  isAdmin: PropTypes.bool.isRequired,
};

ProfileHeaderComponent.defaultProps = {
  isEditable: false,
  useSeparator: true,
  isRequisitesRequired: true,
};

const mapStateToProps = (state) => ({
  isRegistered: getRegistered(state),
  isAdmin: authSelectors.isAdmin(state),
});

export const ProfileHeader = connect(mapStateToProps)(ProfileHeaderComponent);
