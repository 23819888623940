import { call, put, takeEvery } from 'redux-saga/effects';
import { get, isNull } from 'lodash';

import { Api } from '../../../../common_components/Api/Api';

import * as DocumentsListActions from './DocumentsList.actions';

function* getListSaga() {
  const requestPayload = {
    url: '/crm-api/documents',
    timeout: 10000,
  };

  const [success] = yield call(Api.request, requestPayload);

  if (get(success, 'data.response', false)) {
    const items = success.data.response.map((row) => ({
      __form_id: get(row, 'docid'),
      ...row,
    }));

    yield put(
      DocumentsListActions.loaded({
        ...success.data,
        items: items.filter((item) => !isNull(item.doctype)),
      })
    );
  }
}

function* savePaginatorValuesSaga(action = {}) {
  const { payload } = action;
  yield put(DocumentsListActions.offset((payload.page - 1) * 10));
}

export const saga = function* watch() {
  yield takeEvery(
    [
      DocumentsListActions.filter,
      DocumentsListActions.offset,
      DocumentsListActions.removeKeys,
      DocumentsListActions.mount,
    ],
    getListSaga
  );
  yield takeEvery(DocumentsListActions.subject, savePaginatorValuesSaga);
};
