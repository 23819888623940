export enum FieldIds {
  NAME = 'name',
  GTIN = 'gtin',
  DATA = 'data',
}

export interface FormValues {
  [FieldIds.DATA]: {
    [FieldIds.NAME]: string;
    [FieldIds.GTIN]: string;
  }[];
}
