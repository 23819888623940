import { createActions } from 'redux-actions';

export const { openHelperModal, closeHelperModal } = createActions(
  {
    OPEN_HELPER_MODAL: () => {},
    CLOSE_HELPER_MODAL: () => {},
  },
  {
    prefix: 'PROFILE_HELPER',
  }
);
