import styled from 'styled-components';

export const Item = styled.div`
  display: flex;
  outline: none;
  padding: 0 20px;
  box-sizing: border-box;
  cursor: pointer;

  & g {
    fill: #2b5d80;
  }

  &:hover {
    background: #f7f7f7;
  }
`;

export const ItemText = styled.div`
  margin-left: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 38px;
`;
