import React, { Fragment } from 'react';

import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';

import { Loader } from '../../../common_components/Preloader/Preloader';
import { withContentWrapper } from '../../../common_components/hocs/withContentWrapper/withContentWrapper';
import { translate } from '../../Translate/Translate';

import { CommonFormConnected } from './common-info/common-form-view';
import { RolesConnected } from './roles/roles';
import { ContactsForm } from './contacts/contacts-form-view';
import { ParticipantForm } from './participant/participant-form-view';
import { ForeignOrganisationInfoConnected } from './foreign-organisation-info/foreign-organisation-info';

type CompanyViewComponent = {
  isForeignLegalEntity: boolean;
  isLoading: boolean;
};

const CompanyViewComponent: React.FC<CompanyViewComponent> = ({
  isForeignLegalEntity,
  isLoading,
}) => {
  if (isLoading) {
    return <Loader text={translate('Загрузка')} />;
  }

  return (
    <Fragment>
      <Box mb={5}>
        <Grid
          container
          alignItems="flex-start"
          justify="space-between"
          spacing={2}
        >
          <Grid item xs={6}>
            <CommonFormConnected />
          </Grid>
          <Grid item xs={6}>
            <ContactsForm disabled />
          </Grid>
        </Grid>
      </Box>
      <Box mb={5}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ParticipantForm />
            {isForeignLegalEntity && (
              <Box mt={5}>
                <ForeignOrganisationInfoConnected />
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <RolesConnected disabled />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

CompanyViewComponent.propTypes = {
  stepDescriptionData: PropTypes.shape({}),
  isLoading: PropTypes.bool.isRequired,
  isRegistered: PropTypes.bool.isRequired,
  isForeignLegalEntity: PropTypes.bool.isRequired,
  isFormErrors: PropTypes.bool.isRequired,
};

export const CompanyView = withContentWrapper()(CompanyViewComponent);
