import { getValue } from '../../../common_components/Document/Document.utils';
import {
  documentStatusHEXColorsMap,
  documentStatusIconsMap,
  edoDocumentStatusColorsMap,
  edoDocumentStatusValuesMap,
  sdDocumentStatusIconsMap,
  sdDocumentStatusValuesMap,
} from '../DocumentsList/DocumentsList.constants';

export const API_ENVIRONMENT = {
  EDO: 'edo',
  CRM: 'crm',
};

/**
 * @param document
 * @returns {{document_direction: string, document_source: string}|{document_direction: (string), document_source: (string)}}
 */
export const definitionEnvironment = (document) => {
  if (!document) {
    return {
      document_source: API_ENVIRONMENT.CRM,
      document_direction: 'outgoing-documents',
    };
  }

  const signedDocument = document.status === 2 || document.edoDocumentId;

  let document_source = signedDocument
    ? API_ENVIRONMENT.EDO
    : API_ENVIRONMENT.CRM;
  let document_direction = 'outgoing-documents';

  switch (document.doctype) {
    case -10:
    case -11:
    case -26:
    case -27:
      document_source = signedDocument
        ? API_ENVIRONMENT.EDO
        : API_ENVIRONMENT.CRM;
      break;
    case -16:
      document_direction = 'incoming-documents';
      break;
    case -19:
      document_source = API_ENVIRONMENT.CRM;
      break;
    default:
      break;
  }

  return {
    document_source,
    document_direction,
  };
};

export const getPrepareDocumentStatus = (
  edoStatus,
  sdStatus,
  useHEXColor = false
) => {
  const hasEdoStatus = edoStatus !== undefined;

  const status = hasEdoStatus ? edoStatus : sdStatus;

  const statusEnum = hasEdoStatus
    ? edoDocumentStatusValuesMap
    : sdDocumentStatusValuesMap;

  const iconEnum = hasEdoStatus
    ? documentStatusIconsMap
    : sdDocumentStatusIconsMap;

  const colorEnum = useHEXColor
    ? documentStatusHEXColorsMap
    : edoDocumentStatusColorsMap;

  return {
    color: getValue(colorEnum, status),
    icon: getValue(iconEnum, status),
    label: getValue(statusEnum, status),
  };
};
