import { createAction } from '@reduxjs/toolkit';

const subject = 'ParticipantDetails';

export const acceptAndCancelAndRefundRegistration = createAction(
  `${subject}/acceptAndCancelAndRefundRegistration`
);
export const activateProductGroup = createAction(
  `${subject}/activateProductGroup`
);
export const fetchUpdateUot = createAction(`${subject}/fetchUpdateUot`);
export const setUpdateUot = createAction(`${subject}/setUpdateUot`);
export const setChangeStatusUOT = createAction(`${subject}/setChangeStatusUOT`);
export const setChangeStatusProductGroup = createAction(
  `${subject}/setChangeStatusProductGroup`
);
export const setUpdated = createAction(`${subject}/setUpdated`);

export const mounted = createAction(`${subject}/mounted`);
export const loaded = createAction(`${subject}/loaded`);
export const loadingEnd = createAction(`${subject}/loadingEnd`);
export const unmounted = createAction(`${subject}/unmounted`);
