import i18n from 'i18next';

import { GroupItem } from './ducks/Company.store';

export const makeSelectOptions = (data: GroupItem[]) => {
  return data.map(({ code, name }) => ({
    value: code,
    label: i18n.t(name),
  }));
};

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/(\d{3})(\d{2})(\d{2})(\d{2})$/);
  if (match) {
    const intlCode = '+996';
    return [
      intlCode,
      ' (',
      match[1],
      ') ',
      match[2],
      '-',
      match[3],
      '-',
      match[4],
    ].join('');
  }
  return null;
};
