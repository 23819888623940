import { createActions } from 'redux-actions';

export const {
  closePaymentDialog,
  openPaymentDialog,
  sendPayment,
  setLoading,
} = createActions(
  {
    CLOSE_PAYMENT_DIALOG: () => {},
    OPEN_PAYMENT_DIALOG: () => {},

    SEND_PAYMENT: (payload) => payload,

    SET_LOADING: (payload) => payload,
  },
  {
    prefix: 'PaymentDialog',
  }
);
