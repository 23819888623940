import React, { Fragment, useCallback, useState } from 'react';

import { Box, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Table } from '@ibox/ui';

import { withContentWrapper } from '../../../common_components/hocs/withContentWrapper/withContentWrapper';
import { PreloaderWrapper } from '../../../common_components/PreloaderWrapper/PreloaderWrapper';
import { BlockUserDialog } from '../../BlockUserDialog/BlockUserDialog';
import { UserRoles } from '../../../constants';
import type { UserInfo } from '../../../common_components/Auth/ducks/Auth.store';
import { Data } from '../Company/ducks/Company.store';
import { getCookie } from '../../../utils/cookieHelpers';

import { AddingUserDialogConnected } from './adding-user-dialog/adding-user-dialog';
import { CertificateSelectionDialogConnected } from './adding-user-dialog/CertificateSelectionDialog';
import { HeaderConnected } from './Header/Header';
import { EditingUserRoleView } from './editing-user-role/editing-user-role';
import type { UserListItem } from './ducks/UserList.store';
import { putUserRole } from './ducks/UserList.actions';
import type { ColumnsItem } from './UserList.utils';

import css from './user-list.module.scss';

type UserListComponentView = {
  data: UserListItem[];
  isLoading: boolean;
  isInActive: boolean;
  openBlockUserDialog: () => void;
  userInfo: UserInfo;
  companyData: Data;
  columns: ColumnsItem[];
};

const UserListComponent: React.FC<UserListComponentView> = ({
  data,
  columns,
  isLoading,
  userInfo,
  companyData,
  isInActive,
  openBlockUserDialog,
}) => {
  const { authorities, full_name: fullName, id } = userInfo;
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState(null);
  const isUserAdmin = authorities.includes(UserRoles.ADMIN);
  const isHeadAdmin = authorities.includes('ROLE_HEAD_OPERATOR');
  const language = getCookie('language') || 'hy';

  const renderActionMenu = useCallback(
    ({ row: { original } }) => [
      <MenuItem
        className={`${css.actionMenuItem} ${css.weightGainSelector}`}
        disabled={(isHeadAdmin || !isUserAdmin) && original?.user?.id === id}
        key="change"
        onClick={() => setSelectedUser(original)}
      >
        {t('Изменить')}
      </MenuItem>,
      ...(!(
        original.user.status === 'DRAFT' || original.user.status === 'ACTIVE'
      ) || original.user.id === userInfo.id
        ? []
        : [
            <MenuItem
              className={`${css.actionMenuItem} ${css.weightGainSelector}`}
              key="block"
              onClick={() => openBlockUserDialog(original.user)}
            >
              {t('Блокировать')}
            </MenuItem>,
          ]),
    ],
    [fullName, isUserAdmin, openBlockUserDialog, t, userInfo.id]
  );

  return (
    <Fragment>
      <AddingUserDialogConnected />
      <BlockUserDialog mode="profile" />
      <CertificateSelectionDialogConnected />
      <HeaderConnected isAdmin={isUserAdmin} isInActive={isInActive} />
      <PreloaderWrapper isLoading={isLoading}>
        <Box mb={-4} mx={-4}>
          <Table
            columns={columns}
            data={data}
            enableRowActions={isUserAdmin || isInActive}
            enableTopToolbar={false}
            locale={language}
            renderRowActionMenuItems={renderActionMenu}
          />
        </Box>
        <EditingUserRoleView
          companyID={companyData?.id}
          putUserRole={putUserRole}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      </PreloaderWrapper>
    </Fragment>
  );
};

UserListComponent.defaultProps = {
  data: [],
};

export const UserListView = withContentWrapper()(UserListComponent);
