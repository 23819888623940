import { createActions } from 'redux-actions';

export const {
  openSignModalById,
  setDocumentId,
  signAgreementDocument,
  successfullySignedAgreementDocuments,
} = createActions(
  {
    OPEN_SIGN_MODAL_BY_ID: (payload) => payload,

    SET_DOCUMENT_ID: (payload) => payload,

    SIGN_AGREEMENT_DOCUMENT: () => {},
    SUCCESSFULLY_SIGNED_AGREEMENT_DOCUMENTS: () => {},
  },
  {
    prefix: 'AGREEMENT_DOCUMENT_SIGN_MODAL',
  }
);
