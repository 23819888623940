import { handleActions } from 'redux-actions';

import { setSended, setValidToken } from './OIVUserRegister.actions';

const sended = handleActions(
  {
    [setSended]: (_state, { payload }) => payload,
  },
  false
);

const isValidToken = handleActions(
  {
    [setValidToken]: (_state, { payload }) => payload,
  },
  false
);

export const reducer = {
  sended,
  isValidToken,
};
