import { createSelector } from 'reselect';

import { getProductGroupsAndRoles } from '../../Company/ducks/Company.selectors';
import { isShowProductionTab } from '../../Profile.utils';

export const getProduction = (state) => state.Profile.Production;

export const getProductionData = createSelector(
  [getProduction],
  (production) => production.data
);

export const getProductionPage = createSelector(
  [getProduction],
  (production) => production.page
);

export const getProductionPagesCount = createSelector(
  [getProductionData],
  (data) => Math.ceil(data.total / 10)
);

export const getOpenModal = createSelector(
  [getProduction],
  (production) => production.isOpenModal
);

export const getLoading = createSelector(
  [getProduction],
  (production) => production.isLoading
);

export const getSubmitting = createSelector(
  [getProduction],
  (production) => production.submitting
);

export const getShowProductionTab = createSelector(
  [getProductGroupsAndRoles],
  (productGroupsAndRoles) => isShowProductionTab(productGroupsAndRoles)
);

export const getProductionLineId = createSelector(
  [getProduction],
  (production) => production.productionLineId
);

export const getProductionCountries = createSelector(
  [getProduction],
  (production) => production.countries
);

export const getCurrentProductionLine = createSelector(
  [getProductionData, getProductionLineId],
  (productionData, currentProductionLine) =>
    productionData.result[currentProductionLine]
);
