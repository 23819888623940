import { createActions } from 'redux-actions';

export const { openSignModal, closeSignModal, setSigningProcess } =
  createActions(
    {
      OPEN_SIGN_MODAL: (payload) => payload,
      CLOSE_SIGN_MODAL: () => {},

      SET_SIGNING_PROCESS: (payload) => payload,
    },
    {
      prefix: 'DOCUMENT_SIGNING_MODAL',
    }
  );
