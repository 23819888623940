import React from 'react';

import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import { PDFPreview } from '../../../../common_components/PDFPreview/PDFPreview';
import { ContentWrapper } from '../../../../common_components/styled';
import { PreloaderWrapper } from '../../../../common_components/PreloaderWrapper/PreloaderWrapper';

import { DocumentFooterConnected } from './Footer/Footer';
import { DocumentHeaderConnected } from './Header/Header';

export const ViewClosingDocumentView = ({ documentContent, isLoading }) => (
  <ContentWrapper>
    <DocumentHeaderConnected />

    <PreloaderWrapper isLoading={isLoading} minHeight={600}>
      <Box ml={-4} mr={-4}>
        <PDFPreview
          error={false}
          pdfData={documentContent}
          onError={() => {}}
        />
      </Box>
    </PreloaderWrapper>

    <Box mt={2}>
      <DocumentFooterConnected />
    </Box>
  </ContentWrapper>
);

ViewClosingDocumentView.propTypes = {
  documentData: PropTypes.string.isRequired,
};
