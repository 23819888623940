import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { Icon } from '@crpt-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@crpt/material';

export interface AggregationStep {
  value: string | number;
  icon?: React.ReactNode;
}

interface AggregationStepsProps {
  onClick: (index: number) => void;
  steps: AggregationStep[];
}

export const AggregationSteps = ({ onClick, steps }: AggregationStepsProps) => {
  const classes = useStyles();

  const goBack = useCallback(() => onClick(0), [onClick]);

  const handleOnClick = useCallback(
    ({ target: { id } }) => id && onClick(parseInt(id) + 1),
    [onClick]
  );

  return steps.length === 0 ? null : (
    <Grid container alignItems="center" className={classes.root}>
      <Grid onClick={goBack} className={classes.arrow}>
        <Icon name="ArrowLeft" />
      </Grid>
      {steps.map((step, index) => {
        const activeClass =
          steps.length === index + 1 ? classes.lastArrow : classes.arrow;
        return (
          <Grid
            item
            id={index.toString()}
            key={step.value}
            onClick={handleOnClick}
            className={activeClass}
          >
            <Box
              gap={1}
              display="flex"
              alignItems="center"
              style={{ pointerEvents: 'none' }}
            >
              {step.icon}
              {step.value}
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '12px',
    fontWeight: 600,
  },
  arrow: {
    height: '40px',
    minWidth: '48px',
    background: theme.palette.grey[400],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    margin: '0 13px',
    padding: '0 16px',
    cursor: 'pointer',
    '&:before': {
      content: '""',
      width: 0,
      height: 0,
      borderTop: '20px solid transparent',
      borderRight: `13px solid ${theme.palette.grey[400]}`,
      borderBottom: '20px solid transparent',
      position: 'absolute',
      left: '-13px',
    },
    '&:after': {
      content: '""',
      width: 0,
      height: 0,
      borderTop: `20px solid ${theme.palette.grey[400]}`,
      borderRight: '13px solid transparent',
      borderBottom: `20px solid ${theme.palette.grey[400]}`,
      position: 'absolute',
      right: '-13px',
    },
  },
  lastArrow: {
    height: '40px',
    minWidth: '48px',
    background: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    margin: '0 13px',
    padding: '0 16px',
    cursor: 'inherit',
    pointerEvents: 'none',
    '&:before': {
      content: '""',
      width: 0,
      height: 0,
      borderTop: '20px solid transparent',
      borderRight: `13px solid ${theme.palette.primary.main}`,
      borderBottom: '20px solid transparent',
      position: 'absolute',
      left: '-13px',
    },
  },
}));
