import { Checkbox } from '@crpt-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

export const ToggleAllRowSelectedCell = ({ getToggleAllRowsSelectedProps }) => (
  <Checkbox
    {...getToggleAllRowsSelectedProps({
      title: '',
      style: {
        marginLeft: -8,
      },
    })}
  />
);

export const makeToggleAllRowSelectedCell = () => (props) =>
  React.createElement(ToggleAllRowSelectedCell, props);

ToggleAllRowSelectedCell.propTypes = {
  getToggleAllRowsSelectedProps: PropTypes.func.isRequired,
};

export default ToggleAllRowSelectedCell;
