import React, { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Table } from '@ibox/ui';

import { AddUserOiv } from '../add-user-oiv/add-user-oiv';
import { getCookie } from '../../utils/cookieHelpers';
import { ContentWrapper } from '../common/styled';

import { getColumns } from './RegistryOIVPage.constants';

import styles from './index.module.scss';

type OivDataListProp = {
  currentPage: number;
  loaded: boolean;
  results: {}[];
  pid: unknown;
  onChangePage:
    | ((page: number, offset?: number | undefined) => void)
    | undefined;
  last: boolean;
};
export const OivDataList = (props: OivDataListProp) => {
  const { t } = useTranslation();
  const language = getCookie('language') || 'hy';

  const { currentPage } = props;
  const { loaded, results, pid, onChangePage, last } = props;
  const [addUserModal, setAddUserModal] = useState(false);

  const handleChangeOpenUserModal = () => {
    setAddUserModal(!addUserModal);
  };

  const columns = useMemo(
    () =>
      getColumns({
        t,
      }),
    [t]
  );

  return (
    <ContentWrapper>
      <Box className={styles.actionWrapper}>
        <Button onClick={handleChangeOpenUserModal}>
          <Box sx={{ display: 'flex' }}>
            {t('Добавить')}
            <Icon name="Plus" />
          </Box>
        </Button>
      </Box>

      <Box p={3}>
        <Table
          columns={columns}
          currentPage={currentPage}
          data={results}
          isLastPage={last}
          isLoading={!loaded}
          labelPagination={t('Показать еще')}
          locale={language}
          onPageChange={onChangePage}
        />
      </Box>

      {addUserModal && (
        <AddUserOiv
          open={addUserModal}
          pid={pid}
          onClose={handleChangeOpenUserModal}
        />
      )}
    </ContentWrapper>
  );
};
