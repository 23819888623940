import { saveAs } from 'file-saver'; // /FileSaver
import { get } from 'lodash';
import { push } from 'react-router-redux';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { toast } from '@crpt/shared/toast';

import { Api } from '../../../../../common_components/Api/Api';
import {
  filters2params,
  filters2url,
  updateUrlParams,
} from '../../../../../utils/url-utils';
import {
  displayDataLimit,
  requestLimit,
} from '../../../../../constants/requests';
import { documentTypesEnum } from '../../../../../constants/documents';

import * as actions from './Documents.actions';

function* fetchSaga(action) {
  const { payload = {} } = action;
  const {
    page = 1,
    filters,
    history,
    updateHistory = false,
    pathname = '',
  } = payload;

  let filterParams = filters2params(filters);

  if (
    filterParams.documentType &&
    filterParams.documentType === documentTypesEnum.OST_DESCRIPTION
  ) {
    const { documentType: _, ...omitedFilters } = filterParams;
    filterParams = omitedFilters;

    filterParams.documentTypes = [
      documentTypesEnum.OST_DESCRIPTION,
      documentTypesEnum.OST_DESCRIPTION_CSV,
      documentTypesEnum.OST_DESCRIPTION_XML,
    ];
  }

  const requestPayload = {
    url: '/api/v3/facade/documents/search',
    method: 'post',
    data: {
      ...filterParams,
      pagination: {
        limit: requestLimit,
        offset: (page - 1) * displayDataLimit,
      },
      excludingTypes: [
        'LK_ADD_APP_USER',
        'LK_ADD_APP_USER_XML',
        'GREY_ZONE',
        'LP_FTS_INTRODUCE_REQUEST',
      ],
      ...{
        ...filters,
        page: undefined,
      },
    },
  };

  // Error field does not used here cause there are no designs for catching error in datatable. Instead of it we have global error catching - toasts.
  const [success] = yield call(Api.request, requestPayload);

  if (success) {
    // Для запроса используется requestLimit = 11 (на +1 элемент больше, чем отображется на странице),
    // чтобы узнать, находимся мы на последней странице или нет
    // (Если в ответе пришло меньше 11 строк - то последняя страница,
    // Если в ответе пришло 11 строк - страницы есть еще)
    if (page) {
      yield put(actions.updatePage(page));
    }
    const requestResults = success.data || [];
    const results = requestResults.slice(0, displayDataLimit);

    const last = requestResults.length < requestLimit;
    const resultData = {
      results,
      last,
    };

    if (updateHistory && history) {
      const filtersUrl = filters2url(filters);
      const urlString =
        pathname + '?' + (filtersUrl ? filtersUrl + '&' : '') + `page=${page}`;
      yield call(updateUrlParams, {
        urlString,
      });
    }
    if (page === 1) {
      yield put(actions.firstLoaded(resultData));
    } else {
      yield put(actions.loaded(resultData));
    }
  }
}

function* fileUploadSaga(action) {
  const { payload } = action;
  const {
    file: { file, properties, content, data },
    message,
    url,
  } = payload;
  const { document_type } = properties;

  const formData = new FormData();
  formData.append(
    'properties',
    new Blob([JSON.stringify(properties)], {
      type: 'application/json',
    })
  );
  if (typeof content !== 'string') {
    const fileType = /\.xls[x]?/.test(file.name)
      ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      : 'multipart/form-data';
    formData.append(
      'file',
      new Blob([data], {
        type: fileType,
      }),
      file.name
    );
  } else {
    formData.append(
      'file',
      new Blob([content], {
        type: file.type,
      }),
      file.name
    );
  }

  const requestPayload = {
    url,
    method: 'post',
    type: 'multipart',
    data: formData,
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    if (success.data.errors && success.data.errors.length) {
      const errors = get(success, 'data.errors', []);
      yield put(actions.gotErrors(errors));
    } else {
      yield call(toast.success, message);
      if (url === '/api/v3/facade/doc/upload') {
        if (document_type === documentTypesEnum.IMPORT_TRANSIT) {
          yield put(
            push(`/documents/eaes_import/draft-${success.data.result}`)
          );
        } else {
          yield put(push(`/documents/import/draft-${success.data.result}`));
        }
      } else {
        yield put(actions.mounted());
      }
    }
  }
  if (error) {
    yield put(actions.gotErrors(get(error, 'response.data', 'error')));
  }
}

function* filePreview({ payload }) {
  const { id } = payload;

  const requestPayload = {
    url: `/api/v3/facade/doc/body`,
    method: 'post',
    data: {
      id,
    },
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    const content = get(success, 'data.content', '');
    if (!content) {
      put(actions.gotErrors('Сервер вернул пустой файл.'));
    } else {
      //TODO:REFACTOR yield.put filePreviewActions.openString removed cause is not defined
    }
  }
  if (error) {
    yield put(actions.gotErrors(get(error, 'response.data', 'error')));
  }
}

function* downloadDoc(action) {
  const { payload } = action;
  const { id } = payload;
  const decodedId = decodeURIComponent(id);

  const requestPayload = {
    url: `/api/v3/document-api/documents/buildReport/xls`,
    method: 'post',
    responseType: 'blob',
    preloading: false,
    data: {
      id: decodedId,
    },
  };

  const [success] = yield call(Api.request, requestPayload);

  if (success) {
    yield call(saveAs, success.data, `${payload.id}.${payload.type}`);
  }
}

export const saga = function* watch() {
  yield takeLatest(actions.fetch, fetchSaga);
  yield takeEvery(actions.fileUpload, fileUploadSaga);
  yield takeEvery(actions.openDocument, filePreview);
  yield takeEvery(actions.downloadDoc, downloadDoc);
};
