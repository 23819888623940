import { handleActions } from 'redux-actions';

import { setLoading } from './CreateParticipant.actions';

const loading = handleActions(
  {
    [setLoading]: (_, { payload }) => payload,
  },
  false
);

export const reducer = {
  loading,
};
