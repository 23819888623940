import React, { FC, useMemo } from 'react';

import { ChipStatus } from '@ibox/ui';

const USER_STATUSES = {
  ACTIVE: 'success',
  APPROVED: 'default',
  BLOCKED: 'error',
  REGISTERED: 'default',
  REMOVED: 'error',
  DRAFT: 'default',
};

const PARTICIPANT_STATUSES = {
  REGISTERED: 'success',
  PRE_REGISTERED: 'default',
  REJECTED: 'error',
};

type ColorType =
  | 'default'
  | 'secondary'
  | 'success'
  | 'primary'
  | 'error'
  | 'info'
  | 'warning';

type StatusProps = {
  label: string;
  status: keyof typeof USER_STATUSES;
  className?: string;
  participantStatus?: boolean;
};

export const Status: FC<StatusProps> = ({
  label,
  status,
  participantStatus,
  ...props
}) => {
  const color = useMemo(() => {
    if (!status) {
      return 'default';
    }
    if (participantStatus) {
      return PARTICIPANT_STATUSES[
        status as keyof typeof PARTICIPANT_STATUSES
      ] as ColorType;
    }
    return USER_STATUSES[status as keyof typeof USER_STATUSES] as ColorType;
  }, [participantStatus, status]);

  return (
    <ChipStatus
      {...props}
      small
      color={color}
      data-testid="status"
      label={label}
    />
  );
};
