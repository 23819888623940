import React, { FC, useEffect, useMemo } from 'react';
import { uniq } from 'lodash';
import { RangeDatePickerInput as RangeDatePickerInputCRPT } from '@crpt-ui/core';
import FieldHoc from '../FieldHoc';

import type { FieldRenderProps } from 'react-final-form';

interface RangeDatePickerAdapterProps
  extends FieldRenderProps<any, HTMLInputElement> {
  keepValue?: boolean;
  placeholder?: string;
}

const RangeDatePickerAdapter: FC<RangeDatePickerAdapterProps> = ({
  input,
  keepValue,
  meta,
  ...fieldRest
}) => {
  const { placeholder, ...rest } = fieldRest;

  useEffect(() => {
    if (!keepValue) {
      return () => {
        input.onChange('');
      };
    }
  }, []);

  const error = useMemo(() => {
    if (meta.touched || meta.modified) {
      if (meta.error && !Array.isArray(meta.error)) {
        const errors = Object.values(meta.error).flat();

        return uniq(errors).join(', ');
      }

      return meta.error;
    }

    return undefined;
  }, [meta.error, meta.touched, meta.modified]);

  return (
    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    <RangeDatePickerInputCRPT
      endDate={input.value ? input.value.endDate : undefined}
      inputProps={{
        readOnly: true,
      }}
      label={placeholder}
      onChange={input.onChange}
      onFocus={input.onFocus}
      startDate={input.value ? input.value.startDate : undefined}
      {...rest}
      error={error}
    />
  );
};

export default FieldHoc(RangeDatePickerAdapter);
