import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

const getLocalState = (state) =>
  get(state, 'ParticipantDetails.components.documents', {});

export const lastSelector = createSelector(
  getLocalState,
  (state) => state.last
);
export const resultsSelector = createSelector(
  getLocalState,
  (state) => state.results
);
export const selectedPageSelector = createSelector(
  getLocalState,
  (state) => state.selectedPage
);
export const uploadErrors = createSelector(
  getLocalState,
  (state) => state.uploadErrors
);
export const reloading = createSelector(
  getLocalState,
  (state) => state.reloading
);

export const savedFiltersSelector = createSelector(
  getLocalState,
  (state) => state.savedFilters
);

export const historyFiltersSelector = createSelector(
  getLocalState,
  (state) => state.historyFilters
);
