import { DocumentTypeEnum } from '@crpt/shared/services/bff';
import type { DialogProps } from '@material-ui/core';
import { ReturnTypeEnum } from '../../services/bff/constants/ReturnTypeEnum';

export enum UploadCisDialogRequests {
  FETCH_CISES, // Простое получение списка кодов из файла
  CISES, // Валидация кодов + Получение информации о валидных кодах
  PRODUCT_ENRICHMENT, // [Значение по-умолчанию] Обогащение информации о валидных кодах информацией о товарах
  ORGANISATION_ENRICHMENT, // Обогащение информации о валидных кодах информацией об организациях
}

export interface UploadCisDialogViewModelProps extends DialogProps {
  doctype: keyof typeof DocumentTypeEnum;
  productGroup: number | number[];
  onAccept?: (data: any) => void;
  request?: UploadCisDialogRequests;
  loadChildrens?: boolean;
  fetchCisesByCisSearchRequest?: boolean;
  participantInn?: string;
  returnType?: ReturnTypeEnum;
}

export interface Organization {
  name: string;
  inn: string;
}

export interface ValidationCode {
  groupType: string;
}
