import { useMemo } from 'react';

import { ViewClosingDocumentConnected } from './ViewClosingDocument/ViewClosingDocument';
import { SignClosingDocumentConnected } from './SignClosingDocument/SignClosingDocument';

export const documentsRoutes = [
  {
    component: ViewClosingDocumentConnected,
    path: '/documents/cd/:id',
  },
  {
    component: SignClosingDocumentConnected,
    path: '/documents/cd/:id/sign',
  },
];

export const useClosingDocumentRoutes = () =>
  useMemo(() => documentsRoutes, []);
