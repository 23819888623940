import React, { Fragment, useMemo } from 'react';

import { Grid, Typography } from '@mui/material';

import { Button } from '@ibox/ui';

import { Dialog } from '../Dialog/Dialog';
import { Separator } from '../Separator/Separator';
import { CloseModalIcon } from '../CloseModalIcon/CloseModalIcon';
import { prepareList } from '../PageActions/PageActions';

export type ModalActionsType = {
  label: string;
  callback: () => void;
  variant?: 'contained' | 'outlined' | 'text';
  disabled?: boolean;
  gridProps?: unknown;
}[];

interface ModalProps {
  children: React.ReactNode;
  onClose: () => void;
  actions: ModalActionsType;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  title?: string;
  titleVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2';
  open: boolean;
  useCloseIcon?: boolean;
  useHeaderDivider?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  maxWidth,
  title,
  titleVariant,
  open,
  onClose,
  children,
  actions,
  useHeaderDivider,
  useCloseIcon,
  ...rest
}) => {
  const preparedActions: ModalActionsType = useMemo(
    () => prepareList(actions),
    [actions]
  );

  return (
    <Dialog maxWidth={maxWidth} open={open} onClose={onClose} {...rest}>
      {Boolean(title) && (
        <Typography
          style={{
            marginTop: 0,
            paddingRight: 24,
            paddingBottom: 16,
          }}
          variant={titleVariant}
        >
          {title}
        </Typography>
      )}
      {useCloseIcon && <CloseModalIcon onClick={onClose} />}
      {useHeaderDivider && Boolean(title) && <Separator />}
      {children}
      {actions.length > 0 && (
        <>
          <Separator mt={2} />
          <Grid container spacing={2}>
            {preparedActions.map(
              ({ label, callback, variant, disabled, gridProps, ...rest }) => (
                <Grid item key={label} {...gridProps}>
                  <Button
                    className={
                      !variant || variant === 'contained' ? 'whiteColor' : ''
                    }
                    disabled={disabled}
                    variant={variant}
                    onClick={callback}
                    {...rest}
                  >
                    {label}
                  </Button>
                </Grid>
              )
            )}
          </Grid>
        </>
      )}
    </Dialog>
  );
};

Modal.defaultProps = {
  maxWidth: 'md',
  titleVariant: 'h5',
  useHeaderDivider: true,
  useCloseIcon: true,
  actions: [],
};
