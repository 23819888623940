import React, { useState } from 'react';

import { Notification, Typography } from '@crpt-ui/core';

export const OperatorsNotification = () => {
  const [visible, setVisible] = useState(true);

  if (!visible) {
    return null;
  }

  return (
    <Notification
      onClose={() => {
        setVisible(false);
      }}
    >
      <Typography variant="body1">
        Укажите, подключение к какому оператору ЭДО необходимо использовать для
        передаточных документов в ГИС МТ.
      </Typography>
      <Typography variant="body1">
        <b>
          Внимание! Закрывающие документы от Оператора ЦРПТ будут всегда
          приходить в ЭДО&nbsp;lite.
        </b>
      </Typography>
    </Notification>
  );
};
