import { takeEvery, put, call } from 'redux-saga/effects';

import { displayDataLimit } from '../../../constants/requests';
import { Api } from '../../../common_components/Api/Api';
import {
  filters2url,
  filters2params,
  spreadMultiValueOfFilter,
  updateUrlParams,
} from '../../../utils/url-utils';

import {
  addedOiv,
  onUpdatePage,
  fetchAddOiv,
  fetchData,
  onDataLoaded,
} from './RegistryOIV.actions';

function* addOivSaga(action) {
  const { payload = {} } = action;

  const requestPayload = {
    url: '/api/v3/org/registration/oiv',
    method: 'post',
    params: payload,
  };
  const [success] = yield call(Api.request, requestPayload);

  if (success) {
    yield put(
      addedOiv({
        inn: payload.oivInn,
        fullName: payload.oivName,
        pid: success.data,
        status: 'REGISTERED',
      })
    );
  }
}

function* requestSaga(action) {
  const { payload = {} } = action;

  const {
    page = 1,
    filters,
    history,
    updateHistory = false,
    pathname = '',
  } = payload;

  const flatFilters = spreadMultiValueOfFilter(filters, [
    'multiNameInn',
    'multiParticipantIdInn',
  ]);
  const filterParams = filters2params(flatFilters);

  const requestPayload = {
    url: '/api/v3/org/organisation/search-oiv',
    method: 'get',
    params: {
      ...filterParams,
      limit: displayDataLimit,
      page: page - 1,
    },
  };

  const [success] = yield call(Api.request, requestPayload, {
    preloading: false,
  });
  if (success) {
    if (updateHistory && history) {
      const filtersUrl = filters2url(filters);
      const urlString =
        pathname + '?' + (filtersUrl ? filtersUrl + '&' : '') + `page=${page}`;
      yield call(updateUrlParams, { urlString });
    }
    if (page) {
      yield put(onUpdatePage(page));
    }
    yield put(onDataLoaded(success.data));
  } else {
    yield put(onDataLoaded());
  }
}

export const saga = function* watch() {
  yield takeEvery(fetchData, requestSaga);
  yield takeEvery(fetchAddOiv, addOivSaga);
};
