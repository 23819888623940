export const documentTypes: Record<string, number> = {
  unknown: 0,
  nf: -1,
  nfSD2: -2,
  nfSD3: -3,
  nfSD4: -4,
  nfSD5: -5,
  nfSD6: -6,
  nfSD7: -7,
  nfSD8: -8,
  nfSD9: -9,
  nfSD10: -10,
  nfSD11: -11,
  nfSD12: -12,
  nfSD13: -13,
  nfSD14: -14,
  nfSD15: -15,
  nfSD16: -16,
  nfSD17: -17,
  nfSD18: -18,
  nfSD19: -19,
  nfSD20: -20,
  nfSD21: -21,
  nfSD22: -22,
  nfSD23: -23,
  nfSD24: -24,
  nfSD25: -25,
  nfSD26: -26,
  nfSD27: -27,
  nfSD28: -28,
  nfSD29: -29,
  nfSD31: -31,
  nfSD32: -32,
  nfSD33: -33,
  nfSD34: -34,
  nfSD35: -35,
  nfSD36: -36,
  nfSD100: -100,
  nfSD101: -101,
  nfBill: -10000,
  updDop: 100,
  updSf: 102,
  updSfDop: 104,
  ukdDis: 200,
  ukdSf: 202,
  ukdSfDis: 204,
  updDopI: 300,
  updSfI: 302,
  updSfDopI: 304,
  ukdDisI: 400,
  ukdSfI: 402,
  ukdSfDisI: 404,
  upd820Dop: 500,
  upd820Sf: 502,
  upd820SfDop: 504,
  upd820DopI: 800,
  upd820SfI: 802,
  upd820SfDopI: 804,
};
