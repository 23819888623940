import { createAction, createActions } from 'redux-actions';

export const subject = '[CADES]';

export const signByteArray = createAction(
  `${subject} sign byteArray`,
  (payload) => payload,
  (_, meta) => meta
);

export const signedByteArray = createAction(`${subject} signed byteArray`);
export const error = createAction(`${subject} sign error`);

export const {
  setCurrentCertificate,
  setCertificates,
  setPreparedCertificates,
  startSetCurrentCertificate,
  startSetCertificates,
  clearCertificates,
} = createActions(
  {
    SET_CURRENT_CERTIFICATE: (payload) => payload,
    SET_CERTIFICATES: (payload) => payload,
    SET_PREPARED_CERTIFICATES: (payload) => payload,

    START_SET_CURRENT_CERTIFICATE: () => {},
    START_SET_CERTIFICATES: () => {},

    CLEAR_CERTIFICATES: () => {},
  },
  {
    prefix: 'CERTIFICATE',
  }
);
