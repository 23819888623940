import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import Autocomplete from '../Form/Fields/Autocomplete';
import { debounce } from 'lodash';
import bff, { OrganisationBriefDTO } from '@crpt/shared/services/bff';
import toast from '@crpt/shared/toast';
import { FieldProps } from 'react-final-form';
import { AutocompleteInputChangeReason } from '@mui/material';

export function ParticipantAutocompleteFormField(
  props: FieldProps<string, HTMLInputElement>
) {
  const [loading, setLoading] = useState<boolean>(false);
  const [organisationList, setOrganisationList] = useState<
    OrganisationBriefDTO[]
  >([]);

  const fetchOrganisationList = useCallback(
    debounce((search: string) => {
      setLoading(true);
      setOrganisationList([]);
      bff
        .searchOrganisationBrief({ name: search, limit: 20 })
        .then(({ data }) => {
          setOrganisationList(data);
        })
        .catch((error) => {
          toast.error(error.message);
          setOrganisationList([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 350),
    []
  );

  const options = useMemo(() => {
    return organisationList
      .map((organisation) => ({
        label: organisation.name || organisation.inn,
        value: organisation.inn,
      }))
      .filter(({ value }) => Boolean(value));
  }, [organisationList]);

  const onInputChange = useCallback(
    (
      event: ChangeEvent<Record<string, unknown>>,
      value: string,
      reason: AutocompleteInputChangeReason
    ) => {
      if (['input', 'clear'].includes(reason)) {
        const normalizedValue = (value || '').trim();
        if (normalizedValue.length > 2) {
          fetchOrganisationList(normalizedValue);
        } else {
          setOrganisationList([]);
        }
      }
    },
    [fetchOrganisationList]
  );

  return (
    <Autocomplete
      {...props}
      loading={loading}
      onInputChange={onInputChange}
      options={options}
    />
  );
}
