import React, { FC } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps as MuiAccordionProps,
} from '@material-ui/core';
import { Icon, Typography } from '@crpt-ui/core';

import {
  useDetailsClasses,
  useSummaryClasses,
  useRootClasses,
} from './Accordion.styles';

export interface AccordionProps extends MuiAccordionProps {
  header: string;
}

export const Accordion: FC<AccordionProps> = ({
  header,
  id,
  children,
  ...rest
}) => {
  return (
    <MuiAccordion
      classes={useRootClasses()}
      elevation={0}
      variant="elevation"
      {...rest}
    >
      <MuiAccordionSummary
        aria-controls={`${id}-content`}
        aria-label="Expand"
        classes={useSummaryClasses()}
        expandIcon={<Icon name="Chevron" />}
        id={`${id}-header`}
      >
        <Typography variant="button">{header}</Typography>
      </MuiAccordionSummary>
      <MuiAccordionDetails classes={useDetailsClasses()}>
        {children}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
