import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import { withForm } from '../../../../common_components/hocs/withForm';
import { getCurrentCertificateValidTo } from '../../../../common_components/Cades/Cades.selectors';

import { schema } from './ManuallyAgreementDocument.schema';
import { ManuallyAgreementDocumentView } from './ManuallyAgreementDocument.view';
import {
  clearManualModeModal,
  closeManualModeModal,
  fetchParticipantsAutocomplete,
  sendParticipants,
} from './ducks/ManuallyAgreementDocument.actions';
import {
  getLoading,
  getLoadingAutocomplete,
  getManuallyAutocompleteList,
  getOpenManualModeModal,
} from './ducks/ManuallyAgreementDocument.selectors';
import { useManuallyAgreementDocumentStyles } from './ManuallyAgreementDocument.styled';

const ManuallyAgreementDocument = ({
  form,
  closeManualModeModal,
  fetchParticipantsAutocomplete,
  sendParticipants,
  clearManualModeModal,
  autocompleteList,
  certificateValidTo,
  values,
  hasValidationErrors,
  isOpen,
  isLoadingAutocomplete,
  isLoading,
}) => {
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState('');

  const classes = useManuallyAgreementDocumentStyles();

  useEffect(() => {
    if (certificateValidTo) {
      const date = moment(certificateValidTo);

      form.change('expiration_date', date);
    }
  }, [certificateValidTo, form.change, isOpen]);

  useEffect(() => {
    if (values.everyonePermitted) {
      setSelectedOrganizations([]);
    }
  }, [values.everyonePermitted]);

  const onCloseModal = useCallback(() => {
    closeManualModeModal();
    clearManualModeModal();
    setSelectedOrganizations([]);
    form.reset();
  }, [clearManualModeModal, closeManualModeModal, form]);

  const onInputChange = useCallback(
    (_, value) => {
      setAutocompleteInputValue(value);

      if (value !== '') {
        fetchParticipantsAutocomplete(value);
      }
    },
    [fetchParticipantsAutocomplete]
  );

  const onSetSelectedOrganizations = useCallback(
    (_, value) => {
      if (!value.inn) {
        return false;
      }

      const isDuplicate = selectedOrganizations.find(
        (item) => item.inn === value.inn
      );

      if (!isDuplicate) {
        const tempValue = selectedOrganizations.slice();

        tempValue.push(value);
        setSelectedOrganizations(tempValue);
        clearManualModeModal();
      }

      setAutocompleteValue({});
    },
    [clearManualModeModal, selectedOrganizations]
  );

  const onSubmit = useCallback(() => {
    sendParticipants({
      values: { selectedOrganizations, ...values },
      onSuccess: () => setSelectedOrganizations([]),
    });
  }, [selectedOrganizations, sendParticipants, values]);

  const onRemoveAutocompleteValue = useCallback(
    (row) => {
      const tempValue = selectedOrganizations.slice();

      tempValue.splice(row.index, 1);
      setSelectedOrganizations(tempValue);
    },
    [selectedOrganizations]
  );

  const datatableActions = useMemo(
    () => [
      {
        label: 'Удалить',
        callback: onRemoveAutocompleteValue,
      },
    ],
    [onRemoveAutocompleteValue]
  );

  const buttonLabel = useMemo(() => {
    const orgLength = selectedOrganizations.length;
    const subject = `контрагент${orgLength === 1 ? 'ом' : 'ами'}`;

    return `Создать ${orgLength > 0 ? `с ${orgLength} ${subject}` : ''}`;
  }, [selectedOrganizations]);

  /**
   * Returns true if "values.everyonePermitted" is false and organization is not selected
   * @type {boolean}
   */
  const isOrganisationValidForChoosing = useMemo(
    () => !values.everyonePermitted && selectedOrganizations.length === 0,
    [selectedOrganizations.length, values.everyonePermitted]
  );

  const modalActions = useMemo(
    () => [
      {
        label: buttonLabel,
        callback: onSubmit,
        disabled: isOrganisationValidForChoosing || isLoading,
        loading: isLoading,
      },
      {
        label: 'Отменить',
        callback: onCloseModal,
        variant: 'outlined',
      },
    ],
    [
      buttonLabel,
      onSubmit,
      isOrganisationValidForChoosing,
      isLoading,
      onCloseModal,
    ]
  );

  const datePickerProps = useMemo(() => {
    return {
      minDate: new Date(),
      maxDate: new Date(certificateValidTo),
    };
  }, [certificateValidTo]);

  const autocompleteProps = useMemo(
    () => ({
      disabled: values.everyonePermitted,
      required: isOrganisationValidForChoosing,
      error:
        autocompleteInputValue.length === 0 && isOrganisationValidForChoosing,
    }),
    [
      values.everyonePermitted,
      isOrganisationValidForChoosing,
      autocompleteInputValue.length,
    ]
  );

  const formErrors = useMemo(() => {
    return isOrganisationValidForChoosing || hasValidationErrors;
  }, [hasValidationErrors, isOrganisationValidForChoosing]);

  return React.createElement(ManuallyAgreementDocumentView, {
    onCloseModal,
    onInputChange,
    onSetSelectedOrganizations,
    autocompleteList,
    datePickerProps,
    autocompleteValue,
    selectedOrganizations,
    datatableActions,
    modalActions,
    autocompleteProps,
    hasValidationErrors: formErrors,
    isOpen,
    isLoadingAutocomplete,
    classes,
  });
};

ManuallyAgreementDocument.propTypes = {
  closeManualModeModal: PropTypes.func.isRequired,
  fetchParticipantsAutocomplete: PropTypes.func.isRequired,
  sendParticipants: PropTypes.func.isRequired,
  clearManualModeModal: PropTypes.func.isRequired,
  autocompleteList: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isLoadingAutocomplete: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  autocompleteList: getManuallyAutocompleteList(state),
  certificateValidTo: getCurrentCertificateValidTo(state),
  isOpen: getOpenManualModeModal(state),
  isLoadingAutocomplete: getLoadingAutocomplete(state),
  isLoading: getLoading(state),
});

const mapDispatchToProps = {
  closeManualModeModal,
  fetchParticipantsAutocomplete,
  sendParticipants,
  clearManualModeModal,
};

export const ManuallyAgreementDocumentConnected = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withForm({ schema })
)(ManuallyAgreementDocument);
