import { createSelector } from 'reselect';

export const UploadNotificationOperatorDialogState = (state) =>
  state.Documents.NotificationOperatorDocumentsList
    .UploadNotificationOperatorDialog;

export const getOpen = createSelector(
  [UploadNotificationOperatorDialogState],
  (state) => state.open
);

export const getType = createSelector(
  [UploadNotificationOperatorDialogState],
  (state) => state.type
);

export const getSubmitting = createSelector(
  [UploadNotificationOperatorDialogState],
  (state) => state.submitting
);
