import { handleActions } from 'redux-actions';

import {
  closeCreatePaymentModal,
  openCreatePaymentModal,
  setLoading,
  setSelectedAccount,
} from './CreatePaymentDocument.actions';

const open = handleActions(
  {
    [openCreatePaymentModal]: () => true,
    [closeCreatePaymentModal]: () => false,
  },
  false
);

const loading = handleActions(
  {
    [setLoading]: (_, { payload }) => payload,
  },
  false
);

const selectedAccount = handleActions(
  {
    [setSelectedAccount]: (_, { payload }) => payload,
    [closeCreatePaymentModal]: () => '',
  },
  ''
);

export const reducer = {
  open,
  selectedAccount,
  loading,
};
