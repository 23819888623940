import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 0 0 200px;
`;

export const ErrorCode = styled.div`
  font-size: 160px;
  margin: -100px 0 0;
  font-weight: 600;
  line-height: 1;
`;

export const ErrorMessage = styled.div`
  font-size: 40px;
  line-height: ${50 / 40};
`;

export const ErrorDescription = styled.div`
  line-height: 1.5;
  font-weight: 600;
`;
