import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    alignItems: 'flex-start',
    display: 'flex',
    fontWeight: 600,
    lineHeight: '24px',
    pointerEvents: 'none',
  },
  text: {
    marginLeft: '8px',
  },
});
