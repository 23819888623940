import { createReducer } from '@reduxjs/toolkit';

import { participantStatusTitle } from '../ParticipantDetails.constants';
import { uotStatuses } from '../utils';

import * as actions from './ParticipantDetails.actions';

const results = createReducer(
  {},
  {
    [actions.mounted.type]: () => ({}),
    [actions.loaded.type]: (_state, { payload = {} }) => payload,
    [actions.setUpdateUot.type]: (_state, { payload = {} }) => payload,
    [actions.setChangeStatusProductGroup.type]: (state, { payload = {} }) => {
      const { code, status } = payload;
      return {
        ...state,
        productGroupsAndRoles: state.productGroupsAndRoles.map((el) =>
          el.code === code
            ? { ...el, status, statusName: uotStatuses[status] || '—' }
            : el
        ), //
      };
    },
    [actions.setChangeStatusUOT.type]: (state, { payload = {} }) => {
      return {
        ...state,
        status: payload,
        statusName: participantStatusTitle[payload],
      };
    },
    [actions.unmounted.type]: () => ({}),
  }
);

const loading = createReducer(
  {},
  {
    [actions.mounted.type]: () => true,
    [actions.loaded.type]: () => false,
    [actions.loadingEnd.type]: () => false,
  }
);

const updated = createReducer(
  {},
  {
    [actions.setUpdated.type]: (_state, { payload = {} }) => payload,
  }
);

export const reducer = {
  results,
  updated,
  loading,
};
