import ApiService from '../ApiService';
import { ProductDTO, ProductStatusEnum } from './types';
import { AxiosRequestConfig } from '../../axios';
import { ProductListResponse } from './bff';

export interface FetchProductListParams {
  gtin: string[];
  status?: ProductStatusEnum[];
  inn?: string[];
  includeSubaccount?: boolean;
  include?: (keyof ProductDTO)[];
}

class BffV2 extends ApiService {
  fetchProductList(
    { gtin, status, inn, includeSubaccount, include }: FetchProductListParams,
    config?: Omit<AxiosRequestConfig, 'params'>
  ) {
    return this.post<ProductListResponse>(
      '/products/list',
      { gtin, status, inn, includeSubaccount },
      { params: { include }, ...config }
    );
  }
}

export default new BffV2('/bff-elk/v2');
