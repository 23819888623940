import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import * as Icons from '@crpt-ui/icons';
import { productGroupService } from '@crpt/shared/services';

import { productGroupIdIconsMap } from '../Accounts.constants';

import { useProductGroupCellStyles } from './ProductGroupCell.styled';

export const ProductGroupCell = ({ cell }) => {
  const classes = useProductGroupCellStyles();

  const Label = useMemo(() => productGroupService.getName(cell.value), [cell]);

  const Icon = useMemo(
    () => productGroupIdIconsMap[cell.value] || <Icons.DatamatrixSimplified />,
    [cell]
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.icon}>{Icon}</div>
      <div className={classes.label}>{Label}</div>
    </div>
  );
};

ProductGroupCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.number,
  }),
};
