import React from 'react';

import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

export const SignatureDescriptionLoader = ({ lineWidth }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Skeleton height={25} variant="text" />
    </Grid>

    <Grid item xs={lineWidth}>
      <Skeleton height={20} variant="text" />
      <Skeleton height={20} variant="text" />
    </Grid>
    <Grid item xs={lineWidth}>
      <Skeleton height={20} variant="text" />
      <Skeleton height={20} variant="text" />
    </Grid>
  </Grid>
);
