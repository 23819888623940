import { createSelector } from 'reselect';

export const getSupportState = (state) => state.Support.SupportList;

export const getSupportList = createSelector(
  [getSupportState],
  (supportBase) => supportBase.data
);

export const getSupportListTotalElements = createSelector(
  [getSupportState],
  (supportBase) => supportBase.total
);

export const getSupportListTotalPages = createSelector(
  [getSupportListTotalElements],
  (totalElements) => Math.ceil(totalElements / 10)
);

export const getSupportListParams = createSelector(
  [getSupportState],
  (supportBase) => supportBase.params
);

export const getSupportListPage = createSelector(
  [getSupportState],
  (supportBase) => supportBase.page
);
