import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  wrapper: {
    fontSize: 14,
  },
  bold: {
    fontWeight: 600,
  },
});
