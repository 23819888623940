import React, { useEffect, useState } from 'react';

import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TextField, CommonDialog } from '@ibox/ui';

import { isValidInn } from '../../common_components/Form/utils/validations';
import { addedOIV } from '../RegistryOIV/ducks/RegistryOIV.selectors';
import {
  clearAddedOiv,
  fetchAddOiv,
} from '../RegistryOIV/ducks/RegistryOIV.actions';

import css from './add-registry-oiv.module.scss';

type AddRegistryOIVComponentProps = {
  onClose: () => void;
  fetchAddOiv: (obj: Record<string, string>) => void;
  addedOIV: boolean;
  clearAddedOiv: () => void;
  open: boolean;
};

const AddRegistryOIVComponent: React.FC<AddRegistryOIVComponentProps> = ({
  onClose,
  fetchAddOiv,
  addedOIV,
  clearAddedOiv,
  open,
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [nameErr, setNameErr] = useState(true);
  const [inn, setInn] = useState('');
  const [innErr, setInnErr] = useState(true);

  useEffect(() => {
    if (addedOIV) {
      onClose();
      clearAddedOiv();
    }
  }, [addedOIV]);

  const handleChangeInn = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 8) {
      setInn(e.target.value);
    } else {
      return;
    }
    const validated = t(isValidInn(e.target.value));
    if (validated) {
      validated === t('Допустимы только цифры')
        ? setInnErr(validated)
        : setInnErr(true);
    } else {
      setInnErr(false);
    }
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameErr(false);
    setName(e.target.value);
  };

  const handleAdd = () => {
    if (name === '') {
      setNameErr(true);
    }
    if (!nameErr && !innErr) {
      fetchAddOiv({
        oivInn: inn,
        oivName: name,
      });
    }
  };

  const addDisabled = name === '' || inn.length < 8;

  return (
    <CommonDialog
      invalid={addDisabled}
      labelCancel={t('Отменить')}
      labelSubmit={t('Добавить')}
      open={open}
      size="sm"
      title={t('Добавить ОИВ')}
      onClose={onClose}
      onSubmit={handleAdd}
    >
      <div className={css.modalContainer}>
        <TextField
          multiline
          className={css.textArea}
          error={nameErr}
          label={t('Наименование')}
          value={name}
          onChange={handleChangeName}
        />
        <TextField
          className={css.input}
          error={innErr}
          helperText={innErr ? '' : innErr}
          label={t('ИНН')}
          value={inn}
          onChange={handleChangeInn}
        />
      </div>
    </CommonDialog>
  );
};

const mapState = (state) => ({
  addedOIV: addedOIV(state),
});

export const AddRegistryOIV = compose(
  withRouter,
  connect(mapState, {
    clearAddedOiv,
    fetchAddOiv,
  })
)(AddRegistryOIVComponent);
