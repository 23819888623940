import { call, put, select, takeEvery } from 'redux-saga/effects';
import qs from 'query-string';
import moment from 'moment';

import { Api } from '../../../../common_components/Api/Api';

import {
  clearFilter,
  fetchNotificationOperatorDocuments,
  setFilter,
  setLoading,
  setNotificationOperatorDocuments,
  setPage,
  setSort,
} from './NotificationOperatorDocumentsList.actions';
import {
  getFilter,
  getOffset,
  getSort,
} from './NotificationOperatorDocumentsList.selectors';

function* setSortSaga(sortingColumn) {
  const lastSorting = yield select(getSort);

  if (lastSorting.sortBy === sortingColumn) {
    yield put(setSort({ asc: !lastSorting.asc }));
  } else {
    yield put(setSort({ asc: true, sortBy: sortingColumn }));
  }
}

function* setFilterSaga(filtersData) {
  let filters = {};

  for (let i = 0; i < filtersData.length; i += 1) {
    const filter = filtersData[i];

    if (filter.id === 'created_at') {
      filters = {
        ...filters,
        created_from: Math.floor(
          moment(filter.value.startDate).valueOf() / 1000
        ),
        created_to: Math.floor(moment(filter.value.endDate).valueOf() / 1000),
      };
    }

    if (filter.id === 'type') {
      filters = {
        ...filters,
        type: filter.value,
      };
    }
  }

  yield put(setFilter(filters));
}

function* fetchNotificationOperatorDocumentsSaga({
  payload: { page, sort, filters, loading } = {
    page: null,
    sort: null,
    filters: null,
    loading: true,
  },
}) {
  let offset;
  let sorting;
  let filter;

  yield put(setLoading(loading));

  if (page) {
    yield put(setPage(page));
  }

  if (sort) {
    yield call(setSortSaga, sort);
  }

  if (filters) {
    yield call(setFilterSaga, filters);
  }

  offset = yield select(getOffset);
  sorting = yield select(getSort);
  filter = yield select(getFilter);

  const requestPayload = {
    url: '/edo-api/outgoing-documents',
    params: {
      type: [-33, -34, -35, -36],
      folder: 0,
      offset,
      ...filter,
      ...sorting,
    },
    paramsSerializer: (params) => qs.stringify(params),
  };

  const [success] = yield call(Api.request, requestPayload, {
    preloading: false,
  });

  if (success) {
    yield put(setNotificationOperatorDocuments(success.data));
  }

  yield put(setLoading(false));
}

export const saga = function* watch() {
  yield takeEvery(
    [fetchNotificationOperatorDocuments, clearFilter],
    fetchNotificationOperatorDocumentsSaga
  );
};
