import React from 'react';

import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { Link } from '@crpt-ui/core';

import { Preloader } from '../../../../common_components/Preloader/Preloader';
import { Modal } from '../../../../common_components/Modal/Modal';
import { translate } from '../../../Translate/Translate';

export const UploadAgreementDocumentView = ({
  onCloseModal,
  onFileDialogOpen,
  getInputProps,
  getRootProps,
  errors,
  isOpen,
  isLoading,
  classes,
}) => (
  <Modal
    maxWidth="sm"
    open={isOpen}
    title={translate('Загрузка xls списка контрагентов')}
    onClose={onCloseModal}
  >
    {isLoading ? (
      <div className={classes.preloader}>
        <Preloader
          isOpen
          text={translate('Загрузка файла. Пожалуйста, подождите')}
        />
      </div>
    ) : (
      <div className={classes.upload} {...getRootProps()}>
        <input {...getInputProps()} />
        <Typography className={classes.error}>{errors}</Typography>
        Перетащите или <Link onClick={onFileDialogOpen}>
          выберите файл
        </Link>{' '}
        формата *.xls
      </div>
    )}
  </Modal>
);

UploadAgreementDocumentView.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onFileDialogOpen: PropTypes.func.isRequired,
  getInputProps: PropTypes.func.isRequired,
  getRootProps: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  errors: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
