import moment from 'moment';

const getNameFromSubject = (subject) => {
  const surname = subject.SN || '';
  const namePatronymic = subject.G || '';
  const signed = subject.CN || '';

  const name = [surname, namePatronymic].join(' ').trim();
  return (name || signed)
    .replace(/""/g, '~~~')
    .replace(/"/g, '')
    .replace(/~~~/g, '"');
};

export const prepareCertificates = (certs = []) =>
  certs.map((certificate) => {
    const subject = certificate?.subject || {};
    const issuer = certificate?.issuer || {};

    const inn = subject.INN;
    const ogrn = subject.OGRN;
    const serialNumber = subject.SERIALNUMBER;
    const kpp = subject.KPP;
    const namePatronymic = subject.G;
    const surname = subject.SN;
    const company = subject.O;
    const signed = subject.CN;
    const position = subject.T;
    const email = subject.E;

    let name = '',
      patronymic;
    const matched = namePatronymic && namePatronymic.match(/^(\S+)(\s(.*))?$/);
    if (matched) {
      name = matched[1];
      patronymic = matched[3];
    }

    let shortname;
    if (name && surname) {
      shortname = `${surname} ${name.charAt(0).toUpperCase()}.`;
      if (patronymic) {
        shortname += ` ${patronymic.charAt(0).toUpperCase()}.`;
      }
    }

    const isOrganisation =
      !!inn && (inn.indexOf('00') === 0 || inn.length === 10);
    const companyName =
      (company &&
        company.replace(/""/g, '~~~').replace(/"/g, '').replace(/~~~/g, '"')) ||
      undefined;

    return {
      certificate,
      valid: certificate.valid,
      serial: certificate.serialNumber,
      thumbprint: certificate.thumbprint,
      email,
      ogrn,
      serialNumber,
      inn,
      kpp,
      company: companyName,
      position,
      organisation: isOrganisation,
      fullName: [surname, namePatronymic].join(' ').trim() || signed,
      issuer: getNameFromSubject(issuer),
      name,
      patronymic,
      surname,
      shortname,
      validFrom: certificate.validFromDate,
      validTo: certificate.validToDate,
      validDate: `${moment(certificate.validFromDate).format(
        'DD.MM.YYYY'
      )} - ${moment(certificate.validToDate).format('DD.MM.YYYY')}`,
    };
  });

export const prepareCertificateDate = (fromTimeStamp, toTimeStamp) => {
  const from = moment(fromTimeStamp);
  const to = moment(toTimeStamp);

  return `с ${from.format('DD.MM.YYYY')} по ${to.format('DD.MM.YYYY')}`;
};
