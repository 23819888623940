import { makeStyles, Theme } from '@material-ui/core';

export const useRootClasses = makeStyles(() => ({
  root: {
    '&$expanded': {
      margin: 0,
    },
    '&::before': {
      display: 'none',
    },
  },
  expanded: {},
}));

export const useSummaryClasses = makeStyles((theme: Theme) => ({
  root: {
    flexDirection: 'row-reverse',
    padding: 0,
    width: 'fit-content',
    color: theme.palette.secondary.main,
    '& .MuiAccordionSummary-content': {
      margin: '8px 0',
    },
    '& .MuiIconButton-edgeEnd': {
      margin: 0,
      color: theme.palette.secondary.main,
    },
    '&$expanded': {
      minHeight: '48px',
      '& .MuiAccordionSummary-content': {
        margin: '8px 0',
      },
    },
  },
  expanded: {},
}));

export const useDetailsClasses = makeStyles(() => ({
  root: {
    padding: '8px',
  },
}));
