import Cookies from 'js-cookie';

import { toast } from '@crpt/shared/toast';

import { b64DecodeUnicode } from './localStorageHelpers';

export function saveToken(token) {
  const data = token.split('.')[1];
  const decoded = b64DecodeUnicode(data);
  const exp = JSON.parse(decoded).exp;
  const expires = new Date(exp * 1000);
  const domain = getCurrentDomain();
  const path = '/';

  const options = {
    expires,
    domain,
    path,
  };

  if (expires && new Date(expires) < new Date()) {
    toast.error('Срок действия токена истек');
    deleteAllCookies();
  } else {
    const slices = getTokenSlices(token);
    const count = slices.length;
    Cookies.set('count', count, options);

    slices.forEach((tokenPart, i) => {
      Cookies.set(`tokenPart${i + 1}`, tokenPart, options);
    });
  }
}

export function getTokenSlices(token) {
  let i,
    j,
    splitted = token.split(''),
    results = [],
    chunk = 4000;
  for (i = 0, j = splitted.length; i < j; i += chunk) {
    results.push(splitted.slice(i, i + chunk));
  }
  return results.map((v) => v.join(''));
}

export function saveCertSerial(value) {
  deleteCookie('certSerial', getCurrentDomain());
  Cookies.set('certSerial', value, {
    domain: getCurrentDomain(),
  });
}

export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value, options) {
  options = options || {};

  var expires = options.expires;

  if (typeof expires === 'number' && expires) {
    var d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    expires = options.expires = d;
  }
  if (expires && expires.toUTCString) {
    options.expires = expires.toUTCString();
  }

  value = encodeURIComponent(value);

  var updatedCookie = name + '=' + value;

  for (var propName in options) {
    updatedCookie += '; ' + propName;
    var propValue = options[propName];
    if (propValue !== true) {
      updatedCookie += '=' + propValue;
    }
  }

  document.cookie = updatedCookie;
}

export function setLanguage(lng) {
  setCookie('language', lng.toLowerCase(), {
    domain: getCurrentDomain(),
    path: '/',
  });
}

function deleteCookie(name, domain) {
  document.cookie =
    name + `=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${domain}`;
}

export function getCurrentDomain() {
  return `${window.location.hostname.split('.').slice(-2).join('.')}`;
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

    document.cookie =
      name +
      `=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${getCurrentDomain()}`;
  }
  const nidKeys = window.localStorage.getItem('nidKeys');
  localStorage.clear();
  if (nidKeys) {
    window.localStorage.setItem('nidKeys', nidKeys);
  }
}
