import React, { FC } from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Preloader as PreloaderCore } from '@crpt/shared/components';

import { loading } from './ducks/Preloader.selectors';

type PreloaderProps = {
  isOpen: boolean;
  text?: string;
};

export const Preloader: FC<PreloaderProps> = ({ isOpen, text, ...rest }) => {
  const { t } = useTranslation();
  return (
    <PreloaderCore
      isOpen={isOpen}
      text={typeof text === 'string' ? t(text) : text}
      {...rest}
    />
  );
};

Preloader.defaultProps = {
  isOpen: false,
  text: 'Загрузка',
};

export const Loader = connect((state) => ({
  isOpen: loading(state),
}))(Preloader);
