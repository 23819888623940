import React, { useCallback, useState } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import { UploadAgreementDocumentView } from './UploadAgreementDocument.view';
import { useUploadAgreementDocumentStyles } from './UploadAgreementDocument.styled';
import {
  closeUploadModeModal,
  sendUploadFile,
} from './ducks/UploadAgreementDocument.actions';
import {
  getLoading,
  getOpenUploadModeModal,
} from './ducks/UploadAgreementDocument.selectors';

const UploadAgreementDocument = ({
  closeUploadModeModal,
  sendUploadFile,
  isOpen,
  isLoading,
}) => {
  const classes = useUploadAgreementDocumentStyles();

  const [errors, setErrors] = useState('');

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) => {
      setErrors('');

      if (acceptedFiles.length > 0) {
        sendUploadFile(acceptedFiles);
      }

      if (rejectedFiles.length > 0) {
        setErrors(
          `Расширение ${rejectedFiles[0].type} не поддерживается. Попробуйте снова.`
        );
      }
    },
    noClick: true,
    accept:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx',
  });

  const onCloseModal = useCallback(() => {
    closeUploadModeModal();
    setErrors('');
  }, [closeUploadModeModal]);

  return React.createElement(UploadAgreementDocumentView, {
    onCloseModal,
    onFileDialogOpen: open,
    getInputProps,
    getRootProps,
    errors,
    isOpen,
    isLoading,
    classes,
  });
};

UploadAgreementDocument.propTypes = {
  closeUploadModeModal: PropTypes.func.isRequired,
  sendUploadFile: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isOpen: getOpenUploadModeModal(state),
  isLoading: getLoading(state),
});

const mapDispatchToProps = {
  closeUploadModeModal,
  sendUploadFile,
};

export const UploadAgreementDocumentConnected = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(UploadAgreementDocument);
