import React, { useCallback, useEffect, useMemo } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { getOr } from 'lodash/fp';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';

import {
  Button,
  Icon,
  TextFieldFinalFormWrapper,
  AutocompleteField,
} from '@ibox/ui';

import { withFormContext } from '../../../../common_components/Form/Form.utils';
import { req as required } from '../../../../common_components/Form/Form.validations';
import * as selectors from '../ducks/Company.selectors';
import { Select } from '../../../../common_components/Form/Select';
import { Input } from '../../../../common_components/Form/Input';
import { Data, ProductGroupsAndRolesItem } from '../ducks/Company.store';

const name = 'productGroupsAndRoles';

type GroupOrRoleItem = {
  label: React.ReactNode;
  value: string;
};

type Roles = {
  formMutators: Record<string, unknown>;
  value: ProductGroupsAndRolesItem[];
  roles: GroupOrRoleItem[];
  groups: GroupOrRoleItem[];
  data: Data;
  disabled: boolean;
};

const Roles: React.FC<Roles> = ({
  formMutators,
  value = [],
  data: initialValues,
  roles = [],
  groups = [],
  disabled,
}) => {
  const { t } = useTranslation();
  const addProductGroup = useCallback(() => formMutators.push(name, {}), []);

  const removeProductGroup = useCallback(
    (index) => formMutators.remove(name, index),
    [formMutators]
  );

  const rolesOptions = useMemo(() => {
    return roles.map(({ code, name }) => ({
      value: code,
      label: t(name),
    }));
  }, [roles, t]);

  const selectedProductGroups = useMemo(() => {
    return value.map((item) => item.code);
  }, [value]);

  const isProductGroupDisabled = useCallback(
    (value) => {
      return selectedProductGroups.find((item) => item === value);
    },
    [selectedProductGroups]
  );

  const productGroups = useMemo(() => {
    return groups.map((productGroup) => ({
      value: productGroup.code,
      label: t(productGroup.name),
      disabled: isProductGroupDisabled(productGroup.value),
    }));
  }, [groups, isProductGroupDisabled, t]);

  const isProductGroupAndRoleDisabled = useCallback(
    (productGroupAndRole) => {
      if (disabled) {
        return true;
      }

      if (!productGroupAndRole.code) {
        return false;
      }

      return getOr([])('productGroupsAndRoles')(initialValues)
        .map((i) => i.code)
        .includes(productGroupAndRole.code);
    },
    [disabled, initialValues]
  );

  const canDeleteProductGroup = useCallback(
    (productGroupAndRole) =>
      !isProductGroupAndRoleDisabled(productGroupAndRole),
    [isProductGroupAndRoleDisabled]
  );

  const canCreateProductGroupAndRole = useMemo(() => {
    if (disabled) {
      return false;
    }

    return value.length < groups.length;
  }, [groups, disabled, value]);

  const { values } = useFormState();

  useEffect(() => {
    const productGroupsAndRoles = values.productGroupsAndRoles.map(
      (group: { types: string[] | { value: string; label: string }[] }) => {
        return {
          ...group,
          types: group.types.reduce((acc, item) => {
            const option = rolesOptions.find(({ value }) =>
              typeof item === 'string' ? value === item : value === item.value
            );
            if (option) {
              acc.push(option);
            } else if (typeof item === 'string') {
              formMutators.updateField('hiddenRoles', [
                ...(values?.hiddenRoles ? values.hiddenRoles : []),
                item,
              ]);
            }
            return acc;
          }, []),
        };
      }
    );
    formMutators.updateField('productGroupsAndRoles', productGroupsAndRoles);
  }, [rolesOptions]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h6">{t('Роль участника')}</Typography>
      </Grid>

      {value.map((productGroupAndRole, index) => {
        return (
          <React.Fragment key={`${name}.${index}.code`}>
            <Grid container item spacing={2}>
              <Grid container item xs={6}>
                <Grid
                  item
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <Select
                    disabled={isProductGroupAndRoleDisabled(
                      productGroupAndRole
                    )}
                    label={t('Товарная группа')}
                    name={`${name}.${index}.code`}
                    options={productGroups}
                    validate={
                      isProductGroupAndRoleDisabled(productGroupAndRole)
                        ? undefined
                        : required
                    }
                  />
                </Grid>
              </Grid>
              <Grid container item xs={6}>
                <Grid
                  item
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <Field
                    fullWidth
                    multiple
                    component={AutocompleteField}
                    disabled={disabled}
                    label={t('Тип участника')}
                    name={`${name}.${index}.types`}
                    options={rolesOptions}
                    required={!disabled}
                    validate={required}
                  />
                </Grid>

                {canDeleteProductGroup(productGroupAndRole) ? (
                  <Grid
                    item
                    style={{
                      marginLeft: '20px',
                    }}
                  >
                    <Button
                      color="error"
                      icon={<Icon name="Trash" />}
                      variant="outlined"
                      onClick={() => removeProductGroup(index)}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid container item xs={3}>
                <Field
                  fullWidth
                  component={TextFieldFinalFormWrapper}
                  disabled={disabled}
                  inputProps={{
                    value: t(
                      values?.productGroupsAndRoles?.[index]?.statusName
                    ),
                  }}
                  label={t('Статус в ТГ')}
                  name={`${name}.${index}.statusName`}
                  placeholder={t('Статус в ТГ')}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid container item xs={6}>
                <Input
                  fullWidth
                  disabled={disabled}
                  label={t('Сведения о лицензиях')}
                  name={`${name}.${index}.license`}
                  placeholder={t('Сведения о лицензиях')}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        );
      })}

      {canCreateProductGroupAndRole ? (
        <Grid item>
          <Button variant="outlined" onClick={addProductGroup}>
            {t('Добавить ещё')}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  data: selectors.getCompanyData(state),
  roles: selectors.getCompanyRoles(state),
  groups: selectors.getProductGroups(state),
});

export const RolesConnected = compose(
  connect(mapStateToProps),
  withFormContext({
    mutators: true,
    name,
  })
)(Roles);
