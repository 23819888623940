import { call, put, takeEvery } from 'redux-saga/effects';

import { HttpMethodEnum } from '../../../../../constants/index';
import { Api } from '../../../../../common_components/Api/Api';
import { fetchAgreementDocuments } from '../../ducks/AgreementDocumentsList.actions';
import { openNotificationOfRejectionModal } from '../NotificationOfRejection/ducks/NotificationOfRejection.actions';

import {
  closeUploadModeModal,
  sendUploadFile,
  setLoading,
} from './UploadAgreementDocument.actions';

function* notificationProcess(error) {
  const isShowNotificationOfRejection = error.response.data.find((item) =>
    item.message.includes('ИНН не зарегистрированы в системе')
  );

  if (isShowNotificationOfRejection) {
    yield put(closeUploadModeModal());
    yield put(openNotificationOfRejectionModal());
  }
}

function* sendUploadFileSaga({ payload }) {
  yield put(setLoading(true));

  const formData = new FormData();
  formData.append('multipartFile', payload[0]);

  const requestPayload = {
    url: '/api/v3/facade/agreement-registry/agreement/xls',
    method: HttpMethodEnum.POST,
    data: formData,
  };

  const [success, error] = yield call(Api.request, requestPayload, {
    preloading: false,
    withoutDescriptionOfError: true,
  });

  if (success) {
    yield put(closeUploadModeModal());
    yield put(fetchAgreementDocuments());
  }

  if (error) {
    yield call(notificationProcess, error);
  }

  yield put(setLoading(false));
}

export const saga = function* watch() {
  yield takeEvery(sendUploadFile, sendUploadFileSaga);
};
