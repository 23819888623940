import React from 'react';

import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { getAuthState } from '../Auth/ducks/Auth.selectors';
import { AuthState } from '../Auth/Auth.constants';

import { Route } from './Route';

const PrivateRoute = ({ component, render, authState, ...rest }) => {
  const isAuthorized = authState === AuthState.AUTHORIZED;

  if (isAuthorized) {
    return <Route component={component} render={render} {...rest} />;
  }

  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <Redirect
          to={{ pathname: '/', state: { from: routeProps.location } }}
        />
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  authState: getAuthState(state),
});

export const PrivateRouteConnected = connect(mapStateToProps)(PrivateRoute);
