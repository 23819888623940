import React, { useCallback, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { type FilterSet, type Filter } from '@ibox/ui';

import { initFromUrl } from '../../utils/url-utils';
import * as authSelectors from '../../common_components/Auth/ducks/Auth.selectors';
import {
  deleteSavedFilterFromStorage,
  editSavedFilterStorage,
  newFilter,
} from '../../helpers/filterHistory';

import * as actions from './ducks/ParticipantList.actions';
import * as selectors from './ducks/ParticipantList.selectors';
import { ParticipantListView } from './ParticipantList.view';
import { getFilters } from './ParticipantList.constants';

export const ParticipantList = () => {
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => selectors.page(state));
  const loaded = useSelector((state) => selectors.loaded(state));
  const results = useSelector((state) => selectors.results(state));
  const last = useSelector((state) => selectors.last(state));
  const total = useSelector((state) => selectors.total(state));
  const isOperator = useSelector((state) => authSelectors.isOperator(state));
  const historyFilters = useSelector((state) =>
    selectors.historyFilters(state)
  );
  const savedFilters = useSelector((state) => selectors.savedFilters(state));
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const filters = useMemo(
    () =>
      getFilters({
        t,
        data: results,
      }),
    [t, results]
  );

  const filterNames = filters.map(({ id }) => id);

  useEffect(() => {
    dispatch(
      actions.getRequest({
        filters: [],
        sort: [],
        page: 1,
        updateHistory: true,
        pathname: location.pathname,
        isResultsReset: true,
      })
    );
    return dispatch(actions.unmounted());
  }, []);

  const onPageChange = (...params: unknown) => {
    const [page] = params;
    const { filters } = initFromUrl(location.search, filterNames);
    dispatch(
      actions.getRequest({
        filters,
        page,
        updateHistory: true,
        pathname: location.pathname,
        history,
      })
    );
  };

  const onFilterApply = (filters: Filter[] = []) => {
    dispatch(
      actions.getRequest({
        filters,
        page: 1,
        updateHistory: true,
        pathname: location.pathname,
        history,
        isResultsReset: true,
      })
    );
  };

  const getHistoryFilters = useCallback(() => {
    if (localStorage.getItem('participantsListFiltersHistory')) {
      const allHistoryFilters = JSON.parse(
        localStorage.getItem('participantsListFiltersHistory') || ''
      );
      dispatch(actions.historyFilters(allHistoryFilters));
    } else {
      dispatch(actions.historyFilters([]));
    }
  }, [dispatch]);

  const getSavedFilters = useCallback(() => {
    if (localStorage.getItem('participantsListFiltersSaved')) {
      const allSavedFilters = JSON.parse(
        localStorage.getItem('participantsListFiltersSaved') || ''
      );
      dispatch(actions.savedFilters(allSavedFilters));
    } else {
      dispatch(actions.savedFilters([]));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!historyFilters.length) {
      getHistoryFilters();
    }
    if (!savedFilters.length) {
      getSavedFilters();
    }
  }, [
    historyFilters.length,
    savedFilters.length,
    getHistoryFilters,
    getSavedFilters,
  ]);

  const handleAddFiltersHistory = (value: Filter[]) => {
    if (value.length) {
      newFilter(value, 'participantsListFiltersHistory', '');
      getHistoryFilters();
    }
  };

  const addSavedFilters = (value: Filter[], filterName: string) => {
    if (value.length) {
      newFilter(value, 'participantsListFiltersSaved', filterName);
      getSavedFilters();
    }
  };

  const editSavedFilter = (
    value: Filter[],
    filterName: string,
    filter: FilterSet
  ) => {
    const changedFilter = {
      id: filter.id,
      name: filterName && filterName,
      filters: value,
    };
    editSavedFilterStorage(changedFilter, 'participantsListFiltersSaved');
    getSavedFilters();
  };

  const deleteSavedFilter = (filterId: string) => {
    deleteSavedFilterFromStorage(filterId, 'participantsListFiltersSaved');
    getSavedFilters();
  };

  return (
    <>
      <ParticipantListView
        addSavedFilters={addSavedFilters}
        currentPage={currentPage}
        deleteSavedFilter={deleteSavedFilter}
        editSavedFilter={editSavedFilter}
        handleAddFiltersHistory={handleAddFiltersHistory}
        historyFilters={historyFilters}
        isOperator={isOperator}
        last={last}
        loaded={loaded}
        results={results}
        savedFilters={savedFilters}
        total={total}
        onFilterApply={onFilterApply}
        onPageChange={onPageChange}
      />
    </>
  );
};
