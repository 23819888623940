export type ProjectName = 'lka' | 'tobacco' | 'elk' | 'lightindustry' | 'elk';

export enum TableNamesEnum {
  ViolationsRegistry = 'violationsRegistry',
  ViolationsRegistryByType = 'violationsRegistryByType',
  ViolationsRegistryByInn = 'violationsRegistryByInn',
}

export interface ViolationsData {
  name: string;
  type: string;
  count: number;
}

export interface ViolationsDataByType {
  participant: {
    id: string;
    inn: string;
    name: string;
    type: string[];
  };
  count: number;
  region: string;
}

export interface ViolationsDataByInn {
  datetime: number;
  product: {
    code: string;
    name: string;
  };
}

export interface ViolationsDataRow {
  original: ViolationsData;
}

export interface ViolationsDataByTypeRow {
  original: ViolationsDataByType;
}

export interface ViolationsDataByInnRow {
  original: ViolationsDataByInn;
}

export interface MatchParams {
  type?: string;
  inn?: string;
}
