import { useCallback, useMemo, useState } from 'react';
import { createForm } from '@crpt-ui/form';
import arrayMutators from 'final-form-arrays';
import schema from './GtinDialog.schema';

import { EditGtinsState, Gtin } from '../../CisUnloads.viewmodel';
import { FieldIds } from './GtinDialog.types';
import bff from '@crpt/shared/services/bff';

interface GtinDialogViewmodelProps extends EditGtinsState {
  onClose: () => void;
  onSubmit: (data: Gtin[], fieldName: string) => void;
  isOpen: boolean;
}

export const GtinDialogViewmodel = ({
  onClose,
  onSubmit,
  gtins,
  fieldName,
  isOpen,
}: GtinDialogViewmodelProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    (values) => {
      onSubmit(values.data, fieldName);
    },
    [onSubmit, fieldName]
  );

  const formInstance = useMemo(
    () =>
      createForm({
        schema,
        initialValues: {
          [FieldIds.DATA]: [...gtins],
        },
        onSubmit: handleSubmit,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        mutators: { ...arrayMutators },
      }),
    [gtins, handleSubmit, isOpen]
  );

  const handleClose = useCallback(() => {
    onClose();
  }, [formInstance, onClose]);

  const loadProduct = useCallback(
    async (gtin: string, name: string) => {
      try {
        setIsLoading(true);
        const {
          data: { results },
        } = await bff.fetchProductList([gtin]);
        formInstance.change(name, results.length ? results[0].name : '');
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [setIsLoading, formInstance]
  );

  const clearName = useCallback(
    (name: string) => {
      formInstance.change(name, '');
    },
    [formInstance]
  );

  return {
    isLoading,
    loadProduct,
    clearName,
    handleClose,
    formInstance,
    handleSubmit,
  };
};
