import React from 'react';

import { Icon } from '@crpt-ui/core';

import { translate } from '../components/Translate/Translate';

// deprecated
export const participantStatuses = {
  REGISTERED: 'Зарегистрирован',
  PRE_REGISTERED: 'В процессе регистрации',
  REJECTED: 'Отказано в регистрации',
};

export const participantStatusesEnum = {
  REGISTERED: 'REGISTERED',
  PRE_REGISTERED: 'PRE_REGISTERED',
  REJECTED: 'REJECTED',
};

export const participantStatusesName = {
  [participantStatusesEnum.REGISTERED]: translate('Зарегистрирован'),
  [participantStatusesEnum.PRE_REGISTERED]: translate('В процессе регистрации'),
  [participantStatusesEnum.REJECTED]: translate('Отказано в регистрации'),
};

export const participantStatusesColorsMap = {
  [participantStatusesEnum.REGISTERED]: '#46C83C',
  [participantStatusesEnum.PRE_REGISTERED]: '#FF910A',
  [participantStatusesEnum.REJECTED]: '#FF2319',
};

export const participantStatusesTextColorsMap = {
  [participantStatusesEnum.REJECTED]: '#8686886',
};

export const participantStatusesCellTextColorsMap = {
  ...participantStatusesColorsMap,
  [participantStatusesEnum.REJECTED]: '#8686886',
};

export const participantStatusesIconsMap = {
  [participantStatusesEnum.REGISTERED]: <Icon name="UserActive" />,
  [participantStatusesEnum.PRE_REGISTERED]: <Icon name="UserAwait" />,
  [participantStatusesEnum.REJECTED]: <Icon name="UserBlocked" />,
};
