import { createStyles, Theme } from '@material-ui/core';

export default ({ palette, spacing, transitions }: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      border: `1px solid ${palette.grey[500]}`,
      height: 148,
      padding: spacing(2),
      borderRadius: 4,
      boxSizing: 'border-box',
      width: 384,
      background: '#fff',
      transition: transitions.create(['border', 'box-shadow']),

      '& $date, & $title': {
        fontWeight: 700,
        letterSpacing: '0.02em',
        textTransform: 'uppercase',
      },

      '&:hover': {
        boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.075)',
        borderColor: 'transparent',
      },

      '&::before': {
        content: '""',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '5px 3px 0 3px',
        borderColor: `${palette.grey[500]} transparent transparent transparent`,
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        top: -5,
      },

      '$outter:first-child &::before': {
        display: 'none',
      },
    },
    ch: {
      position: 'relative',
    },
    date: {
      fontSize: 10,
      lineHeight: 16 / 10,
      color: palette.text.secondary,
    },
    title: {
      fontSize: 14,
      lineHeight: 24 / 14,
    },
    children: {
      position: 'relative',
    },
    icon: {
      width: 48,
      height: 48,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 16,
      borderRadius: 4,
      background: '#f9f9f9',
    },
    h: {
      marginBottom: 16,
    },
    b: {
      paddingLeft: 48 + 16,
      lineHeight: 2,
      fontSize: 12,
      color: palette.text.secondary,
      fontWeight: 600,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '& + &': {
        marginTop: 4,
      },
    },
    outter: {},
    fake: {
      '&, &:hover': {
        border: `1px solid ${palette.warning.main}`,
      },
    },
  });
