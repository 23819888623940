import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  wrapper: {
    height: 'calc(100% - 70px)',
    padding: '16px 0 0',
  },
  paginationWrapper: {
    alignItems: 'center',
    display: 'flex',
    height: 60,
    padding: '0 40px',
  },
});
