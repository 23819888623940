import React, { memo } from 'react';

import { useStyles } from '../PDFViewer.styles';
import { Box } from '@material-ui/core';
import Zoom from './Zoom';
import Actions from './Actions';
import Title from './Title';

import type { ActionsProps } from './Actions';
import type { ZoomProps } from './Zoom';

export type TopPanelProps = {
  title: string;
} & ActionsProps &
  ZoomProps;

const PDFViewerView: React.FC<TopPanelProps> = (props) => {
  const { title, onZoomIn, onZoomOut, onDownload, onPrint, onClose } = props;

  const classes = useStyles();

  return (
    <Box
      alignItems="center"
      className={classes.topPanel}
      display="flex"
      flexWrap="nowrap"
      justifyContent="space-between"
      px={4}
      py={2}
    >
      <Box flexBasis="40%" minWidth={0}>
        <Title tooltipTitle={title}>{title}</Title>
      </Box>
      <Box>
        <Zoom onZoomIn={onZoomIn} onZoomOut={onZoomOut} />
      </Box>
      <Box>
        <Actions onClose={onClose} onDownload={onDownload} onPrint={onPrint} />
      </Box>
    </Box>
  );
};

export default memo(PDFViewerView);
