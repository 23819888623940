import React, { FC } from 'react';
import { createStyles, withStyles } from '@material-ui/core';

import type { WithStyles } from '@material-ui/core';

interface TagProps extends WithStyles<typeof styles> {
  value: string;
}

const Tag: FC<TagProps> = ({ classes, value }) => (
  <div className={classes.tag}>{value}</div>
);

const styles = () =>
  createStyles({
    tag: {
      height: '50px',
      lineHeight: '50px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      boxSizing: 'border-box',
      padding: '8px 0 0 16px',
      fontSize: 14,
      fontWeight: 600,
    },
  });

export default withStyles(styles)(Tag);
