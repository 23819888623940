import { Autocomplete } from '@crpt-ui/core';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import { DefaultFilter } from './DefaultFilter';
import { generateFilter } from '../Datatable.utils';

export const AutocompleteFilter = React.memo(
  ({ column, form, formState, instance, options, onInputChange, ...rest }) => {
    const memoizedInputChange = useCallback((event, value, reason) => {
      if (value.trim() && reason === 'input') {
        onInputChange && onInputChange(value);
      }
    }, []);

    const value = useMemo(() => {
      const formStateValue = _.get(formState.values, 'value', '');
      return options.find((option) => option.value === formStateValue);
    }, [formState.values]);

    const onChange = useCallback((_event, option) => {
      form.change('value', option.value);
    }, []);

    return React.createElement(Autocomplete, {
      ...rest,
      name: 'value',
      onChange,
      onInputChange: memoizedInputChange,
      options,
      value,
    });
  }
);

export const makeAutocompleteFilter = generateFilter(AutocompleteFilter);

AutocompleteFilter.propTypes = {
  ...DefaultFilter.propTypes,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  onInputChange: PropTypes.func,
};
