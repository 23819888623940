import { handleActions } from 'redux-actions';

import {
  addedOiv,
  onDataLoaded,
  fetchData,
  clearAddedOiv,
  fetchAddOiv,
  onUnmount,
  onUpdatePage,
} from './RegistryOIV.actions';

const results = handleActions(
  {
    [onDataLoaded]: (state, { payload }) => {
      return [...state, ...(payload.results || [])];
    },
    [addedOiv]: (state, { payload }) => {
      return [
        {
          ...payload,
          innOiv: payload.inn,
        },
        ...state,
      ];
    },
    [onUnmount]: () => [],
  },
  []
);

const loaded = handleActions(
  {
    [onDataLoaded]: () => true,
    [onUnmount]: () => false,
    [fetchData]: () => false,
  },
  false
);

const addedOIV = handleActions(
  {
    [fetchAddOiv]: () => false,
    [clearAddedOiv]: () => false,
    [addedOiv]: () => true,
  },
  false
);

const total = handleActions(
  {
    [onDataLoaded]: (_, { payload }) => payload.total || 0,
    [onUnmount]: () => 0,
  },
  0
);

const last = handleActions(
  {
    [onDataLoaded]: (_, { payload }) => payload.last,
    [onUnmount]: () => false,
  },
  false
);

const page = handleActions(
  {
    [onUpdatePage]: (_, { payload }) => payload,
    [onUnmount]: () => 1,
  },
  false
);

export const reducer = {
  results,
  loaded,
  addedOIV,
  total,
  page,
  last,
};

// const { reducer, actions } = createSlice({
//   initialState: initialState,
//   name: 'RegistryOIV',
//   reducers: {
//     onDataLoaded: (state, action) => {
//       state.results = action.payload.results || [];
//       state.loaded = true;
//       state.total = action.payload.total || 0;
//       state.last = action.payload.last;
//     },
//     fetchData: (state) => {
//       state.loaded = false;
//     },
//     fetchAddOiv: (state) => {
//       state.addedOIV = false;
//     },
//     addedOiv: (state, action) => {
//       state.addedOIV = true;
//       state.results = [
//         ...state.results,
//         {
//           ...action.payload,
//           innOiv: action.payload.inn
//         }
//       ];
//     },
//     clearAddedOiv: (state) => {
//       state.addedOIV = false;
//     },
//     onUpdatePage: (state, action) => {
//       state.page = action.payload;
//     },
//     onUnmount: (state) => {
//       state.results = [];
//       state.loaded = false;
//       state.total = 0;
//       state.page = 1;
//       state.last = false;
//     }
//   },
// });
//
// export {actions , reducer};
//
// export default reducer;
