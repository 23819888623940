import { createStyles } from '@material-ui/core';

export const ZoomStyles = () =>
  createStyles({
    root: {
      position: 'absolute',
      right: 32,
      bottom: '50%',
      overflow: 'hidden',
      transform: 'translateY(50%)',
    },
  });
