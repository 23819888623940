import React from 'react';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import { Button } from '@ibox/ui';

import { StripeLayout } from '../../common_components/Layouts';

type PageMessageProps = {
  history: RouteComponentProps['history'];
};

export const PageMessage = ({ history }: PageMessageProps) => {
  const { t } = useTranslation();

  return (
    <StripeLayout>
      <StripeLayout.Content>
        <Typography align="center" variant="h4">
          Данные сформированы и&nbsp;обрабатываются
        </Typography>
        <Box mt={2}>
          <Typography align="center" variant="body1">
            На&nbsp;основании указанных данных осуществляется обновление
            регистрационных сведений участника оборота товаров в&nbsp;системе
            мониторинга. Обработка данных займет некоторое время. Пожалуйста,
            авторизуйтесь в&nbsp;системе повторно.
            <br />
            При необходимости, обращайтесь в&nbsp;службу поддержки{' '}
          </Typography>
        </Box>
        <Box mt={2} textAlign="center">
          <Button
            size="large"
            style={{ width: '270px' }}
            onClick={() => {
              history.replace('/login-kep');
            }}
          >
            {t('Авторизоваться')}
          </Button>
        </Box>
      </StripeLayout.Content>
    </StripeLayout>
  );
};
