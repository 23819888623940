import { SagaIterator } from 'redux-saga';
import { call, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { Api } from '../../Api/Api';
import { isActOfReconciliation } from '../../../components/Documents/ClosingDocument/ClosingDocument.utils';
import { getFilename, saveFile } from '../Document.utils';

import { downloadDocument, downloadDocumentWorkflow } from './Document.actions';
import { DocumentPayloadType } from './Document.types';

function* downloadDocumentWorkflowSaga({
  payload: { id, name = null, direction = 'incoming' },
}: PayloadAction<
  Pick<DocumentPayloadType, 'id' | 'name' | 'direction'>
>): SagaIterator {
  const [success] = yield call(
    Api.fetchFile,
    `/edo-api/${direction}-documents/${id}`,
    {
      requestConfiguration: {
        headers: {
          Accept: 'application/zip',
        },
      },
      fileQueue: {
        name: name ?? id,
      },
    }
  );

  if (success) {
    yield call(saveFile, {
      filename: getFilename(success),
      content: success.data,
      type: 'application/zip',
    });
  }
}

export function* fetchDocumentsSaga({
  hasEdo = false,
  id,
  name = null,
  fileSize = null,
  type = null,
  status = null,
  useFileQueue = false,
  direction = 'incoming',
}: DocumentPayloadType): SagaIterator {
  let requestPayload;

  if (hasEdo) {
    const printUrl = isActOfReconciliation(type, status)
      ? 'print-utoch'
      : 'print';

    requestPayload = {
      url: `/edo-api/${direction}-documents/${id}/${printUrl}`,
    };
  } else {
    requestPayload = {
      url: '/crm-api/document',
      params: {
        guid: id,
      },
    };
  }

  const fileQueue = useFileQueue
    ? {
        name: name ?? id,
        fileSize,
      }
    : undefined;

  return yield call(Api.fetchFile, requestPayload.url, {
    params: {
      ...requestPayload.params,
    },
    requestConfiguration: {
      headers: {
        Accept: 'application/pdf',
        responseType: 'arraybuffer',
        timeout: 300000,
      },
      type: 'pdf',
    },
    fileQueue,
  });
}

function* downloadDocumentsSaga({
  payload: {
    hasEdo = false,
    id,
    name = null,
    fileSize = null,
    type = null,
    status = null,
    useFileQueue = null,
    direction = 'incoming',
  },
}: PayloadAction<DocumentPayloadType>): SagaIterator {
  const [success] = yield call(fetchDocumentsSaga, {
    hasEdo,
    id,
    name,
    fileSize,
    type,
    status,
    useFileQueue,
    direction,
  });

  if (success) {
    yield call(saveFile, {
      filename: getFilename(success),
      content: success.data,
      type: 'application/pdf',
    });
  }
}

export const saga = function* watch() {
  yield takeEvery(downloadDocumentWorkflow, downloadDocumentWorkflowSaga);
  yield takeEvery(downloadDocument, downloadDocumentsSaga);
};
