import { findKey } from 'lodash';
import { rutokenErrorsDataList } from './errors';

export const getPluginErrorDescription = (code, errorCodes) => {
  const errorKey = findErrorKeyByValue(code, errorCodes);
  return rutokenErrorsDataList[errorKey];
};

export const findErrorKeyByValue = (code, errorCodes) =>
  findKey(errorCodes, (errorCode) => errorCode === +code).toString();
