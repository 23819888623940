import { useEffect } from 'react';
import { redesignDivId } from './redesign.consts';

const redesignPortalSelector = `body div[id=${redesignDivId}]`;

function createStyle(css: string): HTMLStyleElement {
  const element = document.createElement('style');
  element.innerHTML = css;
  document.head.appendChild(element);
  return element;
}

interface StyleItem {
  element: HTMLStyleElement;
  overridesCount: number;
}

const usedStyles = new Map<string, StyleItem>();

function createStyleOverrides(
  styles: Record<string, string>,
  overrideCss: Record<string, Record<string, string | number>>
): [string, StyleItem][] {
  const styleElements: [string, StyleItem][] = [];
  Object.entries(styles).forEach(([styleName, classNames]) => {
    if (overrideCss[styleName]) {
      const cssSelector = `${redesignPortalSelector} ${classNames
        .split(' ')
        .map((className) => `.${className}`)
        .join('')}`;

      const css = Object.entries(overrideCss[styleName])
        .map(([cssName, cssValue]) => {
          return `${cssName}: ${cssValue};`;
        })
        .join(' ');

      let style = usedStyles.get(cssSelector);
      if (!style) {
        style = {
          element: createStyle(`${cssSelector} { ${css} }`),
          overridesCount: 0,
        };
        usedStyles.set(cssSelector, style);
      }
      style.overridesCount++;
      styleElements.push([cssSelector, style]);
    }
  });

  return styleElements;
}

export const useRedesignStyles = (
  styles: Record<string, string>,
  overrideCss: Record<string, Record<string, string | number>>
) => {
  useEffect(() => {
    const styleElements = createStyleOverrides(styles, overrideCss);

    return () => {
      styleElements.forEach(([cssSelector, style]) => {
        style.overridesCount--;
        if (style.overridesCount <= 0) {
          try {
            document.head.removeChild(style.element);
          } catch(e) {
            // do nothing
          } finally {
            usedStyles.delete(cssSelector);
          }
        }
      });
    };
  }, [styles, overrideCss]);
};
