import React from 'react';

import { Icon } from '@crpt-ui/core';

import { translate } from '../components/Translate/Translate';

export const documentTypesEnum = {
  UNIVERSAL_TRANSFER_DOCUMENT: 'UNIVERSAL_TRANSFER_DOCUMENT',
  UNIVERSAL_TRANSFER_DOCUMENT_FIX: 'UNIVERSAL_TRANSFER_DOCUMENT_FIX',
  UNIVERSAL_CORRECTION_DOCUMENT: 'UNIVERSAL_CORRECTION_DOCUMENT',
  UNIVERSAL_CORRECTION_DOCUMENT_FIX: 'UNIVERSAL_CORRECTION_DOCUMENT_FIX',
  UNIVERSAL_TRANSFER_DOCUMENT_UNSIGNED: 'UNIVERSAL_TRANSFER_DOCUMENT_UNSIGNED',
  UNIVERSAL_CORRECTION_DOCUMENT_UNSIGNED:
    'UNIVERSAL_CORRECTION_DOCUMENT_UNSIGNED',
  UNIVERSAL_CANCEL_DOCUMENT: 'UNIVERSAL_CANCEL_DOCUMENT',
  AGGREGATION_DOCUMENT: 'AGGREGATION_DOCUMENT',
  AGGREGATION_DOCUMENT_CSV: 'AGGREGATION_DOCUMENT_CSV',
  AGGREGATION_DOCUMENT_XML: 'AGGREGATION_DOCUMENT_XML',
  SURPLUS_POSTING: 'SURPLUS_POSTING',
  WRITE_OFF: 'WRITE_OFF',
  LP_FTS_INTRODUCE_RESPONSE: 'LP_FTS_INTRODUCE_RESPONSE',
  MTTPS_10: 'MTTPS_10',
  MTTPS_90: 'MTTPS_90',
  IMPORT: 'IMPORT',
  IMPORT_TRANSIT: 'IMPORT_TRANSIT',
  PAYMENT: 'PAYMENT',
  RECEIPT: 'RECEIPT',
  RECEIPT_RETURN: 'RECEIPT_RETURN',
  LP_INTRODUCE_GOODS: 'LP_INTRODUCE_GOODS',
  LK_LOAN_RECEIPT: 'LK_LOAN_RECEIPT',
  LK_RECEIPT: 'LK_RECEIPT',
  OST_DESCRIPTION: 'OST_DESCRIPTION',
  OST_DESCRIPTION_CSV: 'OST_DESCRIPTION_CSV',
  OST_DESCRIPTION_XML: 'OST_DESCRIPTION_XML',
  WITHDRAW: 'WITHDRAW',
  RETURN_TO_TURNOVER: 'RETURN_TO_TURNOVER',
  AUTO_DISAGGREGATION: 'AUTO_DISAGGREGATION',
  INTRODUCED: 'INTRODUCED',
  EMISSION: 'EMISSION',
  APPLICATION: 'APPLICATION',
  AGGREGATION: 'AGGREGATION',
  DOCUMENT_FIX: 'DOCUMENT_FIX',
  DOCUMENT_CORRECTION: 'DOCUMENT_CORRECTION',
  OWNER_CHANGE: 'OWNER_CHANGE',
  UNDEFINED: 'UNDEFINED',
  LP_INTRODUCE_GOODS_CSV: 'LP_INTRODUCE_GOODS_CSV',
  LP_INTRODUCE_GOODS_XML: 'LP_INTRODUCE_GOODS_XML',
  LK_LOAN_RECEIPT_CSV: 'LK_LOAN_RECEIPT_CSV',
  LK_LOAN_RECEIPT_XML: 'LK_LOAN_RECEIPT_XML',
  LK_RECEIPT_XML: 'LK_RECEIPT_XML',
  LK_RECEIPT_CSV: 'LK_RECEIPT_CSV',
  LP_ACCEPT_GOODS: 'LP_ACCEPT_GOODS', // возможно используется в Draft.utils.js
  RETURN: 'RETURN',
  INTRODUCE_GOODS: 'INTRODUCE_GOODS',
  NOTICE_EEU: 'NOTICE_EEU',
  CUSTOM_DECLARATION: 'CUSTOM_DECLARATION',
  UPD: 'UPD',
  UPDi: 'UPDi',
  INTERNAL_TRANSFER: 'INTERNAL_TRANSFER',
  INTERNAL_TRANSFERi: 'INTERNAL_TRANSFERi',
  WITHDROWN_DOC: 'WITHDROWN_DOC',
  WITHDROWN_DOCi: 'WITHDROWN_DOCi',
};

export const documentTypesValues = {
  [documentTypesEnum.UNIVERSAL_TRANSFER_DOCUMENT]: translate(
    'Документ оптовой продажи'
  ),
  // [documentTypesEnum.UNIVERSAL_TRANSFER_DOCUMENT]: 'УПД',
  [documentTypesEnum.UNIVERSAL_TRANSFER_DOCUMENT_FIX]: translate(
    'Оптовая продажа (корректировка)'
  ),
  // [documentTypesEnum.UNIVERSAL_TRANSFER_DOCUMENT_FIX]: 'УПДи',
  [documentTypesEnum.UNIVERSAL_CORRECTION_DOCUMENT]: translate('УКД'),
  [documentTypesEnum.UNIVERSAL_CORRECTION_DOCUMENT_FIX]: translate('УКДи'),
  [documentTypesEnum.UNIVERSAL_TRANSFER_DOCUMENT_UNSIGNED]: translate('ПД'),
  [documentTypesEnum.UNIVERSAL_CORRECTION_DOCUMENT_UNSIGNED]: translate('КД'),
  [documentTypesEnum.UNIVERSAL_CANCEL_DOCUMENT]: translate('Аннулирование'),
  [documentTypesEnum.AGGREGATION_DOCUMENT]: translate('Агрегирование'),
  [documentTypesEnum.SURPLUS_POSTING]: translate('Оприходование излишков'),
  [documentTypesEnum.WRITE_OFF]: translate('Списание'),
  [documentTypesEnum.LP_FTS_INTRODUCE_RESPONSE]: translate(
    'Декларация на товары'
  ),
  [documentTypesEnum.MTTPS_10]: translate(
    'Заявление о регистрации в качестве участника Эксперимента (МТПФ-10)'
  ),
  [documentTypesEnum.MTTPS_90]: translate(
    'Уведомление о выводе из оборота табачной продукции (МТПФ-90)'
  ),
  [documentTypesEnum.IMPORT]: translate(
    'Уведомление о ввозе товара (третьи страны)'
  ),
  [documentTypesEnum.IMPORT_TRANSIT]: translate(
    'Уведомление о ввозе товара (ЕАЭС)'
  ),
  [documentTypesEnum.WITHDRAW]: translate('Чек'),
  [documentTypesEnum.RETURN_TO_TURNOVER]: translate('Чек возврата'),
  [documentTypesEnum.RECEIPT]: translate('Чек'),
  [documentTypesEnum.RECEIPT_RETURN]: translate('Чек возврата'),
  [documentTypesEnum.PAYMENT]: translate('Оплата'),
  [documentTypesEnum.OST_DESCRIPTION]: translate('Описание остатков'),
  [documentTypesEnum.OST_DESCRIPTION_CSV]: translate('Описание остатков'),
  [documentTypesEnum.OST_DESCRIPTION_XML]: translate('Описание остатков'),
  [documentTypesEnum.INTRODUCED]: translate('Уведомление о ввозе товара'),
  [documentTypesEnum.EMISSION]: translate('Агрегирование'),
  [documentTypesEnum.APPLICATION]: translate('Агрегирование'),
  [documentTypesEnum.AGGREGATION]: translate('Агрегирование'),
  [documentTypesEnum.AUTO_DISAGGREGATION]: translate('Дезагрегирован'),
  [documentTypesEnum.DOCUMENT_FIX]: translate('УКДи'),
  [documentTypesEnum.DOCUMENT_CORRECTION]: translate('УКД'),
  [documentTypesEnum.OWNER_CHANGE]: translate('УПД'),
  [documentTypesEnum.UNDEFINED]: translate('Не определен'),
  [documentTypesEnum.LP_INTRODUCE_GOODS]: translate('Ввод в оборот'),
  [documentTypesEnum.LP_INTRODUCE_GOODS_CSV]: translate('Ввод в оборот'),
  [documentTypesEnum.LP_INTRODUCE_GOODS_XML]: translate('Ввод в оборот'),
  [documentTypesEnum.LK_LOAN_RECEIPT]: translate('Вывод из оборота'),
  [documentTypesEnum.LK_LOAN_RECEIPT_CSV]: translate('Вывод из оборота'),
  [documentTypesEnum.LK_LOAN_RECEIPT_XML]: translate('Вывод из оборота'),
  [documentTypesEnum.LK_RECEIPT]: translate('Вывод из оборота'),
  [documentTypesEnum.LK_RECEIPT_XML]: translate('Вывод из оборота'),
  [documentTypesEnum.LK_RECEIPT_CSV]: translate('Вывод из оборота'),
  [documentTypesEnum.RETURN]: translate('Возврат в оборот'),
  [documentTypesEnum.INTRODUCE_GOODS]: translate(
    'Уведомление о вводе в оборот'
  ),
  [documentTypesEnum.UPD]: translate('Документ оптовой продажи'),
  [documentTypesEnum.UPDi]: translate('Оптовая продажа (корректировка)'),
  [documentTypesEnum.INTERNAL_TRANSFER]: translate(
    'Документ внутреннего перемещения'
  ),
  [documentTypesEnum.INTERNAL_TRANSFERi]: translate(
    'Внутреннее перемещение (корректировка)'
  ),
  [documentTypesEnum.WITHDROWN_DOC]: translate('Документ экспорта'),
  [documentTypesEnum.WITHDROWN_DOCi]: translate('Экспорт (корректировка)'),
};

export const initialDocumentTypesValues = {
  [documentTypesEnum.AGGREGATION_DOCUMENT]: 'Агрегирование',
  [documentTypesEnum.WRITE_OFF]: 'Списание',
  [documentTypesEnum.INTRODUCE_GOODS]: 'Уведомление о вводе в оборот',
};

export const documentTypesIcons = {
  [documentTypesEnum.UNIVERSAL_TRANSFER_DOCUMENT]: <Icon name="UKD" />,
  [documentTypesEnum.UNIVERSAL_TRANSFER_DOCUMENT_FIX]: <Icon name="UKDI" />,
  [documentTypesEnum.UNIVERSAL_CORRECTION_DOCUMENT]: <Icon name="UKD" />,
  [documentTypesEnum.UNIVERSAL_CORRECTION_DOCUMENT_FIX]: <Icon name="UKDI" />,
  [documentTypesEnum.UNIVERSAL_TRANSFER_DOCUMENT_UNSIGNED]: (
    <Icon name="Document" />
  ),
  [documentTypesEnum.UNIVERSAL_CORRECTION_DOCUMENT_UNSIGNED]: (
    <Icon name="Document" />
  ),
  [documentTypesEnum.UNIVERSAL_CANCEL_DOCUMENT]: <Icon name="Document" />,
  [documentTypesEnum.WRITE_OFF]: <Icon name="CirculationWithdrawal" />,
  [documentTypesEnum.MTTPS_10]: <Icon name="Document" />,
  [documentTypesEnum.MTTPS_90]: <Icon name="Document" />,
  [documentTypesEnum.OST_DESCRIPTION]: <Icon name="Document" />,
  [documentTypesEnum.OST_DESCRIPTION_CSV]: <Icon name="Document" />,
  [documentTypesEnum.OST_DESCRIPTION_XML]: <Icon name="Document" />,
  [documentTypesEnum.IMPORT]: <Icon name="Document" />,
  [documentTypesEnum.IMPORT_TRANSIT]: <Icon name="Document" />,
  [documentTypesEnum.AGGREGATION_DOCUMENT]: <Icon name="AggregationDocument" />,
  [documentTypesEnum.SURPLUS_POSTING]: <Icon name="Document" />,
  [documentTypesEnum.LP_INTRODUCE_GOODS]: <Icon name="CirculationInput" />,
  [documentTypesEnum.INTRODUCE_GOODS]: <Icon name="CirculationInput" />,
  [documentTypesEnum.LP_INTRODUCE_GOODS_CSV]: <Icon name="CirculationInput" />,
  [documentTypesEnum.LP_INTRODUCE_GOODS_XML]: <Icon name="CirculationInput" />,
  [documentTypesEnum.LK_LOAN_RECEIPT]: <Icon name="CirculationWithdrawal" />,
  [documentTypesEnum.LK_LOAN_RECEIPT_CSV]: (
    <Icon name="CirculationWithdrawal" />
  ),
  [documentTypesEnum.LK_LOAN_RECEIPT_XML]: (
    <Icon name="CirculationWithdrawal" />
  ),
  [documentTypesEnum.LK_RECEIPT]: <Icon name="CirculationWithdrawal" />,
  [documentTypesEnum.LK_RECEIPT_XML]: <Icon name="CirculationWithdrawal" />,
  [documentTypesEnum.LK_RECEIPT_CSV]: <Icon name="CirculationWithdrawal" />,
  [documentTypesEnum.LP_FTS_INTRODUCE_RESPONSE]: <Icon name="Document" />,
  [documentTypesEnum.WITHDRAW]: <Icon name="Receipt" />,
  [documentTypesEnum.RETURN_TO_TURNOVER]: <Icon name="Receipt" />,
  [documentTypesEnum.RECEIPT]: <Icon name="Receipt" />,
  [documentTypesEnum.AUTO_DISAGGREGATION]: <Icon name="Receipt" />,
  [documentTypesEnum.RECEIPT_RETURN]: <Icon name="Receipt" />,
  [documentTypesEnum.RETURN]: <Icon name="Union" />,
  [documentTypesEnum.WITHDROWN_DOC]: <Icon name="Export" />,
  [documentTypesEnum.WITHDROWN_DOCi]: <Icon name="Import" />,
  [documentTypesEnum.INTERNAL_TRANSFER]: <Icon name="View" />,
  [documentTypesEnum.INTERNAL_TRANSFERi]: <Icon name="View" />,
  [documentTypesEnum.INTRODUCE_GOODS]: <Icon name="Shipping" />,
  [documentTypesEnum.UPDi]: <Icon name="UKDI" />,
};
