import { Base64 } from 'js-base64';
import flatten from 'lodash/flatten';

import { reflect } from './helpers';
import { Cryptopro, Rutoken } from './providers';

const enumerateCertificates = () => {
  return Promise.all(
    [Cryptopro.enumerateCertificates(), Rutoken.enumerateCertificates()].map(
      reflect
    )
  ).then((responses) => {
    const errors = responses
      .filter(({ status, error }) => status === 'rejected' && error.message)
      .map(({ error }) => error.message);

    const values = responses
      .filter((response) => response.status === 'fulfilled')
      .map((response) => response.value);

    return errors.length && !values.length
      ? Promise.reject(new Error(errors.join(', ')))
      : flatten(values);
  });
};

const sign = (base64, certificate, detached = false) => {
  switch (certificate.plugin) {
    case 'rutoken':
      return Rutoken.sign(base64, certificate, detached);
  }

  return Cryptopro.sign(base64, certificate, detached);
};

const signBytearray = (bytearray, certificate) => {
  const data = new Uint8Array(bytearray).reduce(
    (symbol, byte) => symbol + String.fromCharCode(byte),
    ''
  );

  return sign(Base64.btoa(data), certificate, true);
};

const signXML = (xml, certificate, cachePin = false) => {
  switch (certificate.plugin) {
    case 'cryptopro':
      return Cryptopro.signXML(xml, certificate, cachePin);
  }

  throw new Error('Метод не поддерживается');
};

const findCertificateBySerialNumber = async (certificateSerialNumber) => {
  const certificates = await enumerateCertificates();
  return certificates.find(
    (certificate) => certificate.serialNumber === certificateSerialNumber
  );
};

const findCertificateByThumbprint = async (thumbprint) => {
  const certificates = await enumerateCertificates();
  return certificates.find(
    (certificate) => certificate.thumbprint === thumbprint
  );
};

export default {
  enumerateCertificates: enumerateCertificates,
  findCertificateBySerialNumber: findCertificateBySerialNumber,
  findCertificateByThumbprint: findCertificateByThumbprint,
  sign: sign,
  signXML: signXML,
  signBytearray: signBytearray,
};

export { Base64 };
