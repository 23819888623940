import faviconAM from '../assets/favicons/faviconAM.ico';
import faviconUZ from '../assets/favicons/faviconUZ.ico';
import favicon from '../assets/favicons/favicon.ico';

export type Language = 'en' | 'ru' | 'uz' | 'hy' | 'kz';
export type Country = 'hy' | 'am' | 'az' | 'kz' | 'uz';
export type CountryCode = 'HY' | 'AM' | 'AZ' | 'KZ' | 'UZ';

const getFaviconByCountry = (countryCode: CountryCode) => {
  switch (countryCode) {
    case 'KZ':
      return favicon;
    case 'AZ':
      return favicon;
    case 'HY':
      return faviconAM;
    case 'AM':
      return faviconAM;
    case 'UZ':
      return faviconUZ;
    default:
      return faviconAM;
  }
};

export const setFavicon = (countryCode: CountryCode) => {
  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = getFaviconByCountry(countryCode);
  document.getElementsByTagName('head')[0].appendChild(link);
};

export const setPageTitle = () => {
  document.title = 'АИС МТ';
};
