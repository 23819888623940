import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as _ from 'lodash';

import { Api } from '../../../../common_components/Api/Api';
import { getAccounts } from '../../../_Profile/Company/ducks/Company.selectors';

import {
  fetchPaymentDocuments,
  setDefaultAccount,
  setPaymentDocuments,
  setSelectedAccount,
} from './PaymentDocumentsList.actions';

function* setDefaultAccountSaga() {
  const accounts = yield select(getAccounts);
  const contractId = _.get(accounts, '0.contractId');

  if (contractId) {
    yield put(setSelectedAccount(contractId));
    yield put(fetchPaymentDocuments(contractId));
  }
}

function* fetchPayments({ payload: contractId }) {
  const [success] = yield call(Api.request, {
    url: '/crm-api/payments',
    params: {
      contractId,
    },
  });

  if (success) {
    const payments = success.data.response.map((payment) => ({
      ...payment,
      amount: _.defaultTo(payment.amount, 0),
    }));

    yield put(setPaymentDocuments(payments));
  }
}

export const saga = function* watch() {
  yield takeEvery(setDefaultAccount, setDefaultAccountSaga);
  yield takeEvery(fetchPaymentDocuments, fetchPayments);
};
