import React from 'react';

import { useStyles } from './PDFError.styled';

export const PDFError = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      Невозможно загрузить файл. Выберите другой файл.
    </div>
  );
};
