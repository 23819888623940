import { createAction } from 'redux-actions';

export const subject = '[SDSignDocuments]';

export const mount = createAction(`${subject} mount`);
export const loaded = createAction(`${subject} loaded`);
export const open = createAction(`${subject} open`);
export const close = createAction(`${subject} close`);
export const downloadAllDocs = createAction(`${subject} download all docs`);
export const sign = createAction(`${subject} sign`);
export const signed = createAction(`${subject} signed`);
export const signError = createAction(`${subject} sign error`);
export const setSignDocumentsInfo = createAction(
  `${subject} SignDocumentsInfo`
);
export const openSignDocumentsModalById = createAction(
  `${subject} openSignDocumentsModalById`
);
