import React from 'react';

import { connect } from 'react-redux';
import { Grid, Typography } from '@mui/material';

import { Input } from '../../../../common_components/Form/Input';
import { getDisabledFields } from '../ducks/Company.selectors';
import { translate } from '../../../Translate/Translate';

type ForeignOrganisationInfo = {
  disabled: boolean;
};

export const ForeignOrganisationInfo: React.FC<ForeignOrganisationInfo> = ({
  disabled,
}) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant="h6">
        {translate('Данные головного офиса иностранных лиц')}
      </Typography>
    </Grid>

    <Grid item xs={12}>
      <Input
        fullWidth
        required
        disabled={disabled}
        label={translate('Наименование головного иностранного юр. лица')}
        name="foreignOrganisation.foreignFullName"
      />
    </Grid>

    <Grid item xs={12}>
      <Input
        fullWidth
        required
        disabled={disabled}
        label={translate('Адрес')}
        name="foreignOrganisation.foreignAddress"
      />
    </Grid>
    <Grid item xs={12}>
      <Input
        fullWidth
        required
        disabled={disabled}
        label={translate('Код налогоплательщика в стране регистрации')}
        name="foreignOrganisation.foreignNalogCode"
      />
    </Grid>
    <Grid item xs={12}>
      <Input
        fullWidth
        required
        disabled={disabled}
        label={translate('Регистрационный номер в стране регистрации')}
        name="foreignOrganisation.foreignRegNumber"
      />
    </Grid>
  </Grid>
);

const mapStateToProps = (state) => ({
  disabled: getDisabledFields(state),
});

export const ForeignOrganisationInfoConnected = connect(mapStateToProps)(
  ForeignOrganisationInfo
);
