import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Typography } from '@crpt-ui/core';

import { Modal } from '../Modal/Modal';
import { Link } from '../Link/Link';

import { closeMilkNotificationDialog } from './ducks/MilkNotification.actions';
import { getOpenMilkNotificationDialog } from './ducks/MilkNotification.selectors';

const MilkNotificationDialog = ({ open, closeMilkNotificationDialog }) => {
  const onClose = useCallback(() => {
    window.localStorage.setItem('MilkNotificationDetached', 'true');

    closeMilkNotificationDialog();
  }, [closeMilkNotificationDialog]);

  return (
    <Modal
      maxWidth="md"
      open={open}
      title="Уважаемые участники оборота товаров!"
      useHeaderDivider={false}
      onClose={onClose}
    >
      <Typography
        style={{
          marginTop: 24,
        }}
        variant="body1"
      >
        С <b>20 января 2021 года</b> согласно Постановлению Правительства РФ
        от&nbsp;15.12.2020 №&nbsp;2099 «Об утверждении Правил маркировки
        молочной продукции средствами идентификации и особенностях внедрения
        государственной информационной системы мониторинга за оборотом товаров,
        подлежащих обязательной маркировке средствами идентификации, в отношении
        молочной продукции» начинается этап добровольной маркировки молочной
        продукции средствами идентификации и предоставления сведений о нанесении
        средств идентификации, вводе в оборот, обороте и выводе из оборота
        молочной продукции в государственную информационную систему мониторинга
        за оборотом товаров, подлежащих обязательной маркировке средствами
        идентификации.
      </Typography>
      <Typography
        style={{
          marginTop: 24,
        }}
        variant="body1"
      >
        Согласно Постановлению Правительства РФ от&nbsp;26.04.2019 №&nbsp;515 «О
        системе маркировки товаров средствами идентификации и прослеживаемости
        движения товаров» участники оборота товаров заключают с оператором:
      </Typography>
      <ul style={{ lineHeight: 1.5 }}>
        <li>
          <Link
            href="https://xn--80ajghhoc2aj1c8b.xn--p1ai/upload/%D0%A2%D0%B8%D0%BF%D0%BE%D0%B2%D0%B0%D1%8F%20%D1%84%D0%BE%D1%80%D0%BC%D0%B0%20%D0%B4%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%D0%B0%20%D0%BE%20%D0%BF%D0%BE%D0%B4%D0%BA%D0%BB%D1%8E%D1%87%D0%B5%D0%BD%D0%B8%D0%B8%20%D0%BA%20%D0%93%D0%98%D0%A1%20%D0%9C%D0%A2.pdf"
            router={false}
            target="_blank"
          >
            договор о подключении к информационной системе мониторинга в целях
            регистрации в информационной системе мониторинга
          </Link>
        </li>
        <li>
          <Link
            href="https://xn--80ajghhoc2aj1c8b.xn--p1ai/upload/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%20%D0%BD%D0%B0%20%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0%D0%BD%D0%B8%D0%B5%20%D1%83%D1%81%D0%BB%D1%83%D0%B3%20%D0%BF%D0%BE%20%D0%BF%D1%80%D0%B5%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8E%20%D0%BA%D0%BE%D0%B4%D0%BE%D0%B2%20%D0%BC%D0%B0%D1%80%D0%BA%D0%B8%D1%80%D0%BE%D0%B2%D0%BA%D0%B8.pdf"
            router={false}
            target="_blank"
          >
            договор на оказание услуг по предоставлению кодов маркировки
          </Link>
        </li>
        <li>
          <Link
            href="https://xn--80ajghhoc2aj1c8b.xn--p1ai/upload/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80,%20%D1%81%D0%BE%D0%B4%D0%B5%D1%80%D0%B6%D0%B0%D1%89%D0%B8%D0%B9%20%D1%83%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F%20%D0%BF%D1%80%D0%B5%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F%20%D1%83%D1%81%D1%82%D1%80%D0%BE%D0%B9%D1%81%D1%82%D0%B2%20%D1%80%D0%B5%D0%B3%D0%B8%D1%81%D1%82%D1%80%D0%B0%D1%86%D0%B8%D0%B8%20%D1%8D%D0%BC%D0%B8%D1%81%D1%81%D0%B8%D0%B8%20%D0%B8%20%D0%B8%D1%85%20%D1%80%D0%B5%D0%B3%D0%BB%D0%B0%D0%BC%D0%B5%D0%BD%D1%82%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%BE%D0%B1%D1%81%D0%BB%D1%83%D0%B6%D0%B8%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F.pdf"
            router={false}
            target="_blank"
          >
            договор, содержащий условия предоставления устройств регистрации
            эмиссии и их регламентного обслуживания. (типовые формы договоров
            утверждаются Министерством промышленности и торговли Российской
            Федерации)
          </Link>
        </li>
      </ul>

      <Typography
        style={{
          marginTop: 24,
        }}
        variant="body1"
      >
        С <b>20 января 2021 года</b> в личном кабинете будет запрошено повторное
        заполнение профиля участника оборота товаров (актуализация данных),
        который необходимо заполнить и сохранить. Только на основании
        заполненного профиля будут сформированы все вышеуказанные договоры.
        Подписание договоров осуществляется с помощью сертификата
        квалифицированной электронной подписи лица, имеющего право действовать
        без доверенности от имени юридического лица, указанного в едином
        государственном реестре юридических лиц или индивидуального
        предпринимателя, указанного в едином государственном реестре
        индивидуальных предпринимателей.
      </Typography>
      <Typography
        style={{
          marginTop: 24,
        }}
        variant="body1"
      >
        <b>Обратите внимание</b>: после 20 января 2021 года и до подписания
        договоров будет не доступна работа в государственной информационной
        системы мониторинга за оборотом товаров, как через графический, так и
        через программный интерфейсы, после подписания договоров доступ будет
        восстановлен.
      </Typography>

      <Typography
        style={{
          marginTop: 24,
        }}
        variant="body1"
      >
        Ознакомиться и подписать договоры можно в разделе Пользователь /{' '}
        <Link to="/documents">
          <span onClick={onClose}>Документы от Оператора</span>
        </Link>
        .
      </Typography>
    </Modal>
  );
};

MilkNotificationDialog.propTypes = {
  closeMilkNotificationDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  open: getOpenMilkNotificationDialog(state),
});

const mapDispatchToProps = {
  closeMilkNotificationDialog,
};

export const MilkNotificationDialogConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(MilkNotificationDialog);
