import React from 'react';

import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import PDFViewer from '@crpt/shared/components/PDFViewer';

import { ContentWrapper } from '../../../common_components/styled';
import { Preloader } from '../../../common_components/Preloader/Preloader';

import { TopPanel } from './TopPanel';

export const AgreementDocumentView = ({
  documentContent,
  documentTitle,
  isLoading,
  isLoadedPdf,
}) => {
  if (isLoading) {
    return (
      <ContentWrapper>
        <Preloader isOpen={isLoading} />
      </ContentWrapper>
    );
  }

  return (
    <>
      <Box pb={2}>
        <TopPanel />
      </Box>

      {isLoadedPdf && (
        <PDFViewer pdfData={documentContent} title={documentTitle} />
      )}
    </>
  );
};

AgreementDocumentView.propTypes = {
  onOpenSignatureModal: PropTypes.func.isRequired,
  onCloseSignatureModal: PropTypes.func.isRequired,
  documentContent: PropTypes.object.isRequired,
  documentInfo: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  documentTitle: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadedPdf: PropTypes.bool.isRequired,
  isOpenModalSignatureModal: PropTypes.bool.isRequired,
  isShowSignature: PropTypes.bool.isRequired,
};
