import React, { useEffect } from 'react';

import { Select } from '../../../_MaterialUi/Select/Select';
import { getClassName } from '../Field.utils';

import { Chip } from './Chip/Chip';
import { useStyles } from './MultiSelectAdapter.styled';
import { SelectItem } from './SelectItem/SelectItem';

/**
 * Material multi select
 * @param {PropsType} - Props for component
 * @returns {Node} - Multi select
 */
export const MultiSelectAdapter = ({
  onChange,
  name,
  label,
  defaultValue,
  textView,
  items,
  required,
  disabled,
  error,
  meta,
  input,
}) => {
  const [value, setValue] = React.useState(defaultValue);
  const isHiddenLabel = value.length > 0 && !textView;

  useEffect(() => {
    input.onChange(value);
  }, []);

  function handleChange(selected) {
    setValue(selected);

    if (input.onChange) {
      input.onChange(selected);
    }

    if (onChange) {
      onChange(selected, name);
    }
  }

  return (
    <Select
      multiple
      className={getClassName({ required, disabled, error, meta })}
      classes={useStyles()}
      defaultValue={defaultValue}
      label={isHiddenLabel ? '' : label}
      name={name}
      renderValue={(selected) => (
        <Chip items={items} selected={selected} textView={textView} />
      )}
      value={value}
      onChange={handleChange}
    >
      {items.map((item) => (
        <SelectItem
          items={items}
          key={`${item.id}_option`}
          title={item.title}
          value={item.id}
        />
      ))}
    </Select>
  );
};

MultiSelectAdapter.defaultProps = {
  isError: false,
  items: [],
  defaultValue: [],
  onChange: () => null,
};
