import { createSelector } from 'reselect';

import { getClosingDocumentState } from '../../ducks/ClosingDocument.selectors';

export const getViewClosingDocumentState = createSelector(
  [getClosingDocumentState],
  (state) => state.ViewClosingDocument
);

export const getClosingDocumentContent = createSelector(
  [getViewClosingDocumentState],
  (state) => state.documentContent
);

export const getLoadingContent = createSelector(
  [getViewClosingDocumentState],
  (state) => state.loading
);
