import React, { useEffect, useMemo, FC, ChangeEvent } from 'react';
import { Input as InputCRPT } from '@crpt-ui/core';
import FieldHoc from '../FieldHoc';

import type { FieldRenderProps } from 'react-final-form';

interface InputAdapterProps extends FieldRenderProps<string, HTMLInputElement> {
  keepValue?: boolean;
  accessor?: (value: string) => void;
  placeholder?: string;
  callBackOnValid?: (value: string) => void;
}

const InputAdapter: FC<InputAdapterProps> = ({
  input,
  meta,
  accessor,
  placeholder,
  keepValue,
  callBackOnValid,
  ...rest
}) => {
  const { value } = input;
  const preparedValue = accessor ? accessor(value) : value;

  useEffect(() => {
    if (!keepValue) {
      return () => {
        input.onChange('');
      };
    }
  }, []);

  const onChangeHandle = (event: ChangeEvent) => {
    input.onChange(event);
  };

  const error = useMemo(() => {
    if (meta.touched || meta.modified) {
      return Array.isArray(meta.error) ? meta.error[0] : meta.error;
    }

    return undefined;
  }, [meta.error, meta.touched, meta.modified]);

  useEffect(() => {
    if (
      !error &&
      preparedValue &&
      callBackOnValid &&
      (meta.touched, meta.modified)
    ) {
      callBackOnValid(preparedValue);
    }
  }, [error, preparedValue, callBackOnValid]);

  return (
    <InputCRPT
      {...input}
      error={error}
      label={placeholder}
      onChange={onChangeHandle}
      value={preparedValue}
      {...rest}
      //eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      autoComplete="off"
    />
  );
};

export default FieldHoc(InputAdapter);
