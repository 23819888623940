import React from 'react';

import arrayMutators from 'final-form-arrays';
import { isArray, isObject, cloneDeep, isNull, isUndefined } from 'lodash';

export const FormContext = React.createContext();

export const formMutators = {
  ...arrayMutators,

  setFieldDefault: ([name, value], state, { changeValue }) =>
    changeValue(state, name, (old) =>
      isUndefined(old) || isNull(old) ? value : old
    ),

  updateField: ([name, value], state, { changeValue }) =>
    changeValue(state, name, () => value),

  removeKeys: ([name, keys], state, { changeValue }) =>
    changeValue(state, name, (old) => {
      if (isArray(old)) {
        return old.filter((_c, index) => keys.indexOf(index) === -1);
      }

      if (isObject(old)) {
        const vals = cloneDeep(old);

        keys.forEach((key) => {
          delete vals[key];
        });

        return vals;
      }

      return [];
    }),

  removeKeysWith: ([name, keys, callback], state, { changeValue }) =>
    changeValue(state, name, (old = []) => {
      if (isArray(old)) {
        return callback(old.filter((_c, index) => keys.indexOf(index) === -1));
      }

      if (isObject(old)) {
        const vals = cloneDeep(old);

        keys.forEach((key) => {
          delete vals[key];
        });

        return callback(vals);
      }

      return [];
    }),

  duplicateKeys: ([name, keys], state, { changeValue }) =>
    changeValue(state, name, (old = []) => {
      const forDuplicate = old.filter(
        (_c, index) => keys.indexOf(index) !== -1
      );
      return [
        ...old,
        ...forDuplicate.map((c) => JSON.parse(JSON.stringify(c))),
      ];
    }),

  updateArray: ([name, ids, inList, outList], state, { changeValue }) =>
    changeValue(state, name, (old = []) => {
      const selected = isArray(ids) ? ids : [];
      const founded = old.filter((_c, index) => selected.indexOf(index) !== -1);
      const other = old.filter((_c, index) => selected.indexOf(index) === -1);

      return [
        ...founded.map((c) => ({ ...c, ...(inList && inList) })),
        ...other.map((c) => ({ ...c, ...(outList && outList) })),
      ];
    }),

  toggle: ([name, value], state, { changeValue }) =>
    changeValue(state, name, (old = []) => {
      if (old.indexOf(value) === -1) {
        return [...old, value];
      }
      const values = old.filter((e) => e !== value);
      if (!values.length) {
        return undefined;
      }
      return values;
    }),

  runMutator: ([cb], state, callbacks) => cb(state, callbacks),
};
