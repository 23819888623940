import type { RouteProps } from 'react-router-dom';
import { WithStyles } from '@material-ui/core';
import { styles } from './Notification.styles';

export enum NotificationType {
  Banner = 'BANNER',
  ParkedPages = 'PARKED_PAGES',
  Notification = 'NOTIFICATION',
}

export enum BannersType {
  Disposable = 'DISPOSABLE',
  Reusable = 'REUSABLE',
}

export type Notification = {
  id: number,
  notificationType: NotificationType,
  bannerType?: BannersType,
  productGroups: string[],
  urls: string[],
  title: string,
  description?: string,
  activated: boolean,
  scheduled: boolean,
  scheduledWorkingPeriodStart?: Date,
  scheduledWorkingPeriodEnd?: Date,
  lastWorkingPeriodStart?: Date,
  lastWorkingPeriodEnd?: Date
}

export type NotificationProps = Pick<
  Notification,
  'notificationType' | 'title' | 'bannerType' | 'description' | 'id'
> &
  WithStyles<typeof styles> &
  Pick<RouteProps, 'path'>;
