import { createActions } from 'redux-actions';

export const {
  fetchAgreementDocuments,
  setAgreementDocuments,
  clearAgreementDocuments,
  startSignAgreementDocumentFromList,
  startDeclineAgreementDocument,
  setSortAgreementDocuments,
  clearSortAgreementDocuments,
  setActivePageAgreementDocuments,
} = createActions(
  {
    FETCH_AGREEMENT_DOCUMENTS: (payload) => payload,
    SET_AGREEMENT_DOCUMENTS: (payload) => payload,
    CLEAR_AGREEMENT_DOCUMENTS: () => {},

    START_SIGN_AGREEMENT_DOCUMENT_FROM_LIST: (payload) => payload,
    START_DECLINE_AGREEMENT_DOCUMENT: (payload) => payload,

    SET_SORT_AGREEMENT_DOCUMENTS: (payload) => payload,
    CLEAR_SORT_AGREEMENT_DOCUMENTS: (payload) => payload,
    SET_ACTIVE_PAGE_AGREEMENT_DOCUMENTS: (payload) => payload,
  },
  {
    prefix: 'AGREEMENT_DOCUMENTS',
  }
);
