import { makeStyles } from '@material-ui/core';

export const usePaginationStyles = makeStyles(() => ({
  pagesText: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 32 / 12,
  },
  countPages: {
    marginLeft: 4,
  },
}));
