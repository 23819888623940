/* eslint-disable */
import { yup } from '@crpt-ui/form';

import { RUSSIA_COUNTRY_CODE } from 'components/_Profile/Production/Production.constants';

export const schema = yup.object({
  productionPlaceName: yup.string().required(),
  productionCountryNumericCode: yup.string().required(),
  productionAddress: yup.string().required(),
  // productionAddress: yup.string().when('productionCountryNumericCode', {
  //   is: (val) => val === RUSSIA_COUNTRY_CODE,
  //   then: yup.string().required(),
  // }),
  // productionKpp: yup.string().required(),
  productionLines: yup.array(),
});
