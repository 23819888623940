import {
  DatePicker,
  DatePickerProps as DatePickerBaseProps,
} from '@material-ui/pickers';
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
import React from 'react';

import DatepickerToolbar from './DatepickerToolbar';
import { renderDay } from './utils';

export type DatepickerProps = {
  toolbarLabelFormat?: string;
} & DatePickerBaseProps;

const Datepicker: React.FC<DatepickerProps> = ({
  maxDate,
  minDate,
  toolbarLabelFormat,
  ...rest
}) => (
  <DatePicker
    ToolbarComponent={(props) => (
      <DatepickerToolbar
        {...props}
        maxDate={maxDate}
        minDate={minDate}
        toolbarLabelFormat={toolbarLabelFormat}
      />
    )}
    renderDay={renderDay}
    maxDate={maxDate}
    minDate={minDate}
    {...rest}
  />
);

Datepicker.defaultProps = {
  ...datePickerDefaultProps,
  invalidDateMessage: 'Неверный формат даты',
  maxDateMessage: 'Дата не должна быть позже максимальной даты',
  minDateMessage: 'Дата не должна быть раньше минимальной даты',
  variant: 'static',
};

export default Datepicker;
