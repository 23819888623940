import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { Icon } from '@crpt-ui/core';

import { useErrorClasses } from './Info.styles';

export const ErrorInfo = ({ error }) => {
  const classes = useErrorClasses();

  const { documentType, documentNumber, textError } = error;

  return (
    <Grid item className={classes.root}>
      <Grid className={classes.iconWrap}>
        <Icon className={classes.icon} name="Error" />
      </Grid>
      {textError}{' '}
      {Boolean(documentType) && Boolean(documentNumber) && (
        <Fragment>
          Документ {documentType} {documentNumber} не будет отправлен.
        </Fragment>
      )}
    </Grid>
  );
};

ErrorInfo.propTypes = {
  error: PropTypes.shape({
    textError: PropTypes.string,
    documentType: PropTypes.string,
    documentNumber: PropTypes.string,
  }).isRequired,
};
