import React, { Fragment } from 'react';

import { Box, Grid } from '@material-ui/core';
import * as _ from 'lodash';

import Datatable from '@crpt-ui/datatable';
import { NoInfo } from '@crpt/shared/components';
import { Button, Icon, Select, Typography } from '@crpt-ui/core';

import { Loader } from '../../../common_components/Preloader/Preloader';
import { ContentWrapper } from '../../../common_components/styled';
import { Currency } from '../../../common_components/Currency/Currency';

import { CreatePaymentDocumentConnected } from './CreatePaymentDocument/CreatePaymentDocument';
import * as Styled from './PaymentDocumentsList.styled';

export const PaymentDocumentsView = React.memo(
  ({
    onAccountChange,
    onOpenCreatePaymentModal,
    accountOptions,
    selectedAccount,
    selectedAccountBalance,
    columns,
    documents,
    loading,
  }) => (
    <Fragment>
      <CreatePaymentDocumentConnected />

      <ContentWrapper
        style={{
          padding: '16px 0 0',
          height: 'calc(100% - 70px)',
        }}
      >
        <Box mb={2} mx={4}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Button
                className="whiteColor"
                endIcon={<Icon name="Add" />}
                onClick={onOpenCreatePaymentModal}
              >
                Получить счет
              </Button>
            </Grid>
            {accountOptions.length !== 0 && (
              <Fragment>
                <Grid
                  item
                  style={{
                    marginLeft: 'auto',
                  }}
                >
                  {!_.isUndefined(selectedAccountBalance) ? (
                    <Styled.Balance>
                      Баланс:&nbsp;
                      <Currency symbolCurrency>
                        {selectedAccountBalance}
                      </Currency>
                    </Styled.Balance>
                  ) : null}
                </Grid>
                <Grid item>
                  <Select
                    disabled={accountOptions.length <= 1}
                    label="Номер лицевого счета"
                    options={accountOptions}
                    style={{
                      width: 290,
                    }}
                    value={selectedAccount}
                    onChange={onAccountChange}
                  />
                </Grid>
              </Fragment>
            )}
          </Grid>
        </Box>

        {loading ? (
          <Loader isOpen={loading} />
        ) : documents.length ? (
          <Fragment>
            <Datatable columns={columns} data={documents} />
          </Fragment>
        ) : (
          <NoInfo>
            <Typography variant="h5">У&nbsp;вас еще нет документов</Typography>
          </NoInfo>
        )}
      </ContentWrapper>
    </Fragment>
  )
);
