import { b64DecodeUnicode, localStorageHelpers } from './localStorageHelpers';
import { getCookie } from './cookieHelpers';

export function isTokenValidAndNotExpired(token) {
  if (!token) {
    return false;
  }
  let exp;

  try {
    const data = token.split('.')[1];
    const decoded = b64DecodeUnicode(data);
    exp = JSON.parse(decoded).exp;
  } catch (err) {
    return false;
  }

  return !(exp && exp < new Date().getTime() / 1000);
}

export function getToken() {
  const count = getCookie('count') || 0;
  const token = new Array(+count)
    .fill(1)
    .map((_v, i) => getCookie(`tokenPart${i + 1}`))
    .join('');
  return count ? token : null;
}

export function getUserFromToken(token) {
  const user = {};
  const roles = localStorageHelpers.getRolesFromToken(token);
  Object.assign(user, roles);
  return user;
}
