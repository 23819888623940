import React, { Fragment } from 'react';

// import { ReceiptsDialogConnected } from 'common_components/ReceiptsDialog/ReceiptsDialog';
import { SignDocuments } from '../../../components/_ServiceDesk/SignDocuments/SignDocuments';
import { PaymentDialogConnected } from '../../PaymentDialog/PaymentDialog';
import { MilkNotificationDialogConnected } from '../../MilkNotification/MilkNotification';

export const Modals = () => (
  <Fragment>
    <SignDocuments />
    {/*<ReceiptsDialogConnected />*/}
    <PaymentDialogConnected />
    <MilkNotificationDialogConnected />
  </Fragment>
);
