import React, { useEffect } from 'react';

import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { StripeLayout } from '../../common_components/Layouts';
import { Preloader } from '../../common_components/Preloader/Preloader';

import { StartPageTabs } from './StartPage.constants';
import { fetchStartPageData } from './ducks/StartPage.actions';

type StartPageProps = {
  activeStep?: number;
  isLoading?: boolean;
};

export const StartPage = ({ activeStep = 0, isLoading }: StartPageProps) => {
  const ComponentStep = StartPageTabs[activeStep].component;
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(fetchStartPageData());
  }, []);

  return (
    <StripeLayout title="Дополнительная информация">
      <StripeLayout.Content>
        {isLoading ? (
          <Preloader isOpen text={t('Загрузка')} />
        ) : (
          <ComponentStep />
        )}
      </StripeLayout.Content>
    </StripeLayout>
  );
};

export const mapStateToProps = (state: any) => ({
  activeStep: state.StartPage.activeStep,
  isLoading: state.StartPage.IsLoading,
});

export const StartPageConnected = connect(mapStateToProps)(StartPage);
