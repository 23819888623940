import { createActions } from 'redux-actions';

export const {
  openUploadNotificationOperatorDialog,
  closeUploadNotificationOperatorDialog,
  sendNotificationOperatorDocument,
  setSubmitting,
  clearUploadNotificationOperatorDocuments,
} = createActions(
  {
    OPEN_UPLOAD_NOTIFICATION_OPERATOR_DIALOG: (payload) => payload,
    CLOSE_UPLOAD_NOTIFICATION_OPERATOR_DIALOG: () => {},

    SEND_NOTIFICATION_OPERATOR_DOCUMENT: (payload) => payload,
    SET_SUBMITTING: (payload) => payload,

    CLEAR_UPLOAD_NOTIFICATION_OPERATOR_DOCUMENTS: () => {},
  },
  {
    prefix: 'UPLOAD_NOTIFICATION_OPERATOR',
  }
);
