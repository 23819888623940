import { call, put, takeEvery } from 'redux-saga/effects';
import { defaultTo, get, isUndefined } from 'lodash';

import crypto, { Base64 } from '@crpt-ui/crypto';
import { toast } from '@crpt/shared/toast';

import { HttpMethodEnum } from '../../../constants/index';
import { Api } from '../../../common_components/Api/Api';
import { CadesUtil } from '../../../utils/CadesUtil';
import { formatPhoneNumber } from '../../_Profile/Company/company-utils';

import {
  errors,
  setCompleteStep,
  setDocumentId,
  setLoading,
  submit,
} from './Registration.actions';

function* errorSaga(action) {
  const message = yield call(
    get,
    action,
    'response.data.error_message',
    'unknown server error'
  );
  yield put(errors(message));
}

function prepareOrganisationName(certificate, formValues) {
  if (
    isUndefined(formValues.organisation_name) ||
    formValues.organisation_name === ''
  ) {
    const organisationName = get(certificate, 'certificate.subject.O');
    const commonName = get(certificate, 'certificate.subject.CN', '');

    return defaultTo(organisationName, commonName).replace(/""/g, '"');
  }

  return formValues.organisation_name;
}

function* successSaga({ payload }) {
  yield put(setLoading(true));

  const certificate = get(payload, 'cert');
  const organisationName = yield call(
    prepareOrganisationName,
    certificate,
    payload
  );

  const product_document = {
    cert_number: get(certificate, 'serial', ''),
    cert_valid_to: get(certificate, 'validTo', ''),
    email: get(payload, 'email', ''),
    phone: formatPhoneNumber(get(payload, 'phone', '')),
    inn:
      get(certificate, 'inn', null) ||
      get(certificate, 'certificate.subject.SERIALNUMBER', null),
    organisation_name: organisationName,
    organisation_type: 'TRADE_PARTICIPANT',
    trust_center_name: get(certificate, 'certificate.issuer.CN', ''),
    user_name: get(certificate, 'fullName', ''),
    fingerprint: get(certificate, 'thumbprint', ''),
  };

  const convertedData = yield call(
    Base64.encode,
    JSON.stringify(product_document)
  );

  try {
    const signature = yield call(
      crypto.sign,
      convertedData,
      certificate.certificate,
      true
    );

    const requestPayload = {
      timeout: 60000,
      url: '/api/v3/facade/registration/send',
      method: HttpMethodEnum.POST,
      headers: {
        Authorization: null,
      },
      data: {
        document_format: 'MANUAL',
        product_document: convertedData,
        signature,
      },
    };

    const [success, error] = yield call(Api.request, requestPayload, {
      showBackendMessage: true,
      withoutDescriptionOfError: true,
    });

    if (success) {
      yield put(
        setDocumentId(get(success, 'data.registrationRequestDocId', 0))
      );
      yield put(setCompleteStep());
    }

    if (error) {
      yield call(errorSaga, error);
    }
  } catch (error) {
    const errorContent = CadesUtil.prepareError(error);

    if (errorContent) {
      yield call(toast.error, errorContent);
    }
  } finally {
    yield put(setLoading(false));
  }
}

function* registrationSaga(action) {
  const requestPayload = {
    timeout: 60000,
    url: '/api/v3/auth/cert/key',
    headers: {
      Authorization: null,
    },
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    yield call(successSaga, action);
  }

  if (error) {
    yield call(errorSaga, error);
  }
}

export const saga = function* watch() {
  yield takeEvery(submit, registrationSaga);
};
