import React from 'react';

import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

export const SkeletonLoader = () => (
  <Grid container justify="space-between">
    <Grid item>
      <Grid container spacing={2}>
        <Grid item>
          <Skeleton height={48} variant="rect" width={200} />
        </Grid>
        <Grid item>
          <Skeleton height={48} variant="rect" width={200} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <Skeleton height={48} variant="rect" width={200} />
    </Grid>
  </Grid>
);
