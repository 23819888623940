import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Input } from '../../../../../common_components/Form/Input';
import { Select } from '../../../../../common_components/Form/Select';

export const SignerAuthorityView = ({ authorityOptions, statusOptions }) => (
  <Grid
    container
    direction="column"
    spacing={2}
    style={{
      maxWidth: '710px',
    }}
  >
    <Grid item>
      <Typography variant="h5">Полномочия подписанта</Typography>
    </Grid>

    <Grid item>
      <Select
        required
        label="Область полномочий"
        name="authority"
        optionLabel="label"
        options={authorityOptions}
      />
    </Grid>

    <Grid item>
      <Select
        required
        label="Статус"
        name="status"
        optionLabel="label"
        options={statusOptions}
      />
    </Grid>

    <Grid item>
      <Input
        required
        defaultValue="Должностные обязанности"
        label="Основания полномочий (доверия)"
        name="grounds"
      />
    </Grid>

    <Grid item>
      <Input
        label="Основания полномочий (доверия) орг."
        name="organization_grounds"
      />
    </Grid>
  </Grid>
);

SignerAuthorityView.propTypes = {
  values: PropTypes.object.isRequired,
  statusOptions: PropTypes.array.isRequired,
  isRequiredOrganizationGrounds: PropTypes.bool.isRequired,
};
