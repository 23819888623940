import { createStyles, Theme } from '@material-ui/core';

const drawerWidth = 216;
const headerHeight = 80;
const threadsMargin = 32;

export default ({ spacing, transitions }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    mainContent: {
      width: '100%',
      transition: transitions.create("width", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen
      }),
    },
    mainContentShift: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    drawer: {
      width: 0,
      transition: transitions.create('width', {
        easing: transitions.easing.easeOut,
        duration: transitions.duration.enteringScreen,
      }),
    },
    drawerPaper: {
      width: '100%',
      position: 'relative',
      zIndex: 'unset',
      overflowY: 'unset',
      borderRadius: 'unset',
    },
    drawerGrowed: {
      width: drawerWidth,
    },
    topPanel: {
      alignItems: 'center',
      display: 'flex',
      height: headerHeight,
      paddingTop: spacing(2),
      paddingRight: spacing(4),
      paddingBottom: spacing(2),
      paddingLeft: spacing(4),
    },
    threads: {
      margin: threadsMargin,
      height: `calc(100% - ${headerHeight + threadsMargin * 2}px)`,
      overflowX: 'auto',
      overflowY: 'auto',
      transition: transitions.create("margin", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen
      }),
    },
    threadsShrinked: {
      marginRight: 0,
    }
  });
