import { call, put, select, takeEvery } from 'redux-saga/effects';

import { HttpMethodEnum } from '../../../../../constants/index';
import { getUserPid } from '../../../../../common_components/Auth/ducks/Auth.selectors';
import { Api } from '../../../../../common_components/Api/Api';
import { openPaymentDocumentSuccessModal } from '../CreatePaymentDocumentSuccess/ducks/CreatePaymentDocumentSuccess.actions';

import {
  closeCreatePaymentModal,
  openCreatePaymentModal,
  openCreatePaymentModalWithId,
  sendPaymentDocument,
  setLoading,
  setSelectedAccount,
} from './CreatePaymentDocument.actions';

function* sendPaymentDocumentSaga({ payload }) {
  yield put(setLoading(true));

  const participantId = yield select(getUserPid);

  const [success] = yield call(
    Api.request,
    {
      url: '/crm-api/prequests',
      method: HttpMethodEnum.POST,
      data: {
        ...payload.values,
        participant_id: participantId,
      },
    },
    {
      preloading: false,
    }
  );

  if (success) {
    yield put(closeCreatePaymentModal());
    yield put(openPaymentDocumentSuccessModal());
  }

  yield put(setLoading(false));
}

function* openCreatePaymentModalWithIdSaga({ payload: accountId }) {
  yield put(setSelectedAccount(accountId));
  yield put(openCreatePaymentModal());
}

export const saga = function* watch() {
  yield takeEvery(
    openCreatePaymentModalWithId,
    openCreatePaymentModalWithIdSaga
  );
  yield takeEvery(sendPaymentDocument, sendPaymentDocumentSaga);
};
