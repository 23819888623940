import { createSelector } from 'reselect';

export const getAgreementDocumentsListBase = (state) =>
  state.Documents.AgreementDocumentsList;

export const getAgreementDocumentsList = createSelector(
  [getAgreementDocumentsListBase],
  (agreementDocumentsListBase) => agreementDocumentsListBase.data
);

export const getAgreementDocumentsSort = createSelector(
  [getAgreementDocumentsListBase],
  (agreementDocumentsListBase) => agreementDocumentsListBase.sort
);

export const getAgreementDocumentsSortInitial = createSelector(
  [getAgreementDocumentsSort],
  (agreementDocumentsSort) => ({
    sortBy: [
      {
        id: agreementDocumentsSort.sort,
        desc: agreementDocumentsSort.direction === 'DESC',
      },
    ],
  })
);

export const getAgreementDocumentsActivePage = createSelector(
  [getAgreementDocumentsListBase],
  (agreementDocumentsListBase) => agreementDocumentsListBase.activePage
);

export const getAgreementDocumentsOffset = createSelector(
  [getAgreementDocumentsActivePage],
  (activePage) => {
    const NUMBER_ITEMS_ON_PAGE = 10;

    return (activePage - 1) * NUMBER_ITEMS_ON_PAGE;
  }
);
