import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, BoxProps, Button, Throbber } from '@crpt/material';

export interface LoadMorePanelProps extends BoxProps {
  isLoading?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  onLoadMore: () => void;
  autoLoadMore?: boolean;
  loadMoreLabel?: string;
}

export function LoadMorePanel(props: LoadMorePanelProps) {
  const {
    disabled,
    hidden,
    onLoadMore,
    isLoading,
    autoLoadMore,
    loadMoreLabel = 'Показать еще',
    ...boxProps
  } = props;

  const timeoutId = useRef<NodeJS.Timeout>();
  const [isScrolling, setScrolling] = useState(false);

  const onScroll = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    if (!isScrolling) {
      setScrolling(true);
    }
    timeoutId.current = setTimeout(() => {
      setScrolling(false);
    }, 300);
  }, [timeoutId, isScrolling]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  useEffect(() => {
    if (isScrolling || isLoading || hidden || !autoLoadMore) {
      return;
    }

    const { height } = document.body.getBoundingClientRect();

    if (window.scrollY + document.documentElement.clientHeight > height - 100) {
      onLoadMore?.();
    }
  }, [autoLoadMore, hidden, isLoading, isScrolling, onLoadMore]);

  return (
    !hidden && (
      <Box {...boxProps}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '24px',
          }}
        >
          {isLoading ? (
            <Throbber variant="blue" height="56px" size="large" />
          ) : (
            <Box height="56px">
              {!isScrolling && !autoLoadMore && (
                <Button
                  color="secondary"
                  disabled={disabled}
                  onClick={onLoadMore}
                >
                  {loadMoreLabel}
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
    )
  );
}
