import React, { Fragment } from 'react';

import { connect } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Typography, Checkbox } from '@crpt-ui/core';

import { SignatureDescriptionConnected } from '../../../../common_components/SignatureDescription/SignatureDescription';
import { getSignDocumentsInfo } from '../ducks/SignDocuments.selectors';
import * as selectors from '../ducks/SignDocuments.selectors';

import { DownloadDocuments } from './DownloadDocuments';
import { ErrorInfo } from './ErrorInfo';

const SignDocumentsInfo = ({
  checked,
  error,
  onDownloadAll,
  onChangeCheckbox,
  signDocumentsInfo,
}) => (
  <Grid container direction="column">
    <Box mb={4}>
      <Typography variant="h4">
        <div>Договоры с&nbsp;оператором&nbsp;на&nbsp;товарную&nbsp;группу</div>
        <div>«{signDocumentsInfo.name}»</div>
      </Typography>
    </Box>

    {error && <ErrorInfo error={error} />}

    <Box mb={4}>
      <Typography variant="body1">
        Для подключения или возобновления доступа к&nbsp;Государственной
        информационной системе мониторинга за&nbsp;оборотом товаров, подлежащих
        обязательной маркировке средствами идентификации, необходимо подписать
        договоры усиленной квалифицированной электронной подписью юридического
        лица или индивидуального предпринимателя от&nbsp;лица, имеющего право
        действовать без доверенности, указанного в&nbsp;ЕГРЮЛ, ЕГРИП.
      </Typography>
    </Box>

    <DownloadDocuments onClick={onDownloadAll} />

    <Box mx={-4} my={4}>
      <SignatureDescriptionConnected />
    </Box>

    <Grid item>
      <Checkbox
        checked={checked}
        label={
          <Fragment>
            Согласен(на) с&nbsp;условиями договоров и&nbsp;заявлений.
          </Fragment>
        }
        onChange={onChangeCheckbox}
      />
    </Grid>
  </Grid>
);

SignDocumentsInfo.propTypes = {
  signDocumentsInfo: PropTypes.shape({ name: PropTypes.string }).isRequired,
  error: PropTypes.shape({
    textError: PropTypes.string,
    documentType: PropTypes.string,
    documentNumber: PropTypes.string,
  }).isRequired,
  onDownloadAll: PropTypes.func.isRequired,
  onChangeCheckbox: PropTypes.func.isRequired,
  ownerError: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  error: selectors.error(state),
  signDocumentsInfo: getSignDocumentsInfo(state),
});

export const SignDocumentsInfoConnected =
  connect(mapStateToProps)(SignDocumentsInfo);
