import { createActions } from 'redux-actions';

export const {
  setLoading,
  openManualModeModal,
  closeManualModeModal,
  clearManualModeModal,
  fetchParticipantsAutocomplete,
  setAutocompleteList,
  setLoadingAutocomplete,
  sendParticipants,
} = createActions(
  {
    SET_LOADING: (payload) => payload,

    OPEN_MANUAL_MODE_MODAL: () => {},
    CLOSE_MANUAL_MODE_MODAL: () => {},
    CLEAR_MANUAL_MODE_MODAL: () => {},

    FETCH_PARTICIPANTS_AUTOCOMPLETE: (payload) => payload,
    SET_AUTOCOMPLETE_LIST: (payload) => payload,
    SET_LOADING_AUTOCOMPLETE: (payload) => payload,

    SEND_PARTICIPANTS: (payload) => payload,
  },
  {
    prefix: 'MANUAL_AGREEMENT_DOCUMENT',
  }
);
