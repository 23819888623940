import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { zoomParameters } from './PDFPreview.constants';
import { PDFError } from './PDFError/PDFError';
import { PDFPreviewView } from './PDFPreview.view';

export class PDFPreview extends Component {
  static propTypes = {
    pdfData: PropTypes.shape({}).isRequired,
    error: PropTypes.bool.isRequired,
    onError: PropTypes.func.isRequired,
  };

  state = {
    page: 1,
    pages: 1,
    pdfData: [],
    zoomRatio: this.props.zoomRatio,
  };

  memoPdfData = undefined;

  isPreviewerMounted = false;

  componentDidMount() {
    this.onUpdate();
  }

  componentDidUpdate(prevProps) {
    const { pdfData } = this.props;
    if (prevProps.pdfData === pdfData) {
      return;
    }
    this.onUpdate();
  }

  onUpdate = () => {
    const { pdfData } = this.props;

    this.memoPdfData = pdfData;
    if (this.isPreviewerMounted) {
      this.setState({ pdfData: [] });
    } else {
      this.loadMemoToState();
    }
  };

  onWillUnmount = () => {
    this.loadMemoToState();
  };

  loadMemoToState = () => {
    const pdfData = this.memoPdfData;

    this.setState({ pdfData, page: 1 });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ pages: numPages });
  };

  onPageIncrement = () => {
    const { page, pages } = this.state;

    if (page === pages) {
      return;
    }

    this.setState({
      page: page + 1,
    });
  };

  onPageDecrement = () => {
    const { page } = this.state;

    if (page === 1) {
      return;
    }

    this.setState({
      page: page - 1,
    });
  };

  onZoomIn = () => {
    const { zoomRatio } = this.state;
    if (zoomRatio >= zoomParameters.maxRatio) {
      return;
    }

    let updatedZoomValue = zoomRatio * zoomParameters.changeRatio;
    if (updatedZoomValue > zoomParameters.maxRatio) {
      updatedZoomValue = zoomParameters.maxRatio;
    }
    this.setState({ zoomRatio: updatedZoomValue });
  };

  onZoomOut = () => {
    const { zoomRatio } = this.state;
    if (zoomRatio <= zoomParameters.minRatio) {
      return;
    }

    let updatedZoomValue = zoomRatio / zoomParameters.changeRatio;
    if (updatedZoomValue < zoomParameters.minRatio) {
      updatedZoomValue = zoomParameters.minRatio;
    }
    this.setState({ zoomRatio: updatedZoomValue });
  };

  render() {
    const { error, onError, size } = this.props;
    const { page, pdfData, zoomRatio, pages } = this.state;

    if (error) {
      this.isPreviewerMounted = false;
      return <PDFError />;
    }

    if (pdfData.length === 0) {
      this.isPreviewerMounted = false;
      return null;
    }
    this.isPreviewerMounted = true;

    return (
      <PDFPreviewView
        error={error}
        fileData={pdfData}
        page={page}
        pages={pages}
        size={size}
        zoomRatio={zoomRatio}
        onLoadError={onError}
        onLoadSuccess={this.onDocumentLoadSuccess}
        onPageDecrement={this.onPageDecrement}
        onPageIncrement={this.onPageIncrement}
        onWillUnmount={this.onWillUnmount}
        onZoomIn={this.onZoomIn}
        onZoomOut={this.onZoomOut}
      />
    );
  }
}

PDFPreview.defaultProps = {
  zoomRatio: 1.5,
};
