import React, { memo } from 'react';
import { Typography } from '@crpt-ui/core';
import { Box } from '@material-ui/core';

type ErrorProps = {
  title?: string;
  advice?: string;
  icon?: React.ReactNode;
};

const Banner: React.FC<ErrorProps> = ({ title, advice, icon }) => {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >
      {icon && <Box mb={4}>{icon}</Box>}
      {title && (
        <Box mb={2}>
          <Typography variant="h4">{title}</Typography>
        </Box>
      )}
      {advice && (
        <Box>
          <Typography variant="body1">{advice}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default memo(Banner);
