const sortByAlphabeticalOrder = (a, b) => {
  const nameA = a.label.toLowerCase();
  const nameB = b.label.toLowerCase();

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

export const DOCUMENT_TEMPLATES_LIST = [
  {
    label: 'Уведомление о расторжении договора',
    list: [
      {
        label: 'Уведомление о расторжении договора - двустороннее.docx',
      },
      {
        label: 'Уведомление о расторжении договора - одностороннее.docx',
      },
    ],
  },
  {
    label: 'Доверенность',
    list: [
      {
        label: 'ГИС МТ ДОВЕРЕННОСТЬ_март 2021.docx',
      },
    ],
  },
  {
    label: 'Уведомление об изменениях в реквизитах',
    list: [
      {
        label:
          'Общий шаблон уведомления об изменении реквизитов для резидентов.docx',
      },
    ],
  },
  {
    label: 'Запрос на изменение данных в ГИС',
    list: [
      {
        label: 'Заявление на аннулирование документа.docx',
      },
      {
        label: 'Заявление на внесение изменений в ранее поданные сведения.docx',
      },
      {
        label: 'Заявление на изменение способа ввода товаров в оборот.docx',
      },
      {
        label: 'Заявление на изменение статуса кодов идентификации.docx',
      },
    ],
  },
]
  .sort(sortByAlphabeticalOrder)
  .map((item) => {
    item.list.sort(sortByAlphabeticalOrder);

    return item;
  });
