import './axiosDefaults';
import './index.css';
import './polyfills';
import '@ibox/ui/src/global.scss';
import '@crpt-fonts/segoe-ui';

import React from 'react';

import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import { Router } from 'react-router-dom';

import './locale/index';
import { App } from './App';
import { unregister } from './registerServiceWorker';
import { history, store } from './store';

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter context={ReactReduxContext} history={history}>
      <Router history={history}>
        <App />
      </Router>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

unregister();
