import React from 'react';

import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';

import { Icon } from '@crpt-ui/core';

import { Item, ItemText } from './SelectItem.styled';

/**
 * Option for multi select
 */
export const SelectItem = ({ title, value, onClick, selected }) => (
  <Item value={value} onClick={onClick}>
    <Checkbox
      checked={selected}
      checkedIcon={<Icon name="CheckboxChecked" />}
      icon={<Icon name="Checkbox" />}
    />
    <ItemText>{title}</ItemText>
  </Item>
);

SelectItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};
