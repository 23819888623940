import React from 'react';

import NumberFormat, { NumberFormatProps } from 'react-number-format';

export const PhoneNumberInput: NumberFormatProps = ({
  inputRef,
  onChange,
  ...restProps
}) => (
  <NumberFormat
    {...restProps}
    format="+374 (##) ##-##-##"
    getInputRef={inputRef}
    mask="_"
    thousandSeparator=" "
    type="tel"
    onValueChange={(values) => {
      onChange({
        target: {
          value: values.value,
        },
      });
    }}
  />
);
