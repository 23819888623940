import React, { FC, memo } from 'react';
import { Button, Icon } from '@crpt-ui/core';
import { makeStyles } from '@material-ui/core';
import { Box, Grid } from '@crpt/material';
import { Preloader } from '@crpt/shared/components';
import { largeDash } from '@crpt/shared/constants';
import EditInnsViewModel from './EditInns.viewmodel';

interface EditInnsProps {
  fieldName: string;
  inns: string[];
  formChange: (name: string, value?: string | undefined) => void;
  onClose: () => void;
  title: string;
}

const EditInns: FC<EditInnsProps> = ({ title, ...restProps }) => {
  const classesRemoveAllButton = useRemoveAllButtonStyles();
  const vm = EditInnsViewModel(restProps);

  return (
    <div>
      <Box mb={4}>
        <Grid columnSpacing={1} container>
          <Grid
            item
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
            xs={5}
          >
            <Box
              sx={{
                fontSize: 20,
                fontWeight: 500,
              }}
            >
              {title}
            </Box>
          </Grid>
          <Grid
            item
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
            xs={6}
          >
            <Box
              sx={{
                color: 'grey.500',
                fontSize: 14,
              }}
            >
              {vm.t('ORGANIZATION', {
                count: vm.organizations.length,
              })}
            </Box>
          </Grid>
          <Grid
            item
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
            xs={1}
          >
            <Button
              icon={<Icon name="close" />}
              onClick={restProps.onClose}
              style={{
                height: 'auto',
              }}
              variant="text"
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          position: 'relative',
        }}
      >
        <Preloader isOpen={vm.isLoading} />
        {vm.organizations.map((organization, index) => {
          return (
            <Box key={index} mb={4}>
              <Grid columnSpacing={1} container>
                <Grid item xs={5}>
                  {organization.inn}
                </Grid>
                <Grid item xs={6}>
                  {organization.name ?? largeDash}
                </Grid>
                <Grid item xs={1}>
                  <Button
                    color="error"
                    icon={<Icon name="trash" />}
                    onClick={vm.removeOrganization(index)}
                    style={{
                      height: 'auto',
                    }}
                    variant="text"
                  />
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          backgroundColor: 'grey.600',
          color: 'white',
          mb: -4,
          px: 4,
          mx: -4,
          py: 2,
        }}
      >
        <Button
          classes={classesRemoveAllButton}
          disabled={vm.isLoading}
          onClick={vm.removeAllOrganizations}
          variant="outlined"
        >
          {vm.t('removeAll')}
        </Button>
      </Box>
    </div>
  );
};

export default memo(EditInns);

const useRemoveAllButtonStyles = makeStyles(() => ({
  root: {
    fontSize: '14px !important',
    textTransform: 'none',
    fontWeight: 500,
    height: '40px !important',
    width: 200,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));
