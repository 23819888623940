import { handleActions } from 'redux-actions';

import {
  closeUploadModeModal,
  openUploadModeModal,
  setLoading,
} from './UploadAgreementDocument.actions';

const open = handleActions(
  {
    [openUploadModeModal]: () => true,
    [closeUploadModeModal]: () => false,
  },
  false
);

const loading = handleActions(
  {
    [setLoading]: (_, { payload }) => payload,
  },
  false
);

export const reducer = {
  open,
  loading,
};
