import { call, put, takeEvery } from 'redux-saga/effects';
import * as _ from 'lodash';

import { Api } from '../../../common_components/Api/Api';
import { ParticipantService } from '../../../services/ParticipantService';
import { history } from '../../../store.js';
import { parseEditDto, parseParticipantData } from '../utils';

import * as actions from './ParticipantDetails.actions';
import {
  setChangeStatusProductGroup,
  setChangeStatusUOT,
  setUpdated,
  setUpdateUot,
} from './ParticipantDetails.actions';

function* mountSaga({ payload }) {
  try {
    const response = yield call(
      ParticipantService.getParticipantInfoByInn,
      payload
    );
    yield put(actions.loaded(parseParticipantData(response.data)));
  } catch (e) {
    console.error('e', e);
    yield put(actions.loadingEnd());
    if (document.referrer) {
      history.push(document.referrer.replace(/^.*?:\/\/.*?(?=\/|$)/, ''));
    } else {
      history.goBack();
    }
  }
}

function* acceptAndCancelAndRefundRegistrationSaga({ payload }) {
  const { org_id, mode } = payload;
  const requestPayload = {
    url: `/api/v3/org/registration/${org_id}/${
      mode === 'accept' ? 'approve' : mode === 'resume' ? 'resume' : 'reject'
    }`,
    method: 'post',
  };
  const [success] = yield call(Api.request, requestPayload);

  if (success) {
    if (mode === 'accept') {
      yield put(setChangeStatusUOT('REGISTERED'));
    }
    if (mode === 'resume') {
      yield put(setChangeStatusUOT('PRE_REGISTERED'));
    }
    if (mode === 'reject') {
      yield put(setChangeStatusUOT('REJECTED'));
    }
  }
}

function* activateProductGroupSaga({ payload }) {
  const { org_id, inn, code, types, license } = payload;
  const requestPayload = {
    url: `/api/v3/elk/registration/activate-pg`,
    method: 'post',
    data: {
      org_id,
      inn,
      product_group: {
        code,
        types,
        license,
      },
    },
  };
  yield put(
    setChangeStatusProductGroup({
      code,
      status: '1',
    })
  );
  const [success] = yield call(Api.request, requestPayload);

  if (success) {
    yield put(
      setChangeStatusProductGroup({
        code,
        status: '1',
      })
    );
  }
}

function* updateUotSaga({ payload }) {
  const requestPayload = {
    url: '/api/v3/facade/participants/update',
    method: 'post',
    data: {
      inn: _.get(payload, 'inn', ''),
      orgData: {
        shortName: _.get(payload, 'shortName', ''),
        fullName: _.get(payload, 'fullName', ''),
        legalAddress: _.get(payload, 'legalAddress', ''),
        factAddress: _.get(payload, 'actualAddress', ''),
      },
      contactDetails: {
        email: _.get(payload, 'email', ''),
        phone: _.get(payload, 'phone', ''),
      },
      physicalPerson: {
        fio: _.get(payload, 'physicalPerson.fio', ''),
        socialCardId: _.get(payload, 'physicalPerson.socialCardId', ''),
      },
      pgData: _.get(payload, 'productGroupsAndRoles', []).map((el) => ({
        code: el.code,
        roleToAdd: el.roleToAdd,
        roleToDelete: el.roleToDelete,
        license: el.license,
        status: el.status,
      })),
    },
  };

  const [success] = yield call(Api.request, requestPayload);

  if (success) {
    yield put(setUpdateUot(parseEditDto(success.data)));
    yield put(setUpdated(true));
  }
}

export const saga = function* watch() {
  yield takeEvery(actions.mounted, mountSaga);
  yield takeEvery(
    actions.acceptAndCancelAndRefundRegistration,
    acceptAndCancelAndRefundRegistrationSaga
  );
  yield takeEvery(actions.activateProductGroup, activateProductGroupSaga);
  yield takeEvery(actions.fetchUpdateUot, updateUotSaga);
};
