import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

const getLocalState = (state) =>
  get(state, 'ParticipantDetails.components.users', {});

export const loaded = createSelector(getLocalState, (state) => state.loaded);
export const results = createSelector(getLocalState, (state) => state.results);
export const last = createSelector(getLocalState, (state) => state.last);
export const page = createSelector(getLocalState, (state) => state.page);
