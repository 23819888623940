import { createStyles, Theme } from '@material-ui/core';

export default ({ palette }: Theme) =>
  createStyles({
    root: {
      color: palette.secondary.main,
    },
    title: {
      textAlign: 'center',
    },
    code: {
      color: palette.primary.main,
    },
  });
