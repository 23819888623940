import { createActions } from 'redux-actions';

/**
 * Page header actions
 */
export const {
  pageHeader: { fetchElkCode, fetchSuzSignIn, fetchSunSignIn },
} = createActions({
  PAGE_HEADER: {
    FETCH_ELK_CODE: () => {},
    FETCH_SUZ_SIGN_IN: () => {},
    FETCH_SUN_SIGN_IN: () => {},
  },
});
