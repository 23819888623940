import { call, put, takeEvery } from 'redux-saga/effects';

import { HttpMethodEnum } from '../../../../../constants/index';
import { Api } from '../../../../../common_components/Api/Api';
import { fetchIncidents } from '../../ducks/SupportList.actions';

import { sendIncident } from './SupportModal.actions';

function* sendIncidentSaga({ payload: { caption, description } }) {
  const data = {
    sourcesystem: 10,
    caption,
    description,
  };

  const requestPayload = {
    url: '/crm-api/incidents',
    method: HttpMethodEnum.POST,
    data,
  };

  const [success] = yield call(Api.request, requestPayload, {
    artificialDelay: 5000,
  });

  if (success) {
    yield put(fetchIncidents());
  }
}

export const saga = function* watch() {
  yield takeEvery(sendIncident, sendIncidentSaga);
};
