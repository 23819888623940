import { handleActions } from 'redux-actions';

import {
  closeDocumentTemplatesDialog,
  openDocumentTemplatesDialog,
} from './DocumentTemplatesDialog.actions';

const open = handleActions(
  {
    [openDocumentTemplatesDialog]: () => true,
    [closeDocumentTemplatesDialog]: () => false,
  },
  false
);

export const reducer = {
  open,
};
