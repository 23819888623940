import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const TableRow = ({ classes, children, selected, disabled, inGroup, grouping, ...rest }) => (
  <div
    className={clsx(classes.root, {
      [classes.selected]: selected,
      [classes.disabled]: disabled,
      [classes.inGroup]: inGroup,
      [classes.grouping]: grouping,
    })}
    {...rest}
  >
    {children}
  </div>
);

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    selected: PropTypes.string.isRequired,
    disabled: PropTypes.string.isRequired,
    inGroup: PropTypes.string.isRequired,
    grouping: PropTypes.string.isRequired,
  }),
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  inGroup: PropTypes.bool,
  grouping: PropTypes.bool,
};

export default withStyles(({ palette }) => ({
  root: {
    background: '#fff',

    '&$selected': {
      background: palette.grey[300],
    },

    '&$disabled': {
      background: palette.grey[400],
    },

    '[role="rowgroup"] &:hover': {
      background: '#f2f2f2',
    },
  },
  selected: {},
  disabled: {},
  inGroup: {
    background: palette.grey[300],
    '& span[aria-disabled="false"]': {
      display: 'none',
    },

    '[role="rowgroup"] &:hover': {
      background: palette.grey[300],
    }
  },
  grouping: {
    '&$selected': {
      background: '#fff',
    },
  }
}))(TableRow);
