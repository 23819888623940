import { call, put, race, select, take, takeEvery } from 'redux-saga/effects';
import { get, isNull } from 'lodash';
import { replace } from 'connected-react-router';

import { productGroupService } from '@crpt/shared/services';

import { HttpMethodEnum } from '../../../../../constants/index';
import { loadFile } from '../../../../../common_components/File/File.utils';
import { Api } from '../../../../../common_components/Api/Api';
import * as cadesActions from '../../../../../common_components/Cades/Cades.actions';
import {
  open as openSignDocumentsModal,
  openSignDocumentsModalById,
  setSignDocumentsInfo,
} from '../SignDocuments.actions';
import { documentTypeValuesMap } from '../../../../Documents/DocumentsList/DocumentsList.constants';
import { SignErrorMapsEnum } from '../../SignDocuments.constants';
import { getCurrentCertificate } from '../../../../../common_components/Cades/Cades.selectors';
import * as actions from '../SignDocuments.actions';
import * as selectors from '../SignDocuments.selectors';

import { fetchSDDocumentsForSigning } from './SDDocuments';
import { fetchSDContent } from './SDContent';

function* openSignDocumentsModalByIdSaga({ payload: { id } }) {
  yield put(
    setSignDocumentsInfo({ name: productGroupService.getName(id), id })
  );
  yield put(openSignDocumentsModal());
}

/**
 * Подписать документ и вернуть его подпись
 */
function* getSignature(content) {
  const { certificate } = yield select(getCurrentCertificate);

  yield put(
    cadesActions.signByteArray(content, {
      subject: 'SD.getSignature',
      certificate,
    })
  );

  const [sign] = yield race([
    take(`${cadesActions.signedByteArray} SD.getSignature`),
    take(`${cadesActions.error} SD.getSignature`),
  ]);

  return get(sign, 'payload');
}

export function prepareSignFormData({ document, signature }) {
  const formData = new FormData();

  formData.append(
    'content',
    new Blob([document.content], { type: 'application/pdf' }),
    encodeURI(document.filename)
  );
  formData.append('signature', signature);
  formData.append('type', document.doctype);
  formData.append('for_approval', document.signtype === 1);
  formData.append('number', document.name);

  return formData;
}

function* signSaga() {
  yield call(fetchSDDocumentsForSigning);

  const documents = yield select(selectors.documents);
  const preparedDocuments = documents.filter((doc) => !isNull(doc.doctype));

  for (let i = 0; i < preparedDocuments.length; i += 1) {
    const document = preparedDocuments[i];
    const { content, type } = document;

    if (isNull(type)) {
      continue;
    }

    const signature = yield call(getSignature, content);

    if (signature) {
      const formData = yield call(prepareSignFormData, { document, signature });

      const requestPayload = {
        url: '/edo-api/outgoing-documents',
        method: HttpMethodEnum.POST,
        data: formData,
        timeout: 600000,
      };

      const [, error] = yield call(Api.request, requestPayload, {
        preloading: false,
      });

      if (error) {
        yield put(
          actions.signError({
            textError: get(
              SignErrorMapsEnum,
              error.response.status,
              'Ошибка подписания документов. Попробуйте снова.'
            ),
            documentType: documentTypeValuesMap[document.doctype],
            documentNumber: document.docid,
          })
        );
        return;
      }
    } else {
      yield put(
        actions.signError({
          textError: 'Ошибка подписания документов. Попробуйте снова.',
        })
      );
      return;
    }
  }

  yield put(actions.close());
  yield put(actions.signed());
  yield put(replace('/message'));
}

function* downloadAllSaga({ payload }) {
  const [content] = yield call(fetchSDContent, {
    group: `ALL,${payload}`,
    filename: 'preview.pdf',
  });

  loadFile({
    success: { data: content.data },
    name: `Документы на подписание.pdf`,
  });
}

export const saga = function* watch() {
  yield takeEvery(actions.sign, signSaga);
  yield takeEvery(actions.downloadAllDocs, downloadAllSaga);
  yield takeEvery(openSignDocumentsModalById, openSignDocumentsModalByIdSaga);
};
