import React, { FC } from 'react';
import clsx from 'clsx';
import { Box, Grid } from '@material-ui/core';
import { Button, Icon } from '@crpt-ui/core';
import { convertByteToMb } from '@crpt/shared/utils/convertByteToMb';

import { useStyles } from './FileQueue.styles';
import type { FileQueueItem as FileQueueItemType } from './FileQueue.types';

interface FileQueueItemProps {
  id: string;
  item: FileQueueItemType;
  onRemove: (arg: string) => void;
}

export const FileQueueItem: FC<FileQueueItemProps> = ({
  id,
  item,
  onRemove,
}) => {
  const classes = useStyles();

  const loadingPercent = item.loaded ? 100 : item.loadingPercent;
  const width = item.fileSize ? `${loadingPercent}%` : undefined;

  return (
    <div className={classes.item}>
      <div
        className={clsx(classes.loader, {
          [classes.fixedWidth]: !item.fileSize,
          [classes.success]: item.loaded,
        })}
        style={{
          width,
        }}
      />

      <Box className={classes.itemWrapper}>
        <Grid
          alignItems="center"
          className={classes.itemInner}
          container
          justify="space-between"
        >
          <Grid className={classes.label} item>
            {item.label}
          </Grid>

          <Grid item>
            <Grid alignItems="center" container justify="flex-end" spacing={2}>
              {item.fileSize && (
                <Grid className={classes.fileSize} item>
                  {convertByteToMb(item.fileSize)}МБ
                </Grid>
              )}

              {!item.loaded && item.hasStop && (
                <Grid item>
                  <Button
                    icon={<Icon name="remove" />}
                    onClick={() => onRemove(id)}
                    size="sm"
                    variant="text"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
