import get from 'lodash/get';
import { call, takeEvery, takeLatest } from 'redux-saga/effects';

import { Api } from '../../../Api/Api';
import { getToken } from '../../../../utils/userUtils';

import {
  fetchElkCode,
  fetchSunSignIn,
  fetchSuzSignIn,
} from './PageHeader.action';

function* fetchCode() {
  const token = yield call(getToken);

  const requestElkCode = {
    url: `${window.env.REACT_APP_NATIONAL_CATALOG_URL}/rest/elk/auth`,
    method: 'POST',
    data: {
      token,
    },
  };

  const [ELKToken] = yield call(Api.request, requestElkCode, {
    preloading: false,
  });
  const elkCode = get(ELKToken, 'data.elkCode', '');

  if (elkCode) {
    window.open(
      `${window.env.REACT_APP_NATIONAL_CATALOG_URL}/profile?elkCode=${elkCode}`
    );
  }
}

function* fetchSuzSignInSaga() {
  const token = yield call(getToken);

  const requestElkCode = {
    data: {
      token: token,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    url: '/oms-api/signin',
  };

  const [SuzUrl] = yield call(Api.request, requestElkCode, {
    preloading: false,
  });

  if (SuzUrl) {
    window.open(SuzUrl.data);
  }
}

function* fetchSunSignInSaga() {
  const requestCode = {
    method: 'POST',
    url: `${window.env.REACT_APP_SUN_FRONTEND_URL}/api/security/gais/authenticate`,
  };

  const [success] = yield call(Api.request, requestCode, {
    preloading: false,
  });

  if (success) {
    window.open(`${window.env.REACT_APP_SUN_FRONTEND_URL}/contraventions`);
  }
}

export const saga = function* watch() {
  yield takeLatest(fetchElkCode, fetchCode);
  yield takeEvery(fetchSuzSignIn, fetchSuzSignInSaga);
  yield takeEvery(fetchSunSignIn, fetchSunSignInSaga);
};
