import { createActions } from 'redux-actions';

/**
 * Actions for cancellation
 */
export const {
  submit,
  sendCancellation,
  setLoading,
  setCancellationLoading,
  openEventModal,
  closeModal,
} = createActions(
  {
    SUBMIT: (payload) => payload,
    SEND_CANCELLATION: (payload) => payload,

    SET_LOADING: (payload) => payload,
    SET_CANCELLATION_LOADING: (payload) => payload,

    OPEN_EVENT_MODAL: (payload) => payload,
    CLOSE_MODAL: () => {},
  },
  {
    prefix: 'EventModal',
  }
);
