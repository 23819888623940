import { createSelector } from 'reselect';

const getSettingsBase = (state) => state.Settings;

export const getInitialValues = createSelector(
  [getSettingsBase],
  (state) => state.initialValues
);

export const getSubmitting = createSelector(
  [getSettingsBase],
  (state) => state.submitting
);

export const getLoading = createSelector(
  [getSettingsBase],
  (state) => state.loading
);
