import React, { useState, useEffect } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { isEmpty, isEqual } from 'lodash/fp';

import {
  withForm,
  withFormContext,
} from '../../../../common_components/Form/Form.utils';
import { results, updated } from '../../ducks/ParticipantDetails.selectors';
import { Separator } from '../../../../common_components/Separator/Separator';
import { getUserInfo } from '../../../../common_components/Auth/ducks/Auth.selectors';
import { setUpdated } from '../../ducks/ParticipantDetails.actions';

import { HeaderActions } from './header-actions';
import { FooterActions } from './footer-actions';
import { CommonInfo } from './common-info';
import { ContactsInfo } from './contacts-info';
import { ParticipantsInfo } from './participant-info';
import { RolesConnected } from './roles-info';

import css from './index.module.scss';

const BasicInfo = (props) => {
  const {
    form,
    formValues,
    data,
    formErrors,
    formMutators,
    userInfo,
    setUpdated,
    updated,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const canEdit =
    (userInfo.roles?.includes('IS_MP_OPERATOR') ||
      userInfo.authorities.includes('ROLE_ORG_IS_MP_OPERATOR')) &&
    (data.status === 'PRE_REGISTERED' || data.status === 'REGISTERED');
  const canSave = !isEqual(data, formValues) && !Object.keys(formErrors).length;

  const accessHeaderActions =
    userInfo.authorities.includes('ROLE_ORG_IS_MP_OPERATOR') &&
    userInfo.authorities.includes('ROLE_ADMIN');

  useEffect(() => {
    setEditMode(false);
    setUpdated(false);
  }, [updated]);

  useEffect(() => {
    if (!isEmpty(data) && data?.physicalPerson?.socialCardId) {
      formMutators.updateField(
        'physicalPerson.socialCardId',
        data.physicalPerson.socialCardId
      );
    }
  }, [data]);

  return (
    <div>
      {!editMode && accessHeaderActions && (
        <HeaderActions status={data.status} />
      )}
      <Separator mt={0} />
      <div className={css.participantDetails}>
        <Grid
          container
          alignItems="flex-start"
          justify="space-between"
          spacing={2}
        >
          <Grid item xs={6}>
            <CommonInfo isEdit disabled={!editMode} />
          </Grid>
          <Grid item xs={6}>
            <ContactsInfo
              isEdit
              disabled={!editMode}
              organizationForm={data.organizationForm}
            />
          </Grid>
          <Grid item xs={12}>
            <ParticipantsInfo />
          </Grid>
          <Grid item xs={12}>
            <RolesConnected useInUOT editMode={editMode} />
          </Grid>
        </Grid>
      </div>
      <FooterActions
        canEdit={canEdit}
        canSave={canSave}
        editMode={editMode}
        form={form}
        formErrors={formErrors}
        initialProductGroupsAndRoles={data?.productGroupsAndRoles}
        setEditMode={setEditMode}
        values={formValues}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: results(state),
  userInfo: getUserInfo(state),
  updated: updated(state),
  // isLoading: preloaderSelectors.loading(state),
});

const mapDispatchToProps = {
  setUpdated,
};

export const BasicInfoConnected = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withForm(),
  withFormContext({ values: true, errors: true, form: true, mutators: true })
)(BasicInfo);
