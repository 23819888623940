import React, { useEffect, useMemo } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { Grid } from '@mui/material';
import type { FormApi } from 'final-form';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import {
  AutocompleteField,
  TextFieldFinalFormWrapper,
  useEvent,
  CommonDialog,
} from '@ibox/ui';

import {
  withForm,
  withFormContext,
} from '../../../../common_components/Form/Form.utils';
import {
  formatPhone,
  getOnlyNums,
  required,
  validateAmPhone,
  validateEmail,
  validateSocialCardNumber,
} from '../../../../common_components/Form/utils/validations';
import {
  userRoleOptionsAdmin,
  userRoleOptionsAdminOperator,
} from '../editing-user-role/editing-user-role.constants';
import { req } from '../../../../common_components/Form/Form.validations';
import { getCompanyState } from '../../Company/ducks/Company.selectors';
import { checkRights } from '../user-list.constants';
import {
  AccountsItem,
  Data,
  GroupItem,
  RolesItem,
} from '../../Company/ducks/Company.store';

import {
  getLoading,
  getOpenAddingUserDialog,
} from './ducks/AddingUserDialog.selectors';
import {
  closeAddingUserDialog,
  sendNewUser,
} from './ducks/AddingUserDialog.actions';

import classes from './adding-user-dialog.module.scss';

export type UserInfo = {
  InputAddress: {
    addressList: unknown[];
    pending: boolean;
    status: boolean;
  };
  accounts: AccountsItem[];
  data: Data;
  editing: boolean;
  groups: GroupItem[];
  roles: RolesItem[];
};

type SubmitValuesType = {
  email: string;
  fullName: string;
  phone: string;
  socialCardNumber: string;
  user_role: string[];
  isRegisterUserUOT: boolean;
};

type AddingUserDialogProps = {
  sendNewUser: (props: SubmitValuesType) => void;
  closeAddingUserDialog: () => void;
  actionAfterSubmit?: () => void;
  form: FormApi<Record<string, unknown>>;
  open: boolean;
  formValues: {
    email: string;
    fullName: string;
    phone: string;
    socialCardNumber: string;
    user_role: { label: string; value: string }[];
  };
  formErrors: Record<string, unknown>;
  isFormErrors: boolean;
  isLoading: boolean;
  selectedParticipant: Data;
  userInfo: UserInfo;
  isRegisterUserUOT: boolean;
};

const AddingUserDialog: React.FC<AddingUserDialogProps> = (props) => {
  const { sendNewUser, closeAddingUserDialog, form, actionAfterSubmit } = props;
  const { open, formValues, formErrors, isFormErrors, isLoading } = props;
  const { selectedParticipant, userInfo, isRegisterUserUOT } = props;
  const userRole = formValues?.user_role?.map((el) => el.value) || [];

  const { t } = useTranslation();

  useEffect(() => {
    if (!open) {
      form.reset();
    }
  }, [open]);

  useEffect(() => {
    form.mutators.updateField('phone', formatPhone(formValues.phone));
  }, [formValues.phone]);

  const onClose = useEvent(() => {
    closeAddingUserDialog();
    form.reset();
  });

  const onSubmit = useEvent(() => {
    if (validateAmPhone(formValues.phone)) {
      formErrors.phone = true;
      return;
    }
    sendNewUser({
      ...formValues,
      isRegisterUserUOT,
      user_role: userRole,
    });
    if (typeof actionAfterSubmit === 'function') {
      //actionAfterSubmit();
    }
  });

  const participantIncludeOperator = useMemo(
    () => checkRights(isRegisterUserUOT ? selectedParticipant : userInfo?.data),
    [isRegisterUserUOT, selectedParticipant, userInfo?.data]
  );

  const defaultRoleOption = useMemo(() => {
    return [];
  }, [open]);

  const getOptionsByRole = useMemo(() => {
    return participantIncludeOperator === null
      ? []
      : participantIncludeOperator
      ? userRoleOptionsAdminOperator(t)
      : userRoleOptionsAdmin(t);
  }, [participantIncludeOperator, t]);

  const handleParseRoles = (values: { label: never; value: string }[]) => {
    const isCoordinator = values.some((role) => role?.value === 'COORDINATOR');
    const isUser = values.some((role) => role?.value === 'USER');

    if (userRole.length > values.length && isCoordinator && !isUser) {
      return values.filter((role) => role.value !== 'COORDINATOR');
    }
    if (isCoordinator && !isUser) {
      return [...values, { label: t('Сотрудник'), value: 'USER' }];
    }

    return values;
  };

  return (
    <CommonDialog
      className={classes.dialog}
      invalid={isFormErrors || isLoading}
      labelCancel={t('Отменить')}
      labelSubmit={t('Добавить')}
      open={open}
      // size="lg"
      title={t('Добавить пользователя')}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Grid container className={classes.container} spacing={2}>
        <Grid item xs={12}>
          <Field
            fullWidth
            required
            component={TextFieldFinalFormWrapper}
            label={t('ФИО')}
            maxRows={4}
            name="fullName"
            validate={required}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            fullWidth
            required
            component={TextFieldFinalFormWrapper}
            format={getOnlyNums}
            inputProps={{
              maxLength: 10,
            }}
            label={t('Номер социальной карты')}
            name="socialCardNumber"
            validate={validateSocialCardNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            fullWidth
            required
            component={TextFieldFinalFormWrapper}
            label={t('Email')}
            name="email"
            validate={validateEmail}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            forceValidate
            fullWidth
            required
            component={TextFieldFinalFormWrapper}
            label={t('Телефон')}
            name="phone"
            validate={validateAmPhone}
          />
        </Grid>
        {open ? (
          <Grid item xs={12}>
            <Field
              fullWidth
              multiple
              required
              textView
              component={AutocompleteField}
              defaultValue={defaultRoleOption}
              disablePortal={false}
              label={t('Роль пользователя')}
              name="user_role"
              options={getOptionsByRole}
              parse={handleParseRoles}
              validate={req}
            />
          </Grid>
        ) : null}
      </Grid>
    </CommonDialog>
  );
};

const mapStateToProps = (state) => ({
  open: getOpenAddingUserDialog(state),
  // data: getPrepareDataForForm(state),
  isLoading: getLoading(state),
  userInfo: getCompanyState(state),
});

const mapDispatchToProps = {
  closeAddingUserDialog,
  sendNewUser,
};

type FormValues = {
  email?: string;
  fullName?: string;
  phone?: string;
  socialCardNumber?: string;
  user_role?: { label: string; value: string }[];
};

export const AddingUserDialogConnected = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withForm({
    validate: (values: FormValues) => {
      const errors = {};
      if (validateAmPhone(values.phone)) {
        errors.phone = 'Error';
      }
      return errors;
    },
  }),
  withFormContext({
    values: true,
    errors: true,
    form: true,
  })
)(AddingUserDialog);
