import { get } from 'lodash';

import {
  AgreementDocumentStatusColorMap,
  AgreementDocumentStatusIconsMap,
  AgreementDocumentStatusValueMap,
} from './AgreementDocument.constants';

export const getPrepareDocumentStatus = (status) => ({
  label: get(AgreementDocumentStatusValueMap, status),
  icon: get(AgreementDocumentStatusIconsMap, status),
  color: get(AgreementDocumentStatusColorMap, status),
});
