import { handleActions } from 'redux-actions';

import {
  closeAddingUserDialog,
  closeCertificateSelectionDialog,
  openAddingUserDialog,
  openCertificateSelectionDialog,
  setCertificate,
  setLoading,
} from './AddingUserDialog.actions';

const isOpenAddingUserDialog = handleActions(
  {
    [closeAddingUserDialog]: () => false,
    [openAddingUserDialog]: () => true,
  },
  false
);

const isOpenCertificateSelectionDialog = handleActions(
  {
    [closeCertificateSelectionDialog]: () => false,
    [openCertificateSelectionDialog]: () => true,
  },
  false
);

const certificate = handleActions(
  {
    [setCertificate]: (_, { payload }) => payload,
    [closeAddingUserDialog]: () => ({}),
  },
  {}
);

const loading = handleActions(
  {
    [setLoading]: (_, { payload }) => payload,
  },
  false
);

export const reducer = {
  certificate,
  isOpenAddingUserDialog,
  isOpenCertificateSelectionDialog,
  loading,
};
