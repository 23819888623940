import { makeStyles } from '@material-ui/core';

export const useProductGroupCellStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 8,
    marginTop: -4,
    marginBottom: -4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    borderRadius: 4,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  label: {
    fontWeight: 600,
  },
}));
