import React from 'react';
import { Tooltip, Typography } from '@crpt-ui/core';

import { useStyles } from './Title.styles';
import { useTextWrapState } from '@crpt/shared/hooks/useTextWrapState';

export type TitleProps = {
  tooltipTitle: string;
};

const Title: React.FC<TitleProps> = ({ children, tooltipTitle }) => {
  const {
    state: isTooltipOpen,
    onCheckWrap: onMouseEnterHandle,
    off: onMouseLeaveHandle,
  } = useTextWrapState('nowrap');

  const classes = useStyles();

  return (
    <Typography
      className={classes.root}
      noWrap
      onMouseEnter={onMouseEnterHandle}
      onMouseLeave={onMouseLeaveHandle}
      variant="h6"
    >
      <Tooltip open={isTooltipOpen} title={tooltipTitle}>
        <span>{children}</span>
      </Tooltip>
    </Typography>
  );
};

export default Title;
