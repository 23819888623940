import React, { useEffect, useMemo, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button, Table, Icon } from '@ibox/ui';

import { ContentWrapper } from '../../common_components/styled';
import { AddRegistryOIV } from '../add-registry-oiv/add-registry-oiv';

import { getColumns } from './RegistryOIV.constants';
import * as actions from './ducks/RegistryOIV.actions';
import * as selectors from './ducks/RegistryOIV.selectors';
import { getCookie } from '../../utils/cookieHelpers';

export const RegistryOIVComp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const loaded = useSelector((state) => selectors.loaded(state));
  const currentPage = useSelector((state) => selectors.page(state));
  const last = useSelector((state) => selectors.last(state));
  const results = useSelector((state) => selectors.results(state));
  const total = useSelector((state) => selectors.total(state));

  const [addRegistryOIVModal, setAddRegistryOIVModal] = useState(false);
  const language = getCookie('language') || 'hy';
  useEffect(() => {
    dispatch(
      actions.fetchData({
        page: currentPage > 0 ? currentPage : 1,
        filters: [],
        history,
        updateHistory: false,
        pathname: location.pathname,
      })
    );
    return dispatch(actions.onUnmount());
  }, [dispatch]);

  const onPageChange = (...params: unknown[]) => {
    const [page] = params;
    dispatch(
      actions.fetchData({
        page,
        filters: [],
        history,
        updateHistory: true,
        pathname: location.pathname,
      })
    );
  };

  const handleOpenModalRegistryOIV = () => {
    setAddRegistryOIVModal(!addRegistryOIVModal);
  };

  const columns = useMemo(
    () =>
      getColumns({
        t,
      }),
    [t]
  );

  return (
    <>
      <ContentWrapper>
        <Button onClick={handleOpenModalRegistryOIV}>
          <Box sx={{ display: 'flex' }}>
            {t('Добавить')}
            <Icon name="Plus" />
          </Box>
        </Button>

        {addRegistryOIVModal && (
          <AddRegistryOIV
            open={addRegistryOIVModal}
            onClose={handleOpenModalRegistryOIV}
          />
        )}
        <Table
          columns={columns}
          currentPage={currentPage as number}
          data={results}
          isLastPage={last}
          isLoading={!loaded}
          labelPagination={t('Показать еще')}
          locale={language}
          onPageChange={onPageChange}
        />
        <Typography>
          {t('Всего ОИВ')}: {total}
        </Typography>
      </ContentWrapper>
    </>
  );
};
