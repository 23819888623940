import React, { useMemo } from 'react';

import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { Typography } from '@crpt-ui/core';

import { getPrepareDocumentInfo } from '../../ClosingDocument.utils';
import {
  getDocumentInfo,
  getLoading,
} from '../../ducks/ClosingDocument.selectors';

const DocumentHeader = ({ documentInfo, isLoading }) => {
  const { type } = useMemo(
    () => getPrepareDocumentInfo(documentInfo),
    [documentInfo]
  );

  return (
    <Box mb={4}>
      {isLoading ? (
        <Skeleton height={24} variant="text" width={200} />
      ) : (
        <Typography variant="h5">{type}</Typography>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  documentInfo: getDocumentInfo(state),
  isLoading: getLoading(state),
});

export const DocumentHeaderConnected = connect(mapStateToProps)(DocumentHeader);
