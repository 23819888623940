import { createAction } from '@reduxjs/toolkit';

const subject = 'ReceiptList';

export const fetch = createAction(`${subject}/fetch`);
export const loaded = createAction(`${subject}/loaded`);
export const firstLoaded = createAction(`${subject}/firstLoaded`);
export const updatePage = createAction(`${subject}/updatePage`);
export const unmounted = createAction(`${subject}/unmounted`);
export const setHistoryFilters = createAction(`${subject} setHistoryFilters`);
export const setSavedFilters = createAction(`${subject} setSavedFilters`);
//Legacy
export const getRequest = createAction(`${subject}/get request`);
