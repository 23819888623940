import React, { useCallback, useMemo } from 'react';

import { TFunction } from 'i18next';
import { MRT_Cell } from 'material-react-table';
import { Box, Link, Typography } from '@mui/material';

import { FilterType } from '@ibox/ui';

import {
  participantStatusesName,
  participantStatuses,
} from '../../constants/participantStatuses';
import {
  ProductGroupEnum,
  ProductGroupNamesMap,
} from '../../common_components/Balance/enums';
import { DataTooltip } from '../../common_components/CellHelpers/DataTooltip';
import { Status } from '../status';

import css from './index.module.scss';

export const getColumns = ({ t }: { t: TFunction }) => [
  {
    accessorKey: 'fullName',
    id: 'multinameInn',
    header: t('Компания'),
    Cell: ({ cell }: { cell: MRT_Cell<Record<string, unknown>> }) => {
      const innValue = (
        <>
          {t('ИНН')}
          {`: ${cell.row.original.inn as string}`}
        </>
      );
      const headerPath = cell.row.original.inn
        ? `/participants/list/${cell.row.original.inn}`
        : '';

      return (
        <Box>
          <Link href={headerPath}>
            <span className={css.link}>
              {cell.row.original.fullName as string}
            </span>
          </Link>
          <Typography variant={'body2'}>{innValue}</Typography>
        </Box>
      );
    },
  },
  {
    id: 'status',
    accessorKey: 'status',
    header: t('Статус участника'),
    Cell: ({ row }) => {
      return (
        <Status
          participantStatus
          label={participantStatusesName[row.original.status] || '-'}
          status={row.original.status}
        />
      );
    },
  },
  {
    accessorKey: 'pg',
    id: 'productGroupId',
    header: t('Товарная группа'),
    enableSorting: false,
    Cell: ({ cell }: { cell: MRT_Cell<Record<string, unknown>> }) => {
      const productGroupsAndRoles = cell.row.original
        .productGroupsAndRoles as unknown[];
      const pgNames = productGroupsAndRoles.map((item: unknown) => {
        const pgId = (ProductGroupEnum as Record<string, number>)[
          item.code.toUpperCase()
        ];
        return (ProductGroupNamesMap as Record<string, string>)[pgId];
      });

      const listOfPgNames = useCallback(() => {
        return pgNames.map((pgName, key) => {
          const haveSeparate = key !== pgNames.length - 1;
          return (
            <span key={key}>
              {t(pgName as string)}
              {haveSeparate && ', '}
            </span>
          );
        });
      }, [pgNames]);

      const renderValues = () => {
        return <Box>{listOfPgNames()}</Box>;
      };
      const memoizedTitleValues = useMemo(
        () => listOfPgNames(),
        [listOfPgNames]
      );
      return (
        <DataTooltip
          alwaysDisplay
          arrow={false}
          render={renderValues}
          title={memoizedTitleValues}
        />
      );
    },
  },
];

export const getFiltersTabs = ({ t }: { t: TFunction }) => [
  {
    label: t('Все фильтры'),
    value: 0,
  },
  {
    label: t('Избранные фильтры'),
    value: 1,
  },
  {
    label: t('История фильтров'),
    value: 2,
  },
];

export const getFilters = ({
  t,
  loadParticipantFilter,
}: {
  t: TFunction;
  loadParticipantFilter: (value: string) => Promise<
    {
      value: string;
      label: string;
    }[]
  >;
}) => [
  {
    id: 'inn',
    label: t('ИНН'),
    type: FilterType.TEXT,
  },
  {
    id: 'fullName',
    label: t('Наименование'),
    type: FilterType.AUTOCOMPLETE_ASYNC,
    config: {
      request: loadParticipantFilter,
    },
  },
  {
    id: 'status',
    label: t('Статус участника'),
    type: FilterType.SELECT,
    config: {
      options: Object.keys(participantStatuses).map((status) => ({
        value: status,
        label: t(
          participantStatuses[
            status as keyof typeof participantStatuses
          ] as string
        ),
      })),
    },
  },
  {
    id: 'productGroupId',
    label: t('Товарная группа'),
    type: FilterType.SELECT,
    config: {
      options: Object.values(ProductGroupEnum).map((type) => ({
        value: `${type}`,
        label: t(ProductGroupNamesMap[type]),
      })),
    },
  },
];
