import moment from 'moment';

const YEAR_THREE_THOUSAND = 32503680000;

interface FormatToTimezone {
  date: string;
  timezone?: number;
}

/**
 * @example
 *   { date: '2021-06-01' } -> Tue Jun 01 2021 00:00:00 GMT+0300 (Москва, стандартное время)
 *   { date: '2021-06-01', timezone: 4 } -> Mon May 31 2021 23:00:00 GMT+0300 (Москва, стандартное время)
 * @param {string} date
 * @param {number} [timezone] - default MSK
 */
export const formatToTimezone = ({
  date,
  timezone = 3,
}: FormatToTimezone): Date => moment(date).utcOffset(timezone, true).toDate();

export const toTimezone = (date: string | undefined, format: string) =>
  date
    ? moment(
        formatToTimezone({
          date,
          timezone: 3,
        })
      ).format(format)
    : null;

export interface ToMomentDateOptions {
  ignoreUtcOffset: boolean;
}

function toUnix(value: number) {
  const isSeconds = value <= YEAR_THREE_THOUSAND;
  if (isSeconds) {
    return moment.unix(value);
  } else {
    return moment(value);
  }
}

export function toMomentDate(
  value?: string | number,
  options?: ToMomentDateOptions
): moment.Moment | null {
  if (!value) {
    return null;
  }

  switch (typeof value) {
    case 'string':
      if (!Number.isNaN(Number(value))) {
        return options?.ignoreUtcOffset
          ? toUnix(Number(value)).utc()
          : toUnix(Number(value));
      }
      return options?.ignoreUtcOffset ? moment.utc(value) : moment(value);
    case 'number':
      return options?.ignoreUtcOffset ? toUnix(value).utc() : toUnix(value);
    default:
      return null;
  }
}
