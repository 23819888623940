import { createSelector } from 'reselect';

export const getServiceDeskDocumentState = (state) =>
  state.Documents.ServiceDeskDocument;

export const getLoading = createSelector(
  [getServiceDeskDocumentState],
  (state) => state.loading
);
export const getServiceDeskDocumentContent = createSelector(
  [getServiceDeskDocumentState],
  (state) => state.documentContent
);

export const getServiceDeskDocumentInfo = createSelector(
  [getServiceDeskDocumentState],
  (state) => state.documentInfo
);
