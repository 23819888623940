import { createSelector } from 'reselect';

import { getCurrentCertificate } from '../../../../../common_components/Cades/Cades.selectors';
import {
  getClosingDocumentState,
  isShowAcceptanceContentCode,
  isUkdDocuments,
} from '../../ducks/ClosingDocument.selectors';

const getSignClosingDocumentState = createSelector(
  [getClosingDocumentState],
  (state) => state.SignClosingDocument
);

/**
 * Returns form data for signed documents
 * @returns {{}} - Form data for signed documents
 */
export const getSignData = createSelector(
  [getCurrentCertificate, isShowAcceptanceContentCode, isUkdDocuments],
  (certificateInfo, isShowAcceptanceContentCode, isUkdDocuments) => ({
    position: certificateInfo.position || 'Ответственное лицо',
    surname: certificateInfo.surname,
    name: certificateInfo.name,
    patronymic: certificateInfo.patronymic,
    grounds: 'Должностные обязанности',
    content_operation: isUkdDocuments
      ? 'С изменением стоимости согласен'
      : undefined,
    date: new Date(),
    meta: {
      isShowAcceptanceContentCode,
      isUkdDocuments,
    },
  })
);

export const isSigningInProcess = createSelector(
  [getSignClosingDocumentState],
  (state) => state.isSigningInProcess
);
