import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { eventsService } from '../../../services';
import {
  downloadDocument,
  downloadDocumentWorkflow,
} from '../../../common_components/Document/ducks';

import { ClosingDocumentsListView } from './ClosingDocumentsList.view';
import {
  fetchClosingDocumentsList,
  setPage,
} from './ducks/ClosingDocumentsList.actions';
import {
  getClosingDocumentsList,
  getLoading,
  getPage,
  getPagesCount,
} from './ducks/ClosingDocumentsList.selectors';
import { getPreparedDocument } from './ClosingDocuments.utils';

const ClosingDocumentsList = ({
  data,
  pagesCount,
  page,
  history,
  fetchClosingDocumentsList,
  downloadDocumentWorkflow,
  downloadDocument,
  setPage,
  isLoading,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    fetchClosingDocumentsList();
    eventsService.watch();

    return () => {
      eventsService.unwatch();
    };
  }, [fetchClosingDocumentsList]);

  const onRowClick = useCallback(
    (_, row) => {
      const { original: document } = row;

      history.push(`/documents/cd/${document.id}`);
    },
    [history]
  );

  const onChangePage = useCallback(
    (page) => {
      setPage(page);
    },
    [setPage]
  );

  const onRowSelect = useCallback((rows) => {
    setSelectedRows(Object.keys(rows));
  }, []);

  const onDownload = useCallback(
    (document) => {
      if (!document) {
        return;
      }

      const { id, type, status } = document;

      downloadDocument({
        hasEdo: true,
        useFileQueue: true,
        id,
        type,
        status,
      });
    },
    [downloadDocument]
  );

  const actionButtonDisabled = useMemo(() => {
    return selectedRows.length === 0 || isLoading;
  }, [isLoading, selectedRows.length]);

  const pageActions = useMemo(
    () => [
      {
        label: 'Действие с выбранными',
        variant: 'outlined',
        disabled: actionButtonDisabled,
        actionsList: [
          {
            label: 'Печать',
            onClick: () => {
              selectedRows.forEach((item) => {
                onDownload(data[item]);
              });
            },
          },
          {
            label: 'Скачать',
            onClick: () => {
              selectedRows.forEach((item) => {
                if (!data[item]) {
                  return;
                }

                downloadDocumentWorkflow({
                  id: data[item].id,
                });
              });
            },
          },
        ],
      },
    ],
    [
      actionButtonDisabled,
      data,
      downloadDocumentWorkflow,
      onDownload,
      selectedRows,
    ]
  );

  const datatableActions = useMemo(
    () => [
      {
        label: 'Печать',
        callback: (row) => {
          onDownload(row.original);
        },
      },
      {
        label: 'Скачать',
        callback: ({ original }) => {
          downloadDocumentWorkflow({
            id: original.id,
          });
        },
      },
    ],
    [downloadDocumentWorkflow, onDownload]
  );

  const preparedData = useMemo(() => data.map(getPreparedDocument), [data]);

  return React.createElement(ClosingDocumentsListView, {
    data: preparedData,
    pagination: {
      onChangePage,
      pagesCount,
      page,
    },
    pageActions,
    datatableActions,
    isLoading,
    onRowClick,
    onRowSelect,
  });
};

ClosingDocumentsList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

const mapDispatchToProps = {
  fetchClosingDocumentsList,
  setPage,
  downloadDocumentWorkflow,
  downloadDocument,
};

const mapStateToProps = (state) => ({
  data: getClosingDocumentsList(state),
  pagesCount: getPagesCount(state),
  page: getPage(state),
  isLoading: getLoading(state),
});

export const ClosingDocumentsListConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClosingDocumentsList);
