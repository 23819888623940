/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, Dialog, Icon, Typography, Divider } from '@crpt-ui/core';
// @ts-ignore
import Datatable from '@crpt-ui/datatable';
import {
  Box,
  Grid,
  InputAdornment,
  InputBase,
  LinearProgress,
} from '@material-ui/core';
import React, { FC } from 'react';
import { Accordion } from '@crpt/shared/components/Accordion';
import { useLinearProgressClasses, useClasses } from './UploadCisDialog.styles';
import clsx from 'clsx';
import { UploadCisDialogViewModel } from './UploadCisDialogViewModel';
import type { UploadCisDialogViewModelProps } from './UploadCisDialog.types';
import { UploadCisDialogRequests } from './UploadCisDialog.types';
import { getTableHeight } from './UploadCisDialog.utils';
import { useTranslation } from 'react-i18next';

const UploadCisDialog: FC<UploadCisDialogViewModelProps> = ({ request = UploadCisDialogRequests.PRODUCT_ENRICHMENT, ...props }) => {
  const vm = UploadCisDialogViewModel({ request, ...props });

  const { t } = useTranslation();

  const linearProgressClasses = useLinearProgressClasses();
  const classes = useClasses();

  const columns = [
    {
      Header: t('UploadCisDialog.columns.errorType', 'Тип ошибки'),
      accessor: 'groupDescription',
    },
    {
      Header: t('UploadCisDialog.columns.errorCount', 'Количество ошибок'),
      accessor: 'count',
    },
  ];

  const tableHeight = getTableHeight(vm.validationErrors.length);

  return (
    <Dialog {...props} disableBackdropClick={true}>
      <Box overflow="hidden">
        <Grid
          alignItems="center"
          container
          justify="space-between"
          spacing={2}
          wrap="nowrap"
        >
          <Grid item>
            <Typography variant="h5">
              {t('UploadCisDialog.header', 'Загрузка товаров')}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              icon={<Icon name="Close" />}
              onClick={vm.cancel}
              size="sm"
              variant="text"
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={4} mt={2} mx={-4}>
        <Divider />
      </Box>
      <div className={clsx(classes.inputWrapper, { uploading: vm.uploading })}>
        <input {...vm.dropzone.getInputProps()} />
        {vm.uploading ? (
          <Grid
            container
            className={classes.linearProgressWrapper}
            justify="space-between"
          >
            <Grid item>
              <LinearProgress
                classes={linearProgressClasses}
                value={vm.uploadingProgress}
                variant="determinate"
              />
            </Grid>
            <Grid item>
              <Button
                className={classes.closeButton}
                icon={<Icon name="Close" />}
                onClick={vm.cancelUploading}
              />
            </Grid>
          </Grid>
        ) : null}
        <InputBase
          className={clsx(classes.input, { uploading: vm.uploading })}
          endAdornment={
            !vm.uploading ? (
              <InputAdornment position="end">
                {Boolean(vm.dropzoneFiles.length) && (
                  <Button
                    color="error"
                    icon={<Icon name="Trash" />}
                    onClick={vm.resetFiles}
                    variant="text"
                  />
                )}
                <Button
                  className={classes.uploadButton}
                  icon={<Icon name="Upload" />}
                  onClick={vm.dropzone.open}
                />
              </InputAdornment>
            ) : null
          }
          fullWidth
          placeholder={t('UploadCisDialog.placeholder', 'Выберите файл формата *.xls или *.xlsx')}
          readOnly
          value={vm.dropzoneFiles[0]?.name || ''}
        />
      </div>

      {vm.hasValidationErrors && !vm.uploading ? (
        <>
          <Box className={classes.errorWrapper}>
            <Grid container spacing={1} wrap="nowrap">
              <Grid item>
                <Icon color="error" name="error" />
              </Grid>
              <Grid item>
                <Box>
                  <Typography variant="h6">
                    {t('UploadCisDialog.errorTitle', 'Выявлены ошибки при проверке кодов идентификации')}
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography variant="body2">
                    {t('UploadCisDialog.errorMessageTitle', 'Вы можете:')}
                    <Box mx={-2}>
                      <ul>
                        <li>
                          {t('UploadCisDialog.errorMessageOption1',
                            'cкорректировать перечень кодов и загрузить файл повторно')}
                        </li>
                        {Boolean(vm.acceptedCodes.length) && (
                          <li>
                            {t('UploadCisDialog.errorMessageOption2',
                              'добавить в документ коды идентификации без ошибок')}
                          </li>
                        )}
                      </ul>
                    </Box>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box position="absolute" right={32} zIndex={1}>
            <Button
              color="secondary"
              icon={<Icon name="Download" />}
              onClick={vm.downloadValidationResults}
              variant="text"
            >
              {t('UploadCisDialog.downloadErrors', 'Скачать ошибки')}
            </Button>
          </Box>

          <Accordion header={t('UploadCisDialog.showErrors', 'Показать ошибки')} id="validation-errors">
            <Box my={-1} overflow="hidden" width={1}>
              <Box className={classes.tableWrapper}>
                <Datatable
                  columns={columns}
                  data={vm.validationErrors}
                  height={tableHeight}
                />
              </Box>
            </Box>
          </Accordion>

          <Box mb={4} mt={0} mx={-4}>
            <Divider />
          </Box>

          <Box overflow="hidden">
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  disabled={!vm.acceptedCodes.length || vm.loading}
                  onClick={vm.accept}
                >
                  {t('UploadCisDialog.addCodesWithoutErrors', 'Добавить коды без ошибок')}
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={vm.cancel} variant="outlined">
                  {t('UploadCisDialog.cancel', 'Отменить')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : null}
    </Dialog>
  );
};

export default UploadCisDialog;
