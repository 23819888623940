import React, { useMemo } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

import { Icon } from '@ibox/ui';

import { ProductGroupIconsMap, ProductGroupNamesMap } from '../enums';

import css from './index.module.scss';

type AccountProps = {
  account: any[];
  className?: string;
};

export const Account = ({ account, className, ...rest }: AccountProps) => {
  const { t } = useTranslation();
  const balance = useMemo(
    () =>
      (account.balance ?? 0).toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
      }),
    [account]
  );

  const productGroupId = useMemo(() => account.productGroupId, [account]);

  const icon = useMemo(
    () => ProductGroupIconsMap[productGroupId] ?? 'Datamatrix',
    [productGroupId]
  );

  const title = useMemo(
    () => ProductGroupNamesMap[productGroupId],
    [productGroupId]
  );

  return (
    <div className={clsx(css.root, className)} {...rest}>
      <Tooltip
        arrow
        disableHoverListener={!title}
        disableTouchListener={!title}
        placement="bottom"
        title={
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {title}
          </div>
        }
      >
        <div className={css.icon}>
          <Icon name={icon} />
        </div>
      </Tooltip>
      <div>
        <div className={css.label}>
          {t('Лицевой счет')} &#8470;{account.contractId}
        </div>
        <div className={css.value}>{balance}&nbsp;драм.</div>
      </div>
    </div>
  );
};
