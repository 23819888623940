import React, { useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useTranslation } from 'react-i18next';

// import { FileQueueContainer } from '@crpt/shared/components/FileQueue'; // ломает верстку кнопок
import { Toaster } from '@crpt/shared/toast';
import { fetchNotifications } from '@crpt/shared/components/RouteController/ducks/RouteController.store';

import { DefaultWrap } from './common_components/DefaultWrap/DefaultWrap';
import {
  checkMobileDevice,
  changeLanguage,
} from './common_components/App/App.actions';
import { getRoutes } from './common_components/App/App.selectors';
import { PrivateRouteConnected } from './common_components/Routing/PrivateRoute';
import { Route } from './common_components/Routing/Route';
import { getUserAuthorities } from './common_components/Auth/ducks/Auth.selectors';
import { PARTICIPANT_TYPES } from './constants/participantTypes';
import { Theme } from './common_components/themes';

import css from './App.module.scss';

const AppComponent = ({
  checkMobileDevice,
  routes,
  userAuthorities,
  changeLanguage,
}) => {
  const { i18n } = useTranslation();

  i18n.on('languageChanged', changeLanguage);

  useEffect(() => {
    checkMobileDevice();
  });

  const inLayoutRoutes = useMemo(() => {
    return routes.filter((page) => {
      if (!page.inLayout) {
        return false;
      }
      const adminOrUser =
        userAuthorities.includes(PARTICIPANT_TYPES.ROLE_ADMIN) ||
        userAuthorities.includes(PARTICIPANT_TYPES.ROLE_USER);
      switch (page.route) {
        case '/participants/list':
          return (
            (userAuthorities.includes(
              PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR
            ) &&
              adminOrUser) ||
            userAuthorities.includes(PARTICIPANT_TYPES.ROLE_ORG_OGV)
          );
        case '/oiv':
        case '/oiv/:id':
          return (
            userAuthorities.includes(
              PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR
            ) && adminOrUser
          );
        default:
          return true;
      }
    });
  }, [routes, userAuthorities]);

  const withoutLayoutRoutes = useMemo(() => {
    return routes.filter((route) => !route.inLayout);
  }, [routes]);

  return (
    <Theme>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className={css.App}>
          <Switch>
            {withoutLayoutRoutes.map((route, index) =>
              route.private ? (
                <PrivateRouteConnected
                  component={route.component}
                  exact={route.exact}
                  key={index}
                  path={route.route}
                />
              ) : (
                <Route
                  component={route.component}
                  exact={route.exact}
                  key={index}
                  path={route.route}
                />
              )
            )}
            <PrivateRouteConnected
              render={(props) => {
                return <DefaultWrap {...props} routes={inLayoutRoutes} />;
              }}
            />
          </Switch>

          {/*<FileQueueContainer />*/}
          <Toaster />
        </div>
      </MuiPickersUtilsProvider>
    </Theme>
  );
};

AppComponent.propTypes = {
  checkMobileDevice: PropTypes.func.isRequired,
  routes: PropTypes.array.isRequired,
};

const mapDispatchToProps = {
  changeLanguage,
  checkMobileDevice,
  loadNotifications: fetchNotifications,
};

const mapStateToProps = (state) => {
  return {
    routes: getRoutes(state),
    userAuthorities: getUserAuthorities(state),
  };
};

export const App = connect(mapStateToProps, mapDispatchToProps)(AppComponent);
