import React, { useCallback } from 'react';

import { Box } from '@material-ui/core';
import { Typography, Dialog, Divider, Button } from '@crpt-ui/core';
import type { DialogProps as MuiDialogProps } from '@material-ui/core';

export type DocumentsDialogProps = {
  title: string;
} & MuiDialogProps;

const DocumentsDialog: React.FC<DocumentsDialogProps> = ({
  title,
  onClose,
  children,
  ...rest
}) => {
  const handleDialogClose = useCallback((e) => {
    onClose && onClose(e, 'escapeKeyDown');
  }, [onClose]);

  return (
    <Dialog onClose={onClose} {...rest}>
      <Box display="flex" flexDirection="column">
        <Typography variant="h4">{title}</Typography>
        <Box mb={4} mx={-4}>
          <Divider />
        </Box>
        {children}
        <Box mx={-4} my={4}>
          <Divider />
        </Box>
        <Box display="flex">
          <Button onClick={handleDialogClose} variant="outlined">
            Закрыть
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DocumentsDialog;
