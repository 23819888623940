import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { productGroupService } from '@crpt/shared/services';

import { HttpMethodEnum } from '../../../../constants/index';
import { Api } from '../../../../common_components/Api/Api';
import { fetchUsersListSaga } from '../../../_Profile/UserList/ducks/UserList.saga';
import { getInn } from '../../../_Profile/Company/ducks/Company.selectors';
import { fetchAccounts } from '../../../_Profile/Company/ducks/Company.saga';

import {
  closeModalNotification,
  openNotificationBalanceModal,
  sendNotificationMinBalance,
  setBalanceMinimum,
  setLoading,
  setNotificationRecipientsIds,
  setOpenNotificationBalanceModal,
  setSelectedAccount,
} from './NotificationBalance.actions';
import { getSelectedAccount } from './NotificationBalance.selectors';

function* fetchBalanceMinimum() {
  const account = yield select(getSelectedAccount);
  const participantInn = yield select(getInn);

  const data = {
    participant_inn: participantInn,
    personal_account: account.contractId,
    product_group: productGroupService.getCode(account.productGroupId),
  };

  const requestPayload = {
    url: '/api/v3/facade/balance/minimum',
    method: HttpMethodEnum.POST,
    data,
  };

  const [success] = yield call(Api.request, requestPayload);

  if (success) {
    const notificationRecipients = success.data.notification_recipients;

    if (notificationRecipients) {
      const notificationRecipientsIds = notificationRecipients.map((item) =>
        Number(item)
      );
      yield put(setNotificationRecipientsIds(notificationRecipientsIds));
    }

    const balanceMinimum = success.data.balance_minimum_value;

    if (balanceMinimum) {
      yield put(setBalanceMinimum(Number(balanceMinimum) / 100));
    }
  }
}

function* sendNotificationMinBalanceSaga({
  payload: { formValues, account, successMessage },
}) {
  yield put(setLoading(true));
  const inn = yield select(getInn);

  const data = {
    balance_minimum_value: formValues.minBalance
      ? Number(formValues.minBalance) * 100
      : null,
    notification_recipients: formValues.recipientsList,
    participant_inn: inn,
    personal_account: account.contractId,
    product_group: productGroupService.getCode(account.productGroupId),
  };

  const requestPayload = {
    url: '/api/v3/facade/balance/minimum',
    method: HttpMethodEnum.PUT,
    data,
  };

  const [success] = yield call(Api.request, requestPayload, {
    successMessage,
  });

  if (success) {
    yield call(fetchAccounts);

    yield put(closeModalNotification());
  }

  yield put(setLoading(false));
}

function* openNotificationBalanceModalSaga({ payload: accountInfo }) {
  yield put(setLoading(true));

  yield put(setSelectedAccount(accountInfo));
  yield put(setOpenNotificationBalanceModal(true));

  yield all([call(fetchUsersListSaga), call(fetchBalanceMinimum)]);

  yield put(setLoading(false));
}

export const saga = function* watch() {
  yield takeEvery(
    openNotificationBalanceModal,
    openNotificationBalanceModalSaga
  );
  yield takeEvery(sendNotificationMinBalance, sendNotificationMinBalanceSaga);
};
