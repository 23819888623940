import { createAction } from 'redux-actions';

export const subject = '[REGISTRY_PAGE]';

export const onDataLoaded = createAction(`${subject} onDataLoaded`);
export const onUsersLoaded = createAction(`${subject} onUsersLoaded`);
export const clearUsers = createAction(`${subject} clearUsers`);
export const fetchData = createAction(`${subject} fetchData`);
export const fetchGetUsers = createAction(`${subject} fetchGetUsers`);
export const fetchAddUser = createAction(`${subject} fetchAddUser`);
export const setAddedUser = createAction(`${subject} setAddedUser`);
export const clearAddedUser = createAction(`${subject} clearAddedUser`);
export const onUnmount = createAction(`${subject} onUnmount`);
