import { createSelector } from 'reselect';
import * as _ from 'lodash';

import { PRODUCT_GROUP } from '../../../../constants/ProductGroup';
import {
  productGroupStatusEnum,
  UserStatusEnum,
} from '../../../../constants/index';
import { getProductGroupsDetails } from '../../../../common_components/Auth/ducks/Auth.selectors';

export const getCompanyState = (state) => state.Profile.Company;

export const getCompanyData = createSelector(
  [getCompanyState],
  (companyBase) => companyBase.data || {}
);

export const getProductGroups = createSelector(
  [getCompanyState],
  (companyBase) => companyBase.groups
);

export const getCompanyRoles = createSelector(
  [getCompanyState],
  (companyBase) => companyBase.roles
);

export const getEditing = createSelector(
  [getCompanyState],
  (companyData) => companyData.editing
);

export const getCompanyStatus = createSelector(
  [getCompanyData],
  (companyData) => companyData.status
);

export const getCompanyId = createSelector(
  [getCompanyData],
  (companyData) => companyData.id
);

export const getProductGroupsAndRoles = createSelector(
  [getCompanyData],
  (companyData) => companyData.productGroupsAndRoles || []
);

export const getEmail = createSelector(
  [getCompanyData],
  (companyData) => companyData.email
);

export const getPhone = createSelector(
  [getCompanyData],
  (companyData) => companyData.phone
);

export const getInn = createSelector(
  [getCompanyData],
  (companyData) => companyData.inn || ''
);

export const getGs1List = createSelector(
  [getCompanyData],
  (state) => state.gs1_list
);

/**
 * Is the company an individual entrepreneur
 *
 * @type {function}
 * @returns {boolean}
 */
export const getSoleProprietorship = createSelector([getInn], (inn) => {
  if (inn.length === 12) {
    return !/^00/.test(inn);
  }

  return false;
});

/**
 * If the company is a foreign legal entity, returns true
 *
 * @type {function}
 * @returns {boolean} - Is it a foreign legal entity
 */
export const getIsForeignLegalEntity = createSelector(
  [getCompanyData],
  (companyData) => companyData.afp
);

/**
 * @returns {{
 *    balance: number,
 *    contractId: number,
 *    minBalance: number,
 *    organisationId: number,
 *    productGroupId: number
 *  }[]}
 */
export const getAccounts = createSelector([getCompanyState], (state) => {
  return _.chain(state)
    .get(`accounts`, [])
    .filter((account) => _.has(account, 'contractId'))
    .map((account) => ({
      ...account,
      balance: account.balance / 100 || 0,
      minBalance: account.minBalance / 100,
    }))
    .value();
});

export const getCommercialProductGroups = createSelector(
  [getProductGroups],
  (productGroups) =>
    productGroups
      .filter(
        (productGroup) =>
          productGroup.productGroupStatus === 'COMMERCIAL' ||
          productGroup.productGroupStatus === 'EMISSION_BLOCKED'
      )
      .map((productGroup) => productGroup.code)
);

/**
 * Returns true if any of the commercial product groups
 * has the REVOKED or REVOKED_WITH_ACCESS_ENABLED status
 */
export const getReRegistrationStatus = createSelector(
  [getProductGroupsDetails, getCommercialProductGroups],
  (productGroups, commercialProductGroups) =>
    productGroups.some(
      (productGroup) =>
        commercialProductGroups.includes(productGroup.name) &&
        [
          null,
          productGroupStatusEnum.REVOKED,
          productGroupStatusEnum.REVOKED_WITH_ACCESS_ENABLED,
        ].includes(productGroup.status)
    )
);

export const getAvailableProductGroups = createSelector(
  [getProductGroupsDetails, getProductGroups],
  (productGroupsDetails, productGroups) =>
    productGroups.map((group) => {
      const productGroup = _.find(productGroupsDetails, {
        name: group.code,
      });

      const productGroupStatus = _.get(group, 'productGroupStatus');

      const isDisabledLink =
        productGroupStatus === 'COMMERCIAL' &&
        ![
          productGroupStatusEnum.ACTIVE,
          productGroupStatusEnum.ENABLING,
          productGroupStatusEnum.CHANGING_TYPE,
          productGroupStatusEnum.REVOKED_WITH_ACCESS_ENABLED,
          productGroupStatusEnum.REGISTERED_WITH_ACCESS_ENABLED,
          productGroupStatusEnum.PROFILED_WITH_ACCESS_ENABLED,
          productGroupStatusEnum.SIGNER_WITH_ACCESS_ENABLED,
          productGroupStatusEnum.PRECESS_SIGNER_ACCESS_ENABLED,
        ].includes(_.get(productGroup, 'status'));

      const isDisabled =
        productGroupStatus === 'COMMERCIAL' &&
        [
          null,
          productGroupStatusEnum.REVOKED,
          productGroupStatusEnum.REGISTERED,
          productGroupStatusEnum.PROFILED,
        ].includes(_.get(productGroup, 'status'));

      return {
        active: productGroup ? !isDisabled : false,
        disabled: productGroup ? isDisabled : true,
        disabledLink: productGroup ? isDisabledLink : true,
        id: group.code,
        name: group.name,
        productGroupStatus: productGroupStatus,
        status: _.get(productGroup, 'status'),
      };
    })
);

/**
 * Returns true if:
 * companyStatus === REGISTERED &&
 * All product groups are disabled &&
 * One of the product groups has the status REVOKED_WITH_ACCESS_ENABLED or REVOKED
 *
 * @returns {boolean} - Is the company registered
 */
export const getRegistered = createSelector(
  [getCompanyStatus, getAvailableProductGroups],
  (companyStatus, productGroups) => {
    const registered = companyStatus === UserStatusEnum.REGISTERED;

    const allProductGroupsNotDisabled = productGroups.every((productGroup) =>
      productGroup.status ? productGroup.active : true
    );

    const productGroupNotRevoked = !productGroups.some((productGroup) =>
      [
        productGroupStatusEnum.REVOKED_WITH_ACCESS_ENABLED,
        productGroupStatusEnum.REVOKED,
      ].includes(productGroup.status)
    );

    return registered && allProductGroupsNotDisabled && productGroupNotRevoked;
  }
);

/**
 * The fields are blocked when the company is registered
 * and the profile is in view mode
 *
 * @type {function}
 * @returns {boolean}
 */
export const getDisabledFields = createSelector(
  [getEditing, getRegistered],
  (isEditing, isRegistered) => isRegistered && !isEditing
);
export const getDisabledLegalAddress = createSelector(
  [getEditing, getCompanyData],
  (isEditing, data) => {
    return !(isEditing && data.organizationForm === 'UL');
  }
);

export const hasActiveProductGroups = createSelector(
  [getCompanyStatus, getAvailableProductGroups],
  (companyStatus, productGroups) =>
    companyStatus === UserStatusEnum.REGISTERED &&
    productGroups.some((productGroup) => !productGroup.disabled)
);

/**
 * 15300 - Крестьянские (фермерские) хозяйства
 * 50101 - Главы крестьянских (фермерских) хозяйств
 * 14100 - Сельскохозяйственные производственные кооперативы
 */
export const isFarmer = createSelector([getCompanyData], (state) =>
  ['15300', '50101', '14100'].includes(state.okopf)
);

export const getCompanyFormData = createSelector(
  [getCompanyData, isFarmer],
  (state, isFarmer) => ({
    ...state,
    isFarmer: isFarmer ? 'Да' : null,
  })
);

/**
 * Возвращает true если среди товарных групп есть "Товары из натурального меха"
 *
 * @type {function}
 * @returns {boolean}
 */
export const isThereProductGroupFurs = createSelector(
  [getProductGroupsAndRoles],
  (productGroups) => {
    return productGroups.some((item) => item.code === PRODUCT_GROUP.FURS.code);
  }
);

/**
 * Возвращает true если товарная группа одна и является "Товары из натурального меха"
 *
 * @type {function}
 * @returns {boolean}
 */
export const onlyProductGroupIsFurs = createSelector(
  [getProductGroupsAndRoles, isThereProductGroupFurs],
  (productGroups, isThereProductGroupFurs) => {
    return productGroups.length === 1 && isThereProductGroupFurs;
  }
);
