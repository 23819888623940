import { StatusProps } from '@crpt-ui/core';

export enum DocumentStatus {
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  CHECKED_NOT_OK = 'CHECKED_NOT_OK',
  CHECKED_OK = 'CHECKED_OK',
  IN_PROGRESS = 'IN_PROGRESS',
  PARSE_ERROR = 'PARSE_ERROR',
  PROCESSING_ERROR = 'PROCESSING_ERROR',
  UNDEFINED = 'UNDEFINED',
  WAIT_ACCEPTANCE = 'WAIT_ACCEPTANCE',
  WAIT_FOR_CONTINUATION = 'WAIT_FOR_CONTINUATION',
  WAIT_PARTICIPANT_REGISTRATION = 'WAIT_PARTICIPANT_REGISTRATION',
}

export const documentStatusTitle = {
  [DocumentStatus.ACCEPTED]: 'Принят',
  [DocumentStatus.CANCELLED]: 'Аннулирован',
  [DocumentStatus.CHECKED_NOT_OK]: 'Обработан с ошибками',
  [DocumentStatus.CHECKED_OK]: 'Обработан',
  [DocumentStatus.IN_PROGRESS]: 'Проверяется',
  [DocumentStatus.PARSE_ERROR]: 'Обработан с ошибками',
  [DocumentStatus.PROCESSING_ERROR]: 'Техническая ошибка',
  [DocumentStatus.UNDEFINED]: 'Не определен',
  [DocumentStatus.WAIT_ACCEPTANCE]: 'Ожидает приемку',
  [DocumentStatus.WAIT_FOR_CONTINUATION]: 'Проверяется',
  [DocumentStatus.WAIT_PARTICIPANT_REGISTRATION]:
    'Ожидаем регистрации участника в  ГИС МТ',
};

// иконки для Icon from '@crpt-ui/core';
export const documentStatusIconName = {
  [DocumentStatus.ACCEPTED]: 'DocumentPublished',
  [DocumentStatus.CANCELLED]: 'DocumentCanceled',
  [DocumentStatus.CHECKED_NOT_OK]: 'DocumentInvalid',
  [DocumentStatus.CHECKED_OK]: 'DocumentPublished',
  [DocumentStatus.IN_PROGRESS]: 'DocumentProcessing',
  [DocumentStatus.PARSE_ERROR]: 'DocumentInvalid',
  [DocumentStatus.PROCESSING_ERROR]: 'DocumentInvalid',
  [DocumentStatus.UNDEFINED]: 'DocumentUndefined',
  [DocumentStatus.WAIT_ACCEPTANCE]: 'DocumentWaitAcceptance',
  [DocumentStatus.WAIT_FOR_CONTINUATION]: 'DocumentWaitAcceptance',
  [DocumentStatus.WAIT_PARTICIPANT_REGISTRATION]: 'DocumentWaitAcceptance',
};

type DocumentStatusColorName = {
  [V in DocumentStatus]: StatusProps['color'];
};

// цвета для Status from '@crpt-ui/core';
export const documentStatusColorName: DocumentStatusColorName = {
  [DocumentStatus.ACCEPTED]: 'success',
  [DocumentStatus.CANCELLED]: 'error',
  [DocumentStatus.CHECKED_NOT_OK]: 'error',
  [DocumentStatus.CHECKED_OK]: 'success',
  [DocumentStatus.IN_PROGRESS]: 'warning',
  [DocumentStatus.PARSE_ERROR]: 'error',
  [DocumentStatus.PROCESSING_ERROR]: 'error',
  [DocumentStatus.UNDEFINED]: 'error',
  [DocumentStatus.WAIT_ACCEPTANCE]: 'warning',
  [DocumentStatus.WAIT_FOR_CONTINUATION]: 'warning',
  [DocumentStatus.WAIT_FOR_CONTINUATION]: 'warning',
  [DocumentStatus.WAIT_PARTICIPANT_REGISTRATION]: 'warning',
};

type DocumentStatusValue = {
  [id: number]: DocumentStatus;
};

export const documentStatusValue: DocumentStatusValue = {
  0: DocumentStatus.IN_PROGRESS,
  1: DocumentStatus.CHECKED_OK,
  2: DocumentStatus.CHECKED_NOT_OK,
  3: DocumentStatus.PROCESSING_ERROR,
  4: DocumentStatus.UNDEFINED,
  5: DocumentStatus.CANCELLED,
  6: DocumentStatus.ACCEPTED,
  7: DocumentStatus.WAIT_ACCEPTANCE,
  8: DocumentStatus.WAIT_PARTICIPANT_REGISTRATION,
  9: DocumentStatus.PARSE_ERROR,
  10: DocumentStatus.WAIT_FOR_CONTINUATION,
};
