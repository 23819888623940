import React from 'react';

import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { Typography } from '@crpt-ui/core';

import { useSignatureDescriptionStyles } from './SignatureDescription.styled';

export const SignatureDescriptionLine = ({ label, value }) => {
  const classes = useSignatureDescriptionStyles();

  return (
    <Grid className={classes.line}>
      {label}:{' '}
      <Typography className={classes.boldText} component="span">
        {value ? value : '—'}
      </Typography>
    </Grid>
  );
};

SignatureDescriptionLine.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};
