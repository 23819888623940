import { ProductGroupEnum } from './ProductGroupEnum';

export const ProductGroupIconsMap = Object.freeze({
  [ProductGroupEnum.ALCOHOL]: 'PgAlcohol',
  [ProductGroupEnum.BEER]: 'PgBeer',
  [ProductGroupEnum.BICYCLE]: 'Bicycle',
  [ProductGroupEnum.ELECTRONICS]: 'Electronics',
  [ProductGroupEnum.FURS]: 'Furs',
  [ProductGroupEnum.LP]: 'PgClothes',
  [ProductGroupEnum.MILK]: 'PgMilk',
  [ProductGroupEnum.NCP]: 'Ncp',
  [ProductGroupEnum.OTP]: 'TobaccoAlternative',
  [ProductGroupEnum.PERFUMERY]: 'Perfumery',
  [ProductGroupEnum.SHOES]: 'PgShoes',
  [ProductGroupEnum.TIRES]: 'Tires',
  [ProductGroupEnum.TOBACCO]: 'PgTobacco',
  [ProductGroupEnum.WATER]: 'PgWater',
  [ProductGroupEnum.WHEELCHAIRS]: 'Wheelchairs',
  [ProductGroupEnum.TELEPHONES]: 'PgTelephones',
  [ProductGroupEnum.LAPTOPS]: 'PgScreen',
  [ProductGroupEnum.CONSERVE]: 'PgConserve',
  [ProductGroupEnum.CHEMISTRY]: 'PgChemistry',
  [ProductGroupEnum.SOFTDRINKS]: 'PgSoftDrinks',
});
