import { handleActions } from 'redux-actions';

import * as actions from './SignDocuments.actions';

const documents = handleActions(
  {
    [actions.mount]: () => [],
    [actions.loaded]: (_state, { payload }) => payload,
  },
  []
);

const open = handleActions(
  {
    [actions.open]: () => true,
    [actions.close]: () => false,
  },
  false
);

const signing = handleActions(
  {
    [actions.sign]: () => true,
    [actions.signed]: () => false,
    [actions.signError]: () => false,
    [actions.open]: () => false,
  },
  false
);

const error = handleActions(
  {
    [actions.sign]: () => null,
    [actions.close]: () => null,
    [actions.signError]: (_state, { payload }) => payload,
  },
  null
);

const ownerError = handleActions(
  {
    [actions.mount]: () => false,
  },
  false
);

const SignDocumentsInfoDefault = { name: '' };

const SignDocumentsInfo = handleActions(
  {
    [actions.setSignDocumentsInfo]: (_, { payload }) => payload,
    [actions.close]: () => SignDocumentsInfoDefault,
  },
  SignDocumentsInfoDefault
);

export const reducer = {
  documents,
  open,
  signing,
  error,
  ownerError,
  SignDocumentsInfo,
};
