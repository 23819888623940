import React, { useCallback } from 'react';
import { useBooleanState } from '../../hooks';

import { styles } from './HelpCard.styles';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  withStyles,
} from '@material-ui/core';
import { Icon, Link } from '@crpt-ui/core';
import DocumentLink from './DocumentLink';
import DocumentsDialog from './DocumentsDialog';
import CardTitle from './CardTitle';

import type { Document } from './HelpCard.types';
import type { WithStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';

export interface HelpCardProps extends WithStyles<typeof styles> {
  title: string;
  icon: string;
  documents: Document[];
  onLinkClick: (props: Document) => void;
}

const HelpCard: React.FC<HelpCardProps> = ({
  title,
  icon,
  onLinkClick: onLinkClickProps,
  documents = [],
  classes,
}) => {
  const [isOpenDocsDialog, { on, off }] = useBooleanState();
  const { t } = useTranslation();

  const onLinkClick = useCallback(
    (document) => () => onLinkClickProps(document),
    [onLinkClickProps]
  );

  const documentsLinkText = t('DOCUMENTS_COUNT', {
    count: documents.length,
  });

  return (
    <Card className={classes.root} data-test={`help-card-${icon}`} variant="outlined">
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        justifyContent="space-between"
        maxWidth="100%"
      >
        <CardContent className={classes.content}>
          {icon && (
            <div className={classes.iconWrapper}>
              <Icon className={classes.icon} name={icon} />
            </div>
          )}
          <CardTitle title={title} />
        </CardContent>
        <CardActions className={classes.actions}>
          {documents.length === 1 ? (
            <DocumentLink
              data-test={`link.document-${icon}`}
              name={documents[0].title}
              onClick={onLinkClick(documents[0])}
            />
          ) : (
            <Link
              className={classes.link}
              component="button"
              data-test={`link.text-${icon}`}
              icon={<Icon className={classes.chevron} name="Chevron" />}
              onClick={on}
            >
              {documentsLinkText}
            </Link>
          )}
        </CardActions>
        <DocumentsDialog onClose={off} open={isOpenDocsDialog} title={title}>
          {sortBy(documents, ['orderId']).map((document) => {
            return <DocumentLink
              data-test={`link.document-${document.id}`}
              key={document.title}
              name={document.title}
              onClick={onLinkClick(document)}
            />
          })}
        </DocumentsDialog>
      </Box>
    </Card>
  );
};

export default withStyles(styles)(HelpCard);
