import React, { useEffect, useMemo, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { Box, Grid } from '@material-ui/core';

import { Button, Icon, RequiredFieldsWarning } from '@crpt-ui/core';

import { RUSSIA_COUNTRY_CODE } from '../Production.constants';
import { Autocomplete } from '../../../../common_components/Form/Autocomplete';
import { Input } from '../../../../common_components/Form/Input';
import { Modal } from '../../../../common_components/Modal/Modal';
import {
  getCurrentProductionLine,
  getOpenModal,
  getProductionCountries,
  getSubmitting,
} from '../ducks/Production.selectors';
import { closeProductionSiteModal } from '../ducks/Production.actions';
import { required } from '../../../../common_components/Form/utils/validations';

const name = 'productionLines';

const ProductionSiteModalView = (props) => {
  const {
    addProductionLine,
    removeProductionLineField,
    closeModal,
    countries,
  } = props;
  const {
    hasValidationErrors,
    values,
    onSubmit,
    isEditForm,
    submitting,
    isOpenModal,
  } = props;
  const [disabledAddress, setDisabledCity] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    ref?.current?.children[1]?.children[0]?.children[0]?.children[1]?.children[0].focus();
    ref?.current?.children[1]?.children[0]?.children[0]?.children[1]?.children[0].blur();
  }, [ref.current]);

  useEffect(() => {
    setDisabledCity(
      values.productionCountryNumericCode !== RUSSIA_COUNTRY_CODE
    );
  }, [values.productionCountryNumericCode]);

  const _propsAddressField = disabledAddress
    ? { required: false, disabled: true }
    : { required: true, disabled: false };

  const modalActions = useMemo(
    () => [
      {
        label: `${isEditForm ? 'Изменить' : 'Добавить'} площадку`,
        callback: () => onSubmit(values),
        disabled: hasValidationErrors || submitting,
        loading: submitting,
      },
      {
        label: 'Отменить',
        callback: closeModal,
        variant: 'outlined',
      },
    ],
    [closeModal, hasValidationErrors, isEditForm, onSubmit, submitting, values]
  );

  return (
    <Modal
      actions={modalActions}
      open={isOpenModal}
      title="Добавление производственных площадок"
      onClose={closeModal}
    >
      <Box mb={2}>
        <RequiredFieldsWarning error={hasValidationErrors} />
      </Box>
      <Grid container direction="column" ref={ref} spacing={2}>
        <Grid item>
          <Input required label="Наименование" name="productionPlaceName" />
        </Grid>
        <Grid item>
          <Autocomplete
            disableClearable
            required
            label="Страна производства"
            name="productionCountryNumericCode"
            options={countries}
            validate={required}
          />
        </Grid>
        <Grid item>
          <Input required label="Адрес производства" name="productionAddress" />
        </Grid>
        <Grid item>
          {values?.productionLines?.map((_item, index) => (
            <Box key={`productionLines.${index}`} mb={2}>
              <Grid container direction="row" key={`${name}.${index}`}>
                <Grid item xs={index === 0 ? 12 : 10}>
                  <Input
                    required
                    label="Производственная линия"
                    name={`${name}.${index}`}
                  />
                </Grid>
                {index !== 0 && (
                  <Grid item xs={2}>
                    <Grid container justify="flex-end">
                      <Button
                        color="error"
                        icon={<Icon name="trash" />}
                        variant="outlined"
                        onClick={() => removeProductionLineField(index)}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          ))}
        </Grid>

        <Grid item>
          <Button
            icon={<Icon name="plus" />}
            variant="outlined"
            onClick={addProductionLine}
          >
            Добавить
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  countries: getProductionCountries(state),
  initialValues: getCurrentProductionLine(state),
  submitting: getSubmitting(state),
  isOpenModal: getOpenModal(state),
});

const mapDispatchToProps = {
  closeProductionSiteModal,
};

export const ProductionSiteModalViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductionSiteModalView);
