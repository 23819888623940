import React from 'react';

import { Box } from '@material-ui/core';

import { RequiredFieldsWarning } from '@crpt-ui/core';

import { Textarea } from '../../../../common_components/Form/Textarea';
import {
  Modal,
  ModalActionsType,
} from '../../../../common_components/Modal/Modal';

interface CancellationViewProps {
  title: string;
  label: string;
  onClose: () => void;
  modalActions: ModalActionsType;
  isOpenModal: boolean;
  hasValidationErrors: boolean;
}

export const CancellationView: React.FC<CancellationViewProps> = ({
  title,
  label,
  onClose,
  modalActions,
  isOpenModal,
  hasValidationErrors,
}) => (
  <Modal
    actions={modalActions}
    open={isOpenModal}
    title={title}
    useCloseIcon={false}
    onClose={onClose}
  >
    <Box mb={2}>
      <RequiredFieldsWarning error={hasValidationErrors} />
    </Box>
    <Textarea required label={label} name="comment" rows={3} />
  </Modal>
);
