/* eslint-disable @typescript-eslint/ban-ts-comment */
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { cloneElement } from 'react';

const red = '#f34747';

export const renderDay = (
  day: MaterialUiPickersDate,
  selectedDate: MaterialUiPickersDate,
  dayInCurrentMonth: boolean,
  dayComponent: React.ReactElement
) =>
  cloneElement(dayComponent, {
    disableRipple: true,
    style: {
      // @ts-ignore
      color: [0, 6].includes(new Date(day).getDay()) ? red : null,
    },
  });
