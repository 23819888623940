import { UIEvent } from 'react';

import checkElementLineClamp from '../utils/checkElementLineClamp';
import checkElementNowrap from '../utils/checkElementNowrap';
import { useBooleanState } from './useBooleanState';

/**
 * Хук контролирует state в зависимости от состаяния элемента с переносом строки
 *
 * @param {string} [lineBreakType]
 * 'nowrap' если вы используете однострочный переснос white-space: nowrap.
 * 'lineclamp' если вы используете многострочный перенос -webkit-line-clamp.
 */

export const useTextWrapState = (
  lineBreakType: 'nowrap' | 'lineclamp' = 'nowrap'
) => {
  const [state, { on, off }] = useBooleanState();

  const onCheckWrap = (event: UIEvent<HTMLElement>) => {
    const element = event.currentTarget;

    if (lineBreakType === 'nowrap' && checkElementNowrap(element)) {
      on();
    }

    if (lineBreakType === 'lineclamp' && checkElementLineClamp(element)) {
      on();
    }
  };

  return {
    state,
    onCheckWrap,
    off,
  };
};
