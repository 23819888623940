import { takeEvery, call, put } from 'redux-saga/effects';

import { AuthService } from '../../../services/AuthService';
import { saveToken } from '../../../utils/cookieHelpers';

import * as actions from './LoginForm.actions';

function* loginSaga(action) {
  const { payload } = action;

  try {
    const answer = yield call(AuthService.login, payload);
    const url = answer.request.responseURL || '';

    if (url.indexOf('Error') !== -1) {
      yield put(actions.errors());
    } else {
      const token = answer.data.token;
      yield call(saveToken, token);
      yield put(actions.errors());
      window.location.replace(`${window.location.origin}/`);
    }
  } catch (e) {
    yield put(actions.errors('Неверный логин или пароль'));
  }
}

export const saga = function* watch() {
  yield takeEvery(actions.submit, loginSaga);
};
