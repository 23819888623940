import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import { TableRow } from './components';

export const DatatableRow = ({ row, onClick, children, ...rest }) => {
  const handleClick = useCallback(
    (event) => {
      if (typeof onClick === 'function') {
        const role = event.target.getAttribute('role');

        if (role && role === 'cell') {
          onClick(event, row);
        }
      }
    },
    [onClick, row]
  );

  const isDisabled = useMemo(() => _.chain(row).get('state.disabled').value(), [
    row,
  ]);

  const isInGroup = useMemo(() => _.chain(row).get('state.inGroup').value(), [row]);
  
  const isGrouping = useMemo(() => _.chain(row).get('state.grouping').value(), [row]);

  const isSelected = useMemo(() => _.chain(row).get('isSelected').value(), [
    row,
  ]);

  return (
    <TableRow
      disabled={isDisabled}
      onClick={handleClick}
      selected={isSelected}
      inGroup={isInGroup}
      grouping={isGrouping}
      {...rest}
    >
      {children}
    </TableRow>
  );
};

DatatableRow.propTypes = {
  row: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default DatatableRow;
