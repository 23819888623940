import * as Icons from '@crpt-ui/icons';
import {
  ButtonBase,
  ClickAwayListener,
  MenuItem as MuiMenuItem,
  MenuList,
  Tooltip as MuiTooltip,
  withStyles,
} from '@material-ui/core';
import get from 'lodash.get';
import invoke from 'lodash.invoke';
import PropTypes from 'prop-types';
import React from 'react';

const Tooltip = withStyles((theme) => ({
  tooltip: {
    background: theme.palette.text.primary,
    minWidth: 192,
    padding: 0,
  },
  arrow: {
    color: theme.palette.text.primary,
  },
}))(MuiTooltip);

const MenuItem = withStyles((theme) => ({
  root: {
    '&, &:focus': {
      background: theme.palette.text.primary,
    },
    '&:hover': {
      background: '#6D7074',
    },
    color: '#fff',
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 16 / 13,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
  },
}))(MuiMenuItem);

const getRowActionAttrValue = (action, row, attr) => {
  if (typeof get(action, attr) === 'function') {
    return invoke(action, attr, row);
  }

  return get(action, attr, false);
};

export const RowActionsCell = ({ actions, row }) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    document.body.style.overflow = 'hidden';
    setOpen(true);
  };

  const handleClose = () => {
    document.body.style.overflow = null;
    setOpen(false);
  };

  const handleMenuItemClick = (event, action) => {
    event.preventDefault();
    action.callback(row);
    handleClose();
  };

  const preparedActions = React.useMemo(
    () =>
      actions
        .map((action, index) => ({
          ...action,
          disabled: getRowActionAttrValue(action, row, 'disabled'),
          hidden: getRowActionAttrValue(action, row, 'hidden'),
          key: index,
        }))
        .filter((action) => !action.hidden),
    [actions, row]
  );

  const isDisabled = React.useMemo(
    () =>
      get(row, 'state.disabled', false) ||
      preparedActions.every((action) => action.hidden || action.disabled),
    [preparedActions, row.state]
  );

  const renderMenuItem = (action) => (
    <MenuItem
      disabled={action.disabled}
      key={action.label}
      onClick={(event) => handleMenuItemClick(event, action)}
    >
      {action.label}
    </MenuItem>
  );

  const renderMenuItems = () =>
    preparedActions.map((action) => renderMenuItem(action));

  return (
    <Tooltip
      arrow
      placement="left"
      interactive
      TransitionProps={{
        timeout: 0,
      }}
      open={open}
      title={
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList>{renderMenuItems()}</MenuList>
        </ClickAwayListener>
      }
    >
      <ButtonBase
        disabled={isDisabled}
        onClick={handleClick}
        disableRipple
        disableTouchRipple
        style={{
          height: 64,
          marginBottom: -24,
          marginLeft: -8,
          marginRight: -8,
          marginTop: -24,
          opacity: isDisabled ? 0.5 : 1,
          verticalAlign: 'top',
          width: 40,
        }}
      >
        <Icons.Dots />
      </ButtonBase>
    </Tooltip>
  );
};

export const makeRowActionsCell = (actions) => (props) =>
  React.createElement(RowActionsCell, {
    ...props,
    actions: actions,
  });

RowActionsCell.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    })
  ).isRequired,
  row: PropTypes.object.isRequired,
};

export default RowActionsCell;
