import instance from '../axios';
import { DOC_HELPER_BASE_PATH } from './constants';

export interface DocHelperCardListParams {
  productGroups: number[];
}

export interface DocHelperConfig {
  docHelperPath: string;
}

export type HelpListDocument = {
  id: number;
  title: string;
  link: string;
  linkPdf: string;
  productGroups: number[];
};

export interface DocHelperCard {
  id: number;
  title: string;
  iconName: string;
  orderId: number;
  documents: HelpListDocument[];
}

export type HelpDocument = {
  id: number;
  title: string;
  link: string[];
  productGroups: number[];
  orderId: number;
  isSelected?: boolean;
};

export interface HelperCard {
  id: number;
  title: string;
  iconName: string;
  orderId: number;
  documents: HelpDocument[];
}

export interface DocHelperStorageFileParams {
  filePath: string;
  config?: {
    path: string;
  };
}

export type DocHelperStorageFile = ArrayBuffer;

class DocHelperService {
  getDocList(params?: DocHelperCardListParams, config?: DocHelperConfig) {
    return instance.post<HelperCard[]>(
      `${config?.docHelperPath || DOC_HELPER_BASE_PATH}/card/list`,
      {
        product_groups: params?.productGroups,
      }
    );
  }

  getStorageFile(
    params?: DocHelperStorageFileParams,
    config?: DocHelperConfig
  ) {
    return instance.get<DocHelperStorageFile>(
      `${config?.docHelperPath || DOC_HELPER_BASE_PATH}/storage/download`,
      {
        responseType: 'arraybuffer',
        params: {
          filePath: params?.filePath,
        },
      }
    );
  }
}

export const docHelperService = new DocHelperService();
