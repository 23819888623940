import React, { useCallback, useEffect, useMemo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { HistoryType } from '../../../../constants/PropTypes';
import { withForm } from '../../../../common_components/hocs/withForm';
import {
  getDocumentInfoLoaded,
  getLoading,
  isShowAcceptanceContentCode,
  isShowSignTab,
} from '../ducks/ClosingDocument.selectors';

import { schema } from './SignClosingDocument.schema';
import { startDocumentSignSaga } from './ducks/SignClosingDocument.actions';
import {
  getSignData,
  isSigningInProcess,
} from './ducks/SignClosingDocument.selectors';
import { SignDocumentView } from './SignClosingDocument.view';

export const SignClosingDocument = ({
  values,
  form,
  data,
  startDocumentSignSaga,
  isShowSignTab,
  isShowAcceptanceContentCode,
  isLoaded,
  isSigningInProcess,
  isLoading,
  hasValidationErrors,
  history,
  match,
}) => {
  const documentId = useMemo(() => match.params.id, [match.params.id]);

  const goToViewDocument = useCallback(() => {
    history.push(`/documents/cd/${documentId}`);
  }, [documentId, history]);

  /**
   * If the document is signed, switches to the document view page
   */
  useEffect(() => {
    if (!isShowSignTab && isLoaded) {
      goToViewDocument();
    }
  }, [goToViewDocument, isLoaded, isShowSignTab]);

  useEffect(() => {
    form.initialize(data);
  }, [data, form.initialize]);

  const onSubmitForm = useCallback(() => {
    startDocumentSignSaga({
      values,
    });
  }, [values, startDocumentSignSaga]);

  return React.createElement(SignDocumentView, {
    values,
    onSubmitForm,
    goToViewDocument,
    isShowAcceptanceContentCode,
    hasValidationErrors,
    isSigningInProcess,
    isLoading,
  });
};

SignClosingDocument.propTypes = {
  history: HistoryType,
  startDocumentSignSaga: PropTypes.func.isRequired,
  isShowSignTab: PropTypes.bool.isRequired,
  isShowAcceptanceContentCode: PropTypes.bool.isRequired,
  hasValidationErrors: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isShowSignTab: isShowSignTab(state),
  isShowAcceptanceContentCode: isShowAcceptanceContentCode(state),
  isLoaded: getDocumentInfoLoaded(state),
  isSigningInProcess: isSigningInProcess(state),
  data: getSignData(state),
  isLoading: getLoading(state),
});

const mapDispatchToProps = {
  startDocumentSignSaga,
};

export const SignClosingDocumentConnected = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withForm({
    schema,
  })
)(SignClosingDocument);
