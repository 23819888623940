import { handleActions } from 'redux-actions';

import { closeReceiptsDialog } from './ReceiptsDialog.actions';

const open = handleActions(
  {
    [closeReceiptsDialog]: () => false,
  },
  !window.localStorage.getItem('ReceiptsDialogDetached')
);

export const reducer = {
  open,
};
