import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { Notification } from '../../Notification/Notification.types';

const initialState: { notifications: Notification[] | null } = {
  notifications: null,
};

const instance = axios.create();

const fetchNotifications = createAsyncThunk(
  'Notifications/loadNotifications',
  async (productGroups: string[]) => {
    return await instance.request({
      timeout: 120000,
      url: '/api/v3/facade/notification/elk/list',
      method: 'post',
      data: {
        filtered: [
          {
            id: 'productGroups',
            value: productGroups,
          },
          {
            id: 'activated',
            value: true,
          },
        ],
      },
    });
  }
);

const { reducer, actions } = createSlice({
  name: 'Route',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.notifications = action.payload.data;
    });
  },
});

export { actions, reducer, fetchNotifications };
