import { fade, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { toast, ToastBar, Toaster } from 'react-hot-toast';

import { ReactComponent as Rejected } from './Rejected.svg';

const useClasses = makeStyles(
  (theme) => ({
    root: {
      overflow: 'hidden',
      backdropFilter: 'blur(4px)',
      background: fade(theme.palette.common.black, 0.6),
      borderRadius: 4,
      boxShadow: 'none',
      color: theme.palette.common.white,
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 20 / 14,
      padding: 8,
      maxWidth: 360,
      textAlign: 'left',
      width: 360,
    },
    error: {
      background: fade(theme.palette.error.main, 0.8),
      color: theme.palette.common.white,
    },
    success: {
      background: fade(theme.palette.success.main, 0.8),
      color: theme.palette.common.white,
    },
    close: {
      cursor: 'pointer',
      position: 'absolute',
      right: 0,
      top: 0,
    },
    message: {
      margin: '4px 10px',
    },
  }),
  {
    name: 'Toast',
  }
);

export default () => {
  const classes = useClasses();

  return (
    <Toaster
      gutter={8}
      toastOptions={{
        className: classes.root,
        duration: 7500,
        error: {
          className: clsx(classes.root, classes.error),
        },
        icon: null,
        success: {
          className: clsx(classes.root, classes.success),
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon }) => (
            <>
              {icon}
              <span
                className={classes.message}
                dangerouslySetInnerHTML={{
                  __html: String(t.message),
                }}
              />
              {t.type !== 'loading' && (
                <span
                  className={classes.close}
                  onClick={() => toast.dismiss(t.id)}
                >
                  <Rejected />
                </span>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};
