import { Link, Typography } from '@crpt-ui/core';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { TableNamesEnum } from './ViolationsRegistryTable.types';

import type {
  ViolationsDataRow,
  ViolationsDataByTypeRow,
  ViolationsDataByInnRow,
} from './ViolationsRegistryTable.types';
import {
  makeCheckboxGroupFilter,
  makeInputFilter,
  makeSelectFilter,
} from '@crpt-ui/datatable';

export const TABLE_NAMES = {
  VIOLATIONS_REGISTRY: TableNamesEnum.ViolationsRegistry,
  VIOLATIONS_REGISTRY_BY_TYPE: TableNamesEnum.ViolationsRegistryByType,
  VIOLATIONS_REGISTRY_BY_INN: TableNamesEnum.ViolationsRegistryByInn,
};

const ACCESS_TYPE = {
  VIOLATIONS_REGISTRY: {
    TYPE: 'type',
    COUNT: 'count',
  },
  VIOLATIONS_REGISTRY_BY_TYPE: {
    PARTICIPANT: 'participant.name',
    COUNT: 'count',
    TYPE: 'participant.type',
    REGION: 'region',
  },
  VIOLATIONS_REGISTRY_BY_INN: {
    DATETIME: 'datetime',
    PRODUCT: 'product.code',
  },
};

export const useColumns = (
  {
    name,
    data,
  }: {
    name?: string;
    data: any[];
  } = {
    data: [],
  }
) => {
  const { t } = useTranslation();

  const participantTypesFilterNames = [
    'Участник оборота товаров',
    'Производитель',
    'Импортер',
    'Розница',
    'Оптовая торговля',
    'ФОИВ',
  ];

  const participantTypesOptions = _.chain(participantTypesFilterNames)
    .map((label) => ({
      label,
      value: label,
    }))
    .sortBy('label')
    .value();

  const regions = t(`REGION`, {
    returnObjects: true,
  });
  const regionsOptions = _.chain(regions)
    .map((label, value) => ({
      label,
      value,
    }))
    .sortBy('label')
    .value();

  const columns = useMemo(() => {
    switch (name) {
      case TABLE_NAMES.VIOLATIONS_REGISTRY_BY_TYPE:
        return [
          {
            Header: t('VIOLATIONS_REGISTRY_BY_TYPE.HEADERS.PARTICIPANT'),
            accessor: ACCESS_TYPE.VIOLATIONS_REGISTRY_BY_TYPE.PARTICIPANT,
            Filter: makeInputFilter(),
            Cell: ({ row }: { row: ViolationsDataByTypeRow }) => (
              <Typography variant="caption">
                {row.original.participant.name}
                <br />
                ИНН: {row.original.participant.inn}
              </Typography>
            ),
            id: 'violationParticipant',
            filter: (rows: any, key: any, value: any) => {
              return _.chain(rows)
                .filter(
                  (row) =>
                    row.original.participant?.name
                      .toLowerCase()
                      .includes(value.toLowerCase()) ||
                    row.original.participant?.inn.includes(value)
                )
                .value();
            },
            disableSortBy: true,
          },
          {
            Header: t('VIOLATIONS_REGISTRY_BY_TYPE.HEADERS.COUNT'),
            accessor: ACCESS_TYPE.VIOLATIONS_REGISTRY_BY_TYPE.COUNT,
            Cell: ({ row }: { row: ViolationsDataByTypeRow }) => (
              <Link
                // eslint-disable-next-line
                //@ts-ignore
                component={RouterLink}
                to={`${window.location.pathname}/${row.original.participant.inn}`}
              >
                {new Intl.NumberFormat('ru-RU').format(row.original.count)}
              </Link>
            ),
            id: 'violationCount',
          },
          {
            Header: t('VIOLATIONS_REGISTRY_BY_TYPE.HEADERS.PARTICIPANT_TYPE'),
            accessor: ACCESS_TYPE.VIOLATIONS_REGISTRY_BY_TYPE.TYPE,
            disableSortBy: true,
            Cell: ({ row }: { row: ViolationsDataByTypeRow }) => {
              const { t } = useTranslation();
              return (
                <>
                  {row.original.participant?.type?.length
                    ? row.original.participant.type
                        .map((type) => t(`PARTICIPANT_TYPE.${type}`))
                        .join(', ')
                    : '—'}
                </>
              );
            },
            id: 'violationParticipantType',
            Filter: makeCheckboxGroupFilter({
              options: participantTypesOptions,
            }),
            filter: (rows: any, key: any, value: any) => {
              return _.chain(rows)
                .filter(
                  (row) =>
                    _.intersection(
                      value,
                      row.original.participant.type.map(
                        // eslint-disable-next-line
                        // @ts-ignore
                        (type) => t(`PARTICIPANT_TYPE.${type}`)
                      )
                    ).length
                )
                .value();
            },
          },
          {
            Header: t('VIOLATIONS_REGISTRY_BY_TYPE.HEADERS.REGION'),
            accessor: ACCESS_TYPE.VIOLATIONS_REGISTRY_BY_TYPE.REGION,
            id: 'violationRegion',
            Cell: ({ cell }: any) => {
              const { t } = useTranslation();
              return t(`REGION.${cell.value}`);
            },
            Filter: makeSelectFilter({
              options: regionsOptions,
            }),
            disableSortBy: true,
          },
        ];

      case TABLE_NAMES.VIOLATIONS_REGISTRY_BY_INN:
        return [
          {
            Header: t('VIOLATIONS_REGISTRY_BY_INN.HEADERS.DATETIME'),
            accessor: ACCESS_TYPE.VIOLATIONS_REGISTRY_BY_INN.DATETIME,
            Cell: ({ row }: { row: ViolationsDataByInnRow }) => {
              const momentFinishTime = moment(row.original.datetime);

              return momentFinishTime.isValid()
                ? momentFinishTime.format('DD.MM.YYYY')
                : '-';
            },
            id: 'violationDatetime',
          },
          {
            Header: t('VIOLATIONS_REGISTRY_BY_INN.HEADERS.PRODUCT'),
            accessor: ACCESS_TYPE.VIOLATIONS_REGISTRY_BY_INN.PRODUCT,
            Cell: ({ row }: { row: ViolationsDataByInnRow }) => (
              <Typography variant="caption">
                <Link
                  // eslint-disable-next-line
                  //@ts-ignore
                  component={RouterLink}
                  to={`/cis/${encodeURIComponent(row.original.product.code)}`}
                >
                  {row.original.product.code}
                </Link>
                <br />
                {row.original.product.name}
              </Typography>
            ),
            id: 'violationProduct',
            Filter: makeInputFilter(),
            disableSortBy: true,
          },
        ];
      default:
        return [
          {
            Header: t('VIOLATIONS_REGISTRY.HEADERS.TYPE'),
            accessor: 'name',
            Filter: makeCheckboxGroupFilter({
              options: _.chain(data)
                .map((item) => ({
                  label: item.name,
                  value: item.type,
                }))
                .sortBy('label')
                .value(),
            }),
            filter: (rows: any, key: any, value: any) =>
              _.chain(rows)
                .filter((row) => value.includes(row.original.type))
                .value(),
            Cell: ({ row }: { row: ViolationsDataRow }) => (
              <Link
                // eslint-disable-next-line
                //@ts-ignore
                component={RouterLink}
                to={`${window.location.pathname}/${row.original.type}`}
              >
                {row.original.name}
              </Link>
            ),
            id: 'violationType',
          },
          {
            Header: t('VIOLATIONS_REGISTRY.HEADERS.COUNT'),
            accessor: ACCESS_TYPE.VIOLATIONS_REGISTRY.COUNT,
            id: 'violationCount',
            Cell: ({ row }: { row: ViolationsDataRow }) =>
              new Intl.NumberFormat('ru-RU').format(row.original.count),
          },
        ];
    }
  }, [name, t, data]);

  return columns;
};
