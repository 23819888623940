import { call, put, takeEvery } from 'redux-saga/effects';

import { Api } from '../../../../common_components/Api/Api';
import { fetchSDDocuments } from '../../../_ServiceDesk/SignDocuments/ducks/sagas/SDDocuments';
import {
  API_ENVIRONMENT,
  definitionEnvironment,
} from '../ServiceDeskDocument.utils';

import {
  fetchServiceDeskDocument,
  setDocumentContent,
  setDocumentInfo,
  setLoading,
} from './ServiceDeskDocument.actions';

function* fetchEdoDocumentContent({ document_direction, edoDocumentId }) {
  const requestPayload = {
    url: `/edo-api/${document_direction}/${edoDocumentId}/print`,
    responseType: 'arraybuffer',
  };

  const [success] = yield call(Api.request, requestPayload, {
    preloading: false,
  });

  if (success) {
    return success.data;
  }
}

function* fetchCrmDocumentContent({ guid }) {
  const requestPayload = {
    url: '/crm-api/document',
    responseType: 'arraybuffer',
    params: {
      guid,
    },
  };

  const [success] = yield call(Api.request, requestPayload, {
    preloading: false,
  });

  if (success) {
    return success.data;
  }
}

export function* fetchServiceDeskDocumentSaga({ payload: guid }) {
  yield put(setLoading(true));

  const [success] = yield call(fetchSDDocuments);

  const documentInfo = success.data.response.find(
    (document) => document.guid === Number(guid)
  );

  const { document_source, document_direction } =
    definitionEnvironment(documentInfo);

  let documentContent;
  if (
    document_source === API_ENVIRONMENT.EDO &&
    Boolean(documentInfo.edoDocumentId)
  ) {
    documentContent = yield call(fetchEdoDocumentContent, {
      document_direction,
      edoDocumentId: documentInfo.edoDocumentId,
    });
  } else {
    documentContent = yield call(fetchCrmDocumentContent, {
      guid: documentInfo.document,
    });
  }

  yield put(setDocumentContent(documentContent));
  yield put(setDocumentInfo(documentInfo));

  yield put(setLoading(false));
}

export const saga = function* watch() {
  yield takeEvery(fetchServiceDeskDocument, fetchServiceDeskDocumentSaga);
};
