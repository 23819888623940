import { Box, Grid, Stack, styled } from '@mui/material';
import React, { ComponentProps, FC, useCallback } from 'react';

import { productGroupService } from '../../services';

export interface BalanceWidgetProps extends ComponentProps<'div'> {
  accounts: {
    balance: number;
    contractId?: number;
    productGroupId: number;
  }[];
}

const BalanceWidget: FC<BalanceWidgetProps> = ({ accounts = [], ...rest }) => {
  const renderBalance = useCallback(
    (balance?: number) =>
      (balance ?? 0).toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
      }),
    []
  );

  return accounts.length ? (
    <div {...rest}>
      <Stack spacing={2}>
        {accounts.map((account) => (
          <Grid
            container
            key={account.productGroupId}
            sx={{
              flexWrap: 'nowrap',
            }}
          >
            <Grid item>
              <Box
                sx={{
                  mr: '8px',
                  width: 32,
                  height: 32,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                  background: '#f6f42e',
                }}
              >
                {productGroupService.renderIcon(account.productGroupId)}
              </Box>
            </Grid>
            <Grid
              item
              sx={{
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  fontSize: 10,
                  lineHeight: 16 / 10,
                  fontWeight: 600,
                }}
              >
                ЛС&nbsp;{account.contractId}
              </Box>
              <Box
                sx={{
                  fontSize: 12,
                  lineHeight: 16 / 12,
                  fontWeight: 600,
                }}
              >
                {renderBalance(account.balance)} руб.
              </Box>
            </Grid>
          </Grid>
        ))}
      </Stack>
    </div>
  ) : null;
};

export default styled(BalanceWidget)({
  background: '#fcfcfc',
  boxShadow: 'inset 0px 1px 0px #f2f2f2, inset 0px -1px 0px #f2f2f2',
  padding: 16,
});
