import { createSelector } from 'reselect';

import { getClosingDocumentState } from '../../ducks/ClosingDocument.selectors';

export const getEventsState = createSelector(
  [getClosingDocumentState],
  (state) => state.Events
);

export const getDocumentEvents = createSelector(
  [getEventsState],
  (state) => state.events
);

export const getLoading = createSelector(
  [getEventsState],
  (state) => state.loading
);
