import { handleActions } from 'redux-actions';

import { onMount, onUnmount } from './SupportIncidentDialog.actions';

const open = handleActions(
  {
    [onMount]: () => true,
    [onUnmount]: () => false,
  },
  false
);

const data = handleActions(
  {
    [onMount]: (_, { payload }) => payload,
  },
  {}
);

export const reducer = {
  data,
  open,
};
