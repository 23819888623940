import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  link: {
    fontWeight: 600,
    color: '#4aa5e8',

    '& svg': {
      fontSize: 22,
    },
    '& svg g': {
      fill: '#4aa5e8',
    },
  },
});
