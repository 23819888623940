import { createSelector } from 'reselect';

import {
  getEmail,
  getPhone,
} from '../../../components/_Profile/Company/ducks/Company.selectors';

const getPaymentDialogState = (state) => state.COMMON.PaymentDialog;

export const getOpenReceiptsDialog = createSelector(
  [getPaymentDialogState],
  (paymentDialogState) => paymentDialogState.open
);

export const getLoading = createSelector(
  [getPaymentDialogState],
  (paymentDialogState) => paymentDialogState.loading
);

export const getFormData = createSelector(
  [getPhone, getEmail],
  (phone, email) => ({
    phone,
    email,
  })
);
