/* eslint-disable */
import axios from 'axios';

import { authInstance } from './authInstance';

// TODO проверить searchByInput когда будут данные и поменять url

const CancelToken = axios.CancelToken;

export class ParticipantService {
  static _searchParticipantCancel;
  static _getListCancel;

  static getParticipantInfo(id) {
    return authInstance.request({
      url: `/private-office-api/private/v2/profile/company/${id}`,
      method: 'get',
    });
  }

  static getParticipantInfoByInn(inn) {
    return authInstance.request({
      url: `/api/v3/facade/participants/${inn}`,
      method: 'get',
    });
  }

  static searchByInputForGrid(input) {
    if (ParticipantService._searchParticipantCancel)
      ParticipantService._searchParticipantCancel('PREVENT_TOAST');

    return authInstance.request({
      url: `/api/v3/org/registry/suggestions/short?name=${encodeURIComponent(
        input
      )}&limit=10`,
      method: 'get',
      cancelToken: new CancelToken(function (c) {
        ParticipantService._searchParticipantCancel = c;
      }),
    });
  }

  static searchByInputGlobal(params) {
    if (ParticipantService._searchGlobal)
      ParticipantService._searchGlobal('PREVENT_TOAST');

    return authInstance.request({
      url: '/api/v3/facade/participants/search',
      method: 'get',
      params,
      cancelToken: new CancelToken(function (c) {
        ParticipantService._searchGlobal = c;
      }),
    });
  }

  static searchProducerByInput(input) {
    if (ParticipantService._searchProducerCancel)
      ParticipantService._searchProducerCancel('PREVENT_TOAST');

    return authInstance.request({
      url: `/api/v3/org/registry/suggestions/?name=${encodeURIComponent(
        input
      )}&limit=10&orgTypes=PRODUCER,IMPORTER`,
      method: 'get',
      cancelToken: new CancelToken(function (c) {
        ParticipantService._searchProducerCancel = c;
      }),
    });
  }

  static searchByInput(input, skipCancel) {
    if (!skipCancel && ParticipantService._searchParticipantCancel)
      ParticipantService._searchParticipantCancel('PREVENT_TOAST');

    return authInstance.request({
      url: `/api/v3/org/registry/suggestions?name=${encodeURIComponent(input)}`,
      method: 'get',
      cancelToken: new CancelToken(function (c) {
        ParticipantService._searchParticipantCancel = c;
      }),
    });
  }

  static getWarehouse(params) {
    const data = { needTotal: true, ...params };

    return authInstance.request({
      url: '/api/v3/facade/storage',
      method: 'get',
      params: data,
    });
  }

  static getWarehouseFile(params) {
    const data = { needTotal: true, ...params };

    return authInstance.request({
      url: '/api/v3/facade/storage/csv',
      method: 'get',
      responseType: 'blob',
      params: data,
    });
  }

  static getXls(inn) {
    return authInstance.request({
      url: `/private-office-api/private/v2/participants/${inn}/xls`,
      method: 'get',
      responseType: 'blob',
    });
  }
}
