import { createActions } from 'redux-actions';

export const {
  fetchClosingDocumentsList,
  setClosingDocumentsList,
  setPageCounts,
  setPage,
  setLoading,
  clearDocumentsList,
} = createActions(
  {
    FETCH_CLOSING_DOCUMENTS_LIST: (payload) => payload,

    SET_CLOSING_DOCUMENTS_LIST: (payload) => payload,
    SET_PAGE_COUNTS: (payload) => payload,
    SET_PAGE: (payload) => payload,

    SET_LOADING: (payload) => payload,

    CLEAR_DOCUMENTS_LIST: () => {},
  },
  {
    prefix: 'CLOSING_DOCUMENTS_LIST',
  }
);
