import { createActions } from 'redux-actions';

export const {
  mountedLoginKep,
  submit,
  setErrors,
  setLoadingButton,
  clearLoginFormKep,
  setNidKeysResponse,
} = createActions(
  {
    MOUNTED_LOGIN_KEP: () => {},

    SUBMIT: (payload) => payload,

    SET_ERRORS: (payload) => payload,
    SET_LOADING_BUTTON: (payload) => payload,

    CLEAR_LOGIN_FORM_KEP: () => {},

    SET_NID_KEYS_RESPONSE: (payload) => payload,
  },
  {
    prefix: 'LOGIN_FORM_KEP',
  }
);
