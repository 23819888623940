import React from 'react';

import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import { Modal } from '../../../../../common_components/Modal/Modal';

export const NotificationOfRejectionView = ({ onCloseModal, isOpen }) => (
  <Modal
    actions={[
      { label: 'Понятно', callback: onCloseModal, variant: 'outlined' },
    ]}
    open={isOpen}
    onClose={onCloseModal}
  >
    <Box mb={2}>Уважаемый участник оборота товаров!</Box>
    <b>Регистрация Согласия</b> о предоставлении информации о принадлежности и
    статусе маркированного товара контрагенту – участнику оборота товаров{' '}
    <b>отменена</b>, так как не все ИНН, указанные в документе, зарегистрированы
    в ГИС МТ. <br />
    Список ИНН контрагентов, не прошедших проверку, отправлен на указанный
    контактный email. Необходимо обновить шаблон и повторить попытку.
  </Modal>
);

NotificationOfRejectionView.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
