export enum EliminationTypeEnum {
  KM_SPOILED = 'KM_SPOILED',
  KM_LOST = 'KM_LOST',
  KM_DESTROYED = 'KM_DESTROYED',
  DESCRIPTION_ERRORS = 'DESCRIPTION_ERRORS',
  RETAIL = 'RETAIL',
  EAS_TRADE = 'EAS_TRADE',
  BEYOND_EEC_EXPORT = 'BEYOND_EEC_EXPORT',
  RETURN = 'RETURN',
  LOSS = 'LOSS',
  DESTRUCTION = 'DESTRUCTION',
  CONFISCATION = 'CONFISCATION',
  DONATION = 'DONATION',
  STATE_CONTRACT = 'STATE_CONTRACT',
  KM_SPOILED_OR_LOST = 'KM_SPOILED_OR_LOST',
  KM_CANCELLATION = 'KM_CANCELLATION',
  RETAIL_RETURN = 'RETAIL_RETURN',
  REMOTE_SALE_RETURN = 'REMOTE_SALE_RETURN',
  LEGAL_RETURN = 'LEGAL_RETURN',
  INTERNAL_RETURN = 'INTERNAL_RETURN',
  EEC_EXPORT_RETURN = 'EEC_EXPORT_RETURN',
  OTHER = 'OTHER',
  BY_SAMPLES = 'BY_SAMPLES',
  DISTANCE = 'DISTANCE',
  VENDING = 'VENDING',
  OWN_USE = 'OWN_USE',
  UTILIZATION = 'UTILIZATION',
  RECALL = 'RECALL',
  PACKING = 'PACKING',
  PRODUCTION_USE = 'PRODUCTION_USE',
  EXPIRATION = 'EXPIRATION',
  KM_CANCELATION = 'KM_CANCELATION',
  STATE_SECRET = 'STATE_SECRET',
  AUTO_CANCELLATION = 'AUTO_CANCELLATION',
}

export const EliminationTypeMap = {
  1: EliminationTypeEnum.KM_SPOILED,
  2: EliminationTypeEnum.KM_LOST,
  3: EliminationTypeEnum.KM_DESTROYED,
  4: EliminationTypeEnum.DESCRIPTION_ERRORS,
  10: EliminationTypeEnum.RETAIL,
  11: EliminationTypeEnum.EAS_TRADE,
  12: EliminationTypeEnum.BEYOND_EEC_EXPORT,
  13: EliminationTypeEnum.RETURN,
  15: EliminationTypeEnum.LOSS,
  16: EliminationTypeEnum.DESTRUCTION,
  17: EliminationTypeEnum.CONFISCATION,
  20: EliminationTypeEnum.DONATION,
  21: EliminationTypeEnum.STATE_CONTRACT,
  24: EliminationTypeEnum.KM_SPOILED_OR_LOST,
  26: EliminationTypeEnum.KM_CANCELLATION,
  27: EliminationTypeEnum.RETAIL_RETURN,
  28: EliminationTypeEnum.REMOTE_SALE_RETURN,
  29: EliminationTypeEnum.LEGAL_RETURN,
  30: EliminationTypeEnum.INTERNAL_RETURN,
  31: EliminationTypeEnum.EEC_EXPORT_RETURN,
  39: EliminationTypeEnum.OTHER,
  40: EliminationTypeEnum.BY_SAMPLES,
  41: EliminationTypeEnum.DISTANCE,
  42: EliminationTypeEnum.VENDING,
  46: EliminationTypeEnum.OWN_USE,
  47: EliminationTypeEnum.UTILIZATION,
  48: EliminationTypeEnum.RECALL,
  49: EliminationTypeEnum.PACKING,
  50: EliminationTypeEnum.PRODUCTION_USE,
  51: EliminationTypeEnum.EXPIRATION,
  99: EliminationTypeEnum.KM_CANCELATION,
  100: EliminationTypeEnum.STATE_SECRET,
  104: EliminationTypeEnum.AUTO_CANCELLATION,
};
