import React from 'react';

import { PaymentStatusTemplate } from './PaymentStatusTemplate';

export const PaymentStatusSuccess = () => {
  return React.createElement(PaymentStatusTemplate, {
    icon: 'Accept',
    title: 'Платеж принят',
    text: 'Деньги зачислены на лицевой счёт',
  });
};
