import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@crpt-ui/core';
import { Grid } from '@crpt/material';
import { AutocompleteMultiple } from '@crpt/shared/components/Form/Fields';
import { FieldIds } from '../../CisUnloads.types';

import type { UserListOption } from '../../../../utils';

interface ReceiverProps {
  usersList: UserListOption[];
}

const Receiver: FC<ReceiverProps> = ({ usersList }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="h6">
          {t('UnloadService.Task.CisUnloads.FieldGroup.receiver')}
        </Typography>
      </Grid>
      <Grid item xs>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AutocompleteMultiple
              additionalLabel
              disableCloseOnSelect
              name={FieldIds.USERS}
              options={usersList}
              placeholder={t('UnloadService.Task.CisUnloads.Fields.user')}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(Receiver);
