import { createActions } from 'redux-actions';

export const {
  fetchIncidents,
  setIncidentItems,
  setIncidentTotal,
  setPage,
  clearSupportList,
} = createActions(
  {
    FETCH_INCIDENTS: () => {},

    SET_INCIDENT_ITEMS: (payload) => payload,
    SET_INCIDENT_TOTAL: (payload) => payload,
    SET_PAGE: (payload) => payload,

    CLEAR_SUPPORT_LIST: () => {},
  },
  {
    prefix: 'SUPPORT',
  }
);
