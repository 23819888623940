import React, { Fragment } from 'react';

import { connect } from 'react-redux';
import { Typography } from '@mui/material';

type TitleProps = {
  step?: number;
  title: string;
};

export const Title = ({ step = 0, title /* , skipGS1Step */ }: TitleProps) => (
  <Fragment>
    <Typography align="center" variant="h6">
      {step + 1}&nbsp;
      <span style={{ color: '#B8B8B8' }}>
        / 2 {/*/&nbsp;{skipGS1Step ? 3 : StartPageTabs.length}*/}
      </span>{' '}
      {title}
    </Typography>
  </Fragment>
);

const mapStateToProps = (state: any) => ({
  step: state.StartPage.activeStep,
  skipGS1Step: state.StartPage.skipGS1Step,
});

export const TitleConnected = connect(mapStateToProps)(Title);
