import { isArray } from 'lodash';
import { all, call, cancel, put, select, takeEvery } from 'redux-saga/effects';

import { Api } from '../../../common_components/Api/Api';
import {
  GS1StepIndex,
  StartPageTabs,
  UserGroupStepIndex,
} from '../StartPage.constants';
import { HttpMethodEnum } from '../../../constants/index';
import { history } from '../../../store.js';
import { fetchProfileData } from '../../_Profile/Company/ducks/Company.saga';

import {
  addNewFieldParticipantGs1,
  fetchStartPageData,
  resetSkipGs1Step,
  setEdoList,
  setFieldParticipantGs1,
  setFinishStep,
  setLoading,
  setNewFieldParticipantGs1,
  setNextStep,
  setParticipantDataGs1,
  setPreviousStep,
  setProductGroupList,
  setSkipGs1Step,
  setStep,
} from './StartPage.actions';
import {
  getActiveStep,
  getGS1List,
  getPreparedEdoList,
  getPreparedProductGroupList,
  getSkipGS1Step,
  getStartPageData,
} from './StartPage.selectors';

/**
 * Get next step
 * @param {boolean} increment - Increment or decrement
 * @returns {SagaIterator<number>} - Next step
 */
function* getStep(increment = false) {
  const activeStep = yield select(getActiveStep);
  return increment ? activeStep + 1 : activeStep - 1;
}

/**
 * Increases or decreases the current step
 * If nextStep equal ProductGroupStepIndex and is skip GS1 step true, increasing the next step will not happen
 * @param {boolean} payload - Increment or decrement
 */
function* beforeSetStep({ payload }) {
  const nextStep = yield call(getStep, payload);
  const skipGS1Step = yield select(getSkipGS1Step);

  if (nextStep === GS1StepIndex && skipGS1Step) {
    yield cancel();
  }

  if (StartPageTabs[nextStep]) {
    yield put(setStep(nextStep));
  }

  if (nextStep === UserGroupStepIndex) {
    yield put(resetSkipGs1Step());
  }
}

/**
 * Fetch data for Start page
 */
function* fetchStartPage() {
  yield all([call(fetchProductGroup), call(fetchEDO)]);
}

/**
 * Fetch product group data
 */
function* fetchProductGroup() {
  const requestProductGroup = {
    url: '/api/v3/org/api/product-groups/search',
    method: HttpMethodEnum.GET,
    params: {
      limit: 100,
    },
  };

  const [productGroupList] = yield call(Api.request, requestProductGroup, {
    preloading: false,
  });

  if (productGroupList) {
    const prepareProductGroup = productGroupList.data.result.map(
      (item, index) => ({
        ...item,
        id: index,
        title: item.name,
      })
    );
    yield put(setProductGroupList(prepareProductGroup));
  }
}

/**
 * Fetch EDO data
 */
function* fetchEDO() {
  const requestEDOList = {
    url: '/api/v3/org/edo/getOperators',
    method: HttpMethodEnum.GET,
  };

  const [EDOList] = yield call(Api.request, requestEDOList, {
    preloading: false,
  });

  if (EDOList) {
    const edoData = EDOList.data.map((item) => ({
      id: item.id,
      title: item.name,
      status: item.status,
    }));
    yield put(setEdoList(edoData));
  }
}

/**
 * Init participant GS1 fields
 */
function* initParticipantGs1() {
  yield put(addNewFieldParticipantGs1({ gcp: '', glns: '' }));
}

/**
 * Set data from GS1 fields
 * @param {ActionType<ParticipantGS1Data>} - Fields data GS1
 */
function* participantGs1Data({ payload: { field, index, value } }) {
  const list = yield select(getGS1List);
  list[index] = { ...list[index], [field]: value };

  yield put(setFieldParticipantGs1(list));
}

/**
 * Send registration data
 */
function* finishStep() {
  yield put(setLoading(true));

  const { skipGS1Step } = yield select(getStartPageData);
  const edoList = yield select(getPreparedEdoList);
  const productGroupList = yield select(getPreparedProductGroupList);
  const GS1List = yield select(getGS1List);

  const Gs1List = isArray(GS1List) ? GS1List : [];
  const data = {
    is_importer_or_producer: !skipGS1Step,
    is_gs1_participant: Gs1List.length > 0,
    edo_list: edoList,
    product_group: productGroupList,
    gs1_list: Gs1List,
  };

  const requestRegistration = {
    url: '/api/v3/facade/registration/additional/send',
    method: HttpMethodEnum.POST,
    data,
  };

  const [success] = yield call(Api.request, requestRegistration, {
    preloading: false,
    artificialDelay: 5000,
  });

  yield put(setLoading(false));

  if (success) {
    const [successProfile] = yield call(fetchProfileData);
    if (successProfile) {
      history.push('/profile');
    }
  }
}

export const saga = function* watch() {
  yield takeEvery(
    [setNextStep, setPreviousStep, setSkipGs1Step],
    beforeSetStep
  );
  yield takeEvery(fetchStartPageData, fetchStartPage);
  yield takeEvery(setNewFieldParticipantGs1, initParticipantGs1);
  yield takeEvery(setParticipantDataGs1, participantGs1Data);
  yield takeEvery(setFinishStep, finishStep);
};
