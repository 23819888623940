import crypto from '@crpt-ui/crypto';

import { instance as http } from './instance';
import { getCertBySerial } from './utils';

class EventsService {
  intervalId;

  watch() {
    this.processUnsignedEvents();

    this.intervalId = setInterval(() => {
      this.processUnsignedEvents();
    }, 1000 * 30);
  }

  async processUnsignedEvents() {
    const events = await this.getUnsignedEvents();

    return events
      ? Promise.all(events.map((event) => this.signEvent(event)))
      : null;
  }

  async getCertificate() {
    const containers = await crypto.enumerateCertificates();

    return getCertBySerial(containers);
  }

  async getEventContent(event) {
    const response = await http({
      url: `/edo-api/incoming-documents/${event.document.id}/events/${event.id}/content`,
      method: 'get',
      responseType: 'arraybuffer',
    });

    return response.data;
  }

  async getEventSignature(event, certificate) {
    const content = await this.getEventContent(event);
    const signature = await crypto.signBytearray(content, certificate);

    return signature;
  }

  async signEvent(event) {
    const certificate = await this.getCertificate();
    const signature = await this.getEventSignature(event, certificate);

    return http({
      data: signature,
      headers: {
        'Content-Encoding': 'base64',
        'Content-Type': 'text/plain',
      },
      method: 'post',
      url: `/edo-api/incoming-documents/${event.document.id}/events/${event.id}/signature`,
    });
  }

  async getUnsignedEvents() {
    const response = await http.get(
      '/edo-api/incoming-documents/unsigned-events'
    );

    return response.data.items;
  }

  unwatch() {
    clearInterval(this.intervalId);
  }
}

export const EventsServiceInstance = new EventsService();
