import instance from '../axios';

interface FetchResultFileParams {
  fileId: string;
}
type FetchResultFileResponse = Blob;

export class DispenserService {
  path: string = '/api/v3/facade/dispenser-api';

  fetchResultFile = (params: FetchResultFileParams) => {
    const { fileId } = params;

    return instance.get<FetchResultFileResponse>(
      `${this.path}/results/${fileId}/file`,
      {
        headers: {
          Accept: '*/*',
        },
        responseType: 'arraybuffer',
      }
    );
  };
}
