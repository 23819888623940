import React, { memo, useMemo, useCallback } from 'react';

import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Autocomplete as CrptuiAutocomplete } from '@crpt-ui/core';

import { composeValidations } from './Form.utils';
import { required as validateRequired } from './utils/validations';

const AutocompleteAdapter = ({ input, meta, onChange, options, ...rest }) => {
  const onChangeHandle = useCallback(
    (event, option) => {
      if (typeof onChange === 'function') {
        onChange(event, option);
      }
      input.onChange(option.value);
    },
    [onChange]
  );

  const propsValue = useMemo(() => {
    return options.find((item) => item.value === input.value);
  }, [options, input.value]);

  return (
    <CrptuiAutocomplete
      {...input}
      error={!!meta.error}
      getOptionSelected={(option, { value }) => {
        return option.value === value;
      }}
      options={options}
      value={propsValue || null}
      onChange={onChangeHandle}
      {...rest}
    />
  );
};

export const Autocomplete = memo(
  ({ validate, required, type = 'text', ...rest }) => {
    const executableValidate = useMemo(() => {
      const validations = [];

      if (required) {
        validations.push(validateRequired);
      }

      if (typeof validate === 'function') {
        validations.push(validate);
      }

      return composeValidations(validations);
    }, [required, validate]);

    return (
      <Field
        {...rest}
        component={AutocompleteAdapter}
        required={required}
        type={type}
        validate={executableValidate}
      />
    );
  }
);

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  validate: PropTypes.func,
  type: PropTypes.text,
};

const InputAdapterPropTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  theme: PropTypes.object,
  blured: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  select: PropTypes.bool,
  maxLength: PropTypes.number,
  action: PropTypes.string,
  actionCallback: PropTypes.func,
  onBlur: PropTypes.func,
  InputProps: PropTypes.object,
  tooltipText: PropTypes.string,
  icon: PropTypes.element,
  initialValue: PropTypes.number,
};

const defaultProps = {
  required: false,
  disabled: false,
};

AutocompleteAdapter.propTypes = InputAdapterPropTypes;
Autocomplete.propTypes = propTypes;
Autocomplete.defaultProps = defaultProps;
