import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@crpt-ui/core';
import { Grid } from '@crpt/material';
import { AutocompleteMultiple } from '@crpt/shared/components/Form/Fields';

import { DefaultFieldIds, User } from '../../AcsUnloads.types';

interface ReceiverProps {
  usersList: User[];
}

const Receiver: FC<ReceiverProps> = ({ usersList }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="h6">
          {t('UnloadService.Task.AcsUnloads.FieldGroup.receiver')}
        </Typography>
      </Grid>
      <Grid item xs>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AutocompleteMultiple
              additionalLabel
              disableCloseOnSelect
              name={DefaultFieldIds.USERS}
              options={usersList}
              placeholder={t('UnloadService.Task.AcsUnloads.Fields.users')}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(Receiver);
