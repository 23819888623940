import React, { Fragment } from 'react';

import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { NoInfo } from '@crpt/shared/components';
import { Icon, Link, MenuButton, Pagination, Typography } from '@crpt-ui/core';
import Datatable from '@crpt-ui/datatable';

import { Preloader } from '../../../common_components/Preloader/Preloader';
import { ContentWrapper } from '../../../common_components/styled';
import { SignModal as AgreementDocumentSignModalConnected } from '../AgreementDocument/SignModal';

import { ManuallyAgreementDocumentConnected } from './ManuallyAgreementDocument/ManuallyAgreementDocument';
import { UploadAgreementDocumentConnected } from './UploadAgreementDocument/UploadAgreementDocument';
import { NotificationOfRejectionConnected } from './UploadAgreementDocument/NotificationOfRejection/NotificationOfRejection';

export const AgreementDocumentsListView = ({
  onSortDatatable,
  columns,
  data,
  manualSortBy,
  datatableActions,
  dropDownItems,
  pagination,
  classes,
  isLoading,
}) => (
  <Fragment>
    <ManuallyAgreementDocumentConnected />
    <UploadAgreementDocumentConnected />
    <AgreementDocumentSignModalConnected />
    <NotificationOfRejectionConnected />

    <ContentWrapper className={classes.contentWrapper}>
      <Box mb={2} mx={4}>
        <Grid container alignItems="center" justify="space-between" spacing={2}>
          <Grid item>
            <MenuButton items={dropDownItems}>Создать согласие</MenuButton>
          </Grid>
          <Grid item>
            <Link
              className={classes.link}
              href="/docs/Шаблон Согласия.xlsx"
              icon={<Icon name="Download" />}
            >
              Скачать шаблон
            </Link>
          </Grid>
        </Grid>
      </Box>

      <Preloader isOpen={isLoading} />

      {data.results.length ? (
        <Fragment>
          <Datatable
            manualSorting
            useSort
            actions={datatableActions}
            columns={columns}
            data={data.results}
            manualSortBy={manualSortBy}
            onSortChange={onSortDatatable}
          />

          <Box mt={1}>
            <Grid container alignItems="center" justify="space-between">
              {pagination.isShowPagination && (
                <Pagination
                  page={pagination.activePage}
                  pagesCount={pagination.pagesCount}
                  onPageChange={pagination.onPageChange}
                />
              )}

              <Grid item className={classes.documentCount}>
                Всего документов: {data.total}
              </Grid>
            </Grid>
          </Box>
        </Fragment>
      ) : (
        <NoInfo>
          <Typography variant="h5">У&nbsp;вас еще нет документов</Typography>
        </NoInfo>
      )}
    </ContentWrapper>
  </Fragment>
);

AgreementDocumentsListView.propTypes = {
  onRowClick: PropTypes.func.isRequired,
  onSortDatatable: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  manualSortBy: PropTypes.object,
  dropDownItems: PropTypes.array.isRequired,
  datatableActions: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  pagination: PropTypes.shape({
    onPageChange: PropTypes.func,
    pagesCount: PropTypes.number,
    activePage: PropTypes.number,
    isShowPagination: PropTypes.bool,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};
