import React, { useMemo } from 'react';
import { Grid } from '@crpt/material';
import { FieldIds, FormValues } from '../../CisUnloads.types';
import {
  MAX_DATE_PERIOD_DAYS,
  MAX_YEARS_AGO,
} from '../../CisUnloads.constants';
import moment from 'moment';
import { DatepickerInput as DatePicker } from '@crpt/shared/components/Form/Fields';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface DatesPeriodProps {
  values: FormValues;
  startMapping:
    | FieldIds.EMISSION_PERIOD_START
    | FieldIds.APPLIED_PERIOD_START
    | FieldIds.PRODUCTION_PERIOD_START;
  endMapping:
    | FieldIds.EMISSION_PERIOD_END
    | FieldIds.APPLIED_PERIOD_END
    | FieldIds.PRODUCTION_PERIOD_END;
  title: string;
  hint?: string | null;
}

const DatesPeriod: React.FC<DatesPeriodProps> = ({
  values,
  startMapping,
  endMapping,
  title,
  hint,
}) => {
  const { t } = useTranslation();

  const maxDate = useMemo(() => new Date(), []);
  const minDate = useMemo(
    () => moment().subtract(MAX_YEARS_AGO, 'years').toDate(),
    []
  );

  const maxDataEndDate = useMemo(() => {
    const max = values[startMapping]
      ? moment(values[startMapping])
          .add(MAX_DATE_PERIOD_DAYS, 'days')
          .startOf('day')
          .toDate()
      : maxDate;

    return max > maxDate ? maxDate : max;
  }, [maxDate, values]);

  const minDataEndDate = useMemo(
    () => values[startMapping] ?? minDate,
    [minDate, values]
  );

  const maxDataStartDate = useMemo(
    () => values[endMapping] ?? maxDate,
    [maxDate, values]
  );

  const minDataStartDate = useMemo(
    () =>
      values[endMapping]
        ? moment(values[endMapping])
            .subtract(MAX_DATE_PERIOD_DAYS, 'days')
            .startOf('day')
            .toDate()
        : minDate,
    [minDate, values]
  );

  const showHint = Boolean(values[endMapping] || values[startMapping]);

  return (
    <Grid item xs={12}>
      <Typography
        sx={{
          fontWeight: 600,
          paddingBottom: 1,
        }}
        variant="body2"
      >
        {title}
      </Typography>
      <Grid columnSpacing={2} container>
        <Grid item xs={6}>
          <DatePicker
            label={t('UnloadService.Task.CisUnloads.Fields.start')}
            maxDate={maxDataStartDate}
            minDate={minDataStartDate}
            name={startMapping}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label={t('UnloadService.Task.CisUnloads.Fields.end')}
            maxDate={maxDataEndDate}
            minDate={minDataEndDate}
            name={endMapping}
          />
        </Grid>
      </Grid>
      {showHint && hint ? (
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 400,
            paddingTop: 1,
          }}
          variant="inherit"
        >
          {hint}
        </Typography>
      ) : null}
    </Grid>
  );
};

export default DatesPeriod;
