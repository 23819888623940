import axios from 'axios';

import { createRequestSignature } from '@crpt/shared/utils';

import { getToken } from '../utils/userUtils';

const configuration = {
  withCredentials: true,
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json;charset=UTF-8',
    Pragma: 'no-cache',
  },
  timeout: 60000,
};

const instance = axios.create(configuration);

const instanceMulti = axios.create({
  ...configuration,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

const instanceZip = axios.create({
  ...configuration,
  headers: {
    Accept: 'application/zip',
  },
});

const onFulfilled = (config) => {
  const token = getToken();
  if (token) {
    Object.assign(config.headers, {
      Authorization: `Bearer ${token}`,
    });
  }

  Object.assign(config.headers, {
    'X-Source-App': createRequestSignature(),
  });

  return config;
};

const onRejected = (error) => {
  return Promise.reject(error);
};

instance.interceptors.request.use(onFulfilled, onRejected);

instanceMulti.interceptors.request.use(onFulfilled, onRejected);

instanceZip.interceptors.request.use(onFulfilled, onRejected);

export { instance, instanceMulti, instanceZip };
