import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
    border: `1px solid ${theme.palette.grey[500]}`,
    boxSizing: 'border-box',
    borderRadius: 3,
    height: 48,
    padding: 0,
    fontSize: 14,
    fontWeight: 600,

    '.disabled > &': {
      border: `1px solid ${theme.palette.grey[500]}`,
      backgroundColor: 'rgba(218, 218, 218, 0.2)',
      pointerEvents: 'none',
    },

    '.error > &': {
      borderLeft: `3px solid ${theme.palette.error.main}`,
    },

    '.required > &': {
      borderLeft: `3px solid ${theme.palette.success.main}`,
    },
  },
}));
