import React from 'react';

import { capitalize } from '@mui/material';
import clsx from 'clsx';

import classes from './product-group-icon.module.scss';

export const ProductGroupIcon = ({
  children,
  className,
  color = 'primary',
}) => (
  <div
    className={clsx(
      classes.root,
      classes[`color${capitalize(color)}`],
      className
    )}
  >
    {children}
  </div>
);
