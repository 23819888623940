// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { forwardRef, useImperativeHandle, useMemo } from 'react';
import { useFlexLayout, useTable, TableInstance } from 'react-table';

import {
  makeRowActionsCell,
  makeToggleAllRowSelectedCell,
  makeToggleRowSelectedCell,
} from './Cells';
import {
  call,
  useDefaultColumn,
  useHooks,
  useMountEffect,
} from './Datatable.utils';
import DatatableView from './Datatable.view';

const Datatable = forwardRef<TableInstance<any>, any>(
  (
    {
      actions,
      columns,
      height,
      onFiltersChange,
      onFiltersReset,
      onLoad,
      onRowClick,
      onRowSelect,
      onSortChange,
      useExpanded,
      useFilters,
      usePagination,
      useRowSelect,
      useRowState,
      useSort,
      isScrollbarAvailable,
      ...rest
    },
    ref
  ) => {
    const modifiedColumns = useMemo(() => {
      const rawColumns = columns.map((column) => ({
        ...column,
        disableFilters: !column.Filter,
        width: column.width === 'auto' ? 150 : column.width || 150,
        minWidth:
          column.width === 'auto'
            ? 150
            : column.minWidth || column.width || 150,
      }));

      if (useRowSelect) {
        rawColumns.unshift({
          Cell: makeToggleRowSelectedCell(),
          Header: makeToggleAllRowSelectedCell(),
          id: 'toggleRowSelected',
          width: 40,
        });
      }

      if (actions && actions.length) {
        rawColumns.push({
          Cell: makeRowActionsCell(actions),
          id: 'rowActions',
          width: 40,
        });
      }

      return rawColumns;
    }, [columns, useRowSelect, actions]);

    const instance = useTable(
      {
        ...rest,
        defaultColumn: useDefaultColumn(),
        columns: modifiedColumns,
      },
      ...useHooks({
        useExpanded,
        useFilters,
        usePagination,
        useRowSelect,
        useRowState,
        useSort,
      }),
      useFlexLayout,
      (hooks) => {
        hooks.getCellProps.push((props, { cell }) => {
          return {
            ...props,
            error: cell.state.error,
          };
        });
      }
    );

    const {
      state: { filters, sortBy, selectedRowIds },
    } = instance;

    useMountEffect(() => {
      call(onSortChange, sortBy);
    }, [sortBy]);

    useMountEffect(() => {
      call(onFiltersChange, filters);
    }, [filters]);

    useMountEffect(() => {
      call(onRowSelect, selectedRowIds);
    }, [selectedRowIds]);

    useImperativeHandle(ref, () => instance);

    return (
      <DatatableView
        height={height}
        instance={instance}
        onFiltersReset={onFiltersReset}
        onRowClick={onRowClick}
        isScrollbarAvailable={isScrollbarAvailable}
      />
    );
  }
);

Datatable.defaultProps = {
  manualFilters: true,
  manualPagination: true,
  manualSorting: true,
  useRowState: true,
};

export default Datatable;
