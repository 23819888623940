import { createActions } from 'redux-actions';

export const {
  setLoading,
  openCreatePaymentModal,
  openCreatePaymentModalWithId,
  closeCreatePaymentModal,
  setSelectedAccount,
  sendPaymentDocument,
} = createActions(
  {
    SET_LOADING: (payload) => payload,

    OPEN_CREATE_PAYMENT_MODAL: () => {},
    OPEN_CREATE_PAYMENT_MODAL_WITH_ID: (payload) => payload,
    CLOSE_CREATE_PAYMENT_MODAL: () => {},

    SET_SELECTED_ACCOUNT: (payload) => payload,

    SEND_PAYMENT_DOCUMENT: (payload) => payload,
  },
  {
    prefix: 'CREATE_PAYMENT_DOCUMENT',
  }
);
