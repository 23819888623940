import { call, put, takeEvery } from 'redux-saga/effects';

import { Api } from '../../../common_components/Api/Api';
import { /*saveCertSerial,*/ saveToken } from '../../../utils/cookieHelpers';

import {
  setErrors,
  setLoadingButton,
  submit,
  mountedLoginKep,
  setNidKeysResponse,
} from './LoginFormKep.actions';
import { HttpMethodEnum } from '../../../constants/HttpMethodEnum';

function* loginMountedSaga() {
  try {
    const [success] = yield call(Api.request, {
      cache: true,
      url: '/api/v3/auth/nid/keys',
    });
    localStorage.clear();

    if (success) {
      window.localStorage.setItem('nidKeys', JSON.stringify(success.data));
      yield put(setNidKeysResponse(success.data));
    }
  } catch (e) {
    yield put(setErrors('Ошибка авторизации'));
  }
}

function* loginSaga({ payload }) {
  const { inn, code, code_verifier } = payload;
  yield put(setLoadingButton(true));
  try {
    if (inn && code_verifier && code) {
      const data = {
        inn,
        code,
        code_verifier,
      };

      const [token] = yield call(
        Api.request,
        {
          url: '/api/v3/auth/nid',
          method: HttpMethodEnum.POST,
          data,
        },
        {
          preloading: false,
        }
      );

      if (token) {
        yield call(saveToken, token.data.token);
        yield put(setErrors());
        window.location.replace(`${window.location.origin}/`);
        window.localStorage.clear();
      }
    }
  } catch (e) {
    yield put(setErrors('Ошибка авторизации'));
  }

  yield put(setLoadingButton(false));
}

export const saga = function* watch() {
  yield takeEvery(submit, loginSaga);
  yield takeEvery(mountedLoginKep, loginMountedSaga);
};
