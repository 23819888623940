import { createActions } from 'redux-actions';

export const {
  openNotificationOfRejectionModal,
  closeNotificationOfRejectionModal,
} = createActions(
  {
    OPEN_NOTIFICATION_OF_REJECTION_MODAL: () => {},
    CLOSE_NOTIFICATION_OF_REJECTION_MODAL: () => {},
  },
  {
    prefix: 'NOTIFICATION_OF_REJECTION',
  }
);
