import { createActions } from 'redux-actions';

export const {
  openDocumentTemplatesDialog,
  closeDocumentTemplatesDialog,
  clearDocumentTemplatesDocuments,
} = createActions(
  {
    OPEN_DOCUMENT_TEMPLATES_DIALOG: () => {},
    CLOSE_DOCUMENT_TEMPLATES_DIALOG: () => {},

    CLEAR_DOCUMENT_TEMPLATES_DOCUMENTS: () => {},
  },
  {
    prefix: 'DOCUMENT_TEMPLATES_DIALOG',
  }
);
