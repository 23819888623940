import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  contentWrapper: {
    position: 'absolute',
    inset: 0,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'inherit',
  },
  imageWrapper: {
    display: 'block',
    overflow: 'hidden',
    transform: 'translate3d(0, 0, 0)',
    textAlign: 'center',
    opacity: 1,
    position: 'relative',
    height: '32px',
    width: '32px',
  },
  text: {
    margin: '16px 0 0 0',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 16 / 10,
    letterSpacing: '.02em',
    textTransform: 'uppercase',
  },
}));
