import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';

import { Tabs, Steps } from '@crpt-ui/core';

import { LocationType } from '../../constants/PropTypes';
import { Route } from '../../common_components/Routing/Route';

import { ProfileContext } from './Profile.context';
import { profileRoutes } from './profile-routes';

export const ProfileView = ({
  location,
  isRegistered,
  onLocationChange,
  status,
  tabs,
}) => {
  const TabsComponent = isRegistered ? Tabs : Steps;

  return (
    <Fragment>
      <TabsComponent
        className={isRegistered ? '' : 'steps'}
        items={tabs}
        value={location.pathname}
        variant={isRegistered ? 'standard' : 'fullWidth'}
        onChange={onLocationChange}
      />
      <ProfileContext.Provider
        value={{
          status,
        }}
      >
        <Switch>
          {profileRoutes.map((route) => (
            <Route key={route.path} {...route} />
          ))}
        </Switch>
      </ProfileContext.Provider>
    </Fragment>
  );
};

ProfileView.propTypes = {
  location: LocationType.isRequired,
  onLocationChange: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({})),
  isRegistered: PropTypes.bool.isRequired,
};
