import React, { useCallback, useEffect, useMemo } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { ProfileHeader } from '../../components/ProfileHeader/ProfileHeader';
import { withFormContext } from '../../../../common_components/Form/Form.utils';
import { getRegistered } from '../../Company/ducks/Company.selectors';
import { getShowProductionTab } from '../../Production/ducks/Production.selectors';
import { setEditing, sendRequisitesData } from '../ducks/Requisites.actions';
import { getRequisitesEditing } from '../ducks/Requisites.selectors';
import { translate } from '../../../Translate/Translate';

const Header = ({
  setEditing,
  sendRequisitesData,
  formValues,
  form,
  history,
  isRegistered,
  isEditing,
  isFormErrors,
  isShowProductionTab,
  isRequisitesRequired,
}) => {
  useEffect(() => {
    setEditing(!isRegistered);
  }, [isRegistered, setEditing]);

  const onSubmit = useCallback(() => {
    sendRequisitesData(formValues);
  }, [formValues, sendRequisitesData]);

  const mainActions = useMemo(
    () => [
      {
        label: translate('Сохранить изменения'),
        onClick: onSubmit,
        isShow: isRegistered && isEditing,
        size: 'lg',
        disabled: isFormErrors,
      },
      {
        label: translate('Предыдущий шаг'),
        onClick: () => history.replace('/profile/users/list'),
        isShow: !isRegistered,
        size: 'lg',
        variant: 'outlined',
      },
      {
        label: isShowProductionTab
          ? translate('Следующий шаг')
          : translate('Отправить'),
        onClick: onSubmit,
        isShow: !isRegistered,
        size: 'lg',
        disabled: isFormErrors,
        tooltip: {
          isHidden: !isFormErrors,
          title: isShowProductionTab
            ? translate(
                'Для перехода на следующий шаг заполните все обязательные поля'
              )
            : translate('Для отправки заполните все обязательные поля'),
        },
      },
    ],
    [history, isEditing, isFormErrors, isRegistered, onSubmit]
  );

  return React.createElement(ProfileHeader, {
    mainActions,
    isEditing,
    isFormErrors,
    form,
    setEditing,
    page: isRequisitesRequired ? 'REQUISITES' : 'REQUISITES_OPTIONAL',
    isEditable: true,
    isRequisitesRequired,
  });
};

Header.propTypes = {};

const mapStateToProps = (state) => ({
  isRegistered: getRegistered(state),
  isShowProductionTab: getShowProductionTab(state),
  isEditing: getRequisitesEditing(state),
});

const mapDispatchToProps = {
  setEditing,
  sendRequisitesData,
};

export const HeaderConnected = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormContext({ values: true, errors: true, form: true }),
  withRouter
)(Header);
