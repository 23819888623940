import React, { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Filter, FilterSet, FiltersTable, Table } from '@ibox/ui';

import { Preloader } from '../../../../fragments/Table/Preloader';
import { getCookie } from '../../../../utils/cookieHelpers';

import { getFilters, getFiltersTabs } from './receipts-constants';

type ReceiptListViewComp = {
  columns: unknown[];
  isLoading: boolean;
  last: boolean;
  onFilterApply: () => void;
  onPageChange: () => void;
  results: {
    did: string;
    receiptDate: number;
    senderInn: string;
    senderName: string;
    status: string;
    total: number;
    type: string;
  }[];
  savedFilters: FilterSet[];
  historyFilters: FilterSet[];
  handleAddFiltersHistory: (filters: Filter[]) => void;
  addSavedFilters: (filters: Filter[], filterName: string) => void;
  editSavedFilter: (
    value: Filter[],
    filterName: string,
    filter: FilterSet
  ) => void;
  deleteSavedFilter: (filterId: string) => void;
  currentPage?: number;
  getSavedFilters: VoidFunction;
  getHistoryFilters: VoidFunction;
};

export const ReceiptListView: React.FC<ReceiptListViewComp> = ({
  results,
  isLoading,
  last,
  onFilterApply,
  onPageChange,
  columns,
  currentPage,
  historyFilters,
  savedFilters,
  handleAddFiltersHistory,
  deleteSavedFilter,
  editSavedFilter,
  addSavedFilters,
  getHistoryFilters,
  getSavedFilters,
}) => {
  const { t } = useTranslation();
  const language = getCookie('language') || 'hy';
  const filtersTabs = useMemo(
    () =>
      getFiltersTabs({
        t,
      }),
    [t]
  );

  const filters = useMemo(() => getFilters(t), [t]);
  useEffect(() => {
    if (!historyFilters.length) {
      getHistoryFilters();
    }
    if (!savedFilters.length) {
      getSavedFilters();
    }
  }, [historyFilters.length, savedFilters.length]);
  //
  const initialFilters = useMemo(
    () =>
      historyFilters[historyFilters.length - 1]?.filters?.reduce(
        (acc, { id, value }) => {
          if (typeof value === 'object') {
            acc = {
              ...acc,
              ...value,
            };
          } else {
            acc[id] = value;
          }
          return acc;
        },
        {}
      ),
    [historyFilters]
  );

  return (
    <>
      <FiltersTable
        filters={filters}
        filtersHistory={historyFilters}
        filtersTabs={filtersTabs}
        initialFilters={initialFilters}
        locale={language}
        savedFilters={savedFilters}
        onAddFiltersHistory={handleAddFiltersHistory}
        onApplyFilters={onFilterApply}
        onDeleteFilters={deleteSavedFilter}
        onEditFilter={editSavedFilter}
        onSaveFilters={addSavedFilters}
      />
      <Table
        columns={columns}
        currentPage={currentPage}
        data={results}
        isLastPage={last}
        isLoading={isLoading}
        labelPagination={t('Показать еще')}
        locale={language}
        useSort={false}
        onPageChange={onPageChange}
      />

      <Preloader isOpen={isLoading} text={t('Загрузка')} />
    </>
  );
};
