import { makeStyles } from '@material-ui/core/styles';
import { TypographyProps, Theme } from '@mui/material';

interface TooltipClassesProps {
  isActiveTooltip: boolean;
  variant: TypographyProps['variant'];
  zeroWidth?: boolean;
}

export const useTooltipClasses = makeStyles<Theme, TooltipClassesProps>(() => ({
  text: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    margin: 0,
    width: ({ zeroWidth }) => (zeroWidth ? '0' : 'auto'),
    flexBasis: '100%',
    fontSize: ({ variant }) => {
      if (!variant) {
        return 'inherit';
      }
    },
    cursor: ({ isActiveTooltip }) => (isActiveTooltip ? 'pointer' : 'inherit'),
  },
  icon: {
    cursor: 'pointer',
  },
  tooltipContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    wordBreak: 'break-word',
  },
}));
