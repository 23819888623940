import React, { useMemo } from 'react';

import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Button, Icon } from '@crpt-ui/core';

import { usePaginationStyles } from './Pagination.styled';

export const Pagination = ({ onIncrement, onDecrement, page, pages }) => {
  const classes = usePaginationStyles();

  const disabledDecrementButton = useMemo(() => page === 1, [page]);

  const disabledIncrementButton = useMemo(() => page === pages, [page, pages]);

  return (
    <Box my={2}>
      <Grid container alignItems="center" spacing={3}>
        <Grid item>
          <Button
            disabled={disabledDecrementButton}
            icon={<Icon name="ArrowLeft" />}
            size="sm"
            variant="outlined"
            onClick={onDecrement}
          />
        </Grid>

        <Grid item>
          <div className={classes.pagesText}>
            {page}&nbsp;/&nbsp;{pages}
          </div>
        </Grid>

        <Grid item>
          <Button
            disabled={disabledIncrementButton}
            icon={<Icon name="ArrowRight" />}
            size="sm"
            variant="outlined"
            onClick={onIncrement}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

Pagination.propTypes = {
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
};
