import { combineActions, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
  registrationStatusSubmit,
  setRegistrationDeclineReason,
  setRegistrationStatus,
} from './RegistrationStatus.actions';

const registrationStatus = handleActions(
  {
    [setRegistrationStatus]: (_, { payload }) => payload,
    [combineActions(LOCATION_CHANGE, registrationStatusSubmit)]: () => null,
  },
  null
);

const registrationDeclineReason = handleActions(
  {
    [setRegistrationDeclineReason]: (_, { payload }) => payload,
    [combineActions(LOCATION_CHANGE, registrationStatusSubmit)]: () => null,
  },
  null
);

export const reducer = {
  registrationStatus,
  registrationDeclineReason,
};
