import { push } from 'react-router-redux';
import { put } from 'redux-saga/effects';

export const getPathNameFromUrlString = (urlString: string) => {
  const [urlStringPath] = urlString.split('?');
  return urlStringPath;
};

export function* updateUrlParams({ urlString }: { urlString: string }) {
  const currentLocationPathname = window.location.pathname;
  if (currentLocationPathname === getPathNameFromUrlString(urlString)) {
    yield put(push(urlString));
  }
}

const defaultParseFilterFromUrlStr = (str: string) => {
  const [id, param] = str.split('=');
  if (id === 'page') {
    return { page: JSON.parse(decodeURIComponent(param)) };
  }
  return { id, value: JSON.parse(decodeURIComponent(param)) };
};

export const parseDateFilterFromUrlStr = (str: string) => {
  const [id, param] = str.split('=');
  if (id === 'page') {
    return { page: JSON.parse(decodeURIComponent(param)) };
  }
  return {
    id,
    value: JSON.parse(decodeURIComponent(param), (key, value) => {
      return key && ['startDate', 'endDate', 'date'].includes(key)
        ? new Date(value)
        : value;
    }),
  };
};

export const url2filters = (url: string, fn = defaultParseFilterFromUrlStr) =>
  url.split('&').map((str) => fn(str));

export const filters2url = (filters) =>
  filters
    .map(
      ({ id, value }) => `${id}=${encodeURIComponent(JSON.stringify(value))}`
    )
    .join('&');

const getFilterParams = (filter) => {
  const { id, value } = filter;
  if (!id || !value) {
    return {};
  }
  const paramValue = value?.value ?? value;
  return { [id]: paramValue };
};

export const filters2params = (filters, fn = getFilterParams) =>
  filters.reduce((prev, next) => {
    return { ...prev, ...fn(next) };
  }, {});

export const initFromUrl = (searchString = '', names = [], fn) => {
  const urlParams =
    searchString && searchString[0] === '?' && searchString.indexOf('=') > 0
      ? url2filters(searchString.substr(1), fn)
      : [];
  const page = urlParams.find((param) => 'page' in param);
  const orderDirection = urlParams.find(
    (param) => param.id === 'orderDirection'
  );
  const orderColumn = urlParams.find((param) => param.id === 'orderColumn');
  const sort =
    urlParams.length && orderDirection && orderColumn
      ? [orderDirection, orderColumn]
      : [];
  const filters =
    Array.isArray(names) && names.length
      ? urlParams.filter((param) => names.some((name) => name === param.id))
      : urlParams.filter((param) => !('page' in param));
  return { sort, page, filters };
};

export const getSortData2url = (sort) => {
  if (!sort.length) {
    return [];
  }

  const [{ id, desc }] = sort;

  return [
    {
      id: 'orderColumn',
      value: id,
    },
    {
      id: 'orderDirection',
      value: desc ? 'desc' : 'asc',
    },
  ];
};

export const getFrontendTaskUrl = (absolutePath: string) => {
  const locationOrigin = window.location.origin;
  return /localhost/.test(locationOrigin)
    ? `https://qa05.gismt.crpt.tech${absolutePath}`
    : `${locationOrigin}${absolutePath}`;
};

export const spreadMultiValueOfFilter = (filters, multiNames) => {
  return filters.reduce((acc, currentFilter) => {
    if (multiNames.includes(currentFilter.id)) {
      const splittedMultiValues = Array.from(
        Object.entries(currentFilter.value)
      ).map((filterObject) => {
        const [filterName, filterValue] = filterObject;
        return {
          id: filterName,
          value: filterValue,
        };
      });
      acc.push(...splittedMultiValues);
      return acc;
    } else {
      acc.push(currentFilter);
      return acc;
    }
  }, []);
};
