import { createSelector } from 'reselect';

import { documentStatuses, documentTypes } from '../../../../constants/index';

export const getClosingDocumentState = (state) =>
  state.Documents.ClosingDocument;

/**
 * Returns document info data
 * @param state
 * @returns {*} - Document info
 */
export const getDocumentInfo = createSelector(
  [getClosingDocumentState],
  (state) => state.documentInfo.data
);

/**
 * Returns document info data
 * @param state
 * @returns {*} - Document info
 */
export const getDocumentInfoLoaded = createSelector(
  [getClosingDocumentState],
  (state) => state.documentInfo.loaded
);

/**
 * Returns document incident
 * @param state
 * @returns {{}} - Document incident
 */
export const getDocumentIncident = createSelector(
  [getClosingDocumentState],
  (state) => state.incident
);

export const getLoading = createSelector(
  [getClosingDocumentState],
  (state) => state.loading
);

/**
 * Returns document id
 * @param state
 * @returns {string} - Document id
 */
export const getDocumentId = createSelector(
  [getDocumentInfo],
  (documentInfo) => documentInfo.id
);

/**
 * Returns a link to "/sign"
 * @param state
 * @returns {string} - SignClosingDocument link
 */
export const getSignLink = createSelector(
  [getDocumentInfo],
  (documentInfo) => `/documents/cd/${documentInfo.id}/sign`
);

/**
 * Returns a link to view document
 * @param state
 * @returns {string} - View document link
 */
export const getViewLink = createSelector(
  [getDocumentInfo],
  (documentInfo) => `/documents/cd/${documentInfo.id}`
);

export const getUsingHub = createSelector(
  [getClosingDocumentState, getDocumentInfo],
  (state, documentInfo) =>
    state.useHub &&
    [
      documentTypes.upd820Dop,
      documentTypes.upd820SfDop,
      documentTypes.upd820DopI,
      documentTypes.upd820SfDopI,
      documentTypes.ukdDis,
      documentTypes.ukdSfDis,
      documentTypes.ukdDisI,
      documentTypes.ukdSfDisI,
    ].includes(documentInfo.type)
);

export const isShowRequestForClarification = createSelector(
  [getDocumentInfo, getUsingHub],
  (documentInfo, _areThereIncidents, useHub) =>
    [
      documentTypes.updSf,
      documentTypes.upd820Sf,
      documentTypes.updSfDop,
      documentTypes.upd820SfDop,
    ].includes(documentInfo.type) &&
    ![
      documentStatuses.clarified,
      documentStatuses.clarificationRequired,
      documentStatuses.clarificationRequest,
      documentStatuses.revocationRequest,
      documentStatuses.revocated,
      documentStatuses.revocationExpected,
    ].includes(documentInfo.status) &&
    !useHub
);

/**
 * Запрос аннулирования со стороны УОТа
 */
export const isShowRequestForCancellation = createSelector(
  [getDocumentInfo],
  (documentInfo) =>
    [
      documentStatuses.delivered,
      documentStatuses.viewedSignatureIsNotRequired,
      documentStatuses.signed,
      documentStatuses.revocateDenied,
    ].includes(documentInfo.status)
);

/**
 * Аннулирования со стороны УОТа
 */
export const isShowCancellation = createSelector(
  [getDocumentInfo],
  (documentInfo) =>
    [documentStatuses.revocationRequest].includes(documentInfo.status)
);

/**
 * If the document type is -10000 (Акт сверки)
 * and status 3 or 13 (Требуется подпись) returns true
 * @returns {boolean} - Is show rejection
 */
export const isShowRejection = createSelector(
  [getDocumentInfo],
  (documentInfo) =>
    documentInfo.type === documentTypes.nfBill &&
    [
      documentStatuses.signatureRequired,
      documentStatuses.viewedSignatureRequired,
    ].includes(documentInfo.status)
);

/**
 * @returns {boolean} - Is show request for clarification
 */
export const isShowSignTab = createSelector(
  [getDocumentInfo, getUsingHub],
  (documentInfo, useHub) =>
    [
      documentTypes.updSfDop,
      documentTypes.ukdDis,
      documentTypes.ukdSfDis,
      documentTypes.ukdDisI,
      documentTypes.ukdSfDisI,
      documentTypes.upd820Dop,
      documentTypes.upd820SfDop,
      documentTypes.upd820DopI,
      documentTypes.upd820SfDopI,
      600,
      604,
      700,
      704,
    ].includes(documentInfo.type) &&
    [
      documentStatuses.signatureRequired,
      documentStatuses.clarificationRequired,
      documentStatuses.viewedSignatureRequired,
      documentStatuses.clarificationRequest,
    ].includes(documentInfo.status) &&
    !useHub
);

/**
 * Returns true if the document type is -10000 (Акт сверки)
 * and status 3 or 13 (Требуется подпись)
 * @returns {boolean} - is show sign button
 */
export const isShowSignButton = createSelector(
  getDocumentInfo,
  (documentInfo) =>
    documentInfo.type === documentTypes.nfBill &&
    [
      documentStatuses.signatureRequired,
      documentStatuses.viewedSignatureRequired,
    ].includes(documentInfo.status)
);

/**
 * Is show acceptance content code form
 *
 * @returns {boolean} - Is show acceptance content code form
 */
export const isShowAcceptanceContentCode = createSelector(
  getDocumentInfo,
  (documentInfo) => documentInfo.type === documentTypes.upd820SfDop
);

export const isUkdDocuments = createSelector(getDocumentInfo, (documentInfo) =>
  [documentTypes.ukdSfDis, 604].includes(documentInfo.type)
);
