import React, { useCallback } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { DocumentSigningModalConnected } from '../../../../common_components/DocumentSigningModal/DocumentSigningModal';

import { signAgreementDocument } from './ducks/SignModal.actions';

const SignModalComp = ({ signAgreementDocument }) => {
  const onSign = useCallback(() => {
    signAgreementDocument();
  }, [signAgreementDocument]);

  return React.createElement(DocumentSigningModalConnected, {
    onSign,
  });
};

const mapDispatchToProps = { signAgreementDocument };

SignModalComp.propTypes = {
  signAgreementDocument: PropTypes.func.isRequired,
};

export const SignModal = connect(null, mapDispatchToProps)(SignModalComp);
