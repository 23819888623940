import React from 'react';

import { get } from 'lodash';

import { Link } from '@crpt-ui/core';

import { documentTypeValuesMap } from '../DocumentsList.constants';

export const DoctypeCell = ({ cell, row, onClick }) => {
  const value = get(documentTypeValuesMap, cell.value, '');

  return (
    <Link
      style={{
        fontWeight: 600,
      }}
      onClick={() => onClick(null, row)}
    >
      {value}
    </Link>
  );
};
