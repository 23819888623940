import {
  DocumentStatus,
  DocumentTypeEnum,
  EliminationReasonEnum,
  TurnoverTypeIdEnum,
} from '../constants';
import { ProductGroupIdEnum } from '@crpt/shared/constants';

export interface CrptDocument<T = Record<string, any>> {
  header: DocumentHeaderDTO;
  body?: T;
}

export interface DocumentHeaderDTO {
  cancelDoc?: string;
  docDate: string;
  documentId: string;
  eliminationReason?: EliminationReasonEnum;
  processingDate: string;
  receivedAt?: string;
  status: DocumentStatus;
  turnoverType?: TurnoverTypeIdEnum;
  type: DocumentTypeEnum;
  sender?: DocumentParticipantInfoDTO;
  receiver?: DocumentParticipantInfoDTO;
  invoiceId?: string;
  invoiceDateTime?: string;
  correctionInvoiceId?: string;
  correctionInvoiceDateTime?: string;
  fixNumber?: number;
  fixDateTime?: string;
  total?: number;
  vat?: number;
  specialAttributes?: DocumentSpecialAttributesDto;
  excise?: number;
  productGroups?: ProductGroupIdEnum[];
  commonErrors?: CommonErrorDTO[];
}

export interface CommonErrorDTO {
  errorCode?: string;
  errorMessage?: string;
}

export interface DocumentSpecialAttributesDto {
  trn?: string;
  did?: string;
  additionalProperties?: unknown;
}

export interface DocumentParticipantInfoDTO {
  inn?: string;
  name?: string;
  id?: number;
  address?: string;
  addressId?: string;
}

export interface LkGtinReceiptCancelBody {
  inn: string;
  lkGtinReceiptId: string;
  version: number;
}

export interface CancellationDocumentBody {
  participantInn: string;
  dropoutReason: DropoutReasonEnum;
  address?: string;
  fiasId?: string;
  withChild?: boolean;
  products?: WriteOffProductDTO[];
  sourceDocType?: SourceDocTypeEnum;
  sourceDocNum?: string;
  sourceDocDate?: string;
  sourceDocName?: string;
}

export interface WriteOffProductDTO {
  cises: string[];
  dropoutReason?: DropoutReasonEnum;
}

export interface UniversalCorrectionDocumentBody {
  contract?: string;
  sumNds: string;
  tax: string;
  baseDocument: string;
  knd: string;
  function: string;
  sellerInfo: DocumentCounterparty;
  buyerInfo: DocumentCounterparty;
  signerSeller: DocumentSignerDTO;
  signerBuyer: DocumentSignerDTO;
  acceptanceCode?: string;
  upd: string;
  updFix?: string;
  updDate: string;
  updFixDate?: string;
  cisesInfo?: CisInfoDTO[];
  productsInfo?: UCDProductInfoDTO[];

  ucd: string;
  ucdFix?: string;
  ucdFixDate?: string;
  ucdDate: string;
}

export interface UniversalTransferDocumentBody {
  contract?: string;
  sumNds: string;
  tax: string;
  baseDocument: string;
  knd: string;
  function: string;
  sellerInfo: DocumentCounterparty;
  buyerInfo: DocumentCounterparty;
  signerSeller: DocumentSignerDTO;
  signerBuyer: DocumentSignerDTO;
  acceptanceCode?: string;
  upd: string;
  updFix?: string;
  updDate: string;
  updFixDate: string;
  cisesInfo?: Omit<CisInfoDTO, 'moveType'>[];
  productsInfo: UTDProductInfoDTO[];
}

export interface CisInfoDTO {
  cis: string;
  name?: string;
  moveType?: MoveTypeEnum;
}

export interface DocumentCounterparty {
  name: string;
  inn: string;
  kpp?: string;
  address?: string;
  edoOperatorName?: string;
}

export interface DocumentSignerDTO {
  authorityScope: AuthorityScopeEnum;
  authorityStatus: AuthorityStatusEnum;
  authority: string;
  delegation?: string;
  innOrganisation: string;
  nameOrganisation?: string;
  fullName?: string;
  position: string;
  inn?: string;
  otherInfo?: string;
}

export interface UCDProductInfoDTO {
  moveType?: MoveTypeEnum;
  gtin: string;
  countBefore?: string;
  countAfter?: string;
  delta?: string;
  name?: string;
}

export interface UTDProductInfoDTO {
  name?: string;
  quantity?: string;
  gtin: string;
}

export enum MoveTypeEnum {
  ADDING = 1,
  NO_CHANGES = 2,
  RETURN = 3,
}

export enum AuthorityScopeEnum {
  RESPONSIBLE_INVOICE = '0',
  RESPONSIBLE_EVENT_DESIGN = '3',
  RESPONSIBLE_INVOICE_AND_EVENT_DESIGN = '6',
  OTHER = '29',
}

export enum AuthorityStatusEnum {
  SELLERS_ORGANIZATION_EMPLOYEE = '1',
  VENDOR_INFORMATION_COMPILERS_SELLER_ORGANIZATION_EMPLOYEE = '2',
  ORGANIZATION_EMPLOYEE_OTHER = '3',
  INDIVIDUAL_ENTREPRENEUR = '4',
  BUYERS_ORGANIZATION_EMPLOYEE = '5',
  VENDOR_INFORMATION_COMPILERS_BUYER_ORGANIZATION_EMPLOYEE = '6',
}

export enum DropoutReasonEnum {
  KM_SPOILED = 'KM_SPOILED',
  KM_LOST = 'KM_LOST',
  KM_DESTROYED = 'KM_DESTROYED',
  DESCRIPTION_ERRORS = 'DESCRIPTION_ERRORS',
  RETAIL = 'RETAIL',
  EAS_TRADE = 'EAS_TRADE',
  BEYOND_EEC_EXPORT = 'BEYOND_EEC_EXPORT',
  RETURN = 'RETURN',
  REMOTE_SALE = 'REMOTE_SALE',
  LOSS = 'LOSS',
  DAMAGE_LOSS = 'DAMAGE_LOSS',
  DESTRUCTION = 'DESTRUCTION',
  CONFISCATION = 'CONFISCATION',
  LIQUIDATION = 'LIQUIDATION',
  DONATION = 'DONATION',
  STATE_CONTRACT = 'STATE_CONTRACT',
  NO_RETAIL_USE = 'NO_RETAIL_USE',
  ENTERPRISE_USE = 'ENTERPRISE_USE',
  KM_SPOILED_OR_LOST = 'KM_SPOILED_OR_LOST',
  KM_CANCELLATION = 'KM_CANCELLATION',
  EXPIRY = 'EXPIRY',
  QA_SAMPLES = 'QA_SAMPLES',
  PRODUCT_RECALL = 'PRODUCT_RECALL',
  COMPLAINTS = 'COMPLAINTS',
  PRODUCT_TESTING = 'PRODUCT_TESTING',
  DEMO_SAMPLES = 'DEMO_SAMPLES',
  OTHER = 'OTHER',
  BY_SAMPLES = 'BY_SAMPLES',
  DISTANCE = 'DISTANCE',
  VENDING = 'VENDING',
  OWN_USE = 'OWN_USE',
  UTILIZATION = 'UTILIZATION',
  RECALL = 'RECALL',
  PACKING = 'PACKING',
  PRODUCTION_USE = 'PRODUCTION_USE',
  EXPIRATION = 'EXPIRATION',
  OWN_USE_EXCISE = 'OWN_USE_EXCISE',
  BEFORE_MARKING = 'BEFORE_MARKING',
  KM_CANCELATION = 'KM_CANCELATION',
  STATE_SECRET = 'STATE_SECRET',
  AUTO_CANCELLATION = 'AUTO_CANCELLATION',
  REMAINS_CANCELLATION = 'REMAINS_CANCELLATION',
  DISAGGREGATED = 'DISAGGREGATED',
}

export enum SourceDocTypeEnum {
  RECEIPT = 'RECEIPT',
  SALES_RECEIPT = 'SALES_RECEIPT',
  CONSIGNMENT_NOTE = 'CONSIGNMENT_NOTE',
  CUSTOMS_DECLARATION = 'CUSTOMS_DECLARATION',
  UTD = 'UTD',
  DESTRUCTION_ACT = 'DESTRUCTION_ACT',
  OTHER = 'OTHER',
}

export type UniversalTransferDocument =
  CrptDocument<UniversalTransferDocumentBody>;
export type UniversalCorrectionDocument =
  CrptDocument<UniversalCorrectionDocumentBody>;
export type LkGtinReceiptCancelDocument = CrptDocument<LkGtinReceiptCancelBody>;
export type CancellationDocument = CrptDocument<CancellationDocumentBody>;
