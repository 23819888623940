import { createSelector } from 'reselect';

const getEdoRegistrationState = (state) => state.Profile.EdoRegistration;

export const getEdoRegistrationData = createSelector(
  [getEdoRegistrationState],
  (state) => state.data
);

export const getEdoRegistrationOperators = createSelector(
  [getEdoRegistrationState],
  (state) => state.operators
);

export const getEdoRegistrationEditing = createSelector(
  [getEdoRegistrationState],
  (state) => state.editing
);

export const getLoading = createSelector(
  [getEdoRegistrationState],
  (state) => state.loading
);

export const getSubmitting = createSelector(
  [getEdoRegistrationState],
  (state) => state.submitting
);
