import { createActions } from 'redux-actions';

export const {
  openPaymentDocumentSuccessModal,
  closePaymentDocumentSuccessModal,
} = createActions(
  {
    OPEN_PAYMENT_DOCUMENT_SUCCESS_MODAL: () => {},
    CLOSE_PAYMENT_DOCUMENT_SUCCESS_MODAL: () => {},
  },
  {
    prefix: 'CREATE_PAYMENT_DOCUMENT_SUCCESS',
  }
);
