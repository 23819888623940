import React from 'react';

import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';

import { ExceptionLayout } from '../Layouts/ExceptionLayout/ExceptionLayout';
import { PageHeader } from '../PageHeader/PageHeader';
import { PrivateRouteConnected } from '../Routing/PrivateRoute';
import { Route } from '../Routing/Route';
import { Sidebar } from '../Sidebar/Sidebar';
import { translate } from '../../components/Translate/Translate';

import { Modals } from './Modals/Modals';

export const DefaultWrap = ({ routes }) => (
  <div
    style={{
      boxSizing: 'border-box',
      display: 'flex',
      height: '100%',
    }}
  >
    <div
      style={{
        boxSizing: 'border-box',
        display: 'table',
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        tableLayout: 'fixed',
      }}
    >
      <div
        style={{
          display: 'table-row',
        }}
      >
        <PageHeader />
      </div>
      <div
        style={{
          display: 'table-row',
        }}
      >
        <div
          style={{
            boxSizing: 'border-box',
            overflow: 'hidden',
            padding: 16,
            display: 'table-cell',
            height: '100%',
          }}
        >
          <Sidebar />
          <Modals />

          <Switch>
            {routes.map(({ private: _private, exact, route, component }, i) => {
              return _private ? (
                <PrivateRouteConnected
                  component={component}
                  exact={exact}
                  key={i}
                  path={route}
                />
              ) : (
                <Route
                  component={component}
                  exact={exact}
                  key={i}
                  path={route}
                />
              );
            })}
            <Route
              render={() =>
                React.createElement(ExceptionLayout, {
                  code: 404,
                  message: translate('Страница не найдена'),
                  description: translate(
                    'К сожалению, запрошенной вами страницы не существует.'
                  ),
                })
              }
            />
          </Switch>
        </div>
      </div>
    </div>
  </div>
);

DefaultWrap.propTypes = {
  routes: PropTypes.array.isRequired,
};
