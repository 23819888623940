import React from 'react';

import Banner from './Banner';
import { ReactComponent as NoDataSvg } from '@crpt/shared/svg/NoData.svg';

const Error = () => {
  return (
    <Banner
      icon={<NoDataSvg />}
      title="Невозможно загрузить файл. Выберите другой файл."
    />
  );
};

export default Error;
