import React from 'react';

import { ButtonBase } from '@material-ui/core';

import { Icon } from '@crpt-ui/core';

import { PrimitiveStatus } from '../../../../common_components/PrimitiveStatus/PrimitiveStatus';
import { getValue } from '../../../../common_components/Document/Document.utils';
import { documentTypeValuesMap } from '../../DocumentsList/DocumentsList.constants';

export const ExpandCell = React.memo(({ row, cell }: any) => {
  const { canExpand, isExpanded, depth } = row;
  const expandProps = row.getToggleRowExpandedProps();

  return (
    <div
      style={{
        paddingLeft: depth * 32,
        display: 'flex',
      }}
    >
      {canExpand && (
        <ButtonBase {...expandProps}>
          <Icon name={isExpanded ? 'collapse' : 'expand'} />
        </ButtonBase>
      )}

      <PrimitiveStatus>
        {getValue(documentTypeValuesMap, cell.value)}
      </PrimitiveStatus>
    </div>
  );
});
