import { handleActions } from 'redux-actions';

import {
  clearClosingDocument,
  setClosingDocumentContent,
  setLoading,
} from './ViewDocument.actions';

const documentContent = handleActions(
  {
    [setClosingDocumentContent]: (_, { payload }) => payload,
    [clearClosingDocument]: () => '',
  },
  ''
);

const loading = handleActions(
  {
    [setLoading]: (_, { payload }) => payload,
    [clearClosingDocument]: () => false,
  },
  false
);

export const reducer = {
  documentContent,
  loading,
};
