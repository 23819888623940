import React, { Fragment, useCallback, useMemo } from 'react';

import { Link as RouterLink, Switch } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { Breadcrumbs, Tabs } from '@crpt-ui/core';
import { Route } from '@crpt/shared/components/Route/Route';

import { breadcrumbNameMap } from '../../constants/Breadcrumbs/Breadcrumbs.constants';

import { useDocumentsRoutes } from './Documents.routes';

export const Documents = React.memo(({ location, history }) => {
  const routes = useDocumentsRoutes();

  const onLocationChange = useCallback(
    (_, path) => {
      history.push(path);
    },
    [history]
  );

  const tabItems = routes
    .filter((route) => route.title)
    .map((route) => ({
      label: route.title,
      value: route.path,
    }));

  const isShowTabs = useMemo(() => {
    return (
      tabItems.findIndex((item) => item.value === location.pathname) !== -1
    );
  }, [tabItems, location.pathname]);

  return (
    <Fragment>
      <Box mb={2}>
        <Breadcrumbs
          linkComponent={RouterLink}
          location={location}
          routes={breadcrumbNameMap}
        />
      </Box>

      {isShowTabs && (
        <Tabs
          items={tabItems}
          value={location.pathname}
          onChange={onLocationChange}
        />
      )}

      <Switch>
        {routes.map((route) => (
          <Route
            exact
            component={route.component}
            key={route.path}
            path={route.path}
          />
        ))}
      </Switch>
    </Fragment>
  );
});
