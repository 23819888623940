import { createActions } from 'redux-actions';

/**
 * Action for requisites page
 */
export const {
  fetchRequisites,
  setRequisitesData,
  sendRequisitesData,
  fetchBankInfo,
  setEditing,
} = createActions(
  {
    FETCH_REQUISITES: () => {},
    SET_REQUISITES_DATA: (payload) => payload,
    SEND_REQUISITES_DATA: (payload) => payload,

    FETCH_BANK_INFO: (payload) => payload,

    SET_EDITING: (payload) => payload,
  },
  {
    prefix: 'PROFILE_REQUISITES',
  }
);
