import React from 'react';

import { useStyles } from './List.styles';

export const List = ({ items, open }) => {
  const classes = useStyles();

  if (!open) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      {items.map((item) => (
        <div className={classes.item} key={item}>
          {item}
        </div>
      ))}
    </div>
  );
};
