export const NODE_DOC_TYPE = {
  ACCEPTANCE: {
    name: 'УПД'
  },
  CHILD_AGGREGATION: {
    name: 'Агрегация'
  },
  CROSSBORDER: {
    name: 'Трансграничная торговля'
  },
  CROSSBORDER_CSV: {
    name: 'Трансграничная торговля'
  },
  CROSSBORDER_XML: {
    name: 'Трансграничная торговля'
  },
  EMISSION: {
    name: 'Отчет об эмиссии'
  },
  IMPORT: {
    name: 'Импорт с ФТС'
  },
  IMPORT_CSV: {
    name: 'Импорт с ФТС'
  },
  IMPORT_XML: {
    name: 'Импорт с ФТС'
  },
  IMPORT_TRANSIT: {
    name: 'Трансграничная торговля'
  },
  IMPORT_TRANSIT_CSV: {
    name: 'Трансграничная торговля'
  },
  IMPORT_TRANSIT_XML: {
    name: 'Трансграничная торговля'
  },
  LK_CONTRACT_COMMISSIONING: {
    name: 'Контрактное производство РФ'
  },
  LK_CONTRACT_COMMISSIONING_CSV: {
    name: 'Контрактное производство РФ'
  },
  LK_CONTRACT_COMMISSIONING_XML: {
    name: 'Контрактное производство РФ'
  },
  LK_INDI_COMMISSIONING: {
    name: 'Полученных от физических лиц'
  },
  LK_INDI_COMMISSIONING_CSV: {
    name: 'Полученных от физических лиц'
  },
  LK_INDI_COMMISSIONING_XML: {
    name: 'Полученных от физических лиц'
  },
  LP_FTS_INTRODUCE: {
    name: 'Импорт с ФТС'
  },
  LP_FTS_INTRODUCE_CSV: {
    name: 'Импорт с ФТС'
  },
  LP_FTS_INTRODUCE_XML: {
    name: 'Импорт с ФТС'
  },
  LP_GOODS_IMPORT: {
    name: 'Производство вне ЕАЭС'
  },
  LP_GOODS_IMPORT_CSV: {
    name: 'Производство вне ЕАЭС'
  },
  LP_GOODS_IMPORT_XML: {
    name: 'Производство вне ЕАЭС'
  },
  LP_INTRODUCE_GOODS: {
    name: 'Производство РФ'
  },
  LP_INTRODUCE_GOODS_CSV: {
    name: 'Производство РФ'
  },
  LP_INTRODUCE_GOODS_XML: {
    name: 'Производство РФ'
  },
  LP_INTRODUCE_GOODS_CROSSBORDER_CSD: {
    name: 'На территории стран ЕАЭС (контрактное производство)'
  },
  LP_INTRODUCE_GOODS_CROSSBORDER_CSD_CSV: {
    name: 'На территории стран ЕАЭС (контрактное производство)'
  },
  LP_INTRODUCE_GOODS_CROSSBORDER_CSD_XML: {
    name: 'На территории стран ЕАЭС (контрактное производство)'
  },
  LP_INTRODUCE_OST: {
    name: 'Маркировка остатков'
  },
  LP_INTRODUCE_OST_CSV: {
    name: 'Маркировка остатков'
  },
  LP_INTRODUCE_OST_XML: {
    name: 'Маркировка остатков'
  },
  PARENT_AGGREGATION: {
    name: 'Агрегация'
  },
  SHIPMENT: {
    name: 'УПД'
  },
  SOLD_OUT: {
    name: 'Продажа по чеку'
  },
  USAGE: {
    name: 'Отчет об эмиссии'
  },
  UTILISATION: {
    name: 'Отчет о нанесении'
  }
};

export const documentsWithoutLink  = ['EMISSION', 'USAGE', 'UTILISATION'];

export const documentsReceipts = ['SOLD_OUT'];
