import { createActions } from 'redux-actions';

/**
 * Actions for documents page
 */
export const {
  fetchClosingDocument,
  setLoading,
  setClosingDocumentContent,
  clearClosingDocument,
} = createActions(
  {
    FETCH_CLOSING_DOCUMENT: (payload) => payload,

    SET_LOADING: (payload) => payload,
    SET_CLOSING_DOCUMENT_CONTENT: (payload) => payload,

    CLEAR_CLOSING_DOCUMENT: () => {},
  },
  {
    prefix: 'DOCUMENT_VIEW',
  }
);
