import React, { useCallback, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from '@crpt-ui/core';
import { useTranslation } from 'react-i18next';

interface RedirectButtonProps {
  path: string;
}

const RedirectButton = ({ path }: RedirectButtonProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const redirect = useCallback(() => {
    const queryParams = new URLSearchParams(history.location.search);
    const cisStatus = queryParams.get('cisStatus') 
    const uituStatus = queryParams.get('uituStatus');

    if (cisStatus === 'OTHER') {
      queryParams.delete('cisStatus');
    }

    if (uituStatus === 'OTHER') {
      queryParams.delete('uituStatus');
    }

    history.replace({
      search: queryParams.toString(),
    });
    
    history.push(`${path}${location.search}`);
  }, [history, path]);

  return (
    <Button
      color="secondary"
      icon={<Icon name="add" />}
      onClick={redirect}
      size="sm"
      variant="text"
      width={230}
    >
      {t('UnloadService.Task.formTask')}
    </Button>
  );
};

export default memo(RedirectButton);
