export const participantTypes = {
  RETAIL_ORGANIZATION: 'RETAIL_ORGANIZATION',
  WHOLESALE_ORGANIZATION: 'WHOLESALE_ORGANIZATION',
  FACTORY: 'FACTORY',
  FOIV: 'FOIV',
  IMPORTER: 'IMPORTER',
  OPERATOR_EDO: 'OPERATOR_EDO',
  OPERATOR_FD: 'OPERATOR_FD',
  TRADE_PARTICIPANT: 'TRADE_PARTICIPANT',
  IS_MP_OPERATOR: 'IS_MP_OPERATOR',
  LABELLING_CENTER: 'LABELLING_CENTER',
  FEDERAL_AUTHORITY: 'FEDERAL_AUTHORITY',
  UNDEFINED: 'UNDEFINED',
  EMITENT: 'EMITENT',
  PRODUCER: 'PRODUCER',
};

export const participantNames = {
  CEM: 'ЦЭМ',
  OGV: 'ФОИВ',
  UNDEFINED: 'Не установлен',
  PRODUCER: 'Производитель',
  FOIV: 'ФОИВ',
  WHOLESALER: 'Опт',
  RETAIL: 'Розница',
  OPERATOR_EDO: 'Оператор ЭДО',
  OPERATOR_FD: 'Оператор ФД',
  IMPORTER: 'Импортер',
  ROLE_ADMIN: 'Администратор системы',
  TRADE_PARTICIPANT: 'Участник оборота',
  IS_MP_OPERATOR: 'Оператор ИС МП',
  ROLE_ORG_IS_MP_OPERATOR: 'Оператор ИС МП',
  FEDERAL_AUTHORITY: 'Федеральный орган исполнительной власти',
};

export const PARTICIPANT_TYPES = {
  TRADE_PARTICIPANT: 'TRADE_PARTICIPANT',
  IS_MP_OPERATOR: 'IS_MP_OPERATOR',
  ROLE_ORG_IS_MP_OPERATOR: 'ROLE_ORG_IS_MP_OPERATOR',
  PRODUCER: 'PRODUCER',
  IMPORTER: 'IMPORTER',
  WHOLESALER: 'WHOLESALER',
  RETAIL: 'RETAIL',
  CEM: 'CEM',
  OGV: 'OGV',
  ROLE_ORG_OGV: 'ROLE_ORG_OGV',
  ROLE_HEAD_OGV: 'ROLE_HEAD_OGV',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_USER: 'ROLE_USER',
};
