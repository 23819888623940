import React from 'react';

import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { Status } from '@crpt-ui/core';

import { getPrepareDocumentStatus } from '../NotificationOperatorDocument.utils';
import { PageActions } from '../../../../common_components/PageActions/PageActions';

export const TopPanel = ({ documentInfo, additionalActions, loading }) => {
  return (
    <Box alignItems="center" display="flex" justifyContent="space-between">
      <Box>
        <PageActions additionalActions={additionalActions} />
      </Box>

      <Box>
        {loading ? (
          <Skeleton height={48} variant="rect" width={200} />
        ) : (
          <Status {...getPrepareDocumentStatus(documentInfo.status)} />
        )}
      </Box>
    </Box>
  );
};
