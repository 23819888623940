import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.grey[400],
      borderRadius: 16,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 32 / 12,
      padding: '0 16px',
      whiteSpace: 'nowrap',
    },
  });
