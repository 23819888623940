import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import { Page, pdfjs } from 'react-pdf';

import { withScroll } from '../hocs/withScroll/withScroll';

import { Pagination } from './Pagination/Pagination';
import { Zoom } from './Zoom/Zoom';
import { Wrapper, StyledDocument } from './PDFPreview.styled';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PDFPreviewViewComp extends PureComponent {
  static propTypes = {
    fileData: PropTypes.shape().isRequired,
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    zoomRatio: PropTypes.number.isRequired,
    onLoadSuccess: PropTypes.func.isRequired,
    onZoomIn: PropTypes.func.isRequired,
    onZoomOut: PropTypes.func.isRequired,
    onPageIncrement: PropTypes.func.isRequired,
    onPageDecrement: PropTypes.func.isRequired,
    onWillUnmount: PropTypes.func.isRequired,
    onLoadError: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    const { onWillUnmount } = this.props;

    onWillUnmount();
  }

  render() {
    const {
      fileData,
      page,
      pages,
      zoomRatio,
      onLoadSuccess,
      onLoadError,
      onZoomIn,
      onZoomOut,
      onPageIncrement,
      onPageDecrement,
      size,
    } = this.props;

    return (
      <Wrapper>
        <StyledDocument
          file={{ data: fileData }}
          noData=""
          onLoadError={onLoadError}
          onLoadSuccess={onLoadSuccess}
        >
          <Page
            pageNumber={page}
            renderAnnotationLayer={false}
            scale={zoomRatio}
          />
        </StyledDocument>

        <Zoom size={size} onZoomIn={onZoomIn} onZoomOut={onZoomOut} />

        <Pagination
          page={page}
          pages={pages}
          onDecrement={onPageDecrement}
          onIncrement={onPageIncrement}
        />
      </Wrapper>
    );
  }
}

export const PDFPreviewView = withScroll(PDFPreviewViewComp);
