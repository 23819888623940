import React from 'react';

import { connect } from 'react-redux';

import { AccountsType } from '../../../constants/PropTypes';
import { getAccounts } from '../../../components/_Profile/Company/ducks/Company.selectors';
import { Balance } from '../../Balance/Accounts';

export const BalanceComponent = ({ accounts }) => (
  <Balance accounts={accounts} style={{ width: 320 }} />
);

BalanceComponent.propTypes = {
  accounts: AccountsType,
};

const mapStateToProps = (state) => ({
  accounts: getAccounts(state),
});

export const BalanceConnected = connect(mapStateToProps)(BalanceComponent);
