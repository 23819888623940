import React, { useCallback } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { DocumentSigningModalConnected } from '../../../../common_components/DocumentSigningModal/DocumentSigningModal';

import { signServiceDeskDocument } from './ducks/ServiceDeskDocumentSignModal.actions';

export const ServiceDeskDocumentSignModal = ({ signServiceDeskDocument }) => {
  const onSign = useCallback(() => {
    signServiceDeskDocument();
  }, [signServiceDeskDocument]);

  return React.createElement(DocumentSigningModalConnected, {
    onSign,
  });
};

const mapDispatchToProps = { signServiceDeskDocument };

ServiceDeskDocumentSignModal.propTypes = {
  signAgreementDocument: PropTypes.func.isRequired,
};

export const ServiceDeskDocumentSignModalConnected = compose(
  connect(null, mapDispatchToProps)
)(ServiceDeskDocumentSignModal);
