import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';

import { isDev } from '../../utils/environment';

export class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
      .isRequired,
  };

  state = {
    isError: false,
    info: null,
  };

  componentDidCatch(_error, info) {
    this.setState({ isError: true, info: info.componentStack });
  }

  render() {
    const { children } = this.props;
    const { isError, info } = this.state;

    if (isError) {
      return (
        <Fragment>
          ERROR
          <div style={{ display: isDev ? 'block' : 'none' }}>{info}</div>
        </Fragment>
      );
    }

    return children;
  }
}
