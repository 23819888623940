import React from 'react';

import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { MultiSelectAdapter } from './MultiSelectAdapter';

/**
 * Material multi select
 */
export const MultiSelect = ({ defaultValue, ...rest }) => (
  <Field
    component={(adapter) => (
      <MultiSelectAdapter defaultValue={defaultValue} {...adapter} />
    )}
    type="text"
    {...rest}
  />
);

MultiSelect.propTypes = {
  name: PropTypes.string,
  parse: PropTypes.func,
  format: PropTypes.func,
  validate: PropTypes.func,
  visibility: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  placeholder: PropTypes.string,
  errorPlaceholder: PropTypes.string,
};

MultiSelect.defaultProps = {
  textView: false,
};

MultiSelect.displayName = 'MultiSelect';
