import React, { useCallback, useEffect, useMemo } from 'react';

import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { HistoryType } from '../../../constants/PropTypes';
import * as preloaderSelectors from '../../../common_components/Preloader/ducks/Preloader.selectors';
import { AgreementDocumentStatusMap } from '../AgreementDocument/AgreementDocument.constants';
import { declineAgreementDocument } from '../AgreementDocument/ducks/AgreementDocument.actions';
import { translate } from '../../Translate/Translate';

import { AgreementDocumentsListView } from './AgreementDocumentsList.view';
import { useAgreementDocumentsListStyles } from './AgreementDocumentsList.styled';
import {
  clearAgreementDocuments,
  clearSortAgreementDocuments,
  fetchAgreementDocuments,
  startDeclineAgreementDocument,
  startSignAgreementDocumentFromList,
} from './ducks/AgreementDocumentsList.actions';
import {
  getAgreementDocumentsActivePage,
  getAgreementDocumentsList,
  getAgreementDocumentsSortInitial,
} from './ducks/AgreementDocumentsList.selectors';
import { agreementDocumentsListColumns } from './AgreementDocumentsList.constants';
import { openManualModeModal } from './ManuallyAgreementDocument/ducks/ManuallyAgreementDocument.actions';
import { openUploadModeModal } from './UploadAgreementDocument/ducks/UploadAgreementDocument.actions';

const AgreementDocumentsList = ({
  openManualModeModal,
  openUploadModeModal,
  fetchAgreementDocuments,
  clearAgreementDocuments,
  clearSortAgreementDocuments,
  startSignAgreementDocumentFromList,
  startDeclineAgreementDocument,
  data,
  manualSortBy,
  activePage,
  isLoading,
}) => {
  useEffect(() => {
    fetchAgreementDocuments();

    return () => {
      clearAgreementDocuments();
    };
  }, [clearAgreementDocuments, fetchAgreementDocuments]);

  const NUMBER_ITEMS_ON_PAGE = 10;

  const classes = useAgreementDocumentsListStyles();

  /**
   * Pagination handler for page changes
   */
  const onPageChange = useCallback(
    (page) => {
      fetchAgreementDocuments({ page });
    },
    [fetchAgreementDocuments]
  );

  const onSortDatatable = useCallback(
    (sorting) => {
      const sort = get(sorting[0], 'id');
      if (sort) {
        fetchAgreementDocuments({ sort });
      }

      if (sorting.length === 0) {
        clearSortAgreementDocuments();
      }
    },
    [clearSortAgreementDocuments, fetchAgreementDocuments]
  );

  /**
   * If the number of elements is less than 10 pagination is not shown
   * @type {boolean}
   */
  const isShowPagination = useMemo(() => {
    return data.total > NUMBER_ITEMS_ON_PAGE;
  }, [data]);

  /**
   * Number of pagination pages
   * @type {number}
   */
  const pagesCount = useMemo(() => {
    return Math.ceil(data.total / NUMBER_ITEMS_ON_PAGE);
  }, [data]);

  /**
   * Elements for the add a list of contractors button
   * @type {({onClick: function, title: string})[]}
   */
  const dropDownItems = [
    {
      label: 'Ручное добавление контрагентов',
      onClick: () => {
        openManualModeModal();
      },
    },
    {
      label: translate('Загрузка xls списка контрагентов'),
      onClick: () => {
        openUploadModeModal();
      },
    },
  ];

  /**
   * Context menu for a table
   * @type {[{callback: function, disabled: (function(*): boolean), label: string}]}
   */
  const datatableActions = useMemo(
    () => [
      {
        label: 'Подписать',
        callback: (row) => {
          startSignAgreementDocumentFromList({
            documentId: row.original.agreementRevisionId,
          });
        },
        disabled: (row) =>
          row.values.status !== AgreementDocumentStatusMap.ESTABLISHED,
      },
      {
        label: 'Отклонить',
        callback: startDeclineAgreementDocument,
        disabled: (row) =>
          row.values.status !== AgreementDocumentStatusMap.ESTABLISHED,
      },
    ],
    [startDeclineAgreementDocument, startSignAgreementDocumentFromList]
  );

  return React.createElement(AgreementDocumentsListView, {
    columns: agreementDocumentsListColumns,
    data,
    manualSortBy,
    onSortDatatable,
    datatableActions,
    pagination: {
      activePage,
      pagesCount,
      onPageChange,
      isShowPagination,
    },
    dropDownItems,
    classes,
    isLoading,
  });
};

AgreementDocumentsList.propTypes = {
  history: HistoryType,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchAgreementDocuments: PropTypes.func.isRequired,
  clearAgreementDocuments: PropTypes.func.isRequired,
  clearSortAgreementDocuments: PropTypes.func.isRequired,
  openManualModeModal: PropTypes.func.isRequired,
  openUploadModeModal: PropTypes.func.isRequired,
  startSignAgreementDocumentFromList: PropTypes.func.isRequired,
  startDeclineAgreementDocument: PropTypes.func.isRequired,
  manualSortBy: PropTypes.object,
  activePage: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  data: getAgreementDocumentsList(state),
  isLoading: preloaderSelectors.loading(state),
  manualSortBy: getAgreementDocumentsSortInitial(state),
  activePage: getAgreementDocumentsActivePage(state),
});

const mapDispatchToProps = {
  fetchAgreementDocuments,
  clearAgreementDocuments,
  clearSortAgreementDocuments,
  openManualModeModal,
  openUploadModeModal,
  declineAgreementDocument,
  startSignAgreementDocumentFromList,
  startDeclineAgreementDocument,
};

export const AgreementDocumentsListConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(AgreementDocumentsList);
