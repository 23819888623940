import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 30,
    height: 30,
    background: '#2B5D80',
    color: '#4f5256',
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      width: 18,
    },
  },
  label: {
    marginLeft: 10,
    marginRight: 10,
  },
  currency: {
    fontWeight: 700,
  },
});
