import { createAction } from '@reduxjs/toolkit';

const subject = 'RequestList';
export const mounted = createAction(`${subject}/mounted`);
export const unmounted = createAction(`${subject}/unmounted`);
export const loaded = createAction(`${subject}/loaded`);
export const fetch = createAction(`${subject}/fetch`);
export const updatePage = createAction(`${subject}/update page`);
export const fileUpload = createAction(`${subject}/file upload`);
export const openDocument = createAction(`${subject}/open document`);
export const downloadDoc = createAction(`${subject}/download doc`);
export const gotErrors = createAction(`${subject}/got errors`);
export const clearErrors = createAction(`${subject}/clear errors`);
export const reload = createAction(`${subject}/reload`);
export const setHistoryFilters = createAction(`${subject} setHistoryFilters`);
export const setSavedFilters = createAction(`${subject} setSavedFilters`);
export const firstLoaded = createAction(`${subject}/firstLoaded`);
//Legacy
export const updateOffset = createAction(`${subject}/update offset`);
export const getRequest = createAction(`${subject}/get request`);
export const pagination = createAction(`${subject}/pagination`);
export const filters = createAction(`${subject}/filters`);
export const setSorting = createAction(`${subject}/set sorting`);
