import React from 'react';
import { Box, IconButton, BoxProps, Icons } from '@crpt/material';
import {
  TableConfig,
  TableFilter,
  UseTableInstance,
} from '@crpt/table';

export interface TableToolButtonsProps<T extends Record<string, any>>
  extends BoxProps {
  tableInstance: UseTableInstance<T>;
  hideFilters?: boolean;
  hideConfig?: boolean;
}

export function TableToolButtons<T extends Record<string, any>>({
  tableInstance,
  hideFilters,
  hideConfig,
  ...boxProps
}: TableToolButtonsProps<T>) {
  return (
    <Box {...boxProps}>
      {!hideFilters && (
        <TableFilter tableInstance={tableInstance}>
          {(open) => (
            <IconButton onClick={open}>
              <Icons.Filter_medium />
            </IconButton>
          )}
        </TableFilter>
      )}
      {!hideConfig && (
        <TableConfig tableInstance={tableInstance}>
          {(open) => (
            <IconButton onClick={open}>
              <Icons.Settings_medium />
            </IconButton>
          )}
        </TableConfig>
      )}
    </Box>
  );
}
