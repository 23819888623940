import React, { memo, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Typography, Box } from '@mui/material';

import {
  Button,
  Icon,
  Table,
  useEvent,
  FiltersTable,
  type FilterSet,
  type Filter,
  BreadCrumbs,
} from '@ibox/ui';

import { ContentWrapper } from '../common/styled';

import {
  getColumns,
  getFilters,
  getFiltersTabs,
} from './ParticipantList.constants';
import { getCookie } from '../../utils/cookieHelpers';
import { ParticipantService } from '../../services/ParticipantService';

type ParticipantListViewProp = {
  currentPage: number;
  loaded: boolean;
  last: boolean;
  onFilterApply: (filters: Filter[]) => void;
  onPageChange: (page: number, offset?: number) => void;
  results: {}[];
  total: number;
  isOperator: boolean;
  savedFilters: FilterSet[];
  historyFilters: FilterSet[];
  handleAddFiltersHistory: (filters: Filter[]) => void;
  addSavedFilters: (filters: Filter[], filterName: string) => void;
  editSavedFilter: (
    value: Filter[],
    filterName: string,
    filter: FilterSet
  ) => void;
  deleteSavedFilter: (filterId: string) => void;
};
export const ParticipantListView = memo(
  ({
    currentPage,
    loaded,
    last,
    onFilterApply,
    onPageChange,
    results,
    total,
    isOperator,
    historyFilters,
    savedFilters,
    addSavedFilters,
    handleAddFiltersHistory,
    editSavedFilter,
    deleteSavedFilter,
  }: ParticipantListViewProp) => {
    const { t } = useTranslation();
    const history = useHistory();
    const language = getCookie('language') || 'hy';

    const onAdd = () => {
      history.push('/participants/list/create');
    };

    const columns = useMemo(
      () =>
        getColumns({
          t,
        }),
      [t]
    );

    const filtersTabs = useMemo(
      () =>
        getFiltersTabs({
          t,
        }),
      [t]
    );

    const loadParticipantFilter = useEvent(
      async (value: string): Promise<{ value: string; label: string }[]> => {
        const response = await ParticipantService.searchByInput(value);
        const data = response.data.map(
          (item: { id: string; fullName: string }) => ({
            value: item.fullName,
            label: item.fullName,
          })
        );
        return data || [];
      }
    );

    const filters = useMemo(
      () =>
        getFilters({
          t,
          loadParticipantFilter,
        }),
      [t, loadParticipantFilter]
    );

    return (
      <>
        <Box className="breadcrumbsWrap">
          <BreadCrumbs
            data={[{ label: t('Участники'), href: '/participants/list' }]}
          />
        </Box>
        <ContentWrapper>
          {isOperator && (
            <Box p={2}>
              <Button onClick={onAdd}>
                <Box sx={{ display: 'flex' }}>
                  {t('Добавить участника')}
                  <Icon name="Plus" />
                </Box>
              </Button>
            </Box>
          )}
          <Box p={3}>
            <FiltersTable
              filters={filters}
              filtersHistory={historyFilters}
              filtersTabs={filtersTabs}
              locale={language}
              savedFilters={savedFilters}
              onAddFiltersHistory={handleAddFiltersHistory}
              onApplyFilters={onFilterApply}
              onDeleteFilters={deleteSavedFilter}
              onEditFilter={editSavedFilter}
              onSaveFilters={addSavedFilters}
            />
            <Table
              columns={columns}
              currentPage={currentPage}
              data={results}
              isLastPage={last}
              locale={language}
              isLoading={!loaded}
              labelPagination={t('Показать еще')}
              onPageChange={onPageChange}
            />
          </Box>
          <Box pb={3} pt={3} px={5}>
            <Typography>
              {t('Всего участников')}: {total}
            </Typography>
          </Box>
        </ContentWrapper>
      </>
    );
  }
);
