import React, { Component } from 'react';

import PropTypes from 'prop-types';

import {
  TableWrapper,
  CellWrapper,
  CellContentWrapper,
} from './TableScrollWrapper.styled';

export class TableScrollWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prepared: false,
      contentWidth: 0,
      parentWidth: 0,
    };

    this.ref = React.createRef();

    this.setParentWidth = this.setParentWidth.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.setParentWidth);
    const parentWidth = parseInt(
      window
        .getComputedStyle(this.ref.current.parentNode)
        .getPropertyValue('width'),
      10
    );
    const contentWidth = parseInt(
      window.getComputedStyle(this.ref.current).getPropertyValue('width'),
      10
    );
    this.setState({ prepared: true, parentWidth, contentWidth });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setParentWidth);
  }

  setParentWidth() {
    const parentWidth = parseInt(
      window
        .getComputedStyle(this.ref.current.parentNode)
        .getPropertyValue('width'),
      10
    );
    this.setState({ parentWidth });
  }

  render() {
    const { prepared } = this.state;

    if (!prepared) {
      return (
        <div ref={this.ref} style={{ visibility: 'hidden', position: 'fixed' }}>
          {this.props.children}
        </div>
      );
    }

    return (
      <div ref={this.ref}>
        <TableWrapper>
          <CellWrapper>
            <CellContentWrapper role="tablescrooller">
              {this.props.children}
            </CellContentWrapper>
          </CellWrapper>
        </TableWrapper>
      </div>
    );
  }
}

TableScrollWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

TableScrollWrapper.displayName = 'TableScrollWrapper';
