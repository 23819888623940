import React, { memo, useMemo } from 'react';

import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { TextFieldFinalFormWrapper } from '@ibox/ui';

import { composeValidations } from './Form.utils';
import { required as validateRequired } from './utils/validations';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  mask: PropTypes.string,
  validate: PropTypes.func,
  minLength: PropTypes.number,
};

const InputAdapterPropTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  select: PropTypes.bool,
  maxLength: PropTypes.number,
  mask: PropTypes.string,
};

const defaultProps = {
  required: false,
  disabled: false,
  maxLength: null,
  minLength: null,
  tooltipText: '',
  type: 'text',
};

const InputAdapter = ({ input, meta, ...rest }) => {
  const { t } = useTranslation();
  const val = input.value;
  const inputProps = {
    ...input,
    value: typeof val === 'string' ? t(val) : val,
  };
  return (
    <TextFieldFinalFormWrapper {...inputProps} error={meta.error} {...rest} />
  );
};

export const Input = memo(
  ({ validate, required, type, mask, minLength, ...rest }) => {
    const executableValidate = useMemo(() => {
      const validations = [];

      if (required) {
        validations.push(validateRequired);
      }

      if (typeof validate === 'function') {
        validations.push(validate);
      }

      if (minLength) {
        validations.push((value) => {
          return value && value.length < minLength ? 'Error' : undefined;
        });
      }

      return composeValidations(validations);
    }, [validate, required, minLength]);

    return (
      <Field
        {...rest}
        component={TextFieldFinalFormWrapper}
        mask={mask}
        required={required}
        type={type}
        validate={executableValidate}
      />
    );
  }
);

InputAdapter.propTypes = InputAdapterPropTypes;
Input.propTypes = propTypes;
Input.defaultProps = defaultProps;
Input.displayName = 'Input';
