import moment from 'moment';

import type { CreateTaskDataCanceled } from '../../../../services/UnloadTasksService.types';

type CreateTaskDataKeys = 'name' | 'periodicity' | 'users';

interface CreateTaskData extends Pick<CreateTaskDataCanceled, CreateTaskDataKeys> {
  participantInn: string;
  dataPeriod: {
    endDate: Date;
    startDate: Date;
  }
}

type CreateTaskKeys =
  | 'name'
  | 'dataStartDate'
  | 'dataEndDate'
  | 'periodicity'
  | 'params'
  | 'users';

interface CreateTask extends Pick<CreateTaskDataCanceled, CreateTaskKeys> {}

export const createTask = (data: CreateTaskData): CreateTask => {
  const { dataPeriod, participantInn, ...rest } = data;
  const { endDate, startDate } = dataPeriod;

  const params = {
    participantInn,
  };

  const dataStartDate = moment(startDate).format('YYYY-MM-DD');
  const dataEndDate = moment(endDate).format('YYYY-MM-DD');

  return {
    ...rest,
    dataStartDate,
    dataEndDate,
    params: JSON.stringify(params),
  };
};
