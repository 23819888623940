import React, { useMemo } from 'react';
import { Button, Icon } from '@crpt-ui/core';
import { Box, Grid } from '@material-ui/core';

import { useStyles } from './Pagination.styles';
import type { ButtonProps } from '@crpt-ui/core';

export type PaginationProps = {
  onIncrement: ButtonProps['onClick'];
  onDecrement: ButtonProps['onClick'];
  page: number;
  pages: number;
};

const Pagination: React.FC<PaginationProps> = ({
  onIncrement,
  onDecrement,
  page,
  pages,
}) => {
  const classes = useStyles();

  const disabledDecrementButton = useMemo(() => page === 1, [page]);
  const disabledIncrementButton = useMemo(() => page === pages, [page, pages]);

  return (
    <Box py={1.5}>
      <Grid alignItems="center" container justify="center" spacing={3}>
        <Grid item>
          <Button
            disabled={disabledDecrementButton}
            icon={<Icon name="ArrowLeft" />}
            onClick={onDecrement}
            size="sm"
            variant="outlined"
          />
        </Grid>

        <Grid item>
          <div className={classes.pagesText}>
            {page}&nbsp;/&nbsp;{pages}
          </div>
        </Grid>

        <Grid item>
          <Button
            disabled={disabledIncrementButton}
            icon={<Icon name="ArrowRight" />}
            onClick={onIncrement}
            size="sm"
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Pagination;
