import { createStyles } from '@material-ui/core';

const versionHistoryHeaderHeight = 80;

export default () =>
  createStyles({
    root: {
      height: '100%',
    },
    content: {
      height: `calc(100% - ${versionHistoryHeaderHeight}px)`,
      overflowY: 'auto',
    },
    header: {
      height: versionHistoryHeaderHeight,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      marginLeft: 16,
      fontWeight: 600,
      lineHeight: '24px',
    },
  });
