import React from 'react';

import { Grid } from '@mui/material';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Button } from '@ibox/ui';

import { HeaderActionsWrap } from '../../ParticipantDetails.styled';
import { acceptAndCancelAndRefundRegistration } from '../../ducks/ParticipantDetails.actions';
import { getUserInfo } from '../../../../common_components/Auth/ducks/Auth.selectors';
import { results } from '../../ducks/ParticipantDetails.selectors';
import { translate } from '../../../Translate/Translate';
import { UserInfo } from '../../../../common_components/Auth/ducks/Auth.store';

type AcceptAndCancelAndRefundRegistrationPayload = {
  org_id: number;
  mode: string;
};

type HeaderActionsComponentProps = {
  orgId: number;
  status: string;
  acceptAndCancelAndRefundRegistration: (
    obj: AcceptAndCancelAndRefundRegistrationPayload
  ) => void;
  userInfo: UserInfo;
};

const HeaderActionsComponent: React.FC<HeaderActionsComponentProps> = (
  props
) => {
  const { acceptAndCancelAndRefundRegistration, orgId, status } = props;

  return (
    <HeaderActionsWrap>
      <Grid container spacing={2}>
        <Grid item>
          {status === 'PRE_REGISTERED' && (
            <Button
              className="whiteColor"
              variant="contained"
              onClick={() =>
                acceptAndCancelAndRefundRegistration({
                  org_id: orgId,
                  mode: 'accept',
                })
              }
            >
              {translate('Подтвердить регистрацию')}
            </Button>
          )}
        </Grid>
        <Grid item>
          {status === 'PRE_REGISTERED' && (
            <Button
              variant="outlined"
              onClick={() =>
                acceptAndCancelAndRefundRegistration({
                  org_id: orgId,
                  mode: 'reject',
                })
              }
            >
              {translate('Отказать в регистрации')}
            </Button>
          )}
        </Grid>
        <Grid item>
          {status === 'REJECTED' && (
            <Button
              className="whiteColor"
              variant="contained"
              onClick={() =>
                acceptAndCancelAndRefundRegistration({
                  org_id: orgId,
                  mode: 'resume',
                })
              }
            >
              {translate('Вернуть на рассмотрение')}
            </Button>
          )}
        </Grid>
      </Grid>
    </HeaderActionsWrap>
  );
};

const mapStateToProps = (state) => ({
  userInfo: getUserInfo(state),
  orgId: results(state).id,
});

const mapDispatchToProps = {
  acceptAndCancelAndRefundRegistration,
};

export const HeaderActions = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(HeaderActionsComponent);
