import React from 'react';

import cn from 'classnames';

import { Icons } from '@ibox/ui';

import { PRODUCT_GROUP } from '../../constants/ProductGroup';
import { getEnvironment } from '../../utils/environment';

import css from './dashboard.module.scss';

export const productGroupsDetails = [
  {
    date: 'Март 2022',
    startDate: '2022-12-01T00:00:00.000Z',
    icon: <Icons.PgPerfumery className={css.icon} />,
    id: PRODUCT_GROUP.PERFUMERY.code,
  },
  {
    date: 'Март 2022',
    startDate: '2022-12-01T00:00:00.000Z',
    icon: <Icons.PgTires className={css.icon} />,
    id: PRODUCT_GROUP.TIRES.code,
  },
  {
    date: 'Март 2022',
    startDate: '2022-03-01T00:00:00.000Z',
    icon: <Icons.PgTobacco className={css.icon} />,
    id: PRODUCT_GROUP.TOBACCO.code,
  },
  {
    date: 'Март 2022',
    startDate: '2022-03-01T00:00:00.000Z',
    icon: <Icons.PgAlcohol className={css.icon} />,
    id: PRODUCT_GROUP.ALCOHOL.code,
  },
  {
    date: 'Февраль 2023',
    startDate: '2023-02-10T00:00:00.000Z',
    icon: <Icons.PgScreen className={cn(css.icon, css.phonesAndLaptops)} />,
    id: PRODUCT_GROUP.LAPTOPS.code,
  },
  {
    date: 'Февраль 2023',
    startDate: '2023-02-10T00:00:00.000Z',
    icon: <Icons.PgTelephones className={cn(css.icon, css.phonesAndLaptops)} />,
    id: PRODUCT_GROUP.TELEPHONES.code,
  },
  {
    date: 'Март 2022',
    startDate: '2022-10-01T00:00:00.000Z',
    icon: <Icons.PgWater className={css.icon} />,
    id: PRODUCT_GROUP.WATER.code,
  },
  {
    date: 'Март 2022',
    startDate: '2022-04-01T00:00:00.000Z',
    icon: <Icons.PgBeer className={css.icon} />,
    id: PRODUCT_GROUP.BEER.code,
  },
  {
    date: 'Июнь 2020',
    startDate: '2020-06-01T00:00:00.000Z',
    icon: <Icons.PgMilk className={css.icon} />,
    id: PRODUCT_GROUP.MILK.code,
  },
  {
    date: 'Апрель 2024',
    startDate: '2024-04-15T00:00:00.000Z',
    icon: <Icons.PgChemistry className={css.icon} />,
    id: PRODUCT_GROUP.CHEMISTRY.code,
  },
  {
    date: 'Апрель 2024',
    startDate: '2024-04-15T00:00:00.000Z',
    icon: <Icons.PgConserve className={css.icon} />,
    id: PRODUCT_GROUP.CONSERVE.code,
  },
  {
    date: 'Апрель 2024',
    startDate: '2024-04-15T00:00:00.000Z',
    icon: <Icons.PgSoftDrinks className={css.icon} />,
    id: PRODUCT_GROUP.SOFTDRINKS.code,
  },
].map((productGroup) => ({
  ...productGroup,
  href: getEnvironment(productGroup.id) ?? '#',
}));
