import React from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Button } from '@crpt-ui/core';

import { SignatureDescriptionConnected } from '../../../../common_components/SignatureDescription/SignatureDescription';
import { Checkbox } from '../../../../common_components/Form/Checkbox';
import { ContentWrapper } from '../../../../common_components/styled';
import { Preloader } from '../../../../common_components/Preloader/Preloader';

import { InfoAboutAcceptanceConnected } from './InfoAboutAcceptance/InfoAboutAcceptance';
import { AcceptanceContentCode } from './AcceptanceContentCode/AcceptanceContentCode';
import { SignerAuthorityConnected } from './SignerAuthority/SignerAuthority';

export const SignDocumentView = ({
  values,
  onSubmitForm,
  goToViewDocument,
  isShowAcceptanceContentCode,
  hasValidationErrors,
  isSigningInProcess,
  isLoading,
}) => (
  <ContentWrapper>
    <Preloader isOpen={isLoading} />

    <Box mt={4}>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          {isShowAcceptanceContentCode && (
            <Box mb={2}>
              <AcceptanceContentCode />
            </Box>
          )}

          <Box mb={4}>
            <SignerAuthorityConnected />
          </Box>

          <Box mb={4}>
            <Checkbox label="Сведения о приемке" name="isOpenAcceptance" />

            {values.isOpenAcceptance && (
              <Box mt={2}>
                <InfoAboutAcceptanceConnected />
              </Box>
            )}
          </Box>
          <Grid
            container
            spacing={2}
            style={{
              maxWidth: '710px',
            }}
          >
            <Grid item>
              <Button
                className="whiteColor"
                disabled={hasValidationErrors || isSigningInProcess}
                loading={isSigningInProcess}
                onClick={onSubmitForm}
              >
                Подписать и отправить
              </Button>
            </Grid>
            <Grid
              item
              style={{
                marginLeft: 'auto',
              }}
            >
              <Button variant="outlined" onClick={goToViewDocument}>
                Отменить
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={5}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h5">Ваш сертификат</Typography>
            </Grid>
            <Grid item>
              <SignatureDescriptionConnected direction="vertical" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </ContentWrapper>
);

SignDocumentView.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  goToViewDocument: PropTypes.func.isRequired,
  isShowAcceptanceContentCode: PropTypes.bool.isRequired,
};
