import React, { memo } from 'react';
import { Form } from 'react-final-form';
import { Box } from '@crpt/material';
import { Typography, Icon, Divider, Button, Dialog } from '@crpt-ui/core';
import { Content } from '@crpt/shared/components';
import AcsDocumentsViewModel from './AcsDocuments.viewmodel';
import { Header, EditInns, CommonInformation, Receiver } from '../components';
import UnloadFilter from './FieldGroup/UnloadFilter';

import type { AcsDocumentsViewModelOptions } from './AcsDocuments.viewmodel';

interface AcsDocumentsProps
  extends Omit<AcsDocumentsViewModelOptions, 'pathToUnloadServiceRegistry'> {
  pathToUnloadServiceRegistry?: string;
}

const AcsDocuments: React.FC<AcsDocumentsProps> = ({
  pathToUnloadServiceRegistry = '/unload-service',
  ...restProps
}) => {
  const vm = AcsDocumentsViewModel({
    pathToUnloadServiceRegistry,
    ...restProps,
  });

  return (
    <Box flexGrow={1}>
      <Content>
        <Form
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          form={vm.formInstance}
          onSubmit={vm.formInstance.submit}
          subscription={{
            dirty: true,
            hasValidationErrors: true,
          }}
        >
          {({ dirty, handleSubmit, hasValidationErrors }) => {
            return (
              <>
                <Header
                  hasErrors={hasValidationErrors}
                  loading={vm.isUploading}
                  onSubmit={handleSubmit}
                  pathToUnloadServiceRegistry={pathToUnloadServiceRegistry}
                />
                <Divider />
                <Box
                  sx={{
                    flexGrow: 1,
                    py: 4,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mb: 6,
                      mx: 4,
                    }}
                  >
                    <Typography
                      style={{
                        marginBottom: 0,
                      }}
                      variant="h3"
                    >
                      {vm.t('UnloadService.Task.AcsDocuments.Title')}
                    </Typography>
                    <Button
                      color="secondary"
                      disabled={!dirty}
                      icon={<Icon name="cancel" />}
                      onClick={vm.resetForm}
                      variant="text"
                    >
                      {vm.t('reset')}
                    </Button>
                  </Box>

                  <Box mb={4} mx={4}>
                    <CommonInformation
                      productGroupCodeOptions={vm.productGroupCodeOptions}
                    />
                  </Box>
                  <Box mb={4}>
                    <Divider />
                  </Box>

                  <Box mb={4} mx={4}>
                    <UnloadFilter
                      handleEditInnsOpen={vm.handleEditInnsOpen}
                      isOperator={restProps.isOperator}
                    />
                  </Box>
                  <Box mb={4}>
                    <Divider />
                  </Box>

                  <Box mb={4} mx={4}>
                    <Receiver usersList={vm.usersList} />
                  </Box>
                </Box>
              </>
            );
          }}
        </Form>
      </Content>
      <Dialog
        maxWidth="md"
        onClose={vm.closeEditInnsModal}
        open={vm.isEditInnsModalOpen}
      >
        <EditInns
          {...vm.editInnsState}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          formChange={vm.formInstance.change}
          onClose={vm.closeEditInnsModal}
        />
      </Dialog>
    </Box>
  );
};

export default memo(AcsDocuments);
