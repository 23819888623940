import { createAction } from 'redux-actions';

export const subject = '[Registration]';

export const submit = createAction(`${subject} submit`);
export const errors = createAction(`${subject} errors`);
export const setCompleteStep = createAction(`${subject}/SET_COMPLETE_STEP`);
export const step = createAction(`${subject} step`);

export const setDocumentId = createAction(`${subject}/SET_DOCUMENT_ID`);
export const setLoading = createAction(`${subject}/SET_LOADING`);
