import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { LanguageSwitcher, useEvent } from '@ibox/ui';

import { StripeLayout } from '../../common_components/Layouts';
import { Form } from '../../common_components/Form/form/form';
import { LanguageWrap } from '../../common_components/LanguageMenu/LanguageWrap';
import { getCookie, setLanguage } from '../../utils/cookieHelpers';
import type { Language } from '../../helpers/index';

import { Fields } from './Fields/Fields';
import { submit } from './ducks/LoginForm.actions';

import css from './index.module.scss';

export const LoginForm = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const DefaultLng: Language = (getCookie('language') as Language) || 'ru';

  const [lang, setLang] = useState<Language>(DefaultLng);

  const languages: Language[] = ['ru', 'hy'];

  const changeLanguage = useEvent((language: Language) => {
    i18n.changeLanguage(language.toUpperCase());
    setLang(language);
    setLanguage(language);
    localStorage.setItem('language', language);
  });

  useEffect(() => {
    const language = (getCookie('language') || 'ru') as Language;

    changeLanguage(language);
  }, [changeLanguage]);

  const onLangSelect = useEvent((language: Language) => {
    changeLanguage(language);
  });

  const onSubmit = (data: unknown) => {
    dispatch(submit(data));
  };

  return (
    <>
      <StripeLayout title={t('Вход в систему')}>
        <Form style={{ width: '95%' }} onSubmit={onSubmit}>
          <Fields />
        </Form>
      </StripeLayout>
      <LanguageWrap>
        <LanguageSwitcher
          className={css.langSwitcher}
          languages={languages}
          selected={lang}
          onChange={onLangSelect}
        />
      </LanguageWrap>
    </>
  );
};
