import React from 'react';

import clsx from 'clsx';

import classes from './product-group-status.module.scss';

export const ProductGroupStatus = ({
  className,
  description,
  label,
  ...rest
}) => (
  <div className={clsx(classes.root, className)} {...rest}>
    <div className={classes.label}>{label}</div>
    {description ? (
      <div className={classes.description}>{description}</div>
    ) : null}
  </div>
);
