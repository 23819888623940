import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { deleteAllCookies } from '../../utils/cookieHelpers';
import { history } from '../../store';

import { submit } from './ducks/LoginFormKep.actions';
import { LoginFormInnView } from './LoginFormInn.view';
import { getErrors, getLoading } from './ducks/LoginFormKep.selectors';

export const LoginFormInn = () => {
  const isLoading = useSelector((state) => getLoading(state));
  const errors = useSelector((state) => getErrors(state));

  const dispatch = useDispatch();

  useEffect(() => {
    const nidKeys = window.localStorage.getItem('nidKeys');
    if (!nidKeys) {
      deleteAllCookies();
      history.push('/login-kep');
    }
  }, []);

  const onSubmit = useCallback(
    (val) => {
      const nidKeys = window.localStorage.getItem('nidKeys');
      const code = window.localStorage.getItem('code');
      if (nidKeys) {
        const { code_verifier } = JSON.parse(nidKeys);
        dispatch(submit({ inn: val.inn, code_verifier, code }));
      } else {
        deleteAllCookies();
        localStorage.clear();
        history.push('/login-kep');
      }
    },
    [dispatch]
  );

  return React.createElement(LoginFormInnView, {
    onSubmit,
    errors,
    isLoading,
  });
};
