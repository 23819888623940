import {
  makeCell,
  makeDateCell,
  makeLinkCell,
} from '../../../utils/Table.utils';
import {
  AgreementDocumentStatusHEXColorMap,
  AgreementDocumentStatusIconsMap,
  AgreementDocumentStatusValueMap,
} from '../AgreementDocument/AgreementDocument.constants';

export const agreementDocumentsListColumns = [
  {
    accessor: 'createDate',
    Cell: makeDateCell({
      format: 'DD.MM.YYYY',
    }),
    Header: 'Дата регистрации',
  },
  {
    accessor: 'publicationDate',
    Header: 'Дата подписания',
    Cell: makeDateCell({
      format: 'DD.MM.YYYY',
    }),
  },
  {
    accessor: 'expirationDate',
    Header: 'Дата завершения',
    Cell: makeDateCell({
      format: 'DD.MM.YYYY',
    }),
  },
  {
    accessor: 'documentRegNumber',
    Header: 'Рег. № документа',
    Cell: makeLinkCell({
      to: '/documents/agreement/document',
      accessorId: 'original.agreementRevisionId',
    }),
  },
  {
    Header: 'Аннулирование',
    accessor: 'cancellation.documentRegNumber',
    Cell: makeLinkCell({
      to: '/documents/agreement/cancellation',
      accessorId: 'original.cancellation.id',
      iconName: 'Save',
    }),
  },
  {
    accessor: 'status',
    Cell: makeCell({
      colors: AgreementDocumentStatusHEXColorMap,
      icons: AgreementDocumentStatusIconsMap,
      values: AgreementDocumentStatusValueMap,
    }),
    Header: 'Статус',
  },
];
