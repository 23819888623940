import React, { useMemo } from 'react';

import { Box } from '@mui/material';
import { get } from 'lodash/fp';

import styles from './index.module.scss';

export const makeCell =
  ({ colors, icons, values }) =>
  ({ cell }) => {
    const value = useMemo(() => get(cell.getValue(), values), [cell]);
    const color = useMemo(() => get(cell.getValue(), colors), [cell]);
    const icon = useMemo(() => get(cell.getValue(), icons), [cell]);

    return (
      <Box
        className={styles.container}
        style={{
          color: color,
        }}
      >
        {icon ? <Box className={styles.iconContainer}>{icon}</Box> : null}
        <Box className={styles.valueContainer}>{value}</Box>
      </Box>
    );
  };
