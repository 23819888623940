import { handleActions } from 'redux-actions';

import {
  addNewFieldParticipantGs1,
  removeAllParticipantGs1,
  removeParticipantGs1,
  resetSkipGs1Step,
  setEdoId,
  setEdoList,
  setFieldParticipantGs1,
  setLoading,
  setNewFieldParticipantGs1,
  setProductGroupList,
  setSelectedEdo,
  setSelectedProductGroup,
  setSkipGs1Step,
  setStep,
  skipParticipantGs1,
} from './StartPage.actions';
import { removeGS1Field } from './Utils/StartPage.utils';

/**
 * Operators EDO list
 * @type {OperatorEdoType} - EDO list
 */
const operatorEdo = handleActions(
  {
    [setEdoList]: (_, { payload }) => ({
      list: payload,
      activeEdo: [],
    }),
    [setSelectedEdo]: (state, { payload }) => ({
      ...state,
      ...{ activeEdo: payload },
    }),
  },
  { list: [], activeEdo: [] }
);

/**
 * Operator EDO ids
 * @type {OperatorEdoIdsType} - EDO ids
 */
const operatorEdoIds = handleActions(
  {
    [setEdoId]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  {}
);

/**
 * Product group list
 * @type {ProductGroupType} - Product group list
 */
const productGroup = handleActions(
  {
    [setProductGroupList]: (_, { payload }) => ({
      list: payload,
      activeProductGroup: [],
    }),
    [setSelectedProductGroup]: (state, { payload }) => ({
      ...state,
      ...{ activeProductGroup: payload },
    }),
  },
  { list: [], activeProductGroup: [] }
);

/**
 * Is skip GS1 step
 * @type {boolean} - Is skip GS1 step
 */
const skipGS1Step = handleActions(
  {
    [setSkipGs1Step]: () => true,
    [resetSkipGs1Step]: () => false,
  },
  false
);

/**
 * Is participant GS1
 * @type {boolean} - Is participant GS1
 */
const isParticipantGS1 = handleActions(
  {
    [setNewFieldParticipantGs1]: () => true,
    [skipParticipantGs1]: () => false,
  },
  false
);

/**
 * GS1 list
 * @type {GS1ListType}
 */
const GS1List = handleActions(
  {
    [addNewFieldParticipantGs1]: (state, { payload }) => [...state, payload],
    [removeAllParticipantGs1]: () => [],
    [removeParticipantGs1]: (state, { payload }) => [
      ...[],
      ...removeGS1Field(state, payload),
    ],
    [setFieldParticipantGs1]: (_state, { payload }) => payload,
  },
  []
);

/**
 * Active step
 * @type {number} - Active step
 */
const activeStep = handleActions(
  {
    [setStep]: (_state, { payload }) => payload,
  },
  0
);

const IsLoading = handleActions(
  {
    [setLoading]: (_, { payload }) => payload,
  },
  false
);

export const reducer = {
  activeStep,
  skipGS1Step,
  operatorEdo,
  operatorEdoIds,
  isParticipantGS1,
  GS1List,
  productGroup,
  IsLoading,
};
