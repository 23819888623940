import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';

import { Tabs } from '@crpt-ui/core';
import { Route } from '@crpt/shared/components/Route/Route';

import { HubNotificationConnected } from './HubNotification/HubNotification';
import { ClosingDocumentActionsConnected } from './ViewClosingDocument/ClosingDocumentActions/ClosingDocumentActions';
import { ClosingDocumentStatusConnected } from './Status/Status';
import { EventsConnected } from './Events/Events';
import { EventModalConnected } from './EventModal/EventModal';
import { SkeletonLoader } from './SkeletonLoader';

export const DocumentView = ({
  routes,
  tabs,
  onLocationChange,
  currentUrl,
  isShowSignTab,
  isLoading,
}) => (
  <Fragment>
    <EventModalConnected />

    <HubNotificationConnected />

    {isLoading ? (
      <SkeletonLoader />
    ) : (
      <Grid container justify="space-between">
        <Grid item>
          <ClosingDocumentActionsConnected />
        </Grid>
        <Grid
          item
          style={{
            height: 48,
          }}
        >
          <ClosingDocumentStatusConnected />
        </Grid>
      </Grid>
    )}

    <Box my={2}>
      <EventsConnected />
    </Box>

    {isShowSignTab && (
      <Tabs items={tabs} value={currentUrl} onChange={onLocationChange} />
    )}

    <Switch>
      {routes.map((route) => (
        <Route
          exact
          component={route.component}
          key={route.path}
          path={route.path}
        />
      ))}
    </Switch>
  </Fragment>
);

DocumentView.propTypes = {
  onLocationChange: PropTypes.func.isRequired,
  currentUrl: PropTypes.string.isRequired,
  isShowSignTab: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
