import { createReducer } from '@reduxjs/toolkit';
import { handleActions } from 'redux-actions';

import * as actions from './Documents.actions';

const last = createReducer(false, {
  [actions.firstLoaded.type]: (_, { payload }) => payload.last || false,
  [actions.loaded.type]: (_state, { payload }) => payload.last || false,
  [actions.unmounted.type]: () => false,
});

const results = createReducer([], {
  [actions.mounted.type]: () => [],
  [actions.loaded.type]: (state, { payload }) => [
    ...state,
    ...(payload.results || []),
  ],
  [actions.firstLoaded.type]: (_, { payload }) => [...(payload.results || [])],
  [actions.unmounted.type]: () => [],
});

const selectedPage = createReducer(1, {
  [actions.updatePage.type]: (_state, { payload }) => payload,
  [actions.unmounted.type]: () => 1,
});

const uploadErrors = createReducer([], {
  [actions.gotErrors.type]: (_state, { payload }) => payload || [],
  [actions.clearErrors.type]: () => [],
  [actions.fileUpload.type]: () => [],
  [actions.unmounted.type]: () => [],
});

const reloading = createReducer(false, {
  [actions.mounted.type]: () => false,
  [actions.reload.type]: (_state, { payload }) => payload || false,
  [actions.unmounted.type]: () => false,
});

const historyFilters = handleActions(
  {
    [actions.setHistoryFilters]: (_state, { payload }) => payload || [],
    [actions.unmounted]: () => false,
  },
  []
);

const savedFilters = handleActions(
  {
    [actions.setSavedFilters]: (_state, { payload }) => payload || [],
    [actions.unmounted]: () => false,
  },
  []
);

export const reducer = {
  last,
  reloading,
  results,
  selectedPage,
  uploadErrors,
  historyFilters,
  savedFilters,
};
