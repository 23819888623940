import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialSwitch from '@material-ui/core/Switch';
import { Field } from 'react-final-form';

const StyledSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 24,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,

    '&$checked': {
      transform: 'translateX(15px)',
      color: theme.palette.common.white,

      '& + $track': {
        backgroundColor: '#868686',
        opacity: 1,
        border: 'none',
      },

      '& $thumb': {
        backgroundColor: theme.palette.primary.main,
      },
    },

    '&$focusVisible $thumb': {
      border: '6px solid #fff',
    },

    '&:hover': {
      backgroundColor: 'none',
    },
  },
  thumb: {
    width: 20,
    height: 20,
    marginTop: 1,
    transition: theme.transitions.create(['background-color']),
    boxShadow: 'none',
    backgroundColor: '#868686',
    transform: 'translateX(2px)',
  },
  track: {
    height: 'auto',
    borderRadius: 26 / 2,
    border: `1px solid #d9d9d9`,
    backgroundColor: 'white',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <MaterialSwitch
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      focusVisibleClassName={classes.focusVisible}
      {...props}
    />
  );
});

export const Switch = ({ label, name, onChange, ...rest }) => {
  const onChangeHandle = useCallback(
    (input) => (event) => {
      if (typeof onChange === 'function') {
        onChange(event);
      }
      input.onChange(event);
    },
    [onChange]
  );

  return (
    <Field name={name} type="checkbox">
      {({ input }) => {
        return (
          <FormControlLabel
            control={
              <StyledSwitch
                {...input}
                name={name}
                value={input.value}
                onChange={onChangeHandle(input)}
                {...rest}
              />
            }
            label={label}
          />
        );
      }}
    </Field>
  );
};

Switch.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};
