import {
  Divider as DividerMaterialUi,
  withStyles,
  createStyles,
} from '@material-ui/core';

const DividerStyles = createStyles({
  root: {
    backgroundColor: '#f2f2f2',
  },
});

export const Divider = withStyles(DividerStyles)(DividerMaterialUi);
