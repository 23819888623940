import { handleActions } from 'redux-actions';
import { omit } from 'lodash';

import * as actions from './DocumentsList.actions';

const data = handleActions(
  {
    [actions.mount]: () => [],
    [actions.offset]: () => [],
    [actions.loaded]: (_state, { payload = { items: [] } }) =>
      payload.items || [],
  },
  []
);

const total = handleActions(
  {
    [actions.mount]: () => 0,
    [actions.loaded]: (_state, { payload = {} }) => payload.count || 0,
  },
  0
);

const params = handleActions(
  {
    [actions.mount]: () => ({ offset: 0 }),
    [actions.params]: (_state, { payload = {} }) => payload,
    [actions.offset]: (state, { payload = {} }) => ({
      ...state,
      offset: payload,
    }),
    [actions.filter]: (state, { payload = {} }) => ({
      offset: state.offset,
      ...payload,
    }),
    [actions.removeKeys]: (state, { payload }) => omit(state, payload),
  },
  { offset: 0 }
);

export const reducer = {
  data,
  total,
  params,
};
