import { makeRangeDatepickerFilter } from '@crpt-ui/datatable/src/Filters/RangeDatepickerFilter';
import { makeCheckboxGroupFilter } from '@crpt-ui/datatable/src/Filters/CheckboxGroupFilter'; // TODO убрать /src с пути

import {
  documentOutgoingStatusCaption,
  documentStatusHEXColor,
  documentStatusIcon,
} from '../../../constants/documentStatuses';
import {
  makeCell,
  makeDateCell,
  makeFilterOptions,
  makeLinkCell,
} from '../../../utils/Table.utils';

export const NOTIFICATION_OPERATOR_TYPES = {
  [-33]: 'Уведомление об изменении реквизитов',
  [-34]: 'Уведомление о расторжении договора',
  [-35]: 'Предоставление доверенности',
  [-36]: 'Запрос на изменение данных в ГИС',
};

export const columns = [
  {
    Header: 'Дата регистрации',
    accessor: 'created_at',
    width: 200,
    Cell: makeDateCell({
      format: 'DD.MM.YYYY',
      unix: true,
    }),
    Filter: makeRangeDatepickerFilter({
      maxDate: new Date(),
    }),
    filterIconName: 'Calendar',
  },
  {
    Header: 'Номер документа',
    accessor: 'id',
    Cell: makeLinkCell({
      to: '/documents/notification-operator',
      accessorId: 'original.id',
      gettingValue: (value) => value.toUpperCase(),
    }),
    width: 300,
    disableSortBy: true,
  },
  {
    Header: 'Наименование документа',
    accessor: 'type',
    Cell: makeCell({
      values: NOTIFICATION_OPERATOR_TYPES,
    }),
    Filter: makeCheckboxGroupFilter(
      makeFilterOptions(NOTIFICATION_OPERATOR_TYPES)
    ),
  },
  {
    Header: 'Тип документа',
    width: 200,
    Cell: () => 'Исходящий',
  },
  {
    Header: 'Статус',
    accessor: 'status',
    width: 200,
    Cell: makeCell({
      colors: documentStatusHEXColor,
      icons: documentStatusIcon,
      values: documentOutgoingStatusCaption,
    }),
  },
];
