import { handleAction, handleActions } from 'redux-actions';

import {
  setEditing,
  setEdoOperators,
  setEdoRegistrationData,
  setLoading,
  setSubmitting,
} from './EdoRegistration.actions';

const data = handleAction(
  setEdoRegistrationData,
  (_, { payload }) => payload,
  {}
);

const operators = handleAction(
  setEdoOperators,
  (_, { payload }) => payload,
  []
);

const editing = handleAction(setEditing, (_, { payload }) => payload, false);

const loading = handleActions(
  {
    [setLoading]: (_, { payload }) => payload,
  },
  false
);

const submitting = handleActions(
  {
    [setSubmitting]: (_, { payload }) => payload,
  },
  false
);

export const reducer = {
  data,
  operators,
  editing,
  loading,
  submitting,
};
