import React, { useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { Select as SelectCore } from '@ibox/ui';

import { FieldHoc } from './FieldHoc';

const SelectAdapter = ({ input, meta, ...fieldRest }) => {
  const {
    placeholder,
    // errorPlaceholder,
    isError,
    options,
    setValueToggle,
    onSelect,
    ...rest
  } = fieldRest;
  const [isToggle, setToggle] = useState(false);

  const onChangeHandle = (event, option) => {
    input.onChange(event, option);
    onSelect && onSelect(option);
  };

  const onHandleBlur = () => {
    setToggle(false);
    setValueToggle(isToggle);
  };

  const onHandlerFocus = () => {
    setToggle(true);
    setValueToggle(isToggle);
  };

  const errorArray = useMemo(() => isError(meta), [isError, meta]);

  const error = useMemo(
    () => errorArray && errorArray.length > 0,
    [errorArray]
  );

  return (
    <SelectCore
      {...input}
      error={error}
      label={placeholder}
      options={options}
      value={input.value}
      onBlur={onHandleBlur}
      onChange={onChangeHandle}
      onFocus={onHandlerFocus}
      {...rest}
    />
  );
};

SelectAdapter.propTypes = {
  isError: PropTypes.func,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
};

SelectAdapter.defaultProps = {
  isError: (meta) => meta.error,
  setValueToggle: () => {},
};

export const Select = FieldHoc(SelectAdapter);
