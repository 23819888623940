import React, { useCallback, useEffect, useMemo } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { getOr } from 'lodash/fp';
import { Field } from 'react-final-form';

import {
  AutocompleteField,
  Button,
  Icon,
  SelectFieldFinalFormWrapper,
  TextFieldFinalFormWrapper,
} from '@ibox/ui';

import { withFormContext } from '../../common_components/Form/Form.utils';
import { req as required } from '../../common_components/Form/Form.validations';
import * as selectors from '../_Profile/Company/ducks/Company.selectors';
import { results } from '../ParticipantDetails/ducks/ParticipantDetails.selectors';

const name = 'productGroupsAndRoles';

type GroupOrRoleItem = {
  label: React.ReactNode;
  value: string;
};

type ValueItem = {
  code: string;
  license: string;
  types: string[];
};

type RoleCreate = {
  formMutators: Record<string, unknown>;
  value: ValueItem[];
  roles?: GroupOrRoleItem[];
  groups?: GroupOrRoleItem[];
  data?: Record<string, unknown>;
  disabled?: boolean;
};

const RoleCreate: React.FC<RoleCreate> = ({
  formMutators,
  value = [],
  data: initialValues,
  roles = [],
  groups = [],
  disabled,
}) => {
  const { t } = useTranslation();
  const addProductGroup = useCallback(() => formMutators.push(name, {}), []);

  const rolesOptions = useMemo(() => {
    return roles.map(({ code, name }) => ({
      value: code,
      label: t(name),
    }));
  }, [roles, t]);

  useEffect(() => {
    if (value.length === 0) {
      formMutators.push(name, {});
    }
  }, []);

  const removeProductGroup = useCallback(
    (index) => formMutators.remove(name, index),
    [formMutators]
  );

  const selectedProductGroups = useMemo(() => {
    return value.map((item) => item.code);
  }, [value]);

  const isProductGroupDisabled = useCallback(
    (value) => {
      return selectedProductGroups.find((item) => item === value);
    },
    [selectedProductGroups]
  );

  const productGroups = useMemo(() => {
    return groups.map((productGroup) => ({
      value: productGroup.code,
      label: t(productGroup.name),
      disabled: isProductGroupDisabled(productGroup.value),
    }));
  }, [groups, isProductGroupDisabled, t]);

  const isProductGroupAndRoleDisabled = useCallback(
    (productGroupAndRole) => {
      if (disabled) {
        return true;
      }

      if (!productGroupAndRole.code) {
        return false;
      }

      return getOr([])('productGroupsAndRoles')(initialValues)
        .map((i) => i.code)
        .includes(productGroupAndRole.code);
    },
    [disabled, initialValues]
  );

  const canDeleteProductGroup = useCallback(
    (productGroupAndRole) =>
      !isProductGroupAndRoleDisabled(productGroupAndRole),
    [isProductGroupAndRoleDisabled]
  );

  const canCreateProductGroupAndRole = useMemo(() => {
    if (disabled) {
      return false;
    }
    return value.length < groups.length;
  }, [groups, disabled, value]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h6">{t('Роль участника')}</Typography>
      </Grid>

      {value.map((productGroupAndRole, index) => {
        return (
          <React.Fragment key={`${name}.${index}.code`}>
            <Grid container item spacing={2}>
              <Grid container item xs={6}>
                <Grid
                  item
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <Field
                    required
                    component={SelectFieldFinalFormWrapper}
                    disabled={isProductGroupAndRoleDisabled(
                      productGroupAndRole
                    )}
                    label={t('Товарная группа')}
                    name={`${name}.${index}.code`}
                    options={productGroups}
                    validate={
                      isProductGroupAndRoleDisabled(productGroupAndRole)
                        ? undefined
                        : required
                    }
                  />
                </Grid>
              </Grid>
              <Grid container item xs={6}>
                <Grid
                  item
                  style={{
                    flexGrow: 1,
                    width: '80%',
                  }}
                >
                  <Field
                    fullWidth
                    multiple
                    required
                    component={AutocompleteField}
                    defaultValue={getOr([])(`${index}.types`)(value)}
                    disabled={disabled}
                    label={t('Тип участника')}
                    name={`${name}.${index}.types`}
                    options={rolesOptions}
                    validate={required}
                  />
                </Grid>

                {canDeleteProductGroup(productGroupAndRole) ? (
                  <Grid
                    item
                    style={{
                      marginLeft: '20px',
                      display: 'flex',
                    }}
                  >
                    <Button
                      color="error"
                      disabled={value.length <= 1}
                      variant="outlined"
                      onClick={() => removeProductGroup(index)}
                    >
                      <Icon name="Delete" />
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  multiline
                  component={TextFieldFinalFormWrapper}
                  defaultValue={disabled ? '-' : ''}
                  disabled={disabled}
                  label={t('Сведения о лицензиях')}
                  name={`${name}.${index}.license`}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        );
      })}

      {canCreateProductGroupAndRole ? (
        <Grid item>
          <Button variant="outlined" onClick={addProductGroup}>
            {t('Добавить ещё')}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  data: results(state),
  roles: selectors.getCompanyRoles(state),
  groups: selectors.getProductGroups(state),
});

export const RolesConnected = compose(
  connect(mapStateToProps),
  withFormContext({
    mutators: true,
    name,
  })
)(RoleCreate);
