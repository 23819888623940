import { createSelector } from 'reselect';

export const getCreatePaymentDocumentState = (state) =>
  state.Documents.PaymentDocumentsList.CreatePaymentDocument;

export const getOpenCreatePaymentModal = createSelector(
  [getCreatePaymentDocumentState],
  (state) => state.open
);

export const getSelectedAccount = createSelector(
  [getCreatePaymentDocumentState],
  (state) => state.selectedAccount
);

export const getLoading = createSelector(
  [getCreatePaymentDocumentState],
  (state) => state.loading
);
