import React, { FC } from 'react';
import { Box, Drawer, Grid, WithStyles, withStyles } from '@material-ui/core';
import { Divider, Icon } from '@crpt-ui/core';
import { NoInfo, Preloader, Threads } from '@crpt/shared/components';
import { Node, Scenario } from '@crpt/shared/components/Threads/Threads.types';
import ThreadsScenarioStyles from './ThreadsScenario.styles';
import {
  renderDocumentLink,
  renderParticipantLink,
} from './ThreadsScenario.utils';
import { VersionsButton, VersionsHistory } from './Versions';
import clsx from 'clsx';

export type ThreadsScenarioViewProps = {
  clickedVersion: number | undefined;
  data: Scenario;
  fetchVersion: (version: number) => void;
  hasFakeNode: boolean;
  isLoading: boolean;
  isStartState: boolean;
  isVersionsHistoryShow: boolean;
  nodes: Node[];
  setVersionsHistoryClose: () => void;
  toggleVersionsHistory: () => void;
  version: number | undefined;
  versions: number[];
} & WithStyles<typeof ThreadsScenarioStyles>;

const ThreadsScenarioView: FC<ThreadsScenarioViewProps> = ({
  isStartState,
  isLoading,
  nodes,
  isVersionsHistoryShow,
  toggleVersionsHistory,
  hasFakeNode,
  data,
  setVersionsHistoryClose,
  versions,
  clickedVersion,
  version,
  fetchVersion,
  classes,
}) => {
  if (!isStartState && !isLoading && !nodes.length) {
    return <NoInfo />;
  }

  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.mainContent, {
          [classes.mainContentShift]: isVersionsHistoryShow,
        })}
      >
        <div className={classes.topPanel}>
          <Grid container spacing={3} justify="flex-end" alignItems="center">
            <Grid item>
              <VersionsButton onClick={toggleVersionsHistory} />
            </Grid>
            {hasFakeNode && (
              <Grid item>
                <Box fontSize={14} display="flex" alignItems="center">
                  <Box mr={1} color="warning.main">
                    <Icon name="error" />
                  </Box>
                  <Box color="warning.main">Оранжевым</Box>
                  &nbsp;отмечены фиктивные узлы
                </Box>
              </Grid>
            )}
          </Grid>
        </div>

        <Divider />

        <div
          className={clsx(classes.threads, {
            [classes.threadsShrinked]: isVersionsHistoryShow,
          })}
        >
          {isLoading ? (
            <Preloader isOpen={isLoading} text="Загрузка данных..." />
          ) : nodes.length ? (
            <Threads
              renderDocumentLink={renderDocumentLink}
              renderParticipantLink={renderParticipantLink}
              scenario={data}
            />
          ) : null}
        </div>
      </div>

      <Drawer
        anchor="right"
        variant="persistent"
        className={clsx(classes.drawer, {
          [classes.drawerGrowed]: isVersionsHistoryShow,
        })}
        open={isVersionsHistoryShow}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          style: { position: 'absolute' },
        }}
      >
        <VersionsHistory
          onClose={setVersionsHistoryClose}
          versions={versions}
          selectedVersion={clickedVersion || version}
          onVersionClick={fetchVersion}
        />
      </Drawer>
    </div>
  );
};

export default withStyles(ThreadsScenarioStyles)(ThreadsScenarioView);
