import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    root: {
      '& *': {
        boxSizing: 'border-box',
      },

      '& $header, & $footer': {
        textAlign: 'center',
      },
    },
    header: {
      background: theme.palette.primary.main,
      minHeight: 320,
      padding: '0 0 48px',
      position: 'relative',
    },
    title: {
      fontSize: 32,
      fontWeight: 700,
      letterSpacing: '0.02em',
      lineHeight: 48 / 32,
      textTransform: 'uppercase',
    },
    container: {
      margin: '0 auto',
      minWidth: 768,
      padding: '0 32px',
      position: 'relative',

      '$sizeMd &': {
        maxWidth: 768,
      },

      '$sizeLg &': {
        maxWidth: 1008,
      },
    },
    content: {
      background: theme.palette.common.white,
      borderRadius: 4,
      boxShadow: '0px 15px 50px rgba(0, 0, 0, 0.05)',
      marginTop: -32,
      padding: 32,
      position: 'relative',
    },
    sizeLg: {},
    sizeMd: {},
    logo: {
      height: 224,
      paddingTop: 80,
    },
    footer: {
      marginTop: 32,
    },
  });
