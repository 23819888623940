import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';

import { Notification } from '@crpt-ui/core';

import { makeShortName } from '../../../../common_components/PageHeader/PageHeader.utils';
import { getDocumentInfo } from '../ducks/ClosingDocument.selectors';

import { useStyles } from './Events.styles';
import { getCommentLabel, getNotificationCaption } from './Events.utils';
import { fetchEventsData } from './ducks/Event.actions';
import { getDocumentEvents, getLoading } from './ducks/Events.selectors';

const Events = ({ events, documentInfo, fetchEventsData, isLoading }: any) => {
  useEffect(() => {
    fetchEventsData();
  }, [fetchEventsData]);

  const classes = useStyles();

  if (isLoading) {
    return <Skeleton height={86} width={'100%'} />;
  }

  return (
    <>
      {events.map((event: any) => {
        const signer = event.content.doc.signer.fio;
        const signerShortName = makeShortName(
          `${signer.last_name} ${signer.first_name} ${signer.middle_name}`
        );
        const date = moment
          .unix(event.created_at / 1000)
          .format('DD.MM.YYYY в HH:mm');
        const title = `${signerShortName} ${date} ${getNotificationCaption({
          documentType: documentInfo.type,
          documentStatus: documentInfo.status,
          item: event,
        })}.`;

        return (
          <Box key={event.created_at} mb={2}>
            <Notification disableClose>
              <div className={classes.wrapper}>
                <Box>
                  <span className={classes.bold}>{title}</span>
                </Box>
                <Box mt={2}>
                  <span className={classes.bold}>
                    {getCommentLabel({
                      eventType: event.type,
                      documentStatus: documentInfo.status,
                    })}
                    :
                  </span>{' '}
                  {event.content.doc.confirmInfo.text.str ??
                    event.content.doc.confirmInfo.text}
                </Box>

                {event.content?.result_BPM && (
                  <Box mt={2}>
                    <span className={classes.bold}>Обращение в СТП:</span>{' '}
                    {event.content.result_BPM.number}{' '}
                    {moment
                      .unix(event.content.result_BPM.created_on / 1000)
                      .format('DD.MM.YYYY в HH:mm')}
                  </Box>
                )}
              </div>
            </Notification>
          </Box>
        );
      })}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  events: getDocumentEvents(state),
  documentInfo: getDocumentInfo(state),
  isLoading: getLoading(state),
});

const mapDispatchToProps = {
  fetchEventsData,
};

export const EventsConnected: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(Events);
