import React, { useCallback, memo } from 'react';
import { Box } from '@crpt/material';
import { Button, Icon, Input } from '@crpt-ui/core';

interface EditGtinsProps {
  name: string;
  label: string;
  value: string;
  editGtins: (name: string) => void;
}

const EditGtins: React.FC<EditGtinsProps> = ({
  value,
  label,
  editGtins,
  name,
}) => {
  const onClick = useCallback(() => editGtins(name), [editGtins, name]);

  return (
    <Box display="flex" gap="16px">
      <Input disabled value={value} label={label} />
      <Button
        color="primary"
        icon={<Icon name="edit" />}
        onClick={onClick}
        size="md"
        variant="outlined"
      />
    </Box>
  );
};

export default memo(EditGtins);
