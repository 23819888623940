import React, { Fragment, useEffect, useMemo } from 'react';

import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { Typography } from '@crpt-ui/core';

import { withFormContext } from '../../../common_components/Form/Form.utils';
import { translate } from '../../Translate/Translate';

import { OperatorsConnected } from './Operators/Operators';
import { HeaderConnected } from './Header/Header';
import { CommonFormConnected } from './CommonForm/CommonForm';
import { OperatorsNotification } from './OperatorsNotification/OperatorsNotification';

const EdoRegistrationViewComponent = ({ isRegistered, formValues, form }) => {
  const isKppVisible = useMemo(() => {
    return get(formValues, 'inn.length', 0) === 10;
  }, [formValues]);

  useEffect(() => {
    if (!isKppVisible) {
      form.setValues({ kppElk: '' });
    }
  }, [form, isKppVisible]);

  if (isRegistered) {
    return (
      <Fragment>
        <HeaderConnected />

        <Box mt={3}>
          <OperatorsConnected />
        </Box>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <HeaderConnected />

      <Grid container alignItems="flex-start" spacing={4}>
        <Grid container item xs={6}>
          <Typography variant="h4">
            {translate('Бесплатно подключитесь к ЭДО lite')}
          </Typography>

          <CommonFormConnected />
        </Grid>

        <Grid container item xs={6}>
          <Typography variant="h4">
            {translate('Добавьте своих операторов ЭДО')}
          </Typography>

          <Box mb={2}>
            <OperatorsNotification />
          </Box>

          <OperatorsConnected />
        </Grid>
      </Grid>
    </Fragment>
  );
};

EdoRegistrationViewComponent.propTypes = {
  isRegistered: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
};

export const EdoRegistrationView = withFormContext({
  form: true,
  values: true,
})(EdoRegistrationViewComponent);
