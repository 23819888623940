import React, { Fragment, useMemo, useCallback } from 'react';

import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { Preloader } from '../Preloader/Preloader';
import { SignatureDescriptionConnected } from '../SignatureDescription/SignatureDescription';
import { Modal } from '../Modal/Modal';
import { getCurrentCertificate } from '../Cades/Cades.selectors';

import { useStyles } from './DocumentSigningModal.styled';
import {
  getOpenModalSignModal,
  isSigningInProcess,
} from './ducks/DocumentSigningModal.selectors';
import { closeSignModal } from './ducks/DocumentSigningModal.actions';

export const DocumentSigningModal = ({
  onSign,
  closeSignModal,
  certificate,
  isOpenModal,
  isSigningInProcess,
}) => {
  const classes = useStyles();

  const onCloseModal = useCallback(() => {
    closeSignModal();
  }, [closeSignModal]);

  const modalActions = useMemo(
    () => [
      {
        label: 'Подписать',
        callback: onSign,
        disabled: isSigningInProcess || isEmpty(certificate),
        loading: isSigningInProcess,
      },
      {
        label: 'Отменить',
        callback: onCloseModal,
        variant: 'outlined',
      },
    ],
    [certificate, onSign, onCloseModal, isSigningInProcess]
  );

  return (
    <Modal
      actions={modalActions}
      open={isOpenModal}
      title="Подписание документа"
      onClose={onCloseModal}
    >
      {isSigningInProcess ? (
        <Grid className={classes.loader}>
          <Preloader
            isOpen
            text={
              <Fragment>
                Пожалуйста, подождите,
                <br />
                идет подписание документа
              </Fragment>
            }
          />
        </Grid>
      ) : (
        <Fragment>
          <Box mx={-4} my={2}>
            <SignatureDescriptionConnected />
          </Box>
        </Fragment>
      )}
    </Modal>
  );
};

DocumentSigningModal.propTypes = {
  closeSignModal: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  isSigningInProcess: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isOpenModal: getOpenModalSignModal(state),
  isSigningInProcess: isSigningInProcess(state),
  certificate: getCurrentCertificate(state),
});

const mapDispatchToProps = {
  closeSignModal,
};

export const DocumentSigningModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentSigningModal);
