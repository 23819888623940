import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withFormValues } from '@crpt/shared/components/Form/FormSpy';
import { Grid } from '@crpt/material';
import { Typography } from '@crpt-ui/core';
import { Input, Select } from '@crpt/shared/components/Form/Fields';
import { FieldIds } from '../../CisUnloads.types';

import type { FormValues } from '../../CisUnloads.types';

interface CommonInformationProps {
  values: FormValues;
}

const CommonInformation: FC<CommonInformationProps> = ({ values }) => {
  const { t } = useTranslation();

  const typeOptions = useMemo(
    () => [
      {
        value: values[FieldIds.PERIODICITY],
        label: t('Periodicity.Single'),
      },
    ],
    [values]
  );

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="h6">
          {t('UnloadService.Task.CisUnloads.FieldGroup.commonInformation')}
        </Typography>
      </Grid>
      <Grid item xs>
        <Grid columnSpacing={2} container rowSpacing={2}>
          <Grid item xs={12}>
            <Input
              name={FieldIds.NAME}
              placeholder={t('UnloadService.Task.CisUnloads.Fields.name')}
              required
              clearable
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              name={FieldIds.PERIODICITY}
              options={typeOptions}
              placeholder={t('UnloadService.Task.CisUnloads.Fields.type')}
              required
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withFormValues(CommonInformation, {
  subscription: {
    values: true,
  },
});
