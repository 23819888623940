import React, { useMemo } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  AUTHORITY_OPTIONS,
  AUTHORITY_UKD_VALUES,
  STATUS_UKD_VALUES,
  STATUS_VALUES,
  UPD820_STATUS_VALUES,
} from '../SignClosingDocument.constants';
import {
  isShowAcceptanceContentCode,
  isUkdDocuments,
} from '../../ducks/ClosingDocument.selectors';

import { SignerAuthorityView } from './SignerAuthority.view';

const SignerAuthority = ({ isShowAcceptanceContentCode, isUkdDocuments }) => {
  const statusOptions = useMemo(() => {
    if (isShowAcceptanceContentCode) {
      return UPD820_STATUS_VALUES;
    }

    if (isUkdDocuments) {
      return STATUS_UKD_VALUES;
    }

    return STATUS_VALUES;
  }, [isShowAcceptanceContentCode, isUkdDocuments]);

  const authorityOptions = useMemo(
    () => (isUkdDocuments ? AUTHORITY_UKD_VALUES : AUTHORITY_OPTIONS),
    [isUkdDocuments]
  );

  return React.createElement(SignerAuthorityView, {
    authorityOptions,
    statusOptions,
  });
};

SignerAuthority.propTypes = {
  isShowAcceptanceContentCode: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isShowAcceptanceContentCode: isShowAcceptanceContentCode(state),
  isUkdDocuments: isUkdDocuments(state),
});

export const SignerAuthorityConnected =
  connect(mapStateToProps)(SignerAuthority);
