import React from 'react';

import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';

import { Input } from '../../../../common_components/Form/Input';
import { validatePhone } from '../../../../common_components/Form/utils/validations';
import { PhoneNumberInput } from '../../../../common_components/PhoneNumberInput/phone-number-input';

type ContactsForm = {
  disabled: boolean;
};

export const ContactsForm: React.FC<ContactsForm> = (props) => {
  const { t } = useTranslation();
  const { disabled } = props;

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h6">{t('Контактные данные')}</Typography>
      </Grid>
      <Grid item>
        <Input
          disabled
          fullWidth
          label={t('Юридический адрес')}
          name="legalAddress"
        />
      </Grid>
      <Grid item>
        <Input
          fullWidth
          disabled={disabled}
          label={t('Фактический адрес')}
          name="actualAddress"
        />
      </Grid>
      <Grid item>
        <Input
          fullWidth
          disabled={disabled}
          label={t('Контактное лицо')}
          name="physicalPerson.fio"
        />
      </Grid>
      <Grid item>
        <Input
          fullWidth
          disabled={disabled}
          label={t('Номер социальной карты')}
          name="physicalPerson.socialCardId"
        />
      </Grid>
      <Grid item>
        <Input fullWidth disabled={disabled} label={t('Email')} name="email" />
      </Grid>
      <Grid item>
        <Input
          fullWidth
          InputProps={{
            inputComponent: PhoneNumberInput,
          }}
          disabled={disabled}
          label={t('Телефон')}
          name="phone"
          validate={validatePhone}
        />
      </Grid>
    </Grid>
  );
};
