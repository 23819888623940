import { createSelector } from 'reselect';

export const getCertificatesState = (state) => state.COMMON.Cades;

export const getCertificates = createSelector(
  [getCertificatesState],
  (state) => state.certificates
);

export const getPreparedCertificates = createSelector(
  [getCertificatesState],
  (state) => state.preparedCertificates
);

export const getCurrentCertificate = createSelector(
  [getCertificatesState],
  (state) => state.currentCertificate
);

export const getCurrentCertificateValidTo = createSelector(
  [getCurrentCertificate],
  (state) => state.validTo
);
