import React from 'react';

import { Typography, Box } from '@material-ui/core';

import { StripeLayout } from '../../common_components/Layouts';

import SystemUpdatesIcon from './SystemUpdates.svg';

export const InformationPage = () => (
  <StripeLayout>
    <StripeLayout.Content>
      <Box textAlign="center">
        <SystemUpdatesIcon />
      </Box>
      <Box mt={3} textAlign="center">
        <Typography variant="h1">Обновление системы</Typography>
      </Box>
      <Box mt={2} textAlign="center">
        <Typography
          component="div"
          style={{
            fontWeight: 600,
          }}
          variant="body1"
        >
          Приносим извинения за&nbsp;неудобства.
          <br />
          Повторите попытку позднее.
        </Typography>
      </Box>
    </StripeLayout.Content>
  </StripeLayout>
);
