import React, { useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Link } from '@crpt-ui/core';

import {
  makeCell,
  makeDateCell,
  makeTextCell,
} from '../../../utils/Table.utils';
import * as preloaderSelectors from '../../../common_components/Preloader/ducks/Preloader.selectors';
import * as supportIncidentDialogActions from '../SupportIncidentDialog/ducks/SupportIncidentDialog.actions';

import {
  clearSupportList,
  fetchIncidents,
  setPage,
} from './ducks/SupportList.actions';
import {
  getSupportList,
  getSupportListPage,
  getSupportListTotalElements,
  getSupportListTotalPages,
} from './ducks/SupportList.selectors';
import {
  incidentStatusColorsMap,
  incidentStatusIconsMap,
  incidentStatusValuesMap,
} from './SupportList.constants';
import { SupportListView } from './SupportList.view';

const SupportList = ({
  fetchIncidents,
  onSupportIncidentDialogMount,
  setPage,
  clearSupportList,
  data,
  loading,
  page,
  pagesCount,
  totalElements,
}) => {
  useEffect(() => {
    fetchIncidents();

    return () => {
      clearSupportList();
    };
  }, [fetchIncidents, clearSupportList]);

  const columns = useMemo(
    () => [
      {
        accessor: 'createtime',
        Cell: makeDateCell({
          format: 'DD.MM.YYYY в HH:mm',
        }),
        Header: 'Дата и время',
        width: 160,
      },
      {
        accessor: 'refid',
        Cell: ({ cell, row }) => (
          <Link
            style={{ fontWeight: 600 }}
            onClick={() => onSupportIncidentDialogMount(row.original)}
          >
            {cell.value}
          </Link>
        ),
        Header: 'Номер обращения',
        width: 160,
      },
      {
        accessor: 'caption',
        Header: 'Тема',
        Cell: makeTextCell(),
      },
      {
        accessor: 'status',
        Header: 'Статус',
        width: 200,
        Cell: makeCell({
          colors: incidentStatusColorsMap,
          icons: incidentStatusIconsMap,
          values: incidentStatusValuesMap,
        }),
      },
    ],
    []
  );

  return React.createElement(SupportListView, {
    columns,
    data,
    loading,
    page,
    pagesCount,
    totalElements,
    setPage,
  });
};

SupportList.propTypes = {
  fetchIncidents: PropTypes.func.isRequired,
  onSupportIncidentDialogMount: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  data: getSupportList(state),
  loading: preloaderSelectors.loading(state),
  page: getSupportListPage(state),
  pagesCount: getSupportListTotalPages(state),
  totalElements: getSupportListTotalElements(state),
});

const mapDispatchToProps = {
  fetchIncidents,
  onSupportIncidentDialogMount: supportIncidentDialogActions.onMount,
  setPage,
  clearSupportList,
};

export const SupportListConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportList);
