import { combineActions, handleActions } from 'redux-actions';

import {
  clearAgreementDocuments,
  clearSortAgreementDocuments,
  setActivePageAgreementDocuments,
  setAgreementDocuments,
  setSortAgreementDocuments,
} from './AgreementDocumentsList.actions';

const defaultListState = { results: [] };

const data = handleActions(
  {
    [setAgreementDocuments]: (_, { payload }) => payload,
    [clearAgreementDocuments]: () => defaultListState,
  },
  defaultListState
);

const sort = handleActions(
  {
    [setSortAgreementDocuments]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [combineActions(clearAgreementDocuments, clearSortAgreementDocuments)]:
      () => ({}),
  },
  {}
);

const activePage = handleActions(
  {
    [setActivePageAgreementDocuments]: (_, { payload }) => payload,
    [clearAgreementDocuments]: () => 1,
  },
  1
);

export const reducer = {
  data,
  sort,
  activePage,
};
