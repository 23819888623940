import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  contentWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'inherit',
  },
  imageWrapper: {
    display: 'block',
    overflow: 'hidden',
    transform: 'translate3d(0, 0, 0)',
    textAlign: 'center',
    opacity: 1,
    position: 'relative',
    height: '32px',
    width: '32px',
  },
  textWrapper: {
    margin: '10px 0 0 0',
    color: '#434244',
    fontWeight: '200',
    fontSize: '14px',
  },
});
