import React from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import { LightTheme } from '@crpt-ui/core';

import type {
  FileQueueCollection,
  FileQueueCollectionMap,
} from './FileQueue.types';
import FileQueueManager from './FileQueueManager';
import { FileQueue } from './FileQueue';

interface FileQueueContainerStateType {
  collection: FileQueueCollectionMap;
}

export class FileQueueContainer extends React.Component {
  state: Readonly<FileQueueContainerStateType> = {
    collection: new Map(),
  };

  componentDidMount = () => {
    FileQueueManager.addChangeListener(this.handleStoreChange);
  };

  componentWillUnmount = () => {
    FileQueueManager.removeChangeListener(this.handleStoreChange);
  };

  handleStoreChange = (collection: FileQueueCollection) => {
    this.setState({
      collection,
    });
  };

  render() {
    const { collection } = this.state;

    const collectionArray = Array.from(collection, ([name, value]) => ({
      name,
      value,
    }));

    return (
      <MuiThemeProvider theme={LightTheme}>
        <FileQueue collection={collectionArray} />
      </MuiThemeProvider>
    );
  }
}
