import React from 'react';
import { Icon, Link, Tooltip } from '@crpt-ui/core';
import { RenderDocumentLink, RenderParticipantLink } from '@crpt/shared/components/Threads/Threads';
import { Box } from '@material-ui/core';
import { Link as LinkRouter } from 'react-router-dom';
import { get } from 'lodash';
import { NODE_DOC_TYPE, documentsReceipts, documentsWithoutLink } from './ThreadsScenario.constants';

export const renderParticipantLink: RenderParticipantLink = (inn = '') => {
  const link = `/participants/list/${inn}`;

  return (
    <Link
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      component={LinkRouter}
      to={link}
    >
      ИНН:&nbsp;{inn}
    </Link>
  )
}

export const renderDocumentLink: RenderDocumentLink = (uuid = '', type = '') => {
  const link = getDocumentLink(uuid, type);

  const docType = get(NODE_DOC_TYPE, [type, 'name']);
  const text = docType ? `${docType} ${uuid}` : uuid;

  return (
    <>
      <Box ml={-0.5} mr={0.5} component="span">
        <Icon name="Document" />
      </Box>
      <Tooltip placement="bottom" title={text}>
        <span>
          {link ? (
            <Link
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              component={LinkRouter}
              to={link}
            >
              {text}
            </Link>
          ) : (
            text
          )}
        </span>
      </Tooltip>
    </>
  );
};

const getDocumentLink = (uuid = '', type = '') => {
  switch (true) {
    case documentsWithoutLink.includes(type):
    case uuid === '-':
      return null
    case documentsReceipts.includes(type):
      return `/documents/receipts/${uuid}`;
    default:
      return `/documents/list/${uuid}`
  }
}
