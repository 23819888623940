import { chain, isObject, isString, isUndefined } from 'lodash';
import i18n from 'i18next';

export const required = (value) => {
  if (isObject(value)) {
    return value.from && value.to ? undefined : i18n.t('Обязательное поле');
  }
  return chain(value).trim().isEmpty().value()
    ? i18n.t('Обязательное поле')
    : undefined;
};

export const requiredWithError = (value) => {
  if (isObject(value)) {
    return value.from && value.to ? undefined : i18n.t('Обязательное поле');
  }
  return value ? undefined : i18n.t('Обязательное поле');
};

export const getOnlyNumsPhone = (value) =>
  value.replace('+374', '').replace(/[^\d]/g, '');
export const getOnlyNums = (value) =>
  value ? value.replace(/[^\d]/g, '') : undefined;

export const formatINN = (value) => (value ? value.slice(0, 8) : undefined);
export const formatLength = (max) => (value) =>
  value ? value.slice(0, max) : undefined;

export const formatSocialCard = (value) =>
  value ? value.replace(/[^\d]/g, '').slice(0, 10) : undefined;

export const formatPhone = (value) => {
  if (!value) {
    return value;
  }
  const prefix = '+374';
  const onlyNums = getOnlyNumsPhone(value);
  if (onlyNums.length === 0) {
    return onlyNums;
  }
  if (onlyNums.length <= 2) {
    return `${prefix} (${onlyNums}`;
  }
  if (onlyNums.length <= 4) {
    return `${prefix} (${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}`;
  }
  if (onlyNums.length <= 6) {
    return `${prefix} (${onlyNums.slice(0, 2)}) ${onlyNums.slice(
      2,
      4
    )}-${onlyNums.slice(4, 6)}`;
  }
  return `${prefix} (${onlyNums.slice(0, 2)}) ${onlyNums.slice(
    2,
    4
  )}-${onlyNums.slice(4, 6)}-${onlyNums.slice(6, 8)}`;
};

const isAMPhone = (data) =>
  /^((\+374)[-]?)?(\(?\d{2}\)?[-]?)?[\d-]{8,8}$/.test(data);

export const validateAmPhone = (v) => {
  if (!v) {
    return i18n.t('Обязательное поле');
  }
  const onlyNum = v ? `+374${v.replace(/[^\d]/g, '').slice(3, 11)}` : v;
  if (onlyNum?.length < 12) {
    return i18n.t('Некорректный номер');
  }
  return isAMPhone(onlyNum) ? undefined : i18n.t('Некорректный номер');
};

export const isValidInn = (value) => {
  if (!value) {
    return i18n.t('Обязательное поле');
  }
  if (!/^\d+$/.test(value)) {
    return i18n.t('Допустимы только цифры');
  }
  return value.length < 8 ? i18n.t('Некорректный ИНН') : undefined;
};

export const isValidInnForFinalForm = (value) => {
  if (!value) {
    return i18n.t('Обязательное поле');
  }
  if (!/^\d+$/.test(value)) {
    return i18n.t('Допустимы только цифры');
  }
  return value.length < 8 ? i18n.t('Некорректный ИНН') : undefined;
};

export const certRequired = (value) => {
  if (isObject(value)) {
    return value.valid ? undefined : 'Error';
  }
  return value ? undefined : 'Error';
};

export const validateIsNumber = (value) => {
  return !/\D/.test(value) ? undefined : 'Error';
};

export const isPhone = (value) =>
  value && value.match(/^\+\d \(\d{3}\) \d{3}-\d{2}-\d{2}$/)
    ? undefined
    : 'Error';

export const isInn = (value) =>
  value && value.match(/^(\d{10}|\d{12})$/) ? undefined : 'Error';

export const isIFNS = (value) =>
  value && value.match(/^\d{4}$/) ? undefined : 'Error';

export const isKPP = (value) =>
  value && value.match(/^\d{9}$/) ? undefined : 'Error';

export const isOGRN = (value) =>
  value && value.match(/^(\d{13}|\d{15})$/) ? undefined : 'Error';

export const isPostIndex = (value) =>
  value && value.match(/^\d{6}$/) ? undefined : 'Error';

export const isRegionCode = (value) =>
  value && value.match(/^\d{2}$/) ? undefined : 'Error';

export const isEDOParticipantID = (value) =>
  value && value.match(/^[A-z\d-]{4,46}$/) ? undefined : 'Error';

export const isGCP = (value) =>
  value && isString(value) && value.match(/^(\d{7}|\d{9})$/)
    ? undefined
    : 'Error';

export const isGLN = (value) =>
  value && isString(value) && value.match(/^\d{13}$/) ? undefined : 'Error';

export const isBIC = (value) =>
  value && value.match(/^\d{6}$/) && value[0] !== 0 ? undefined : 'Error';

export const isCorrAccount = (value) =>
  value && value.match(/^\d{20}$/) ? undefined : 'Error';

export const isSettAccount = (value) =>
  value && value.match(/^\d{16}$/) ? undefined : 'Error';

export const validateArray = (vals) => {
  if (!(vals && vals.length)) {
    return ['Error'];
  }
  return undefined;
};

export const validateUIT = (value) =>
  value && /^[A-z\d]{38}$/.test(value) ? undefined : 'Error';

export const validateEmail = (value) => {
  /* prettier-ignore */
  // eslint-disable-next-line no-useless-escape
  const re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  return re.test(value) ? undefined : i18n.t('Обязательное поле');
};

export const validateSocialCardNumber = (value) => {
  return value && value.match(/^(\d{10})$/)
    ? undefined
    : i18n.t('Некорректный номер социальной карты');
};

export const validateMaxLength =
  (maxLength) =>
  (value = '') =>
    value.length > 0 && value.length < maxLength
      ? undefined
      : i18n.t('Обязательное поле');

/**
 * The check is performed if the string contains only Cyrillic characters
 *
 * @example
 *    Correct value:
 *    'а' => undefined
 *    'бвгд' => undefined
 *    'Ы' => undefined
 *    'Через-дефис' => undefined
 *    'Через пробел' => undefined
 *
 *    Incorrect value:
 *    'J' => 'Error'
 *    'слоwо' => 'Error'
 *    'd' => 'Error'
 *
 * @param {string} value
 * @returns {undefined|'Error'}
 */
export const checkCyrillic = (value) => {
  if (value) {
    return /^[-а-яА-ЯёЁ0-9\s]+$/g.test(value) ? undefined : 'Error';
  }
};

/**
 * Validation of amount value
 *
 * @example
 *    Correct value:
 *    123.12 => undefined
 *    123.1 => undefined
 *    123 => undefined
 *
 *    Incorrect value:
 *    'qwe' => 'Error'
 *    123. => 'Error'
 *    123.123 => 'Error'
 *    123,12 => 'Error'
 *    0 => 'Error'
 *    01 => 'Error'
 *    -123 => 'Error'
 *
 * @param {string} value
 * @returns {undefined|'Error'}
 */
export const validateAmount = (value) => {
  const re = /^(\d+[.]\d{1,2}|[^0]\d*|\D)$/;
  return re.test(value) && Number(value) > 0 ? undefined : 'Error';
};

/**
 * Validation of phone
 * the value must be greater than 10 characters
 *
 * @param {string} value
 * @returns {undefined|'Error'}
 */
export const validatePhone = (value) => {
  return (value && value.length >= 9) || isUndefined(value)
    ? undefined
    : 'Error';
};

/**
 * Validate participant edo id.
 *
 * Independence to register;
 * No spaces;
 * Field length 1-46 characters;
 * Valid characters: 1-0, A-Z, - (dash), . (dot).
 *
 * @param {string} value
 * @returns {undefined|'Error'}
 */
export const validateParticipantEdoId = (value) => {
  const re = /^(?=^.{1,46}$)([A-z0-9-.])+$/;
  return re.test(value) ? undefined : 'Error';
};
