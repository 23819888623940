import React, { FC, forwardRef } from 'react';

import cn from 'classnames';
import { ru, enUS, uz, hy } from 'date-fns/locale';
import {
  default as ReactDatePicker,
  type ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  TextField,
  InputAdornment,
  Box,
  type TextFieldProps,
} from '@mui/material';

import { Icon } from '../icons';
import { PartialKeys } from '../../utils/types.utils';
import { constVoid } from '../../utils/helpers';

import css from './datepicker.module.scss';

registerLocale('hy', hy);
registerLocale('ru', ru);
registerLocale('en', enUS);
registerLocale('kz', enUS);
registerLocale('uz', uz);

export type Language = 'en' | 'ru' | 'uz' | 'hy' | 'kz';

export type DatePickerProps = PartialKeys<ReactDatePickerProps, 'onChange'> & {
  helperText?: string;
  label?: string;
  error?: boolean;
  locale?: Language;
  fullWidth?: boolean;
};

type DateInputProps = TextFieldProps & { readOnly?: boolean };

const DateInput = forwardRef<HTMLDivElement, DateInputProps>((props, ref) => (
  <TextField
    {...props}
    InputProps={{
      autoComplete: 'off',
      readOnly: props.readOnly,
      inputProps: {
        onChange: props.onChange,
      },
      endAdornment: (
        <InputAdornment position="end">
          <Icon name="Calendar" />
        </InputAdornment>
      ),
    }}
    className={cn(css['custom-input'])}
    ref={ref}
  />
));

export const DatePicker: FC<DatePickerProps> = (props) => {
  const {
    helperText,
    placeholderText,
    label,
    title,
    error,
    ariaInvalid,
    dateFormat = 'dd.MM.yyyy',
    showMonthDropdown = true,
    showYearDropdown = true,
    scrollableYearDropdown = true,
    showPopperArrow = false,
    locale = 'ru' as Language,
    fullWidth = false,
    className = '',
    required = false,
    disabled = false,
    onChange = constVoid,
    ...reactDatePickerProps
  } = props;

  return (
    <Box className={cn(css.DatePickerRoot, className)} data-testid="DatePicker">
      <ReactDatePicker
        {...reactDatePickerProps}
        ariaInvalid={error ? 'true' : ariaInvalid}
        customInput={
          <DateInput
            data-testid="DatePickerInput"
            disabled={disabled}
            error={error}
            fullWidth={fullWidth}
            helperText={helperText}
            label={label}
            required={required}
          />
        }
        dateFormat={dateFormat}
        disabled={disabled}
        locale={locale}
        placeholderText={placeholderText}
        popperPlacement={fullWidth ? 'bottom-end' : undefined}
        required={required}
        scrollableYearDropdown={scrollableYearDropdown}
        showMonthDropdown={showMonthDropdown}
        showPopperArrow={showPopperArrow}
        showYearDropdown={showYearDropdown}
        title={label || title}
        onChange={onChange}
      />
    </Box>
  );
};
