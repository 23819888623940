import React from 'react';
import { Icon } from '@crpt-ui/core';
import { Box, Grid } from '@material-ui/core';

import ActionButton from '../ActionButton';
import type { ButtonProps } from '@crpt-ui/core';

export type ActionsProps = {
  onDownload: ButtonProps['onClick'];
  onPrint: ButtonProps['onClick'];
  onClose?: ButtonProps['onClick'];
};

const Actions: React.FC<ActionsProps> = ({ onDownload, onPrint, onClose }) => {
  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid item>
        <ActionButton
          icon={<Icon name="Download" />}
          onClick={onDownload}
          title="Скачать"
          variant="text"
        />
      </Grid>
      <Grid item>
        <ActionButton
          icon={<Icon name="Print" />}
          onClick={onPrint}
          title="Печать"
          variant="text"
        />
      </Grid>
      {onClose && (
        <Grid item>
          <Box ml={2}>
            <ActionButton
              icon={<Icon name="Close" />}
              onClick={onClose}
              title="Закрыть"
              variant="text"
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default Actions;
