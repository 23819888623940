import React, { Fragment } from 'react';

import { Box, Grid } from '@material-ui/core';

import Datatable from '@crpt-ui/datatable';
import { Pagination, Typography } from '@crpt-ui/core';
import { NoInfo } from '@crpt/shared/components';

import { PageActions } from '../../../common_components/PageActions/PageActions';
import { PreloaderWrapper } from '../../../common_components/PreloaderWrapper/PreloaderWrapper';

import { UploadNotificationOperatorDialogConnected } from './UploadNotificationOperatorDialog/UploadNotificationOperatorDialog';
import { DocumentTemplatesDialogConnected } from './DocumentTemplatesDialog/DocumentTemplatesDialog';
import { columns } from './NotificationOperatorDocumentsList.constants';

export const NotificationOperatorDocumentsListView = ({
  data,
  pagination,
  mainActions,
  additionalActions,
  manualSortBy,
  onSortDatatable,
  onFiltersChange,
  clearFilter,
  loading,
  noData,
}) => (
  <Fragment>
    <UploadNotificationOperatorDialogConnected />
    <DocumentTemplatesDialogConnected />

    <Box mb={2} mx={4}>
      <PageActions
        additionalActions={additionalActions}
        mainActions={mainActions}
      />
    </Box>

    <PreloaderWrapper isLoading={loading}>
      {noData ? (
        <NoInfo>
          <Typography variant="h5">У&nbsp;вас еще нет документов</Typography>
        </NoInfo>
      ) : (
        <Datatable
          useFilters
          useSort
          columns={columns}
          data={data.items}
          manualSortBy={manualSortBy}
          onFiltersChange={onFiltersChange}
          onFiltersReset={clearFilter}
          onSortChange={onSortDatatable}
        />
      )}
    </PreloaderWrapper>

    <Box mt={2} mx={4}>
      <Grid container alignItems="center" justify="space-between">
        {pagination.isShowPagination && (
          <Pagination
            page={pagination.page}
            pagesCount={pagination.pagesCount}
            onPageChange={pagination.onPageChange}
          />
        )}
      </Grid>
    </Box>
  </Fragment>
);
