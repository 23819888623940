import React, { useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import * as preloaderSelectors from '../../../common_components/Preloader/ducks/Preloader.selectors';
import {
  withForm,
  withFormContext,
} from '../../../common_components/Form/Form.utils';
import {
  getRegistered,
  getSoleProprietorship,
  onlyProductGroupIsFurs,
} from '../Company/ducks/Company.selectors';

import { fetchRequisites, setEditing } from './ducks/Requisites.actions';
import {
  getRequisitesData,
  getRequisitesEditing,
} from './ducks/Requisites.selectors';
import { RequisitesView } from './Requisites.view';

const RequisitesComponent = ({
  fetchRequisites,
  setEditing,
  onlyProductGroupIsFurs,
  isSoleProprietorship,
  isLoading,
  isRegistered,
  isEditing,
}) => {
  useEffect(() => {
    fetchRequisites();

    return () => {
      setEditing(false);
    };
  }, [fetchRequisites, setEditing]);

  /**
   * Поля обязательны если УОТ Юр. лицо и у него нет единственной товарной группы "Товары из натурального меха"
   * @type {boolean}
   */
  const isRequisitesRequired = useMemo(() => {
    if (isSoleProprietorship) {
      return false;
    } else if (onlyProductGroupIsFurs) {
      return false;
    }

    return true;
  }, [isSoleProprietorship, onlyProductGroupIsFurs]);

  /**
   * Requests information about the bank when filling in the "BIC" field
   */
  // useEffect(() => {
  //   if (!isBIC(formValues.bic)) {
  //     fetchBankInfo(formValues.bic);
  //   }
  // }, [formValues.bic, fetchBankInfo]);

  return React.createElement(RequisitesView, {
    isRegistered,
    isLoading,
    isRequisitesRequired,
    isEditing,
  });
};

const mapStateToProps = (state) => ({
  data: getRequisitesData(state),
  isLoading: preloaderSelectors.loading(state),
  isRegistered: getRegistered(state),
  isSoleProprietorship: getSoleProprietorship(state),
  isEditing: getRequisitesEditing(state),
  onlyProductGroupIsFurs: onlyProductGroupIsFurs(state),
});

const mapDispatchToProps = {
  fetchRequisites,
  // fetchBankInfo,
  setEditing,
};

RequisitesComponent.propTypes = {
  data: PropTypes.shape({}).isRequired,
  formValues: PropTypes.shape({}).isRequired,
  fetchRequisites: PropTypes.func.isRequired,
  // fetchBankInfo: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isSoleProprietorship: PropTypes.bool.isRequired,
};

export const Requisites = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withForm(),
  withFormContext({ values: true })
)(RequisitesComponent);
