import React, { useCallback, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { chain, isEqual } from 'lodash';

import { Preloader } from '../../common_components/Preloader/Preloader';
import {
  withForm,
  withFormContext,
} from '../../common_components/Form/Form.utils';

import { documentTypes } from './Settings.constants';
import {
  fetchDocsForNotification,
  sendDocsForNotification,
} from './ducks/Settings.actions';
import {
  getInitialValues,
  getLoading,
  getSubmitting,
} from './ducks/Settings.selectors';
import { SettingsView } from './Settings.view';

type SettingsProps = {
  formValues: Record<string, any>;
};

const Settings = ({ formValues }: SettingsProps) => {
  const dispatch = useDispatch();

  const data = useSelector((state) => getInitialValues(state));
  const submitting = useSelector((state) => getSubmitting(state));
  const loading = useSelector((state) => getLoading(state));

  useEffect(() => {
    dispatch(fetchDocsForNotification());
  }, []);

  const onHandleSubmit = useCallback(() => {
    dispatch(sendDocsForNotification(formValues));
  }, [dispatch, formValues]);

  const selectedValues: Record<string, any> = useMemo(() => {
    return chain(formValues).pickBy().value();
  }, [formValues]);

  const isCannotBeSaved: boolean = useMemo(() => {
    const selectedDocumentTypes = documentTypes.some(
      (item) => selectedValues[item.name]
    );
    const selectedReceiptTypes = [
      'RECEIPT_GET',
      'RECEIPT_UPDATE_SUCCESS',
      'RECEIPT_UPDATE_FAILED',
    ].some((item) => selectedValues[item]);

    return (
      selectedValues.BY_EMAIL &&
      !(selectedDocumentTypes && selectedReceiptTypes)
    );
  }, [selectedValues]);

  const isDisabledButton: boolean = useMemo(() => {
    return isEqual(selectedValues, data) || isCannotBeSaved;
  }, [data, selectedValues, isCannotBeSaved]);

  const isDisableFields: boolean = useMemo(
    () => !formValues.BY_EMAIL,
    [formValues]
  );

  if (loading) {
    return <Preloader isOpen />;
  }

  return React.createElement(SettingsView, {
    onHandleSubmit,
    submitting,
    isDisabledButton,
    isDisableFields,
    isCannotBeSaved,
  });
};

export const SettingsConnected = compose(
  withForm(),
  withFormContext({ values: true, errors: true })
)(Settings);
