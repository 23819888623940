import React, { Fragment } from 'react';

import { Box } from '@material-ui/core';

import PDFViewer from '@crpt/shared/components/PDFViewer/PDFViewer';

import { PreloaderWrapper } from '../../../common_components/PreloaderWrapper/PreloaderWrapper';

import { TopPanel } from './TopPanel';

export const NotificationOperatorDocumentView = ({
  documentTitle,
  additionalActions,
  documentInfo,
  content,
  loading,
}) => (
  <Fragment>
    <Box pb={2}>
      <TopPanel
        additionalActions={additionalActions}
        documentInfo={documentInfo}
        loading={loading}
      />
    </Box>

    <PreloaderWrapper isLoading={loading}>
      <PDFViewer pdfData={content} title={documentTitle} />
    </PreloaderWrapper>
  </Fragment>
);
