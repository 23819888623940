import lottie from 'lottie-web';
import React, { FC, useEffect, useRef } from 'react';
import { animationData } from './preloaderAnimationData';
import { useStyles } from './Preloader.styles';
import { Typography } from '@crpt-ui/core';

interface PreloaderProps {
  isOpen: boolean;
  text: string;
}

export const PreloaderView: FC<PreloaderProps> = ({ isOpen, text }) => {
  const styles = useStyles();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      });
    }
  }, [isOpen, ref]);

  if (!isOpen) {return null;}

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.imageWrapper} ref={ref} />
      <Typography className={styles.text}>{text}</Typography>
    </div>
  );
};
