import { createActions } from 'redux-actions';

/**
 * Accounts actions
 */
export const {
  setLoading,
  sendNotificationMinBalance,
  openNotificationBalanceModal,
  setOpenNotificationBalanceModal,
  setSelectedAccount,
  setNotificationRecipientsIds,
  setBalanceMinimum,
  closeModalNotification,
} = createActions(
  {
    SET_LOADING: (payload) => payload,

    SEND_NOTIFICATION_MIN_BALANCE: (payload) => payload,

    CLOSE_MODAL_NOTIFICATION: () => {},
    OPEN_NOTIFICATION_BALANCE_MODAL: (payload) => payload,
    SET_OPEN_NOTIFICATION_BALANCE_MODAL: (payload) => payload,

    SET_SELECTED_ACCOUNT: (payload) => payload,
    SET_BALANCE_MINIMUM: (payload) => payload,
    SET_NOTIFICATION_RECIPIENTS_IDS: (payload) => payload,
  },
  {
    prefix: 'NOTIFICATION_BALANCE',
  }
);
