import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@crpt/material';
import { Button, Icon, Typography } from '@crpt-ui/core';
import { withFormValues } from '@crpt/shared/components/Form/FormSpy';
import { Input, Select } from '@crpt/shared/components/Form/Fields';
import { FieldIds, TypeOfMovement } from '../AcsCis.types';

import type { FormErrors, FormValues } from '../AcsCis.types';

interface UnloadFilterProps {
  errors: FormErrors;
  values: FormValues;
  isOperator: boolean;
  handleEditInnsOpen: (value: string, isSenderInn: boolean) => () => void;
}

const UnloadFilter: FC<UnloadFilterProps> = ({
  errors,
  isOperator,
  handleEditInnsOpen,
  values,
}) => {
  const { t } = useTranslation();

  const typeOfMovementOptions = useMemo(() => {
    return Object.values(TypeOfMovement).map((option) => ({
      value: option,
      label: t(`UnloadService.Task.AcsCis.TypeOfMovement.${option}`),
    }));
  }, []);

  const isSenderInn = useMemo(
    () => values[FieldIds.TYPE_OF_MOVEMENT] === TypeOfMovement.SENT,
    [values]
  );

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="h6">
          {t('UnloadService.Task.AcsCis.FieldGroup.unloadFilter')}
        </Typography>
      </Grid>
      <Grid item xs>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Select
              name={FieldIds.TYPE_OF_MOVEMENT}
              options={typeOfMovementOptions}
              placeholder={t('UnloadService.Task.AcsCis.Fields.typeOfMovement')}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Grid columnSpacing={2} container>
              {!!values[FieldIds.TYPE_OF_MOVEMENT] && (
                <Grid item xs>
                <Input
                  name={FieldIds.INN}
                  placeholder={t(
                    isSenderInn
                      ? 'UnloadService.Task.AcsCis.Fields.receiverInn'
                      : 'UnloadService.Task.AcsCis.Fields.senderInn'
                  )}
                  required={isOperator}
                />
              </Grid>
              )}
              {!!values[FieldIds.INN] && !errors[FieldIds.INN] && (
                <Grid item>
                  <Button
                    color="secondary"
                    onClick={handleEditInnsOpen(FieldIds.INN, isSenderInn)}
                    startIcon={<Icon name="document" />}
                    style={{
                      fontSize: 16,
                      textTransform: 'none',
                      fontWeight: 500,
                    }}
                    variant="text"
                  >
                    {t('UnloadService.Task.AcsDocuments.AllList')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withFormValues(UnloadFilter, {
  subscription: {
    errors: true,
    values: true,
  },
});
