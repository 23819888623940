import React, { useCallback, useMemo } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { documentStatuses } from '../../../../constants';
import { withForm } from '../../../../common_components/hocs/withForm';
import { ModalActionsType } from '../../../../common_components/Modal/Modal';

import { closeModal, submit } from './ducks/EventModal.actions';
import {
  getData,
  getLoading,
  getOpenModal,
} from './ducks/EventModal.selectors';
import { CancellationView } from './EventModal.view';
import { schema } from './EventModal.schema';

interface CancellationProps {
  form: any;
  data: {
    status: number;
  };
  values: {
    comment: string;
  };
  closeModal: () => void;
  openModal: () => void;
  submit: ({ comment }: { comment: string }) => void;
  isOpenModal: boolean;
  isLoading: boolean;
  hasValidationErrors: boolean;
}

const EventModal: React.FC<CancellationProps> = ({
  form,
  data,
  values,
  submit,
  isOpenModal,
  isLoading,
  hasValidationErrors,
  closeModal,
}) => {
  const submitForm = useCallback(() => {
    submit(values);
  }, [values, submit]);

  const onClose = useCallback(() => {
    closeModal();
    form.reset();
  }, [closeModal, form]);

  const modalActions: ModalActionsType = useMemo(
    () => [
      {
        label: 'Отправить',
        callback: submitForm,
        loading: isLoading,
        disabled: hasValidationErrors || isLoading,
      },
      {
        label: 'Отменить',
        callback: onClose,
        variant: 'outlined',
      },
    ],
    [submitForm, isLoading, hasValidationErrors, onClose]
  );

  const title = useMemo(() => {
    const enumTitles: Record<string, string> = {
      [documentStatuses.clarificationRequired]: 'Запрос на уточнение',
      [documentStatuses.revocationExpected]:
        'Запрос на аннулирование документа',
      [documentStatuses.revocateDenied]: 'Отказ в аннулировании документа',
      [documentStatuses.rejected]: 'Подписание с расхождениями',
    };

    return enumTitles[data.status];
  }, [data.status]);

  const label = useMemo(() => {
    const enumLabels: Record<string, string> = {
      [documentStatuses.rejected]: 'Укажите расхождения',
      [documentStatuses.revocationExpected]:
        'Укажите причину аннулирования документа',
      [documentStatuses.revocateDenied]:
        'Укажите причину отказа в аннулировании документа',
      [documentStatuses.clarificationRequired]:
        'Укажите причину уточнения документа',
    };

    return enumLabels[data.status] ?? 'Комментарий';
  }, [data.status]);

  return React.createElement(CancellationView, {
    title,
    label,
    onClose,
    modalActions,
    isOpenModal,
    hasValidationErrors,
  });
};

const mapDispatchToProps = {
  submit,
  closeModal,
};

const mapStateToProps = (state: any) => ({
  isOpenModal: getOpenModal(state),
  isLoading: getLoading(state),
  data: getData(state),
});

export const EventModalConnected: any = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withForm({
    schema,
  })
)(EventModal);
