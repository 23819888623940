import { combineActions, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';

import { clearEventsData, setEventsData, setLoading } from './Event.actions';

const events = handleActions(
  {
    [setEventsData.toString()]: (_state, { payload }) => payload,
    [combineActions(LOCATION_CHANGE, clearEventsData.toString()).toString()]:
      () => [],
  },
  []
);

const loading = handleActions(
  {
    [setLoading.toString()]: (_, { payload }) => payload,
    [clearEventsData.toString()]: () => true,
  },
  false
);

export const reducer = {
  events,
  loading,
};
