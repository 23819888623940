import React from 'react';

import { Form } from 'react-final-form';

import { FormContext } from './Form.constants';

export const FormWrapper = ({ form, children }) => (
  <Form form={form}>
    {({ handleSubmit, values, errors }) => (
      <form onSubmit={handleSubmit}>
        <FormContext.Provider
          value={{
            values,
            errors,
            mutators: form.mutators,
            reset: form.reset,
            formChange: form.change,
            form,
          }}
        >
          {children}
        </FormContext.Provider>
      </form>
    )}
  </Form>
);
