import styled from 'styled-components';

export const HeaderActionsWrap = styled.div`
  padding: 20px;
`;

export const BasicInfoWrap = styled.div`
  padding: 0 20px 20px;
`;

export const ActionWrap = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CreateWrapWrap = styled.div`
  padding: 20px;
  min-height: calc(100vh - 230px);
`;

export const CreateActionsWrapWrap = styled.div`
  padding: 20px;
  justify-content: flex-end;
  display: flex;
  '&button': {
    margin-left: 10px;
  }
`;

export const ButtonWrap = styled.div`
  margin-right: 10px;
`;
