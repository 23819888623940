import { createSelector } from 'reselect';

export const getPaymentStatusState = (state) => state.COMMON.PaymentStatus;

export const getPaymentStatusData = createSelector(
  [getPaymentStatusState],
  (paymentStatusState) => paymentStatusState.data
);

export const getLoading = createSelector(
  [getPaymentStatusState],
  (paymentStatusState) => paymentStatusState.loading
);
