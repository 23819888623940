import React, { FC } from 'react';
import { Typography } from '@crpt-ui/core';
import { ReactComponent as NoDataIcon } from '../../svg/NoData.svg';
import { useStyles } from './NoInfo.styles';
import clsx from 'clsx';
import { Box, BoxProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export interface NoInfoProps extends BoxProps {
  className?: string;
  icon?: React.ReactNode;
}

export const NoInfo: FC<NoInfoProps> = ({
  className,
  icon,
  children,
  minHeight = 640,
  ...boxProps
}) => {
  const styles = useStyles();

  const { t } = useTranslation();

  return (
    <Box minHeight={minHeight} {...boxProps} className={styles.noInfoWrapper}>
      <div className={clsx(styles.contentWrapper, className)}>
        <div>{icon ?? <NoDataIcon />}</div>
        {children ?? (
          <Typography variant="h4">
            {t('NoInfo.default', 'Нет данных для отображения')}
          </Typography>
        )}
      </div>
    </Box>
  );
};
