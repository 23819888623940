import React, { Fragment } from 'react';

import { Box, Typography } from '@mui/material';

//TODO: Добавить такие иконки в ibox-ui
// eslint-disable-next-line no-restricted-imports
import { Icon } from '@crpt-ui/core';

import { RegistrationStatusEnum } from '../../constants/index';

type RegistrationStatusProps = {
  registrationStatus: string;
  registrationDeclineReason?: any;
};

export const RegistrationStatus = ({
  registrationStatus,
  registrationDeclineReason,
}: RegistrationStatusProps) => {
  const statusMap = {
    [RegistrationStatusEnum.IN_PROGRESS]: {
      icon: 'Pending',
      label: 'на рассмотрении',
      color: '#ffa800',
    },
    [RegistrationStatusEnum.CHECKED_OK]: {
      icon: 'Success',
      label: 'подтверждена',
      color: '#2fa93b',
    },
    [RegistrationStatusEnum.REGISTERED]: {
      icon: 'Success',
      label: 'подтверждена',
      color: '#2fa93b',
    },
    [RegistrationStatusEnum.CHECKED_NOT_OK]: {
      icon: 'Decline',
      label: 'отклонена',
      color: '#ff4b4b',
      reason: registrationDeclineReason,
    },
    [RegistrationStatusEnum.PROCESSING_ERROR]: {
      icon: 'Decline',
      label: 'отклонена',
      color: '#ff4b4b',
      reason: registrationDeclineReason,
    },
  };

  const data = statusMap[registrationStatus];

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      <Box color={data.color} component="span" marginRight={1}>
        <Icon name={data.icon} />
      </Box>
      <Typography component="span" style={{ marginTop: 0 }}>
        Статус вашей заявки:&nbsp;
        <Box color={data.color} component="span" fontWeight={600}>
          {data.label}
        </Box>
        {data.reason ? <Box component="span">&nbsp;({data.reason})</Box> : null}
      </Typography>
    </Fragment>
  );
};
