import Version from './Version';
import React from 'react';

type VersionsParams = {
  versions: number[];
  onVersionClick: (timestamp: number) => void;
  selectedVersion?: number;
};

export const generateVersionsList = ({ versions, selectedVersion, onVersionClick }: VersionsParams) => {
  const sortedDescendingVersions = [...versions].sort((a, b) => b - a);

  return sortedDescendingVersions.map((timestamp, index) => {
    const checkIsVersionSelected = () => {
      if ((!selectedVersion && index === 0) || (selectedVersion === timestamp)) {
        return true;
      }
      return false;
    };

    return (
      <Version
        key={timestamp}
        isLastVersion={index === 0}
        isSelected={checkIsVersionSelected()}
        version={timestamp}
        onClick={() => onVersionClick(timestamp)}
      />
    );
  });
};
