import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';

import { setSigningProcess } from './SignClosingDocument.actions';

const isSigningInProcess = handleActions(
  {
    [setSigningProcess]: (_, { payload }) => payload,
    [LOCATION_CHANGE]: () => false,
  },
  false
);

export const reducer = {
  isSigningInProcess,
};
