import React, { Fragment, useMemo } from 'react';

import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { MenuButton, Tooltip } from '@crpt-ui/core';
import { Button } from '@ibox/ui';

import { Separator } from '../Separator/Separator';

import { AdditionalActionsType, MainActionsType } from './PageActionsTypes';
import { useStyles } from './PageActions.styled';

export const prepareList = (list) =>
  list.filter((item) => {
    if (item.isShow === undefined) {
      return true;
    }

    return item.isShow;
  });

export const PageActions = ({
  mainActions,
  additionalActions,
  useSeparator,
  isParticipantForm,
}) => {
  const classes = useStyles();

  const preparedMainActions = useMemo(
    () => prepareList(mainActions),
    [mainActions]
  );

  const preparedAdditionalActions = useMemo(
    () => prepareList(additionalActions),
    [additionalActions]
  );

  if (
    preparedMainActions.length === 0 &&
    preparedAdditionalActions.length === 0
  ) {
    return null;
  }

  return (
    <Fragment>
      <Box mb={useSeparator ? 2 : 0}>
        <Grid
          container
          spacing={2}
          style={isParticipantForm ? { justifyContent: 'flex-end' } : {}}
        >
          {preparedMainActions.map((mainAction) => (
            <Grid item className={mainAction.classes} key={mainAction.label}>
              {mainAction.Component ? (
                mainAction.Component
              ) : (
                <Tooltip
                  arrow
                  disableFocusListener={
                    mainAction.tooltip ? mainAction.tooltip.isHidden : true
                  }
                  disableHoverListener={
                    mainAction.tooltip ? mainAction.tooltip.isHidden : true
                  }
                  placement="right"
                  title={mainAction.tooltip ? mainAction.tooltip.title : null}
                  {...mainAction.tooltip}
                >
                  <div>
                    {mainAction.actionsList ? (
                      <MenuButton
                        className={
                          !['outlined', 'text'].includes(mainAction.variant)
                            ? 'whiteColor'
                            : ''
                        }
                        disabled={mainAction.disabled}
                        icon={mainAction.icon}
                        items={prepareList(mainAction.actionsList)}
                        variant={mainAction.variant}
                      >
                        {mainAction.label}
                      </MenuButton>
                    ) : (
                      <Button
                        className={
                          !['outlined', 'text'].includes(mainAction.variant)
                            ? 'whiteColor'
                            : ''
                        }
                        icon={mainAction.icon}
                        variant={mainAction.variant}
                        onClick={mainAction.onClick}
                        {...mainAction}
                      >
                        {mainAction.label}
                      </Button>
                    )}
                  </div>
                </Tooltip>
              )}
            </Grid>
          ))}

          {!isParticipantForm && (
            <Grid
              item
              style={{
                marginLeft: 'auto',
              }}
            >
              <Grid container spacing={2}>
                {preparedAdditionalActions.map((additionalAction) => (
                  <Grid item key={additionalAction.label}>
                    {additionalAction.actionsList ? (
                      <MenuButton
                        icon={additionalAction.icon}
                        items={prepareList(additionalAction.actionsList)}
                        variant="outlined"
                      >
                        {additionalAction.label}
                      </MenuButton>
                    ) : (
                      <Button
                        className={classes.link}
                        disabled={additionalAction.disabled}
                        icon={additionalAction.icon}
                        variant="text"
                        onClick={additionalAction.onClick}
                      >
                        {additionalAction.label}
                      </Button>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>

      {useSeparator && <Separator />}
    </Fragment>
  );
};

PageActions.defaultProps = {
  mainActions: [],
  additionalActions: [],
  useSeparator: false,
  isParticipantForm: false,
};

PageActions.propTypes = {
  mainActions: MainActionsType,
  additionalActions: AdditionalActionsType,
  useSeparator: PropTypes.bool,
  isParticipantForm: PropTypes.bool,
};
