import React from 'react';
import * as selectors from '../RouteController/ducks/RouteController.selectors';
import { connect } from 'react-redux';
import { RouteControllerProps } from './RouteController.types';
import Notification from '../Notification/Notification';
import { NotificationType } from '../Notification/Notification.types';
import {
  getMatchedNotification,
  checkIsNotificationCanBeShown,
} from './RouteController.utils';

const RouteController: React.FC<RouteControllerProps> = (props) => {
  const {
    absolutePath,
    notifications,
    path: routePath,
    component: Component,
    render,
    renderProps,
    location,
  } = props;
  const path = absolutePath || location?.pathname || routePath;
  const matchedNotification = getMatchedNotification(notifications, path);
  const isNotificationCanBeShown = matchedNotification
    ? checkIsNotificationCanBeShown(matchedNotification)
    : false;

  return (
    <>
      {isNotificationCanBeShown && matchedNotification && (
        <Notification
          id={matchedNotification.id}
          bannerType={matchedNotification.bannerType}
          notificationType={matchedNotification.notificationType}
          title={matchedNotification.title}
          description={matchedNotification.description}
          path={path}
        />
      )}
      {(!isNotificationCanBeShown ||
        matchedNotification?.notificationType !==
          NotificationType.ParkedPages) && (
        <>
          {render && render(renderProps)}
          {Component && <Component {...props} {...renderProps} />}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  notifications: selectors.notifications(state),
});

export default connect(mapStateToProps)(RouteController);
