import { yup } from '@crpt-ui/form';
import type { StringSchema } from 'yup';

import { commonErrorMessages } from '../../../constants';
import { FieldIds } from './AcsCis.types';
import { testInns } from '../AcsUnloads.utils';
import defaultSchema from '../AcsUnloads.schema';
import { MetaFieldIds } from '../AcsUnloads.types';

export default defaultSchema.shape(
  {
    [FieldIds.INN]: yup
      .string()
      .when([`meta.${MetaFieldIds.IS_OPERATOR}`], {
        is: (isOperator) => isOperator,
        then: (schema: StringSchema) =>
          schema.required(commonErrorMessages.REQUIRED),
      })
      .test(FieldIds.INN, commonErrorMessages.INNS_TYPE, testInns),
    [FieldIds.TYPE_OF_MOVEMENT]: yup.string().required(),
  },
  []
);
