import { handleActions } from 'redux-actions';

import {
  closeBlockUserDialog,
  openBlockUserDialog,
} from './BlockUserDialog.actions';

const isOpenBlockUserDialog = handleActions(
  {
    [closeBlockUserDialog]: () => null,
    [openBlockUserDialog]: (_state, { payload }) => payload,
  },
  null
);

export const reducer = {
  isOpenBlockUserDialog,
};
