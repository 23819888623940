import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  preparingStatus: {
    color: palette.warning.main,
  },
  failedStatus: {
    color: palette.error.main,
  },
}));
