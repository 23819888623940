import React, { useCallback, useMemo } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { Icon } from '@crpt-ui/core';

import { ProfileHeader } from '../../components/ProfileHeader/ProfileHeader';
import { openProductionSiteModal } from '../ducks/Production.actions';
import { getRegistered } from '../../Company/ducks/Company.selectors';
import { getProductionData } from '../ducks/Production.selectors';
import { sendFinalize } from '../../Company/ducks/Company.actions';
import * as authSelectors from '../../../../common_components/Auth/ducks/Auth.selectors';

const Header = ({
  productionLines,
  openProductionSiteModal,
  history,
  isRegistered,
  sendFinalize,
  isAdmin,
  isInActive,
}) => {
  const openModal = useCallback(() => {
    openProductionSiteModal();
  }, [openProductionSiteModal]);

  const mainActions = useMemo(
    () => [
      {
        label: 'Добавить площадку',
        onClick: openModal,
        isShow: (isRegistered && isAdmin) || isInActive,
        size: 'lg',
        icon: <Icon fill="#63666A" name="Add" />,
      },
      {
        label: 'Предыдущий шаг',
        onClick: () => history.replace('/profile/requisites'),
        isShow: !isRegistered,
        size: 'lg',
        variant: 'outlined',
      },
      {
        label: 'Отправить',
        onClick: sendFinalize,
        isShow: !isRegistered,
        disabled: productionLines.result.length === 0,
        size: 'lg',
        tooltip: {
          isHidden: productionLines.result.length !== 0,
          title:
            'Для отправки данных добавьте сведения минимум об одной производственной площадке.',
        },
      },
    ],
    [history, isRegistered, openModal, productionLines.result.length]
  );

  return React.createElement(ProfileHeader, {
    mainActions,
    page: 'PRODUCTION',
    useSeparator: false,
  });
};

Header.propTypes = {};

const mapStateToProps = (state) => ({
  isRegistered: getRegistered(state),
  productionLines: getProductionData(state),
  isAdmin: authSelectors.isAdmin(state),
  isInActive: authSelectors.isInActive(state),
});

const mapDispatchToProps = {
  openProductionSiteModal,
  sendFinalize,
};

export const HeaderConnected = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Header);
