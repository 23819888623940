import { createAction } from 'redux-actions';

export const subject = '[RegistryOIV]';

export const onDataLoaded = createAction(`${subject} onDataLoaded`);
export const fetchData = createAction(`${subject} fetchData`);
export const fetchAddOiv = createAction(`${subject} fetchAddOiv`);
export const clearAddedOiv = createAction(`${subject} clearAddedOiv`);
export const addedOiv = createAction(`${subject} addedOiv`);
export const onUpdatePage = createAction(`${subject} onUpdatePage`);
export const onUnmount = createAction(`${subject} onUnmount`);
