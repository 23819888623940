import { createSelector } from 'reselect';

export const getClosingDocumentsListState = (state) =>
  state.Documents.ClosingDocumentsList;

export const getClosingDocumentsList = createSelector(
  [getClosingDocumentsListState],
  (state) => state.documents
);

export const getPagesCount = createSelector(
  [getClosingDocumentsListState],
  (state) => state.pagesCount
);

export const getPage = createSelector(
  [getClosingDocumentsListState],
  (state) => state.page
);

export const getLoading = createSelector(
  [getClosingDocumentsListState],
  (state) => state.loading
);
