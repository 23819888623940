import { createActions } from 'redux-actions';

/**
 * Payment status actions
 */
export const {
  setLoading,
  setPaymentStatus,
  fetchPaymentStatus,
  clearPaymentStatus,
} = createActions(
  {
    SET_LOADING: (payload) => payload,
    SET_PAYMENT_STATUS: (payload) => payload,

    FETCH_PAYMENT_STATUS: (payload) => payload,

    CLEAR_PAYMENT_STATUS: () => {},
  },
  {
    prefix: 'PAYMENT_STATUS',
  }
);
