import React, { useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Icon, Header, useEvent } from '@ibox/ui';
import { MainMenuSpace } from '@ibox/ui/src/layout/header/main-menu';

import { getCookie, setLanguage } from '../../utils/cookieHelpers';
import { startLogoutSaga } from '../Auth/ducks/Auth.actions';
import * as authSelectors from '../Auth/ducks/Auth.selectors';
import Logo from '../../assets/logos/logo_am.png';
import { Language } from '../../helpers';
import {
  getUserInfo,
  getUserAuthorities,
  getProductGroupsDetails,
} from '../Auth/ducks/Auth.selectors';
import { PARTICIPANT_TYPES } from '../../constants/participantTypes';

import { getItems, makeShortName } from './PageHeader.utils';
import { BalanceConnected } from './Balance/Balance';
import { items } from './ducks/pageHeaderSelector';

//TODO: Возможно понадобиться
// import { NavigationInfo } from './NavigationInfo/NavigationInfo';

import css from './index.module.scss';

const languages = [
  {
    code: 'ru',
    translations: {
      hy: 'Ռուսերեն',
      ru: 'Русский',
    },
  },
  {
    code: 'hy',
    translations: {
      hy: 'Հայերեն',
      ru: 'Армянский',
    },
  },
];

export const PageHeader = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const userInfo = useSelector((state) => getUserInfo(state));
  const userAuthorities = useSelector((state) => getUserAuthorities(state));
  const isUserOIV = useSelector((state) => authSelectors.isOIV(state));

  const productGroupsDetails = useSelector((state) =>
    getProductGroupsDetails(state)
  );

  const subMenuList = useSelector((state) => items(state));

  const filteredItems = useMemo(
    () =>
      subMenuList.filter(({ route }) => {
        const adminOrUser =
          userAuthorities.includes(PARTICIPANT_TYPES.ROLE_ADMIN) ||
          userAuthorities.includes(PARTICIPANT_TYPES.ROLE_USER);
        const desiredPg = !!productGroupsDetails.find(
          (pg) => pg.status === '5'
        );
        switch (route) {
          case '/tg':
            return (
              (adminOrUser ||
                userAuthorities.includes(PARTICIPANT_TYPES.ROLE_ORG_OGV)) &&
              desiredPg
            );
          case '/participants/list':
            return (
              (userAuthorities.includes(
                PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR
              ) &&
                adminOrUser) ||
              userAuthorities.includes(PARTICIPANT_TYPES.ROLE_ORG_OGV)
            );
          case '/oiv':
            return (
              userAuthorities.includes(
                PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR
              ) && adminOrUser
            );
          default:
            return true;
        }
      }),
    [subMenuList, userAuthorities, productGroupsDetails]
  );

  const subMenuItems = useMemo(
    () =>
      filteredItems.map((el, index) => ({
        id: String(index + 1),
        label: t(el.title) as string,
        value: el.route as string,
        onClick: el.onClick,
      })),
    [filteredItems, t]
  );

  const userShortName = useMemo(
    () => userInfo && makeShortName(userInfo.full_name),
    [userInfo]
  );

  const organizationForm = useSelector(
    (state) => state.Profile.Company.data.organizationForm
  );

  const menuItems = getItems({
    userInfo,
    organizationForm,
    t,
  });

  const onProfileClick = () => {
    history.push('/profile');
  };

  const changeLanguage = useEvent((language) => {
    const lng = language === 'en' ? 'ru' : language;
    const lang = lng.toUpperCase();
    i18n.changeLanguage(lang);
    setLanguage(lang);
  });

  const logoutUser = () => {
    dispatch(startLogoutSaga());
  };

  const handleHistoryPush = useEvent((newValue) => history.push(newValue));

  return (
    <>
      <Header
        changeLanguage={changeLanguage}
        className={css['header-container']}
        isCustomProfileVisible={true}
        languages={languages}
        locationPathname={location.pathname}
        logoSmall={Logo}
        logout={logoutUser}
        menuItems={menuItems}
        profileDropDownMunu={
          <Box>
            <BalanceConnected />
            {!isUserOIV && (
              <Box className={css['button-container']} onClick={onProfileClick}>
                <Box className={css['icon-container']}>
                  <Icon name="Profile" />
                </Box>
                <Typography>{t('Профиль')}</Typography>
              </Box>
            )}
            <Box className={css['button-container']} onClick={logoutUser}>
              <Box className={css['icon-container']}>
                <Icon name="SignOut" />
              </Box>
              <Typography>{t('Выход')}</Typography>
            </Box>
          </Box>
        }
        selectedLanguage={
          (getCookie('language') as Language) || ('ru' as Language)
        }
        subMenuItems={subMenuItems}
        userName={userShortName}
        onChangeSubMenu={handleHistoryPush}
      >
        <MainMenuSpace />
      </Header>
    </>
  );
};
