/* eslint-disable @typescript-eslint/ban-ts-comment */
import { WithStyles, withStyles } from '@material-ui/core';
import { useUtils } from '@material-ui/pickers';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';
import { MenuButton } from '@crpt-ui/core';
import React, { useCallback, useMemo } from 'react';

import DatepickerToolbarStyles from './DatepickerToolbar.styles';

export type DatepickerToolbarProps = {
  toolbarLabelFormat?: string;
} & ToolbarComponentProps &
  WithStyles<typeof DatepickerToolbarStyles>;

const DatepickerToolbar: React.FC<DatepickerToolbarProps> = ({
  classes,
  date,
  maxDate,
  minDate,
  onChange,
  onYearChange,
  toolbarLabelFormat = 'DD MMMM, dddd',
}) => {
  const utils = useUtils();

  const onClick = useCallback(
    (year: number) => {
      const newDate = utils.setYear(date, year);

      if (typeof onYearChange === 'function') {
        onYearChange(newDate);
      }

      onChange(newDate, true);
    },
    [date, onChange, onYearChange, utils]
  );

  const currentYear = useMemo(() => utils.getYear(date), [date, utils]);

  const years = useMemo(
    () =>
      utils
        .getYearRange(utils.date(minDate), utils.date(maxDate))
        .map((date) => {
          const year = utils.getYear(date);

          return {
            label: year,
            onClick: () => onClick(year),
            selected: year === currentYear,
          };
        }),
    [currentYear, maxDate, minDate, onClick, utils]
  );

  return (
    <div className={classes.root}>
      {/* @ts-ignore */}
      <MenuButton items={years} size="sm">
        {currentYear}
      </MenuButton>
      <div className={classes.label}>
        {utils.format(date, toolbarLabelFormat)}
      </div>
    </div>
  );
};

export default withStyles(DatepickerToolbarStyles)(DatepickerToolbar);
