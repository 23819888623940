import { call, put, select, takeEvery } from 'redux-saga/effects';
import qs from 'query-string';

import { documentTypes } from '../../../../constants/index';
import { Api } from '../../../../common_components/Api/Api';

import {
  fetchClosingDocumentsList,
  setClosingDocumentsList,
  setLoading,
  setPage,
  setPageCounts,
} from './ClosingDocumentsList.actions';
import { getPage } from './ClosingDocumentsList.selectors';

function* fetchClosingDocumentsListSaga() {
  yield put(setLoading(true));

  const page = yield select(getPage);

  const [success] = yield call(
    Api.request,
    {
      url: '/edo-api/incoming-documents',
      params: {
        folder: 0,
        limit: 10,
        offset: (page - 1) * 10,
        partner_inn: 7731376812,
        type: [
          documentTypes.updSf,
          documentTypes.updSfDop,
          documentTypes.nfBill,
          documentTypes.upd820Sf,
          documentTypes.upd820SfDop,
          documentTypes.ukdSfDis,
          documentTypes.ukdSf,
          604,
          602,
        ],
      },
      paramsSerializer: (params) => qs.stringify(params),
    },
    {
      preloading: false,
    }
  );

  if (success) {
    const { data } = success;
    const items = data.items.map((item) => ({
      ...item,
      total_price: item.total_price / 100,
    }));
    const pageCounts = Math.ceil(data.count / 10);

    yield put(setClosingDocumentsList(items));
    yield put(setPageCounts(pageCounts));
  }

  yield put(setLoading(false));
}

export const saga = function* watch() {
  yield takeEvery(
    [fetchClosingDocumentsList, setPage],
    fetchClosingDocumentsListSaga
  );
};
