export enum DocumentStatus {
  ACCEPTED = 6,
  CANCELLED = 5,
  CHECKED_NOT_OK = 2,
  CHECKED_OK = 1,
  IN_PROGRESS = 0,
  PARSE_ERROR = 9,
  PROCESSING_ERROR = 3,
  UNDEFINED = 4,
  WAIT_ACCEPTANCE = 7,
  WAIT_FOR_CONTINUATION = 10,
  WAIT_PARTICIPANT_REGISTRATION = 8,
}

export enum DocumentStatusCode {
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  CHECKED_NOT_OK = 'CHECKED_NOT_OK',
  CHECKED_OK = 'CHECKED_OK',
  IN_PROGRESS = 'IN_PROGRESS',
  PARSE_ERROR = 'PARSE_ERROR',
  PROCESSING_ERROR = 'PROCESSING_ERROR',
  UNDEFINED = 'UNDEFINED',
  WAIT_ACCEPTANCE = 'WAIT_ACCEPTANCE',
  WAIT_FOR_CONTINUATION = 'WAIT_FOR_CONTINUATION',
  WAIT_PARTICIPANT_REGISTRATION = 'WAIT_PARTICIPANT_REGISTRATION',
}