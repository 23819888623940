import { CompanyConnected } from './Company/company';
import { EdoRegistrationConnected } from './EdoRegistration/EdoRegistration';
import { ProductionConnected } from './Production/Production';
import { Requisites } from './Requisites/Requisites';
import { UserList } from './UserList/user-list';

export const profileRoutes = [
  {
    path: '/profile',
    exact: true,
    component: CompanyConnected,
  },
  {
    path: '/profile/requisites',
    exact: true,
    component: Requisites,
  },
  {
    path: '/profile/edo',
    exact: true,
    component: EdoRegistrationConnected,
  },
  {
    path: '/profile/production',
    exact: true,
    component: ProductionConnected,
  },
  {
    path: '/profile/users/list',
    exact: true,
    component: UserList,
  },
];
