import { selector } from '../../../utils/selector';

export const loaded = (state) => selector(state, 'ParticipantList.loaded');
export const results = (state) => selector(state, 'ParticipantList.results');
export const last = (state) => selector(state, 'ParticipantList.last');
export const total = (state) => selector(state, 'ParticipantList.total');
export const page = (state) => selector(state, 'ParticipantList.page');
export const pagesCount = (state) => Math.ceil(total(state) / 10);
export const error = (state) => selector(state, 'ParticipantList.error');
export const savedFilters = (state) =>
  selector(state, 'ParticipantList.savedFilters');

export const historyFilters = (state) =>
  selector(state, 'ParticipantList.historyFilters');
