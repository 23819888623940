import { createActions } from 'redux-actions';

export const {
  fetchNotificationOperatorDocument,
  setNotificationOperatorDocumentContent,
  setNotificationOperatorDocumentInfo,
  setLoading,
} = createActions(
  {
    FETCH_NOTIFICATION_OPERATOR_DOCUMENT: (payload) => payload,
    SET_NOTIFICATION_OPERATOR_DOCUMENT_CONTENT: (payload) => payload,
    SET_NOTIFICATION_OPERATOR_DOCUMENT_INFO: (payload) => payload,

    SET_LOADING: (payload) => payload,
  },
  {
    prefix: 'NOTIFICATION_OPERATOR_DOCUMENT',
  }
);
