import React from 'react';

import { AccountItemType } from '../../constants/PropTypes';
import { productGroupIdIconsMap } from '../../components/Accounts/Accounts.constants';
import { Currency } from '../Currency/Currency';
import { translate } from '../../components/Translate/Translate';

import { useStyles } from './SelectOption.styled';

export const SelectOption = ({ item }) => {
  const classes = useStyles();
  const Icon = productGroupIdIconsMap[item.productGroupId] || null;

  return (
    <div className={classes.wrapper}>
      <span className={classes.icon}>{Icon}</span>
      <span className={classes.label}>
        {translate('Лицевой счет')} № {item.contractId}
      </span>
      <span className={classes.currency}>
        <Currency>{item.balance}</Currency>
      </span>
    </div>
  );
};

SelectOption.propTypes = {
  item: AccountItemType,
};
