import { createActions } from 'redux-actions';

export const {
  startAuthSaga,
  setAuthState,
  setUserInfo,
  startLogoutSaga,
  clearAuth,
} = createActions(
  {
    START_AUTH_SAGA: (payload) => payload,
    START_LOGOUT_SAGA: () => {},

    SET_AUTH_STATE: (payload) => payload,
    SET_USER_INFO: (payload) => payload,

    CLEAR_AUTH: () => {},
  },
  {
    prefix: 'AUTH',
  }
);
