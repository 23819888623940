import { call, put, select, takeEvery } from 'redux-saga/effects';

import { Api } from '../../../../../common_components/Api/Api';
import { HttpMethodEnum } from '../../../../../constants/index';
import { getUserInn } from '../../../../../common_components/Auth/ducks/Auth.selectors';
import { results } from '../../../../ParticipantDetails/ducks/ParticipantDetails.selectors';
import { fetchUsersListSaga } from '../../ducks/UserList.saga';
import { requestSaga } from '../../../../ParticipantDetails/components/users/ducks/Users.saga';

import {
  closeAddingUserDialog,
  sendNewUser,
  setLoading,
} from './AddingUserDialog.actions';

function* createUserSaga({ payload }) {
  yield put(setLoading(true));
  const {
    email,
    user_role,
    fullName,
    socialCardNumber,
    phone,
    isRegisterUserUOT,
  } = payload;

  const senderInn = yield select(getUserInn);
  const selectedParticipant = yield select(results);

  const userData = {
    inn: isRegisterUserUOT ? selectedParticipant.inn : senderInn,
    // inn: senderInn,
    socialCardId: socialCardNumber,
    email,
    phone,
    roles: user_role,
    fio: fullName,
    ...(isRegisterUserUOT ? { fullName } : {}),
  };

  const [success] = yield call(
    Api.request,
    {
      url: '/api/v3/org/registration/participant/user',
      method: HttpMethodEnum.POST,
      data: userData,
    },
    {
      showBackendMessage: true,
      preloading: false,
    }
  );

  if (success) {
    yield put(closeAddingUserDialog());
    if (isRegisterUserUOT) {
      yield call(requestSaga, { payload: { id: selectedParticipant.id } });
    } else {
      yield call(fetchUsersListSaga);
    }
  }

  yield put(setLoading(false));
}

export const saga = function* watch() {
  yield takeEvery(sendNewUser, createUserSaga);
};
