import React from 'react';

import { Icon } from '@crpt-ui/core';

export const documentStatuses = {
  unknown: -1,
  draft: 0,
  sent: 1,
  delivered: 2,
  signatureRequired: 3,
  signed: 4,
  rejected: 5,
  recalled: 6,
  clarified: 7,
  clarificationRequired: 8,
  signError: 9,
  deliveringError: 10,
  deliveringIsExpected: 11,
  viewedSignatureIsNotRequired: 12,
  viewedSignatureRequired: 13,
  clarificationRequest: 14,
  viewedRejected: 15,
  revocationExpected: 16,
  revocationRequest: 17,
  revocated: 18,
  revocateDenied: 19,
  errorSend1: 41,
  errorSend2: 42,
  errorParse1: 43,
  errorParse2: 44,
  transitRejected: 51,
  revocationRoaming: 53,
  revocationRoamingDenied: 54,
  signedSubmitted: 61,
  signedNotAccepted: 62,
  signedSubmitting: 63,
  transitClarificationRequired: 81,
};

export const documentOutgoingStatusCaption = {
  [documentStatuses.unknown]: 'Неизвестно',
  [documentStatuses.draft]: 'Черновик',
  [documentStatuses.sent]: 'Отправлен',
  [documentStatuses.delivered]: 'Доставлен',
  [documentStatuses.signatureRequired]: 'Доставлен (ожидается подпись)',
  [documentStatuses.signed]: 'Подписан',
  [documentStatuses.rejected]: 'Отклонен',
  [documentStatuses.recalled]: 'Отозван',
  [documentStatuses.clarified]: 'Уточнён',
  [documentStatuses.clarificationRequired]: 'Требуется уточнение',
  [documentStatuses.signError]: 'Ошибка в подписи',
  [documentStatuses.deliveringError]: 'Ошибка доставки',
  [documentStatuses.deliveringIsExpected]: 'Ожидается отправка',
  [documentStatuses.viewedSignatureIsNotRequired]:
    'Просмотрен (подпись не требуется)',
  [documentStatuses.viewedSignatureRequired]: 'Просмотрен (ожидается подпись)',
  [documentStatuses.clarificationRequest]:
    'Требуется уточнение (запрос на уточнение просмотрен)',
  [documentStatuses.viewedRejected]: 'Отклонен (запрос просмотрен)',
  [documentStatuses.errorSend1]: 'Ошибка отправки',
  [documentStatuses.errorSend2]: 'Ошибка отправки',
  [documentStatuses.errorParse1]: 'Ошибка обработки',
  [documentStatuses.errorParse2]: 'Ошибка обработки',
  [documentStatuses.transitRejected]: 'Запрашивается отказ в подписи',
  [documentStatuses.transitClarificationRequired]: 'Запрашивается уточнение',
  [documentStatuses.signedSubmitted]: 'Подписан: Отправлен в ГИС МТ',
  [documentStatuses.signedNotAccepted]: 'Подписан: Не принят в ГИС МТ',
  [documentStatuses.signedSubmitting]: 'Подписан: Отправка в ГИС МТ',
  [documentStatuses.revocationExpected]: 'Ожидается аннулирование',
  [documentStatuses.revocationRequest]: 'Требуется аннулирование',
  [documentStatuses.revocated]: 'Аннулирован',
  [documentStatuses.revocateDenied]: 'Отказано в аннулировании',
  [documentStatuses.revocationRoaming]: 'Аннулирование документа',
  [documentStatuses.revocationRoamingDenied]: 'Отказ в аннулировании',
};

export const documentStatusIcon = {
  [documentStatuses.unknown]: <Icon name="Cross" />,
  [documentStatuses.draft]: <Icon name="Draft" />,
  [documentStatuses.sent]: <Icon name="Sent" />,
  [documentStatuses.delivered]: <Icon name="PublishedWithoutSign" />,
  [documentStatuses.signatureRequired]: <Icon name="SignatureRequired" />,
  [documentStatuses.signed]: <Icon name="Signed" />,
  [documentStatuses.rejected]: <Icon name="Rejected" />,
  [documentStatuses.recalled]: <Icon name="Recalled" />,
  [documentStatuses.clarified]: <Icon name="Clarified" />,
  [documentStatuses.clarificationRequired]: (
    <Icon name="ClarificationRequired" />
  ),
  [documentStatuses.signError]: <Icon name="SignError" />,
  [documentStatuses.deliveringError]: <Icon name="SignError" />,
  [documentStatuses.deliveringIsExpected]: <Icon name="DeliveringIsExpected" />,
  [documentStatuses.viewedSignatureIsNotRequired]: (
    <Icon name="ViewedSignatureIsNotRequired" />
  ),
  [documentStatuses.viewedSignatureRequired]: (
    <Icon name="ViewedSignatureRequired" />
  ),
  [documentStatuses.clarificationRequest]: (
    <Icon name="ClarificationRequired" />
  ),
  [documentStatuses.viewedRejected]: <Icon name="Rejected" />,
  [documentStatuses.errorSend1]: <Icon name="Rejected" />,
  [documentStatuses.errorSend2]: <Icon name="Rejected" />,
  [documentStatuses.errorParse1]: <Icon name="Rejected" />,
  [documentStatuses.errorParse2]: <Icon name="Rejected" />,
  [documentStatuses.transitRejected]: <Icon name="DeliveringIsExpected" />,
  [documentStatuses.transitClarificationRequired]: (
    <Icon name="DeliveringIsExpected" />
  ),
  [documentStatuses.signedSubmitted]: <Icon name="Delivered" />,
  [documentStatuses.signedSubmitting]: <Icon name="DeliveringIsExpected" />,
  [documentStatuses.signedNotAccepted]: <Icon name="Rejected" />,
  [documentStatuses.revocationExpected]: <Icon name="RevocationExpected" />,
  [documentStatuses.revocationRequest]: <Icon name="RevocationExpected" />,
  [documentStatuses.revocated]: <Icon name="Denied" />,
  [documentStatuses.revocateDenied]: <Icon name="RevocationDenied" />,
  [documentStatuses.revocationRoaming]: <Icon name="DocumentCross" />,
  [documentStatuses.revocationRoamingDenied]: <Icon name="Denied" />,
};

export const documentStatusHEXColor = {
  [documentStatuses.draft]: '#868686',
  [documentStatuses.rejected]: '#F34747',
  [documentStatuses.viewedRejected]: '#F34747',
  [documentStatuses.signError]: '#F34747',
  [documentStatuses.deliveringError]: '#F34747',
  [documentStatuses.errorSend1]: '#F34747',
  [documentStatuses.errorSend2]: '#F34747',
  [documentStatuses.errorParse1]: '#F34747',
  [documentStatuses.errorParse2]: '#F34747',
  [documentStatuses.signedNotAccepted]: '#F34747',
  [documentStatuses.revocated]: '#F34747',
  [documentStatuses.delivered]: '#48C834',
  [documentStatuses.signatureRequired]: '#F5A020',
  [documentStatuses.viewedSignatureRequired]: '#F5A020',
  [documentStatuses.signed]: '#48C834',
  [documentStatuses.viewedSignatureIsNotRequired]: '#48C834',
  [documentStatuses.recalled]: '#868686',
};

export const documentStatusColor = {
  [documentStatuses.rejected]: 'error',
  [documentStatuses.viewedRejected]: 'error',
  [documentStatuses.signError]: 'error',
  [documentStatuses.deliveringError]: 'error',
  [documentStatuses.errorSend1]: 'error',
  [documentStatuses.errorSend2]: 'error',
  [documentStatuses.errorParse1]: 'error',
  [documentStatuses.errorParse2]: 'error',
  [documentStatuses.signedNotAccepted]: 'error',
  [documentStatuses.revocated]: 'error',
  [documentStatuses.delivered]: 'success',
  [documentStatuses.signatureRequired]: 'warning',
  [documentStatuses.viewedSignatureRequired]: 'warning',
  [documentStatuses.signed]: 'success',
  [documentStatuses.viewedSignatureIsNotRequired]: 'success',
};
