import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { withFormValues } from '@crpt/shared/components/Form/FormSpy';
import { Box, Grid } from '@crpt/material';
import { Typography } from '@crpt-ui/core';
import {
  DatepickerInput as DatePicker,
  Input,
  Select,
} from '@crpt/shared/components/Form/Fields';
import {
  fileFormatOptions,
  MAX_DATE_PERIOD_DAYS,
  MAX_YEARS_AGO,
} from '../../AcsUnloads.constants';
import { DefaultFieldIds } from '../../AcsUnloads.types';

import type { DefaultFormValues } from '../../AcsUnloads.types';

interface CommonInformationProps {
  productGroupCodeOptions: { value: string | number; label: string | number }[];
  values: DefaultFormValues;
}

const CommonInformation: FC<CommonInformationProps> = ({
  values,
  productGroupCodeOptions,
}) => {
  const { t } = useTranslation();

  const maxDate = useMemo(() => new Date(), []);
  const minDate = useMemo(
    () => moment().subtract(MAX_YEARS_AGO, 'years').toDate(),
    []
  );

  const maxDataEndDate = useMemo(() => {
    const max = values[DefaultFieldIds.DATA_START_DATE]
      ? moment(values[DefaultFieldIds.DATA_START_DATE])
          .add(MAX_DATE_PERIOD_DAYS, 'days')
          .startOf('day')
          .toDate()
      : maxDate;

    return max > maxDate ? maxDate : max;
  }, [maxDate, values]);

  const minDataEndDate = useMemo(
    () => values[DefaultFieldIds.DATA_START_DATE] ?? minDate,
    [minDate, values]
  );

  const maxDataStartDate = useMemo(
    () => values[DefaultFieldIds.DATA_END_DATE] ?? maxDate,
    [maxDate, values]
  );

  const minDataStartDate = useMemo(
    () =>
      values[DefaultFieldIds.DATA_END_DATE]
        ? moment(values[DefaultFieldIds.DATA_END_DATE])
            .subtract(MAX_DATE_PERIOD_DAYS, 'days')
            .startOf('day')
            .toDate()
        : minDate,
    [minDate, values]
  );

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="h6">
          {t('UnloadService.Task.AcsUnloads.FieldGroup.commonInformation')}
        </Typography>
      </Grid>
      <Grid item xs>
        <Grid columnSpacing={2} container rowSpacing={2}>
          <Grid item xs={12}>
            <Input
              name={DefaultFieldIds.NAME}
              placeholder={t('UnloadService.Task.AcsUnloads.Fields.name')}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              name={DefaultFieldIds.PRODUCT_GROUP_CODE}
              options={productGroupCodeOptions}
              placeholder={t(
                'UnloadService.Task.AcsUnloads.Fields.productGroupCode'
              )}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                fontWeight: 600,
                fontSize: 16,
                mb: 1,
              }}
            >
              {t('UnloadService.Task.AcsUnloads.FieldGroup.dataPeriod')}
            </Box>
            <Grid columnSpacing={2} container>
              <Grid item xs={6}>
                <DatePicker
                  label={t(
                    'UnloadService.Task.AcsUnloads.Fields.dataStartDate'
                  )}
                  maxDate={maxDataStartDate}
                  minDate={minDataStartDate}
                  name={DefaultFieldIds.DATA_START_DATE}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  label={t(
                    'UnloadService.Task.AcsUnloads.Fields.dataEndDate'
                  )}
                  maxDate={maxDataEndDate}
                  minDate={minDataEndDate}
                  name={DefaultFieldIds.DATA_END_DATE}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Select
              name={DefaultFieldIds.FILE_FORMAT}
              options={fileFormatOptions}
              placeholder={t(
                'UnloadService.Task.AcsUnloads.Fields.fileFormat'
              )}
              required
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withFormValues(CommonInformation, {
  subscription: {
    values: true,
  },
});
