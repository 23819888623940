import { yup } from '@crpt-ui/form';

import { cyrillicRegex } from '../../../../common_components/Form/Regex';

export const schema = yup.object({
  acceptance: yup.object({
    content_code: yup.object({
      code: yup.number().when('$meta.isShowAcceptanceContentCode', {
        is: true,
        then: (schema) => schema.required(),
      }),
      name: yup.string(),
      kind: yup.number(),
      number: yup.number(),
      date: yup.date(),
      id: yup.string(),
    }),
  }),
  authority: yup.number().required(),
  status: yup.number().required(),
  grounds: yup.string(),
  organization_grounds: yup.string().when('status', {
    is: (val) => val === 3,
    then: yup
      .string()
      .required(
        'Поле обязательно для заполнения, если статус равен «3 — работник иной уполномоченной организации»'
      ),
  }),
  isOpenAcceptance: yup.boolean().default(false),
  date: yup.date(),
  position: yup.string().when('isOpenAcceptance', {
    is: true,
    then: yup.string().min(2).matches(cyrillicRegex).required(),
  }),
  name: yup.string().when('isOpenAcceptance', {
    is: true,
    then: yup.string().min(2).matches(cyrillicRegex).required(),
  }),
  surname: yup.string().when('isOpenAcceptance', {
    is: true,
    then: yup.string().matches(cyrillicRegex).required(),
  }),
  patronymic: yup.string().min(2).matches(cyrillicRegex),
  content_operation: yup.string().when('$meta.isUkdDocuments', {
    is: true,
    then: (schema) => schema.required(),
  }),
});
