import React, { Fragment } from 'react';

import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { RequiredFieldsWarning } from '@crpt-ui/core';

import { Modal } from '../../../../common_components/Modal/Modal';
import { Autocomplete } from '../../../../common_components/Form/Autocomplete';
import { Input } from '../../../../common_components/Form/Input';
import {
  validateAmount,
  validateEmail,
} from '../../../../common_components/Form/utils/validations';
import { NumberFormatCustom } from '../../../../common_components/NumberFormatCustom/NumberFormatCustom';

import { CreatePaymentDocumentSuccessConnected } from './CreatePaymentDocumentSuccess/CreatePaymentDocumentSuccess';

export const CreatePaymentDocumentView = ({
  onCloseModal,
  selectedAccount,
  accountOptions,
  email,
  modalActions,
  isOpenModal,
  isFormErrors,
}) => (
  <Fragment>
    <Modal
      actions={modalActions}
      open={isOpenModal}
      title="Получить счет"
      onClose={onCloseModal}
    >
      <Box my={3}>
        <RequiredFieldsWarning error={isFormErrors} />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            required
            initialValue={String(selectedAccount)}
            label="Номер лицевого счета"
            name="contractId"
            options={accountOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            required
            InputProps={{
              inputComponent: NumberFormatCustom,
              inputProps: {
                isAllowed: ({ floatValue }) => {
                  return String(Math.trunc(floatValue)).length <= 10;
                },
                decimalScale: 2,
                allowNegative: false,
              },
            }}
            label="Сумма с учетом НДС"
            name="amount"
            validate={validateAmount}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            required
            defaultValue={email}
            label="Email"
            name="sendto"
            validate={validateEmail}
          />
        </Grid>
      </Grid>
    </Modal>

    <CreatePaymentDocumentSuccessConnected />
  </Fragment>
);

CreatePaymentDocumentView.propTypes = {
  accountOptions: PropTypes.arrayOf(PropTypes.object),
  email: PropTypes.string.isRequired,
  isFormErrors: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedAccount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
