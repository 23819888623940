import { isEqual, omit, pick } from 'lodash';

export const getClassName = ({ required, disabled, error, meta }) => {
  if (disabled || (meta.data && meta.data.disabled)) {
    return 'disabled';
  }
  if (error || meta.error) {
    return 'error';
  }
  if (required || (meta.data && meta.data.required)) {
    return 'required';
  }

  return undefined;
};

export const pickProps = (obj, keys) => [pick(obj, keys), omit(obj, keys)];

export const isPropsEqual = (a, b) => {
  const result =
    isEqual(a.meta, b.meta) &&
    a.required === b.required &&
    a.error === b.error &&
    a.disabled === b.disabled &&
    a.input.name === b.input.name &&
    a.input.value === b.input.value;
  return result;
};
