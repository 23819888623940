import { createSelector } from 'reselect';

export const getSignModalState = (state) => state.COMMON.DocumentSigningModal;

export const getOpenModalSignModal = createSelector(
  [getSignModalState],
  (state) => state.open
);

export const isSigningInProcess = createSelector(
  [getSignModalState],
  (state) => state.isSigningInProcess
);

export const getAdditionalInfoSigningModal = createSelector(
  [getSignModalState],
  (state) => state.additionalInformation
);
