import React from 'react';

import { Box, Grid, Link } from '@mui/material';
import { Field } from 'react-final-form';

import { Button, Icon, TextFieldFinalFormWrapper as TextField } from '@ibox/ui';

import { requiredWithError } from '../../common_components/Form/utils/validations';
import { StripeLayout } from '../../common_components/Layouts';

import { RegistrationStatus } from './RegistrationStatus.utils';

type RegistrationStatusViewProps = {
  onSubmit: () => void;
  registrationDeclineReason: string;
  registrationStatus: string;
  isFormErrors?: boolean;
  isSignInButtonVisible?: boolean;
};

export const RegistrationStatusView = ({
  onSubmit,
  registrationDeclineReason,
  registrationStatus,
  isFormErrors,
  isSignInButtonVisible,
}: RegistrationStatusViewProps) => (
  <StripeLayout enableMobileLinks title="Заявка на регистрацию">
    <StripeLayout.Content>
      <Grid container spacing={2}>
        <Grid
          item
          style={{
            flex: '1 0 auto',
          }}
        >
          <Field
            fullWidth
            required
            component={TextField}
            label="Номер вашей заявки"
            name="documentId"
            type="number"
            validate={requiredWithError}
          />
        </Grid>

        <Grid item>
          <Button
            disabled={isFormErrors}
            size="large"
            type="submit"
            onClick={onSubmit}
          >
            Проверить
          </Button>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Link color="#888888" href="/login-kep" underline="none">
          <Button color="inherit" size="large" variant="outlined">
            <Icon name="Back" size="Small" />
            Назад
          </Button>
        </Link>
      </Box>
    </StripeLayout.Content>

    <Box alignItems="center" display="flex" justifyContent="center" mt={4}>
      <RegistrationStatus
        registrationDeclineReason={registrationDeclineReason}
        registrationStatus={registrationStatus}
      />
    </Box>

    {isSignInButtonVisible && (
      <Box mt={1} textAlign="center">
        <Link color="#888888" href="/login-kep" underline="none">
          <Button>Войти в личный кабинет</Button>
        </Link>
      </Box>
    )}
  </StripeLayout>
);
