import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
  setLoading,
  setNotificationOperatorDocumentContent,
  setNotificationOperatorDocumentInfo,
} from './NotificationOperatorDocument.actions';

const content = handleActions(
  {
    [setNotificationOperatorDocumentContent]: (_, { payload }) => payload,
    [LOCATION_CHANGE]: () => ({}),
  },
  {}
);

const info = handleActions(
  {
    [setNotificationOperatorDocumentInfo]: (_, { payload }) => payload,
    [LOCATION_CHANGE]: () => ({}),
  },
  {}
);

const loading = handleActions(
  {
    [setLoading]: (_, { payload }) => payload,
    [LOCATION_CHANGE]: () => true,
  },
  true
);

export const reducer = {
  content,
  info,
  loading,
};
