import { handleActions } from 'redux-actions';

import * as actions from './LoginForm.actions';

const errors = handleActions(
  {
    [actions.errors]: (_state, { payload }) => payload || null,
  },
  null
);

const submitDisabled = handleActions(
  {
    [actions.submit]: () => true,
    [actions.errors]: () => false,
  },
  false
);

export const reducer = {
  errors,
  submitDisabled,
};
