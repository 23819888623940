import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: 288,
    position: 'fixed',
    right: 20,
    bottom: -48,
    color: theme.palette.secondary.contrastText,
    borderRadius: 4,
    overflow: 'hidden',
    transition: 'bottom 0.2s ease-in-out, opacity 0.15s ease-in-out',
    opacity: 0,

    '&$open': {
      opacity: 1,
      bottom: 20,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.text.primary,
    borderBottom: '1px solid white',
    height: 48,
    padding: '16px 8px',
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'uppercase',
    boxSizing: 'border-box',
  },
  item: {
    position: 'relative',
    width: '100%',
    height: 40,
    background: theme.palette.text.primary,
    borderBottom: `1px solid ${theme.palette.secondary.contrastText}`,
    transition: 'all 0.2s linear',

    '&:last-child': {
      borderBottom: 'none',
    },
  },
  itemWrapper: {
    position: 'absolute',
    width: '100%',
    height: 40,
    transition: 'background 0.2s ease-in-out',
  },
  itemInner: {
    alignItems: 'center',
    height: '100%',
    padding: '0 8px',
  },
  label: {
    minWidth: 0,
    flex: '1 !important',
    fontSize: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingRight: 8,
  },
  fileSize: {
    fontSize: 12,
    fontWeight: 700,
  },
  loader: {
    background: `linear-gradient(90deg, ${theme.palette.grey[700]}, ${theme.palette.grey[600]}, ${theme.palette.grey[700]})`,
    animation: '$gradient 2s ease infinite',
    backgroundSize: '200% 200%',
    transition: 'width .3s ease-in-out, background .25s',
    height: 40,
    position: 'absolute',
    left: 0,

    '&$fixedWidth': {
      width: '90%',
    },

    '&$success': {
      width: '100%',
      background: theme.palette.success.main,
      transition: 'none',
    },
  },
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '100% 50%',
    },
    '50%': {
      backgroundPosition: '0% 50%',
    },
    '100%': {
      backgroundPosition: '100% 50%',
    },
  },
  success: {},
  open: {},
  fixedWidth: {},
}));
