import _ from 'lodash';

export const parseEdoOperators = (operators = []) => {
  return _.chain(operators)
    .map(({ id, name, status }) => ({
      label: name,
      value: id,
      status,
    }))
    .unshift({
      disabled: true,
      label: 'ЭДО лайт (ЦРПТ)',
      value: 16,
    })
    .value();
};
