import React, { useState, useMemo } from 'react';

import { Grid, Box } from '@mui/material';
import { isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { Button, BreadCrumbs } from '@ibox/ui';

import {
  ButtonWrap,
  CreateActionsWrapWrap,
  CreateWrapWrap,
} from '../ParticipantDetails/ParticipantDetails.styled';
import { Form } from '../../common_components/Form/form/form';
import { FormContext } from '../../common_components/Form/Form.constants';
import { ContactsInfo } from '../ParticipantDetails/components/basicInfo/contacts-info';
import { CommonInfo } from '../ParticipantDetails/components/basicInfo/common-info';
import { Preloader } from '../../common_components/Preloader/Preloader';

import { RolesConnected } from './role-create';

import css from './create-participant.module.scss';

export const ParticipantCreateView = ({ onSubmit, onCancel, loading }) => {
  const { t } = useTranslation();
  const [orgForm, setOrgForm] = useState(null);
  const validate = ({ legalAddress, organizationForm }) => {
    const error = {};
    if (organizationForm === 'UL' && !legalAddress) {
      error.legalAdress = 'error';
    }
    return error;
  };

  const breadcrumbs = useMemo(
    () => [
      {
        href: '/participants',
        label: t('Участники'),
      },
      {
        href: '/participants/list/create',
        label: t('Добавить участника'),
      },
    ],
    [t]
  );

  return (
    <>
      <Preloader isOpen={loading} text={t('Загрузка')} />
      <Box className="breadcrumbsWrap">
        <BreadCrumbs data={breadcrumbs} />
      </Box>
      <div className={css.content}>
        <Form validate={validate} onSubmit={onSubmit}>
          <CreateWrapWrap>
            <Grid
              container
              alignItems="flex-start"
              justify="space-between"
              spacing={2}
            >
              <Grid item xs={6}>
                <CommonInfo
                  isCreation
                  disabled={false}
                  onSelectOrgForm={setOrgForm}
                />
              </Grid>
              <Grid item xs={6}>
                <ContactsInfo
                  isCreation
                  disabled={false}
                  organizationForm={orgForm}
                />
              </Grid>
              <Grid item xs={12}>
                <RolesConnected disabled={false} />
              </Grid>
            </Grid>
          </CreateWrapWrap>
          <CreateActionsWrapWrap>
            <ButtonWrap>
              <Button variant="text" onClick={() => onCancel()}>
                {t('Отменить')}
              </Button>
            </ButtonWrap>
            <FormContext.Consumer>
              {({ errors: formErrors, values }) => (
                <Button
                  className="whiteColor"
                  disabled={!isEmpty(formErrors)}
                  onClick={() => onSubmit(values)}
                >
                  {t('Сохранить')}
                </Button>
              )}
            </FormContext.Consumer>
          </CreateActionsWrapWrap>
        </Form>
      </div>
    </>
  );
};
