import React, { useMemo } from 'react';

import { connect } from 'react-redux';
import { Box } from '@material-ui/core';

import { Notification } from '@crpt-ui/core';
import { useBooleanState } from '@crpt/shared/hooks';

import { getUsingHub } from '../ducks/ClosingDocument.selectors';

interface HubNotificationProps {
  useHub: boolean;
}

const HubNotification: React.FC<HubNotificationProps> = ({ useHub }) => {
  const [isOpenHubNotification, { off: onCloseHubNotification }] =
    useBooleanState(true);

  const isShowHubNotification = useMemo(
    () => useHub && isOpenHubNotification,
    [isOpenHubNotification, useHub]
  );

  if (!isShowHubNotification) {
    return null;
  }

  return (
    <Box mb={2} mt={-2} mx={-2}>
      <Notification onClose={onCloseHubNotification}>
        Действия над документом не доступны, так как в личном кабинете Вы
        указали, что будете использовать для этого систему 1С. Для работы с
        документом через веб-интерфейс Единого личного кабинета перейдите в
        раздел «Профиль» → вкладка «Операторы ЭДО» и отключите настройку
        «Использовать 1С» у основного оператора ЭДО.
      </Notification>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  useHub: getUsingHub(state),
});

export const HubNotificationConnected =
  connect(mapStateToProps)(HubNotification);
