import { handleActions } from 'redux-actions';

import { DocumentTypesMap } from '../AgreementDocument.constants';

import {
  clearAgreementDocument,
  setAgreementDocumentContent,
  setAgreementDocumentInfo,
  setDocumentType,
} from './AgreementDocument.actions';

const info = handleActions(
  {
    [setAgreementDocumentInfo]: (_, { payload }) => payload,
    [clearAgreementDocument]: () => ({}),
  },
  {}
);

const content = handleActions(
  {
    [setAgreementDocumentContent]: (_, { payload }) => payload,
    [clearAgreementDocument]: () => ({}),
  },
  {}
);

const pageType = handleActions(
  {
    [setDocumentType]: (_, { payload }) => payload,
    [clearAgreementDocument]: () => DocumentTypesMap.AGREEMENT,
  },
  DocumentTypesMap.AGREEMENT
);

export const reducer = {
  info,
  content,
  pageType,
};
