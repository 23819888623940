export function createRequestSignature(): string {
  return btoa(
    JSON.stringify({
      timestamp: new Date().toISOString(),
    })
  );
}

/**
 * Функция для корректного отображения числительных
 * @param {Array} titles //['Яблоко','Яблока','Яблок'] [1,2,5]
 * @param {number} n
 * @returns {string}
 */
export const declOfNum = (titles: string[], n: number) =>
  titles[
    n % 10 === 1 && n % 100 !== 11
      ? 0
      : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? 1
      : 2
  ];

export { default as registerMetrics } from './registerMetrics';
