import { put, takeEvery, select, call } from 'redux-saga/effects';
import { replace, LOCATION_CHANGE } from 'connected-react-router';
import { isMobile } from 'react-device-detect';

import { checkMobileDevice } from './App.actions';
import { getLocation } from './App.selectors';

/**
 * Check mobile device
 */
export function* checkDevice() {
  const { pathname } = yield select(getLocation);

  if (isMobile) {
    yield put(replace('/mobile'));
  } else if (pathname === '/mobile') {
    yield put(replace('/login-kep'));
  }
}

export function* changePage() {
  yield call([window, 'scrollTo'], 0, 0);
}

export const saga = function* watch() {
  yield takeEvery(checkMobileDevice, checkDevice);
  yield takeEvery(LOCATION_CHANGE, changePage);
};
