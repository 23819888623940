import React, { useCallback, useEffect, useMemo } from 'react';

import { useUtils } from '@material-ui/pickers';

import { DatepickerInput as DatepickerInputBase } from '@crpt-ui/lab';

import { FieldHoc } from './FieldHoc';

const isNoEmptyString = (value) => Boolean(value && typeof value === 'string');

const DatePickerInputAdapter = ({ meta, input, ...fieldRest }) => {
  const { value, ...inputProps } = input;
  const utils = useUtils();

  useEffect(() => {
    if (isNoEmptyString(value)) {
      if (utils.isValid(value)) {
        inputProps.onChange(utils.date(value));
      } else {
        inputProps.onChange('');
      }
    }
  }, [inputProps, utils, value]);

  const isError = useMemo(() => {
    if (meta.initial) {
      return meta.invalid;
    }

    return !meta.touched && !meta.modified ? false : meta.invalid;
  }, [meta.initial, meta.invalid, meta.modified, meta.touched]);

  const preparedValue = useMemo(() => {
    if (isNoEmptyString(value)) {
      return utils.isValid(value) ? utils.date(value) : null;
    }

    return value || null;
  }, [utils, value]);

  const onChangeHandle = useCallback(
    (date) => {
      if (date === null) {
        inputProps.onChange('');
      } else {
        inputProps.onChange(date);
      }
    },
    [inputProps]
  );

  return (
    <DatepickerInputBase
      {...fieldRest}
      error={isError}
      helperText={isError ? meta.error : undefined}
      value={preparedValue}
      onBlur={inputProps.onBlur}
      onChange={onChangeHandle}
      onFocus={inputProps.onFocus}
    />
  );
};

DatePickerInputAdapter.defaultProps = {
  placeholder: 'ДД.ММ.ГГГГ',
};

export const DatePickerInput = FieldHoc(DatePickerInputAdapter);
