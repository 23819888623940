import ApiService from '@crpt/shared/services/ApiService';
import { AxiosRequestConfig } from 'axios';
import { DocumentTypeEnum } from './constants';
import {
  CisDocumentDTO,
  CisDTO,
  CisSearchRequestDTO,
  CisSearchResponseDTO,
  CrptDocument,
  FetchReceiptTransactionsParams,
  GroupedCisListDTO,
  OrganisationDTO,
  OrganisationBriefDTO,
  ProductDTO,
  ReceiptDTO,
  ReceiptId,
  ReceiptTransactionResponseDTO,
} from './types';
import { ReturnTypeEnum } from './constants/ReturnTypeEnum';

export interface ProductListResponse {
  results: ProductDTO[];
  total: number;
}

export interface FiscalDocumentResponse {
  fiscalDriveNumber: string;
  receiptsCount: number;
}

export const MINIMUM_DATE_TIME_FOR_RECEIPT_SEARCH = '19 Aug 2021 00:00:00 GMT';

export interface GroupedCisListResponse {
  results: GroupedCisListDTO[];
}

class Bff extends ApiService {
  fetchProductList(gtins: string[], config?: AxiosRequestConfig) {
    return this.post<ProductListResponse>('/products/list', gtins, config);
  }

  fetchDocument<T = CrptDocument>(
    requestParams: { documentId: string; productGroup: number },
    config?: AxiosRequestConfig
  ) {
    const { documentId, productGroup } = requestParams;
    return this.get<T>(`/documents/${documentId}`, {
      params: { productGroup },
      ...config,
    });
  }

  fetchCisList(cises: string[], config?: AxiosRequestConfig) {
    return this.post<CisDTO[]>('/cis/cises', cises, config);
  }

  searchCisList(data: CisSearchRequestDTO, config?: AxiosRequestConfig) {
    return this.post<CisSearchResponseDTO>('/cis/search', data, config);
  }

  fetchCisSearchMode(config?: AxiosRequestConfig) {
    return this.options<null>('/cis/search', config);
  }

  fetchCisDocumentList(cises: string[], config?: AxiosRequestConfig) {
    return this.post<CisDocumentDTO[]>(
      '/cis-history/list',
      { cis: cises },
      config
    );
  }

  fetchReceipt(id: ReceiptId, config?: AxiosRequestConfig) {
    return this.get<ReceiptDTO>(`/receipts/${id}`, config);
  }

  fetchOrganisationList(innList: string[], config?: AxiosRequestConfig) {
    return this.get<OrganisationDTO[]>('/organisation/list', {
      params: {
        inns: innList,
      },
      ...config,
    });
  }

  searchOrganisationBrief(
    requestParams: OrganisationBriefRequestParams,
    config?: AxiosRequestConfig
  ) {
    const { name = null, limit } = requestParams;
    return this.get<OrganisationBriefDTO[]>('/organisation/suggestions', {
      params: { name, limit },
      ...config,
    });
  }

  fetchReceiptTransactions(
    requestParams: FetchReceiptTransactionsParams,
    config?: AxiosRequestConfig
  ) {
    const request = {
      pagination: {
        page: requestParams.pageNumber,
        perPage: requestParams.pageSize,
      },
      filter: requestParams.filter,
    };
    return this.post<ReceiptTransactionResponseDTO>(
      '/receipts/search',
      request,
      config
    );
  }

  fetchFiscalDocument(onlyCalculateCount: boolean, data: ArrayBuffer) {
    return this.post<FiscalDocumentResponse>(
      `/receipts/kkt-receipts?onlyCalculateCount=${onlyCalculateCount}`,
      data,
      {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      }
    );
  }

  fetchGroupedCisList(
    cisList: string[],
    productGroup: number | number[],
    documentTypeCode = DocumentTypeEnum.UNKNOWN,
    returnType?: ReturnTypeEnum
  ) {
    return this.post<GroupedCisListResponse>('/cis/grouped', {
      cises: cisList,
      productGroups: Array.isArray(productGroup)
        ? productGroup
        : [productGroup],
      documentTypeCode,
      ...(returnType && { returnType }),
    });
  }

  fetchAddressFromFiasId(fiasId: string) {
    return this.post<{ address?: string }>('/address-service/address/fias', {
      fias: fiasId,
    });
  }

  transformXlsCisListToJson(file: File, config?: AxiosRequestConfig) {
    const data = new FormData();

    data.append(
      'file',
      new Blob([file], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
      file.name
    );

    return this.post<{ cises: string[] }>('/transform/cis_list', data, {
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8',
        ...config?.headers,
      },
      ...config,
    });
  }
}

export interface OrganisationBriefRequestParams {
  name?: string;
  limit?: number;
}

export default new Bff('/bff-elk/v1');
