import { validateStringRegExp } from './CisUnloads.constants';

import type { TestFunction } from 'yup';
import { intersection, isEmpty } from 'lodash';

export const testInns: TestFunction<string | undefined | null> = function (
  this,
  value
) {
  if (!value) {
    return true;
  }

  return value
    .split(',')
    .map((item) => item.trim())
    .every((item) => validateStringRegExp.inn.test(item));
};

const createErrorMessage = (
  type: string[],
  value: string,
  translation: any
) => {
  if (isEmpty(type)) {
    return '';
  }

  if (type.length === 1) {
    return translation.fieldErrors.statuses.onePackageType(
      translation.statuses[value],
      translation.packageTypes[type[0]]
    );
  }

  const typeName = type
    .map((item) => `"${translation.packageTypes[item]}"`)
    .join(', ');

  return translation.fieldErrors.statuses.multiplePackageType(
    translation.statuses[value],
    typeName
  );
};

export const testStasus: TestFunction = function (this, value) {
  const packageType: string[] = this.parent.packageType || [];
  let message = '';

  switch (value) {
    case 'EMITTED':
    case 'RETIRED':
      message = createErrorMessage(
        intersection(packageType, [
          'ATK',
          'LEVEL1',
          'LEVEL2',
          'LEVEL3',
          'LEVEL4',
          'LEVEL5',
        ]),
        value,
        this.parent.meta.translation
      );
      break;
    case 'DISAGGREGATION':
      message = createErrorMessage(
        intersection(packageType, ['BUNDLE', 'UNIT']),
        value,
        this.parent.meta.translation
      );
      break;
    default:
      break;
  }

  if (message) {
    return this.createError({
      message,
    });
  }
  return true;
};