import React from 'react';

import { Box, Typography } from '@mui/material';

import css from './index.module.scss';

export const StripeLayout = ({ title, children }) => (
  <Box className={css.root}>
    <Box className={css.block}>
      <Box className={css.head}>
        <Box className={css.container}>
          {title && (
            <Box mb={3} mt={9}>
              <Typography className={css.title} variant="h3">
                {title}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box className={css.content}>{children}</Box>
    </Box>
  </Box>
);
