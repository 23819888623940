import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import moment from 'moment';

import { HttpMethodEnum } from '../../../../../constants/index';
import { Api } from '../../../../../common_components/Api/Api';
import { fetchAgreementDocuments } from '../../ducks/AgreementDocumentsList.actions';
import { getCurrentCertificateValidTo } from '../../../../../common_components/Cades/Cades.selectors';

import {
  clearManualModeModal,
  closeManualModeModal,
  fetchParticipantsAutocomplete,
  sendParticipants,
  setAutocompleteList,
  setLoading,
  setLoadingAutocomplete,
} from './ManuallyAgreementDocument.actions';

function* fetchParticipantsAutocompleteSaga({ payload }) {
  yield put(setLoadingAutocomplete(true));

  const requestPayload = {
    url: '/api/v3/org/registry/suggestions',
    params: {
      name: payload,
    },
  };

  const [autocompleteList] = yield call(Api.request, requestPayload, {
    preloading: false,
  });

  if (autocompleteList) {
    yield put(setAutocompleteList(autocompleteList.data));
  }

  yield put(setLoadingAutocomplete(false));
}

function* sendParticipantsSaga({ payload }) {
  yield put(setLoading(true));

  const {
    values: { selectedOrganizations, expiration_date, everyonePermitted },
  } = payload;
  const expirationDateValue = moment(expiration_date, 'DD.MM.YYYY')
    .add(1, 'day')
    .subtract(1, 'seconds')
    .toISOString();
  const certificateValidTo = yield select(getCurrentCertificateValidTo);
  const diffExpirationDate =
    moment(expirationDateValue).diff(certificateValidTo);
  const expirationDate =
    diffExpirationDate > 0 ? certificateValidTo : expirationDateValue;

  const trustedInns = selectedOrganizations.map((item) => item.inn);

  const requestPayload = {
    url: '/api/v3/facade/agreement-registry/agreement',
    method: HttpMethodEnum.POST,
    data: {
      trustedInns,
      expirationDate,
      everyonePermitted,
    },
  };

  const [success] = yield call(Api.request, requestPayload, {
    preloading: false,
    withoutDescriptionOfError: true,
  });

  if (success) {
    yield put(closeManualModeModal());
    yield put(clearManualModeModal());
    yield put(fetchAgreementDocuments());

    if (payload.onSuccess) {
      payload.onSuccess();
    }
  }

  yield put(setLoading(false));
}

export const saga = function* watch() {
  yield takeLatest(
    fetchParticipantsAutocomplete,
    fetchParticipantsAutocompleteSaga
  );
  yield takeEvery(sendParticipants, sendParticipantsSaga);
};
