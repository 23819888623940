import React, { useEffect } from 'react';

import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash/fp';
import { Field, Form } from 'react-final-form';
import { Grid } from '@mui/material';

import { CommonDialog, TextFieldFinalFormWrapper } from '@ibox/ui';

import {
  onDataLoaded,
  onUsersLoaded,
  fetchData,
  fetchGetUsers,
  fetchAddUser,
  setAddedUser,
  clearAddedUser,
  onUnmount,
} from '../RegistryOIVPage/ducks/RegistryOIVPage.actions';
import { addedUser } from '../RegistryOIVPage/ducks/RegistryOIVPage.selectors';
import {
  formatPhone,
  getOnlyNums,
  required,
  validateAmPhone,
  validateEmail,
  validateSocialCardNumber,
} from '../../common_components/Form/utils/validations';

import classes from './add-user-oiv.module.scss';

type AddUserOIVCompProps = {
  onClose: () => void;
  fetchAddUser: (values: Record<string, string | number>) => void;
  addedUser: boolean;
  clearAddedUser: () => void;
  pid: number;
  open: boolean;
};

const AddUserOIVComp = ({
  onClose,
  fetchAddUser,
  addedUser,
  clearAddedUser,
  pid,
  open,
}: AddUserOIVCompProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (addedUser) {
      onClose();
      clearAddedUser();
    }
  }, [addedUser]);

  const onSubmit = (values: Record<string, string>) => {
    fetchAddUser({
      ...values,
      oivId: pid,
    });
  };

  return (
    <Form
      render={({ handleSubmit, errors }) => {
        return (
          <CommonDialog
            invalid={!isEmpty(errors)}
            labelCancel={t('Отменить')}
            labelSubmit={t('Добавить')}
            open={open}
            size="sm"
            title={t('Добавить пользователя')}
            onClose={onClose}
            onSubmit={handleSubmit}
          >
            <Grid container className={classes.modalContainer} spacing={2}>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  component={TextFieldFinalFormWrapper}
                  label={t('ФИО')}
                  maxRows={4}
                  name="fio"
                  validate={required}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  component={TextFieldFinalFormWrapper}
                  format={getOnlyNums}
                  inputProps={{
                    maxLength: 10,
                  }}
                  label={t('Номер социальной карты')}
                  name="inn"
                  validate={validateSocialCardNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  component={TextFieldFinalFormWrapper}
                  label={t('Email')}
                  name="email"
                  validate={validateEmail}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  forceValidate
                  fullWidth
                  required
                  component={TextFieldFinalFormWrapper}
                  format={formatPhone}
                  label={t('Телефон')}
                  name="phone"
                  validate={validateAmPhone}
                />
              </Grid>
            </Grid>
          </CommonDialog>
        );
      }}
      onSubmit={onSubmit}
    />
  );
};

const mapState = (state) => ({
  addedUser: addedUser(state),
});

export const AddUserOiv = compose(
  withRouter,
  connect(mapState, {
    onDataLoaded,
    onUsersLoaded,
    fetchData,
    fetchGetUsers,
    fetchAddUser,
    setAddedUser,
    clearAddedUser,
    onUnmount,
  })
)(AddUserOIVComp);
