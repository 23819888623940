import { get } from 'lodash';

import { getValue } from '../../../common_components/Document/Document.utils';
import {
  documentStatusColorsMap,
  documentStatusIconsMap,
  documentTypeValuesMap,
  incomingDocumentStatusValuesMap,
} from '../DocumentsList/DocumentsList.constants';

export const isActOfReconciliation = (type, status) =>
  type === -10000 && [5, 15].includes(status);

export const getPreparedStatus = (info) => {
  if (isActOfReconciliation(info.type, info.status)) {
    return 'Подписано с расхождениями';
  }

  return getValue(incomingDocumentStatusValuesMap, info.status);
};

/**
 * Preparing document data, replacing ids with values
 * @param info
 * @returns {{senderId: number, statusIcon: Element, type: string, status: string, color: string}}
 */
export const getPrepareDocumentInfo = (info) => ({
  type: getValue(documentTypeValuesMap, info.type),
  number: info.number,
  status: getPreparedStatus(info),
  statusIcon: getValue(documentStatusIconsMap, info.status),
  color: get(documentStatusColorsMap, info.status),
});
