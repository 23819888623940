import { CheckboxGroup } from '@crpt-ui/core';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { DefaultFilter } from './DefaultFilter';
import { generateFilter } from '../Datatable.utils';

export const CheckboxGroupFilter = React.memo(
  ({ column, form, formState, instance, ...rest }) => {
    const onChange = useCallback((value) => {
      const nextValue = _.isEmpty(value) ? undefined : value;
      form.change('value', nextValue);
    }, []);

    const value = _.get(formState.values, 'value');

    return <CheckboxGroup {...rest} onChange={onChange} value={value} />;
  }
);

export const makeCheckboxGroupFilter = generateFilter(CheckboxGroupFilter);

CheckboxGroupFilter.propTypes = {
  ...DefaultFilter.propTypes,
  options: PropTypes.arrayOf(PropTypes.object),
};
