import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Button, Icon, Status, Tooltip } from '@crpt-ui/core';

import { MatchType } from '../../../../common_components/Document/DocumentType';
import { PageActions } from '../../../../common_components/PageActions/PageActions';
import { SignatureModal } from '../../../../common_components/SignatureModal/SignatureModal';
import { getPrepareDocumentStatus } from '../AgreementDocument.utils';
import { SignModal } from '../SignModal';
import {
  getAgreementDocumentInfo,
  isAgreementDocumentType,
  isCanSignDocument,
  isSignedDocument,
} from '../ducks/AgreementDocument.selectors';
import {
  cancellationAgreementDocument,
  declineAgreementDocument,
  downloadXlsAgreementDocument,
  startSignAgreementDocumentFromDocument,
} from '../ducks/AgreementDocument.actions';

const TopPanelComp = ({
  startSignAgreementDocumentFromDocument,
  declineAgreementDocument,
  cancellationAgreementDocument,
  downloadXlsAgreementDocument,
  match,
  documentInfo,
  isCanSignDocument,
  isSignedDocument,
  isAgreementDocumentType,
}) => {
  const {
    params: { id: documentId },
  } = match;

  const onSignAgreementDocument = useCallback(() => {
    startSignAgreementDocumentFromDocument({ documentId });
  }, [startSignAgreementDocumentFromDocument, documentId]);

  const onDownloadXLSFile = useCallback(() => {
    downloadXlsAgreementDocument(documentId);
  }, [downloadXlsAgreementDocument, documentId]);

  const isShowCancellationButton = useMemo(
    () => isSignedDocument && isAgreementDocumentType,
    [isSignedDocument, isAgreementDocumentType]
  );

  const mainDocumentActions = useMemo(
    () => [
      {
        label: 'Подписать',
        onClick: onSignAgreementDocument,
        isShow: isCanSignDocument,
        icon: <Icon name="Sign" />,
      },
      {
        label: 'Отклонить',
        onClick: declineAgreementDocument,
        isShow: isCanSignDocument,
        icon: <Icon name="Cancel" />,
        variant: 'outlined',
      },
      {
        label: 'Аннулировать',
        onClick: cancellationAgreementDocument,
        isShow: isShowCancellationButton,
        icon: <Icon name="Decline" />,
        variant: 'outlined',
      },
    ],
    [
      isShowCancellationButton,
      isCanSignDocument,
      onSignAgreementDocument,
      declineAgreementDocument,
      cancellationAgreementDocument,
    ]
  );

  const actions = React.createElement(PageActions, {
    mainActions: mainDocumentActions,
  });

  return (
    <Box alignItems="center" display="flex" justifyContent="space-between">
      <Box>{actions}</Box>
      <Box>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <SignatureModal certificate={documentInfo.certificate} />
          </Grid>
          <Grid item>
            <Tooltip title="Скачать список доверенных контрагентов">
              <Button
                icon={<Icon name="UserActive" />}
                variant="text"
                onClick={onDownloadXLSFile}
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <Status {...getPrepareDocumentStatus(documentInfo.statusCode)} />
          </Grid>
        </Grid>
      </Box>
      <SignModal />
    </Box>
  );
};

TopPanelComp.propTypes = {
  match: MatchType,
  startSignAgreementDocumentFromDocument: PropTypes.func.isRequired,
  declineAgreementDocument: PropTypes.func.isRequired,
  cancellationAgreementDocument: PropTypes.func.isRequired,
  isCanSignDocument: PropTypes.bool.isRequired,
  isSignedDocument: PropTypes.bool.isRequired,
  isAgreementDocumentType: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  documentInfo: getAgreementDocumentInfo(state),
  isCanSignDocument: isCanSignDocument(state),
  isSignedDocument: isSignedDocument(state),
  isAgreementDocumentType: isAgreementDocumentType(state),
});

const mapDispatchToProps = {
  declineAgreementDocument,
  cancellationAgreementDocument,
  startSignAgreementDocumentFromDocument,
  downloadXlsAgreementDocument,
};

export const TopPanel = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(TopPanelComp);
