import flatten from 'lodash/flatten';
import rutoken from 'rutoken';

import { DN, removeColonsFromString } from '../../helpers';
import { convertSubjectToDistinguishedName } from '../../helpers/DistinguishedName';
import { findErrorKeyByValue, getPluginErrorDescription } from './utils';

export const convertToString = (object) =>
  object.map((element) => [element.rdn, element.value].join('=')).join(', ');

export const load = () => {
  return rutoken.ready
    .then(() => {
      if (window.chrome) {
        return rutoken.isExtensionInstalled();
      }
      return Promise.resolve(true);
    })
    .then((isExtensionInstalled) => {
      if (isExtensionInstalled) {
        return rutoken.isPluginInstalled();
      }

      throw "Rutoken Extension wasn't found";
    })
    .then((isPluginInstalled) => {
      if (isPluginInstalled) {
        return rutoken.loadPlugin();
      }

      throw "Rutoken plugin wasn't found";
    });
};

export const enumerateCertificates = () => {
  return load().then((plugin) =>
    plugin.enumerateDevices().then((devices) =>
      Promise.all(
        devices.map((deviceId) =>
          Promise.all([
            plugin.enumerateCertificates(deviceId, plugin.CERT_CATEGORY_USER),
            plugin.enumerateCertificates(deviceId, plugin.CERT_CATEGORY_UNSPEC),
          ])
            .then((certificates) => flatten(certificates))
            .then((certificates) =>
              Promise.all(
                certificates.map((id) =>
                  plugin.parseCertificate(deviceId, id).then((certificate) => {
                    const subject = new DN(
                      convertToString(certificate.subject)
                    );
                    const subjectName = subject.toString();

                    const issuer = new DN(convertToString(certificate.issuer));
                    const issuerName = issuer.toString();

                    const validFromDate = new Date(certificate.validNotBefore);
                    const validToDate = new Date(certificate.validNotAfter);

                    const timestamp = new Date();
                    const thumbprint = removeColonsFromString(id);
                    const serialNumber = removeColonsFromString(
                      certificate.serialNumber
                    );

                    // Note: `info` used only for back compatibility
                    return Object.freeze({
                      deviceId: deviceId,
                      id: id,
                      info: [
                        subjectName,
                        validFromDate,
                        validToDate,
                        serialNumber,
                        issuerName,
                      ],
                      issuer: issuer,
                      issuerName: issuerName,
                      issuerDistinguishedName:
                        convertSubjectToDistinguishedName(issuerName),
                      plugin: 'rutoken',
                      serialNumber: serialNumber,
                      subject: subject,
                      subjectName: subjectName,
                      subjectDistinguishedName:
                        convertSubjectToDistinguishedName(subjectName),
                      thumbprint: thumbprint,
                      valid:
                        timestamp >= validFromDate && timestamp <= validToDate,
                      validFromDate: validFromDate.toISOString(),
                      validToDate: validToDate.toISOString(),
                    });
                  })
                )
              )
            )
        )
      ).then(flatten)
    )
  );
};

export const bind = (deviceId) => {
  return load().then((plugin) =>
    plugin
      .getDeviceInfo(deviceId, plugin.TOKEN_INFO_IS_LOGGED_IN)
      .then((authorized) => {
        if (authorized) {
          return Promise.resolve(plugin);
        } else {
          // eslint-disable-next-line no-alert
          const pin = prompt('Type password for container');

          return pin
            ? plugin.login(deviceId, pin).then(() => Promise.resolve(plugin))
            : Promise.reject('Авторизация отменена пользователем');
        }
      })
  );
};

export const sign = (base64, certificate, detached) => {
  const { deviceId, id } = certificate;

  return bind(deviceId)
    .then((plugin) =>
      plugin.sign(deviceId, id, base64, plugin.DATA_FORMAT_BASE64, {
        addSignTime: true,
        addUserCertificate: true,
        detached: detached,
      })
    )
    .catch((err) => {
      return load().then((plugin) => {
        throw {
          message: getPluginErrorDescription(err.message, plugin.errorCodes),
          errorMessage: `${getPluginErrorDescription(
            err.message,
            plugin.errorCodes
          )}. [${findErrorKeyByValue(err.message, plugin.errorCodes)}]`,
          type: 'error',
        };
      });
    });
};

export default {
  enumerateCertificates: enumerateCertificates,
  load: load,
  sign: sign,
  bind: bind,
  convertToString: convertToString,
};
