import { createSelector } from 'reselect';

export const geRegistrationStatusState = (state) => state.RegistrationStatus;

export const getRegistrationStatus = createSelector(
  [geRegistrationStatusState],
  (state) => state.registrationStatus
);

export const getRegistrationDeclineReason = createSelector(
  [geRegistrationStatusState],
  (state) => state.registrationDeclineReason
);
