import { call, put, takeEvery } from 'redux-saga/effects';

import { Api } from '../../../../common_components/Api/Api';
import { HttpMethodEnum } from '../../../../constants';
import { closeBlockUserDialog } from '../../../BlockUserDialog/ducks/BlockUserDialog.actions';

import {
  fetchBlockUser,
  fetchUsersList,
  putUserRole,
  setChangeStatus,
  setUsersList,
} from './UserList.actions';
import { requestSaga } from '../../../ParticipantDetails/components/users/ducks/Users.saga';

export function* fetchUsersListSaga() {
  const [success, error] = yield call(Api.request, {
    url: '/api/v3/facade/user/list/active',
  });

  if (success) {
    yield put(setUsersList(success.data));
  }

  if (error) {
    console.error('loading profile info error: ', error);
  }
}

export function* putUsersRoleSaga({ payload }) {
  const id = payload?.user_id;
  const participantId = payload?.participantId;
  if (id) {
    const [success, error] = yield call(Api.request, {
      url: `/api/v3/facade/users/${id}/roles`,
      method: HttpMethodEnum.PUT,
      data: { roles: payload.roles },
    });

    if (success) {
      participantId || participantId === 0
        ? yield call(requestSaga, { payload: { id: payload.participantId } })
        : yield call(fetchUsersListSaga);
    }

    if (error) {
      console.error('Role editing info error: ', error);
    }
  }
}

export function* blockUserSaga({ payload }) {
  const { user_id } = payload;

  const requestPayload = {
    url: `/api/v3/org/user/block/${user_id}`,
    method: 'put',
  };
  const [success] = yield call(Api.request, requestPayload);
  if (success) {
    yield put(
      setChangeStatus({
        id: user_id,
        status: 'BLOCKED',
      })
    );
    yield put(closeBlockUserDialog());
  }
}

export const saga = function* watch() {
  yield takeEvery(fetchUsersList, fetchUsersListSaga);
  yield takeEvery(fetchBlockUser, blockUserSaga);
  yield takeEvery(putUserRole, putUsersRoleSaga);
};
