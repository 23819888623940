import { createSelector } from 'reselect';

const getRequisitesState = (state) => state.Profile.Requisites;

export const getRequisitesData = createSelector(
  [getRequisitesState],
  (state) => state.data
);

export const getRequisitesEditing = createSelector(
  [getRequisitesState],
  (state) => state.editing
);
