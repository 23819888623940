import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  documentWrapper: {
    background: theme.palette.grey[400],
    overflowX: 'auto',
  },
  topPanel: {
    background: theme.palette.background.paper,
  },
}));
