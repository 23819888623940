import { handleAction } from 'redux-actions';

import { config as currentConfig } from '../../App.config.js';
import { getCookie } from '../../utils/cookieHelpers.js';

import * as actions from './App.actions';

const config = handleAction(
  actions.loadConfig,
  (_state, { payload }) => payload || {},
  currentConfig
);

const language = handleAction(
  actions.changeLanguage,
  (_state, { payload }) => payload.toLowerCase(),
  getCookie('language') || 'hy'
);

export const reducer = {
  language,
  config,
};
