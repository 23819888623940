import React from 'react';

import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

import { ContentWrapperStyles } from './ContentWrapper.styles';

const ContentWrapperComponent = ({ classes, className, children, ...rest }) => (
  <div className={clsx(classes.root, className)} {...rest}>
    {children}
  </div>
);

export const ContentWrapper = withStyles(ContentWrapperStyles)(
  ContentWrapperComponent
);
