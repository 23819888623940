import styled from 'styled-components';

export const Footer = styled.div`
  padding: 20px 40px;
  color: #b8b8b8;
  font-size: 12px;
  line-height: ${20 / 12};
`;

export const Balance = styled.div`
  font-size: 12px;
  line-height: ${20 / 12};
  font-weight: 600;
  text-transform: uppercase;
`;
