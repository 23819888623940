import { connect } from 'react-redux';
import { compose } from 'redux';

import * as selectors from '../ducks/LoginForm.selectors';

import { FieldsView } from './Fields.view';

const mapState = (state: any) => ({
  errors: selectors.errors(state),
  disabled: selectors.submitDisabled(state),
});

export const Fields = compose(connect(mapState))(FieldsView);
