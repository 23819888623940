import React, { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Filter, FilterSet, FiltersTable, Table } from '@ibox/ui';

import { Preloader } from '../../../../fragments/Table/Preloader';
import { getCookie } from '../../../../utils/cookieHelpers';

import {
  getFilters,
  getNewColumns,
  getFiltersTabs,
} from './documents.constants';
import {
  historyFiltersSelector,
  savedFiltersSelector,
} from './ducks/Documents.selectors';

type DocumentsViewProps = {
  isLoading: boolean;
  results: unknown[];
  onFilterApply: (filters: unknown) => void;
  last: unknown;
  onPageChange: (val: unknown) => void;
  selectedPage: number;
  handleAddFiltersHistory: (filters: Filter[]) => void;
  deleteSavedFilter: (filterId: string) => void;
  addSavedFilters: (filters: Filter[], filterName: string) => void;
  editSavedFilter: (
    value: Filter[],
    filterName: string,
    filter: FilterSet
  ) => void;
  getSavedFilters: VoidFunction;
  getHistoryFilters: VoidFunction;
};

export const DocumentsView = ({
  isLoading,
  results,
  onFilterApply,
  last,
  onPageChange,
  selectedPage,
  handleAddFiltersHistory,
  deleteSavedFilter,
  addSavedFilters,
  editSavedFilter,
  getHistoryFilters,
  getSavedFilters,
}: DocumentsViewProps) => {
  const { t } = useTranslation();

  const savedFilters = useSelector((state) => savedFiltersSelector(state));

  const historyFilters = useSelector((state) => historyFiltersSelector(state));

  const labelPagination = t('Показать еще');

  const head = getNewColumns(t);

  const filtersTabs = useMemo(() => getFiltersTabs(t), [t]);

  const filters = useMemo(() => getFilters(t), [t]);

  const language = getCookie('language') || 'hy';

  useEffect(() => {
    if (!historyFilters.length) {
      getHistoryFilters();
    }
    if (!savedFilters.length) {
      getSavedFilters();
    }
  }, [
    historyFilters.length,
    savedFilters.length,
    getHistoryFilters,
    getSavedFilters,
  ]);

  const initialFilters = useMemo(
    () =>
      historyFilters[historyFilters.length - 1]?.filters?.reduce(
        (acc, { id, value }) => {
          if (typeof value === 'object') {
            acc = {
              ...acc,
              ...value,
            };
          } else {
            acc[id] = value;
          }
          return acc;
        },
        {}
      ),
    [historyFilters]
  );

  return (
    <>
      <Preloader isOpen={isLoading} />
      <FiltersTable
        filters={filters}
        filtersHistory={historyFilters}
        filtersTabs={filtersTabs}
        initialFilters={initialFilters}
        locale={language}
        savedFilters={savedFilters}
        onAddFiltersHistory={handleAddFiltersHistory}
        onApplyFilters={onFilterApply}
        onDeleteFilters={deleteSavedFilter}
        onEditFilter={editSavedFilter}
        onSaveFilters={addSavedFilters}
      />
      <Table
        columns={head}
        currentPage={selectedPage}
        data={results}
        isLastPage={last}
        isLoading={isLoading}
        labelPagination={labelPagination}
        locale={language}
        useSort={false}
        onPageChange={onPageChange}
      />
    </>
  );
};
