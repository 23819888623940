import React from 'react';

import { Preloader } from '../Preloader/Preloader';

interface PreloaderWrapperProps extends React.ComponentProps<'div'> {
  children: React.ReactElement;
  isLoading?: boolean;
  minHeight?: number | 'auto';
}

export const PreloaderWrapper: React.FC<PreloaderWrapperProps> = ({
  isLoading,
  children,
  minHeight,
  ...rest
}: PreloaderWrapperProps): React.ReactElement => {
  if (isLoading) {
    return (
      <div
        style={{
          minHeight,
          display: 'flex',
        }}
        {...rest}
      >
        <Preloader isOpen />
      </div>
    );
  }

  return children;
};

PreloaderWrapper.defaultProps = {
  minHeight: 705,
};
