import React, { useCallback, useMemo, useState } from 'react';

import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import {
  getOpen,
  getSubmitting,
  getType,
} from './ducks/UploadNotificationOperatorDialog.selectors';
import {
  closeUploadNotificationOperatorDialog,
  sendNotificationOperatorDocument,
} from './ducks/UploadNotificationOperatorDialog.actions';
import { UploadNotificationOperatorDialogView } from './UploadNotificationOperatorDialog.view';

const UploadNotificationOperatorDialog = ({
  open,
  submitting,
  type,
  closeUploadNotificationOperatorDialog,
  sendNotificationOperatorDocument,
}) => {
  const [file, setFile] = useState({});

  const hasFile = useMemo(() => Boolean(file.data), [file.data]);

  const prepareFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const blob = new Blob([file], file);
        resolve({ data: reader.result, file, blob, filename: file.name });
      };
      reader.onerror = (event) => reject(`${event.target.error}`);
      reader.readAsArrayBuffer(file);
    });
  };

  const {
    getRootProps,
    getInputProps,
    open: onFileDialogOpen,
  } = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        prepareFile(acceptedFiles[0])
          .then((f) => {
            setFile(f);
            return null;
          })
          .catch(() => console.error('prepareFile err'));
      }

      if (rejectedFiles.length > 0) {
        // errors handler
      }
    },
    noClick: true,
    accept: '.pdf',
  });

  const onSubmit = useCallback(() => {
    sendNotificationOperatorDocument(file);
  }, [file, sendNotificationOperatorDocument]);

  const onClose = useCallback(() => {
    closeUploadNotificationOperatorDialog();
    setFile({});
  }, [closeUploadNotificationOperatorDialog]);

  const modalActions = useMemo(
    () => [
      {
        label: 'Подписать и отправить',
        onClick: onSubmit,
        isShow: hasFile,
        loading: submitting,
        disabled: submitting,
      },
    ],
    [hasFile, onSubmit, submitting]
  );

  return React.createElement(UploadNotificationOperatorDialogView, {
    open,
    type,
    file,
    onClose,
    getRootProps,
    getInputProps,
    modalActions,
    setFile,
    onFileDialogOpen,
    hasFile,
  });
};

const mapStateToProps = (state) => ({
  open: getOpen(state),
  type: getType(state),
  submitting: getSubmitting(state),
});

const mapDispatchToProps = {
  closeUploadNotificationOperatorDialog,
  sendNotificationOperatorDocument,
};

export const UploadNotificationOperatorDialogConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadNotificationOperatorDialog);
