import { createSelector } from 'reselect';

import { getAccounts } from '../../../_Profile/Company/ducks/Company.selectors';

export const getPaymentDocumentsList = (state) =>
  state.Documents.PaymentDocumentsList;

export const getAccountsOptions = createSelector([getAccounts], (accounts) =>
  accounts
    .map((account) => account.contractId)
    .sort()
    .map((contractId) => ({
      label: String(contractId),
      value: String(contractId),
    }))
);

export const getDocuments = createSelector(
  [getPaymentDocumentsList],
  (paymentDocumentsList) => paymentDocumentsList.documents
);

export const getSelectedAccount = createSelector(
  [getPaymentDocumentsList],
  (paymentDocumentsList) => paymentDocumentsList.selectedAccount
);

export const getSelectedAccountBalance = createSelector(
  [getSelectedAccount, getAccounts],
  (selectedAccountNumber, accounts) => {
    if (!selectedAccountNumber) {
      return 0;
    }

    const selectedAccount = accounts.find(
      (account) => account.contractId === Number(selectedAccountNumber)
    );

    return selectedAccount.balance;
  }
);
