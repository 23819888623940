import { useMemo } from 'react';

import { makeDateCell, makeMoneyCell } from '../../../utils/Table.utils';

export const usePaymentDocumentsListColumns = () =>
  useMemo(
    () => [
      {
        accessor: 'paymentdate',
        Header: 'Дата получения',
        Cell: makeDateCell({
          format: 'DD.MM.YYYY',
        }),
        width: 200,
      },
      {
        accessor: 'documentnr',
        Header: 'Номер платежного поручения',
        width: 240,
      },
      {
        accessor: 'amount',
        Cell: makeMoneyCell(),
        Header: 'Сумма',
        id: 'amount',
      },
    ],
    []
  );
