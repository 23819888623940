import React from 'react';

import { Box, Grid, Tooltip, Typography } from '@mui/material';

import { Button, Icon } from '@ibox/ui';

import { Divider } from '../../common_components/Divider/Divider';
import { ContentWrapper } from '../../common_components/styled';
import { Checkbox } from '../../common_components/Form/Checkbox';
import { Switch } from '../../common_components/Form/Switch';
import { CheckboxGroup } from '../../common_components/Form/CheckboxGroup/CheckboxGroup';

import { SettingsGrid } from './SettingsGrid/SettingsGrid';
import { documentTypes } from './Settings.constants';

import css from './index.module.scss';

type SettingsViewProps = {
  onHandleSubmit: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  submitting: boolean;
  isDisabledButton: boolean;
  isDisableFields: boolean;
  isCannotBeSaved: boolean;
};

export const SettingsView = ({
  onHandleSubmit,
  submitting,
  isDisabledButton,
  isDisableFields,
  isCannotBeSaved,
}: SettingsViewProps) => (
  <>
    <Box mb={2}>
      <Grid item>
        <Tooltip
          disableFocusListener={!isCannotBeSaved}
          disableHoverListener={!isCannotBeSaved}
          placement="bottom-start"
          title={
            <>
              Для того, чтобы сохранить изменения необходимо:
              <ul className={css.tooltipList}>
                <li>
                  либо выбрать типы документов и типы квитанций для получения
                </li>
                <li>либо отключить получение квитанций по email</li>
              </ul>
            </>
          }
        >
          <div>
            <Button
              disabled={isDisabledButton}
              loading={submitting}
              size="large"
              onClick={onHandleSubmit}
            >
              <>
                <Icon name="Save" />
                Сохранить изменения
              </>
            </Button>
          </div>
        </Tooltip>
      </Grid>
    </Box>

    <ContentWrapper className={css.contentWrapper}>
      <Typography variant="h2">Настройки</Typography>
      <Box mx={-4} my={4}>
        <Divider />
      </Box>
      <Box mb={4}>
        <SettingsGrid>
          <SettingsGrid.Left>
            <Typography variant="h4">Получать квитанции на email</Typography>
          </SettingsGrid.Left>

          <SettingsGrid.Right>
            <Switch name="BY_EMAIL" />
          </SettingsGrid.Right>
        </SettingsGrid>
      </Box>

      <Box mb={4}>
        <SettingsGrid>
          <SettingsGrid.Left>
            <Typography variant="h4">Типы квитанций</Typography>
          </SettingsGrid.Left>

          <SettingsGrid.Right>
            <Box>
              <Checkbox
                disabled={isDisableFields}
                label="О получении электронных документов"
                name="RECEIPT_GET"
              />
            </Box>

            <Box mt={2}>
              <CheckboxGroup
                disabled={isDisableFields}
                items={[
                  {
                    label: 'Обработанные успешно',
                    name: 'RECEIPT_UPDATE_SUCCESS',
                  },
                  {
                    label: 'Обработанные с ошибкой',
                    name: 'RECEIPT_UPDATE_FAILED',
                  },
                ]}
                name="RECEIPT_UPDATE"
                title="О внесении сведений"
              />
            </Box>
          </SettingsGrid.Right>
        </SettingsGrid>
      </Box>
      <Box mb={4}>
        <SettingsGrid>
          <SettingsGrid.Left>
            <Typography className={css.settingsGridTypograhy} variant="h4">
              Типы документов
            </Typography>
            <Typography color="textSecondary" variant="body2">
              Выберите типы документы, о&nbsp;которых&nbsp;Вы будете получать
              квитанции
            </Typography>
          </SettingsGrid.Left>

          <SettingsGrid.Right>
            {documentTypes.map((documentType) => (
              <Box key={documentType.name} mb={1}>
                <Checkbox
                  disabled={isDisableFields}
                  label={documentType.label}
                  name={documentType.name}
                />
              </Box>
            ))}
          </SettingsGrid.Right>
        </SettingsGrid>
      </Box>
    </ContentWrapper>
  </>
);
