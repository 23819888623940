import React, { Fragment, useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import { Checkbox as CoreCheckbox } from '@crpt-ui/core';

import { withFormContext } from '../Form.utils';
import { Checkbox } from '../Checkbox';
import { FormType } from '../../../constants/PropTypes';

export const CheckboxGroupComponent = ({
  title,
  name,
  items,
  formValues,
  form,
  disabled,
}) => {
  const itemsNames = useMemo(() => items.map((item) => item.name), [items]);

  const itemsValues = useMemo(
    () => itemsNames.map((itemName) => formValues[itemName]),
    [formValues]
  );

  const isReceiptUpdateIndeterminate = useMemo(
    () =>
      itemsValues.some((value) => value) &&
      !itemsValues.every((value) => value),
    [itemsValues]
  );

  const onAllChecked = useCallback(
    (event) => {
      itemsNames.forEach((itemName) => {
        form.change(itemName, event.target.checked);
      });
    },
    [itemsNames]
  );

  return (
    <Fragment>
      <CoreCheckbox
        checked={itemsValues.every((value) => value)}
        disabled={disabled}
        indeterminate={isReceiptUpdateIndeterminate}
        label={title}
        name={name}
        onChange={onAllChecked}
      />

      <Box pl={3.5}>
        {items.map((item) => (
          <Box key={item.name} mt={1}>
            <Checkbox disabled={disabled} label={item.label} name={item.name} />
          </Box>
        ))}
      </Box>
    </Fragment>
  );
};

export const CheckboxGroup = withFormContext({
  values: true,
  form: true,
})(CheckboxGroupComponent);

CheckboxGroupComponent.propTypes = {
  form: FormType,
  formValues: PropTypes.object,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  name: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
};
