import { combineActions, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
  clearFilter,
  clearSort,
  setFilter,
  setLoading,
  setNotificationOperatorDocuments,
  setPage,
  setSort,
} from './NotificationOperatorDocumentsList.actions';

const defaultStateData = { items: [] };

const data = handleActions(
  {
    [setNotificationOperatorDocuments]: (_, { payload }) => payload,
    [LOCATION_CHANGE]: () => defaultStateData,
  },
  defaultStateData
);

const loading = handleActions(
  {
    [setLoading]: (_, { payload }) => payload,
    [LOCATION_CHANGE]: () => true,
  },
  true
);

const sort = handleActions(
  {
    [setSort]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [combineActions(clearSort, LOCATION_CHANGE)]: () => ({}),
  },
  {}
);

const filter = handleActions(
  {
    [setFilter]: (_, { payload }) => payload,
    [combineActions(clearFilter, LOCATION_CHANGE)]: () => ({}),
  },
  {}
);

const page = handleActions(
  {
    [setPage]: (_, { payload }) => payload,
    [LOCATION_CHANGE]: () => 1,
  },
  1
);

export const reducer = {
  data,
  loading,
  sort,
  filter,
  page,
};
