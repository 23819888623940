import { createAction } from 'redux-actions';

export const subject = '[ParticipantList]';

export const mounted = createAction(`${subject} mounted`);
export const loaded = createAction(`${subject} loaded`);
export const getRequest = createAction(`${subject} get request`);
export const updatePage = createAction(`${subject} updatePage`);
export const unmounted = createAction(`${subject} unmounted`);
export const download = createAction(`${subject} download`);
export const error = createAction(`${subject} error`);
export const historyFilters = createAction(`${subject} historyFilters`);
export const savedFilters = createAction(`${subject} savedFilters`);
