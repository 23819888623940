import { call, put, takeEvery } from 'redux-saga/effects';

import Crypto, { Base64 } from '@crpt-ui/crypto';
import { toast } from '@crpt/shared/toast';

import { AuthService } from '../../../services/AuthService';

import {
  fetchCheckToken,
  fetchRegisterOIVUser,
  setSended,
  setValidToken,
} from './OIVUserRegister.actions';

function* OIVUserRegisterSaga({ payload: { cert, token } }) {
  try {
    const base64json = yield call(Base64.encode, token);
    const signature = yield call(Crypto.sign, base64json, cert.certificate);
    const success = yield call(AuthService.saveOivUserCertificate, {
      registrationToken: token,
      signature,
    });
    if (success) {
      yield put(setSended(true));
    }
  } catch (e) {
    toast.error(e.response.data.error_message);
  }
}

function* CheckTokenSaga({ payload: { token } }) {
  try {
    const success = yield call(AuthService.checkToken, { token });

    if (success) {
      yield put(setValidToken(true));
    }
  } catch (e) {
    if (e.response.status === 404) {
      toast.error(
        'Ссылка для завершения регистрации пользователя ОИВ не найдена в Системе. Обратитесь к сотруднику Оператора ГАИС МТ'
      );
    }
    if (e.response.status === 400) {
      toast.error(
        'Ссылка использована. Попробуйте авторизоваться в ЛК ГАИС МТ'
      );
    }
  }
}

export const saga = function* watch() {
  yield takeEvery(fetchRegisterOIVUser, OIVUserRegisterSaga);
  yield takeEvery(fetchCheckToken, CheckTokenSaga);
};
