import { useEffect, useMemo, useState } from 'react';

interface RedesignSwitcher {
  isActive: boolean;
  toggleActive: () => void;
}

const LOCAL_STORAGE_KEY = 'disabledRedesignComponents';

export function useRedesignSwitcher(component: string): RedesignSwitcher {
  const [disabledComponents, setDisabledComponents] = useState<string[]>(() => {
    const value = localStorage.getItem(LOCAL_STORAGE_KEY);
    try {
      const components = value ? JSON.parse(value) : [];
      return Array.isArray(components) ? components : [];
    } catch (e) {
      console.error(e);
      return [];
    }
  });

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(disabledComponents));
  }, [disabledComponents]);

  return useMemo<RedesignSwitcher>(() => {
    const isActive = !disabledComponents.includes(component);
    return {
      isActive,
      toggleActive: () => {
        const newComponents = isActive
          ? disabledComponents.concat(component)
          : disabledComponents.filter((item) => item !== component);
        setDisabledComponents(newComponents);
      },
    };
  }, [component, disabledComponents]);
}
