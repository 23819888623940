import React, { useCallback, useMemo } from 'react';

import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';

import { Icon, Status } from '@crpt-ui/core';

import { PageActions } from '../../../../common_components/PageActions/PageActions';
import { openSignModal } from '../../../../common_components/DocumentSigningModal/ducks/DocumentSigningModal.actions';
import { downloadDocumentWorkflow } from '../../../../common_components/Document/ducks';
import {
  clearServiceDeskDocument,
  fetchServiceDeskDocument,
  getLoading,
  getServiceDeskDocumentContent,
  getServiceDeskDocumentInfo,
} from '../ducks';
import { getPrepareDocumentStatus } from '../ServiceDeskDocument.utils';

const TopPanel = ({
  fetchServiceDeskDocument,
  openSignModal,
  documentInfo,
  documentId,
}) => {
  const onOpenSignModal = useCallback(() => {
    openSignModal({
      guid: documentId,
      onSuccess: () => fetchServiceDeskDocument(documentId),
    });
  }, [openSignModal, fetchServiceDeskDocument, documentId]);

  const canSignDocument = useMemo(() => {
    return documentInfo.status === 1;
  }, [documentInfo.status]);

  const mainDocumentActions = useMemo(
    () => [
      {
        label: 'Подписать',
        onClick: onOpenSignModal,
        isShow: canSignDocument,
        icon: <Icon name="Confirmation" />,
      },
    ],
    [onOpenSignModal, canSignDocument]
  );

  const statusInfo = useMemo(
    () => getPrepareDocumentStatus(documentInfo.edoStatus, documentInfo.status),
    [documentInfo.edoStatus, documentInfo.status]
  );

  return (
    <Grid container justify="space-between">
      <Grid item>
        <PageActions mainActions={mainDocumentActions} />
      </Grid>
      <Grid item>
        <Status {...statusInfo} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  documentContent: getServiceDeskDocumentContent(state),
  documentInfo: getServiceDeskDocumentInfo(state),
  isLoading: getLoading(state),
});

const mapDispatchToProps = {
  fetchServiceDeskDocument,
  clearServiceDeskDocument,
  openSignModal,
  downloadDocumentWorkflow,
};

export const TopPanelConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(TopPanel);
