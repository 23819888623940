import { handleAction } from 'redux-actions';

import {
  setPaymentDocuments,
  setSelectedAccount,
} from './PaymentDocumentsList.actions';

const documents = handleAction(
  setPaymentDocuments,
  (_, { payload }) => payload,
  []
);

const selectedAccount = handleAction(
  setSelectedAccount,
  (_, { payload }) => payload,
  ''
);

export const reducer = {
  documents,
  selectedAccount,
};
