import { call, put, takeLatest } from 'redux-saga/effects';

import { Api } from '../../../../../common_components/Api/Api';
import { closeBlockUserDialog } from '../../../../BlockUserDialog/ducks/BlockUserDialog.actions';

import * as actions from './Users.actions';
import { changeUserStatus } from './Users.actions';

export function* requestSaga(action) {
  const { payload = {} } = action;

  const { page = 0, id } = payload;

  const requestPayload = {
    url: `/api/v3/org/user/participant/${id}`,
    method: 'get',
    params: {
      limit: 10,
      page,
    },
  };

  const [success] = yield call(Api.request, requestPayload);

  if (success) {
    if (page !== undefined) {
      yield put(actions.updatePage(page));
    }

    const requestResults = success.data.items || [];
    const calculateLastPage = Math.ceil(success.data.count / 10);
    const last = (calculateLastPage === 0 ? 0 : calculateLastPage - 1) === page;
    const resultData = {
      results: requestResults,
      last,
    };
    if (page === 0) {
      yield put(actions.unmounted());
    }
    yield put(actions.loaded(resultData));
  }
}

function* blockUserSaga(action) {
  const { payload = {} } = action;
  const { user_id } = payload;

  const requestPayload = {
    url: `/api/v3/org/user/block/${user_id}`,
    method: 'put',
  };
  const [success] = yield call(Api.request, requestPayload);
  if (success) {
    yield put(
      changeUserStatus({
        id: user_id,
        status: 'BLOCKED',
      })
    );
    yield put(closeBlockUserDialog());
  }
}

export const saga = function* watch() {
  yield takeLatest(actions.getRequest, requestSaga);
  yield takeLatest(actions.blockUser, blockUserSaga);
};
