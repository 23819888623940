import { func, shape, string, arrayOf, number } from 'prop-types';

/**
 * Type for "form" props
 */
export const FormType = shape({
  getState: func,
  change: func,
  reset: func,
});

/**
 * Type for "formMutators" props
 */
export const FormMutatorsType = shape({
  updateField: func,
  push: func,
  remove: func,
});

export const LocationType = shape({
  pathname: string,
  search: string,
  hash: string,
  key: string,
});

/**
 * Type for "history" props
 */
export const HistoryType = shape({
  push: func.isRequired,
  location: LocationType,
}).isRequired;

export const AccountItemType = shape({
  organisationId: number,
  productGroupId: number,
  contractId: number,
  balance: number,
});

/**
 * Type for "accounts"
 */
export const AccountsType = arrayOf(AccountItemType);
