import { createActions } from 'redux-actions';

export const {
  setLoading,
  openUploadModeModal,
  closeUploadModeModal,
  sendUploadFile,
} = createActions(
  {
    SET_LOADING: (payload) => payload,

    OPEN_UPLOAD_MODE_MODAL: () => {},
    CLOSE_UPLOAD_MODE_MODAL: () => {},

    SEND_UPLOAD_FILE: (payload) => payload,
  },
  {
    prefix: 'UPLOAD_AGREEMENT_DOCUMENT',
  }
);
