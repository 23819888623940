import { call, put, takeEvery, select } from 'redux-saga/effects';

import crypto, { Base64 } from '@crpt-ui/crypto';
import Crypto from '@crpt-ui/crypto';
import { toast } from '@crpt/shared/toast';

import { CadesUtil } from '../../utils/CadesUtil';
import { getCertSerial } from '../../services/utils';
import { getLocation } from '../App/App.selectors';

import * as actions from './Cades.actions';
import {
  setCurrentCertificate,
  startSetCurrentCertificate,
  setPreparedCertificates,
  setCertificates,
  startSetCertificates,
} from './Cades.actions';
import { prepareCertificates } from './Cades.utils';
import {
  getCurrentCertificate,
  getPreparedCertificates,
} from './Cades.selectors';

function* loadSaga() {
  const certificates = yield call(crypto.enumerateCertificates);

  yield put(setCertificates(certificates));
  const prepared = yield call(prepareCertificates, certificates);
  yield put(setPreparedCertificates(prepared));
  yield put(startSetCurrentCertificate(prepared));
}

function* setCurrentCertificateSaga() {
  const { pathname } = yield select(getLocation);

  if (['/login', '/login-kep', '/register'].includes(pathname)) {
    return;
  }

  const serial = yield call(getCertSerial);
  let certificates = yield select(getPreparedCertificates);

  let certificate = {};

  try {
    certificate = certificates.find((item) => item.serial === serial);
  } catch (e) {
    throw new Error('Сертификат не найден');
  }

  yield put(setCurrentCertificate(certificate));
}

export function* signSaga(action) {
  const { payload } = action;
  const { data = {}, file } = payload;

  const { certificate } = yield select(getCurrentCertificate);

  try {
    let signedData;
    if (file) {
      const { type, content } = file;
      const signature = yield call(
        crypto.sign,
        content,
        certificate,
        true,
        true
      );

      signedData = {
        signature,
        document: content,
        format: type,
      };
    } else {
      const convertedData = yield call(Base64.encode, JSON.stringify(data));
      const signature = yield call(
        crypto.sign,
        convertedData,
        certificate,
        true
      );

      signedData = {
        signature,
        document: convertedData,
        format: 'MANUAL',
      };
    }

    return signedData;
  } catch (error) {
    const errorContent = CadesUtil.prepareError(error);

    yield call(toast.error, errorContent);
  }
}

function* signByteArraySaga(action) {
  const { payload: data, meta } = action;
  let { certificate } = meta;

  if (!data) {
    return;
  }

  if (!certificate) {
    const { certificate: currentCertificate } = yield select(
      getCurrentCertificate
    );
    certificate = currentCertificate;
  }

  try {
    const signature = yield call(crypto.signBytearray, data, certificate, true);
    yield put({
      type: `${actions.signedByteArray} ${meta.subject}`,
      payload: signature,
      meta,
    });
  } catch (error) {
    const errorContent = CadesUtil.prepareError(error);

    yield call(toast.error, errorContent);
    yield put({
      type: `${actions.error} ${meta.subject}`,
    });
  }
}

export function* signContent(content) {
  const { certificate } = yield select(getCurrentCertificate);

  return yield call(Crypto.signBytearray, content, certificate);
}

export const saga = function* watchSignCalls() {
  yield takeEvery(actions.signByteArray, signByteArraySaga);
  yield takeEvery(startSetCertificates, loadSaga);
  yield takeEvery(startSetCurrentCertificate, setCurrentCertificateSaga);
};
