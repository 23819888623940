import React, { Fragment, useCallback, useMemo, useState } from 'react';

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Link } from '@crpt-ui/core';

import { Modal } from '../Modal/Modal';
import { SignatureDescriptionView } from '../SignatureDescription/SignatureDescription.view';

export const SignatureModal = ({ certificate }) => {
  const [isOpen, setOpenModal] = useState(false);

  const onClose = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const onOpen = useCallback(() => {
    setOpenModal(true);
  }, [setOpenModal]);

  const isShowSignature = useMemo(
    () => certificate !== undefined,
    [certificate]
  );

  if (!isShowSignature) {
    return null;
  }

  return (
    <Fragment>
      <Link onClick={onOpen}>Электронная подпись</Link>

      <Modal
        actions={[
          {
            label: 'Закрыть',
            callback: onClose,
            variant: 'outlined',
          },
        ]}
        open={isOpen}
        title="Электронная подпись"
        onClose={onClose}
      >
        <Box ml={-4} mr={-4}>
          <SignatureDescriptionView certificate={certificate} />
        </Box>
      </Modal>
    </Fragment>
  );
};

SignatureModal.propTypes = {
  certificate: PropTypes.object.isRequired,
};
