import React from 'react';

import { PrimitiveStatus } from '../../../../common_components/PrimitiveStatus/PrimitiveStatus';
import { getValue } from '../../../../common_components/Document/Document.utils';
import {
  documentStatusHEXColorsMap,
  documentStatusIconsMap,
} from '../../DocumentsList/DocumentsList.constants';
import { getPreparedStatus } from '../../ClosingDocument/ClosingDocument.utils';

export const makeStatusCell =
  () =>
  ({ cell: { value }, row: { values } }) => {
    return React.createElement(
      PrimitiveStatus,
      {
        color: getValue(documentStatusHEXColorsMap, value),
        icon: getValue(documentStatusIconsMap, value),
      },
      getPreparedStatus(values)
    );
  };
