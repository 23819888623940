import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import * as Icons from '@crpt-ui/icons';

import { Currency } from '../../../common_components/Currency/Currency';

import { useBalanceCellStyles } from './BalanceCell.styled';

export const BalanceCell = ({
  cell: {
    value: balance,
    row: {
      values: { minBalance },
    },
  },
}) => {
  const classes = useBalanceCellStyles();

  /**
   * An error is shown if the balance is less than the minimum balance
   *
   * @returns {boolean}
   */
  const isError = useMemo(() => minBalance >= balance, [balance, minBalance]);

  return (
    <div className={clsx(classes.wrapper, { [classes.error]: isError })}>
      <div className={classes.label}>
        <Currency>{balance}</Currency>
      </div>
      {isError && <Icons.Error />}
    </div>
  );
};

BalanceCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.number,
    row: PropTypes.shape({
      values: PropTypes.shape({
        minBalance: PropTypes.number,
      }),
    }),
  }),
};
