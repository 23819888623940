import React, { useMemo } from 'react';

import * as _ from 'lodash';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';

import { getAvailableProductGroups } from '../_Profile/Company/ducks/Company.selectors';
import {
  getProductGroupsDetails,
  getUserInfo,
} from '../../common_components/Auth/ducks/Auth.selectors';

import { productGroupsDetails } from './dashboard.data';
import { DashboardTileConnected } from './dashboard-tile/dashboard-tile';

const DashboardComp = ({ productGroups, pgInProfile, userInfo }) => {
  const { showDashboard } = userInfo;
  const items = useMemo(() => {
    const pgIds = pgInProfile.map((item) => item.name);
    const sortedPg = productGroups.filter((item) =>
      [...pgIds].includes(item.id)
    );

    const allPg = productGroupsDetails
      .map((productGroupDetail) => {
        const productGroup = _.find(sortedPg, {
          id: productGroupDetail.id,
        });

        return {
          ...productGroupDetail,
          name: _.get(productGroup, 'name'),
          status: _.get(productGroup, 'status'),
          productGroupStatus: _.get(productGroup, 'productGroupStatus'),
          disabled: _.get(productGroup, 'disabled', true),
        };
      })
      .filter((productGroup) => !!productGroup.productGroupStatus);

    const activePg = allPg
      .filter((pg) => !pg.disabled)
      .sort((a, b) => (a.startDate <= b.startDate ? -1 : 1));

    const disabledPg = allPg
      .filter((pg) => pg.disabled)
      .sort((a, b) => (a.startDate <= b.startDate ? -1 : 1));

    return _.concat(activePg, disabledPg);
  }, [productGroups, pgInProfile]);

  return (
    <Grid container spacing={2}>
      {showDashboard
        ? items.map((item) => (
            <Grid item key={item.id} lg={4} md={6} xl={3} xs={12}>
              <DashboardTileConnected {...item} />
            </Grid>
          ))
        : null}
    </Grid>
  );
};

DashboardComp.propTypes = {
  productGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  productGroups: getAvailableProductGroups(state),
  pgInProfile: getProductGroupsDetails(state),
  userInfo: getUserInfo(state),
});

export const Dashboard = connect(mapStateToProps)(DashboardComp);
