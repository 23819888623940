import { createActions } from 'redux-actions';

/**
 * Users list actions
 */
export const {
  fetchUsersList,
  setUsersList,
  putUserRole,
  fetchBlockUser,
  setChangeStatus,
} = createActions(
  {
    FETCH_USERS_LIST: () => {},
    SET_USERS_LIST: (payload) => payload,
    PUT_USER_ROLE: (payload) => payload,
    FETCH_BLOCK_USER: (payload) => payload,
    SET_CHANGE_STATUS: (payload) => payload,
  },
  {
    prefix: 'USER_LIST',
  }
);
