import { createStyles } from '@material-ui/core';

export const styles = () =>
  createStyles({
    parkedPageContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 32,
      height: '100%',
      justifyContent:'center'
    },
    parkedPageDescription: {
      margin: '0px'
    },
    banner: {
      position: 'fixed',
      top: 16,
      zIndex: 10000,
      left: '50%',
      transform: 'translateX(-50%)'
    }
  });
