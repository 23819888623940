import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

export const Wrapper = styled.div`
  font-size: 24px;
  color: #52535a;
  text-align: center;
  padding: 20px 0 40px 0;
`;

export const useStyles = makeStyles({
  wrapper: {
    fontSize: 24,
    color: '#52535a',
    textAlign: 'center',
    padding: '20px 0 40px 0',
  },
});
