import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Link as CoreLink } from '@crpt-ui/core';

export const Link = ({ children, to, router, ...restProps }) => {
  let additionsProps = {};

  if (router) {
    additionsProps = {
      to,
      component: RouterLink,
    };
  }

  return React.createElement(
    CoreLink,
    {
      ...additionsProps,
      ...restProps,
    },
    children
  );
};

Link.defaultProps = {
  router: true,
};

Link.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]),
  to: PropTypes.string,
  router: PropTypes.bool,
};
