import { makeStyles } from '@material-ui/core';

export const useClasses = makeStyles({
  tooltip: {
    width: '260px',
    textAlign: 'center',
    boxSizing: 'border-box',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
  },
});
