import moment from 'moment';

import {
  ORGANIZATION_TYPE_NAMES,
  participantStatusTitle,
} from './ParticipantDetails.constants';

export const uotStatuses = {
  0: 'Добавлена',
  1: 'В процессе активации',
  5: 'Активна',
};

export const parseParticipantData = (data) => ({
  ...data,
  organizationFormName: ORGANIZATION_TYPE_NAMES[data.organizationForm],
  statusName: participantStatusTitle[data.status],
  omsId: data.omsId || '—',
  legalAddress: data.legalAddress || '—',
  physicalPerson: {
    socialCardId: data?.physicalPerson?.socialCardId || '',
    fio: data?.physicalPerson?.fio || '',
  },
  registrationDate: moment(data.registrationDate).format('DD.MM.YYYY'),
  productGroupsAndRoles: data.productGroupsAndRoles.map((el) => ({
    code: el.code,
    startDate: el.startDate,
    types: el.types,
    license: el.license?.replace('-', '—') || '—',
    contractId:
      typeof el.contractId === 'number'
        ? el.contractId
        : el.contractId?.replace('-', '—') || '—',
    status: el.status?.replace('-', '—') || '—',
    statusName: uotStatuses[el.status?.replace('-', '—')] || '—',
  })),
});

export const parseEditDto = (data) => ({
  id: data.id,
  inn: data.inn,
  shortName: data.name,
  fullName: data.fullName,
  status: data.status,
  head: data.head,
  legalAddress: data.legalAddress || '—',
  actualAddress: data.actualAddress,
  email: data.email,
  phone: data.phone,
  afp: data.afp,
  organizationForm: data.organisationForm,
  dissolved: data.dissolved,
  roles: data.roles,
  omsId: data.omsId || '—',
  productGroupsAndRoles: data.productGroupInfo.map((el) => ({
    code: el.productGroup.code,
    startDate: el.productGroup.startDate,
    types: el.orgTypes,
    license: el.license?.replace('-', '—') || '—',
    contractId:
      typeof el.contractId === 'number'
        ? el.contractId
        : el.contractId?.replace('-', '—') || '—',
    status: el.organisationPgStatus?.replace('-', '—') || '—',
    statusName: uotStatuses[el.organisationPgStatus?.replace('-', '—')] || '—',
  })),
  foreignOrganisation: data.foreignOrganisation,
  needCheckProfile: data.needCheckProfile,
  statusName: participantStatusTitle[data.status],
  registrationDate: moment(data.registrationDate).format('DD.MM.YYYY'),
  physicalPerson: {
    fio: data?.contact?.fio,
    socialCardId: data?.contact?.socialCardId,
  },
});
