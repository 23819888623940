import React, { useCallback } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';

import { history } from '../../store';

import { ParticipantCreateView } from './create-participant-view';
import { create } from './ducks/CreateParticipant.actions';
import { loading } from './ducks/CreateParticipant.selectors';

type CreateParticipantComp = {
  create: (obj: Record<string, unknown>) => void;
  loading: boolean;
  location: {
    hash: string;
    key: string;
    pathname: string;
    search: string;
    state?: unknown;
  };
};

const CreateParticipantComp: React.FC<CreateParticipantComp> = ({
  create,
  location,
  loading,
}) => {
  const onSubmit = useCallback(
    (values) => {
      create(values);
    },
    [create]
  );

  const onCancel = useCallback(() => {
    history.push('/participants/list');
  }, []);

  return React.createElement(ParticipantCreateView, {
    onSubmit,
    onCancel,
    location,
    loading,
  });
};

const mapState = (state) => {
  return {
    loading: loading(state),
  };
};

const mapDispatchToProps = {
  create,
};

export const CreateParticipant = compose(
  withRouter,
  withTheme,
  connect(mapState, mapDispatchToProps)
)(CreateParticipantComp);
