import { call, put, select } from 'redux-saga/effects';
import { get } from 'lodash';

import { Api } from '../../../../../common_components/Api/Api';
import * as selectors from '../SignDocuments.selectors';
import * as actions from '../SignDocuments.actions';

import { getSDContent } from './SDContent';

export function* fetchSDDocuments(params) {
  const requestPayload = {
    url: '/crm-api/documents',
    timeout: 10000,
    params,
  };

  return yield call(Api.request, requestPayload, {
    preloading: false,
  });
}

/**
 * Получить документы из SD по pid и статусу.
 */
export function* getSDDocuments({ status, group, presign }) {
  const [success] = yield call(fetchSDDocuments, {
    group: `ALL,${group}`,
    presign,
  });

  return get(success, 'data.response', []).filter(
    (d) => d.status === status || d.doctype === null
  );
}

export function* fetchSDDocumentsForSigning() {
  const UNSIGNED = 1;
  const { id } = yield select(selectors.getSignDocumentsInfo);

  const unsignedDocuments = yield call(getSDDocuments, {
    status: UNSIGNED,
    group: id,
    presign: 'y',
  });
  const preparedDocuments = yield call(getSDContent, unsignedDocuments);

  yield put(actions.loaded(preparedDocuments));
}
