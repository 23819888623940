import React from 'react';
import { Icon } from '@crpt-ui/core';
import { Grid } from '@material-ui/core';

import ActionButton from '../ActionButton';
import type { ButtonProps } from '@crpt-ui/core';

export type ZoomProps = {
  onZoomIn: ButtonProps['onClick'];
  onZoomOut: ButtonProps['onClick'];
};

const Zoom: React.FC<ZoomProps> = ({ onZoomIn, onZoomOut }) => {
  return (
    <Grid alignItems="center" container spacing={1}>
      <Grid item>
        <ActionButton
          icon={<Icon name="ZoomIn" />}
          onClick={onZoomIn}
          title="Увеличить"
          variant="text"
        />
      </Grid>
      <Grid item>
        <ActionButton
          icon={<Icon name="ZoomOut" />}
          onClick={onZoomOut}
          title="Уменьшить"
          variant="text"
        />
      </Grid>
    </Grid>
  );
};

export default Zoom;
