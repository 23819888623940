import { get } from 'lodash';

import {
  documentOutgoingStatusCaption,
  documentStatusColor,
  documentStatusIcon,
} from '../../../constants/documentStatuses';

export const getPrepareDocumentStatus = (status) => ({
  label: get(documentOutgoingStatusCaption, status),
  icon: get(documentStatusIcon, status),
  color: get(documentStatusColor, status),
});
