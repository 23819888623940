import { combineReducers } from 'redux';
import { get, isFunction, isObject, keys, set, unset } from 'lodash';
import { connectRouter } from 'connected-react-router';

import { reducer as Route } from '@crpt/shared/components/RouteController/ducks/RouteController.store';

export const getReducers = (order, reducers, config, history) => {
  const reducersTree = {};
  const map = {};

  order.forEach((project) => {
    const obj = reducers.find((r) => r.id === project);

    obj.data.keys().forEach((f) => {
      const { reducer } = obj.data(f);
      const temp = f.split('/');
      temp.shift();
      temp.pop();
      let key = temp.filter((k) => k !== 'ducks').join('.');

      if (map[key] && map[key] !== obj.id) {
        const keyToDelete = `${map[key]}.${key}`;
        unset(reducersTree, keyToDelete);
      }

      map[key] = obj.id;

      if (obj.id) {
        key = `${obj.id}.${key}`;
      }
      const current = get(reducersTree, key, {});
      set(reducersTree, key, { ...reducer, ...current });
    });
  });

  function getMapped(obj, first = false) {
    const reducer = {};

    keys(obj).forEach((key) => {
      const newKey = key.replace(/^_/, '');
      if (isFunction(obj[key])) {
        reducer[newKey] = obj[key];
      } else if (isObject(obj[key])) {
        reducer[newKey] = getMapped(obj[key]);
      }
    });

    if (first) {
      return reducer;
    }
    return combineReducers({ ...reducer });
  }

  return combineReducers({
    ...getMapped(reducersTree, true),
    Route,
    router: connectRouter(history),
    order: () => order,
    config: () => config,
  });
};
