import { createSelector } from 'reselect';

import { formatToTimezone } from '@crpt/shared/utils/dateUtils';

import { PRODUCT_GROUP } from '../../../constants/ProductGroup';
import { getUserInfo } from '../../Auth/ducks/Auth.selectors';

const getMilkNotificationDialogState = (state) => state.COMMON.MilkNotification;

export const getOpenMilkNotificationDialog = createSelector(
  [getMilkNotificationDialogState, getUserInfo],
  (state, userInfo) =>
    state.open &&
    Boolean(
      userInfo.product_group_info.find(
        (item) => item.name === PRODUCT_GROUP.MILK.code
      )
    ) &&
    formatToTimezone({
      date: '2021-06-01',
    }) > new Date()
);
