import React from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { MRT_Cell } from 'material-react-table';
import { TFunction } from 'i18next';

import css from './index.module.scss';

export const getColumns = ({ t }: { t: TFunction }) => [
  {
    header: t('Орган исполнительной власти'),
    id: 'fullName',
    Cell: ({ cell }: { cell: MRT_Cell<Record<string, unknown>> }) => (
      <Stack>
        <Link href={`/oiv/${cell.row.original.innOiv}`}>
          <span className={css.link}>
            {cell.row.original.fullName as string}
          </span>
        </Link>
      </Stack>
    ),
    enableSorting: false,
  },
  {
    header: t('ИНН'),
    id: 'inn',
    accessorKey: 'innOiv',
    enableSorting: false,
  },
  {
    header: t('Статус ОИВ'),
    id: 'status',
    Cell: ({ cell }: { cell: MRT_Cell<Record<string, unknown>> }) => (
      <Stack>
        <Typography>
          {cell.row.original.status === 'REGISTERED'
            ? (t('Активен') as string)
            : ('-' as string)}
        </Typography>
      </Stack>
    ),
    enableSorting: false,
  },
];
