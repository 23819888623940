/* eslint-disable react/jsx-key */
/* eslint-disable no-shadow */
import { withStyles } from '@material-ui/core';
import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import Autosizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { TableCell, TableRow } from './components';
import DatatableFiltersPlaceholder from './DatatableFiltersPlaceholder';
import DatatableHeaderCell from './DatatableHeaderCell';
import DatatableRow from './DatatableRow';

// import InfiniteLoader from 'react-window-infinite-loader';

const DatatableView = ({
  instance,
  classes,
  onRowClick,
  height,
  onFiltersReset,
  isScrollbarAvailable = false,
}) => {
  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    state: { filters },
  } = instance;

  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const [isItemsRendered, setItemsIsRendered] = useState(false);
  const listRef = useRef(null);

  const onItemsRendered = useCallback(() => {
    setItemsIsRendered(true);
  }, [setItemsIsRendered]);

  useEffect(() => {
    if (isScrollbarAvailable && rows.length > 0 && listRef.current && isItemsRendered) {

      setScrollbarWidth(
        listRef.current.offsetWidth - listRef.current.clientWidth
      );
    }
  }, [rows, isScrollbarAvailable, listRef.current, isItemsRendered, setScrollbarWidth]);
  const getTableBodyHeight = useCallback(() => {
    const headerHeight = 64;

    if (isScrollbarAvailable) {
      return `calc(100% - ${headerHeight}px)`;
    }

    return height ? height - headerHeight : 640;
  }, [height, isScrollbarAvailable]);

  const getTableColumnGrow = useCallback((column) => {
    if (['toggleRowSelected', 'rowActions'].includes(column.id)) {
      return 0;
    }
    return column.width === 150 ? 1 : 0;
  }, []);

  const countLastHeaderCellWidth = useCallback(
    (column) => {
      return column.width + scrollbarWidth;
    },
    [scrollbarWidth]
  );

  const isFiltersPlaceholderVisible = useMemo(
    () => filters?.length && rows.length === 0,
    [filters, rows]
  );

  const handleFiltersReset = useCallback(
    (event) => {
      instance.setAllFilters([]);

      if (typeof onFiltersReset === 'function') {
        onFiltersReset(event);
      }
    },
    [onFiltersReset, instance]
  );

  const renderRow = ({ index, style }) => {
    const row = rows[index];

    if (row) {
      prepareRow(row);

      return (
        <DatatableRow
          {...row.getRowProps({
            style: style,
          })}
          key={index}
          onClick={onRowClick}
          row={row}
        >
          {row.cells.map((cell, key) => (
            <TableCell
              key={key}
              {...cell.getCellProps({
                style: {
                  flexGrow: getTableColumnGrow(cell.column),
                },
              })}
            >
              {cell.render('Cell')}
            </TableCell>
          ))}
        </DatatableRow>
      );
    }

    return null;
  };

  return (
    <div
      className={classes.root}
      style={{
        height: isScrollbarAvailable && '100%',
      }}
    >
      <div
        {...getTableProps({
          style: {
            height: isScrollbarAvailable ? '100%' : height,
          },
        })}
      >
        <div>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <DatatableHeaderCell
                  {...column.getHeaderProps({
                    style: {
                      flexGrow: getTableColumnGrow(column),
                      ...(isScrollbarAvailable &&
                        headerGroup.headers.length - 1 === index && {
                          width: countLastHeaderCellWidth(column),
                        }),
                    },
                  })}
                  column={column}
                />
              ))}
            </TableRow>
          ))}
        </div>
        <div
          {...getTableBodyProps({
            style: {
              height: getTableBodyHeight(),
            },
          })}
        >
          {isFiltersPlaceholderVisible ? (
            <DatatableFiltersPlaceholder
              fillAvailableSpace={isScrollbarAvailable}
              onFiltersReset={handleFiltersReset}
            />
          ) : null}
          <Autosizer>
            {({ width, height }) => (
              <FixedSizeList
                className={!isScrollbarAvailable && classes.body}
                height={height}
                itemCount={rows.length}
                itemSize={64}
                outerRef={listRef}
                onItemsRendered={onItemsRendered}
                width={width}
              >
                {renderRow}
              </FixedSizeList>
            )}
          </Autosizer>
        </div>
      </div>
    </div>
  );
};

export default withStyles({
  root: {
    overflowX: 'auto',
    overflowY: 'hidden',
    position: 'relative',
    borderTop: '1px solid #f2f2f2',
  },
  body: {
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
})(DatatableView);
