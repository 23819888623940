import React from 'react';

import { Grid, Typography } from '@mui/material';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { TextFieldFinalFormWrapper } from '@ibox/ui';

import {
  formatSocialCard,
  formatPhone,
  required,
  validateAmPhone,
  validateEmail,
  validateMaxLength,
  validateSocialCardNumber,
} from '../../../../common_components/Form/utils/validations';
import { translate } from '../../../Translate/Translate';

type ContactsInfoProps = {
  disabled?: boolean;
  isEdit?: boolean;
  organizationForm: string;
  isCreation: boolean;
};

export const ContactsInfo: React.FC<ContactsInfoProps> = ({
  disabled,
  isEdit,
  organizationForm,
  isCreation,
}) => {
  const { t } = useTranslation();
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h6">{translate('Контактные данные')}</Typography>
      </Grid>
      <Grid item>
        {organizationForm === 'UL' ? (
          <Field
            fullWidth
            component={TextFieldFinalFormWrapper}
            defaultValue={disabled ? '—' : ''}
            disabled={disabled}
            label={t('Юридический адрес')}
            multiline={isEdit || isCreation}
            name="legalAddress"
            required={!disabled}
            validate={required}
          />
        ) : (
          <Field
            fullWidth
            component={TextFieldFinalFormWrapper}
            defaultValue={disabled ? '—' : ''}
            disabled={isEdit ? organizationForm !== 'UL' : disabled}
            label={t('Юридический адрес')}
            multiline={isEdit || isCreation}
            name="legalAddress"
            required={!disabled}
          />
        )}
      </Grid>
      <Grid item>
        <Field
          fullWidth
          component={TextFieldFinalFormWrapper}
          defaultValue={disabled ? '—' : ''}
          disabled={disabled}
          label={t('Фактический адрес')}
          multiline={isEdit || isCreation}
          name="actualAddress"
          required={!disabled}
          validate={validateMaxLength(255)}
        />
      </Grid>
      <Grid item>
        <Field
          fullWidth
          component={TextFieldFinalFormWrapper}
          defaultValue={disabled ? '—' : ''}
          disabled={disabled}
          label={t('Контактное лицо')}
          multiline={isEdit || isCreation}
          name="physicalPerson.fio"
          required={!disabled}
        />
      </Grid>
      <Grid item>
        <Field
          fullWidth
          component={TextFieldFinalFormWrapper}
          defaultValue={disabled ? '—' : ''}
          disabled={disabled}
          format={formatSocialCard}
          inputProps={{
            maxLength: 10,
          }}
          label={t('Номер социальной карты')}
          name="physicalPerson.socialCardId"
          required={!disabled}
          validate={validateSocialCardNumber}
        />
      </Grid>
      <Grid item>
        <Field
          fullWidth
          component={TextFieldFinalFormWrapper}
          defaultValue={disabled ? '—' : ''}
          disabled={disabled}
          label={t('email')}
          name="email"
          required={!disabled}
          validate={validateEmail}
        />
      </Grid>
      <Grid item>
        <Field
          fullWidth
          component={TextFieldFinalFormWrapper}
          defaultValue={disabled ? '—' : ''}
          disabled={disabled}
          format={formatPhone}
          label={t('Телефон')}
          name="phone"
          required={!disabled}
          validate={validateAmPhone}
        />
      </Grid>
    </Grid>
  );
};

ContactsInfo.defaultProps = {
  disabled: true,
};
