import { createActions } from 'redux-actions';

export const {
  startPage: {
    addNewFieldParticipantGs1,
    fetchStartPageData,
    removeAllParticipantGs1,
    removeParticipantGs1,
    resetSkipGs1Step,
    setEdoId,
    setEdoList,
    setFieldParticipantGs1,
    setFinishStep,
    setLoading,
    setNewFieldParticipantGs1,
    setNextStep,
    setParticipantDataGs1,
    setPreviousStep,
    setProductGroupList,
    setSelectedEdo,
    setSelectedProductGroup,
    setSkipGs1Step,
    setStep,
    skipParticipantGs1,
  },
} = createActions({
  START_PAGE: {
    ADD_NEW_FIELD_PARTICIPANT_GS1: (field) => field,
    FETCH_START_PAGE_DATA: () => {},
    REMOVE_All_PARTICIPANT_GS1: () => [],
    REMOVE_PARTICIPANT_GS1: (index) => index,
    RESET_SKIP_GS1_STEP: () => {},
    SET_EDO_ID: (id) => id,
    SET_EDO_LIST: (list) => list,
    SET_FIELD_PARTICIPANT_GS1: (fields) => fields,
    SET_FINISH_STEP: () => {},
    SET_LOADING: (loading) => loading,
    SET_NEW_FIELD_PARTICIPANT_GS1: () => true,
    SET_NEXT_STEP: () => true,
    SET_PARTICIPANT_DATA_GS1: (data) => data,
    SET_PREVIOUS_STEP: () => false,
    SET_PRODUCT_GROUP_LIST: (list) => list,
    SET_SELECTED_EDO: (index) => index,
    SET_SELECTED_PRODUCT_GROUP: (index) => index,
    SET_SKIP_GS1_STEP: () => true,
    SET_STEP: (step) => step,
    SKIP_PARTICIPANT_GS1: () => false,
  },
});
