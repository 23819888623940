import { handleActions } from 'redux-actions';

import {
  setDocsForNotification,
  setLoading,
  setSubmitting,
} from './Settings.actions';

const initialValues = handleActions(
  {
    [setDocsForNotification]: (_, { payload }) => payload,
  },
  {}
);

const submitting = handleActions(
  {
    [setSubmitting]: (_, { payload }) => payload,
  },
  false
);

const loading = handleActions(
  {
    [setLoading]: (_, { payload }) => payload,
  },
  true
);

export const reducer = {
  initialValues,
  loading,
  submitting,
};
