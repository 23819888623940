import React, { useCallback } from 'react';

import { connect } from 'react-redux';

import { closeDocumentTemplatesDialog } from './ducks/DocumentTemplatesDialog.actions';
import { getOpen } from './ducks/DocumentTemplatesDialog.selectors';
import { DocumentTemplatesDialogView } from './DocumentTemplatesDialog.view';

const DocumentTemplatesDialog = ({ open, closeDocumentTemplatesDialog }) => {
  const onClose = useCallback(() => {
    closeDocumentTemplatesDialog();
  }, [closeDocumentTemplatesDialog]);

  return React.createElement(DocumentTemplatesDialogView, { open, onClose });
};

const mapStateToProps = (state) => ({
  open: getOpen(state),
});

const mapDispatchToProps = {
  closeDocumentTemplatesDialog,
};

export const DocumentTemplatesDialogConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentTemplatesDialog);
