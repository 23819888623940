import { FormRenderProps } from 'react-final-form';
import { DefaultFormValues } from '../AcsUnloads.types';

export enum FieldIds {
  SENDER_INN = 'senderInn',
  RECEIVER_INN = 'receiverInn',
  STATUS_CHECKED_OK = 'CHECKED_OK',
  STATUS_CHECKED_NOT_OK = 'CHECKED_NOT_OK',
  STATUS_CANCELLED = 'CANCELLED',
  STATUS = 'status',
}

export enum MetaFieldIds {
  IS_OPERATOR = 'isOperator',
}

export interface FormValues extends DefaultFormValues {
  meta: {
    [MetaFieldIds.IS_OPERATOR]: boolean;
  };
  [FieldIds.SENDER_INN]?: string;
  [FieldIds.RECEIVER_INN]?: string;
  [FieldIds.STATUS]: {
    [FieldIds.STATUS_CHECKED_OK]?: boolean;
    [FieldIds.STATUS_CHECKED_NOT_OK]?: boolean;
    [FieldIds.STATUS_CANCELLED]?: boolean;
  };
}

export type FormErrors = FormRenderProps['errors'];
