import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Button, Table } from '@ibox/ui';

import { HeaderActionsWrap } from '../../ParticipantDetails.styled';
import { BlockUserDialog } from '../../../BlockUserDialog/BlockUserDialog';
import { getCookie } from '../../../../utils/cookieHelpers';

import { getColumns } from './users.constants';

type UsersViewProp = {
  isLoading: boolean;
  isAdmin: boolean;
  results: {}[];
  onPageChange:
    | ((page: number, offset?: number | undefined) => void)
    | undefined;
  onOpenAddingUserDialog: () => void;
  currentPage: number;
  openBlockUserDialog: (value: unknown) => void;
  setSelectedUser: (value: unknown) => void;
  companyId: number;
  last: boolean;
};

export const UsersView = ({
  isLoading,
  results,
  isAdmin,
  onOpenAddingUserDialog,
  onPageChange,
  currentPage,
  openBlockUserDialog,
  setSelectedUser,
  companyId,
  last,
}: UsersViewProp) => {
  const { t } = useTranslation();
  const language = getCookie('language') || 'hy';

  const columns = useMemo(
    () =>
      getColumns({
        t,
        results,
        openBlockUserDialog,
        setSelectedUser,
        companyId,
      }),
    [t, results, openBlockUserDialog, setSelectedUser, companyId]
  );

  return (
    <>
      <BlockUserDialog mode="uotView" />
      {isAdmin && (
        <HeaderActionsWrap>
          <Button
            className="whiteColor"
            variant="contained"
            onClick={onOpenAddingUserDialog}
          >
            {t('Добавить пользователя')}
          </Button>
        </HeaderActionsWrap>
      )}
      <Box p={3}>
        <Table
          columns={columns}
          currentPage={currentPage}
          data={results}
          isLastPage={last}
          isLoading={isLoading}
          labelPagination={t('Показать еще')}
          locale={language}
          onPageChange={onPageChange}
        />
      </Box>
    </>
  );
};
