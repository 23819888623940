import { createSelector } from 'reselect';

const getSupportIncidentDialogBase = (state) =>
  state.Support.SupportIncidentDialog;

export const getSupportIncidentDialogData = createSelector(
  [getSupportIncidentDialogBase],
  (state) => state.data
);
export const getSupportIncidentDialogOpen = createSelector(
  [getSupportIncidentDialogBase],
  (state) => state.open
);
