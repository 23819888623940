import React, { useEffect, useMemo } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { clearDocumentInfo } from '../ducks/ClosingDocument.actions';

import { ViewClosingDocumentView } from './ViewClosingDocument.view';
import {
  clearClosingDocument,
  fetchClosingDocument,
} from './ducks/ViewDocument.actions';
import {
  getClosingDocumentContent,
  getLoadingContent,
} from './ducks/ViewDocument.selectors';

const ViewClosingDocument = ({
  fetchClosingDocument,
  clearClosingDocument,
  clearDocumentInfo,
  match,
  documentContent,
  isLoading,
}) => {
  const documentId = useMemo(() => match.params.id, [match.params.id]);

  /**
   * Fetching the document content
   */
  useEffect(() => {
    fetchClosingDocument(documentId);

    return () => {
      clearClosingDocument();
      clearDocumentInfo();
    };
  }, [
    fetchClosingDocument,
    clearClosingDocument,
    documentId,
    clearDocumentInfo,
  ]);

  return React.createElement(ViewClosingDocumentView, {
    documentContent,
    isLoading,
  });
};

const mapStateToProps = (state) => ({
  documentContent: getClosingDocumentContent(state),
  isLoading: getLoadingContent(state),
});

const mapDispatchToProps = {
  fetchClosingDocument,
  clearClosingDocument,
  clearDocumentInfo,
};

export const ViewClosingDocumentConnected = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewClosingDocument)
);
