import { get } from 'lodash';

export const getValue = (valueEnum, key) => {
  return get(valueEnum, key, get(valueEnum, 'default'));
};

/**
 * Returns the file name from headers
 * @param {{}} response
 * @returns {string} - File name
 */
export const getFilename = (response) => {
  let result;

  const contentDisposition = get(response, 'headers[content-disposition]', '');
  const matchUtf = contentDisposition.match(/filename\*=UTF-8''(.+)/);
  const match = contentDisposition.match(/filename="(.+?)"/);

  if (matchUtf) {
    result = decodeURIComponent(matchUtf[1]);
  } else if (match) {
    result = decodeURIComponent(match[1]);
  }

  return result;
};

/**
 * Saving file
 * @param {*} content
 * @param {string} filename
 * @param {string} type
 * @returns {Promise<void>}
 */
export const saveFile = async ({ content, filename, type }) => {
  // for IE 11
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const csvData = new Blob([content], { type });
    window.navigator.msSaveOrOpenBlob(
      csvData,
      decodeURI(filename).replace(/\+/g, ' ')
    );

    return;
  }

  const url = window.URL.createObjectURL(new Blob([content], { type }));
  const link = document.createElement('a');

  link.href = url;
  link.rel = 'noopener';
  link.setAttribute('download', decodeURI(filename).replace(/\+/g, ' '));
  document.body.appendChild(link);
  link.click();

  setTimeout(() => {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }, 100);
};
