import { createSelector } from 'reselect';
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';

import { getConfig } from '../../App/App.selectors';
import { getCompanyStatus } from '../../../components/_Profile/Company/ducks/Company.selectors';
import { isAdmin, isOIV, isOperator } from '../../Auth/ducks/Auth.selectors';

export const items = createSelector(
  [getConfig, getCompanyStatus, isOperator, isOIV, isAdmin],
  (
    config: { menuItems: any[] },
    status: any,
    isOperator: unknown,
    isOIV: unknown
  ) => {
    return config.menuItems
      .filter((item) => _.includes(item.applicableStatuses, status))
      .filter((item) => {
        if (item.route === '/accounts') {
          return !isOperator;
        }
        if (item.route === '/participants/list') {
          return isOperator || isOIV;
        }
        if (item.route === '/oiv') {
          return isOperator;
        }
        return true;
      });
  }
);
