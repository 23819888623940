import React, { useEffect, useMemo } from 'react';

import { connect } from 'react-redux';

import * as preloaderSelectors from '../../../common_components/Preloader/ducks/Preloader.selectors';
import * as companySelectors from '../Company/ducks/Company.selectors';
import { getUserInfo } from '../../../common_components/Auth/ducks/Auth.selectors';
import * as authSelectors from '../../../common_components/Auth/ducks/Auth.selectors';
import { openBlockUserDialog } from '../../BlockUserDialog/ducks/BlockUserDialog.actions';
import { Data } from '../Company/ducks/Company.store';
import { UserInfo } from '../../../common_components/Auth/ducks/Auth.store';
import { UserStatusEnum } from '../../../constants';

import { fetchUsersList, putUserRole } from './ducks/UserList.actions';
import * as userListSelectors from './ducks/UserList.selectors';
import { ColumnsItem } from './UserList.utils';
import { UserListView } from './user-list-view';
import { UserListItem } from './ducks/UserList.store';
import {
  columns as initialColumn,
  useUsersListColumns,
} from './user-list.constants';

type UserListCompProps = {
  status: string;
  isInActive: boolean;
  isLoading: boolean;
  putUserRole: () => void;
  openBlockUserDialog: () => void;
  fetchUsersList: () => void;
  companyData: Data;
  userInfo: UserInfo;
  data: UserListItem[];
};

const UserListComp: React.FC<UserListCompProps> = ({
  fetchUsersList,
  status,
  data,
  isLoading,
  companyData,
  userInfo,
  isInActive,
  openBlockUserDialog,
}) => {
  const columns: ColumnsItem[] = useMemo(
    () =>
      status === UserStatusEnum.REGISTERED
        ? useUsersListColumns()
        : initialColumn,
    [status]
  );

  useEffect(() => {
    fetchUsersList();
  }, [fetchUsersList]);

  return React.createElement(UserListView, {
    data,
    columns,
    isLoading,
    companyData,
    userInfo,
    isInActive,
    openBlockUserDialog,
  });
};

const mapStateToProps = (state) => ({
  data: userListSelectors.data(state),
  isLoading: preloaderSelectors.loading(state),
  status: companySelectors.getCompanyStatus(state),
  isRegistered: companySelectors.getRegistered(state),
  companyData: companySelectors.getCompanyData(state),
  userInfo: getUserInfo(state),
  isInActive: authSelectors.isInActive(state),
});

const mapDispatchToProps = {
  fetchUsersList,
  putUserRole,
  openBlockUserDialog,
};

export const UserList = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserListComp);
