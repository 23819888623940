import { translate } from '../Translate/Translate';

export const getTabs = (inn, t) => [
  {
    value: 0,
    label: t('Общая информация'),
    href: `/participants/list/${inn}/basic-info`,
  },
  {
    value: 1,
    label: t('Документы'),
    href: `/participants/list/${inn}/documents`,
  },
  {
    value: 2,
    label: t('Чеки'),
    href: `/participants/list/${inn}/receipts`,
  },
  {
    value: 3,
    label: t('Пользователи'),
    href: `/participants/list/${inn}/users`,
  },
];

export const ORGANIZATION_TYPES = {
  UL: 'UL',
  IP: 'IP',
};

export const ORGANIZATION_TYPE_NAMES = {
  [ORGANIZATION_TYPES.UL]: 'Организация',
  [ORGANIZATION_TYPES.IP]: 'Индивидуальный предприниматель',
};

export const participantStatus = {
  NOT_REGISTERED: 'NOT_REGISTERED',
  REGISTERED: 'REGISTERED',
  PRE_REGISTERED: 'PRE_REGISTERED',
  PRE_REGISTERED_TRADER: 'PRE_REGISTERED_TRADER',
  PRE_REGISTERED_ISSUER: 'PRE_REGISTERED_ISSUER',
  REMOVED: 'REMOVED',
  RESTORED: 'RESTORED',
  BLOCKED: 'BLOCKED',
  REJECTED: 'REJECTED',
};

export const participantStatusTitle = {
  [participantStatus.NOT_REGISTERED]: 'Незарегистрирован',
  [participantStatus.REGISTERED]: 'Зарегистрирован',
  [participantStatus.PRE_REGISTERED]: 'В процессе регистрации',
  [participantStatus.PRE_REGISTERED_TRADER]: 'В процессе регистрации',
  [participantStatus.PRE_REGISTERED_ISSUER]: 'В процессе регистрации',
  [participantStatus.REMOVED]: 'Удален',
  [participantStatus.RESTORED]: 'Восстановлен',
  [participantStatus.BLOCKED]: 'Заблокирован',
  [participantStatus.REJECTED]: 'Отказано в регистрации',
};

export const orgTypeOptions = [
  {
    label: translate(ORGANIZATION_TYPE_NAMES.UL),
    value: ORGANIZATION_TYPES.UL,
  },
  {
    label: translate(ORGANIZATION_TYPE_NAMES.IP),
    value: ORGANIZATION_TYPES.IP,
  },
];
