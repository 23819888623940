/** Направления документов */
export const directions = {
  incoming: 'incoming',
  outgoing: 'outgoing',
};

export type Direction = keyof typeof directions;

export interface DocumentFlowDirection {
  documentFlowDirection: Direction;
}
