import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withFormValues } from '@crpt/shared/components/Form/FormSpy';
import { Grid } from '@crpt/material';
import { Typography } from '@crpt-ui/core';
import {
  Select,
  SelectMultiple,
  Input,
} from '@crpt/shared/components/Form/Fields';
import { FieldIds } from '../../CisUnloads.types';

import type { FormValues } from '../../CisUnloads.types';
import DatesPeriod from '../DatesPeriod';
import EditGtins from '../EditGtins';
import { Gtin } from '../../CisUnloads.viewmodel';
import { pgsWithHint } from '../../CisUnloads.constants';

interface AdditionalDataProps {
  packageTypeOptions: { value: string | number; label: string | number }[];
  statusOptions: { value: string | number; label: string | number }[];
  values: FormValues;
  handleEditGtinOpen: (name: string) => void;
  isFoivOrOperator: boolean;
  productGroupCode: number;
  includeGtins: Gtin[];
}

const CommonInformation: FC<AdditionalDataProps> = ({
  values,
  packageTypeOptions,
  statusOptions,
  handleEditGtinOpen,
  isFoivOrOperator,
  includeGtins,
  productGroupCode,
}) => {
  const { t } = useTranslation();

  const includeLabel = useMemo(
    () =>
      includeGtins.length
        ? `${includeGtins[0].gtin} ${includeGtins[0].name} ${
            includeGtins.length > 1 ? '+' + (includeGtins.length - 1) : ''
          }`
        : '',
    [includeGtins]
  );

  const hint = useMemo(() => {
    return pgsWithHint.includes(productGroupCode)
      ? t('UnloadService.Task.CisUnloads.Fields.productionHint')
      : null;
  }, [productGroupCode, t]);

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="h6">
          {t('UnloadService.Task.CisUnloads.FieldGroup.additionalData')}
        </Typography>
      </Grid>
      <Grid item xs>
        <Grid columnSpacing={2} container rowSpacing={2}>
          {isFoivOrOperator && (
            <Grid item xs={12}>
              <Input
                name={FieldIds.PARTICIPANT_INN}
                placeholder={t(
                  'UnloadService.Task.CisUnloads.Fields.participantInn'
                )}
                required
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <EditGtins
              name={FieldIds.INCLUDE_GTIN}
              label={t('UnloadService.Task.CisUnloads.Fields.includeGtin')}
              value={includeLabel}
              editGtins={handleEditGtinOpen}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectMultiple
              multiple
              name={FieldIds.PACKAGE_TYPE}
              options={packageTypeOptions}
              placeholder={t(
                'UnloadService.Task.CisUnloads.Fields.packageType'
              )}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              name={FieldIds.STATUS}
              options={statusOptions}
              placeholder={t('UnloadService.Task.CisUnloads.Fields.status')}
              required
            />
          </Grid>
          <DatesPeriod
            values={values}
            startMapping={FieldIds.EMISSION_PERIOD_START}
            endMapping={FieldIds.EMISSION_PERIOD_END}
            title={t('UnloadService.Task.CisUnloads.Fields.emissionPeriod')}
          />
          <DatesPeriod
            values={values}
            startMapping={FieldIds.APPLIED_PERIOD_START}
            endMapping={FieldIds.APPLIED_PERIOD_END}
            title={t('UnloadService.Task.CisUnloads.Fields.appliedPeriod')}
          />
          <DatesPeriod
            values={values}
            startMapping={FieldIds.PRODUCTION_PERIOD_START}
            endMapping={FieldIds.PRODUCTION_PERIOD_END}
            title={t('UnloadService.Task.CisUnloads.Fields.productionPeriod')}
            hint={hint}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withFormValues(CommonInformation, {
  subscription: {
    values: true,
  },
});
