import { get } from 'lodash';

const decodeUrl = (str) => decodeURI(str).replace(/\+/g, ' ');

export const loadFile = ({ success, name = '' }) => {
  const content = get(success, 'data');
  let fileName = name;
  if (!fileName) {
    const match = get(
      success,
      'payload.headers[content-disposition]',
      ''
    ).match(/filename=(.+)/);

    fileName = match ? match[1].replace(/^"/, '').replace(/"$/, '') : 'file';
  }

  // for IE 11
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const csvData = new Blob([content], {
      type: get(success, 'data.type'),
    });
    window.navigator.msSaveOrOpenBlob(csvData, decodeUrl(fileName));
  }

  const url = window.URL.createObjectURL(new Blob([content]));
  const link = document.createElement('a');

  link.href = url;
  link.rel = 'noopener';
  link.setAttribute('download', decodeUrl(fileName));
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }, 100);
};
