export enum DefaultFieldIds {
  NAME = 'name',
  DATA_START_DATE = 'dataStartDate',
  DATA_END_DATE = 'dataEndDate',
  PRODUCT_GROUP_CODE = 'productGroupCode',
  FILE_FORMAT = 'fileFormat',
  USERS = 'users',
}

export interface DefaultFormValues {
  meta: {
    [MetaFieldIds.IS_OPERATOR]: boolean;
  };
  [DefaultFieldIds.NAME]: string;
  [DefaultFieldIds.DATA_START_DATE]: Date;
  [DefaultFieldIds.DATA_END_DATE]: Date;
  [DefaultFieldIds.PRODUCT_GROUP_CODE]: number;
  [DefaultFieldIds.FILE_FORMAT]: string;
  [DefaultFieldIds.USERS]?: number[];
}

export enum MetaFieldIds {
  IS_OPERATOR = 'isOperator',
}

export interface User {
  value: number;
  label: string;
  additionalLabel: string;
}