import { createActions } from 'redux-actions';

export const { closeBlockUserDialog, openBlockUserDialog } = createActions(
  {
    CLOSE_BLOCK_USER_DIALOG: (payload) => payload,
    OPEN_BLOCK_USER_DIALOG: (payload) => payload,
  },
  {
    prefix: 'BLOCK_USER_DIALOG',
  }
);
