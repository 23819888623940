import React, { useEffect } from 'react';

import lottie from 'lottie-web';
import uuid from 'uuid';

import { animationData } from '../../../constants/preloader';

import { useStyles } from './Preloader.styles';

export const PreloaderView = ({ isOpen, text }) => {
  const styles = useStyles();
  const id = `animation-${uuid.v4()}`;
  useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById(id),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    });
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.imageWrapper} id={id} />
      <div className={styles.textWrapper}>{text}</div>
    </div>
  );
};
