import styled from 'styled-components';
import { get } from 'lodash';

export const StickContentWrapper = styled.div`
  background: ${(props) =>
    get(props, 'theme.Content.backgroundColor', 'black')};
  margin-bottom: 0px;
  margin-top: 0px;
  box-shadow: ${(props) => get(props, 'theme.Content.shadowX', '0')}
    ${(props) => get(props, 'theme.Content.shadowY', '0')}
    ${(props) => get(props, 'theme.Content.shadowBlur', '0')}
    ${(props) => get(props, 'theme.Content.shadowColor', 'black')};
  border-top: 1px solid rgba(196, 196, 196, 0.25);
`;
