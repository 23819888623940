import { yup } from '@crpt-ui/form';


import { FieldIds } from './GtinDialog.types';
import { commonErrorMessages } from '../../../../constants';
import { GTIN_LENGTH, validateStringRegExp } from '../../CisUnloads.constants';
import { testIsGtinUniq } from './utils';

export default yup.object().shape({
  [FieldIds.DATA]: yup
    .array()
    .of(
      yup.object().shape({
        [FieldIds.GTIN]: yup
          .string()
          .required(commonErrorMessages.REQUIRED)
          .matches(validateStringRegExp.noSpaces, commonErrorMessages.GTIN_TRIM)
          .matches(validateStringRegExp.digital, commonErrorMessages.VALUE_NOT_DIGITAL)
          .length(GTIN_LENGTH, commonErrorMessages.GTIN_LENGTH)
          .test(FieldIds.GTIN, commonErrorMessages.GTIN_UNIQ, testIsGtinUniq),
        [FieldIds.NAME]: yup.string().required(commonErrorMessages.REQUIRED),
      })
    ),
});
