import { createStyles } from '@material-ui/core';

export const ContentWrapperStyles = (theme) =>
  createStyles({
    root: {
      background: theme.palette.common.white,
      borderRadius: 4,
      boxShadow: '0 1px 6px rgba(41, 49, 53, 0.1)',
      boxSizing: 'border-box',
      minHeight: 704,
      padding: 32,
      position: 'relative',
    },
  });
