import { createReducer } from '@reduxjs/toolkit';

import * as actions from './Users.actions';

const results = createReducer([], {
  [actions.loaded.type]: (state, { payload }) => {
    return [...state, ...(payload.results || [])];
  },
  [actions.changeUserStatus]: (state, { payload }) => {
    const { id, status } = payload;
    return state.map((el) => (el.id === id ? { ...el, status } : el));
  },
  [actions.unmounted.type]: () => [],
});

const last = createReducer(false, {
  [actions.loaded.type]: (_state, { payload }) => payload.last || false,
  [actions.unmounted.type]: () => false,
});

const page = createReducer(0, {
  [actions.updatePage.type]: (_state, { payload }) => payload,
  [actions.unmounted.type]: () => 0,
});

export const reducer = {
  results,
  last,
  page,
};
