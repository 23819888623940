import { createActions } from 'redux-actions';

export const {
  registrationStatusSubmit,
  setRegistrationStatus,
  setRegistrationDeclineReason,
} = createActions(
  {
    REGISTRATION_STATUS_SUBMIT: (payload) => payload,
    SET_REGISTRATION_STATUS: (payload) => payload,
    SET_REGISTRATION_DECLINE_REASON: (payload) => payload,
  },
  {
    prefix: 'REGISTRATION_STATUS',
  }
);
