import { useMemo } from 'react';

import { AgreementDocumentConnected } from './AgreementDocument/AgreementDocument';
import { AgreementDocumentsListConnected } from './AgreementDocumentsList/AgreementDocumentsList';
import { ClosingDocumentsListConnected } from './ClosingDocumentsList/ClosingDocumentsList';
import { ClosingDocumentConnected } from './ClosingDocument/ClosingDocument';
import { DocumentListConnected } from './DocumentsList/DocumentsList';
import { ServiceDeskDocumentConnected } from './ServiceDeskDocument/ServiceDeskDocument';
import { PaymentDocumentsListConnected } from './PaymentDocumentsList/PaymentDocumentsList';
import { NotificationOperatorDocumentsList } from './NotificationOperatorDocumentsList/NotificationOperatorDocumentsList';
import { NotificationOperatorDocumentConnected } from './NotificationOperatorDocument/NotificationOperatorDocument';

export const documentsRoutes = [
  {
    component: DocumentListConnected,
    path: '/documents',
    title: 'Общие документы',
  },
  {
    component: ServiceDeskDocumentConnected,
    path: '/documents/view/:id',
  },
  {
    component: PaymentDocumentsListConnected,
    path: '/documents/pd',
    title: 'Платежи',
  },
  {
    component: ClosingDocumentsListConnected,
    path: '/documents/cd',
    title: 'Закрывающие документы',
  },
  {
    component: ClosingDocumentConnected,
    path: '/documents/cd/:id',
  },
  {
    component: ClosingDocumentConnected,
    path: '/documents/cd/:id/sign',
  },
  {
    component: AgreementDocumentsListConnected,
    path: '/documents/agreement',
    title: 'Согласия о предоставлении информации',
  },
  {
    component: AgreementDocumentConnected,
    path: '/documents/agreement/document/:id',
  },
  {
    component: AgreementDocumentConnected,
    path: '/documents/agreement/cancellation/:id',
  },
  {
    component: NotificationOperatorDocumentsList,
    path: '/documents/notification-operator',
    title: 'Уведомление оператору ЦРПТ',
  },
  {
    component: NotificationOperatorDocumentConnected,
    path: '/documents/notification-operator/:id',
  },
];

export const useDocumentsRoutes = () => useMemo(() => documentsRoutes, []);
