import { arrayOf, bool, element, func, string } from 'prop-types';

export const MainActionsType = arrayOf({
  label: string,
  onClick: func,
  isShow: bool,
  icon: element,
  classes: string,
  variant: string,
});

export const AdditionalActionsType = arrayOf({
  label: string,
  onClick: func,
  disabled: bool,
  icon: element,
  actionsList: arrayOf({
    label: string,
    onClick: func,
  }),
});
