import React, { FC, useCallback, useEffect, useMemo, memo } from 'react';
import { select } from 'd3-selection';
import { getTree } from '../../utils';
import { RenderDocumentLink, RenderParticipantLink } from '../../Threads';
import { NodeWithChildren } from '../../Threads.types';
import { Node } from '../index';

export interface TreeProps {
  node: NodeWithChildren;
  renderDocumentLink: RenderDocumentLink;
  renderParticipantLink: RenderParticipantLink;
}

const Tree: FC<TreeProps> = ({ node, renderDocumentLink, renderParticipantLink }) => {
  const tree = getTree(node);
  const ancestors = tree.ancestors();
  const descendants = tree.descendants();

  const dx = Math.max(
    ...descendants.map((node) => node.x)
  );

  const height = useMemo(() => (tree.height + 1) * (148 + 32), [tree.height]);
  const width = useMemo(() => dx * 2 + 384 + 32, [dx]);

  const renderNodes = useCallback((nodes) => {
    return nodes.map((node: any) => (
      <Node
        node={node}
        key={node.id}
        renderDocumentLink={renderDocumentLink}
        renderParticipantLink={renderParticipantLink}
      >
        {node.children?.length ? renderNodes(node.children) : null}
      </Node>
    ));
  }, [renderDocumentLink, renderParticipantLink]);

  useEffect(() => {
    select(`#canvas-${node.id}`)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr(
        'style',
        `position: relative; z-index: -1; transform: translate(-${dx}px, 0);`
      )
      .append('g')
      .attr('transform', `translate(${dx}, 0)`)
      .selectAll('.link')
      .data(tree.descendants().slice(1))
      .enter()
      .append('path')
      .attr('class', 'link')
      .attr('fill', 'none')
      .attr('stroke', '#d9d9d9')
      .attr('stroke-width', '1px')
      .attr('d', function (d: any) {
        const x = d.x + (384 + 32) / 2;
        const y = d.y + 16;
        const parentX = d.parent.x + (384 + 32) / 2;
        const parentY = d.parent.y + (148 + 16);

        return `M${x},${y},${x},${(y + parentY) / 2} ${parentX},${(y + parentY) / 2} ${parentX},${parentY}`;
      });
  }, [tree, dx, height, width, node.id]);

  return (
    <div
      style={{
        position: 'relative',
        height: height,
        width: width,
      }}
    >
      <div
        id={`canvas-${node.id}`}
        style={{
          position: 'absolute',
          transform: `translate(${dx}px, 0)`,
        }}
      >
        {renderNodes(ancestors)}
      </div>
    </div>
  );
};

export default memo(Tree);
