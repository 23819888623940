import { createActions } from 'redux-actions';

export const {
  startAgreementDocumentSaga,
  setAgreementDocumentInfo,
  setAgreementDocumentContent,
  setDocumentType,
  startSignAgreementDocumentFromDocument,
  declineAgreementDocument,
  cancellationAgreementDocument,
  downloadXlsAgreementDocument,
  clearAgreementDocument,
} = createActions(
  {
    START_AGREEMENT_DOCUMENT_SAGA: (payload) => payload,

    SET_AGREEMENT_DOCUMENT_INFO: (payload) => payload,
    SET_AGREEMENT_DOCUMENT_CONTENT: (payload) => payload,
    SET_DOCUMENT_TYPE: (payload) => payload,

    START_SIGN_AGREEMENT_DOCUMENT_FROM_DOCUMENT: (payload) => payload,
    DECLINE_AGREEMENT_DOCUMENT: () => {},
    CANCELLATION_AGREEMENT_DOCUMENT: () => {},

    DOWNLOAD_XLS_AGREEMENT_DOCUMENT: (payload) => payload,

    CLEAR_AGREEMENT_DOCUMENT: () => {},
  },
  {
    prefix: 'AGREEMENT_DOCUMENT',
  }
);
