import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  documentStatuses,
  documentTypes,
  eventTypes,
} from '../../../../../constants';
import { Api } from '../../../../../common_components/Api/Api';
import { getDocumentInfo } from '../../ducks/ClosingDocument.selectors';
import { setDocumentInfo } from '../../ducks/ClosingDocument.actions';
import {
  clarificationEventIds,
  rejectedEventIds,
  revokeEventIds,
  revokeStatuses,
} from '../Events.constants';

import {
  clearEventsData,
  fetchEventsData,
  setEventsData,
  setLoading,
} from './Event.actions';

function* fetchDocumentsEvents({
  id,
  eventId,
}: {
  id: string;
  eventId: number;
}): SagaIterator {
  return yield call(
    Api.request,
    {
      url: `/edo-api/incoming-documents/${id}/events/${eventId}`,
    },
    {
      preloading: false,
    }
  );
}

function* startFetchDocumentsEvents({
  statuses,
  eventIds,
  types = [],
}: {
  statuses: number[];
  eventIds: number[];
  types?: number[];
}): SagaIterator<any[]> {
  const { status, id, type } = yield select(getDocumentInfo);

  if (types.length > 0) {
    if (!types.includes(type)) {
      return [];
    }
  }

  if (statuses.includes(status)) {
    let eventsData;

    for (let i = 0; i < eventIds.length; i += 1) {
      const [success] = yield call(fetchDocumentsEvents, {
        id,
        eventId: eventIds[i],
      });

      if (success) {
        eventsData = success.data;

        if (eventsData.items.length > 0) {
          break;
        }
      }
    }

    if (eventsData.items.length > 0) {
      return eventsData.items;
    }
  }

  return [];
}

function* fetchEventsDataSaga() {
  yield put(setLoading(true));
  yield put(clearEventsData());

  yield take(setDocumentInfo);

  let eventsData: any[] = [];

  const data: any[] = yield all([
    call(startFetchDocumentsEvents, {
      statuses: [documentStatuses.revocateDenied],
      eventIds: [eventTypes.DP_PRANNULDENY],
    }),
    call(startFetchDocumentsEvents, {
      statuses: revokeStatuses,
      eventIds: revokeEventIds,
    }),
    call(startFetchDocumentsEvents, {
      statuses: [
        documentStatuses.clarificationRequired,
        documentStatuses.clarificationRequest,
      ],
      eventIds: clarificationEventIds,
      types: [
        documentTypes.upd820Sf,
        documentTypes.upd820SfDop,
        documentTypes.ukdSf,
        documentTypes.ukdSfDis,
        602,
        604,
      ],
    }),
    call(startFetchDocumentsEvents, {
      statuses: [documentStatuses.rejected, documentStatuses.viewedRejected],
      eventIds: rejectedEventIds,
      types: [documentTypes.nfBill],
    }),
  ]);

  data.forEach((item) => {
    eventsData = [...eventsData, ...item];
  });

  yield put(setEventsData(eventsData));
  yield put(setLoading(false));
}

export const saga = function* watch() {
  yield takeEvery(fetchEventsData, fetchEventsDataSaga);
};
