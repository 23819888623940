import thunk from 'redux-thunk';
import CreateSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';

import { allSagas, allStores, config, order } from './App.config';
import { getMainSaga } from './middlewares/mainSaga';
import { getReducers } from './reducers';

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

export const history = createBrowserHistory();

const sagaMiddleware = CreateSagaMiddleware();

const reducers = getReducers(order, allStores, config, history);
const mainSaga = getMainSaga(order, allSagas);

const enhancer = composeEnhancers(
  applyMiddleware(thunk, sagaMiddleware, routerMiddleware(history))
);

export const store = createStore(reducers, enhancer);

sagaMiddleware.run(mainSaga);
