import { translate } from '../../components/Translate/Translate';

export const breadcrumbNameMap = [
  {
    routes: [
      {
        path: '/documents',
        title: translate('Документы от оператора'),
        routes: [
          { path: '/documents/view/:id', title: 'Документ' },
          { path: '/documents/pd', title: 'Платежи' },
          {
            path: '/documents/cd',
            title: 'Закрывающие документы',
            routes: [
              {
                path: '/documents/cd/:id',
                title: 'Документ',
                routes: [
                  {
                    path: '/documents/cd/:id/sign',
                    title: 'Подпись',
                  },
                ],
              },
            ],
          },
          {
            path: '/documents/agreement',
            title: 'Согласия о предоставлении информации',
            routes: [
              {
                path: '/documents/agreement/document/:id',
                title: 'Документ',
              },
              {
                path: '/documents/agreement/cancellation/:id',
                title: 'Документ',
              },
            ],
          },
          {
            path: '/documents/notification-operator',
            title: 'Уведомление оператору ЦРПТ',
            routes: [
              {
                path: '/documents/notification-operator/:id',
                title: 'Документ',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/participants/list',
    title: 'Участники',
    routes: [
      {
        path: '/participants/list/:id',
        title: 'Участник',
        routes: [
          {
            path: '/participants/list/:id/basic-info',
            title: 'Общая информация',
          },
          {
            path: '/participants/list/:id/documents',
            title: 'Документы',
          },
          {
            path: '/participants/list/:id/receipts',
            title: 'Чеки',
          },
          {
            path: '/participants/list/:id/warehouse',
            title: 'Склад',
          },
          {
            path: '/participants/list/:id/excise',
            title: 'Акцизный налог',
          },
          {
            path: '/participants/list/:id/users',
            title: 'Пользователи',
          },
          {
            path: '/participants/list/:id/tree',
            title: 'Дерево связей',
          },
        ],
      },
    ],
  },
];
