import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { Api } from '../../../../common_components/Api/Api';
import { HttpMethodEnum } from '../../../../constants';

import {
  closeProductionSiteModal,
  fetchProductionData,
  openProductionSiteModal,
  openProductionSiteModalEdit,
  removeProductionLine,
  sendProductionData,
  setCountries,
  setLoading,
  setProductionData,
  setProductionLineId,
  setProductionPage,
  setSubmitting,
} from './Production.actions';
import { getProductionPage } from './Production.selectors';

function* sendProductionDataSaga({ payload }) {
  yield put(setSubmitting(true));

  const { values, isEditForm, onSuccess } = payload;
  const request = {
    url: '/api/v3/facade/profile/productions',
    data: {
      ...values,
      productionKpp: '999999999',
    },
    method: isEditForm ? HttpMethodEnum.PUT : HttpMethodEnum.POST,
  };
  const requestMeta = {
    showBackendMessage: true,
    preloading: false,
    withoutDescriptionOfError: true,
  };
  const [success] = yield call(Api.request, request, requestMeta);

  if (success) {
    yield call(fetchProductionLines);
    yield put(closeProductionSiteModal());
    yield call(onSuccess);
  }

  yield put(setSubmitting(false));
}

function* fetchProductionLines() {
  yield put(setLoading(true));

  const page = yield select(getProductionPage);

  const [productionLines] = yield call(Api.request, {
    url: '/api/v3/facade/profile/productions',
    params: {
      page,
    },
  });

  if (productionLines) {
    yield put(setProductionData(productionLines.data));
  }

  yield put(setLoading(false));
}

function* fetchProductionCountries() {
  const [productionCountries] = yield call(
    Api.request,
    {
      url: '/api/v3/org/countries',
    },
    {
      preloading: false,
    }
  );

  if (productionCountries) {
    const productionCountriesList = productionCountries.data.map(
      ({ numericCode, name }) => ({
        value: numericCode,
        label: name,
      })
    );
    yield put(setCountries(productionCountriesList));
  }
}

function* fetchProductionDataSaga() {
  yield all([call(fetchProductionLines), call(fetchProductionCountries)]);
}

function* removeProductionLineSaga({ payload }) {
  const [successRemove] = yield call(Api.request, {
    url: `/api/v3/facade/profile/productions/${payload}`,
    method: HttpMethodEnum.DELETE,
  });

  if (successRemove) {
    yield call(fetchProductionLines);
  }
}

function* setProductionLineIdSaga({ payload }) {
  yield put(setProductionLineId(payload));
  yield put(openProductionSiteModal());
}

export const saga = function* watch() {
  yield takeEvery(fetchProductionData, fetchProductionDataSaga);
  yield takeEvery(sendProductionData, sendProductionDataSaga);
  yield takeEvery(openProductionSiteModalEdit, setProductionLineIdSaga);
  yield takeEvery(removeProductionLine, removeProductionLineSaga);
  yield takeEvery(setProductionPage, fetchProductionLines);
};
