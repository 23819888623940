import { translate } from '../Translate/Translate';

type Values = {
  actualAddress: string;
  email: string;
  fullName: string;
  inn: string;
  legalAddress: string;
  organizationForm: string;
  phone: string;
  physicalPerson: {
    fio: string;
    socialCardId: string;
  };
  productGroupsAndRoles: {
    code: string;
    license: string;
    types: { value: string; label: string }[];
  }[];
  shortName: string;
};

export const prepareParticipant = (values: Values) => ({
  inn: values.inn.slice(0, 8),
  org_short_name: values.shortName,
  org_full_name: values.fullName,
  email: values.email,
  phone: values.phone,
  organisation_form: values.organizationForm,
  legal_address: values.legalAddress,
  actual_address: values.actualAddress,
  contact_fio: values.physicalPerson.fio,
  contact_social_id: values.physicalPerson.socialCardId,
  product_groups: values.productGroupsAndRoles.map((item) => ({
    ...item,
    types: item.types.map(({ value }) => value),
  })),
});

export const breadcrumbs = [
  {
    href: '/participants/list',
    title: translate('Участники'),
    id: 1,
  },
  {
    title: translate('Добавить участника'),
    id: 2,
  },
];
