export const rutokenErrorsDataList = {
  ALREADY_LOGGED_IN: 'Пользователь уже авторизован',
  ASN1_ERROR: 'Ошибка разбора структуры ASN1',
  BAD_PARAMS: 'Неправильные параметры',
  BASE64_DECODE_FAILED: 'Ошибка декодирования из base64',
  CA_CERTIFICATES_NOT_FOUND: 'Корневые сертификаты отсутствуют',
  CANNOT_SAVE_PIN_IN_CACHE: 'Невозможно сохранить PIN-код',
  CANT_HARDWARE_VERIFY_CMS:
    'Проверка множественной подписи с вычислением хеша на устройстве не поддерживается',
  CEK_NOT_AUTHENTIC:
    'Контейнер в формате CMS поврежден или переданный ключ не является ключом получателя',
  CERTIFICATE_CATEGORY_BAD: 'Некорректная категория сертификата',
  CERTIFICATE_EXISTS: 'Сертификат с таким телом уже существует на устройстве',
  CERTIFICATE_HASH_NOT_UNIQUE: 'Идентификатор сертификата не уникален',
  CERTIFICATE_NOT_FOUND: 'Сертификат не найден на устройстве',
  CERTIFICATE_VERIFICATION_ERROR: 'Ошибка проверки сертификата',
  DATA_INVALID: 'Неверные данные',
  DEVICE_ERROR: 'Ошибка устройства.',
  DEVICE_NOT_FOUND: 'Устройство не найдено',
  FUNCTION_FAILED: 'Невозможно выполнить операцию',
  FUNCTION_REJECTED: 'Операция отклонена пользователем',
  KEY_FUNCTION_NOT_PERMITTED: 'Операция запрещена для данного типа ключа',
  KEY_ID_NOT_UNIQUE: 'Идентификатор ключевой пары не уникален',
  KEY_LABEL_NOT_UNIQUE: 'Метка ключевой пары не уникальна',
  KEY_NOT_FOUND: 'Не найдена соответствующая ключевая пара.',
  LICENCE_READ_ONLY: 'Лицензия доступна только для чтения',
  NOT_ENOUGH_MEMORY: 'Недостаточно памяти',
  PEM_ERROR: 'Ошибка декодирования PEM',
  PIN_CHANGED: 'PIN-код был изменен извне',
  PIN_INCORRECT: 'Введен неправильный PIN-код',
  PIN_INVALID: 'PIN-код содержит недопустимые символы',
  PIN_LENGTH_INVALID: 'Введен слишком короткий PIN-код',
  PIN_LOCKED: 'PIN-код заблокирован',
  PKCS11_LOAD_FAILED: 'Не удалось загрузить модуль PKCS#11',
  SESSION_INVALID: 'Текущая сессия устарела',
  TOKEN_INVALID: 'Ошибка чтения/записи устройства.',
  UNSUPPORTED_BY_TOKEN: 'Операция не поддерживается токеном',
  USER_NOT_LOGGED_IN: 'Пользователь не авторизован',
  USER_PIN_NOT_INITIALIZED: 'PIN-код пользователя не инициализирован',
  WRONG_KEY_TYPE: 'Неправильный тип ключа',
  X509_AKID_ISSUER_SERIAL_MISMATCH:
    'Несовпадение серийного номера субъекта и доверенного центра',
  X509_AKID_SKID_MISMATCH:
    'Несовпадение идентификатора ключа у субъекта и доверенного центра',
  X509_APPLICATION_VERIFICATION: 'Ошибка проверки приложения',
  X509_CERT_CHAIN_TOO_LONG: 'Слишком длинная цепочка сертификатов',
  X509_CERT_HAS_EXPIRED: 'Срок действия сертификата истек',
  X509_CERT_NOT_YET_VALID: 'Срок действия сертификата еще не начался',
  X509_CERT_REJECTED: 'Сертификат отклонен',
  X509_CERT_REVOKED: 'Сертификат отозван',
  X509_CERT_SIGNATURE_FAILURE: 'Неверная подпись сертификата',
  X509_CERT_UNTRUSTED: 'Недоверенный сертификат',
  X509_CRL_HAS_EXPIRED: 'Срок действия CRL истек',
  X509_CRL_NOT_YET_VALID: 'Срок действия CRL еще не начался',
  X509_CRL_PATH_VALIDATION_ERROR: 'Неправильный путь CRL',
  X509_CRL_SIGNATURE_FAILURE: 'Неверная подпись CRL',
  X509_DEPTH_ZERO_SELF_SIGNED_CERT: 'Недоверенный самоподписанный сертификат',
  X509_DIFFERENT_CRL_SCOPE: 'Другая область CRL',
  X509_ERROR_IN_CERT_NOT_AFTER_FIELD:
    'Некорректные данные в поле “notAfter” у сертификата',
  X509_ERROR_IN_CERT_NOT_BEFORE_FIELD:
    'Некорректные данные в поле “notBefore” у сертификата',
  X509_ERROR_IN_CRL_LAST_UPDATE_FIELD:
    'Некорректные данные в поле “lastUpdate” у CRL',
  X509_ERROR_IN_CRL_NEXT_UPDATE_FIELD:
    'Некорректные данные в поле “nextUpdate” у CRL',
  X509_EXCLUDED_VIOLATION: 'Неправильная структура сертификата',
  X509_INVALID_CA: 'Неверный корневой сертификат',
  X509_INVALID_EXTENSION: 'Неверное или некорректное расширение сертификата',
  X509_INVALID_NON_CA:
    'Неверный некорневой сертификат, помеченный как корневой',
  X509_INVALID_POLICY_EXTENSION:
    'Неверное или некорректное расширение политик сертификата',
  X509_INVALID_PURPOSE: 'Неподдерживаемое назначение сертификата',
  X509_KEYUSAGE_NO_CERTSIGN:
    'Ключ не может быть использован для подписи сертификатов',
  X509_KEYUSAGE_NO_CRL_SIGN: 'Ключ не может быть использован для подписи CRL',
  X509_KEYUSAGE_NO_DIGITAL_SIGNATURE:
    'Ключ не может быть использован для цифровой подписи',
  X509_NO_EXPLICIT_POLICY: 'Явные политики отсутствуют',
  X509_OUT_OF_MEM: 'Нехватает памяти',
  X509_PATH_LENGTH_EXCEEDED: 'Превышена длина пути',
  X509_PERMITTED_VIOLATION: 'Неправильная структура сертификата',
  X509_PROXY_CERTIFICATES_NOT_ALLOWED: 'Проксирующие сертификаты недопустимы',
  X509_PROXY_PATH_LENGTH_EXCEEDED: 'Превышена длина пути прокси',
  X509_SELF_SIGNED_CERT_IN_CHAIN:
    'В цепочке обнаружен недоверенный самоподписанный сертификат',
  X509_SUBJECT_ISSUER_MISMATCH: 'Несовпадения субъекта и эмитента',
  X509_SUBTREE_MINMAX: 'Неправильная структура сертификата',
  X509_UNABLE_TO_DECODE_ISSUER_PUBLIC_KEY:
    'Невозможно раскодировать открытый ключ эмитента',
  X509_UNABLE_TO_DECRYPT_CERT_SIGNATURE:
    'Невозможно расшифровать подпись сертификата',
  X509_UNABLE_TO_DECRYPT_CRL_SIGNATURE: 'Невозможно расшифровать подпись CRL',
  X509_UNABLE_TO_GET_CRL: 'Невозможно получить CRL',
  X509_UNABLE_TO_GET_CRL_ISSUER: 'Невозможно получить CRL подписанта',
  X509_UNABLE_TO_GET_ISSUER_CERT: 'Невозможно получить сертификат подписанта',
  X509_UNABLE_TO_GET_ISSUER_CERT_LOCALLY:
    'Невозможно получить локальный сертификат подписанта',
  X509_UNABLE_TO_VERIFY_LEAF_SIGNATURE:
    'Невозможно проверить первый сертификат',
  X509_UNHANDLED_CRITICAL_CRL_EXTENSION: 'Неподдерживаемое расширение CRL',
  X509_UNHANDLED_CRITICAL_EXTENSION: 'Неподдерживаемое расширение',
  X509_UNNESTED_RESOURCE: 'RFC 3779 неправильное наследование ресурсов',
  X509_UNSUPPORTED_CONSTRAINT_SYNTAX: 'Неправильная структура сертификата',
  X509_UNSUPPORTED_CONSTRAINT_TYPE: 'Неправильная структура сертификата',
  X509_UNSUPPORTED_EXTENSION_FEATURE:
    'Неподдерживаемое расширение возможностей',
  X509_UNSUPPORTED_NAME_SYNTAX: 'Неправильная структура сертификата',
};
