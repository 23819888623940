import instance from '../axios';

class DocumentReprocessingService {
  startReprocessing = (id: string) => {
   return instance.post(`/api/v3/facade/doc/reprocess`, {
        documentId: id,
      });
  };
}

export const documentReprocessingService = new DocumentReprocessingService();
