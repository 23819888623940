import { TABLE_NAMES } from './ViolationsRegistryTable.constants';
import type {
  TableNamesEnum,
  MatchParams,
} from './ViolationsRegistryTable.types';

type PluralType = 'one' | 'few' | 'many';

export const getDataCountText = (value: number) => {
  let pluralType: PluralType = 'many';

  const lastDigit = value % 10;
  const hasOneOnSecondNumberRank = `${value}`.slice(-2, -1) === '1';

  if (!hasOneOnSecondNumberRank) {
    if (lastDigit === 1) {
      pluralType = 'one';
    }
    if ([2, 3, 4].includes(lastDigit)) {
      pluralType = 'few';
    }
    if ([5, 6, 7, 8, 9].includes(lastDigit)) {
      pluralType = 'many';
    }
  }

  const messages = {
    one: 'запись',
    few: 'записи',
    many: 'записей',
  };

  return `${value} ${messages[pluralType]}`;
};

export const getCurrentTableName = (params: MatchParams): TableNamesEnum => {
  const { type, inn } = params;

  let name = TABLE_NAMES.VIOLATIONS_REGISTRY;

  if (type && !inn) {
    name = TABLE_NAMES.VIOLATIONS_REGISTRY_BY_TYPE;
  }
  if (type && inn) {
    name = TABLE_NAMES.VIOLATIONS_REGISTRY_BY_INN;
  }

  return name;
};
