export const ManuallyAgreementDocumentColumns = [
  {
    accessor: 'name',
    Header: 'Наименование',
  },
  {
    accessor: 'inn',
    Header: 'ИНН организации',
  },
];
