import { handleActions } from 'redux-actions';
import * as _ from 'lodash';

import * as actions from './Preloader.actions';

const queue = handleActions(
  {
    [actions.start]: (state) => ({
      value: [...state.value, Date.now()],
    }),
    [actions.end]: (state) => ({
      value: _.slice(state.value, 1),
    }),
  },
  { value: [] }
);

export const reducer = { queue };
