import { useCallback, useEffect, useState } from 'react';
import { uniq } from 'lodash';
import bff from '@crpt/shared/services/bff';
import toast from '@crpt/shared/toast';
import { largeDash } from '@crpt/shared/constants';
import { useTranslation } from 'react-i18next';

interface Organization {
  inn: string;
  name?: string;
}

interface State {
  organizations: Organization[];
  isLoading: boolean;
}

interface EditInnsViewModelOptions {
  fieldName: string;
  inns: string[];
  formChange: (name: string, value?: string | undefined) => void;
  onClose: () => void;
}

const EditInnsViewModel = ({
  fieldName,
  formChange,
  inns,
  onClose,
}: EditInnsViewModelOptions) => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>({
    isLoading: false,
    organizations: inns.map((inn) => ({
      inn,
    })),
  });

  const removeOrganization = useCallback(
    (orgIndex) => () => {
      const preparedOrganizations = [
        ...state.organizations.slice(0, orgIndex),
        ...state.organizations.slice(orgIndex + 1),
      ];

      formChange(
        fieldName,
        preparedOrganizations.map(({ inn }) => inn).join(', ')
      );

      if (preparedOrganizations.length === 0) {
        onClose();
      } else {
        setState((prevState) => ({
          ...prevState,
          organizations: preparedOrganizations,
        }));
      }
    },
    [fieldName, formChange, onClose, state.organizations]
  );

  const removeAllOrganizations = useCallback(() => {
    formChange(fieldName, '');
    onClose();
  }, [fieldName, formChange, onClose]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    bff
      .fetchOrganisationList(uniq(inns))
      .then((success) => {
        const enrichedOrganizations = inns.map((inn) => {
          const org = success.data.find((item) => item.inn === inn);

          return {
            inn,
            name: org?.name ?? largeDash,
          };
        });

        setState({
          organizations: enrichedOrganizations,
          isLoading: false,
        });
      })
      .catch(() => {
        toast.error(
          t('UnloadService.Task.AcsUnloads.Messages.requestError')
        );

        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  }, [inns, t]);

  return {
    ...state,
    removeOrganization,
    removeAllOrganizations,
    t,
  } as const;
};

export default EditInnsViewModel;
