import React from 'react';

import { Box } from '@mui/material';

import { Icon } from '@ibox/ui';

import { makeDateCell } from '../../../utils/Table.utils';
import { Status } from '../../status';
import { translate } from '../../Translate/Translate';

import css from './user-list.module.scss';

export const userStatusEnum = {
  ACTIVE: 'ACTIVE',
  APPROVED: 'APPROVED',
  BLOCKED: 'BLOCKED',
  REGISTERED: 'REGISTERED',
  REMOVED: 'REMOVED',
  DRAFT: 'DRAFT',
};

export const userStatusValuesMap = {
  [userStatusEnum.ACTIVE]: translate('Активен'),
  [userStatusEnum.BLOCKED]: translate('Заблокирован'),
  [userStatusEnum.REGISTERED]: translate('Зарегестрирован'),
  [userStatusEnum.APPROVED]: translate('Согласовано оператором'),
  [userStatusEnum.REMOVED]: translate('Удален'),
  [userStatusEnum.DRAFT]: translate('Черновик'),
};

export const userStatusColorsMap = {
  [userStatusEnum.ACTIVE]: '#48c834',
  [userStatusEnum.BLOCKED]: '#f34747',
  [userStatusEnum.DRAFT]: '#f34747',
};

export const userStatusIconsMap = {
  [userStatusEnum.ACTIVE]: (
    <Icon className={css.userStatusIcon} name="UserActive" />
  ),
  [userStatusEnum.BLOCKED]: (
    <Icon className={css.userStatusIcon} name="UserBlock" />
  ),
  [userStatusEnum.REMOVED]: (
    <Icon className={css.userStatusIcon} name="UserRemove" />
  ),
};

export const columns = [
  {
    header: translate('ФИО пользователя'),
    id: 'name',
    enableSorting: false,
    Cell: ({ row: { original } }) => {
      return (
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            //display: '-moz-box',
            display: '-webkit-box',
            '-moz-box-orient': 'vertical',
            '-webkit-line-clamp': '3',
            '-webkit-box-orient': 'vertical',
            'line-clamp': 3,
            'box-orient': 'vertical',
          }}
        >
          {original.user.fullName}
        </div>
      );
    },
  },
  {
    accessorKey: 'user.email',
    header: translate('Email пользователя'),
    id: 'email',
    enableSorting: false,
  },
  {
    accessorKey: 'user.phones',
    header: translate('Контактный телефон'),
    id: 'phone',
    enableSorting: false,
    Cell: ({ cell }) => {
      const phones = cell.getValue();
      return <>{phones[0].length ? <Box>{phones[0]}</Box> : <Box>—</Box>}</>;
    },
  },
  {
    Cell: ({ row: { original } }) => {
      const { assignedRoles = [] } = original;
      const assignedRolesFiltered = assignedRoles.filter((role) =>
        [
          'ADMIN',
          'USER',
          'RMT_MODERATOR',
          'RMT_SUPERMODERATOR',
          'RMT_USER',
          'RMT_PUBLISHER',
          'COORDINATOR',
        ].includes(role.name)
      );
      return assignedRolesFiltered.map((role, i) => (
        <>
          {translate(role.description)}
          {assignedRolesFiltered[i + 1] ? ', ' : ''}
        </>
      ));
    },
    header: translate('Роль пользователя'),
    id: 'assignedRoles',
    enableSorting: false,
  },
];

export const useUsersListColumns = () => [
  ...columns,
  {
    accessorKey: 'user.lastActivity',
    enableSorting: false,
    header: translate('Последняя активность'),
    Cell: ({ row: { original } }) =>
      makeDateCell({
        format: 'DD.MM.YY hh:mm',
      })({ cell: { value: original.user.lastActivity } }),
    width: 200,
  },
  {
    accessorKey: 'user.status',
    enableSorting: false,
    header: translate('Статус пользователя'),
    width: 200,
    Cell: ({ cell }) => {
      return (
        <Status
          label={userStatusValuesMap[cell.getValue()] || '-'}
          status={cell.getValue()}
        />
      );
    },
  },
];

export const checkRights = (participant: Record<string, unknown>) =>
  participant
    ? participant?.productGroupsAndRoles?.reduce(
        (acc: boolean, item) => acc || item.types.includes('IS_MP_OPERATOR'),
        false
      )
    : null;
