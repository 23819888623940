import { DatePicker } from '@crpt-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import { DefaultFilter } from './DefaultFilter';
import { generateFilter } from '../Datatable.utils';

export const DatePickerFilter = React.memo(
  ({ column, endOfDay, form, formState, instance, ...rest }) => {
    const value = useMemo(() => get(formState, 'values.value.date', ''), [
      formState.values,
    ]);

    const onChange = useCallback((date) => {
      endOfDay
        ? form.change('value', { date: new Date(date.setHours(23, 59, 59, 0)) })
        : form.change('value', { date });
    }, []);

    return (
      <div style={{ margin: '-24px -16px 0' }}>
        <DatePicker
          {...rest}
          name="value"
          date={value}
          onDateChange={onChange}
          isFilter
        />
      </div>
    );
  }
);

export const makeDatepickerFilter = generateFilter(DatePickerFilter);

DatePickerFilter.propTypes = {
  ...DefaultFilter.propTypes,
  // set date at the end of the day
  endOfDay: PropTypes.bool,
};

DatePickerFilter.defaultProps = {
  endOfDay: false,
};
