import React from 'react';
import { FormSpy } from 'react-final-form';

import type { FormSpyProps } from 'react-final-form';

export const withFormValues =
  (Component: any, configSpy?: FormSpyProps) => (props: any) =>
    (
      <FormSpy
        subscription={{
          values: true,
          errors: true,
        }}
        {...configSpy}
      >
        {(spyProps) => <Component {...props} {...spyProps} />}
      </FormSpy>
    );
