import { createActions } from 'redux-actions';

/**
 * Production actions
 */
export const {
  closeProductionSiteModal,
  fetchProductionData,
  openProductionSiteModal,
  openProductionSiteModalEdit,
  removeProductionLine,
  sendProductionData,
  setCountries,
  setLoading,
  setProductionData,
  setProductionLineId,
  setProductionPage,
  setSubmitting,
} = createActions(
  {
    FETCH_PRODUCTION_DATA: () => {},
    REMOVE_PRODUCTION_LINE: (payload) => payload,

    SET_PRODUCTION_DATA: (payload) => payload,
    SEND_PRODUCTION_DATA: (payload) => payload,
    SET_COUNTRIES: (payload) => payload,
    SET_PRODUCTION_LINE_ID: (payload) => payload,
    SET_PRODUCTION_PAGE: (payload) => payload,

    OPEN_PRODUCTION_SITE_MODAL: (payload) => payload,
    OPEN_PRODUCTION_SITE_MODAL_EDIT: (payload) => payload,
    CLOSE_PRODUCTION_SITE_MODAL: () => {},

    SET_LOADING: (payload) => payload,
    SET_SUBMITTING: (payload) => payload,
  },
  {
    prefix: 'PRODUCTION',
  }
);
