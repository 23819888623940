import { combineActions, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';

import { openHelperModal, closeHelperModal } from './ProfileHelper.actions';

const open = handleActions(
  {
    [openHelperModal]: () => true,
    [combineActions(closeHelperModal, LOCATION_CHANGE)]: () => false,
  },
  false
);

export const reducer = {
  open,
};
