import { handleActions } from 'redux-actions';

import {
  closeUploadNotificationOperatorDialog,
  openUploadNotificationOperatorDialog,
  setSubmitting,
} from './UploadNotificationOperatorDialog.actions';

const open = handleActions(
  {
    [openUploadNotificationOperatorDialog]: () => true,
    [closeUploadNotificationOperatorDialog]: () => false,
  },
  false
);

const type = handleActions(
  {
    [openUploadNotificationOperatorDialog]: (_, { payload }) => payload,
    [closeUploadNotificationOperatorDialog]: () => ({}),
  },
  {}
);

const submitting = handleActions(
  {
    [setSubmitting]: (_, { payload }) => payload,
    [closeUploadNotificationOperatorDialog]: () => false,
  },
  false
);

export const reducer = {
  open,
  type,
  submitting,
};
