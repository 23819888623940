import { PRODUCT_GROUP, ProductGroup } from '../../constants';

class ProductGroupService {
  private cache = Object.values(PRODUCT_GROUP).reduce<
    Record<string, ProductGroup>
  >((acc, productGroup) => {
    Object.assign(acc, {
      [productGroup.id]: productGroup,
      [productGroup.code]: productGroup,
    });

    return acc;
  }, {});

  get(query: number | string) {
    return this.cache[query];
  }

  getName(query: number | string) {
    return this.get(query)?.name;
  }

  getId(query: number | string) {
    return this.get(query)?.id;
  }

  getCode(query: number | string) {
    return this.get(query)?.code;
  }

  getEnvironment(query: number | string) {
    return this.get(query)?.env;
  }

  renderIcon(query: number | string) {
    return this.get(query)?.icon;
  }

  /**
   * @example [{ bicycle: 'Велосипеды и велосипедные рамы' }]
   */
  getNamesMap(): Record<string, string>[] {
    const pgArray = Object.values(this.cache).map(({ code, name }) => [
      code,
      name,
    ]);

    return Object.fromEntries(pgArray);
  }
}

export const productGroupService = new ProductGroupService();
