import React, { useCallback } from 'react';

import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Link, Icon } from '@crpt-ui/core';

const styles = () => ({
  root: {
    alignItems: 'center',
    display: 'flex',
  },
  text: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 20 / 14,
  },
  icon: {
    marginRight: '10px',
    color: '#4AA5E8',
  },
});

const DownloadDocumentsComp = ({ onClick, classes }) => {
  const handleClick = useCallback(
    (event) => {
      if (typeof onClick === 'function') {
        onClick(event);
      }
    },
    [onClick]
  );

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <Icon name="Attachment" />
      </div>
      <div className={classes.text}>
        Перед подписанием{' '}
        <Link onClick={handleClick}>
          скачайте и ознакомьтесь с приложенными документами
        </Link>{' '}
        и&nbsp;
        <Link href="/docs/информационное_письмо.pdf" target="_blank">
          информационным письмом об операторе ЦРПТ
        </Link>
      </div>
    </div>
  );
};

DownloadDocumentsComp.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const DownloadDocuments = withStyles(styles)(DownloadDocumentsComp);
