import { call } from 'redux-saga/effects';
import { get } from 'lodash';

import { Api } from '../../../../../common_components/Api/Api';
import { getFilename } from '../../../../../common_components/Document/Document.utils';

/**
 * Получить контент для документов из SD
 */
export function* getSDContent(documents) {
  const result = [];

  for (let i = 0; i < documents.length; i += 1) {
    const document = documents[i];
    let link;

    try {
      link = JSON.parse(get(document, 'link'));
    } catch (e) {
      console.error('Document link error', document);
    }

    if (link) {
      const [success] = yield call(fetchSDContent, { guid: document.document });

      if (success) {
        result.push({
          content: get(success, 'data'),
          doctype: document.doctype,
          filename: document.fileName || getFilename(success),
          signtype: document.signtype,
          docid: document.docid,
          name: document.name,
        });
      }
    }
  }

  return result;
}

export function* fetchSDContent({ guid, filename, group }) {
  const requestPayload = {
    url: '/crm-api/document',
    responseType: 'arraybuffer',
    params: {
      group,
      presign: 'y',
      guid,
      filename,
    },
    headers: {
      ContentType: 'text/xml;application/json',
    },
  };

  return yield call(Api.request, requestPayload, {
    preloading: false,
  });
}
