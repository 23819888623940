import moment from 'moment';
import {
  FileFormat,
  Format,
  Periodicity,
  TASK_TYPE_ENUM,
  isoStringDateFormat,
} from '../../../constants';
import { getFrontendTaskUrl } from '../../../utils';

import type { CreateTaskDataAcsDocuments } from '@crpt/shared/services/UnloadTasksService.types';
import type { FormValues } from './AcsDocuments.types';

interface CreateTaskExtraData {
  isOperator: boolean;
  participantInn?: string;
  pathToUnloadServiceRegistry: string;
}

const prepareInn = (value: string) =>
  value.split(',').map((item) => item.trim());

export const createTask = (
  data: FormValues,
  {
    isOperator,
    participantInn,
    pathToUnloadServiceRegistry,
  }: CreateTaskExtraData
): CreateTaskDataAcsDocuments => {
  const {
    dataStartDate,
    dataEndDate,
    senderInn,
    receiverInn,
    status,
    fileFormat,
    meta,
    ...restData
  } = data;

  const preparedStatus = Object.entries(status)
    .filter(([, value]) => value)
    .map(([key]) => key);

  const params: any = {
    status: preparedStatus,
  };

  if (isOperator) {
    if (receiverInn) {
      params.receiverInn = prepareInn(receiverInn);
    }
    if (senderInn) {
      params.senderInn = prepareInn(senderInn);
    }
  } else {
    if (receiverInn) {
      params.receiverInn = prepareInn(receiverInn);
      params.senderInn = [participantInn];
    } else if (senderInn) {
      params.senderInn = prepareInn(senderInn);
      params.receiverInn = [participantInn];
    } else {
      params.senderInn = [participantInn];
      params.receiverInn = [participantInn];
    }
  }

  const adaptedData: any = {
    dataStartDate: moment(dataStartDate).format(isoStringDateFormat),
    dataEndDate: moment(dataEndDate).format(isoStringDateFormat),
  };

  if (fileFormat === FileFormat.XLSX) {
    adaptedData.extraFormats = [fileFormat];
  }

  return {
    ...restData,
    ...adaptedData,
    params: JSON.stringify(params),
    format: Format.CSV,
    frontendTaskUrl: getFrontendTaskUrl(pathToUnloadServiceRegistry),
    periodicity: Periodicity.SINGLE,
    taskTypeShortName: TASK_TYPE_ENUM.ACS_DOCUMENTS,
  };
};
