import axios, { AxiosRequestConfig } from 'axios';
import cookie from 'js-cookie';
import _ from 'lodash';
import qs from 'query-string';

const getAuthorizationToken = () => {
  const cookies = cookie.get();
  return _.chain(cookies)
    .filter((_value, key) => /^tokenPart\d/.test(key))
    .join('')
    .value();
};

const createRequestSignature = () =>
  btoa(
    JSON.stringify({
      source: process.env.REACT_APP_NAME,
      timestamp: Date.now(),
    })
  );

const create = (config?: AxiosRequestConfig) => {
  const instance = axios.create({
    ...config,
    paramsSerializer: qs.stringify,
  });

  instance.interceptors.request.use((config) => {
    _.assignIn(config.headers, {
      'X-Source-App': createRequestSignature(),
    });

    if (
      process.env.REACT_APP_NAME === '@crpt/elk' ||
      process.env.REACT_APP_NAME === '@crpt/lightindustry' ||
      process.env.REACT_APP_NAME === '@crpt/tobacco'
    ) {
      const token = getAuthorizationToken();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  });

  instance.interceptors.request.use((config) => {
    if (typeof window.cm !== 'undefined') {
      window.cm('send', 'request', {
        method: config.method,
        url: config.url,
      });
    }

    return config;
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (typeof window.cm !== 'undefined' && axios.isAxiosError(error)) {
        window.cm('send', 'response', {
          method: error.config.method,
          status: error.response?.status,
          url: error.config.url,
        });
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export default {
  ...create(),
  create,
} as ReturnType<typeof create> & {
  create: typeof create;
};
