import { handleActions } from 'redux-actions';

import {
  closePaymentDocumentSuccessModal,
  openPaymentDocumentSuccessModal,
} from './CreatePaymentDocumentSuccess.actions';

const open = handleActions(
  {
    [openPaymentDocumentSuccessModal]: () => true,
    [closePaymentDocumentSuccessModal]: () => false,
  },
  false
);

export const reducer = {
  open,
};
