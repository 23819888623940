import React, { PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider, ThemeProvider, useTheme } from '@crpt/material';
import { redesignBodyClass, redesignDivId } from './redesign.consts';

export function Redesign(props: PropsWithChildren<unknown>) {
  const domNode = document.getElementById(redesignDivId);

  if (!domNode) {
    throw new Error(`No element found with id = ${redesignDivId}`);
  }

  const theme = useTheme();

  useEffect(() => {
    document.body.classList.add(redesignBodyClass);

    return () => {
      document.body.classList.remove(redesignBodyClass);
    };
  }, []);

  return ReactDOM.createPortal(
    <ThemeProvider theme={theme}>
      <SnackbarProvider>{props.children}</SnackbarProvider>
    </ThemeProvider>,
    domNode
  );
}
