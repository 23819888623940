import { TFunction } from 'react-i18next';
import { USER_ROLES } from './constant';

export const makeShortName = (longName: string) => {
  if (!longName) {
    return '';
  }

  const [surname, ...names] = longName.split(' ');
  const abbrs = names.map((name) => `${name.slice(0, 1)}. `);

  return `${surname} ${abbrs.join('')}`;
};

type ProductGroupInfoType = {
  name: string;
  status: string;
  types: ('PRODUCER' | 'IMPORTER' | 'TRADE_PARTICIPANT' | 'WHOLESALER')[];
};

type UserInfoType = {
  authorities: string[];
  client_id: string;
  exp: number;
  full_name: string;
  id: number;
  inn: string;
  jti: string;
  organisation_status: string;
  pid: 1;
  product_group_info: ProductGroupInfoType[];
  roles: [];
  scope: string[];
  showDashboard: boolean;
  showProfileLink: boolean;
  showRMTLink: boolean;
  user_name: null | string;
  user_status: string;
};

const rmtPath = (roles: string[]) => {
  const isModerator = roles.some(
    (role) =>
      role === USER_ROLES.moderator || role === USER_ROLES.superModerator
  );
  return isModerator ? '/tasks' : '/products';
};

export const getItems = ({
  userInfo,
  organizationForm,
  t,
}: {
  userInfo: UserInfoType;
  organizationForm: string;
  t: TFunction<'translation'>;
}) => {
  const { showRMTLink, authorities } = userInfo;
  const icomUrl = `${window.env.REACT_APP_ICOM_URL}/#/orders`;
  const rmtUrl = `${window.env.REACT_APP_RMT_URL}${rmtPath(authorities)}`;

  return [
    {
      id: 1,
      value: 'elk',
      selected: true,
      label: t('Маркировка товаров'),
    },
    ...(userInfo.product_group_info?.find(
      (el: ProductGroupInfoType) =>
        (el.status === '5' || el.status === '4') &&
        (el.types.includes('PRODUCER') || el.types.includes('IMPORTER'))
    )
      ? [
          {
            id: 2,
            value: 'icom',
            selected: false,
            label: t('Заказ кодов'),
            url: icomUrl,
          },
        ]
      : []),
    ...(userInfo.user_status === 'ACTIVE' &&
    showRMTLink &&
    userInfo.organisation_status === 'REGISTERED' &&
    organizationForm !== 'OIV' &&
    userInfo.product_group_info?.find(
      (el: ProductGroupInfoType) => el.status === '5'
    )
      ? [
          {
            id: 2,
            label: t('Реестр товаров'),
            selected: false,
            url: rmtUrl,
            value: 'rmt',
          },
        ]
      : []),
  ];
};
