import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { withForm } from '../../../common_components/Form/Form.utils';
import * as preloaderSelectors from '../../../common_components/Preloader/ducks/Preloader.selectors';

import { CompanyView } from './company-view';
import { setEditing } from './ducks/Company.actions';
import {
  getCompanyFormData,
  getIsForeignLegalEntity,
  getRegistered,
} from './ducks/Company.selectors';

type Company = {
  setEditing: (val: boolean) => void;
  staticContext: unknown;
  isForeignLegalEntity: boolean;
  isLoading: boolean;
  isRegistered: boolean;
  isFormErrors: boolean;
  isEditing: boolean;
  match: {
    isExact: boolean;
    params: Record<string, unknown>;
    path: string;
    url: string;
  };
  location: {
    hash: string;
    key: string;
    pathname: string;
    search: string;
    state: unknown;
  };
};

const Company: React.FC<Company> = (props) => {
  const { startProfileSaga, setEditing, isLoading, isForeignLegalEntity } =
    props;

  useEffect(() => {
    return () => {
      setEditing(false);
    };
  }, [setEditing, startProfileSaga]);

  return React.createElement(CompanyView, {
    isForeignLegalEntity,
    isLoading,
  });
};

const mapStateToProps = (state) => ({
  data: getCompanyFormData(state),
  isLoading: preloaderSelectors.loading(state),
  isRegistered: getRegistered(state),
  isForeignLegalEntity: getIsForeignLegalEntity(state),
});

const mapDispatchToProps = {
  setEditing,
};

export const CompanyConnected = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withForm()
)(Company);
