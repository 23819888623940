import { yup } from '@crpt-ui/form';

export const schema = yup.object({
  contractId: yup.number().required(),
  amount: yup.number().min(100).max(50000).required(),
  phone: yup
    .string()
    .test({
      test: (value) => value && value.length >= 10,
    })
    .required(),
  email: yup.string().email().required(),
  agree: yup.boolean(),
});
