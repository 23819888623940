import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, RequiredFieldsWarning } from '@crpt-ui/core';
import { Box, Grid } from '@crpt/material';

interface HeaderProps {
  loading?: boolean;
  hasErrors: boolean;
  onSubmit: () => void;
  pathToUnloadServiceRegistry: string;
}

const Header: React.FC<HeaderProps> = ({
  onSubmit,
  loading,
  hasErrors,
  pathToUnloadServiceRegistry,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        px: 4,
        py: 2,
      }}
    >
      <Box flexGrow={1}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              component={Link}
              to={pathToUnloadServiceRegistry}
              variant="outlined"
            >
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button disabled={hasErrors} loading={loading} onClick={onSubmit}>
              {t('UnloadService.Task.create')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <RequiredFieldsWarning error={hasErrors} />
      </Box>
    </Box>
  );
};

export default memo(Header);
