import { makeStyles } from '@material-ui/core';

export const useManuallyAgreementDocumentStyles = makeStyles({
  datatable: {
    marginLeft: -32,
    marginRight: -32,

    '& [role=rowgroup]': {
      height: '300px !important',
    },
  },
  close: {
    cursor: 'pointer',
  },
  button: {
    '& button': {
      paddingLeft: '32px !important',
      paddingRight: '32px !important',
    },
  },
  preloader: {
    padding: 20,
  },
});
