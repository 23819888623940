import { createSelector } from 'reselect';

export const getUploadAgreementDocument = (state) =>
  state.Documents.AgreementDocumentsList.UploadAgreementDocument;

export const getOpenUploadModeModal = createSelector(
  [getUploadAgreementDocument],
  (uploadAgreementDocument) => uploadAgreementDocument.open
);

export const getLoading = createSelector(
  [getUploadAgreementDocument],
  (uploadAgreementDocument) => uploadAgreementDocument.loading
);
