import { makeStyles } from '@material-ui/core';

export const useUploadAgreementDocumentStyles = makeStyles({
  upload: {
    border: '1px dashed #d9d9d9',
    borderRadius: 3,
    padding: 40,
    margin: '40px 0 15px',
    textAlign: 'center',
  },
  preloader: {
    padding: 20,
  },
  close: {
    cursor: 'pointer',
  },
  separator: {
    marginLeft: -32,
    marginRight: -32,
    borderTop: '1px solid #f2f2f2',
  },
  error: {
    color: '#ff4b4b',
  },
});
