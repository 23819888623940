import React, { useCallback, useMemo } from 'react';

import { compose } from 'redux';
import { AutocompleteGetTagProps, Chip, Grid } from '@mui/material';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import { CommonDialog, AutocompleteField } from '@ibox/ui';

import { Input } from '../../../../common_components/Form/Input';
import { req } from '../../../../common_components/Form/Form.validations';
import {
  withForm,
  withFormContext,
} from '../../../../common_components/Form/Form.utils';
import { getCompanyState } from '../../Company/ducks/Company.selectors';
import { UserInfo } from '../adding-user-dialog/adding-user-dialog';
import { UserListItem } from '../ducks/UserList.store';
import { checkRights } from '../user-list.constants';
import { UserRoles } from '../../../../constants';

import {
  userRoleOptionsAdmin,
  userRoleOptionsAdminOperator,
} from './editing-user-role.constants';

import css from './editing-user-role.module.scss';

type FormValues = {
  FIO?: string;
  user_role?: string[];
};

type PutUserRolePayload = {
  user_id: number;
  roles: { value: string; label: string }[];
};

type EditingUserRoleViewCompProps = {
  companyID: number;
  selectedUser: UserListItem;
  setSelectedUser: (user: null) => void;
  formValues: FormValues;
  putUserRole: (obj: PutUserRolePayload) => void;
  form: Record<string, unknown>;
  userInfo: UserInfo;
  isParticipantUser?: boolean;
};

const EditingUserRoleViewComp: React.FC<EditingUserRoleViewCompProps> = ({
  companyID,
  selectedUser,
  setSelectedUser,
  formValues,
  putUserRole,
  form,
  userInfo,
  isParticipantUser = false,
}) => {
  const { t } = useTranslation();
  const { user, assignedRoles } = selectedUser || {};
  const { user_role: userRole } = formValues || {};
  const authenticatedUser = useSelector((state) => state.COMMON.Auth.userInfo);

  const dispatch = useDispatch();

  const onCloseModal = useCallback(() => {
    form.reset();
    setSelectedUser(null);
  }, [form, setSelectedUser]);

  const onSubmit = useCallback(() => {
    dispatch(
      putUserRole({
        user_id: user?.id,
        roles: userRole?.map((el) => el.value),
        participantId: isParticipantUser ? companyID : null,
      })
    );
    setSelectedUser(null);
  }, [user, userRole, putUserRole, setSelectedUser, companyID]);

  const optionsByRole = useMemo(() => {
    return checkRights(userInfo?.data)
      ? userRoleOptionsAdminOperator(t)
      : userRoleOptionsAdmin(t);
  }, [t, userInfo]);

  const preparedAssignedRoles = useMemo(
    () =>
      assignedRoles?.map((role) =>
        typeof role === 'string' ? role : role.name
      ),
    [assignedRoles]
  );

  const defaultRoleOption = useMemo(
    () =>
      optionsByRole.filter(({ value }) =>
        preparedAssignedRoles?.includes(value)
      ),
    [preparedAssignedRoles, optionsByRole]
  );

  const handleParseRoles = (values: { label: never; value: string }[]) => {
    const isCoordinator = values.some((role) => role?.value === 'COORDINATOR');
    const isUser = values.some((role) => role?.value === 'USER');

    if (userRole) {
      if (!isUser && isCoordinator && userRole.length > values.length) {
        return values.filter((role) => role.value !== 'COORDINATOR');
      }
    }

    if (isCoordinator && !isUser) {
      return [...values, { label: t('Сотрудник'), value: 'USER' }];
    }

    return values;
  };

  const authenticatedUserIsAdmin = useMemo(
    () => authenticatedUser?.authorities.includes(UserRoles.ADMIN),
    [authenticatedUser]
  );

  return (
    <CommonDialog
      className={css.dialog}
      invalid={!userRole?.length}
      labelCancel={t('Отменить')}
      labelSubmit={t('Добавить')}
      open={!!selectedUser}
      size="sm"
      title={t('Изменить роль')}
      onClose={onCloseModal}
      onSubmit={onSubmit}
    >
      <Grid container className={css.modal} direction="column" spacing={2}>
        <Grid container item xs={12}>
          <Input
            disabled
            className={css.input}
            defaultValue={user?.fullName}
            label={t('Фамилия Имя Отчество')}
            name="FIO"
          />
        </Grid>
        {selectedUser ? (
          <Grid item xs={12}>
            <Field
              fullWidth
              multiple
              required
              textView
              component={AutocompleteField}
              defaultValue={defaultRoleOption}
              disablePortal={false}
              getOptionDisabled={(option: { label: string; value: string }) =>
                option.value === 'ADMIN' &&
                selectedUser?.user?.id === authenticatedUser?.id &&
                authenticatedUserIsAdmin
              }
              label={t('Роль пользователя')}
              name="user_role"
              options={optionsByRole}
              parse={handleParseRoles}
              renderTags={(
                tagValue: { label: string; value: string }[],
                getTagProps: AutocompleteGetTagProps
              ) => {
                return tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    className={css.chip}
                    disabled={
                      option.value === 'ADMIN' &&
                      selectedUser?.user?.id === authenticatedUser?.id &&
                      authenticatedUserIsAdmin
                    }
                    key={option.value}
                    label={option.label}
                  />
                ));
              }}
              validate={req}
            />
          </Grid>
        ) : null}
      </Grid>
    </CommonDialog>
  );
};

const mapStateToProps = (state) => ({
  userInfo: getCompanyState(state),
});

export const EditingUserRoleView = compose(
  withForm(),
  withFormContext({
    values: true,
    form: true,
  })
)(connect(mapStateToProps)(EditingUserRoleViewComp));
