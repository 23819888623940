interface valueWithEllipsis {
  (
    str: string,
    params?: {
      maxLength: number;
      tailLength: number;
    }
  ): string;
}

export const valueWithEllipsis: valueWithEllipsis = (
  str,
  params = { maxLength: 36, tailLength: 6 }
) => {
  const { maxLength, tailLength } = params;
  const startLength = maxLength - (tailLength + 3);
  return str.length <= maxLength
    ? str
    : `${str.slice(0, startLength)}...${str.slice(-tailLength)}`;
};
