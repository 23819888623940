import { get } from 'lodash';

const path = 'ServiceDesk.SignDocuments';

export const documents = (state) => get(state, `${path}.documents`);
export const open = (state) => get(state, `${path}.open`);
export const signing = (state) => get(state, `${path}.signing`);
export const error = (state) => get(state, `${path}.error`);
export const getSignDocumentsInfo = (state) =>
  get(state, `${path}.SignDocumentsInfo`);
