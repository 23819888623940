import { createActions } from 'redux-actions';

export const subject = '[PAYMENT DOCUMENTS]';

export const {
  setDefaultAccount,
  fetchPaymentDocuments,
  setPaymentDocuments,
  setSelectedAccount,
} = createActions(
  {
    SET_DEFAULT_ACCOUNT: () => {},

    FETCH_PAYMENT_DOCUMENTS: (payload) => payload,

    SET_PAYMENT_DOCUMENTS: (payload) => payload,
    SET_SELECTED_ACCOUNT: (payload) => payload,
  },
  {
    prefix: 'PAYMENT_DOCUMENTS',
  }
);
