import { yup } from '@crpt-ui/form';
import moment from 'moment';
import { commonErrorMessages, FileFormat } from '../../constants';
import { MAX_DATE_PERIOD_DAYS } from './AcsUnloads.constants';
import { DefaultFieldIds } from './AcsUnloads.types';

import type { DateSchema } from 'yup';

export default yup.object().shape(
  {
    [DefaultFieldIds.NAME]: yup
      .string()
      .min(10, commonErrorMessages.MIN_LENGTH)
      .required(commonErrorMessages.REQUIRED),
    [DefaultFieldIds.PRODUCT_GROUP_CODE]: yup
      .number()
      .required(commonErrorMessages.REQUIRED),
    [DefaultFieldIds.DATA_START_DATE]: yup
      .date()
      .typeError(commonErrorMessages.DATE_TYPE)
      .required(commonErrorMessages.REQUIRED)
      .max(moment().endOf('day').toDate(), commonErrorMessages.MAX_DATE)
      .when(DefaultFieldIds.DATA_END_DATE, {
        is: (date) => Boolean(date) && moment(date).isValid(),
        then: (schema: DateSchema) =>
          schema.max(
            yup.ref(DefaultFieldIds.DATA_END_DATE),
            commonErrorMessages.MAX_DATE
          ),
      })
      .when(DefaultFieldIds.DATA_END_DATE, (date, schema) => {
        return Boolean(date) && moment(date).isValid()
          ? schema.min(
              moment(date)
                .subtract(MAX_DATE_PERIOD_DAYS, 'days')
                .startOf('day')
                .toDate(),
              commonErrorMessages.MIN_DATE
            )
          : schema;
      }),
    [DefaultFieldIds.DATA_END_DATE]: yup
      .date()
      .typeError(commonErrorMessages.DATE_TYPE)
      .required(commonErrorMessages.REQUIRED)
      .max(moment().endOf('day').toDate(), commonErrorMessages.MAX_DATE)
      .when(DefaultFieldIds.DATA_START_DATE, {
        is: (date) => Boolean(date) && moment(date).isValid(),
        then: (schema: DateSchema) =>
          schema.min(
            yup.ref(DefaultFieldIds.DATA_START_DATE),
            commonErrorMessages.MIN_DATE
          ),
      })
      .when(DefaultFieldIds.DATA_START_DATE, (date, schema) => {
        if (!date || !moment(date).isValid()) {
          return schema;
        }

        const absoluteMaxDate = moment().endOf('day').toDate();
        const relativeMaxDate = moment(date)
          .add(MAX_DATE_PERIOD_DAYS, 'days')
          .endOf('day')
          .toDate();
        const maxDate =
          relativeMaxDate > absoluteMaxDate ? absoluteMaxDate : relativeMaxDate;

        return schema.max(maxDate, commonErrorMessages.MAX_DATE);
      }),
    [DefaultFieldIds.FILE_FORMAT]: yup
      .string()
      .required(commonErrorMessages.REQUIRED)
      .oneOf(Object.values(FileFormat)),
    [DefaultFieldIds.USERS]: yup.array(yup.string()),
  },
  [[DefaultFieldIds.DATA_START_DATE, DefaultFieldIds.DATA_END_DATE]]
);
