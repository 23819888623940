import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minWidth: 312,
      height: 256,
      border: 'none',
      padding: theme.spacing(3),
      borderRadius: '4px',
      '&:hover': {
        boxShadow: '0px 35px 100px rgba(0, 0, 0, 0.2)',
      },
    },
    iconWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '72px',
      height: '72px',
      background: theme.palette.primary.main,
      borderRadius: '4px',
    },
    icon: {
      width: '40px',
      height: '40px',
    },
    content: {
      padding: 0,
    },
    actions: {
      padding: 0,
    },
    title: {
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
    },
    link: {
      fontSize: '14px',
      minWidth: 0,
      display: 'flex',
      flexWrap: 'nowrap',
      fontWeight: 600,
      alignItems: 'center',
    },
    chevron: {
      transform: 'rotate(-90deg)',
    },
  });
