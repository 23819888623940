import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import { Button, TextFieldFinalFormWrapper } from '@ibox/ui';

import { FormContext } from '../../../common_components/Form/Form.constants';
import { cleanErrors } from '../../../common_components/Form/Form.utils';
import { ErrorNotification } from '../LoginForm.styled';

type FieldsViewProps = {
  errors: unknown;
  disabled: boolean;
};

export const FieldsView = ({ errors, disabled }: FieldsViewProps) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Field
            fullWidth
            required
            component={TextFieldFinalFormWrapper}
            disabled={disabled}
            label={t('Пользователь')}
            name="login"
          />
        </Grid>
        <Grid item>
          <Field
            fullWidth
            required
            component={TextFieldFinalFormWrapper}
            disabled={disabled}
            label={t('Пароль')}
            name="password"
            type="password"
          />
        </Grid>
      </Grid>
      <Box mt={4}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {errors && <ErrorNotification>{errors}</ErrorNotification>}
          </Grid>
          <Grid item>
            <FormContext.Consumer>
              {({ errors: formErrors }) => (
                <Button
                  disabled={!isEmpty(cleanErrors(formErrors))}
                  type="submit"
                >
                  {t('Войти')}
                </Button>
              )}
            </FormContext.Consumer>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

FieldsView.propTypes = {
  disabled: PropTypes.bool.isRequired,
  errors: PropTypes.string,
};

FieldsView.defaultProps = {
  errors: null,
};
