import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const TableCell = ({ classes, children, error, role, ...rest }) => {
  return (
    <div
      className={clsx(classes.root, {
        [classes.td]: role === 'cell',
        [classes.th]: role === 'columnheader',
        [classes.error]: !!error,
      })}
      role={role}
      {...rest}
    >
      {children}
    </div>
  );
};

export default withStyles((theme) => ({
  root: {
    alignItems: 'center',
    boxShadow: 'inset 0px -1px 0px #f2f2f2, inset -1px 0px 0px #f2f2f2',
    display: 'flex',
    minHeight: 64,
    padding: '0 8px',
  },
  td: {
    fontSize: 12,
    lineHeight: 16 / 12,
  },
  th: {
    fontSize: 10,
    fontWeight: 700,
    letterSpacing: '0.02em',
    lineHeight: 16 / 10,
    textTransform: 'uppercase',
  },
  error: {
    background: '#fef6f6',
    color: theme.palette.error.main,
  },
}))(TableCell);
