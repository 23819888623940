import React, { FC, useMemo, memo } from 'react';
import { Grid } from '@material-ui/core';
import { Scenario } from './Threads.types';
import { getNodes } from './utils';
import { Tree } from './components';

export interface RenderDocumentLink {
  (uuid: string, type: string): React.ReactNode;
}

export interface RenderParticipantLink {
  (inn: string): React.ReactNode;
}

export interface ThreadsProps {
  renderDocumentLink?: RenderDocumentLink;
  renderParticipantLink?: RenderParticipantLink;
  scenario: Scenario;
}

export const Threads: FC<ThreadsProps> = ({
  renderDocumentLink = (uuid) => uuid,
  renderParticipantLink = inn => inn,
  scenario
}) => {
  const nodes = useMemo(() => getNodes(scenario), [scenario]);

  return (
    <Grid container wrap="nowrap">
      {nodes.map((node) => (
        <Grid item key={node.id}>
          <Tree
            node={node}
            renderDocumentLink={renderDocumentLink}
            renderParticipantLink={renderParticipantLink}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default memo(Threads);
