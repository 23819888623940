import { get } from 'lodash';

import { yup } from '@crpt-ui/form';

export const schema = yup.object({
  edoOperators: yup
    .array()
    .of(
      yup.object().shape({
        edoOperatorId: yup.string(),
        participantEdoId: yup.string().test({
          name: 'unique',
          message: 'У этого оператора ЭДО уже существует такой ID абонента',
          test: function (value) {
            const list = this.options.context.edoOperators;
            const mapper = (x) => get(x, 'participantEdoId');

            if (!list) {
              return;
            }

            const duplicatesEdoOperatorId = list
              .map((i, index) => {
                if (
                  i.edoOperatorId === list[this.options.index].edoOperatorId
                ) {
                  return { ...i, index };
                }
              })
              .filter((x) => x);

            if (duplicatesEdoOperatorId.length === 1) {
              return true;
            }

            const set = [...new Set(duplicatesEdoOperatorId.map(mapper))];
            const isUnique = duplicatesEdoOperatorId.length === set.length;

            if (isUnique) {
              return true;
            }

            const tempValue = duplicatesEdoOperatorId.map(mapper).slice();
            const index = tempValue.indexOf(value);
            if (index > -1) {
              tempValue.splice(index, 1);
            }

            if (tempValue.includes(value)) {
              return this.createError();
            } else {
              return true;
            }
          },
        }),
      })
    )
    .required(),
});
