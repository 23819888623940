import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import { HttpMethodEnum } from '../../../../constants/index';
import {
  getFilename,
  saveFile,
} from '../../../../common_components/Document/Document.utils';
import { Api } from '../../../../common_components/Api/Api';
import { prepareCertificateDate } from '../../../../common_components/Cades/Cades.utils';
import {
  openSignModalById,
  successfullySignedAgreementDocuments,
} from '../SignModal/ducks/SignModal.actions';
import { setSigningProcess } from '../../../../common_components/DocumentSigningModal/ducks/DocumentSigningModal.actions';
import { signContent } from '../../../../common_components/Cades/Cades.saga';

import * as agreementDocumentActions from './AgreementDocument.actions';
import {
  getAgreementDocumentId,
  getDocumentType,
} from './AgreementDocument.selectors';

function* prepareDocumentInfoData(documentInfo) {
  let certificate;
  const { id, status, statusCode, trustedInns, sign, documentRegNumber } =
    documentInfo;

  if (sign) {
    const date = yield call(
      prepareCertificateDate,
      sign.validityDateFrom,
      sign.validityDateUpTo
    );
    certificate = {
      date,
      fingerPrint: sign.fingerPrint,
      organisation: sign.organisationName,
      inn: sign.inn,
    };
  }

  return {
    id,
    documentRegNumber,
    status,
    statusCode,
    trustedInns,
    certificate,
  };
}

function* fetchAgreementDocumentInfo(documentId) {
  const documentType = yield select(getDocumentType);
  const requestPayload = {
    url: `/api/v3/facade/agreement-registry/${documentType}/${documentId}`,
  };

  const [documentInfo] = yield call(Api.request, requestPayload);

  if (documentInfo) {
    const preparedDocumentInfo = yield call(
      prepareDocumentInfoData,
      documentInfo.data
    );

    yield put(
      agreementDocumentActions.setAgreementDocumentInfo(preparedDocumentInfo)
    );
  }
}

export function* fetchAgreementDocumentContent(id) {
  const documentType = yield select(getDocumentType);
  const requestPayload = {
    url: `/api/v3/facade/agreement-registry/${documentType}/${id}/print-form/pdf`,
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
    },
  };

  const [documentContent] = yield call(Api.request, requestPayload);

  if (documentContent) {
    yield put(
      agreementDocumentActions.setAgreementDocumentContent(documentContent.data)
    );
  }
}

function* fetchAgreementDocumentPrintForm({ id, preloading }) {
  const documentType = yield select(getDocumentType);
  const requestPayload = {
    url: `/api/v3/facade/agreement-registry/${documentType}/${id}/print-form`,
    responseType: 'arraybuffer',
  };

  const [documentPrintForm] = yield call(Api.request, requestPayload, {
    preloading,
  });

  if (documentPrintForm) {
    return documentPrintForm.data;
  }
}

function* definitionDocumentType(pathname) {
  if (pathname.indexOf('cancellation') !== -1) {
    yield put(agreementDocumentActions.setDocumentType('cancellation'));
  }
}

function* agreementDocumentSaga({ payload: { id, pathname } }) {
  yield call(definitionDocumentType, pathname);

  yield all([
    fetchAgreementDocumentContent(id),
    fetchAgreementDocumentInfo(id),
  ]);
}

export function* signAgreementDocumentSaga({ documentId, preloading }) {
  yield put(setSigningProcess(true));

  const documentPrintForm = yield call(fetchAgreementDocumentPrintForm, {
    id: documentId,
    preloading,
  });
  const signature = yield call(signContent, documentPrintForm);

  const documentType = yield select(getDocumentType);

  const requestPayload = {
    url: `/api/v3/facade/agreement-registry/${documentType}/publish`,
    method: HttpMethodEnum.POST,
    data: {
      documentId,
      signature,
    },
  };

  const [successSign] = yield call(Api.request, requestPayload, {
    successMessage: 'Документ подписан и отправлен',
    preloading,
  });

  yield put(setSigningProcess(false));

  if (successSign) {
    return successSign.data;
  }
}

function* declineAgreementDocumentProcess() {
  const successDecline = yield call(declineAgreementDocumentSaga);

  if (successDecline) {
    const documentId = yield select(getAgreementDocumentId);
    yield call(fetchAgreementDocumentInfo, documentId);
  }
}

export function* declineAgreementDocumentSaga() {
  const documentType = yield select(getDocumentType);
  const requestPayload = {
    url: `/api/v3/facade/agreement-registry/${documentType}/decline`,
    method: HttpMethodEnum.POST,
  };

  const [successDecline] = yield call(Api.request, requestPayload);

  return successDecline;
}

function* cancellationAgreementDocumentSaga() {
  const requestPayload = {
    url: '/api/v3/facade/agreement-registry/cancellation',
    method: HttpMethodEnum.POST,
  };

  const [successCancellation] = yield call(Api.request, requestPayload);

  if (successCancellation) {
    yield put(
      replace(
        `/documents/agreement/cancellation/${successCancellation.data.id}`
      )
    );
  }
}

function* downloadXlsAgreementDocument({ payload: id }) {
  const requestPayload = {
    url: `/api/v3/facade/agreement-registry/agreement/${id}/print-form/xls`,
    responseType: 'arraybuffer',
  };

  const [success] = yield call(Api.request, requestPayload, {
    preloading: false,
  });

  if (success) {
    yield call(saveFile, {
      filename: getFilename(success),
      content: success.data,
      type: 'application/vnd.ms-excel',
    });
  }
}

function* signAgreementDocumentFromDocument({ payload: { documentId } }) {
  yield put(openSignModalById(documentId));

  yield take(successfullySignedAgreementDocuments);

  yield call(fetchAgreementDocumentContent, documentId);
  yield call(fetchAgreementDocumentInfo, documentId);
}

export const saga = function* watch() {
  yield takeEvery(
    agreementDocumentActions.startAgreementDocumentSaga,
    agreementDocumentSaga
  );
  yield takeEvery(
    agreementDocumentActions.declineAgreementDocument,
    declineAgreementDocumentProcess
  );
  yield takeEvery(
    agreementDocumentActions.cancellationAgreementDocument,
    cancellationAgreementDocumentSaga
  );
  yield takeEvery(
    agreementDocumentActions.startSignAgreementDocumentFromDocument,
    signAgreementDocumentFromDocument
  );
  yield takeEvery(
    agreementDocumentActions.downloadXlsAgreementDocument,
    downloadXlsAgreementDocument
  );
};
