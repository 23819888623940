import instance, { AxiosRequestConfig, AxiosResponse } from '../axios';

export class ApiService {
  constructor(path: string) {}

  get<T>(url: string, config?: AxiosRequestConfig) {
    return instance.get<T>(this.path + url, config);
  }

  post<T, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig<D>) {
    return instance.post<D, AxiosResponse<T>>(this.path + url, data, config);
  }

  put<T, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig<D>) {
    return instance.put<D, AxiosResponse<T>>(this.path + url, data, config);
  }

  options<T>(url: string, config?: AxiosRequestConfig) {
    return instance.options<T>(this.path + url, config);
  }
}

export default ApiService;
