import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { FormType } from '../../../../constants/PropTypes';
import * as companySelectors from '../../../_Profile/Company/ducks/Company.selectors';
import {
  withForm,
  withFormContext,
} from '../../../../common_components/Form/Form.utils';
import { getAccountsOptions } from '../ducks/PaymentDocumentsList.selectors';

import { CreatePaymentDocumentView } from './CreatePaymentDocument.view';
import {
  closeCreatePaymentModal,
  sendPaymentDocument,
} from './ducks/CreatePaymentDocument.actions';
import {
  getLoading,
  getOpenCreatePaymentModal,
  getSelectedAccount,
} from './ducks/CreatePaymentDocument.selectors';

const CreatePaymentDocument = ({
  closeCreatePaymentModal,
  sendPaymentDocument,
  accountOptions,
  email,
  selectedAccount,
  formValues,
  form,
  isOpenModal,
  isLoading,
  isFormErrors,
}) => {
  const onSubmit = useCallback(() => {
    sendPaymentDocument({ values: formValues });
  }, [formValues, sendPaymentDocument]);

  const onCloseModal = useCallback(() => {
    closeCreatePaymentModal();
    form.reset();
  }, [closeCreatePaymentModal, form]);

  const modalActions = useMemo(
    () => [
      {
        label: 'Заказать',
        callback: onSubmit,
        disabled: isFormErrors || isLoading,
        loading: isLoading,
      },
      {
        label: 'Отменить',
        callback: onCloseModal,
        variant: 'outlined',
      },
    ],
    [onSubmit, isFormErrors, isLoading, onCloseModal]
  );

  return React.createElement(CreatePaymentDocumentView, {
    onCloseModal,
    selectedAccount,
    accountOptions,
    email,
    modalActions,
    isOpenModal,
    isFormErrors,
  });
};

const mapStateToProps = (state) => ({
  email: companySelectors.getEmail(state),
  accountOptions: getAccountsOptions(state),
  selectedAccount: getSelectedAccount(state),
  isOpenModal: getOpenCreatePaymentModal(state),
  isLoading: getLoading(state),
});

const mapDispatchToProps = {
  closeCreatePaymentModal,
  sendPaymentDocument,
};

CreatePaymentDocument.propTypes = {
  accountOptions: PropTypes.arrayOf(PropTypes.object),
  selectedAccount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  closeCreatePaymentModal: PropTypes.func.isRequired,
  sendPaymentDocument: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  form: FormType,
  email: PropTypes.string.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isFormErrors: PropTypes.bool.isRequired,
};

export const CreatePaymentDocumentConnected = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withForm(),
  withFormContext({ values: true, errors: true, form: true })
)(CreatePaymentDocument);
