import { makeRadioGroupFilter } from '@crpt-ui/datatable';

import {
  documentTypesValues,
  documentTypesEnum,
} from '../../../../../constants/documents';

// TODO: нужна правильная фильтрация
export const documentTypesFilterOptionsList = [
  documentTypesEnum.AGGREGATION_DOCUMENT,
  documentTypesEnum.WRITE_OFF,
  // documentTypesEnum.IMPORT,
  // documentTypesEnum.IMPORT_TRANSIT,
  documentTypesEnum.INTRODUCE_GOODS,
  // documentTypesEnum.UPD,
  // documentTypesEnum.UPDi,
  // documentTypesEnum.INTERNAL_TRANSFER,
  // documentTypesEnum.INTERNAL_TRANSFERi,
  // documentTypesEnum.WITHDROWN_DOC,
  // documentTypesEnum.WITHDROWN_DOCi,
];

const options = Object.keys(documentTypesEnum)
  .filter((type) => documentTypesFilterOptionsList.includes(type))
  .map((type) => ({
    value: type,
    label: documentTypesValues[type],
  }));

export const DocumentType = makeRadioGroupFilter({ options });
