import React, { FC, useMemo } from 'react';
import { get } from 'lodash';
import clsx from 'clsx';
import { Grid, Box, WithStyles, withStyles } from '@material-ui/core';
import moment from 'moment';
import { Icon, Tooltip } from '@crpt-ui/core';
import { AggregationTooltip } from '../index';
import { NODE_TYPE } from './Node.constants';
import NodeStyles from './Node.styles';
import { RenderDocumentLink, RenderParticipantLink } from '../../Threads';
import { NodeType } from '../../Threads.types';

type NodeProps = {
  node: any;
  children: any;
  renderDocumentLink: RenderDocumentLink;
  renderParticipantLink: RenderParticipantLink;
} & WithStyles<typeof NodeStyles>;

const Node: FC<NodeProps> = ({
  node,
  children,
  classes,
  renderDocumentLink,
  renderParticipantLink,
}) => {
  const type = useMemo(
    () => get(NODE_TYPE, [node.data.type, 'name'], node.data.type),
    [node.data.type]
  );

  const icon = useMemo(() => {
    const iconName = get(NODE_TYPE, [node.data.type, 'icon']);

    return iconName ? <Icon name={iconName} /> : null;
  }, [node.data.type]);

  const docDate = useMemo(
    () =>
      node.data.fake
        ? '-'
        : moment(+node.data.docDate).format('DD.MM.yyyy в HH:mm:ss'),
    [node.data.docDate, node.data.fake]
  );

  const aggregationCode = useMemo(
    () =>
      node.data.docType === 'PARENT_AGGREGATION'
        ? node.data.parent
        : node.data.code,
    [node.data.code, node.data.docType, node.data.parent]
  );

  const violationTitle = useMemo(
    () =>
      node.data.violation ? (
        <Box textAlign="center">
          {`Зафиксировано отклонение ${node.data.violation}`}
        </Box>
      ) : null,
    [node.data.violation]
  );

  return (
    <>
      <div
        className={classes.outter}
        style={{
          left: node.x,
          top: node.y,
          position: 'absolute',
          padding: 16,
        }}
      >
        <div
          className={clsx(classes.root, {
            [classes.fake]: node.data.fake,
          })}
        >
          <Grid
            alignItems="center"
            className={classes.h}
            container
            wrap="nowrap"
          >
            <Grid item>
              <div className={classes.icon}>{icon}</div>
            </Grid>
            <Grid alignItems="center" container item justify="space-between">
              <Grid item>
                <div className={classes.date}>{docDate}</div>
                <div className={classes.title}>{type}</div>
              </Grid>
              <Grid
                alignItems="center"
                container
                item
                justify="flex-end"
                xs={3}
              >
                {node.data.type === NodeType.AGGREGATION ? (
                  <Grid item>
                    <AggregationTooltip code={aggregationCode} />
                  </Grid>
                ) : null}
                {violationTitle ? (
                  <Grid item>
                    <Tooltip
                      interactive
                      placement="bottom"
                      title={violationTitle}
                    >
                      <Box color="error.main">
                        <Icon name="Error" />
                      </Box>
                    </Tooltip>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          {node.data.type !== NodeType.OWNER_CHANGE && (
            <div className={classes.b}>
              {renderDocumentLink(node.data.docId, node.data.docType)}
            </div>
          )}
          <div className={classes.b}>
            <Icon
              name={node.data.seller ? 'Users' : 'User'}
              style={{
                margin: '0 -4px',
              }}
            />
            <span
              style={{
                marginLeft: 8,
              }}
            >
              {node.data.seller ? (
                <>
                  {renderParticipantLink(node.data.seller)}
                  &nbsp; → &nbsp;
                  {renderParticipantLink(node.data.buyer)}
                </>
              ) : (
                renderParticipantLink(node.data.owner)
              )}
            </span>
          </div>
        </div>
      </div>
      {children ? <>{children}</> : null}
    </>
  );
};

export default withStyles(NodeStyles)(Node);
