import { Input } from '@crpt-ui/core';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import { generateFilter } from '../Datatable.utils';

export const DefaultFilter = React.memo(
  ({ column, form, formState, instance, ...rest }) => {
    const value = useMemo(() => get(formState, 'values.value', ''), [
      formState.values,
    ]);

    const onChange = useCallback((event) => {
      form.change('value', event.target.value);
    }, []);

    return <Input {...rest} name="value" value={value} onChange={onChange} />;
  }
);

export const makeInputFilter = generateFilter(DefaultFilter);

DefaultFilter.propTypes = {
  column: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  instance: PropTypes.object.isRequired,
};
