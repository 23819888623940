import { Box, Stack, StackProps } from '@crpt/material';
import React, { memo } from 'react';
import { Char } from '../Char';

export interface TwoLinesCellProps extends Omit<StackProps, 'title'> {
  title?: string | React.ReactNode | null;
  underline?: string | React.ReactNode | null;
}

function TwoLinesCell(props: TwoLinesCellProps) {
  const { title, underline, ...stackProps } = props;
  return title || underline ? (
    <Stack {...stackProps}>
      <Box
        style={{
          fontWeight: 600,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {title}
      </Box>
      <Box width="inherit">{underline}</Box>
    </Stack>
  ) : (
    <Char name="largeDash" />
  );
}

export default memo<TwoLinesCellProps>(TwoLinesCell);
