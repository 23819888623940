import { createSelector } from 'reselect';

const getAddingUserDialogState = (state) =>
  state.Profile.UserList['adding-user-dialog'];

export const getOpenAddingUserDialog = createSelector(
  [getAddingUserDialogState],
  (state) => state.isOpenAddingUserDialog
);

export const getOpenCertificateSelectionDialog = createSelector(
  [getAddingUserDialogState],
  (state) => state.isOpenCertificateSelectionDialog
);

export const getSelectCertificate = createSelector(
  [getAddingUserDialogState],
  (state) => state.certificate
);

export const getLoading = createSelector(
  [getAddingUserDialogState],
  (state) => state.loading
);

/**
 * Returns prepared data for the form
 *
 * @type {function}
 * @returns {{
 *   surname: string,
 *   name: string,
 *   patronymic: string,
 *   email: string,
 *   user_role: string,
 * }}
 */
export const getPrepareDataForForm = createSelector(
  [getSelectCertificate],
  ({ fullName, email }) => ({
    fullName,
    email,
    user_role: 'ADMIN',
  })
);
