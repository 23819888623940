import React, { useMemo } from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';

import { Input } from '../../../../common_components/Form/Input';
import {
  // getDisabledFields,
  getSoleProprietorship,
  isFarmer,
} from '../ducks/Company.selectors';
import { orgTypeOptions } from '../../../ParticipantDetails/ParticipantDetails.constants';
import { Select } from '../../../../common_components/Form/Select';

type CommonForm = {
  isSoleProprietorship: boolean;
  isFarmer: boolean;
};

export const CommonForm: React.FC<CommonForm> = ({
  isSoleProprietorship,
  isFarmer,
}) => {
  const { t } = useTranslation();
  const nameLabel = useMemo(
    () =>
      isSoleProprietorship
        ? 'Наименование индивидуального предпринимателя'
        : 'Полное наименование',
    [isSoleProprietorship]
  );

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h6">{t('Общие данные')}</Typography>
      </Grid>
      {!isSoleProprietorship && (
        <Grid item>
          <Input
            disabled
            fullWidth
            label={t('Краткое наименование')}
            name="shortName"
          />
        </Grid>
      )}
      <Grid item>
        <Input disabled fullWidth label={t(nameLabel)} name="fullName" />
      </Grid>
      <Grid item>
        <Select
          disabled
          label={t('Организационно-правовая форма')}
          name="organizationForm"
          optionLabel="label"
          options={orgTypeOptions}
        />
      </Grid>
      <Grid item>
        <Input disabled fullWidth label={t('ИНН')} name="inn" />
      </Grid>
      {isFarmer && (
        <Grid item>
          <Input
            disabled
            fullWidth
            hint="Коды идентификации в товарной группе «Молочная продукция» предоставляются бесплатно для крестьянских (фермерских) хозяйств / сельскохозяйственных производственных кооперативов до 1 декабря 2022 года"
            label={t(
              'Крестьянское (фермерское) хозяйство/Сельскохозяйственный производственный кооператив'
            )}
            name="isFarmer"
          />
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  // disabled: getDisabledFields(state),
  isSoleProprietorship: getSoleProprietorship(state),
  isFarmer: isFarmer(state),
});

export const CommonFormConnected = connect(mapStateToProps)(CommonForm);
