export enum ReceiptTypeCodeEnum {
  RECEIPT = 'RECEIPT',
  RECEIPT_RETURN = 'RECEIPT_RETURN',
  RECEIPT_CORRECTION = 'RECEIPT_CORRECTION',
  RECEIPT_CORRECTION_FOR_RETURN = 'RECEIPT_CORRECTION_FOR_RETURN ',
}

export enum ReceiptTypeIdEnum {
  RECEIPT = 1,
  RECEIPT_RETURN = 2,
  RECEIPT_CORRECTION = 3,
  RECEIPT_CORRECTION_FOR_RETURN = 4,
}
