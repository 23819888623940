import { NodeType } from '../../Threads.types';

export const NODE_TYPE = {
  [NodeType.AGGREGATION]: {
    name: 'Агрегирование',
    icon: "Aggregation",
  },
  [NodeType.EMISSION]: {
    name: 'Эмиссия',
    icon: "CodeEmission",
  },
  [NodeType.INTRODUCTION]: {
    name: 'Ввод в оборот',
    icon: "CirculationInput",
  },
  [NodeType.OWNER_CHANGE]: {
    name: 'Смена собственника',
    icon: "Violations",
  },
  [NodeType.SOLD_OUT]: {
    name: 'Вывод из оборота',
    icon: "CirculationWithdrawal",
  },
  [NodeType.UTILISATION]: {
    name: 'Нанесение',
    icon: "Application",
  },
};
