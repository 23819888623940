import React, { memo } from 'react';

import { DownloadFileStatus } from '@crpt/shared/services';
import { useStyles } from './Status.styles';
import { Typography } from '@crpt-ui/core';

interface StatusProps {
  status?: DownloadFileStatus;
}

const { PREPARATION, FAILED } = DownloadFileStatus;

const Status: React.FC<StatusProps> = ({ status }) => {
  const classes = useStyles();

  switch (status) {
    case PREPARATION:
      return (
        <Typography>
          Список ошибок&nbsp;
          <span className={classes.preparingStatus}>формируется</span>
        </Typography>
      );
    case FAILED:
      return (
        <Typography noWrap>
          <span className={classes.failedStatus}>Не удалось</span>
          &nbsp;сформировать список ошибок
        </Typography>
      );
    default:
      return null;
  }
};

export default memo(Status);
