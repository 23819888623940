import React, { useEffect, useMemo } from 'react';

import { Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { BreadCrumbs } from '@ibox/ui';
import { Content } from '@crpt/shared/components/Content';
import { Route } from '@crpt/shared/components/Route/Route';

import { Preloader as ContentPreloader } from '../../fragments/Table/Preloader';
import { history } from '../../store';
import { translate } from '../Translate/Translate';
import * as authSelectors from '../../common_components/Auth/ducks/Auth.selectors';

import { getTabs } from './ParticipantDetails.constants';
import { ParticipantDetailsTabs } from './components/ParticipantDetailsTabs';
import { BasicInfoConnected } from './components/basicInfo';
import * as actions from './ducks/ParticipantDetails.actions';
import * as selectors from './ducks/ParticipantDetails.selectors';
import { DocumentList } from './components/documents';
import { Receipts } from './components/receipts/receipts';
import { Users } from './components/users/Users';

const ParticipantDetailsComponent = ({
  match,
  selectedParticipant,
  onMounted,
  loading,
  onUnmounted,
  isOIV,
}) => {
  const inn = useMemo(() => match && match.params.inn, [match]);
  const { t } = useTranslation();
  const tabs = useMemo(() => getTabs(inn, t), [inn, t]);
  const location = useLocation();

  const currentIndex = tabs.findIndex(
    (v) => ~v.href.indexOf(window.location.pathname)
  );

  const tabsItems = () =>
    tabs
      .filter(({ value }) => (value === 3 ? !isOIV : true))
      .map((tab, i) => {
        return i === currentIndex
          ? tab
          : {
              ...tab,
              onClick: () => {
                history.push(tab.href);
              },
            };
      });

  useEffect(() => {
    if (
      window.location.pathname === `/participants/list/${inn}` ||
      window.location.pathname === `/participants/list/${inn}/`
    ) {
      history.replace(`/participants/list/${inn}/basic-info`);
    }
    const formated = decodeURIComponent(inn);
    onMounted(formated);
    return onUnmounted;
  }, [inn]);

  const dataList = useMemo(
    () => [
      {
        href: '/participants',
        label: t('Участники'),
      },
      {
        href: `/participants/list/${inn}/basic-info`,
        label: `${t('Участник')} ${inn}`,
      },
      {
        href: '',
        label: `${
          tabs.find((el) => el.href === location?.pathname)?.label || ''
        }`,
      },
    ],
    [inn, location.pathname, t, tabs]
  );

  return (
    <>
      <div className="breadcrumbsWrap">
        <BreadCrumbs data={dataList} />
      </div>
      <Box mt="20px">
        <ParticipantDetailsTabs items={tabsItems()} value={currentIndex} />
      </Box>
      <Content>
        <ContentPreloader
          isOpen={loading}
          text={translate('Загрузка данных')}
        />
        {!loading && (
          <Switch>
            <Route
              path={`/participants/list/${inn}/basic-info`}
              render={() => (
                <BasicInfoConnected selectedParticipant={selectedParticipant} />
              )}
            />
            <Route
              path={`/participants/list/${inn}/documents`}
              render={(props) => (
                <DocumentList
                  inn={inn}
                  {...props}
                  selectedParticipant={selectedParticipant}
                />
              )}
            />
            <Route
              path={`/participants/list/${inn}/receipts`}
              render={(props) => (
                <Receipts
                  inn={inn}
                  {...props}
                  selectedParticipant={selectedParticipant}
                />
              )}
            />
            <Route
              path={`/participants/list/${inn}/users`}
              render={(props) => (
                <Users
                  inn={inn}
                  {...props}
                  selectedParticipant={selectedParticipant}
                />
              )}
            />
          </Switch>
        )}
      </Content>
    </>
  );
};

const mapState = (state) => ({
  selectedParticipant: selectors.results(state),
  loading: selectors.loading(state),
  isOIV: authSelectors.isOIV(state),
});

const mapDispatch = {
  onMounted: actions.mounted,
  onUnmounted: actions.unmounted,
};

export const ParticipantDetails = compose(
  withRouter,
  connect(mapState, mapDispatch)
)(ParticipantDetailsComponent);
