export enum CisStatusEnum {
  APPLIED = 1,
  DISAGGREGATED = 7,
  EMITTED = 0,
  INTRODUCED = 2,
  INTRODUCED_RETURNED = 6,
  RETIRED = 4,
  WRITTEN_OFF = 3,
  AWAITING_SHIPMENT = 8,
  EXPORTED = 9,
  APPLIED_NOT_PAID = 12,
}

export enum UnifiedCisStatusEnum {
  EMITTED = 0,
  APPLIED = 1,
  INTRODUCED = 2,
  INTRODUCED_RETURNED = 6,
  WRITTEN_OFF = 3,
  RETIRED = 4,
  DISAGGREGATED = 7,
  APPLIED_NOT_PAID = 12,
}
