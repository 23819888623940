import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { getCookie, setLanguage } from '../utils/cookieHelpers';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import translationRU from './ru.json';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import translationAM from './am.json';

let language = getCookie('language') || 'hy';
if (language === 'am' || language === 'hy') {
  language = 'HY';
}

setLanguage(language);

const resources = {
  RU: {
    translation: translationRU,
  },
  HY: {
    translation: translationAM,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: language,
  fallbackLng: 'RU',
  keySeparator: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});
// eslint-disable-next-line import/no-default-export
export default i18n;
