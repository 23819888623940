import { all, call, cancel, put, select, takeEvery } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import * as _ from 'lodash';
import moment from 'moment';

import { HttpMethodEnum } from '../../../../constants/index';
import { logout } from '../../../../common_components/Auth/ducks/Auth.saga';
import { Api } from '../../../../common_components/Api/Api';
import { deleteAllCookies } from '../../../../utils/cookieHelpers';
import {
  adjustProgress,
  getStatusName,
  parseProductGroupsAndRoles,
} from '../../Profile.utils';
// import { checkAddressSaga } from '../InputAddress/ducks/InputAddress.saga';
import { isOIV } from '../../../../common_components/Auth/ducks/Auth.selectors';
import { formatPhoneNumber } from '../company-utils';

import {
  sendFinalize,
  sendProfileData,
  setAccounts,
  setEditing,
  setGroups,
  setProfileData,
  setRoles,
} from './Company.actions';
import {
  getRegistered /* , getSoleProprietorship */,
} from './Company.selectors';

function* fetchCompanyDetails() {
  const [success, error] = yield call(
    Api.request,
    {
      url: '/api/v3/facade/profile/company2',
    },
    { showBackendMessage: true }
  );

  if (success) {
    const { data } = success;
    const preparedData = {
      ...success.data,
      emails: Array.isArray(data.email) ? data.email : [data.email],
      phones: Array.isArray(data.phone) ? data.phone : [data.phone],
      statusName: getStatusName(data.status),
      registrationDate: moment(data.registrationDate).format('DD.MM.YYYY'),
      type: 'Участник оборота товаров',
      productGroupsAndRoles: parseProductGroupsAndRoles(
        _.get(success.data, 'productGroupsAndRoles')
      ),
      actualAddress: data.actualAddress
        ? data.actualAddress
        : data.legalAddress,
    };

    yield put(setProfileData(preparedData));

    return success;
  }

  if (error) {
    console.error('loading profile info error: ', error);

    yield call(logout);

    yield cancel();
  }
}

export function* fetchAccounts() {
  const [success] = yield call(
    Api.request,
    {
      url: '/api/v3/facade/balance/all',
    },
    {
      showBackendMessage: true,
    }
  );

  if (success) {
    yield put(setAccounts(success.data));
  }
}

export function* fetchProfileData() {
  const isUserOiv = yield select(isOIV);

  const resolve = yield all({
    companyDetails: call(fetchCompanyDetails),
    groups: call(fetchGroups),
    roles: isUserOiv ? [] : call(fetchRoles),
    accounts: call(fetchAccounts),
  });

  return [resolve.companyDetails];
}

/**
 * Saving the actual address for legal entities
 * @param {{}} values
 */
// function* savingAddressForLegalEntities({ values }) {
//   const isSoleProprietorship = yield select(getSoleProprietorship);
//
//   if (!isSoleProprietorship) {
//     yield call(checkAddressSaga, {
//       payload: {
//         name: 'actualAddress',
//         values,
//       },
//     });
//   }
// }

function* sendProfileDataSaga({ payload }) {
  let gs1_list;

  // yield call(savingAddressForLegalEntities, {
  //   values: payload,
  // });

  if (payload.gs1_list) {
    gs1_list = payload.gs1_list.map(({ gcp, glns }) => ({
      gcp,
      glns,
    }));
  }

  const requestPayload = {
    url: '/api/v3/facade/profile/update',
    method: HttpMethodEnum.POST,
    data: {
      email: _.get(payload, 'email'),
      kppElk: '999999999',
      // kppElk: _.get(payload, 'kppElk', ''),
      phone: formatPhoneNumber(_.get(payload, 'phone')),
      productGroup: _.get(payload, 'productGroupsAndRoles', []),
      // ifns: _.get(payload, 'ifns', ''),
      ifns: '9999',
      foreignOrganisation: _.get(payload, 'foreignOrganisation'),
      actualAddress: _.get(payload, 'actualAddress'),
      legalAddress: _.get(payload, 'legalAddress'),
      gs1_list,
    },
  };

  const [success, error] = yield call(Api.request, requestPayload, {
    withoutDescriptionOfError: true,
  });

  if (success) {
    yield put(setEditing(false));
    yield call(fetchCompanyDetails);

    const isRegistered = yield select(getRegistered);

    if (!isRegistered) {
      yield call(adjustProgress);
      yield put(replace('/profile/users/list'));
    }
  }

  if (error) {
    console.error('update company error');
  }
}

function* finalize() {
  const [success, error] = yield call(Api.request, {
    url: '/api/v3/facade/profile/finalize',
    method: HttpMethodEnum.PUT,
  });

  if (success) {
    yield call(deleteAllCookies);
    return yield put(replace('/message'));
  }

  if (error) {
    console.error('error', error);
    return yield put(replace('/profile'));
  }
}

function* fetchRoles() {
  const [success] = yield call(
    Api.request,
    {
      url: '/api/v3/org/org-roles/participant-roles',
      cache: true,
    },
    {
      errorNotify: false,
    }
  );

  if (success) {
    yield put(setRoles(success.data));
    return success;
  }
}

function* fetchGroups() {
  const [success] = yield call(
    Api.request,
    {
      url: '/api/v3/org/api/product-groups/search',
      params: {
        limit: 100,
      },
      cache: true,
    },
    {
      showBackendMessage: true,
    }
  );

  if (success) {
    yield put(setGroups(success.data.result));
    return success.result;
  }
}

export const saga = function* watch() {
  yield takeEvery(sendFinalize, finalize);
  yield takeEvery(sendProfileData, sendProfileDataSaga);
};
