import { yup } from '@crpt-ui/form';
import { commonErrorMessages } from '../../constants';

export default yup.object().shape({
  name: yup
    .string()
    .min(10, commonErrorMessages.MIN_LENGTH)
    .required(commonErrorMessages.REQUIRED),
  periodicity: yup.string().required(commonErrorMessages.REQUIRED),
  participantInn: yup.string().required(commonErrorMessages.REQUIRED),
  users: yup.array(yup.string()),
  dataPeriod: yup
    .object()
    .shape({
      endDate: yup.date().required(commonErrorMessages.REQUIRED),
      startDate: yup.date().required(commonErrorMessages.REQUIRED),
    })
    .typeError(commonErrorMessages.REQUIRED),
});
