import { get, set } from 'lodash';
import { Node, NodeWithChildren, Scenario } from '../Threads.types';

export const getNodes = (scenario: Scenario) => {
  const visited = {};
  const getChildren = (node: Node): NodeWithChildren => {
    set(visited, node.id, true);
    const edges = scenario.graph.edges
      .filter((edge) => edge.source === node.id)
      .map((edge) => edge.target);

    const children = scenario.graph.nodes
      .filter((node) => edges.includes(node.id))
      .map(getChildren);

    return {
      ...node,
      children: children,
    };
  };

  return scenario.graph.nodes.reduce<NodeWithChildren[]>((acc, node) => {
    if (get(visited, node.id)) {
      return acc;
    }

    acc.push(getChildren(node));
    return acc;
  }, []);
};
