import React, { FC, useCallback, useEffect, useMemo, useState, memo } from 'react';
import { isArray } from 'lodash';
import {
  Autocomplete as AutocompleteCRPT,
  AutocompleteProps,
} from '@crpt-ui/core';
import FieldHoc from '../../FieldHoc';
import { renderTwoValuesOption } from './TwoValuesOption';
import Tag from './Tag';
import { useStyles } from './AutocompleteMultiple.styles';

import type { FieldRenderProps } from 'react-final-form';

interface AutocompleteMultipleAdapterProps
  extends FieldRenderProps<any, HTMLInputElement>,
    AutocompleteProps {
  additionalLabel?: boolean;
}

const AutocompleteMultipleAdapter: FC<AutocompleteMultipleAdapterProps> = ({
  input,
  meta,
  ...fieldRest
}) => {
  const classes = useStyles();

  const { placeholder, options, additionalLabel, ...rest } = fieldRest;

  const [sortedOptions, setSortedOptions] = useState(options);

  const [opened, setOpened] = useState(true);

  useEffect(() => {
    return () => {
      input.onChange('');
    };
  }, []);

  const newOptions = useMemo(
    () =>
      options.map((option) => ({
        ...option,
        selected: Boolean(input.value.includes(option.value)),
      })),
    [options, input.value]
  );

  const renderAutocompleteOption = useCallback(renderTwoValuesOption, []);

  const renderAutocompleteTags = useCallback(
    (values) =>
      opened ? <Tag value={`Выбрано: ${values.length}`} /> : () => null,
    [opened]
  );

  //Sorting the list after the selection window closes
  useEffect(() => {
    if (!meta.active) {
      const sortedNewOptions = newOptions.sort(
        (a, b) => b.selected - a.selected
      );

      setSortedOptions(sortedNewOptions);
    }
  }, [meta.active, newOptions]);

  const onChangeHandle = useCallback(
    (event, option) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const values = option.map((item) => item.value);

      input.onChange(values);
    },
    [input]
  );

  const propsValue = useMemo(
    () =>
      isArray(input.value)
        ? sortedOptions.filter((item) => input.value.includes(item.value))
        : [],
    [input.value, sortedOptions]
  );

  const error = meta.error;

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <AutocompleteCRPT
      {...input}
      classes={classes}
      error={error}
      label={placeholder}
      multiple
      onChange={onChangeHandle}
      onClose={() => setOpened(true)}
      onOpen={() => setOpened(false)}
      options={sortedOptions}
      renderOption={additionalLabel ? renderAutocompleteOption : undefined}
      renderTags={renderAutocompleteTags}
      value={propsValue}
      {...rest}
    />
  );
};

export default memo(FieldHoc(AutocompleteMultipleAdapter));
