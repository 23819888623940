import React, { useCallback, useMemo } from 'react';

import { connect } from 'react-redux';
import { isUndefined } from 'lodash';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';

import { createForm } from '@crpt-ui/form';

import {
  closeProductionSiteModal,
  sendProductionData,
} from '../ducks/Production.actions';
import {
  getCurrentProductionLine,
  getOpenModal,
} from '../ducks/Production.selectors';

import { ProductionSiteModalViewConnected } from './ProductionModal.view';
import { schema } from './ProductionSiteModal.schema';

const ProductionSiteModal = ({
  closeProductionSiteModal,
  sendProductionData,
  initialValues,
  isOpenModal,
}) => {
  const isEditForm = useMemo(
    () => !isUndefined(initialValues),
    [initialValues]
  );

  const onSubmit = useCallback(
    (values) => {
      sendProductionData({
        values,
        isEditForm,
        onSuccess: closeModal,
      });
    },
    [closeModal, isEditForm, sendProductionData]
  );

  const form = useMemo(() => {
    return createForm({
      schema,
      onSubmit,
      initialValues: initialValues ?? { productionLines: [''] },
      subscribeFields: [],
      mutators: {
        ...arrayMutators,
      },
    });
  }, [initialValues, onSubmit]);

  const closeModal = useCallback(() => {
    form.reset();
    closeProductionSiteModal();
  }, [closeProductionSiteModal, form]);

  const addProductionLine = useCallback(() => {
    form.mutators.push('productionLines', '');
  }, [form.mutators]);

  const removeProductionLineField = useCallback(
    (index) => {
      form.mutators.remove('productionLines', index);
    },
    [form.mutators]
  );

  if (!isOpenModal) {
    return null;
  }

  return (
    <Form form={form}>
      {({ hasValidationErrors, handleSubmit, values, form }) => (
        <form onSubmit={handleSubmit}>
          {React.createElement(ProductionSiteModalViewConnected, {
            addProductionLine,
            removeProductionLineField,
            hasValidationErrors,
            values,
            onSubmit,
            isEditForm,
            form,
            closeModal,
          })}
        </form>
      )}
    </Form>
  );
};

ProductionSiteModal.propTypes = {};

const mapStateToProps = (state) => ({
  initialValues: getCurrentProductionLine(state),
  isOpenModal: getOpenModal(state),
});

const mapDispatchToProps = {
  sendProductionData,
  closeProductionSiteModal,
};

export const ProductionModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductionSiteModal);
