import { productGroupService } from '@crpt/shared/services';

export const isDev = () => {
  const location = window.location.origin;

  return /crpt\.tech|localhost/g.test(location);
};

/**
 * Returns the url environment of the product group
 *
 * @param {number | string} productGroupId
 * @return {string | null}
 */
export const getEnvironment = (
  productGroupId: number | string
): string | null => {
  const environment = productGroupService.getEnvironment(productGroupId);

  if (environment !== null) {
    return window.env[environment];
  }

  return null;
};
