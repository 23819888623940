import {
  ColumnInstance,
  PluginHook,
  TableCellProps,
  TableInstance,
  TableState,
  UseFiltersInstanceProps,
  UseFiltersOptions,
  UseFiltersState,
  UseRowSelectInstanceProps,
  UseRowSelectState,
  UseSortByInstanceProps,
  UseSortByOptions,
  UseSortByState,
  UseTableOptions,
  useFlexLayout,
  useRowState,
  useTable,
  UsePaginationState,
  UsePaginationOptions,
  UsePaginationInstanceProps,
} from 'react-table';

import {
  makeRowActionsCell,
  makeToggleAllRowSelectedCell,
  makeToggleRowSelectedCell,
} from './Cells';
import Datatable from './Datatable';

export * from './Filters';
export { makeCell } from './Datatable.utils';

export {
  useFilters,
  useSortBy,
  useRowSelect,
  usePagination,
  useAsyncDebounce,
} from 'react-table';

export type { Column, Row, Cell } from 'react-table';

export { default as DatatableView } from './Datatable.view';

// eslint-disable-next-line @typescript-eslint/ban-types
type UseDatatableState<D extends object = {}> = Partial<TableState<D>> &
  Partial<UseFiltersState<D>> &
  Partial<UseSortByState<D>> &
  Partial<UseRowSelectState<D>> &
  Partial<UsePaginationState<D>>;

// eslint-disable-next-line @typescript-eslint/ban-types
export interface useDatatableOptions<D extends object = {}>
  extends UseTableOptions<D>,
    UseSortByOptions<D>,
    UseFiltersOptions<D>,
    UsePaginationOptions<D> {
  actions?: Record<string, unknown>[];
  height?: number;
  initialState?: UseDatatableState<D>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type UseDatatable<D extends object = {}> = TableInstance<D> &
  Partial<UseRowSelectInstanceProps<D>> &
  Partial<UseFiltersInstanceProps<D>> &
  Partial<UseSortByInstanceProps<D>> &
  Partial<UsePaginationInstanceProps<D>> & {
    state: UseDatatableState<D>;
  };

// eslint-disable-next-line @typescript-eslint/ban-types
export type Instance<D extends object = {}> = UseDatatable<D>;

// eslint-disable-next-line @typescript-eslint/ban-types
export const useDatatable = <D extends object = {}>(
  { actions, height = 704, ...options }: useDatatableOptions<D>,
  ...plugins: PluginHook<D>[]
): UseDatatable<D> => {
  return useTable(
    options,
    useRowState,
    ...plugins,
    useFlexLayout,
    (hooks) => {
      const getColumnProps = (
        props: Partial<TableCellProps>,
        column: ColumnInstance<D>
      ) => ({
        ...props,
        style: {
          ...props.style,
          flexGrow: column.width !== 150 ? 0 : 1,
          minWidth: column.minWidth ? column.minWidth : column.width,
        },
      });

      hooks.getCellProps.push((props, { cell }) => ({
        ...getColumnProps(props, cell.column),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        error: cell.state?.error,
      }));

      hooks.getHeaderProps.push((props, { column }) => ({
        ...getColumnProps(props, column),
      }));
    },
    (hooks) => {
      hooks.getTableProps.push((props) => ({
        ...props,
        style: {
          ...props.style,
          height: height,
        },
      }));
      hooks.getTableBodyProps.push((props) => ({
        ...props,
        style: {
          ...props.style,
          height: height - 64,
        },
      }));
    },
    (hooks) => {
      if (plugins.some((plugin) => plugin.pluginName === 'useRowSelect')) {
        hooks.visibleColumns.push((columns) => [
          {
            Cell: makeToggleRowSelectedCell(),
            Header: makeToggleAllRowSelectedCell(),
            id: 'toggleRowSelected',
            width: 40,
          },
          ...columns,
        ]);
      }
    },
    (hooks) => {
      if (actions?.length) {
        hooks.visibleColumns.push((columns) => [
          ...columns,
          {
            Cell: makeRowActionsCell(actions),
            id: 'rowActions',
            width: 40,
          },
        ]);
      }
    }
  );
};

export default Datatable;
