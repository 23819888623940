import { handleActions } from 'redux-actions';

import * as actions from './ParticipantList.actions';

const results = handleActions(
  {
    [actions.loaded]: (_state, { payload }) => {
      if (payload.isResultsReset) {
        return payload.results || [];
      } else {
        return [..._state, ...(payload.results || [])];
      }
    },
    [actions.unmounted]: () => [],
  },
  []
);

const last = handleActions(
  {
    [actions.loaded]: (_state, { payload }) =>
      (payload.results.length ? payload.last : true) || false,
    [actions.unmounted]: () => false,
  },
  []
);

const loaded = handleActions(
  {
    [actions.getRequest]: () => false,
    [actions.loaded]: () => true,
    [actions.unmounted]: () => false,
  },
  false
);

const total = handleActions(
  {
    [actions.loaded]: (_state, { payload = {} }) =>
      payload.total !== -1 ? payload.total : 0,
  },
  0
);

const page = handleActions(
  {
    [actions.updatePage]: (_state, { payload }) => payload,
    [actions.unmounted]: () => 1,
  },
  1
);

const historyFilters = handleActions(
  {
    [actions.historyFilters]: (_state, { payload }) => payload || [],
    [actions.unmounted]: () => false,
  },
  []
);

const savedFilters = handleActions(
  {
    [actions.savedFilters]: (_state, { payload }) => payload || [],
    [actions.unmounted]: () => false,
  },
  []
);

export const reducer = {
  results,
  last,
  total,
  page,
  loaded,
  historyFilters,
  savedFilters,
};
