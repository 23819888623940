import _ from 'lodash';
import React, { useMemo } from 'react';
import {
  useExpanded,
  useFilters,
  usePagination,
  useRowSelect,
  useRowState,
  useSortBy,
} from 'react-table';

import { DefaultCell } from './Cells';

export const useHooks = (options) => {
  const hooks = [];

  options.useFilters && hooks.push(useFilters);
  options.useSort && hooks.push(useSortBy);
  options.useExpanded && hooks.push(useExpanded);
  options.usePagination && hooks.push(usePagination);
  options.useRowSelect && hooks.push(useRowSelect);
  options.useRowState && hooks.push(useRowState);

  return hooks;
};

export const useDefaultColumn = () =>
  useMemo(
    () => ({
      Cell: DefaultCell,
      width: 248,
    }),
    []
  );

export const useMountEffect = (effect, deps) => {
  const ref = React.useRef(false);

  React.useEffect(() => {
    if (ref.current) {
      effect();
    } else {
      ref.current = true;
    }
  }, deps);
};

export const call = (callback, ...args) => {
  if (typeof callback === 'function') {
    callback(...args);
  }
};

export const makeCell = ({ colors, icons, values }) => ({ cell }) => {
  const value = useMemo(() => _.get(values, cell.value, '-'), [cell]);
  const color = useMemo(() => _.get(colors, cell.value), [cell]);
  const icon = useMemo(() => _.get(icons, cell.value), [cell]);

  return (
    <span
      style={{
        alignItems: 'center',
        color: color,
        display: 'flex',
        fontWeight: 600,
      }}
    >
      {icon ? (
        <span
          style={{
            marginRight: 8,
          }}
        >
          {icon}
        </span>
      ) : null}
      {value}
    </span>
  );
};

export const generateFilter = (Component) => (props = {}) => ({
  column,
  form,
  formState,
  ...instance
}) =>
  React.createElement(Component, {
    ...props,
    column,
    label: _.defaultTo(props.label, column.Header),
    form,
    formState,
    instance,
  });
