import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import clsx from 'clsx';
import { chain, drop } from 'lodash';

import { Icon } from '@crpt-ui/core';

import { ProductionLineListItemType } from '../ProductionType';
import { TextWrapper } from '../../../../utils/Table.utils';

import { List } from './List/List';
import { useStyles } from './ProductionLinesCell.styled';

export const ProductionLinesCell = ({ productionLine }) => {
  const wrapperRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        isOpen &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, wrapperRef]);

  const canOpen = useMemo(() => {
    return productionLine?.productionLines?.length > 1;
  }, [productionLine.productionLines]);

  const listItems = useMemo(() => {
    const _productionLines = chain(productionLine.productionLines).value();

    return drop(_productionLines);
  }, [productionLine.productionLines]);

  const toggleOpen = useCallback(() => {
    if (canOpen) {
      setOpen(!isOpen);
    }
  }, [canOpen, isOpen]);

  return (
    <div className={classes.wrapper} ref={wrapperRef}>
      <div className={clsx(classes.expandWrapper, { isOpen, canOpen })}>
        <div
          className={clsx(classes.label, { isOpen, canOpen })}
          onClick={toggleOpen}
        >
          <div className={clsx(classes.icon, { isOpen, canOpen })}>
            <Icon name="Chevron" />
          </div>

          <TextWrapper>
            {productionLine?.productionLines
              ? productionLine?.productionLines[0]
              : ''}
          </TextWrapper>

          {canOpen && !isOpen && (
            <span className={classes.counter}>+{listItems.length}</span>
          )}
        </div>

        {canOpen && <List items={listItems} open={isOpen} />}
      </div>
    </div>
  );
};

ProductionLinesCell.propTypes = {
  productionLine: ProductionLineListItemType,
};
