import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import * as preloaderSelectors from '../../common_components/Preloader/ducks/Preloader.selectors';

import { getProductGroupsAndRoles } from './Company/ducks/Company.selectors';
import * as companySelectors from './Company/ducks/Company.selectors';
import { getTabs } from './Profile.utils';
import { ProfileView } from './Profile.view';

const ProfileComponent = ({
  history,
  status,
  isRegistered,
  productGroupsAndRoles,
  ...rest
}) => {
  const onLocationChange = useCallback(
    (_event, path) => {
      return history.push(path);
    },
    [isRegistered]
  );

  return React.createElement(ProfileView, {
    ...rest,
    history: history,
    onLocationChange: onLocationChange,
    status: status,
    isRegistered: true,
    tabs: getTabs(status, productGroupsAndRoles),
  });
};

ProfileComponent.propTypes = {
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  productGroupsAndRoles: PropTypes.arrayOf(
    PropTypes.exact({
      code: PropTypes.string,
      types: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired
  ),
  isRegistered: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  status: companySelectors.getCompanyStatus(state),
  isRegistered: companySelectors.getRegistered(state),
  isLoading: preloaderSelectors.loading(state),
  productGroupsAndRoles: getProductGroupsAndRoles(state),
});

export const Profile = compose(
  connect(mapStateToProps),
  withRouter
)(ProfileComponent);
