import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Checkbox as CoreCheckbox } from '@crpt-ui/core';

export const Checkbox = ({ name, onChange, ...rest }) => {
  const onChangeHandle = useCallback(
    (input) => (event) => {
      if (typeof onChange === 'function') {
        onChange(event);
      }
      input.onChange(event);
    },
    [onChange]
  );

  return (
    <Field name={name} type="checkbox">
      {({ input }) => {
        return (
          <CoreCheckbox {...input} {...rest} onChange={onChangeHandle(input)} />
        );
      }}
    </Field>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};
