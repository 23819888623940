import { handleActions } from 'redux-actions';

import {
  clearManualModeModal,
  closeManualModeModal,
  openManualModeModal,
  setAutocompleteList,
  setLoading,
  setLoadingAutocomplete,
} from './ManuallyAgreementDocument.actions';

const open = handleActions(
  {
    [openManualModeModal]: () => true,
    [closeManualModeModal]: () => false,
  },
  false
);

const autocompleteList = handleActions(
  {
    [setAutocompleteList]: (_, { payload }) => payload,
    [clearManualModeModal]: () => [],
  },
  []
);

const isLoadingAutocomplete = handleActions(
  {
    [setLoadingAutocomplete]: (_, { payload }) => payload,
    [closeManualModeModal]: () => false,
  },
  false
);

const loading = handleActions(
  {
    [setLoading]: (_, { payload }) => payload,
  },
  false
);

export const reducer = {
  open,
  autocompleteList,
  isLoadingAutocomplete,
  loading,
};
