import React from 'react';
import { format } from 'date-fns';

import { Stack, Typography } from '@mui/material';
import { TFunction } from 'i18next';
import { translate } from '../Translate/Translate';
import { MRT_Cell } from 'material-react-table';
import { Icon } from '@ibox/ui';

import { Status } from '../status';

export const userStatusEnum = {
  ACTIVE: 'ACTIVE',
  APPROVED: 'APPROVED',
  BLOCKED: 'BLOCKED',
  REGISTERED: 'REGISTERED',
  REMOVED: 'REMOVED',
  DRAFT: 'DRAFT',
};

export const userStatusValuesMap = {
  [userStatusEnum.ACTIVE]: translate('Активен'),
  [userStatusEnum.DRAFT]: translate('Черновик'),
  [userStatusEnum.BLOCKED]: translate('Заблокирован'),
  [userStatusEnum.REGISTERED]: translate('Зарегестрирован'),
  [userStatusEnum.APPROVED]: translate('Согласовано оператором'),
  [userStatusEnum.REMOVED]: translate('Удален'),
};

export const userStatusIconsMap = {
  [userStatusEnum.ACTIVE]: <Icon name="UserActive" />,
  [userStatusEnum.BLOCKED]: <Icon name="UserBlock" />,
  [userStatusEnum.REMOVED]: <Icon name="UserRemove" />,
};

export const getColumns = ({ t }: { t: TFunction }) => [
  {
    header: t('ФИО пользователя'),
    accessorKey: 'fullName',
    enableSorting: false,
  },
  {
    header: t('Контактный телефон'),
    accessorKey: 'phone',
    enableSorting: false,
  },
  {
    header: t('Email пользователя'),
    accessorKey: 'email',
    enableSorting: false,
  },
  {
    header: t('Последняя активность'),
    accessorKey: 'lastActivity',
    Cell: ({ cell }: { cell: MRT_Cell<Record<string, unknown>> }) => (
      <Stack>
        <Typography>
          {cell.row.original.lastActivity
            ? format(
                new Date(cell.row.original.lastActivity as string),
                'dd.MM.yyyy'
              )
            : '-'}
        </Typography>
      </Stack>
    ),
    enableSorting: false,
  },
  {
    header: t('Статус пользователя'),
    Cell: ({ row }) => {
      return (
        <Status
          label={userStatusValuesMap[row.original.status] || '-'}
          status={row.original.status}
        />
      );
    },
    enableSorting: false,
  },
];

export const tabs = [
  { label: translate('Данные ОИВ'), value: 0 },
  { label: translate('Пользователи'), value: 1 },
];
