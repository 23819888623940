import React from 'react';

import { get } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { isValid, format } from 'date-fns';
import { Box, Grid } from '@mui/material';

import { Preloader } from '../../fragments/Table/Preloader/Preloader';
import { GroupInfoItem } from '../../fragments/Data/GroupInfo/Item';
import { GroupInfoTitle } from '../../fragments/Data/GroupInfo/Title';

import styles from './index.module.scss';

type OivDataProp = {
  result: {}[];
  loaded: boolean;
};

export const OivData = ({ result, loaded }: OivDataProp) => {
  const { t } = useTranslation();

  const name = get('fullName', result);
  const innOiv = get('innOiv', result);
  const registrationDate = get('registrationDate', result);

  const date = isValid(registrationDate ?? null)
    ? format(new Date(registrationDate ?? null), 'dd.MM.yyyy')
    : '-';
  const pid = get('pid', result);
  const status = get('status', result) === 'REGISTERED' ? t('Активен') : '-';

  return (
    <Box className={styles.pageWrapper}>
      <Preloader isOpen={!loaded} />
      <Grid container p={4}>
        <Grid item xs={12}>
          <GroupInfoItem
            classes={styles.groupItemWrapper}
            title={t('Наименование')}
            value={name}
          />
        </Grid>
        <Grid item xs={12}>
          <GroupInfoItem
            classes={styles.groupItemWrapper}
            title={t('ИНН')}
            value={innOiv}
          />
        </Grid>
        <Grid item xs={12}>
          <GroupInfoTitle
            classes={styles.infoTitle}
            title={t(`Участие в системе`)}
            variant={'h6'}
          />
        </Grid>
        <Grid container item xs={6}>
          <Grid item xs={12}>
            <GroupInfoItem
              classes={styles.groupItemWrapper}
              title={t('ID')}
              value={pid}
            />
          </Grid>
          <Grid item xs={12}>
            <GroupInfoItem
              classes={styles.groupItemWrapper}
              title={t('Статус ОИВ')}
              value={status}
            />
          </Grid>
          <Grid item xs={12}>
            <GroupInfoItem
              classes={styles.groupItemWrapper}
              title={t('Дата регистрации в системе')}
              value={date}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
