import { createActions } from 'redux-actions';

export const {
  fetchEventsData,
  setEventsData,
  clearEventsData,
  setLoading,
}: any = createActions(
  {
    FETCH_EVENTS_DATA: () => {},
    SET_EVENTS_DATA: (payload) => payload,
    CLEAR_EVENTS_DATA: () => {},

    SET_LOADING: (payload) => payload,
  },
  {
    prefix: 'EVENTS',
  }
);
