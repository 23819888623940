import { all, call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { Api } from '../../../../common_components/Api/Api';

import {
  setDocumentInfo,
  setLoading,
  setUseHub,
  startDocumentSaga,
} from './ClosingDocument.actions';

/**
 * Fetch information about the document
 * @param {string} payload - Document id
 */
function* fetchDocumentInfoSaga({ payload: { id: documentId } }) {
  yield put(setLoading(true));

  const [documentInfo] = yield call(
    Api.request,
    {
      url: `/edo-api/incoming-documents/${documentId}`,
    },
    {
      preloading: false,
    }
  );

  if (documentInfo) {
    const documentInfoData = {
      data: documentInfo.data,
      loaded: true,
    };

    yield put(setDocumentInfo(documentInfoData));
  } else {
    yield put(push('/404'));
  }

  yield put(setLoading(false));
}

function* fetchPropertiesUser() {
  const [success] = yield call(
    Api.request,
    {
      url: '/edo-api/properties-user',
    },
    {
      preloading: false,
    }
  );

  if (success.data) {
    yield put(setUseHub(success.data.hub_using));
  }
}

/**
 * @param {string} payload - Document id
 */
function* closingDocumentSaga({ payload }) {
  yield all([
    call(fetchPropertiesUser),
    call(fetchDocumentInfoSaga, {
      payload,
    }),
  ]);
}

export const saga = function* watch() {
  yield takeEvery(startDocumentSaga, closingDocumentSaga);
};
