import { takeEvery, put, call } from 'redux-saga/effects';
import { saveAs } from 'file-saver'; // /FileSaver

import { Api } from '../../../common_components/Api/Api';
import {
  filters2url,
  filters2params,
  spreadMultiValueOfFilter,
  updateUrlParams,
} from '../../../utils/url-utils';

import * as actions from './ParticipantList.actions';

export const displayDataLimit = 10;

function* requestSaga(action) {
  const { payload = {} } = action;

  const {
    page = 1,
    filters,
    history,
    updateHistory = false,
    pathname = '',
    isResultsReset = false,
  } = payload;

  const flatFilters = spreadMultiValueOfFilter(filters, ['multinameInn']);
  const filterParams = filters2params(flatFilters);

  const requestPayload = {
    url: '/api/v3/facade/participants/search',
    method: 'get',
    params: {
      ...filterParams,
      limit: displayDataLimit,
      page: page - 1,
    },
  };

  // Error field does not used here cause there are no designs for catching error in datatable. Instead of it we have global error catching - toasts.
  const [success] = yield call(Api.request, requestPayload);

  yield put(actions.getRequest);
  if (success) {
    if (updateHistory && history) {
      const filtersUrl = filters2url(filters);
      const urlString =
        pathname + '?' + (filtersUrl ? filtersUrl + '&' : '') + `page=${page}`;
      yield call(updateUrlParams, { urlString });
    }
    if (page) {
      yield put(actions.updatePage(page));
    }
    if (isResultsReset) {
      success.data.isResultsReset = isResultsReset;
    }
    yield put(actions.loaded(success.data));
  }
}

/**
 * Old function that do not use in current time, but may be needed in future plans. Do not remove.
 * @param action
 * @returns {Generator<<"CALL", CallEffectDescriptor>, void, ?>}
 */
function* downloadSaga(action) {
  const { payload = {} } = action;
  const { reportType = 'xls', page = 1, ...filters } = payload;

  const requestPayload = {
    url: `/private-office-api/private/v2/participants/buildReport/${reportType}`,
    method: 'post',
    responseType: 'blob',
    data: { ...filters, limit: 10, page: page - 1 },
  };

  const [success] = yield call(Api.request, requestPayload);

  if (success) {
    saveAs(success.data, `participant_list.xls`);
  }
}

export const saga = function* watch() {
  yield takeEvery(actions.getRequest, requestSaga);
  yield takeEvery(actions.download, downloadSaga);
};
