import { saveAs } from 'file-saver';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import bffInstance, {
  CisSearchDTO,
  CisSearchResponseDTO,
  DocumentTypeEnum,
  OrganisationDTO,
  ProductListResponse,
  CisDTO,
  ProductDTO,
  GroupedCisListDTO,
} from '@crpt/shared/services/bff';
import bffV2Instance, {
  FetchProductListParams
} from '@crpt/shared/services/bff/bffV2';
import toast from '@crpt/shared/toast';
import type { UploadCisDialogViewModelProps } from './UploadCisDialog.types';
import { UploadCisDialogRequests } from './UploadCisDialog.types';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from '../../axios';

const CancelToken = axios.CancelToken;
let cancelRequest: () => void;

interface UploadCisDialogViewModelState {
  validationResults: GroupedCisListDTO[];
  cises: string[];
}

interface CisDTOExtends extends CisDTO {
  name?: string;
  veterinaryControl?: string;
  producerName?: string;
  ownerName?: string;
}

interface CisSearchDTOExtends extends CisSearchDTO {
  name?: string;
  veterinaryControl?: string;
  producerName?: string;
  ownerName?: string;
}

export const UploadCisDialogViewModel = (
  props: UploadCisDialogViewModelProps
) => {
  const initialState = {
    validationResults: [],
    cises: [],
  };

  const { t } = useTranslation();

  const { request, productGroup, participantInn, returnType } = props;

  const [state, setState] =
    useState<UploadCisDialogViewModelState>(initialState);
  const [dropzoneFiles, setDropzoneFiles] = useState<File[]>([]);

  const [uploading, setUploading] = useState(false);
  const [uploadingProgress, setUploadingProgress] = useState(0);

  const [loading, setLoading] = useState(false);

  const setCodes = useCallback(
    (data) => {
      _.invoke(props, 'onAccept', data);
      _.invoke(props, 'onClose');
      toast(t('UploadCisDialog.success.codesAdded', 'Коды добавлены'));
    },
    [props]
  );

  const acceptedCodes = useMemo(() => {
    return _.chain(state.validationResults)
      .filter((validationResult) => validationResult.upload)
      .map((validationResult) => validationResult.codes)
      .flatten()
      .compact()
      .value();
  }, [state.validationResults, state.cises]);

  useEffect(() => {
    if (
      state.validationResults.length &&
      !hasValidationErrors &&
      acceptedCodes.length
    ) {
      accept();
    }
  }, [state.validationResults]);

  const fetchCises = useCallback((file: File) => {
    return bffInstance.transformXlsCisListToJson(file, {
      onUploadProgress: (event) => {
        setUploadingProgress((event.loaded * 100) / event.total);
      },
      cancelToken: new CancelToken(function executor(c) {
        cancelRequest = c;
      }),
    });
  }, []);

  const fetchValidatedCises = useCallback(
    (cises = acceptedCodes) => {
      // https://jira.crpt.ru/browse/FC-5150
      // Доработать форму создания WRITE_OFF в Табаке
      // Для документа Списание указано использовать запрос /bff-elk/v1/cis/search вместо /bff-elk/v1/cis/cises
      return props.fetchCisesByCisSearchRequest
        ? bffInstance.searchCisList({
          // codesPrintView: childrensArray || acceptedCodes,
          filter: { codesPrintView: cises },
        })
        : bffInstance.fetchCisList(cises).then((response) => {
          const data = cises.map(
            (cis: string) => {
              const cisDataFromResponse = response.data.find((d) => d.cis === cis);

              if (cisDataFromResponse) {
                return cisDataFromResponse;
              }

              const requestedCisDataFromResponse = response.data.find((d) => d.requestedCis === cis);

              return requestedCisDataFromResponse;
            }
          );

          return {
            data,
          };
        });
    },
    [acceptedCodes]
  );

  const fetchProducts = useCallback(
    (cises) => {
      const params: string[] = _.uniq(
        cises.map((cis: CisDTO) => cis.gtin).filter(Boolean)
      ); //filter(Boolean) - delete undefined values
      const requestParams: FetchProductListParams = { gtin: params };
      if (participantInn) {
        requestParams.inn = [participantInn];
        requestParams.includeSubaccount = true;
      }
      return params.length > 0 ? bffV2Instance.fetchProductList(requestParams) : null;
    },
    [participantInn]
  );

  const fetchOrganizations = useCallback((cises) => {
    const params: string[] = _.uniq(
      _.flatten(cises.map((cis: CisDTO) => [cis.producerInn, cis.ownerInn]))
    );
    return params.length > 0 ? bffInstance.fetchOrganisationList(params) : null;
  }, []);

  const validateCises = useCallback(
    (cises) => {
      return bffInstance.fetchGroupedCisList(
        cises,
        productGroup,
        DocumentTypeEnum[props.doctype],
        returnType,
      );
    },
    [productGroup, props.doctype, returnType]
  );

  const onDrop = useCallback(
    (files) => {
      if (!files.length) {
        return;
      }

      setUploadingProgress(0);
      setUploading(true);

      setDropzoneFiles([...dropzoneFiles, ...files]);

      return fetchCises(files[0])
        .then((response) => {
          if (!response.data.cises.length) {
            toast.error(
              t(
                'UploadCisDialog.error.noCodes',
                'Файл не содержит ни одного кода'
              )
            );
            return;
          }

          const cisesData = response.data.cises ?? [];

          setState((state) => ({
            ...state,
            cises: cisesData,
          }));

          if (request === UploadCisDialogRequests.FETCH_CISES) {
            setCodes(cisesData);
          } else {
            return validateCises(response.data.cises).then((response) => {
              setState((state) => ({
                ...state,
                validationResults: response.data.results ?? [],
              }));
            });
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error_message);
          setDropzoneFiles([]);
        })
        .finally(() => {
          setUploading(false);
        });
    },
    [validateCises, fetchCises, dropzoneFiles, request, setCodes, t]
  );

  const cancelUploading = () => {
    setUploading(false);
    cancelRequest();
  };

  const dropzone = useDropzone({
    accept: ['.xls', '.xlsx'],
    multiple: false,
    onDrop,
  });

  const validationErrors = useMemo(
    () =>
      state.validationResults.filter(
        (validationResult) =>
          !validationResult.upload && validationResult.codes.length
      ),
    [state.validationResults]
  );

  const hasValidationErrors = useMemo(
    () => Boolean(validationErrors.length),
    [validationErrors]
  );

  const downloadValidationResults = useCallback(() => {
    let csvString = '';

    validationErrors.forEach((error) => {
      error.codes.forEach((code) => {
        csvString += `"${error.groupDescription}",${code}\n`;
      });
    });

    saveAs(
      new Blob([csvString], {
        type: 'text/csv',
      }),
      `${props.doctype.toLowerCase()}_cis_list_errors.csv`
    );
  }, [props.doctype, validationErrors]);

  const loadData = useCallback(
    (childrensArray?: string[]) => {
      if (request && request < UploadCisDialogRequests.CISES) {
        return;
      }

      return fetchValidatedCises(childrensArray).then((cisesResponse) => {
        // https://jira.crpt.ru/browse/FC-5150
        // Доработать форму создания WRITE_OFF в Табаке
        // Для документа Списание указано использовать запрос /bff-elk/v1/cis/search вместо /bff-elk/v1/cis/cises
        // Поэтому здесь могут приходить два различных ответа
        const cisesData = props.fetchCisesByCisSearchRequest
          ? (cisesResponse.data as CisSearchResponseDTO).result || []
          : (cisesResponse.data as CisDTO[]) || [];

        if (request === UploadCisDialogRequests.CISES) {
          setCodes(cisesData);
          return;
        }

        const promises = [];

        if (request === UploadCisDialogRequests.PRODUCT_ENRICHMENT) {
          promises.push(fetchProducts(cisesData));
        } else if (
          request === UploadCisDialogRequests.ORGANISATION_ENRICHMENT
        ) {
          promises.push(
            fetchProducts(cisesData),
            fetchOrganizations(cisesData)
          );
        }

        return Promise.all(promises as any).then(async (results) => {
          const [products, organizations] = results;

          const { data: productsData } =
          (products as AxiosResponse<ProductListResponse>) || {
            data: { results: [] },
          };
          const { data: organisationsData = [] } =
          (organizations as AxiosResponse<OrganisationDTO[]>) || {};

          cisesData.forEach((cis: CisDTOExtends | CisSearchDTOExtends) => {
            const productsForCis = productsData.results.filter(
              (product: ProductDTO) => {
                return cis.gtin === product.gtin;
              }
            );

            // https://jira.crpt.ru/browse/FC-5150
            // Доработать форму создания WRITE_OFF в Табаке
            // name устанавливается для cis, если для него найден единственный gtin из /bff-elk/v2/products
            // если найдено несколько gtin, то неизвестно какой name правильный
            if (productsForCis.length === 1) {
              const product = productsForCis[0];

              cis.name = product.name;
              cis.productName = product.name;

              // eslint-disable-next-line no-prototype-builtins
              if (product.hasOwnProperty('veterinaryControl')) {
                cis.veterinaryControl = product.veterinaryControl;
              }
            }
          });

          organisationsData.forEach((org: OrganisationDTO) => {
            const producerInnOrg = _.find(cisesData, ['producerInn', org.inn]);
            const ownerInnOrg = _.find(cisesData, ['ownerInn', org.inn]);

            if (producerInnOrg) {
              (
                producerInnOrg as CisDTOExtends | CisSearchDTOExtends
              ).producerName = org.name;
            }
            if (ownerInnOrg) {
              (ownerInnOrg as CisDTOExtends | CisSearchDTOExtends).ownerName =
                org.name;
            }
          });

          if (props.fetchCisesByCisSearchRequest) {
            return cisesData;
          }

          const uinCode = cisesData.map((cis) => {
            const countChildren = _.get(cis, 'children', []).length;
            // Расширение необходимыми полями для соответствию виду ручного добавления
            return {
              ...cis,
              packType: cis.packageType,
              [countChildren ? 'uitu' : 'uit']: (cis as CisDTO).requestedCis,
              [countChildren ? 'kitu' : 'ki']: (cis as CisDTO).requestedCis,
            };
          });

          return uinCode;
          });
        });
      },
    [fetchValidatedCises, fetchProducts, fetchOrganizations, request]
  );

  const accept = useCallback(async () => {
    try {
      setLoading(true);
      const data = await loadData();
      if (data) {
        const childrens = data.map((cis) => _.get(cis, 'children', [])).flat(1);
        if (props.loadChildrens && childrens && childrens.length > 0) {
          const childrensData = await loadData(childrens);
          if (childrensData) {
            const dataWithChildrens = data.map((cis) => {
              const children = (childrensData as CisDTO[])
                .filter((child) =>
                  (cis as CisDTO).children?.includes(child.cis)
                )
                ?.map((value) => ({
                  ...value,
                  children: [],
                }));
              return { ...cis, children };
            });
            setCodes(dataWithChildrens);
          }
        } else {
          const clearedFromChildernData = data.map((cis) =>
            _.omit(cis, 'children')
          );
          setCodes(clearedFromChildernData);
        }
      }
      setLoading(false);
    } catch (error: any) {
      toast.error(error.response?.data?.error_message);
      setLoading(false);
    }
  }, [loadData, setCodes]);

  const resetFiles = useCallback(() => {
    setDropzoneFiles([]);
    setState({
      ...initialState,
    });
  }, [setState, setDropzoneFiles, initialState]);

  const cancel = useCallback(() => {
    _.invoke(props, 'onClose');
    resetFiles();
  }, [props, resetFiles]);

  return {
    accept,
    cancel,
    downloadValidationResults,
    dropzone,
    hasValidationErrors,
    loading,
    uploading,
    uploadingProgress,
    validationErrors,
    validationResults: state.validationResults,
    dropzoneFiles,
    resetFiles,
    cancelUploading,
    acceptedCodes,
  };
};
