import { call, put, takeEvery } from 'redux-saga/effects';

import { Api } from '../../../../common_components/Api/Api';

import {
  fetchNotificationOperatorDocument,
  setLoading,
  setNotificationOperatorDocumentContent,
  setNotificationOperatorDocumentInfo,
} from './NotificationOperatorDocument.actions';

function* fetchNotificationOperatorDocumentContent(id) {
  const requestPayload = {
    url: `/edo-api/outgoing-documents/${id}/print`,
    responseType: 'arraybuffer',
  };

  const [success] = yield call(Api.request, requestPayload, {
    preloading: false,
  });

  if (success) {
    yield put(setNotificationOperatorDocumentContent(success.data));
  }
}

function* fetchNotificationOperatorDocumentInfo(id) {
  const requestPayload = {
    url: `/edo-api/outgoing-documents/${id}`,
  };

  const [success] = yield call(Api.request, requestPayload, {
    preloading: false,
  });

  if (success) {
    yield put(setNotificationOperatorDocumentInfo(success.data));
  }
}

function* fetchNotificationOperatorDocuments({ payload: id }) {
  yield put(setLoading(true));

  yield call(fetchNotificationOperatorDocumentContent, id);
  yield call(fetchNotificationOperatorDocumentInfo, id);

  yield put(setLoading(false));
}

export const saga = function* watch() {
  yield takeEvery(
    fetchNotificationOperatorDocument,
    fetchNotificationOperatorDocuments
  );
};
