import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: 64,
    height: 64,
    position: 'fixed',
    right: 20,
    bottom: -64,
    borderRadius: '50%',
    overflow: 'hidden',
    background: theme.palette.text.primary,
    color: theme.palette.secondary.contrastText,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'bottom 0.2s ease-in-out',

    '&$open': {
      bottom: 20,
    },
  },
  loader: {
    zIndex: 1,
  },
  loaderBackground: {
    width: 58,
    height: 58,
    position: 'absolute',
    border: `4px solid ${theme.palette.text.secondary}`,
    borderRadius: '50%',
  },
  button: {
    position: 'absolute',
    borderRadius: '50%',
    zIndex: 2,
  },
  open: {},
}));
