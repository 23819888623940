import React, { useMemo } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { getCurrentCertificate } from '../Cades/Cades.selectors';

import { SignatureDescriptionView } from './SignatureDescription.view';

export const SignatureDescription = ({ certificate, direction }) => {
  const isLoading = useMemo(() => isEmpty(certificate), [certificate]);

  return React.createElement(SignatureDescriptionView, {
    direction,
    certificate,
    isLoading,
  });
};

SignatureDescription.propTypes = {
  certificate: PropTypes.object,
  startSetCurrentCertificate: PropTypes.func,
  setCertificateInformation: PropTypes.func,
};

const mapStateToProps = (state) => ({
  certificate: getCurrentCertificate(state),
});

export const SignatureDescriptionConnected =
  connect(mapStateToProps)(SignatureDescription);
