import { RouteProps } from 'react-router-dom';
import { Notification } from '../Notification/Notification.types';
import { matchPath } from 'react-router';
import { BannersType } from '../Notification/Notification.types';
import { checkIsNotificationShown } from '../Notification/Notification.utils';

enum ComparsionCondition  {
  LOWER,
  HIGHER
}


export const getMatchedNotification = (
  notifications: Array<Notification> | null,
  path?: RouteProps['path']
): Notification | undefined => {
  if (!notifications || !path) {
    return;
  }

  const matchedNotification = notifications.find((item: Notification) => {
    return item.urls.some((url) => {
      const trimmedUrl = url.trim();

      return matchPath(`${trimmedUrl}`, {
        path,
        strict: true,
        exact: true,
      });
    });
  });

  return matchedNotification || undefined;
};

const checkIsNotificationDateProper = (condition:ComparsionCondition, date?:Date) => {
  // by business logic if we have unsetted date ( equals undefined ), we should not check that this date is proper.
  // this mean that notification do not have time border
  if(!date) {
    return true;
  }
  const dateTime =  new Date(date).getTime();
  const currentTime = new Date().getTime();
  return condition === ComparsionCondition.HIGHER ? dateTime > currentTime : dateTime < currentTime;
}

const checkIsProperDateRange = (notification: Notification): boolean => {
  const isStartNotificationDateProper = checkIsNotificationDateProper(ComparsionCondition.LOWER, notification.scheduledWorkingPeriodStart);
  const isEndNotificationDateProper = checkIsNotificationDateProper(ComparsionCondition.HIGHER, notification.scheduledWorkingPeriodEnd);

  return Boolean(isStartNotificationDateProper && isEndNotificationDateProper);
};

export const checkIsNotificationCanBeShown = (
  notification: Notification
): boolean => {
  const isDisposableAndAlreadyShown =
    notification?.bannerType === BannersType.Disposable &&
    checkIsNotificationShown(notification.id);

  const isProperDateRange = notification.scheduled ? checkIsProperDateRange(notification) : true;

  return (
    notification.activated &&
    isProperDateRange &&
    !isDisposableAndAlreadyShown
  );
};
