import React from 'react';

import { Icon } from '@crpt-ui/core';

export const DocumentTypesMap = {
  AGREEMENT: 'agreement',
  CANCELLATION: 'cancellation',
};

export const DocumentTitlesMap = {
  [DocumentTypesMap.AGREEMENT]: 'Согласие о предоставлении информации',
  [DocumentTypesMap.CANCELLATION]:
    'Аннулирование согласия о предоставлении информации',
};

export const AgreementDocumentStatusMap = {
  /**
   * На подготовке
   */
  ESTABLISHED: 'ESTABLISHED',
  /**
   * Подписан
   */
  SIGNED: 'SIGNED',
  /**
   * Устарел
   */
  OUTDATED: 'OUTDATED',
  /**
   * Аннулирован
   */
  CANCELLED: 'CANCELLED',
  /**
   * Отклонен
   */
  REJECTED: 'REJECTED',
};

export const AgreementDocumentStatusValueMap = {
  [AgreementDocumentStatusMap.ESTABLISHED]: 'Требует подписи',
  [AgreementDocumentStatusMap.SIGNED]: 'Подписано',
  [AgreementDocumentStatusMap.OUTDATED]: 'Устарело',
  [AgreementDocumentStatusMap.CANCELLED]: 'Аннулировано',
  [AgreementDocumentStatusMap.REJECTED]: 'Отклонено',
};

export const AgreementDocumentStatusColorMap = {
  [AgreementDocumentStatusMap.ESTABLISHED]: 'warning',
  [AgreementDocumentStatusMap.SIGNED]: 'success',
  [AgreementDocumentStatusMap.OUTDATED]: 'warning',
  [AgreementDocumentStatusMap.CANCELLED]: 'error',
  [AgreementDocumentStatusMap.REJECTED]: 'error',
};

export const AgreementDocumentStatusHEXColorMap = {
  [AgreementDocumentStatusMap.ESTABLISHED]: '#f5a020',
  [AgreementDocumentStatusMap.SIGNED]: '#48c834',
  [AgreementDocumentStatusMap.OUTDATED]: '#f5a020',
  [AgreementDocumentStatusMap.CANCELLED]: '#f34747',
  [AgreementDocumentStatusMap.REJECTED]: '#f34747',
};

export const AgreementDocumentStatusIconsMap = {
  [AgreementDocumentStatusMap.ESTABLISHED]: <Icon name="DocumentDraft" />,
  [AgreementDocumentStatusMap.SIGNED]: <Icon name="PublishedWithSign" />,
  [AgreementDocumentStatusMap.OUTDATED]: <Icon name="Trash" />,
  [AgreementDocumentStatusMap.CANCELLED]: <Icon name="Decline" />,
  [AgreementDocumentStatusMap.REJECTED]: <Icon name="Decline" />,
};
