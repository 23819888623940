import { values } from 'lodash';
import { all, call, spawn } from 'redux-saga/effects';

export const getMainSaga = (order, sagas) => {
  const temp = {};

  order.forEach((project) => {
    const obj = sagas.find((s) => s.id === project).data;

    obj.keys().forEach((f) => {
      const { saga } = obj(f);
      if (saga) {
        temp[f] = saga;
      }
    });
  });

  const allSagas = values(temp);

  function* mainSaga() {
    const currentSagas = [...allSagas];

    yield all(
      currentSagas.map((saga) =>
        spawn(function* () {
          while (true) {
            try {
              yield call(saga);
              break;
            } catch (error) {
              console.error(error);
            }
          }
        })
      )
    );
  }

  return mainSaga;
};
