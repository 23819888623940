import { createActions } from 'redux-actions';

export const {
  startEdoRegistrationSaga,
  setEdoRegistrationData,
  setEdoOperators,
  setEditing,
  setLoading,
  setSubmitting,
  sendEdoRegistrationData,
} = createActions(
  {
    START_EDO_REGISTRATION_SAGA: () => {},

    SET_EDO_REGISTRATION_DATA: (payload) => payload,
    SET_EDO_OPERATORS: (payload) => payload,
    SET_EDITING: (payload) => payload,
    SET_LOADING: (payload) => payload,
    SET_SUBMITTING: (payload) => payload,

    SEND_EDO_REGISTRATION_DATA: (payload) => payload,
  },
  {
    prefix: 'OPERATORS',
  }
);
