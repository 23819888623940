import React from 'react';

import { productGroupService } from '@crpt/shared/services';
import { Icon } from '@crpt-ui/core';

import { makeCell, makeDateCell } from '../../../utils/Table.utils';

import { DoctypeCell } from './DoctypeCell/DoctypeCell';
import { StatusCell } from './StatusCell/StatusCell';

export const useColumns = ({ onRowClick }) => [
  {
    accessor: 'createtime',
    Cell: makeDateCell({
      format: 'DD.MM.YYYY',
    }),
    Header: 'Дата регистрации',
    width: 170,
  },
  {
    accessor: 'docid',
    Header: 'Рег. № документа',
    width: 200,
  },
  {
    accessor: 'doctype',
    Cell: (props) => <DoctypeCell {...props} onClick={onRowClick} />,
    Header: 'Наименование',
    disableSortBy: true,
  },
  {
    accessor: 'group',
    Cell: makeCell({
      values: productGroupService.getNamesMap(),
    }),
    Header: 'Товарная группа',
    width: 440,
    disableSortBy: true,
  },
  {
    accessor: 'edoStatus',
    Cell: StatusCell,
    Header: 'Статус',
  },
];

export const sdDocumentStatusValuesMap = {
  0: 'На проверке',
  1: 'Требует подписи',
  2: 'Подписан сторонами',
  3: 'На подписании',
  4: 'Подписание не требуется',
  default: 'На проверке',
};

export const sdDocumentStatusIconsMap = {
  0: <Icon name="DocumentProcessing" />,
  1: <Icon name="DocumentDraft" />,
  2: <Icon name="DocumentPublished" />,
  3: <Icon name="DocumentProcessing" />,
  4: <Icon name="DocumentPublished" />,
  default: <Icon name="DocumentProcessing" />,
};

export const edoDocumentStatusValuesMap = {
  0: 'Требуется подпись',
  1: 'Подписан',
  2: 'Подписан',
  3: 'Требуется подпись от оператора',
  4: 'Подписан',
  5: 'Отклонен',
  6: 'Отозван',
  7: 'Уточнен',
  8: 'Ожидается уточнение',
  9: 'Ошибка в подписи',
  10: 'Ошибка доставки',
  12: 'Подписан',
  13: 'Требуется подпись',
  14: 'Запрос на уточнение просмотрен',
  15: 'Отклонен',
  16: 'Ожидается аннулирование',
  17: 'Запрошено аннулирование',
  18: 'Аннулирован',
  19: 'В аннулировании отказано',
  20: 'Подписан, требуется уточнение',
  21: 'Подписан, требуется уточнение',
  22: 'Подписан, уточнен',
  default: 'Обрабатывается',
};

export const edoDocumentStatusColorsMap = {
  1: 'warning',
  2: 'success',
  3: 'warning',
  4: 'success',
  8: 'warning',
  12: 'success',
  13: 'warning',
};

export const incomingDocumentStatusValuesMap = {
  2: 'Подпись не требуется',
  3: 'Требуется подпись',
  4: 'Подписан',
  5: 'Отклонен',
  6: 'Отозван',
  7: 'Уточнен',
  8: 'Ожидается уточнение',
  9: 'Ошибка в подписи',
  10: 'Ошибка доставки',
  12: 'Подпись не требуется',
  13: 'Требуется подпись',
  14: 'Запрос на уточнение просмотрен (ожидается уточнение)',
  16: 'Ожидается аннулирование',
  17: 'Требуется аннулирование',
  18: 'Аннулирован',
  19: 'Отказано в аннулировании',
  default: 'Обрабатывается',
};

export const documentStatusIconsMap = {
  2: <Icon name="PublishedWithoutSign" />,
  3: <Icon name="DocumentDraft" />,
  4: <Icon name="PublishedWithSign" />,
  5: <Icon name="PublishedWithoutSign" />,
  12: <Icon name="PublishedWithoutSign" />,
  13: <Icon name="DocumentDraft" />,
  15: <Icon name="PublishedWithoutSign" />,
  16: <Icon name="Time" />,
  17: <Icon name="DocumentCanceled" />,
  18: <Icon name="DocumentCanceled" />,
  19: <Icon name="Cancel" />,
  default: <Icon name="DocumentProcessing" />,
};

export const documentStatusHEXColorsMap = {
  0: '#868686',
  1: '#ffa800',
  2: '#48c834',
  3: '#ffa800',
  4: '#48c834',
  5: '#48c834',
  8: '#ffa800',
  12: '#48c834',
  13: '#ffa800',
  15: '#48c834',
  16: '#ffa800',
  17: '#ffa800',
  18: '#f34747',
  default: '#868686',
};

export const documentStatusColorsMap = {
  2: 'success',
  3: 'warning',
  4: 'success',
  5: 'success',
  8: 'warning',
  12: 'success',
  13: 'warning',
  15: 'success',
  16: 'warning',
  17: 'warning',
  18: 'error',
};

export const documentTypeValuesMap = {
  [-1]: 'Неформализованный',
  [-2]: 'Договор о подключении к ГИС МТ',
  [-3]: 'Договор по предоставлению РЭ',
  [-4]: 'Договор на оказание услуг по предоставлению кодов маркировки',
  [-5]: 'Договор по предоставлению РВ',
  [-6]: 'Договор ЭДО',
  [-7]: 'Договор безвозмездного пользования движимым имуществом',
  [-8]: 'Договор по предоставлению устройства регистрации эмиссии',
  [-9]: 'Заявка Участника о предоставлении Оборудования РВ',
  [-10]: 'Заявка Участника о предоставлении Оборудования РЭ',
  [-11]: 'Заявка Участника о предоставлении РЭ путем УД',
  [-12]: 'Акт приема-передачи Оборудования',
  [-13]: 'Акт возврата Оборудования',
  [-14]: 'Акт проверки состояния и условий эксплуатации Оборудования',
  [-15]: 'Акт выполненных работ',
  [-16]:
    'Уведомление о предоставлении Участнику права пользования Оборудованием путем предоставления УД',
  [-24]: 'Информационное письмо',
  [-25]: 'Уведомление от Оператора',
  [-31]: 'Заявка на предоставление доступа к API СУЗ для резидентов',
  [-10000]: 'Акт сверки',
  100: 'Накладная',
  101: 'Титул покупателя на накладную',
  102: 'Счет-фактура',
  104: 'УПД',
  105: 'Титул покупателя на УПД',
  300: 'УПД(и) на накладную',
  301: 'Титул покупателя на УПД(и) на накладную',
  302: 'УПД(и) на счет-фактуру',
  304: 'УПД(и) на УПД',
  305: 'Титул покупателя на УПД(и) на УПД',
  504: 'УПД',
  200: 'УКД на накладную',
  201: 'Титул покупателя на УКД на накладную',
  202: 'УКД на СЧФ',
  204: 'УКД на УПД',
  205: 'Титул покупателя на УКД на УПД',
  400: 'УКД(и) на УКД на накладную',
  401: 'Титул покупателя на УКД(и) на накладную',
  402: 'УКД(и) на УКД на счет-фактуру',
  404: 'УКД(и) на УКД на УПД',
  405: 'Титул покупателя на УКД(и) на УПД',
  502: 'Счет-фактура',
  602: 'УКД на СЧФ',
  604: 'УКД на УПД',
};
