import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
  clearDocumentInfo,
  setDocumentInfo,
  setLoading,
  setUseHub,
} from './ClosingDocument.actions';

const documentInfoDefaultState = {
  data: {},
  loaded: false,
};

const documentInfo = handleActions(
  {
    [setDocumentInfo]: (_, { payload }) => payload,
    [LOCATION_CHANGE]: () => documentInfoDefaultState,
  },
  documentInfoDefaultState
);

const loading = handleActions(
  {
    [setLoading]: (_, { payload }) => payload,
    [clearDocumentInfo]: () => true,
  },
  true
);

const useHub = handleActions(
  {
    [setUseHub]: (_, { payload }) => payload,
  },
  false
);

export const reducer = {
  documentInfo,
  loading,
  useHub,
};
