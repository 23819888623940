import { DN, removeColonsFromString } from '../../helpers';
import { convertSubjectToDistinguishedName } from '../../helpers/DistinguishedName';
import { getErrorMessage } from './utils';

require('./cadesplugin_api');

const load = () => {
  window.cadesplugin_skip_extension_install = true;
  window.allow_firefox_cadesplugin_async = true;

  return new Promise((resolve) => {
    if (window.cadesplugin) {
      resolve(window.cadesplugin);
    }

    throw new Error('КриптоПро ЭЦП Browser plug-in не обнаружен');
  });
};

function signAsync(base64, certificate, detached) {
  let oStore, oCertificates, oCertificate, oSigner, oSignedData;
  return Promise.all([
    window.cadesplugin.CreateObjectAsync('CAdESCOM.Store'),
    window.cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner'),
    window.cadesplugin.CreateObjectAsync('CAdESCOM.CadesSignedData'),
  ])
    .then((objects) => {
      [oStore, oSigner, oSignedData] = objects;

      return oStore.Open(
        window.cadesplugin.CAPICOM_CURRENT_USER_STORE,
        window.cadesplugin.CAPICOM_MY_STORE,
        window.cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
      );
    })
    .then(() => oStore.Certificates)
    .then((certificates) =>
      certificates.Find(
        window.cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
        certificate.thumbprint
      )
    )
    .then((certificates) => {
      oCertificates = certificates;
      return oCertificates.Count;
    })
    .then((count) => {
      if (count != 1) {
        throw 'Не обнаружено сертификатов c указанным SHA1';
      }

      return oCertificates.Item(1);
    })
    .then((cert) => {
      oCertificate = cert;
      return oStore.Close();
    })
    .then(() => oCertificate.PrivateKey)
    .then((privateKey) => privateKey.propset_CachePin(false))
    .then(() => {
      const promises = [
        oSigner.propset_Certificate(oCertificate),
        oSigner.propset_Options(
          window.cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN
        ),
      ];

      return Promise.all(promises);
    })
    .then(() =>
      oSignedData.propset_ContentEncoding(
        window.cadesplugin.CADESCOM_BASE64_TO_BINARY
      )
    )
    .then(() => oSignedData.propset_Content(base64))
    .then(() =>
      oSignedData.SignCades(
        oSigner,
        window.cadesplugin.CADESCOM_CADES_BES,
        detached
      )
    )
    .catch((err) => {
      throw {
        ...err,
        errorMessage: getErrorMessage(err.message),
      };
    });
}

const signSync = (base64, certificate, detached) => {
  const { thumbprint } = certificate;

  return new Promise((resolve) => {
    const oStore = window.cadesplugin.CreateObject('CAdESCOM.Store');
    oStore.Open(
      window.cadesplugin.CAPICOM_CURRENT_USER_STORE,
      window.cadesplugin.CAPICOM_MY_STORE,
      window.cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
    );

    const oCertificates = oStore.Certificates.Find(
      window.cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
      thumbprint
    );

    if (oCertificates.Count != 1) {
      throw new Error('Не обнаружено сертификатов c указанным SHA1');
    }

    const oCertificate = oCertificates.Item(1);
    oStore.Close();

    const oSigner = window.cadesplugin.CreateObject('CAdESCOM.CPSigner');
    oSigner.Certificate = oCertificate;
    // oSigner.Options = cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN;
    oSigner.Options =
      window.cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_END_ENTITY_ONLY;

    const oSignedData = window.cadesplugin.CreateObject(
      'CAdESCOM.CadesSignedData'
    );
    // Значение свойства ContentEncoding должно быть задано до заполнения свойства Content
    oSignedData.ContentEncoding = window.cadesplugin.CADESCOM_BASE64_TO_BINARY;
    oSignedData.Content = base64;

    const signature = oSignedData.SignCades(
      oSigner,
      window.cadesplugin.CADESCOM_CADES_BES,
      detached
    );
    resolve(signature);
  }).catch((err) => {
    throw {
      ...err,
      errorMessage: getErrorMessage(err.message),
    };
  });
};

async function sign(...args) {
  await load();

  return window.cadesplugin.CreateObjectAsync
    ? signAsync(...args)
    : signSync(...args);
}

function signXML(xml, certificate, cachePin) {
  const cadesplugin = window.cadesplugin;

  return cadesplugin.async_spawn(
    function* (args) {
      let Signature;
      const dataToSign = args[0];
      const { thumbprint } = args[1];
      const cachePin = args[2];

      const oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store');
      oStore.Open(
        cadesplugin.CAPICOM_CURRENT_USER_STORE,
        cadesplugin.CAPICOM_MY_STORE,
        cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
      );

      const oStoreCertificates = yield oStore.Certificates;
      const oCertificates = yield oStoreCertificates.Find(
        cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
        thumbprint
      );
      const oCertificatesCount = yield oCertificates.Count;

      if (oCertificatesCount !== 1) {
        throw new Error('Не обнаружено сертификатов c указанным SHA1');
      }

      const oCertificate = yield oCertificates.Item(1);
      oStore.Close();

      const linkPrivateKey = yield oCertificate.PrivateKey;
      linkPrivateKey.propset_CachePin(cachePin);

      const oSigner = yield cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');

      if (!oSigner) {
        throw new Error('Failed to create CAdESCOM.CPSigner');
      }
      yield oSigner.propset_Certificate(oCertificate);

      const oSignedXML = yield cadesplugin.CreateObjectAsync(
        'CAdESCOM.SignedXML'
      );

      let signMethod = '';
      let digestMethod = '';

      const pubKey = yield oCertificate.PublicKey();
      const algo = yield pubKey.Algorithm;
      const algoOid = yield algo.Value;

      if (algoOid === '1.2.643.7.1.1.1.1') {
        // алгоритм подписи ГОСТ Р 34.10-2012 с ключом 256 бит
        signMethod = cadesplugin.XmlDsigGost3410Url2012256;
        digestMethod = cadesplugin.XmlDsigGost3411Url2012256;
      } else if (algoOid === '1.2.643.7.1.1.1.2') {
        // алгоритм подписи ГОСТ Р 34.10-2012 с ключом 512 бит
        signMethod = cadesplugin.XmlDsigGost3410Url2012512;
        digestMethod = cadesplugin.XmlDsigGost3411Url2012512;
      } else if (algoOid === '1.2.643.2.2.19') {
        // алгоритм ГОСТ Р 34.10-2001
        signMethod = cadesplugin.XmlDsigGost3410Url;
        digestMethod = cadesplugin.XmlDsigGost3411Url;
      } else {
        throw new Error(
          'Поддерживается XML подпись только сертификатами с алгоритмом ГОСТ Р 34.10-2012, ГОСТ Р 34.10-2001'
        );
      }

      if (dataToSign) {
        // Данные на подпись ввели
        yield oSignedXML.propset_Content(dataToSign);
        yield oSignedXML.propset_SignatureType(
          cadesplugin.CADESCOM_XML_SIGNATURE_TYPE_ENVELOPED
        );
        yield oSignedXML.propset_SignatureMethod(signMethod);
        yield oSignedXML.propset_DigestMethod(digestMethod);

        try {
          Signature = yield oSignedXML.Sign(oSigner);
        } catch (err) {
          throw new Error(
            'Не удалось создать подпись из-за ошибки: ' +
              cadesplugin.getLastError(err)
          );
        }
      }
      return Signature;
    },
    xml,
    certificate,
    cachePin
  );
}

function parseCertificate([
  subjectName,
  issuerName,
  thumbprint,
  validFromDate,
  validToDate,
  serialNumber,
]) {
  const subject = new DN(subjectName);
  const issuer = new DN(issuerName);
  const timestamp = new Date();

  return Object.freeze({
    id: thumbprint,
    info: [subjectName, validFromDate, validToDate, serialNumber, issuerName],
    issuer,
    issuerName,
    issuerDistinguishedName: convertSubjectToDistinguishedName(issuerName),
    plugin: 'cryptopro',
    serialNumber: removeColonsFromString(serialNumber),
    subject,
    subjectName,
    subjectDistinguishedName: convertSubjectToDistinguishedName(subjectName),
    thumbprint,
    valid:
      timestamp >= new Date(validFromDate) &&
      timestamp <= new Date(validToDate),
    validFromDate,
    validToDate,
  });
}

async function enumerateCertificatesAsync() {
  let Certificates;
  const certificates = [];

  const {
    CAPICOM_CURRENT_USER_STORE,
    CAPICOM_MY_STORE,
    CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
  } = window.cadesplugin;

  const Store = await window.cadesplugin.CreateObjectAsync('CAdESCOM.Store');

  await Store.Open(
    CAPICOM_CURRENT_USER_STORE,
    CAPICOM_MY_STORE,
    CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
  );

  Certificates = await Store.Certificates;

  const count = await Certificates.Count;

  for (let index = 1; index <= count; index++) {
    const Certificate = await Certificates.Item(index);

    const SubjectName = await Certificate.SubjectName;
    const IssuerName = await Certificate.IssuerName;

    if (SubjectName && IssuerName) {
      certificates.push(
        parseCertificate([
          SubjectName,
          IssuerName,
          await Certificate.Thumbprint,
          await Certificate.ValidFromDate,
          await Certificate.ValidToDate,
          await Certificate.SerialNumber,
        ])
      );
    }
  }

  await Store.Close();

  return certificates;
}

function enumerateCertificatesSync() {
  return new Promise((resolve) => {
    try {
      const oStore = window.cadesplugin.CreateObject('CAdESCOM.Store');

      oStore.Open(
        window.cadesplugin.CAPICOM_CURRENT_USER_STORE,
        window.cadesplugin.CAPICOM_MY_STORE,
        window.cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
      );

      const oCertificates = oStore.Certificates;
      const certificates = [];
      for (let i = 1; i <= oCertificates.Count; i++) {
        const oCertificate = oCertificates.Item(i);
        certificates.push(
          parseCertificate([
            oCertificate.SubjectName,
            oCertificate.IssuerName,
            oCertificate.Thumbprint,
            oCertificate.ValidFromDate,
            oCertificate.ValidToDate,
            oCertificate.SerialNumber,
          ])
        );
      }
      oStore.Close();
      resolve(certificates);
    } catch (error) {
      console.error(error);
    }
  });
}

async function enumerateCertificates() {
  await load();

  return window.cadesplugin.CreateObjectAsync
    ? enumerateCertificatesAsync()
    : enumerateCertificatesSync();
}

export default {
  enumerateCertificates,
  load,
  sign,
  signXML,
};
