import React from 'react';
import { largeDash } from '@crpt/shared/constants';

export interface CharProps {
  name: 'largeDash';
}

export const Char: React.VFC<CharProps> = (props) => {
  switch (props.name) {
    case 'largeDash':
      return <>{largeDash}</>;
    default:
      return null;
  }
};
