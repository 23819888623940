/** Типы квитанций в ЭДО */
export const EdoEventTypes: {[key: string]: number} = {
  DP_UVUTOCH: 110,
  DP_NEWUVUTOCH: 110019,
  DP_NEWUVUTOCH2: 110009,
  DP_UVUTOCH_NF: 110120,
  DP_UVUTOCH_NF_OLD: 120,
  DP_PRANNUL: 114001,
  DP_PRANNUL_OTHER: 114002,
  DP_PRANNULDENY: 110114,
  DP_PRANNULTEMP: 98,
};
