import { call, put, takeEvery } from 'redux-saga/effects';

import { HttpMethodEnum } from '../../../constants/index';
import { Api } from '../../Api/Api';

import { sendPayment, setLoading } from './PaymentDialog.actions';

function* sendPaymentSaga({ payload }) {
  yield put(setLoading(true));

  const requestPayload = {
    url: '/billing-api/orders/create',
    method: HttpMethodEnum.POST,
    data: {
      ...payload,
      amount: payload.amount * 100,
      successUrl: `${window.location.origin}/payment-status/success/{orderId}`,
      fallbackUrl: `${window.location.origin}/payment-status/error`,
    },
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    window.location = success.data.uri;
  }

  if (error) {
    // behavior on unsuccessful execution
  }

  yield put(setLoading(false));
}

export const saga = function* watch() {
  yield takeEvery(sendPayment, sendPaymentSaga);
};
