import { handleActions } from 'redux-actions';

import * as notificationActions from './Notification.actions';

const open = handleActions(
  {
    [notificationActions.open]: () => true,
    [notificationActions.close]: () => false,
  },
  false
);

export const reducer = {
  open,
};
