import { createSelector } from 'reselect';

export const getStartPageData = (state) => state.StartPage;

/**
 * Get active step
 * @param {ApplicationStoreType} state - Application state
 * @returns {number} - Active step
 */
export const getActiveStep = (state) => state.StartPage.activeStep;

/**
 * Get is skip GS1 step
 * @param {ApplicationStoreType} state - Application state
 * @returns {boolean} - Is skip GS1 step
 */
export const getSkipGS1Step = (state) => state.StartPage.skipGS1Step;

/**
 * Get GS1 list
 * @param {ApplicationStoreType} state - Application state
 * @returns {Array<GS1ListType>} - GS1 list
 */
export const getGS1List = (state) => state.StartPage.GS1List;

/**
 * Get operators EDO
 * @param {ApplicationStoreType} state - Application state
 * @returns {OperatorEdoType} - operators EDO
 */
export const getOperatorEdo = (state) => state.StartPage.operatorEdo;

/**
 * Get operators EDO
 * @param {ApplicationStoreType} state - Application state
 * @returns {OperatorEdoType} - operators EDO
 */
export const getOperatorEdoIds = (state) => state.StartPage.operatorEdoIds;

/**
 * Get selected operators  EDO
 * @param {ApplicationStoreType} state - Application state
 * @returns {Array<ListType>} - Selected operators  EDO
 */
export const getSelectedEDO = createSelector(getOperatorEdo, (operatorEdo) =>
  operatorEdo.activeEdo.map((activeItemId) =>
    operatorEdo.list.find((listItem) => listItem.id === activeItemId)
  )
);
/**
 * Get product group
 * @param {ApplicationStoreType} state - Application state
 * @returns {ProductGroupType} - Product group
 */
export const getProductGroup = (state) => state.StartPage.productGroup;

/**
 * Get selected product group
 * @param {ApplicationStoreType} state - Application state
 * @returns {Array<ListType>} - Selected product group
 */
export const getSelectedProductGroup = createSelector(
  getProductGroup,
  (productGroup) =>
    productGroup.activeProductGroup.map((item) => productGroup.list[item])
);

/**
 * Get prepared edo list from backend
 * @param {ApplicationStoreType} state - Application state
 */
export const getPreparedEdoList = createSelector(
  getSelectedEDO,
  getOperatorEdo,
  getOperatorEdoIds,
  (selectedEDO, _operatorEDO, operatorEDOIds) =>
    selectedEDO.map((edo) => ({
      id: edo.id,
      participant_edo_id: operatorEDOIds[edo.title],
    }))
);

/**
 * Check the form is filled EDO
 * @param {ApplicationStoreType} state - Application state
 */
export const isEdoFinish = createSelector(getPreparedEdoList, (edoList) =>
  edoList.every((edo) => edo.participant_edo_id)
);

/**
 * Get prepared product group list from backend
 * @param {ApplicationStoreType} state - Application state
 */
export const getPreparedProductGroupList = createSelector(
  getSelectedProductGroup,
  (selectedProductGroup) =>
    selectedProductGroup.map((productGroup) => productGroup.code)
);
