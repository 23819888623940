import React, { useCallback, useEffect, useMemo } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { loading } from '../../../common_components/Preloader/ducks/Preloader.selectors';
import * as authSelectors from '../../../common_components/Auth/ducks/Auth.selectors';

import {
  fetchProductionData,
  openProductionSiteModalEdit,
  removeProductionLine,
  setProductionPage,
} from './ducks/Production.actions';
import {
  getProductionData,
  getProductionPage,
  getProductionPagesCount,
} from './ducks/Production.selectors';
import { ProductionView } from './Production.view';
import { ProductionLineType } from './ProductionType';

const Production = ({
  productionLine,
  pagesCount,
  activePage,
  fetchProductionData,
  removeProductionLine,
  openProductionSiteModalEdit,
  setProductionPage,
  isLoading,
  isAdmin,
  isInActive,
}) => {
  useEffect(() => {
    fetchProductionData();
  }, [fetchProductionData]);

  const onRowClick = useCallback(
    (_, row) => {
      openProductionSiteModalEdit(row.index);
    },
    [openProductionSiteModalEdit]
  );

  const onRemoveProductionLine = useCallback(
    (index) => {
      removeProductionLine(index);
    },
    [removeProductionLine]
  );

  const onPageChange = useCallback(
    (page) => {
      setProductionPage(page - 1);
    },
    [setProductionPage]
  );

  const productionLines = useMemo(
    () => productionLine.result,
    [productionLine]
  );

  const datatableActions = useMemo(
    () => [
      {
        label: 'Изменить',
        callback: (row) => {
          onRowClick(null, row);
        },
      },
      {
        label: 'Удалить',
        callback: (row) => {
          onRemoveProductionLine(row.original.id);
        },
      },
    ],
    [onRemoveProductionLine, onRowClick]
  );

  return React.createElement(ProductionView, {
    onRowClick,
    productionLines,
    pagination: {
      pagesCount,
      onPageChange,
      activePage: activePage + 1,
    },
    datatableActions: isAdmin || isInActive ? datatableActions : [],
    isLoading,
  });
};

const mapStateToProps = (state) => ({
  productionLine: getProductionData(state),
  isLoading: loading(state),
  pagesCount: getProductionPagesCount(state),
  activePage: getProductionPage(state),
  isAdmin: authSelectors.isAdmin(state),
  isInActive: authSelectors.isInActive(state),
});

const mapDispatchToProps = {
  fetchProductionData,
  openProductionSiteModalEdit,
  removeProductionLine,
  setProductionPage,
};

Production.propTypes = {
  productionLine: ProductionLineType,
  fetchProductionData: PropTypes.func.isRequired,
  removeProductionLine: PropTypes.func.isRequired,
  openProductionSiteModalEdit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isInActive: PropTypes.bool.isRequired,
};

export const ProductionConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Production);
