import React, { FC, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Node, Scenario } from '@crpt/shared/components/Threads/Threads.types';
import { useBooleanState } from '@crpt/shared/hooks';
import ThreadsScenarioView from './ThreadsScenario.view';

export interface FetchDataParams {
  id: string;
  version?: number;
}

export type ThreadsScenarioProps = {
  data: Scenario;
  fetchData: (params: FetchDataParams) => void;
  id: string;
  isLoading: boolean;
  reset: () => void;
} & RouteComponentProps;

const ThreadsScenario: FC<ThreadsScenarioProps> = ({
  data,
  fetchData,
  id,
  isLoading,
  reset,
}) => {
  const nodes: Node[] = data?.graph?.nodes || [];

  const hasFakeNode = useMemo(
    () => nodes.findIndex(({ fake }) => fake) !== -1,
    [nodes]
  );

  const [
    isVersionsHistoryShow,
    { off: closeVersionsHistory, toggle: toggleVersionsHistory },
  ] = useBooleanState();

  const { versions, version } = useMemo(
    () => ({
      versions: data?.versions || [],
      version: data?.version,
    }),
    [data]
  );

  const [clickedVersion, setClickedVersion] = useState<number | undefined>();

  const [isStartState, setStartState] = useState(true);

  const clearClickedVersion = () => setClickedVersion(undefined);

  useEffect(() => {
    fetchData({
      id,
    });
    setStartState(false);

    return () => {
      reset();
    };
  }, [id]);

  useEffect(() => {
    if (!isLoading) {
      clearClickedVersion();
    }
  }, [isLoading]);

  const fetchVersion = (version: number) => {
    setClickedVersion(version);
    fetchData({
      id,
      version,
    });
  };

  return (
    <ThreadsScenarioView
      clickedVersion={clickedVersion}
      data={data}
      fetchVersion={fetchVersion}
      hasFakeNode={hasFakeNode}
      isLoading={isLoading}
      isStartState={isStartState}
      isVersionsHistoryShow={isVersionsHistoryShow}
      nodes={nodes}
      setVersionsHistoryClose={closeVersionsHistory}
      toggleVersionsHistory={toggleVersionsHistory}
      version={version}
      versions={versions}
    />
  );
};

export default ThreadsScenario;
