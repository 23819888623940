import { Select } from '@crpt-ui/core';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { generateFilter } from '../Datatable.utils';

export const SelectFilter = React.memo(
  ({ column, form, formState, instance, ...rest }) => {
    const onChange = useCallback((event) => {
      const value = event.target.value;
      form.change('value', value);
    }, []);

    const value = useMemo(() => _.get(formState.values, 'value'), [
      formState.values,
    ]);

    return React.createElement(Select, {
      ...rest,
      name: 'value',
      onChange: onChange,
      value,
    });
  }
);

export const makeSelectFilter = generateFilter(SelectFilter);

SelectFilter.propTypes = {
  column: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};
