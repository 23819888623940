import { createActions } from 'redux-actions';

export const { downloadDocumentWorkflow, downloadDocument } = createActions(
  {
    DOWNLOAD_DOCUMENT_WORKFLOW: (payload) => payload,
    DOWNLOAD_DOCUMENT: (payload) => payload,
  },
  {
    prefix: 'DOCUMENT',
  }
);
