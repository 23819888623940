export enum EliminationReasonEnum {
  KM_SPOILED = 1,
  KM_LOST = 2,
  KM_DESTROYED = 3,
  DESCRIPTION_ERRORS = 4,
  RETAIL = 10,
  EAS_TRADE = 11,
  BEYOND_EEC_EXPORT = 12,
  RETURN = 13,
  REMOTE_SALE = 14,
  LOSS = 15,
  DAMAGE_LOSS = 15,
  DESTRUCTION = 16,
  CONFISCATION = 17,
  LIQUIDATION = 18,
  DONATION = 20,
  STATE_CONTRACT = 21,
  NO_RETAIL_USE = 22,
  ENTERPRISE_USE = 23,
  KM_SPOILED_OR_LOST = 24,
  KM_CANCELLATION = 26,
  EEC_EXPORT_RETURN = 31, // FIXME: отсутствует в справочнике, но используется в карточке КИ
  DEFECT = 32,
  EXPIRY = 33,
  QA_SAMPLES = 34,
  PRODUCT_RECALL = 35,
  COMPLAINTS = 36,
  PRODUCT_TESTING = 37,
  DEMO_SAMPLES = 38,
  OTHER = 39,
  BY_SAMPLES = 40,
  DISTANCE = 41,
  VENDING = 42,
  OWN_USE = 46,
  UTILIZATION = 47,
  RECALL = 48,
  PACKING = 49,
  PRODUCTION_USE = 50,
  EXPIRATION = 51,
  OWN_USE_EXCISE = 54,
  BEFORE_MARKING = 55,
  KM_CANCELATION = 99,
  STATE_SECRET = 100,
  AUTO_CANCELLATION = 104,
  REMAINS_CANCELLATION = 105,
  DISAGGREGATED = 106,
}
