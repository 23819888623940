import React, { FC, memo } from 'react';
import { PreloaderView } from './Preloader.view';

export interface PreloaderProps {
  isOpen: boolean;
  text?: string;
}

export const Preloader: FC<PreloaderProps> = memo(({ isOpen, text = 'Загрузка списка' }) => {
  return <PreloaderView text={text} isOpen={isOpen} />
});