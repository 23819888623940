import {
  DocumentCodeEnum,
  NdsEnum,
  ReceiptStatusCodeEnum,
  ReceiptTypeCodeEnum,
  ReceiptTypeIdEnum,
  TaxationTypeEnum,
} from '../constants';
import {
  ProductGroupCodeEnum,
  ProductGroupDTO,
  ProductGroupIdEnum,
} from '@crpt/shared/constants';
import { PaginationDTO } from './common';

export type ReceiptId = string;

// https://confluence.crpt.ru/pages/viewpage.action?pageId=184607335
export enum ReceiptProductCodeTypeEnum {
  KM = 1,
  KIZ = 2,
  GTIN = 3,
}

export interface ReceiptItemDTO {
  name: string;
  price: number;
  productGroups?: ProductGroupDTO;
  quantity: number;
  nds: NdsEnum;
  ndsSum: number;
  sum: number;
  cis?: string;
  productCode: string;
  sgtin: string;
  status: ReceiptStatusCodeEnum;
  paymentType?: number;
  productType?: number;
  providerInn?: string;
  paymentAgentByProductType?: number;
  propertiesItem?: string;
  internetSign?: boolean;
  productCodeType?: ReceiptProductCodeTypeEnum;
  providerData?: ProviderDataDTO;
  cisesToSearch?: string[];
}

export interface ReceiptBodyDTO {
  receipt: {
    ofdINN: string;
    indicationfiscalSign: number;
    code: DocumentCodeEnum;
    retailAddress: string;
    items: ReceiptItemDTO[];
    fiscalDriveNumber: string;
    kktRegId: string;
    userInn: string;
    fiscalDocumentNumber: number;
    fiscalSign: string;
    fiscalDocumentFormatVer: number;
    dateTime: number;
    shiftNumber: number;
    requestNumber: number;
    operationType: ReceiptTypeIdEnum;
    totalSum?: number;
    nds18: number;
    user: string;
    taxationType: TaxationTypeEnum;
  };
}

export interface ReceiptProductGroupDTO {
  id: ProductGroupIdEnum;
  code: ProductGroupCodeEnum;
  status: ReceiptStatusCodeEnum;
}

export interface ReceiptDTO {
  documentId: ReceiptId;
  docDate: string;
  receivedAt: string;
  type: ReceiptTypeCodeEnum;
  status: ReceiptStatusCodeEnum;
  senderName: string;
  senderInn: string;
  senderKpp?: string;
  receiverInn?: string;
  receiverKpp?: string;
  receiverName?: string;
  total?: number;
  vat?: number;
  content?: string;
  docErrors?: string[];
  productGroups: ReceiptProductGroupDTO[];
  commonErrors: CommonErrorDTO[];
  lastError?: boolean;
  body: ReceiptBodyDTO;
}

export interface CommonErrorDTO {
  errorCode?: string;
  errorMessage?: string;
}

export interface ReceiptTransactionFilterDTO {
  startSourceDocDate?: number;
  endSourceDocDate?: number;
  startStartDocDate?: number;
  endStartDocDate?: number;
  startResultDocDate?: number;
  endResultDocDate?: number;
  workflowTypes?: string[];
  resultDocIds?: string[];
  sourceDocIds?: string[];
  resultCodes?: number[];

  operationFilter: {
    productGroups?: string[];
    productGroupIds?: number[];
    kktRegIds?: string[];
    fiscalDocumentNumbers?: string[];
    startReceiptDateTime?: number;
    endReceiptDateTime?: number;
    receiptBuyerInns?: string[];
    receiptUserInns?: string[];
    receiptProviderInns?: string[];
    successful?: string;
    buyerORseller?: string;
    dids?: string[];
    receiptTypeIds?: ReceiptTypeIdEnum[];
  };
}

export interface ReceiptOperationDTO {
  details: {
    receiptBuyerInn?: string;
    receiptUserInn?: string;
    receiptProviderInns?: string[];
    receiptCode?: string;
    receiptFiscalDocumentFormatVer?: FiscalDocumentFormatVerEnum;
    receiptOperationType?: ReceiptOperationTypeEnum;
    successful?: 'true' | 'false';
    kktRegId?: string;
    documentNumber?: string;
    receiptDateTime?: number;
    productGroups?: ProductGroupCodeEnum[];
    receiptTypeId?: ReceiptTypeIdEnum;
  };
}

export type FiscalDocumentFormatVerEnum = '1' | '2' | '3' | '4';

export enum TransactionStateEnum {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  PARTIALLY = 'PARTIALLY',
}

export enum TransactionStateCodeEnum {
  SUCCESS = 0,
  FAILED = 1,
  IN_PROGRESS = 2,
  PARTIALLY = 3,
}

export interface ReceiptTransactionDTO {
  did?: string;
  resultDocId: string;
  resultDocDate: number;
  sourceDocId: string;
  sourceDocDate: number;
  state: TransactionStateEnum;
  code: number;
  description: string;
  workflow?: string;
  workflowVersion?: number;
  operations?: ReceiptOperationDTO[];
}

export enum ReceiptOperationTypeEnum {
  INCOME = 1,
  INCOME_RETURN = 2,
  EXPENSES = 3,
  EXPENSES_RETURN = 4,
}

export interface FetchReceiptTransactionsParams {
  pageNumber: number;
  pageSize: number;
  filter?: ReceiptTransactionFilterDTO;
}

export interface ProviderDataDTO {
  providerName: string;
  providerPhone: string;
}

export interface ReceiptTransactionResponseDTO {
  pagination: PaginationDTO;
  results?: ReceiptTransactionDTO[];
}
