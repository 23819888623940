import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { Icon, Tooltip, TooltipProps, Typography } from '@crpt-ui/core';
import { ClassNameMap, TooltipClassKey, TypographyProps } from '@mui/material';
import { useTooltipClasses } from './SmartTooltip.styles';
import { toast } from '@crpt/shared/toast';
import { useTranslation } from 'react-i18next';

export interface SmartTooltipProps {
  text: string | React.ReactNode;
  classNameText?: string;
  placement?: TooltipProps['placement'];
  variant?: TypographyProps['variant'];
  zeroWidth?: boolean;
  arrow?: boolean;
  tooltipClasses?: Partial<ClassNameMap<TooltipClassKey>>;
  copyable?: boolean;
  maxWidth?: number;
}

const SmartTooltip = ({
  text,
  classNameText,
  variant,
  placement = 'right',
  zeroWidth = false,
  arrow = true,
  tooltipClasses,
  copyable,
  maxWidth,
}: SmartTooltipProps) => {
  const [isActiveTooltip, setIsActiveTooltip] = useState(false);

  const inlineStyle = {};
  const { t } = useTranslation();

  const handlerOnMouseEnter = ({ target }: React.MouseEvent) => {
    const targetElement = target as HTMLElement;
    if (!isActiveTooltip && targetElement) {
      const active = targetElement.scrollWidth > targetElement.clientWidth;
      setIsActiveTooltip(active);
    }
  };

  const copyHandler = useCallback(() => {
    const message = t(
      'Message.copyToClipboard',
      'Значение было успешно скопировано в буфер обмена'
    );
    if (typeof text === 'string') {
      navigator.clipboard.writeText(text).then(() => toast.success(message));
    }
  }, [t, text]);

  const classes = useTooltipClasses({
    isActiveTooltip,
    variant,
    zeroWidth,
  });

  const tooltipStyling = tooltipClasses ? { classes: tooltipClasses } : {};

  return (
    <Grid container>
      <Tooltip
        arrow={arrow}
        interactive
        maxWidth={maxWidth}
        {...tooltipStyling}
        disableHoverListener={!isActiveTooltip}
        placement={placement}
        title={
          <div className={classes.tooltipContainer}>
            {copyable && (
              <Icon
                className={classes.icon}
                name="copy"
                onClick={copyHandler}
              />
            )}
            {text}
          </div>
        }
      >
        <Typography
          className={clsx(classNameText, classes.text)}
          onMouseEnter={handlerOnMouseEnter}
          style={inlineStyle}
          variant={variant}
        >
          {text}
        </Typography>
      </Tooltip>
    </Grid>
  );
};

export default SmartTooltip;
