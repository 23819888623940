import { call, put, select, take, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash';

import { Api } from '../../../../common_components/Api/Api';
import { declineAgreementDocumentSaga } from '../../AgreementDocument/ducks/AgreementDocument.saga';
import {
  openSignModalById,
  successfullySignedAgreementDocuments,
} from '../../AgreementDocument/SignModal/ducks/SignModal.actions';

import {
  getAgreementDocumentsOffset,
  getAgreementDocumentsSort,
} from './AgreementDocumentsList.selectors';
import {
  clearSortAgreementDocuments,
  fetchAgreementDocuments,
  setActivePageAgreementDocuments,
  setAgreementDocuments,
  setSortAgreementDocuments,
  startDeclineAgreementDocument,
  startSignAgreementDocumentFromList,
} from './AgreementDocumentsList.actions';

function* setSortSaga(sortingColumn) {
  const lastSorting = yield select(getAgreementDocumentsSort);

  if (lastSorting.sort === sortingColumn) {
    const direction = lastSorting.direction === 'DESC' ? 'ASC' : 'DESC';
    yield put(setSortAgreementDocuments({ direction }));
  } else {
    yield put(
      setSortAgreementDocuments({ direction: 'ASC', sort: sortingColumn })
    );
  }
}

function* fetchAgreementDocumentsSaga(action) {
  const page = get(action, 'payload.page');
  if (page) {
    yield put(setActivePageAgreementDocuments(page));
  }
  const offset = yield select(getAgreementDocumentsOffset);

  const sortingColumn = get(action, 'payload.sort');
  if (sortingColumn) {
    yield call(setSortSaga, sortingColumn);
  }
  const sorting = yield select(getAgreementDocumentsSort);

  const requestPayload = {
    url: '/api/v3/facade/agreement-registry/agreement/list',
    params: {
      offset,
      ...sorting,
    },
  };

  const [success] = yield call(Api.request, requestPayload);

  if (success) {
    yield put(setAgreementDocuments(success.data));
  }
}

function* signAgreementDocumentFromList({ payload: { documentId } }) {
  yield put(openSignModalById(documentId));

  yield take(successfullySignedAgreementDocuments);
  yield call(fetchAgreementDocumentsSaga);
}

function* declineAgreementDocumentFromList() {
  yield call(declineAgreementDocumentSaga);
  yield call(fetchAgreementDocumentsSaga);
}

export const saga = function* watch() {
  yield takeEvery(
    [fetchAgreementDocuments, clearSortAgreementDocuments],
    fetchAgreementDocumentsSaga
  );
  yield takeEvery(
    startSignAgreementDocumentFromList,
    signAgreementDocumentFromList
  );
  yield takeEvery(
    startDeclineAgreementDocument,
    declineAgreementDocumentFromList
  );
};
