import React from 'react';

import PropTypes from 'prop-types';

import { Icon } from '@ibox/ui';

export const CloseModalIcon = ({ onClick }) => (
  <div style={{ cursor: 'pointer', position: 'absolute', top: 32, right: 32 }}>
    <Icon name="Close" onClick={onClick} />
  </div>
);

CloseModalIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
};
