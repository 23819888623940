/** Статусы документов ЭДО */
export const EdoDocumentStatuses = {
  unknown: -1,
  draft: 0,
  sent: 1,
  delivered: 2,
  signatureRequired: 3,
  signed: 4,
  rejected: 5,
  recalled: 6,
  clarified: 7,
  clarificationRequired: 8,
  signError: 9,
  deliveringError: 10,
  deliveringIsExpected: 11,
  viewedSignatureIsNotRequired: 12,
  viewedSignatureRequired: 13,
  clarificationRequest: 14,
  viewedRejected: 15,
  revocationExpected: 16,
  revocationRequest: 17,
  revocated: 18,
  revocateDenied: 19,
  errorSend1: 41,
  errorSend2: 42,
  errorParse1: 43,
  errorParse2: 44,
  transitRejected: 51,
  revocationRoaming: 53,
  revocationRoamingDenied: 54,
  signedSubmitted: 61,
  signedNotAccepted: 62,
  signedSubmitting: 63,
  revocatedSubmitted: 64,
  revocatedNotAccepted: 65,
  revocatedSubmitting: 66,
  transitClarificationRequired: 81,
} as const;

/** Названия исходящих статусов документов */
export const EdoDocumentStatusOutgoingTitles = {
  [EdoDocumentStatuses.draft]: 'Черновик',
  [EdoDocumentStatuses.sent]: 'Отправлен',
  [EdoDocumentStatuses.delivered]: 'Доставлен (подпись не требуется)',
  [EdoDocumentStatuses.signatureRequired]: 'Доставлен (ожидается подпись)',
  [EdoDocumentStatuses.signed]: 'Подписан',
  [EdoDocumentStatuses.rejected]: 'Подписан с расхождениями',
  [EdoDocumentStatuses.viewedRejected]:
    'Подписан с расхождениями (запрос просмотрен)',
  [EdoDocumentStatuses.recalled]: 'Отозван',
  [EdoDocumentStatuses.clarified]: 'Уточнён',
  [EdoDocumentStatuses.clarificationRequired]: 'Требуется уточнение',
  [EdoDocumentStatuses.signError]: 'Ошибка в подписи',
  [EdoDocumentStatuses.deliveringError]: 'Ошибка доставки',
  [EdoDocumentStatuses.deliveringIsExpected]: 'Ожидается отправка',
  [EdoDocumentStatuses.viewedSignatureIsNotRequired]:
    'Просмотрен (подпись не требуется)',
  [EdoDocumentStatuses.viewedSignatureRequired]:
    'Просмотрен (ожидается подпись)',
  [EdoDocumentStatuses.clarificationRequest]:
    'Требуется уточнение (запрос на уточнение просмотрен)',
  [EdoDocumentStatuses.errorSend1]: 'Ошибка отправки',
  [EdoDocumentStatuses.errorSend2]: 'Ошибка отправки',
  [EdoDocumentStatuses.errorParse1]: 'Ошибка обработки',
  [EdoDocumentStatuses.errorParse2]: 'Ошибка обработки',
  [EdoDocumentStatuses.transitRejected]: 'Запрашивается отказ в подписи',
  [EdoDocumentStatuses.transitClarificationRequired]: 'Запрашивается уточнение',
  [EdoDocumentStatuses.signedSubmitted]: 'Подписан: Отправлен в ГИС МТ',
  [EdoDocumentStatuses.signedNotAccepted]: 'Подписан: Не принят в ГИС МТ',
  [EdoDocumentStatuses.signedSubmitting]: 'Подписан: Отправка в ГИС МТ',
  [EdoDocumentStatuses.revocationExpected]: 'Ожидается аннулирование',
  [EdoDocumentStatuses.revocationRequest]: 'Требуется аннулирование',
  [EdoDocumentStatuses.revocated]: 'Аннулирован',
  [EdoDocumentStatuses.revocateDenied]: 'Отказано в аннулировании',
  [EdoDocumentStatuses.revocationRoaming]: 'Аннулирование документа',
  [EdoDocumentStatuses.revocationRoamingDenied]: 'Отказ в аннулировании',
  [EdoDocumentStatuses.revocatedSubmitted]: 'Аннулирован: Отправлен в ГИС МТ',
  [EdoDocumentStatuses.revocatedNotAccepted]: 'Аннулирован: Не принят в ГИС МТ',
  [EdoDocumentStatuses.revocatedSubmitting]: 'Аннулирован: Отправка в ГИС МТ',
};

/** Названия входящих статусов документов */
export const EdoDocumentStatusIncomingTitles = {
  [EdoDocumentStatuses.delivered]: 'Подпись не требуется',
  [EdoDocumentStatuses.signatureRequired]: 'Требуется подпись',
  [EdoDocumentStatuses.signed]: 'Подписан',
  [EdoDocumentStatuses.rejected]: 'Отклонен',
  [EdoDocumentStatuses.recalled]: 'Отозван',
  [EdoDocumentStatuses.clarified]: 'Уточнён',
  [EdoDocumentStatuses.clarificationRequired]: 'Ожидается уточнение',
  [EdoDocumentStatuses.signError]: 'Ошибка в подписи',
  [EdoDocumentStatuses.deliveringError]: 'Ошибка доставки',
  [EdoDocumentStatuses.viewedSignatureIsNotRequired]:
    'Подпись не требуется (документ просмотрен)',
  [EdoDocumentStatuses.viewedSignatureRequired]:
    'Требуется подпись (документ просмотрен)',
  [EdoDocumentStatuses.clarificationRequest]:
    'Запрос на уточнение просмотрен (ожидается уточнение)',
  [EdoDocumentStatuses.viewedRejected]: 'Отклонен (запрос просмотрен)',
  [EdoDocumentStatuses.errorSend1]: 'Ошибка отправки',
  [EdoDocumentStatuses.errorSend2]: 'Ошибка отправки',
  [EdoDocumentStatuses.errorParse1]: 'Ошибка обработки',
  [EdoDocumentStatuses.errorParse2]: 'Ошибка обработки',
  [EdoDocumentStatuses.transitRejected]: 'Запрашивается отказ в подписи',
  [EdoDocumentStatuses.transitClarificationRequired]: 'Запрашивается уточнение',
  [EdoDocumentStatuses.signedSubmitted]: 'Подписан: Отправлен в ГИС МТ',
  [EdoDocumentStatuses.signedNotAccepted]: 'Подписан: Не принят в ГИС МТ',
  [EdoDocumentStatuses.signedSubmitting]: 'Подписан: Отправка в ГИС МТ',
  [EdoDocumentStatuses.revocationExpected]: 'Ожидается аннулирование',
  [EdoDocumentStatuses.revocationRequest]: 'Требуется аннулирование',
  [EdoDocumentStatuses.revocated]: 'Аннулирован',
  [EdoDocumentStatuses.revocateDenied]: 'Отказано в аннулировании',
  [EdoDocumentStatuses.revocationRoaming]: 'Аннулирование документа',
  [EdoDocumentStatuses.revocationRoamingDenied]: 'Отказ в аннулировании',
  [EdoDocumentStatuses.revocatedSubmitted]: 'Аннулирован: Отправлен в ГИС МТ',
  [EdoDocumentStatuses.revocatedNotAccepted]: 'Аннулирован: Не принят в ГИС МТ',
  [EdoDocumentStatuses.revocatedSubmitting]: 'Аннулирован: Отправка в ГИС МТ',
};

export type EdoDocumentStatusesType =
  typeof EdoDocumentStatuses[keyof typeof EdoDocumentStatuses];
