import { createSelector } from 'reselect';

export const NotificationOperatorDocumentState = (state) =>
  state.Documents.NotificationOperatorDocument;

export const getContentNotificationOperatorDocument = createSelector(
  [NotificationOperatorDocumentState],
  (state) => state.content
);
export const getInfoNotificationOperatorDocument = createSelector(
  [NotificationOperatorDocumentState],
  (state) => state.info
);

export const getLoading = createSelector(
  [NotificationOperatorDocumentState],
  (state) => state.loading
);
