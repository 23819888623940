import React, { Fragment, useMemo } from 'react';

interface CurrencyProps {
  children: number | string;
  symbolCurrency?: boolean;
  isPenny?: boolean;
}

/**
 * @example
 *   1000 -> 1 000,00 драм.
 *   1000.22 -> 1 000,22 драм.
 *   1000, symbolCurrency: true -> 1 000,00 ₽
 *   100000, isPenny: true -> 1 000,00 драм.
 * @param {number} value
 * @param {boolean} symbolCurrency - Show currency symbol
 * @param {boolean} isPenny - Do I need to divide the amount by 100
 * @return {Node}
 */
export const Currency: React.FC<CurrencyProps> = ({
  children,
  symbolCurrency,
  isPenny,
}) => {
  const value = useMemo(() => {
    const numberValue = Number(children);

    return Number(isPenny ? numberValue / 100 : numberValue);
  }, [children, isPenny]);

  return (
    <Fragment>
      {value.toLocaleString('ru-RU', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}
      &nbsp;{symbolCurrency ? 'драм' : 'драм.'}
    </Fragment>
  );
};

Currency.defaultProps = {
  symbolCurrency: false,
  isPenny: false,
};
