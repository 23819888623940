import { createActions } from 'redux-actions';

export const {
  fetchNotificationOperatorDocuments,
  setNotificationOperatorDocuments,
  setLoading,
  setPage,
  setSort,
  setFilter,
  clearFilter,
  clearSort,
} = createActions(
  {
    FETCH_NOTIFICATION_OPERATOR_DOCUMENTS: (payload) => payload,
    SET_NOTIFICATION_OPERATOR_DOCUMENTS: (payload) => payload,

    SET_LOADING: (payload) => payload,
    SET_PAGE: (payload) => payload,
    SET_SORT: (payload) => payload,
    SET_FILTER: (payload) => payload,

    CLEAR_SORT: () => {},
    CLEAR_FILTER: () => {},
  },
  {
    prefix: 'NOTIFICATION_OPERATOR_DOCUMENTS',
  }
);
