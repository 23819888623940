import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import { Icon, Pagination, Typography } from '@crpt-ui/core';
import Datatable from '@crpt-ui/datatable';
import { NoInfo } from '@crpt/shared/components';

import { withContentWrapper } from '../../../common_components/hocs/withContentWrapper/withContentWrapper';
import { Loader } from '../../../common_components/Preloader/Preloader';

export const SupportListViewComponent = ({
  columns,
  data,
  loading,
  page,
  pagesCount,
  totalElements,
  setPage,
}) => {
  if (loading) {
    return <Loader />;
  }

  if (data.length === 0) {
    return (
      <NoInfo
        icon={
          <Icon
            name="Support"
            style={{
              fontSize: 100,
            }}
          />
        }
      >
        <Typography variant="h5">
          У вас еще нет обращений в техподдержку
        </Typography>
      </NoInfo>
    );
  }

  return (
    <Fragment>
      <Datatable columns={columns} data={data} />

      {totalElements > 10 && (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            height: 60,
            padding: '0 40px',
            position: 'absolute',
            left: 0,
            bottom: 0,
          }}
        >
          <Pagination
            page={page}
            pagesCount={pagesCount}
            onPageChange={setPage}
          />
        </div>
      )}
    </Fragment>
  );
};

SupportListViewComponent.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};

export const SupportListView = withContentWrapper({
  style: {
    padding: '0 0 60px',
    height: 'calc(100% - 70px)',
  },
})(SupportListViewComponent);
