import { createActions } from 'redux-actions';

export const { closeMilkNotificationDialog } = createActions(
  {
    CLOSE_MILK_NOTIFICATION_DIALOG: () => {},
  },
  {
    prefix: 'MILK_NOTIFICATION',
  }
);
