import React from 'react';

import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import {
  SelectFieldFinalFormWrapper,
  TextFieldFinalFormWrapper,
} from '@ibox/ui';

import {
  formatINN,
  isValidInn,
  required,
  validateMaxLength,
} from '../../../../common_components/Form/utils/validations';
import { orgTypeOptions } from '../../ParticipantDetails.constants';

type CommonInfoProps = {
  disabled?: boolean;
  isCreation?: boolean;
  isEdit?: boolean;
  onSelectOrgForm: () => void;
};

export const CommonInfo: React.FC<CommonInfoProps> = ({
  disabled,
  isCreation,
  isEdit,
  onSelectOrgForm,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h6">{t('Общие данные')}</Typography>
      </Grid>
      <Grid item>
        <Field
          fullWidth
          component={TextFieldFinalFormWrapper}
          defaultValue={disabled ? '—' : ''}
          disabled={disabled}
          label={t('Краткое наименование')}
          name="shortName"
          required={!disabled}
          validate={validateMaxLength(1024)}
        />
      </Grid>
      <Grid item>
        <Field
          fullWidth
          component={TextFieldFinalFormWrapper}
          defaultValue={disabled ? '—' : ''}
          disabled={disabled}
          label={t('Полное наименование')}
          name="fullName"
          required={!disabled}
          validate={validateMaxLength(1024)}
        />
      </Grid>
      <Grid item>
        {isCreation || isEdit ? (
          <Field
            component={SelectFieldFinalFormWrapper}
            disabled={isEdit}
            label={t('Организационно-правовая форма')}
            name="organizationForm"
            optionLabel="label"
            options={orgTypeOptions}
            required={!disabled && !isEdit}
            validate={required}
            onSelect={onSelectOrgForm}
          />
        ) : (
          <Field
            fullWidth
            component={TextFieldFinalFormWrapper}
            defaultValue={disabled ? '—' : ''}
            disabled={disabled}
            label={t('Организационно-правовая форма')}
            name="organizationFormName"
            required={!disabled}
          />
        )}
      </Grid>
      <Grid item>
        <Field
          fullWidth
          component={TextFieldFinalFormWrapper}
          defaultValue={disabled ? '—' : ''}
          disabled={disabled}
          format={formatINN}
          label={t('ИНН')}
          name="inn"
          required={!disabled}
          validate={isValidInn}
        />
      </Grid>
    </Grid>
  );
};

CommonInfo.defaultProps = {
  disabled: true,
  isCreation: false,
};
