import React, { memo, useCallback, useEffect } from 'react';
import { DatePickerInput, Icon } from '@crpt-ui/core';
import { Stack } from '@mui/material';
import { FilterValidationErrors } from './FilterValidationErrors';

export const makeRangeDateInputFilter = ({
  format = 'dd.MM.yyyy',
  startLabel = 'От',
  endLabel = 'До',
  minDate,
  maxDate = new Date(),
  validator,
  maxErrorCount,
} = {}) => {
  return memo((props) => {
    const { form, formState } = props;
    const formValue = formState?.values?.value;
    const errors = formState.errors;

    useEffect(() => {
      if (validator) {
        form.setConfig('validate', validator);
      }
    }, [validator]);

    const onStartChange = useCallback(
      (newValue) => form.change('value.startDate', newValue),
      [form]
    );

    const onEndChange = useCallback(
      (newValue) => form.change('value.endDate', newValue),
      [form]
    );

    return (
      <Stack direction="column" spacing={2}>
        <DatePickerInput
          date={formValue?.startDate}
          displayFormat={format}
          label={startLabel}
          maxDate={formValue?.endDate || maxDate}
          minDate={minDate}
          onChange={onStartChange}
          InputProps={{
            endAdornment: (
              <Icon name="calendar" style={{ cursor: 'pointer' }} />
            ),
          }}
        />
        <DatePickerInput
          date={formValue?.endDate}
          displayFormat={format}
          label={endLabel}
          maxDate={maxDate}
          minDate={formValue?.startDate}
          onChange={onEndChange}
          InputProps={{
            endAdornment: (
              <Icon name="calendar" style={{ cursor: 'pointer' }} />
            ),
          }}
        />
        <FilterValidationErrors errors={errors} maxErrorCount={maxErrorCount} />
      </Stack>
    );
  });
};
