export const productGroupStatusEnum = Object.freeze({
  ACTIVE: '5',
  CHANGING_TYPE: '6',
  ENABLING: '4',
  PROFILED: '2',
  REGISTERED: '1',
  SIGNER: '3',
  REVOKED: '0',
  REVOKED_WITH_ACCESS_ENABLED: '10',
  REGISTERED_WITH_ACCESS_ENABLED: '11',
  PROFILED_WITH_ACCESS_ENABLED: '12',
  SIGNER_WITH_ACCESS_ENABLED: '13',
  PRECESS_SIGNER_ACCESS_ENABLED: '14',
});
