import { RadioGroup } from '@crpt-ui/core';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import { DefaultFilter } from './DefaultFilter';
import { generateFilter } from '../Datatable.utils';

export const RadioGroupFilter = React.memo(
  ({ column, form, formState, instance, ...rest }) => {
    const onChange = useCallback(
      (event) => {
        form.change('value', event.target.value);
      },
      [form]
    );

    const value = useMemo(
      () => _.get(formState.values, 'value'),
      [formState.values]
    );

    return React.createElement(RadioGroup, {
      ...rest,
      defaultValue: value,
      name: 'value',
      onChange: onChange,
    });
  }
);

export const makeRadioGroupFilter = generateFilter(RadioGroupFilter);

RadioGroupFilter.propTypes = {
  ...DefaultFilter.propTypes,
  options: PropTypes.arrayOf(PropTypes.object),
};
