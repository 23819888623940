import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { TextField } from '@ibox/ui';

import { composeValidations } from './Form.utils';
import { required as validateRequired } from './utils/validations';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  mask: PropTypes.string,
  maskOptions: PropTypes.shape({}),
  validate: PropTypes.func,
};

const TextareaAdapterPropTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  theme: PropTypes.object,
  blured: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  select: PropTypes.bool,
  maxLength: PropTypes.number,
  mask: PropTypes.string,
  maskOptions: PropTypes.shape({}),
  action: PropTypes.string,
  actionCallback: PropTypes.func,
  onBlur: PropTypes.func,
  InputProps: PropTypes.object,
  tooltipText: PropTypes.string,
  icon: PropTypes.element,
  initialValue: PropTypes.number,
};

const defaultProps = {
  required: false,
  disabled: false,
  maxLength: null,
  tooltipText: '',
};

const TextareaAdapter = ({ input, meta, ...rest }) => {
  return <TextField multiline {...input} error={!!meta.error} {...rest} />;
};

export const Textarea = ({
  validate = null,
  required = false,
  type = 'text',
  mask = null,
  ...rest
}) => {
  const executableValidate = useMemo(() => {
    const validations = [];

    if (mask) {
      validations.push((value = '') => {
        const extracted = value.replace(/_/g, '');
        return extracted.length !== value.length ? 'Error' : undefined;
      });
    }

    if (required) {
      validations.push(validateRequired);
    }

    if (typeof validate === 'function') {
      validations.push(validate);
    }

    return composeValidations(validations);
  }, [mask, required, validate]);

  return (
    <Field
      {...rest}
      component={TextareaAdapter}
      mask={mask}
      required={required}
      type={type}
      validate={executableValidate}
    />
  );
};

TextareaAdapter.propTypes = TextareaAdapterPropTypes;
Textarea.propTypes = propTypes;
Textarea.defaultProps = defaultProps;
