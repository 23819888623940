import { createActions } from 'redux-actions';

export const {
  closeDemoMessageDialog,
  openDemoMessageDialog,
  startDemoMessageSaga,
} = createActions(
  {
    START_DEMO_MESSAGE_SAGA: () => {},

    OPEN_DEMO_MESSAGE_DIALOG: () => {},
    CLOSE_DEMO_MESSAGE_DIALOG: () => {},
  },
  {
    prefix: 'DemoMessageDialog',
  }
);
