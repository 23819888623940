import memoize from 'lodash/memoize';

export const removeColonsFromString = memoize((string) =>
  string.replace(/:/g, '')
);

export const reflect = (promise) =>
  promise.then(
    (value) => ({
      value: value,
      status: 'fulfilled',
    }),
    (error) => ({
      error: error,
      status: 'rejected',
    })
  );

export { default as DN } from './DN';
