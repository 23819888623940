import { handleActions } from 'redux-actions';

import {
  clearPaymentStatus,
  setLoading,
  setPaymentStatus,
} from './PaymentStatus.actions';

const data = handleActions(
  {
    [setPaymentStatus]: (_, { payload }) => payload,
    [clearPaymentStatus]: () => ({}),
  },
  {}
);

const loading = handleActions(
  {
    [setLoading]: (_, { payload }) => payload,
    [clearPaymentStatus]: () => false,
  },
  false
);

export const reducer = {
  data,
  loading,
};
