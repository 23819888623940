import React, { useCallback, useState } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';

import { Button } from '@crpt-ui/core';

import { Modal } from '../../../../common_components/Modal/Modal';
import { Separator } from '../../../../common_components/Separator/Separator';
import { withForm } from '../../../../common_components/Form/Form.utils';
import { MaterialCertSelect } from '../../../../common_components/Form/Fields/Select/CertificateSelect/MaterialCertSelect';
import { SignatureDescriptionView } from '../../../../common_components/SignatureDescription/SignatureDescription.view';
import { getUserInn } from '../../../../common_components/Auth/ducks/Auth.selectors';
import { translate } from '../../../Translate/Translate';

import {
  closeCertificateSelectionDialog,
  setCertificate,
} from './ducks/AddingUserDialog.actions';
import { getOpenCertificateSelectionDialog } from './ducks/AddingUserDialog.selectors';
import { useStyles } from './AddingUserDialog.styled';

const CertificateSelectionDialog = ({
  closeCertificateSelectionDialog,
  setCertificate,
  userInn,
  open,
}) => {
  const classes = useStyles();
  const [certificate, selectCertificate] = useState(null);

  const onClose = useCallback(() => {
    closeCertificateSelectionDialog();
    selectCertificate(null);
  }, [closeCertificateSelectionDialog]);

  const onSelectCertificate = useCallback((value) => {
    selectCertificate(value);
  }, []);

  const filterCertificates = useCallback(
    (certificate) => {
      return certificate.serialNumber === userInn;
    },
    [userInn]
  );

  const onSubmit = useCallback(() => {
    setCertificate(certificate);
    onClose();
  }, [certificate, setCertificate, onClose]);

  return (
    <Modal
      className={classes.certificateSelectionDialog}
      maxWidth="md"
      open={open}
      title={translate('Загрузка сертификата')}
      onClose={onClose}
    >
      <Box mb={2} mt={2}>
        <MaterialCertSelect
          autoFocus
          filterFn={filterCertificates}
          name="cert"
          onSelect={onSelectCertificate}
        />
      </Box>

      {Boolean(certificate) && (
        <Box ml={-4} mr={-4}>
          <SignatureDescriptionView certificate={certificate} />
        </Box>
      )}

      <Separator mt={3} />

      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              className="whiteColor"
              disabled={!certificate}
              onClick={onSubmit}
            >
              Загрузить
            </Button>
          </Grid>

          <Grid item>
            <Button variant="outlined" onClick={onClose}>
              Отменить
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

CertificateSelectionDialog.propTypes = {
  closeCertificateSelectionDialog: PropTypes.func.isRequired,
  setCertificate: PropTypes.func.isRequired,
  userInn: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  open: getOpenCertificateSelectionDialog(state),
  userInn: getUserInn(state),
});

const mapDispatchToProps = {
  closeCertificateSelectionDialog,
  setCertificate,
};

export const CertificateSelectionDialogConnected = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withForm()
)(CertificateSelectionDialog);
