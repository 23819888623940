import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Preloader } from '../Preloader/Preloader';
import { translate } from '../../components/Translate/Translate';

import { startAuthSaga } from './ducks/Auth.actions';

const Auth = ({ location, startAuthSaga }) => {
  useEffect(() => {
    startAuthSaga(location);
  }, [location, startAuthSaga]);

  return <Preloader isOpen text={translate('Загрузка данных профиля')} />;
};

Auth.propTypes = {
  startAuthSaga: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
};

const mapDispatchToProps = {
  startAuthSaga,
};

export const AuthConnected = connect(null, mapDispatchToProps)(Auth);
