import { validateStringRegExp } from './AcsUnloads.constants';

import type { UsersListResponse } from '@crpt/shared/services/UnloadTasksService.types';
import type { TestFunction } from 'yup';

export const usersListSelect = (data: UsersListResponse) =>
  data.map(({ user }) => ({
    value: user.id,
    label: user.fullName,
    additionalLabel: user.email,
  }));

export const testInns: TestFunction<string | undefined | null> = function (
  this,
  value
) {
  if (!value) {
    return true;
  }

  return value
    .split(',')
    .map((item) => item.trim())
    .every((item) => validateStringRegExp.inn.test(item));
};
