import { call, debounce, put, take } from 'redux-saga/effects';
import { get } from 'lodash';

import { Api } from '../../../common_components/Api/Api';

import {
  registrationStatusSubmit,
  setRegistrationDeclineReason,
  setRegistrationStatus,
} from './RegistrationStatus.actions';

function* handleSubmit(action) {
  const documentId = get(action, 'payload.documentId');

  const [success] = yield call(
    Api.request,
    {
      url: `/api/v3/facade/registration/check/${documentId}`,
    },
    {
      showBackendMessage: true,
      withoutDescriptionOfError: true,
    }
  );

  if (success) {
    const registrationStatus = get(
      success.data,
      'registrationRequestStatus',
      null
    );
    const registrationDeclineReason = get(success.data, 'declineReason', null);

    yield put(setRegistrationStatus(registrationStatus));
    yield put(setRegistrationDeclineReason(registrationDeclineReason));
  }
}

export const saga = function* watch() {
  yield take(
    registrationStatusSubmit,
    yield debounce(250, registrationStatusSubmit, handleSubmit)
  );
};
