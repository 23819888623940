import React from 'react';

import { withStyles, createStyles } from '@material-ui/core';

export const renderValue = (value, needEllipsis) => {
  if (needEllipsis) {
    return <Value>{value}</Value>;
  }
  return <div>{value}</div>;
};

const valueStyles = createStyles(() => ({
  root: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

export const Value = withStyles(valueStyles)(
  ({ classes, children, ...rest }) => (
    <div className={classes.root} {...rest}>
      {children}
    </div>
  )
);
