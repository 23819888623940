import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import { Typography, Pagination } from '@crpt-ui/core';
import Datatable from '@crpt-ui/datatable';

import { PreloaderWrapper } from '../../../common_components/PreloaderWrapper/PreloaderWrapper';
import { withContentWrapper } from '../../../common_components/hocs/withContentWrapper/withContentWrapper';

import { columnsTable } from './Production.constants';
import { ProductionEmpty } from './Production.styled';
import { ProductionModalConnected } from './ProductionModal/ProductionModal';
import { ProductionLineListItemType } from './ProductionType';
import { HeaderConnected } from './Header/Header';

import { ReactComponent as EmptyListIcon } from './EmptyListIcon.svg';

const ProductionViewComponent = ({
  onRowClick,
  productionLines,
  pagination,
  datatableActions,
  isLoading,
}) => (
  <Fragment>
    <ProductionModalConnected />

    <HeaderConnected />

    {productionLines.length === 0 && !isLoading ? (
      <ProductionEmpty>
        <EmptyListIcon />
        <Box mt={3}>
          <Typography variant="h4">
            У вас еще нет добавленных производственных площадок
          </Typography>
        </Box>
      </ProductionEmpty>
    ) : (
      <Box ml={-4} mr={-4}>
        <PreloaderWrapper isLoading={isLoading}>
          <Datatable
            actions={datatableActions}
            columns={columnsTable}
            data={productionLines}
            onRowClick={onRowClick}
          />
        </PreloaderWrapper>

        {productionLines.length > 0 && (
          <Box ml={2} mt={2}>
            <Pagination
              page={pagination.activePage}
              pagesCount={pagination.pagesCount}
              onPageChange={pagination.onPageChange}
            />
          </Box>
        )}
      </Box>
    )}
  </Fragment>
);

ProductionViewComponent.propTypes = {
  productionLines: PropTypes.arrayOf(ProductionLineListItemType).isRequired,
  onRowClick: PropTypes.func.isRequired,
  datatableActions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export const ProductionView = withContentWrapper()(ProductionViewComponent);
