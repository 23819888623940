import {
  Button,
  Dialog,
  LightTheme,
  Notification as CRPTNotification,
  Typography,
} from '@crpt-ui/core';
import { MuiThemeProvider, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { styles } from './Notification.styles';
import {
  BannersType,
  NotificationProps,
  NotificationType,
} from './Notification.types';
import { setNotificationShown } from './Notification.utils';
import { ReactComponent as ParkedPageIcon } from './parkedPageIcon.svg';

const Notification: React.FC<NotificationProps> = (props) => {
  const {
    title,
    notificationType,
    description,
    id,
    classes,
    bannerType,
    path,
  } = props;
  const [opened, setOpened] = useState(true);

  const closeNotification = () => {
    setOpened(false);
    setDisposableNotificationAsShown();
  };

  const setDisposableNotificationAsShown = () => {
    if (bannerType === BannersType.Disposable) {
      setNotificationShown(id);
    }
  };

  useEffect(() => {
    if (notificationType === NotificationType.ParkedPages) {
      setDisposableNotificationAsShown();
    }
  }, []);

  const setInitialState = () => {
    setOpened(true);
  };

  useEffect(() => {
    return setInitialState;
  }, [path]);

  return (
    <MuiThemeProvider theme={LightTheme}>
      {opened && notificationType === NotificationType.Banner && (
        <div className={classes.banner}>
          <CRPTNotification color="error" onClose={closeNotification}>
            <div
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          </CRPTNotification>
        </div>
      )}
      {notificationType === NotificationType.ParkedPages && (
        <div className={classes.parkedPageContainer}>
          <div>
            <ParkedPageIcon />
          </div>
          <Typography
            dangerouslySetInnerHTML={{
              __html: title,
            }}
            variant="h4"
          />
          <Typography
            className={classes.parkedPageDescription}
            dangerouslySetInnerHTML={{
              __html: description ?? '',
            }}
            variant="body1"
          />
        </div>
      )}
      {notificationType === NotificationType.Notification && (
        <Dialog onClose={closeNotification} open={opened}>
          <Typography
            dangerouslySetInnerHTML={{
              __html: title,
            }}
            variant="h4"
          />
          <Typography
            dangerouslySetInnerHTML={{
              __html: description ?? '',
            }}
            variant="body1"
          />
          <Button onClick={closeNotification}>Закрыть</Button>
        </Dialog>
      )}
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(Notification);
