import { Periodicity } from "../../constants";

export enum FieldIds {
  NAME = 'name',
  PERIODICITY = 'periodicity',
  PARTICIPANT_INN = 'participantInn',
  INCLUDE_GTIN = 'includeGtin',
  PACKAGE_TYPE = 'packageType',
  STATUS = 'status',
  EMISSION_PERIOD_START = 'emissionPeriodStart',
  EMISSION_PERIOD_END = 'emissionPeriodEnd',
  APPLIED_PERIOD_START = 'appliedPeriodStart',
  APPLIED_PERIOD_END = 'appliedPeriodEnd',
  PRODUCTION_PERIOD_START = 'productionPeriodStart',
  PRODUCTION_PERIOD_END = 'productionPeriodEnd',
  USERS = 'users',
}

export interface FormValues {
  meta: {
    [MetaFieldIds.IS_FOIV_OR_OPERATOR]: boolean;
  };
  [FieldIds.NAME]: string;
  [FieldIds.PERIODICITY]: Periodicity;
  [FieldIds.PACKAGE_TYPE]: string[];
  [FieldIds.STATUS]: string;
  [FieldIds.PARTICIPANT_INN]: string;
  [FieldIds.INCLUDE_GTIN]: string[];
  [FieldIds.EMISSION_PERIOD_START]: Date;
  [FieldIds.EMISSION_PERIOD_END]: Date;
  [FieldIds.APPLIED_PERIOD_START]: Date;
  [FieldIds.APPLIED_PERIOD_END]: Date;
  [FieldIds.PRODUCTION_PERIOD_START]: Date;
  [FieldIds.PRODUCTION_PERIOD_END]: Date;
  [FieldIds.USERS]?: number[];
}

export enum MetaFieldIds {
  IS_FOIV_OR_OPERATOR = 'isFoivOrOperator',
  TRANSLATION = 'translation',
}

export interface Cis {
  gtin: string;
  name: string;
}

export interface PackageTypeOption {
  value: string | number;
  label: string | number;
}