import React from 'react';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Button, Icon, Radio } from '@crpt-ui/core';

import { Input } from '../../../../common_components/Form/Input';
import { Select } from '../../../../common_components/Form/Select';
import {
  required,
  validateParticipantEdoId,
} from '../../../../common_components/Form/utils/validations';

const name = 'edoOperators';

export const OperatorsView = ({
  onCreate,
  onRemove,
  onMainOperatorChange,
  canRemove,
  formatParticipantEdoIdField,
  operatorsOptions,
  value,
  isEditing,
  isRegistered,
}) => (
  <Grid container direction="row" spacing={4}>
    {value.map((operator, index) => {
      const { isMainOperator } = operator;
      const disabled = !canRemove(operator) || !isEditing;

      return (
        <Grid container item alignItems="center" key={index} spacing={2}>
          <Grid item style={{ flexGrow: 1 }} xs={isRegistered ? 4 : 12}>
            <Select
              required
              disabled={disabled}
              label="Оператор ЭДО"
              name={`${name}.${index}.edoOperatorId`}
              options={operatorsOptions}
              validate={required}
            />
          </Grid>

          <Grid item style={{ flexGrow: 1 }} xs={isRegistered ? 4 : 6}>
            <Input
              required
              disabled={disabled}
              format={(inputValue) =>
                formatParticipantEdoIdField(inputValue, operator)
              }
              label="ID абонента"
              name={`${name}.${index}.participantEdoId`}
              validate={validateParticipantEdoId}
            />
          </Grid>

          <Grid item xs={isRegistered ? 4 : 6}>
            <Grid container item alignItems="center" spacing={2}>
              <Grid item>
                <Radio
                  checked={isMainOperator}
                  disabled={!isEditing}
                  label="Основной оператор ЭДО"
                  onChange={(_event, checked) =>
                    onMainOperatorChange(index, checked)
                  }
                />
              </Grid>

              {canRemove(operator) && isEditing ? (
                <Grid item>
                  <Button
                    color="error"
                    icon={<Icon name="trash" />}
                    variant="outlined"
                    onClick={() => onRemove(index)}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      );
    })}

    <Grid container item>
      {isEditing && (
        <Button className="whiteColor" onClick={onCreate}>
          Добавить
        </Button>
      )}
    </Grid>
  </Grid>
);

OperatorsView.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onMainOperatorChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  canRemove: PropTypes.func.isRequired,
  formatParticipantEdoIdField: PropTypes.func.isRequired,
  operators: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
};
