import { createSelector } from 'reselect';

import { NotificationOperatorListState } from '../../ducks/NotificationOperatorDocumentsList.selectors';

export const DocumentTemplatesDialogState = createSelector(
  [NotificationOperatorListState],
  (state) => state.DocumentTemplatesDialog
);

export const getOpen = createSelector(
  [DocumentTemplatesDialogState],
  (state) => state.open
);
