import React from 'react';

import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogContentProps as MuiDialogContentProps,
  DialogContent,
  Box,
} from '@mui/material';

import { Button, Icon } from '@ibox/ui';

interface DialogExtraProps extends MuiDialogProps {
  header?: React.ReactNode;
  withCloseIcon?: boolean;
  DialogContentProps?: MuiDialogContentProps;
}

export const Dialog: React.FC<DialogExtraProps> = ({
  children,
  fullWidth = true,
  scroll = 'body',
  transitionDuration = 0,
  onClose,
  withCloseIcon,
  header,
  DialogContentProps,
  ...rest
}) => (
  <MuiDialog
    fullWidth={fullWidth}
    scroll={scroll}
    transitionDuration={transitionDuration}
    onClose={onClose}
    {...rest}
  >
    {header || withCloseIcon ? (
      <Box pt={4} px={4}>
        {header ? <Box pr={withCloseIcon ? 4 : 0}>{header}</Box> : null}
        {withCloseIcon ? (
          <Box position="absolute" right={32} top={32}>
            <Button size="small" variant="text" onClick={onClose}>
              <Icon name="Close" />
            </Button>
          </Box>
        ) : null}
      </Box>
    ) : null}
    <DialogContent
      {...DialogContentProps}
      style={{ boxSizing: 'border-box', padding: '32px' }}
    >
      {children}
    </DialogContent>
  </MuiDialog>
);
