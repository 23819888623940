import React, { Fragment, useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button, LanguageSwitcher, useEvent } from '@ibox/ui';

import { StripeLayout } from '../../common_components/Layouts';
import { Form } from '../../common_components/Form/form/form';
import { LanguageWrap } from '../../common_components/LanguageMenu/LanguageWrap';
import { getCookie, setLanguage } from '../../utils/cookieHelpers';
import type { Language } from '../../helpers/index';

import css from './index.module.scss';

type LoginFormKepViewProps = {
  onSubmit: (value: unknown) => void;
  errors: unknown;
  isLoading: boolean;
  nidKeys: string | undefined;
};

export const LoginFormKepView = ({
  onSubmit,
  isLoading,
  nidKeys,
}: LoginFormKepViewProps) => {
  const { t, i18n } = useTranslation();

  const DefaultLng: Language = (getCookie('language') as Language) || 'ru';

  const [lang, setLang] = useState<Language>(DefaultLng);

  const languages: Language[] = ['ru', 'hy'];

  const changeLanguage = useEvent((language: Language) => {
    i18n.changeLanguage(language.toUpperCase());
    setLang(language);
    setLanguage(language);
    localStorage.setItem('language', language);
  });

  useEffect(() => {
    const language = (getCookie('language') || 'ru') as Language;

    changeLanguage(language);
  }, [changeLanguage]);

  const onLangSelect = useEvent((language: Language) => {
    changeLanguage(language);
  });

  return (
    <Fragment>
      <StripeLayout enableMobileLinks title={t('Вход в систему')}>
        <Form onSubmit={onSubmit}>
          <Box mt={4}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item>
                <Button
                  className="whiteColor mt15"
                  disabled={isLoading || !nidKeys}
                  type="submit"
                >
                  {t('Войти')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      </StripeLayout>
      <LanguageWrap>
        <LanguageSwitcher
          className={css.langSwitcher}
          languages={languages}
          selected={lang}
          onChange={onLangSelect}
        />
      </LanguageWrap>
    </Fragment>
  );
};
