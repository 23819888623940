import { createActions } from 'redux-actions';

export const {
  fetchServiceDeskDocument,
  setDocumentContent,
  setDocumentInfo,
  setLoading,
  clearServiceDeskDocument,
} = createActions(
  {
    FETCH_SERVICE_DESK_DOCUMENT: (payload) => payload,

    SET_DOCUMENT_CONTENT: (payload) => payload,
    SET_DOCUMENT_INFO: (payload) => payload,
    SET_LOADING: (payload) => payload,

    CLEAR_SERVICE_DESK_DOCUMENT: () => {},
  },
  {
    prefix: 'SERVICE_DESK_DOCUMENT',
  }
);
