import { handleActions } from 'redux-actions';

import {
  onDataLoaded,
  onUsersLoaded,
  clearUsers,
  fetchData,
  fetchGetUsers,
  fetchAddUser,
  setAddedUser,
  clearAddedUser,
  onUnmount,
} from './RegistryOIVPage.actions';

const result = handleActions(
  {
    [onDataLoaded]: (_, { payload }) => payload,
  },
  null
);
const users = handleActions(
  {
    [onUsersLoaded]: (state, { payload }) => {
      return [...state, ...(payload.items || [])];
    },
    [clearUsers]: () => [],
  },
  []
);
const loaded = handleActions(
  {
    [onDataLoaded]: () => true,
    [fetchData]: () => false,
  },
  false
);
const usersLoaded = handleActions(
  {
    [onUsersLoaded]: () => true,
    [fetchGetUsers]: () => false,
  },
  false
);
const totalUsers = handleActions(
  {
    [onUsersLoaded]: (_, { payload }) => payload.count,
    [onUnmount]: () => 0,
  },
  0
);
const pageUsers = handleActions(
  {
    [onUsersLoaded]: (_, { payload }) => payload.page,
    [onUnmount]: () => 1,
  },
  1
);
const addedUser = handleActions(
  {
    [fetchAddUser]: () => false,
    [clearAddedUser]: () => false,
    [setAddedUser]: () => true,
  },
  false
);

const last = handleActions(
  {
    [onDataLoaded]: (_, { payload }) => payload.last,
    [onUnmount]: () => false,
  },
  false
);

export const reducer = {
  result,
  users,
  loaded,
  usersLoaded,
  totalUsers,
  pageUsers,
  addedUser,
  last,
};
// const result = handleAction(setProfileData, (state, { payload }) => payload, {});

// const { reducer, actions } = createSlice({
//   initialState: initialState,
//   name: 'RegistryOIVPage',
//   reducers: {
//     onDataLoaded: (state, action) => {
//       state.result = action.payload;
//       state.loaded = true;
//     },
//     onUsersLoaded: (state, action) => {
//       state.users = action.payload.items;
//       state.usersLoaded = true;
//       state.totalUsers = action.payload.count;
//       state.pageUsers = action.payload.page;
//     },
//     fetchData: (state) => {
//       state.loaded = false;
//     },
//     fetchGetUsers: (state) => {
//       state.usersLoaded = false;
//     },
//     fetchAddUser: (state) => {
//       state.addedUser = false;
//     },
//     setAddedUser: (state/*, action */) => {
//       state.addedUser = true;
//       // Добавить добавление в стор нового юзера
//     },
//     clearAddedUser: (state) => {
//       state.addedUser = false;
//     },
//     onUnmount: (state) => {
//       state.result = null;
//       state.loaded = false;
//       state.totalUsers = 0;
//       state.pageUsers = 1;
//     }
//   },
// });
//
// export {actions , reducer};
//
// export default reducer;
