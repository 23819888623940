import { createActions } from 'redux-actions';

export const { sendIncident } = createActions(
  {
    SEND_INCIDENT: (payload) => payload,
  },
  {
    prefix: 'SUPPORT_MODAL',
  }
);
