import React from 'react';

import { withStyles } from '@material-ui/core';
import type { WithStyles } from '@material-ui/core';
import type { LinkProps } from '@crpt-ui/core';
import { Icon, Link, Tooltip, Typography } from '@crpt-ui/core';
import { styles } from './HelpCard.styles';
import { useTextWrapState } from '../../hooks/useTextWrapState';

export type DocumentLinkProps = {
  name: string;
} & LinkProps &
  WithStyles<typeof styles>;

const DocumentLink: React.FC<DocumentLinkProps> = ({
  name,
  classes,
  ...rest
}) => {
  const {
    state: isTooltipOpen,
    onCheckWrap: onMouseEnterHandle,
    off: onMouseLeaveHandle,
  } = useTextWrapState('nowrap');

  return (
    <Link
      className={classes.link}
      component="button"
      icon={<Icon name="Attachment" />}
      {...rest}
    >
      <Typography
        noWrap
        onMouseEnter={onMouseEnterHandle}
        onMouseLeave={onMouseLeaveHandle}
        variant="inherit"
      >
        <Tooltip open={isTooltipOpen} placement="bottom-start" title={name}>
          <span>{name}</span>
        </Tooltip>
      </Typography>
    </Link>
  );
};

export default withStyles(styles)(DocumentLink);
