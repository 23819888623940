import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  icon: {
    '& svg': {
      fontSize: 150,
      color: '#60e84a',
    },
  },
  errorIcon: {
    '& svg': {
      fontSize: 150,
      color: '#b8b8b8',
    },
  },
  title: {
    margin: 0,
  },
  text: {
    marginTop: 5,
  },
  link: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 700,
  },
});
