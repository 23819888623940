import React from 'react';

import PropTypes from 'prop-types';

import { Icon } from '@crpt-ui/core';

import { Wrap, IconWrap, TextWrap, Text } from './MaterialCertSelect.styled';

export const CertMenuItem = ({
  fullName,
  position,
  validDate,
  company,
  valid,
}) => (
  <Wrap>
    <IconWrap>
      {valid ? (
        <Icon
          name="save"
          style={{
            color: '#60e84a',
          }}
        />
      ) : (
        <Icon
          name="cancel"
          style={{
            color: '#ff4b4b',
          }}
        />
      )}
    </IconWrap>
    <TextWrap>
      <Text setMargin="0 0 5px 0">{company}</Text>
      <Text isDisabled={!valid} setMargin="0 0 8px 0">
        {validDate}
      </Text>
      <Text setFotnSize="16px">
        {fullName} · {position}
      </Text>
    </TextWrap>
  </Wrap>
);

CertMenuItem.propTypes = {
  name: PropTypes.string,
  position: PropTypes.string,
  date: PropTypes.string,
  source: PropTypes.string,
  disabled: PropTypes.bool,
};
