import { handleActions } from 'redux-actions';

import { setUsersList, setChangeStatus } from './UserList.actions';

type DomainObjectItem = {
  description: string;
  entries: string[];
  id: number;
  name: string;
};

type AssignedRolesItem = {
  create_date: number;
  description: string;
  id: number;
  name: string;
  domain_objects: DomainObjectItem[];
};

export type UserListItem = {
  assignedRoles: AssignedRolesItem[];
  certificates: unknown[];
  user: {
    certificates: unknown[];
    email: string;
    fullName: string;
    id: number;
    phones: string[];
    status: string;
    statusDate: number;
    physicalPerson: {
      fio: string;
      id: number;
      socialCardId: string;
    };
  };
};

const data = handleActions(
  {
    [setUsersList]: (_state, { payload }) => payload || [],
    [setChangeStatus]: (state, { payload }) => {
      return state.map((el) =>
        el.user.id === payload.id
          ? {
              ...el,
              user: {
                ...el.user,
                status: payload.status,
              },
            }
          : el
      );
    },
  },
  []
);

export const reducer = {
  data,
};
