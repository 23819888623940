import { createActions } from 'redux-actions';

/**
 * Actions for documents page
 */
export const {
  startDocumentSaga,
  setDocumentInfo,
  clearDocumentInfo,
  setLoading,
  setUseHub,
  setEventsData,
} = createActions(
  {
    /**
     * Running the main documents saga
     * @param {string} payload - Document id
     * @returns {DOCUMENT.START_DOCUMENT_SAGA.props|*}
     */
    START_DOCUMENT_SAGA: (payload) => payload,

    /**
     * Set document info
     * @param {DocumentInfoType} payload - Document info data
     * @returns {DOCUMENT.SET_DOCUMENT_INFO.props|*}
     */
    SET_DOCUMENT_INFO: (payload) => payload,

    /**
     * Clears all information about the document
     */
    CLEAR_DOCUMENT_INFO: () => {},

    /**
     * Set is there a request for backend now
     * @param {boolean} payload
     * @returns {DOCUMENT.SET_LOADING_BUTTON.props|*}
     */
    SET_LOADING: (payload) => payload,

    SET_USE_HUB: (payload) => payload,
    SET_EVENTS_DATA: (payload) => payload,
  },
  {
    prefix: 'DOCUMENT',
  }
);
