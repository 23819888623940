import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import { Input } from '../../../../../common_components/Form/Input';
import { Select } from '../../../../../common_components/Form/Select';
import { DatePickerInput } from '../../../../../common_components/Form/DatePickerInput';
import {
  CONTENT_CODE_KIND_VALUES,
  CONTENT_CODE_VALUES,
} from '../SignClosingDocument.constants';

export const AcceptanceContentCode = () => (
  <Grid
    container
    direction="column"
    spacing={2}
    style={{
      maxWidth: '710px',
    }}
  >
    <Grid item>
      <Typography variant="h5">Содержание операции</Typography>
    </Grid>
    <Grid item>
      <Select
        required
        label="Итог приемки"
        name="acceptance.content_code.code"
        options={CONTENT_CODE_VALUES}
      />
    </Grid>
    <Grid item>
      <Input
        label="Наименование документа о расхождениях"
        name="acceptance.content_code.name"
      />
    </Grid>
    <Grid item>
      <Select
        label="Вид документа о расхождениях"
        name="acceptance.content_code.kind"
        options={CONTENT_CODE_KIND_VALUES}
      />
    </Grid>
    <Grid item>
      <Input
        label="Номер документа о расхождениях"
        name="acceptance.content_code.number"
      />
    </Grid>
    <Grid item>
      <DatePickerInput
        label="Дата документа о расхождениях"
        name="acceptance.content_code.date"
      />
    </Grid>
    <Grid item>
      <Input
        label="Идентификатор файла док. о расхождениях"
        name="acceptance.content_code.id"
      />
    </Grid>
  </Grid>
);
