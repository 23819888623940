import * as _ from 'lodash';

import { PRODUCT_GROUP } from '../../constants/ProductGroup';
import {
  UserStatusEnum,
  UserStatusNameEnum,
  participantTypes,
} from '../../constants/index';
import { uotStatuses } from '../ParticipantDetails/utils';

import { tabs } from './Profile.constants';

export const isPreRegistered = _.memoize((status) => {
  return _.chain([
    UserStatusEnum.PRE_REGISTERED_ISSUER,
    UserStatusEnum.PRE_REGISTERED_TRADER,
    UserStatusEnum.PRE_REGISTERED,
  ])
    .includes(status)
    .value();
});

export const getStatusName = _.memoize((status) =>
  _.chain(UserStatusNameEnum).get(status, '').value()
);

export const getTabs = (status, productGroupsAndRoles) => {
  const prepareTabs = getPrepareTabsList(tabs, productGroupsAndRoles);

  return prepareTabs.map((tab, index) => ({
    label: isPreRegistered(status)
      ? `Шаг ${index + 1}. ${tab.title}`
      : tab.title,
    value: tab.href,
  }));
};

export const getProgress = () =>
  _.defaultTo(JSON.parse(localStorage.getItem('progress')), []);

export const getOverallProgress = () => {
  const progress = getProgress();

  const requiredTabs = tabs.filter((tab) => tab.required);
  let filledSteps = 0;

  for (const tab of requiredTabs) {
    if (tab.required && progress.includes(tab.href)) {
      filledSteps++;
    }
  }

  return _.round((filledSteps / requiredTabs.length) * 100);
};

export const adjustProgress = () => {
  const progress = getProgress();
  const {
    location: { pathname },
  } = window;

  localStorage.setItem(
    'progress',
    JSON.stringify(_.uniq([...progress, pathname]))
  );
};

export const parseProductGroupsAndRoles = (productGroupsAndRoles = []) => {
  if (_.isEmpty(productGroupsAndRoles)) {
    return [{}];
  }

  return productGroupsAndRoles.map((productGroupAndRole) => ({
    ...productGroupAndRole,
    license: productGroupAndRole.license || '',
    status: productGroupAndRole.status || '-',
    statusName: uotStatuses[productGroupAndRole.status] || '-',
    types: _.without(
      productGroupAndRole.types,
      participantTypes.TRADE_PARTICIPANT,
      participantTypes.EMITENT
    ),
  }));
};

/**
 * Returns true if the product group is TOBACCO and the member type is IMPORTER or PRODUCER
 *
 * @param productGroupsAndRoles
 * @returns {boolean}
 */
export const isShowProductionTab = (productGroupsAndRoles) =>
  productGroupsAndRoles.find(
    (item) =>
      [PRODUCT_GROUP.TOBACCO.code, PRODUCT_GROUP.OTP.code].includes(
        item.code
      ) &&
      Boolean(
        item.types.find((type) =>
          [participantTypes.PRODUCER, participantTypes.IMPORTER].includes(type)
        )
      )
  );

/**
 * Returns a prepared list of tabs
 *
 * @param tabsList
 * @param productGroupsAndRoles
 * @returns {*}
 */
const getPrepareTabsList = (tabsList, productGroupsAndRoles) => {
  if (isShowProductionTab(productGroupsAndRoles)) {
    return tabsList;
  }

  return tabsList.filter((item) => item.href !== '/profile/production');
};
