import React, { useState } from 'react';

import PropTypes from 'prop-types';
import {
  Select as MaterialSelect,
  FormControl,
  MenuItem,
  InputLabel,
  FilledInput,
} from '@material-ui/core';

import { Icon } from '@crpt-ui/core';

import { WrapIcon, WrapIcons } from './Select.style';

export const Select = ({
  onChange,
  label,
  name,
  fields,
  defaultValue,
  children,
  options,
  menuProps,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event) => {
    const value = event.target.value;
    onChange(value);
    setValue(value);
  };

  return (
    <FormControl fullWidth={true}>
      <InputLabel
        style={{ color: '#868686', zIndex: 9, paddingLeft: 15, marginTop: -6 }}
      >
        {label}
      </InputLabel>
      <MaterialSelect
        IconComponent={() => (
          <WrapIcons>
            {value && (
              <WrapIcon>
                <Icon fill="#434343" name="Chevron" />
              </WrapIcon>
            )}
          </WrapIcons>
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          ...menuProps,
        }}
        input={<FilledInput name={name} />}
        renderValue={(selected) => (options ? options(selected) : selected)}
        value={value}
        onChange={handleChange}
        {...props}
      >
        {children
          ? children
          : fields.map(({ value, text, ...props }) => (
              <MenuItem key={value} value={value} {...props}>
                {text}
              </MenuItem>
            ))}
      </MaterialSelect>
    </FormControl>
  );
};

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  menuProps: PropTypes.object,
  options: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  fields: PropTypes.array,
};

Select.defaultProps = {
  onChange: () => {},
  defaultValue: '',
  name: 'select',
  label: '',
};
