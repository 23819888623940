import { call, put, select, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash';

import { Api } from '../../../../common_components/Api/Api';

import {
  fetchIncidents,
  setIncidentItems,
  setIncidentTotal,
  setPage,
} from './SupportList.actions';
import { getSupportListParams } from './SupportList.selectors';

function* fetchIncidentsSaga() {
  const params = yield select(getSupportListParams);

  const requestPayload = {
    params,
    url: '/crm-api/incidents',
  };

  const [success] = yield call(Api.request, requestPayload);

  if (success) {
    const items = success.data.response
      .map((row) => ({
        __form_id: get(row, 'id'),
        ...row,
      }))
      .reverse();

    yield put(setIncidentItems(items));
    yield put(setIncidentTotal(success.data.total));
  }
}

export const saga = function* watch() {
  yield takeEvery([setPage, fetchIncidents], fetchIncidentsSaga);
};
