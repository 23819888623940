import { call, put, select, takeEvery } from 'redux-saga/effects';

import {
  closeSignModal,
  openSignModal,
} from '../../../../../common_components/DocumentSigningModal/ducks/DocumentSigningModal.actions';
import { signAgreementDocumentSaga } from '../../ducks/AgreementDocument.saga';

import {
  openSignModalById,
  setDocumentId,
  signAgreementDocument,
  successfullySignedAgreementDocuments,
} from './SignModal.actions';
import { getDocumentIdModalSign } from './SignModal.selectors';

function* signAgreementDocumentProcess() {
  const documentId = yield select(getDocumentIdModalSign);
  const signData = yield call(signAgreementDocumentSaga, {
    documentId,
    preloading: false,
  });

  if (signData) {
    yield put(closeSignModal());
    yield put(successfullySignedAgreementDocuments());
  }
}

function* openSignModalByIdSaga({ payload }) {
  yield put(setDocumentId(payload));
  yield put(openSignModal());
}

export const saga = function* watch() {
  yield takeEvery(signAgreementDocument, signAgreementDocumentProcess);
  yield takeEvery(openSignModalById, openSignModalByIdSaga);
};
