import React, { useCallback, useEffect, useMemo } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { HistoryType } from '../../../constants/PropTypes';
import { eventsService } from '../../../services';
import { MatchType } from '../../../common_components/Document/DocumentType';

import { startDocumentSaga } from './ducks/ClosingDocument.actions';
import {
  getLoading,
  getSignLink,
  getViewLink,
  isShowSignTab,
} from './ducks/ClosingDocument.selectors';
import { DocumentView } from './ClosingDocument.view';
import { useClosingDocumentRoutes } from './ClosingDocument.routes';

const ClosingDocument = ({
  startDocumentSaga,
  isShowSignTab,
  signLink,
  viewLink,
  history,
  match,
  isLoading,
}) => {
  const documentId = useMemo(() => match.params.id, [match.params.id]);
  const currentUrl = useMemo(() => match.url, [match.url]);
  const routes = useClosingDocumentRoutes();

  /**
   * Fetching information about the document.
   * And start watch the eventsService.
   *
   * After unmount the component, the document information is cleared,
   * and stop the eventsService.
   */
  useEffect(() => {
    startDocumentSaga({
      id: documentId,
    });
    eventsService.watch();

    return () => {
      eventsService.unwatch();
    };
  }, [startDocumentSaga, documentId]);

  /**
   * Location change handler for tab link
   */
  const onLocationChange = useCallback(
    (_, path) => {
      return history.push(path);
    },
    [history]
  );

  const tabs = useMemo(
    () => [
      {
        label: 'Общая информация',
        value: viewLink,
      },
      {
        label: 'Подпись',
        value: signLink,
      },
    ],
    [viewLink, signLink]
  );

  return React.createElement(DocumentView, {
    routes,
    tabs,
    onLocationChange,
    currentUrl,
    isShowSignTab,
    isLoading,
  });
};

ClosingDocument.propTypes = {
  match: MatchType,
  history: HistoryType,
  startDocumentSaga: PropTypes.func.isRequired,
  signLink: PropTypes.string.isRequired,
  viewLink: PropTypes.string.isRequired,
  isShowSignTab: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  startDocumentSaga,
};

const mapStateToProps = (state) => ({
  isLoading: getLoading(state),
  isShowSignTab: isShowSignTab(state),
  signLink: getSignLink(state),
  viewLink: getViewLink(state),
});

export const ClosingDocumentConnected = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClosingDocument)
);
