import React, { useMemo } from 'react';

import { connect } from 'react-redux';

import { Status } from '@crpt-ui/core';

import { getPrepareDocumentInfo } from '../ClosingDocument.utils';
import { getDocumentInfo } from '../ducks/ClosingDocument.selectors';

export const ClosingDocumentStatus = ({ documentInfo }: any) => {
  const statusInfo: any = useMemo(
    () => getPrepareDocumentInfo(documentInfo),
    [documentInfo]
  );

  return (
    <Status
      color={statusInfo.color}
      icon={statusInfo.statusIcon}
      label={statusInfo.status}
    />
  );
};

const mapStateToProps = (state: any) => ({
  documentInfo: getDocumentInfo(state),
});

export const ClosingDocumentStatusConnected = connect(mapStateToProps)(
  ClosingDocumentStatus
);
