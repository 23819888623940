import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getActive, getContent } from './ducks/Sidebar.selectors';
import { close } from './ducks/Sidebar.actions';

const propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.element,
};

const defaultProps = {
  children: undefined,
};

const useStyles = makeStyles({
  paper: {
    width: '490px',
  },
});

const SidebarComp = (props) => {
  const { active, children, onClose } = props;
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      classes={{
        paper: classes.paper,
      }}
      open={active}
      onClose={onClose}
    >
      {children}
    </Drawer>
  );
};

SidebarComp.propTypes = propTypes;
SidebarComp.defaultProps = defaultProps;

const mapState = (state) => ({
  children: getContent(state),
  active: getActive(state),
});

const mapDispatch = (dispatch) => ({
  onClose: () => dispatch(close()),
});

export const Sidebar = compose(connect(mapState, mapDispatch))(SidebarComp);
