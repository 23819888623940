import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      overflow: 'auto',
      maxHeight: 85,
      cursor: 'default',
      padding: '0 8px',
      marginRight: -16,
    },
    item: {
      lineHeight: '24px',
      fontWeight: 600,
    },
  })
);
