import { makeStyles } from '@material-ui/core';

export const useLinearProgressClasses = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    background: theme.palette.background.default,
    height: 58,
    color: theme.palette.error.main,
    inset: 0,
  },
  bar: {
    background: '#f7f8bb',
  },
}));

export const useClasses = makeStyles((theme) => ({
  inputWrapper: {
    position: 'relative',
    border: `1px solid ${theme.palette.background.default}`,
    height: 48,
    boxSizing: 'border-box',
    borderRadius: 4,
    background: theme.palette.background.default,
    boxShadow: 'none',
    overflow: 'hidden',
    '&.uploading': {
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
    },
  },
  linearProgressWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  uploadButton: {
    margin: -1,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  closeButton: {
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    zIndex: 1,
  },
  input: {
    padding: `0 0 0 ${theme.spacing(2)}px`,
    height: 46,
    fontSize: 14,
    fontWeight: 600,
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRight: 0,
    borderRadius: 4,
    '&.uploading': {
      border: 0,
    },
  },
  errorWrapper: {
    background: theme.palette.background.default,
    borderRadius: 6,
    overflow: 'hidden',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    margin: `${theme.spacing(2)}px 0`,
  },
  tableWrapper: {
    borderLeft: `1px solid ${theme.palette.grey[400]}`,
  },
}));
