import React from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CommonDialog } from '@ibox/ui';

import { fetchBlockUser } from '../_Profile/UserList/ducks/UserList.actions';
import { blockUser } from '../ParticipantDetails/components/users/ducks/Users.actions';
import { translate } from '../Translate/Translate';

import { closeBlockUserDialog } from './ducks/BlockUserDialog.actions';
import { getOpenBlockUserDialog } from './ducks/BlockUserDialog.selectors';

const BlockUserDialogComp = (props) => {
  const { closeBlockUserDialog, userData, isLoading, mode } = props;
  const { fetchBlockUserProfile, blockUser } = props;

  const { t } = useTranslation();

  const onSubmit = () => {
    if (mode === 'profile') {
      fetchBlockUserProfile({
        user_id: userData.id,
        status: 'BLOCKED',
      });
    }
    if (mode === 'uotView') {
      blockUser({
        user_id: userData.id,
        status: 'BLOCKED',
      });
    }
  };

  return (
    <CommonDialog
      invalid={isLoading}
      labelCancel={t('Отменить')}
      labelSubmit={t('Заблокировать')}
      open={!!userData}
      title={translate('Подтверждение блокировки учетной записи пользователя')}
      onClose={closeBlockUserDialog}
      onSubmit={onSubmit}
    >
      <Grid container>
        {translate(
          `При блокировке учетной записи доступ к системе для пользователя`
        )}{' '}
        {userData?.fullName} {translate('будет закрыт')}.{' '}
        {translate('Заблокировать')}?
      </Grid>
    </CommonDialog>
  );
};

BlockUserDialogComp.propTypes = {
  closeBlockUserDialog: PropTypes.func.isRequired,
  userData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  userData: getOpenBlockUserDialog(state),
});

const mapDispatchToProps = {
  closeBlockUserDialog,
  fetchBlockUserProfile: fetchBlockUser,
  blockUser,
};

export const BlockUserDialog = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(BlockUserDialogComp);
