export default () => {
  window['cm'] =
    window['cm'] ||
    function (...args) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (window['cm'].q = window['cm'].q || []).push(args);
    };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window['cm'].l = 1 * new Date();

  if (process.env.REACT_APP_METRICS_DISABLED) {
    return;
  }

  if (process.env.REACT_APP_METRICS_URL) {
    window.cm('set', 'app', process.env.REACT_APP_NAME);
    window.cm('set', 'appVersion', process.env.REACT_APP_VERSION);

    const script = document.createElement('script');
    const el = document.body.getElementsByTagName('script')[0];
    script.async = true;
    script.src = `${process.env.REACT_APP_METRICS_URL}/static/js/metrics.js`;
    el.parentNode?.insertBefore(script, el);
  }
};
