import PropTypes from 'prop-types';

export const ProductionLineListItemType = PropTypes.shape({
  productionAddress: PropTypes.string,
  productionCountryName: PropTypes.string,
  productionCountryNumericCode: PropTypes.string,
  productionKpp: PropTypes.string,
  productionLines: PropTypes.arrayOf(PropTypes.string),
  productionPlaceName: PropTypes.string,
});

export const ProductionLineType = PropTypes.shape({
  last: PropTypes.boolean,
  result: PropTypes.arrayOf(ProductionLineListItemType),
  total: PropTypes.number,
});

export const CountriesType = {
  label: PropTypes.string,
  value: PropTypes.string,
};
