import { call, cancel, put, select, takeEvery } from 'redux-saga/effects';

import { toast } from '@crpt/shared/toast';

import {
  documentStatuses,
  HttpMethodEnum,
} from '../../../../../constants/index';
import { Api } from '../../../../../common_components/Api/Api';
import { startDocumentSaga } from '../../ducks/ClosingDocument.actions';
import { getDocumentId } from '../../ducks/ClosingDocument.selectors';
import {
  getEventContent,
  sendEventSignature,
} from '../../SignClosingDocument/ducks/SignClosingDocument.saga';
import { signContent } from '../../../../../common_components/Cades/Cades.saga';
import { fetchEventsData } from '../../Events/ducks/Event.actions';

import { getData } from './EventModal.selectors';
import {
  closeModal,
  sendCancellation,
  setCancellationLoading,
  setLoading,
  submit,
} from './EventModal.actions';

function* signEventContent({ eventId, documentId }) {
  const eventData = yield call(getEventContent, {
    documentId,
    eventId,
  });
  const signature = yield call(signContent, eventData);

  return yield call(sendEventSignature, {
    documentId,
    eventId,
    signature,
  });
}

function* successSaga() {
  const documentId = yield select(getDocumentId);
  const { successMessage } = yield select(getData);

  yield put(closeModal());
  yield put(
    startDocumentSaga({
      id: documentId,
    })
  );
  yield put(fetchEventsData());

  if (successMessage) {
    yield call(toast.success, successMessage);
  }

  yield put(setLoading(false));
}

/**
 * @param {{comment: string}} payload - Form values and document id
 */
function* submitSaga({ payload }) {
  yield put(setLoading(true));

  const documentId = yield select(getDocumentId);
  const { status } = yield select(getData);
  const data = {
    status,
    content: {
      comment: payload.comment ?? '',
    },
  };

  const [event] = yield call(
    Api.request,
    {
      url: `/edo-api/incoming-documents/${documentId}/events`,
      method: HttpMethodEnum.POST,
      data,
    },
    {
      preloading: false,
    }
  );

  if (
    [
      documentStatuses.rejected,
      documentStatuses.revocationExpected,
      documentStatuses.revocateDenied,
      documentStatuses.clarificationRequired,
    ].includes(status) &&
    event
  ) {
    const eventId = event.data.id;

    const eventSignature = yield call(signEventContent, {
      eventId,
      documentId,
    });

    if (eventSignature) {
      yield successSaga();
      yield cancel();
    }
  }

  if (event) {
    yield successSaga();
  }
}

/**
 * Аннулирование документа
 **/
function* sendCancellationSaga() {
  yield put(setCancellationLoading(true));

  const documentId = yield select(getDocumentId);

  const [event] = yield call(Api.request, {
    url: `/edo-api/incoming-documents/${documentId}/events/114001`,
  });

  if (event) {
    const eventId = event.data.items.sort((a, b) => {
      return a.created_at - b.created_at;
    })[0].key.event_id;

    const eventSignature = yield call(signEventContent, {
      eventId,
      documentId,
    });

    if (eventSignature) {
      yield call(toast.success, 'Документ аннулирован');
      yield put(
        startDocumentSaga({
          id: documentId,
        })
      );
    }
  }

  yield put(setCancellationLoading(false));
}

export const saga = function* watch() {
  yield takeEvery(submit, submitSaga);
  yield takeEvery(sendCancellation, sendCancellationSaga);
};
