import handleAction from 'redux-actions/es/handleAction';

import { setRequisitesData, setEditing } from './Requisites.actions';

const data = handleAction(
  setRequisitesData,
  (state, { payload }) => ({ ...state, ...payload }),
  {}
);

const editing = handleAction(setEditing, (_, { payload }) => payload, false);

export const reducer = {
  data,
  editing,
};
