import { isString } from 'lodash';

import {
  documentStatuses,
  documentTypes,
  eventTypes,
} from '../../../../constants';

import {
  clarificationEventIds,
  rejectedEventIds,
  rejectedStatusIds,
  revokeEventIds,
  revokeStatuses,
} from './Events.constants';

type NotificationItem = {
  name: string;
  date: string;
  type: number;
  comment: string;
};

/**
 * Проверка, что документ является неформализованным
 */
export const isDocumentTypeNF = (type: number): boolean => {
  const checkType = isString(type) ? documentTypes[String(type)] : type;

  return checkType < 0;
};

/**
 * Проверка на статус аннулирования документа
 */
const isRevocationStatus = (status: number): boolean =>
  [
    documentStatuses.revocationRequest,
    documentStatuses.revocated,
    documentStatuses.revocationExpected,
  ].includes(status);

export const getNotificationCaption = ({
  documentType,
  documentStatus,
  item,
}: {
  documentType: number;
  documentStatus: number;
  item: NotificationItem;
}): string => {
  let result = '';

  if (clarificationEventIds.includes(item.type)) {
    if (isDocumentTypeNF(documentType) && !isRevocationStatus(documentStatus)) {
      result = 'отказал в подписи (отклонил документ)';
    } else {
      result = 'отправил запрос на уточнение документа';
    }
  } else if (item.type === eventTypes.DP_PRANNULDENY) {
    result = 'отказал в аннулировании документа';
  } else if (item.type === eventTypes.DP_PRANNUL) {
    if (revokeStatuses.includes(documentStatus)) {
      result = 'отправил запрос на аннулирование документа';
    } else if (documentStatus === documentStatuses.revocated) {
      result = 'аннулировал документ';
    }
  } else if (
    documentType === documentTypes.nfBill &&
    rejectedStatusIds.includes(documentStatus)
  ) {
    result = 'подписал документ с расхождениями';
  }

  return result;
};

interface CommentLabelParams {
  eventType: number;
  documentStatus: number;
}

const commentLabel = [
  {
    label: 'Причина отказа',
    check: ({ eventType }: CommentLabelParams) => {
      return eventType === eventTypes.DP_PRANNULDENY;
    },
  },
  {
    label: 'Причина аннулирования',
    check: ({ eventType, documentStatus }: CommentLabelParams) => {
      return (
        revokeEventIds.includes(eventType) &&
        revokeStatuses.includes(documentStatus)
      );
    },
  },
  {
    label: 'Причина уточнения',
    check: ({ eventType }: CommentLabelParams) => {
      return clarificationEventIds.includes(eventType);
    },
  },
  {
    label: 'Расхождения',
    check: ({ eventType, documentStatus }: CommentLabelParams) => {
      return (
        rejectedEventIds.includes(eventType) &&
        rejectedStatusIds.includes(documentStatus)
      );
    },
  },
];

export const getCommentLabel = ({
  eventType,
  documentStatus,
}: CommentLabelParams) => {
  const commentLabelData = commentLabel.find((item) => {
    return item.check({
      eventType,
      documentStatus,
    });
  });

  return commentLabelData?.label ?? 'Комментарий';
};
