import React, { memo } from 'react';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { Box } from '@crpt/material';
import { Typography, Dialog, Divider, Button, Icon } from '@crpt-ui/core';
import { EditGtinsState, Gtin } from '../../CisUnloads.viewmodel';
import { GtinInput } from './GtinInput';
import { useTranslation } from 'react-i18next';
import { GtinDialogViewmodel } from './GtinDialog.viewmolel';

export interface GtinDialogProps extends EditGtinsState {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Gtin[], fieldName: string) => void;
}

const GtinDialog: React.FC<GtinDialogProps> = ({
  title,
  isOpen,
  onClose,
  onSubmit,
  gtins,
  fieldName,
}) => {
  const { t } = useTranslation();

  const vm = GtinDialogViewmodel({
    onClose,
    onSubmit,
    gtins,
    fieldName,
    isOpen,
  });

  return (
    <Dialog maxWidth="md" onClose={onClose} open={isOpen} disableBackdropClick>
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">{title}</Typography>
          <Button
            startIcon={<Icon name="Close" />}
            variant="text"
            onClick={onClose}
            size="sm"
          />
        </Box>
        <Box mb={3} mx={-4}>
          <Divider />
        </Box>
        <Form
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          form={vm.formInstance}
          submit={vm.formInstance.submit}
          subscription={{
            hasValidationErrors: true,
          }}
        >
          {({
            hasValidationErrors,
            handleSubmit,
            form: {
              mutators: { push },
            },
          }) => (
            <>
              <Box pb={2}>
                <Button
                  color="secondary"
                  icon={<Icon name="add" />}
                  onClick={() => push('data', { gtin: '', name: '' })}
                  size="md"
                  variant="text"
                >
                  {t('UnloadService.Task.CisUnloads.GtinModal.addGtin')}
                </Button>
              </Box>
              <FieldArray name="data">
                {({ fields, meta }) => (
                  <Box sx={{ maxHeight: '400px', overflow: 'auto' }}>
                    {fields.map((name, index) => {
                      return (
                        <GtinInput
                          key={index}
                          index={index}
                          name={name}
                          error={meta.error && meta.error[index]}
                          deleteGtin={fields.remove}
                          loadProduct={vm.loadProduct}
                          clearName={vm.clearName}
                          isLoading={vm.isLoading}
                        />
                      );
                    })}
                  </Box>
                )}
              </FieldArray>
              <Box mx={-4} my={3}>
                <Divider />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Button disabled={hasValidationErrors} onClick={handleSubmit}>
                  {t('UnloadService.Task.CisUnloads.GtinModal.save')}
                </Button>
                <Button onClick={vm.handleClose} variant="outlined">
                  {t('UnloadService.Task.CisUnloads.GtinModal.cancel')}
                </Button>
              </Box>
            </>
          )}
        </Form>
      </Box>
    </Dialog>
  );
};

export default memo(GtinDialog);
