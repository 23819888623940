import { call, put, select, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash';
import moment from 'moment';
import { push } from 'connected-react-router';

import { HttpMethodEnum } from '../../../../../constants/index';
import { Api } from '../../../../../common_components/Api/Api';
import { getLocation } from '../../../../../common_components/App/App.selectors';
import {
  getCompanyData,
  getSoleProprietorship,
} from '../../../../_Profile/Company/ducks/Company.selectors';
import { signContent } from '../../../../../common_components/Cades/Cades.saga';
import { getCurrentCertificate } from '../../../../../common_components/Cades/Cades.selectors';
import { startDocumentSaga } from '../../ducks/ClosingDocument.actions';
import {
  getDocumentId,
  getViewLink,
} from '../../ducks/ClosingDocument.selectors';

import {
  setSigningProcess,
  startDocumentSignSaga,
} from './SignClosingDocument.actions';
import { getSignData } from './SignClosingDocument.selectors';

/**
 * Prepare author name,
 * if it is an sole proprietorship is added "КПП"
 *
 * @returns {string} - Prepared author's name
 */
function* getPrepareAuthorName() {
  const authorName = [];
  const company = yield select(getCompanyData);
  const { inn } = yield select(getCurrentCertificate);
  const isSoleProprietorship = yield select(getSoleProprietorship);

  authorName.push(company.name);
  authorName.push(`ИНН: ${inn}`);

  if (!isSoleProprietorship && company.kppElk) {
    authorName.push(`КПП: ${company.kppElk}`);
  }

  return authorName.join(', ');
}

/**
 * Returns prepare signer
 *
 * @param {SignFormValuesType} values - Form values
 * @returns {{
 *  grounds: string,
 *  patronymic: string,
 *  surname: string,
 *  authority: number,
 *  organization_grounds: string,
 *  inn: string,
 *  name: string,
 *  status: number
 * }}
 */
function* getPrepareSigner({ values }) {
  const certificateInfo = yield select(getCurrentCertificate);

  return {
    authority: values.authority,
    grounds: values.grounds,
    status: values.status,
    organization_grounds: values.organization_grounds,
    inn: certificateInfo.inn,
    name: certificateInfo.name,
    patronymic: certificateInfo.patronymic,
    surname: certificateInfo.surname,
    position: certificateInfo.position || 'Ответственное лицо',
  };
}

/**
 * Returns prepare acceptance.
 * If isOpenAcceptance is true, then the data is taken from the form,
 * otherwise the data is taken from the certificate.
 *
 * @param {boolean} isOpenAcceptance - Is open acceptance form
 * @param {SignFormValuesType} values - Form values
 * @returns {{
 *  date: number,
 *  employee: {
 *    name: string,
 *    patronymic: string,
 *    position: string,
 *    surname: string,
 *    authority: 'Должностные обязанности',
 *  }
 *  }} - Prepare acceptance
 */
function* getPrepareAcceptance({ values }) {
  let employee;
  let content_code;
  let date = Math.floor(moment().valueOf() / 1000);

  const getEmployeeFields = (field) => ({
    name: field.name,
    patronymic: field.patronymic,
    position: field.position ?? 'Ответственное лицо',
    surname: field.surname,
    authority: 'Должностные обязанности',
  });

  if (values.isOpenAcceptance) {
    date = Math.floor(moment(values.date).valueOf() / 1000);
    employee = getEmployeeFields(values);
  } else {
    const certificateInfo = yield select(getCurrentCertificate);

    employee = getEmployeeFields(certificateInfo);
  }

  if (get(values, 'acceptance.content_code', false)) {
    content_code = {
      ...values.acceptance.content_code,
      date: Math.floor(
        moment(values.acceptance.content_code.date).valueOf() / 1000
      ),
    };
  }

  return {
    date,
    employee,
    content_code,
  };
}

/**
 * Preparing data for event id
 *
 * @param {SignFormValuesType} values - Form values
 * @returns {{content: {acceptance: {}, author: {name: string}, signer: {}}, status: number}}
 */
function* getPrepareDataForEvent({ values }) {
  const authorName = yield call(getPrepareAuthorName);
  const acceptance = yield call(getPrepareAcceptance, {
    values,
  });
  const signer = yield call(getPrepareSigner, {
    values,
  });

  return {
    status: 4,
    content: {
      author: {
        name: authorName,
        content_operation: values?.content_operation,
      },
      signer,
      acceptance,
    },
  };
}

/**
 * Fetching event id to sign the document
 *
 * @param {string} documentId - Document id
 * @param {SignFormValuesType} values - Form values
 * @returns {{id: string}} - Event id
 */
function* getEvent({ documentId, values }) {
  let data = {
    status: 4,
  };

  if (values) {
    data = yield call(getPrepareDataForEvent, {
      values,
    });
  }

  const [response] = yield call(
    Api.request,
    {
      url: `/edo-api/incoming-documents/${documentId}/events`,
      data,
      method: HttpMethodEnum.POST,
    },
    {
      preloading: false,
    }
  );

  return get(response, 'data');
}

/**
 * Fetching event content by id
 *
 * @param {string} documentId - Document id
 * @param {string} eventId - Event id
 * @returns {string} - Event content
 */
export function* getEventContent({ documentId, eventId }) {
  const [response] = yield call(
    Api.request,
    {
      url: `/edo-api/incoming-documents/${documentId}/events/${eventId}/content`,
      responseType: 'arraybuffer',
      timeout: 60000,
    },
    {
      preloading: false,
    }
  );

  return get(response, 'data');
}

/**
 * Sending a document for signing
 *
 * @param {string} documentId - Document id
 * @param {string} eventId - Event id
 * @param {string} signature - Signed event content
 * @returns {boolean} - Has the document been successfully signed
 */
export function* sendEventSignature({ documentId, eventId, signature }) {
  const [, error] = yield call(Api.request, {
    url: `/edo-api/incoming-documents/${documentId}/events/${eventId}/signature`,
    data: signature,
    method: HttpMethodEnum.POST,
    headers: {
      'Content-Type': 'text/plain',
      'Content-Encoding': 'base64',
    },
    timeout: 60000,
  });

  return !error;
}

/**
 * Signing the document,
 * in case of successful signing, the transition to the document viewing page takes place
 *
 * @param {{document_id: string, values: SignFormValuesType, isOpenAcceptance: boolean}} payload
 */
function* signDocuments({ payload = {} }) {
  yield put(setSigningProcess(true));

  let { values = {} } = payload;

  if (Object.keys(values).length === 0) {
    values = yield select(getSignData);
  }

  const documentId = yield select(getDocumentId);

  const eventId = yield call(getEvent, {
    documentId,
    values,
  });
  const eventData = yield call(getEventContent, {
    documentId,
    eventId: eventId.id,
  });

  const signature = yield call(signContent, eventData);
  const eventSignature = yield call(sendEventSignature, {
    documentId,
    eventId: eventId.id,
    signature,
  });

  if (eventSignature) {
    const { pathname } = yield select(getLocation);
    const viewLink = yield select(getViewLink);

    if (pathname === viewLink) {
      yield put(
        startDocumentSaga({
          id: documentId,
        })
      );
    } else {
      yield put(push(viewLink));
    }
  }

  yield put(setSigningProcess(false));
}

export const saga = function* watch() {
  yield takeEvery(startDocumentSignSaga, signDocuments);
};
