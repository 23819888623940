import axios from 'axios';

import { instance } from './services/instance';
import { history } from './store';
import { b64DecodeUnicode } from './utils/localStorageHelpers';
import { getCookie, deleteAllCookies } from './utils/cookieHelpers';

instance.interceptors.request.use((config) => {
  const tokenPart1 = getCookie('tokenPart1');
  const tokenPart2 = getCookie('tokenPart2');
  const token = tokenPart1 && tokenPart2 ? tokenPart1 + tokenPart2 : null;

  if (token) {
    let exp;

    try {
      const data = token.split('.')[1];
      const decoded = b64DecodeUnicode(data);
      exp = JSON.parse(decoded).exp;
    } catch (err) {
      console.error(err);
    }

    if (exp && exp < new Date().getTime() / 1000) {
      deleteAllCookies();
      history.push('/login-kep');
      throw new axios.Cancel();
    }
  }

  return config;
});

instance.interceptors.response.use(null, (err) => {
  if (err.response && err.response.status === 401) {
    const nidKeys = window.localStorage.getItem('nidKeys');
    if (
      !nidKeys ||
      window.location.pathname !== '/login-inn' ||
      window.location.pathname !== '/nid_call_back_url/'
    ) {
      deleteAllCookies();
      history.push('/login-kep');
    }
  }
  return Promise.reject(err);
});
