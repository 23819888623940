import React from 'react';
import { Box, BoxProps } from '@crpt/material';
import {
  FilterChips,
  FilterValueTranslationHandler,
  UseTableInstance,
} from '@crpt/table';
import { TableToolButtons } from './TableToolButtons';

export interface TableToolbarProps<T extends Record<string, any>>
  extends BoxProps {
  tableInstance: UseTableInstance<T>;
  onTranslateFilterValue?: FilterValueTranslationHandler<T>;
  hideFilters?: boolean;
  hideConfig?: boolean;
  hideChips?: boolean;
  children?: boolean;
}

export function TableToolbar<T extends Record<string, any>>({
  tableInstance,
  onTranslateFilterValue,
  hideFilters,
  hideConfig,
  hideChips,
  children,
  ...boxProps
}: TableToolbarProps<T>) {
  return (
    <Box {...boxProps}>
      <Box sx={{ display: 'flex', padding: '24px'}}>
        <Box sx={{flex: '1 1 0'}}>{children}</Box>
        <TableToolButtons
          hideConfig={hideConfig}
          hideFilters={hideFilters}
          tableInstance={tableInstance}
        />
      </Box>

      {!hideChips && (
        <FilterChips
          onTranslateValue={onTranslateFilterValue}
          sx={{
            paddingBottom: '12px',
            paddingX: '24px',
          }}
          tableInstance={tableInstance}
        />
      )}
    </Box>
  );
}
