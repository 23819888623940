export const defaultFormattingParams = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const formatNumberRU = (value, params = defaultFormattingParams) => {
  const locale = 'ru-RU';

  if (isNaN(value) || (typeof value === 'string' && value.trim() === '')) {
    return '-';
  }

  const resultValue = new Intl.NumberFormat(locale, {
    ...params,
  }).format(value);

  return resultValue;
};
