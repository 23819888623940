import React from 'react';

import { Icon } from '@crpt-ui/core';
import { productGroupService } from '@crpt/shared/services';

import { makeBoldCell, makeMoneyCell } from '../../utils/Table.utils';

import { ProductGroupCell } from './ProductGroupCell/ProductGroupCell';
import { BalanceCell } from './BalanceCell/BalanceCell';

export const AccountsListColumns = [
  {
    id: 'productGroupId',
    accessor: 'productGroupId',
    Header: 'Товарная группа',
    Cell: ProductGroupCell,
    sortType: (rowA, rowB, columnId) => {
      const a = productGroupService.getName(rowA.values[columnId]);
      const b = productGroupService.getName(rowB.values[columnId]);
      return a < b ? -1 : 1;
    },
  },
  {
    accessor: 'contractId',
    Header: 'Номер счета',
    Cell: makeBoldCell(),
    width: 240,
  },
  {
    accessor: 'balance',
    Header: 'Баланс',
    Cell: BalanceCell,
    width: 240,
  },
  {
    id: 'minBalance',
    accessor: 'minBalance',
    Header: 'Минимальный остаток',
    Cell: makeMoneyCell(),
    width: 240,
  },
];

export const productGroupIdIconsMap = Object.freeze({
  1: <Icon name="ClothesSimplified" />,
  2: <Icon name="ShoesSimplified" />,
  3: <Icon name="TobaccoSimplified" />,
  4: <Icon name="PerfumerySimplified" />,
  5: <Icon name="TiresSimplified" />,
  6: <Icon name="ElectronicsSimplified" />,
  8: <Icon name="MilkSimplified" />,
  9: <Icon name="BicyclesSimplified" />,
  10: <Icon name="WheelchairsSimplified" />,
  12: <Icon name="TobaccoAlternativeSimplified" />,
  13: <Icon name="WaterSimplified" />,
  14: <Icon name="FursSimplified" />,
  15: <Icon name="BeerSimplified" />,
  16: <Icon name="NcpSimplified" />,
});
