import React from 'react';

import { Grid } from '@material-ui/core';

import { Icon, Tooltip } from '@crpt-ui/core';

import { useStyles } from './OperatorsOption.styled';

interface PropTypes {
  label: React.ReactElement | string;
  status: string;
}

export const OperatorsOption = ({
  label,
  status,
}: PropTypes): React.ReactElement => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" spacing={1}>
      {status === 'await' && (
        <Grid item>
          <Tooltip title="С данным оператором ЭДО не настроен роуминг. О сроках его подключения уточните в службе технической поддержки вашего оператора.">
            <Icon className={classes.icon} name="Error" />
          </Tooltip>
        </Grid>
      )}
      <Grid item>{label}</Grid>
    </Grid>
  );
};
