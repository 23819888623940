import { handleActions } from 'redux-actions';

import {
  setCurrentCertificate,
  setPreparedCertificates,
  setCertificates,
  clearCertificates,
} from './Cades.actions';

const certificates = handleActions(
  {
    [setCertificates]: (_, { payload }) => payload,
    [clearCertificates]: () => [],
  },
  []
);

const preparedCertificates = handleActions(
  {
    [setPreparedCertificates]: (_, { payload }) => payload,
    [clearCertificates]: () => [],
  },
  []
);

const currentCertificate = handleActions(
  {
    [setCurrentCertificate]: (_, { payload }) => payload,
    [clearCertificates]: () => ({}),
  },
  {}
);

export const reducer = {
  certificates,
  preparedCertificates,
  currentCertificate,
};
