import { useState, useEffect, useCallback, useMemo } from 'react';
import { DocumentStatus } from '../../constants';
import toast from '@crpt/shared/toast';
import {
  CreateErrorsTaskResponse,
  FetchErrorsStatusResponse,
  DownloadFileAvailability,
  DownloadFileStatus,
  DispenserService,
  DocService,
} from '../../services';
import { getFrontendTaskUrl } from '../../utils/urlUtils';
import { saveAs } from 'file-saver';

export interface DocumentUnloadErrorsViewModelOptions {
  documentId: string;
  documentStatus: DocumentStatus;
  productGroupId: number;
}

export interface ErrorTaskStatus {
  loading: boolean;
  data?: CreateErrorsTaskResponse;
}

export interface ReportStatus {
  loading: boolean;
  data?: FetchErrorsStatusResponse;
}

const { SUCCESS, PREPARATION, FAILED } = DownloadFileStatus;
const { AVAILABLE, NOT_AVAILABLE } = DownloadFileAvailability;

const docService = new DocService();
const dispenserService = new DispenserService();

export const DocumentUnloadErrorsViewModel = ({
  documentId,
  documentStatus,
  productGroupId,
}: DocumentUnloadErrorsViewModelOptions) => {
  const documentHasErrors = [
    DocumentStatus.CHECKED_NOT_OK,
    DocumentStatus.PARSE_ERROR,
    DocumentStatus.PROCESSING_ERROR,
    DocumentStatus.UNDEFINED].includes(documentStatus);

  const [reportStatus, setReportStatus] = useState<ReportStatus>({
    loading: false,
  });
  const [taskStatus, setTaskStatus] = useState<ErrorTaskStatus>({
    loading: false,
  });

  const fetchErrorsStatus = useCallback(async () => {
    setReportStatus({
      loading: true,
    });

    try {
      const { data } = await docService.fetchErrorsStatus({
        documentId,
        productGroupId,
      });

      setReportStatus({
        data,
        loading: false,
      });
    } catch (error) {
      if (error?.response?.status === 404) {
        setReportStatus({
          loading: false,
        });
      } else if (error) {
        setReportStatus({
          loading: false,
        });
        toast.error(error.message);
      }
    }
  }, [documentId]);

  useEffect(() => {
    if (documentId && documentHasErrors) {
      fetchErrorsStatus();
    }
  }, [documentId, fetchErrorsStatus, documentHasErrors]);

  const createErrorsTask = useCallback(async () => {
    setTaskStatus({
      loading: true,
    });
    try {
      const { data: data } = await docService.createErrorsTask({
        documentId,
        notificationUrl: getFrontendTaskUrl('/documents/list'),
        productGroupId,
      });

      setTaskStatus({
        loading: false,
        data,
      });
      toast.success(
        'Запрос на формирование списка ошибок отправлен. Файл с перечнем ошибок будет доступен через несколько минут на этой же странице'
      );

      fetchErrorsStatus();
    } catch (error) {
      setTaskStatus({
        loading: false,
      });
      toast.error(error.message);
    }
  }, [documentId, fetchErrorsStatus]);

  const fetchReportFile = useCallback(async () => {
    if (reportStatus.data && reportStatus.data?.fileDownloadLink) {
      const link = reportStatus.data.fileDownloadLink;
      const fileName = link.match(/(file-)(.*)(?=\.csv\.zip)/);
      const fileId = fileName && fileName[0].replace('file-', '');

      if (fileId) {
        try {
          const { data: data } = await dispenserService.fetchResultFile({
            fileId,
          });

          const file = new Blob([data], {
            type: 'application/zip',
          });
          saveAs(file, `${fileId}.zip`);
        } catch (error) {
          toast.error(error.message);
        }
      }
    }
  }, [reportStatus.data]);

  const viewStatus = useMemo(() => {
    const status = reportStatus.data?.status;
    const availability = reportStatus.data?.availability;

    const reportHasStatusText = status === PREPARATION || status === FAILED;
    const reportIsFailedDownload = status === FAILED;

    const reportIsNotExist =
      (availability === NOT_AVAILABLE && !reportHasStatusText) || !status;
    const reportIsReady = availability === AVAILABLE && status === SUCCESS;

    return {
      reportIsReady,
      reportIsFailedDownload,
      reportIsNotExist,
      reportHasStatusText,
    };
  }, [reportStatus.data]);

  return {
    viewStatus,
    documentHasErrors,
    reportStatus,
    createErrorsTask,
    fetchReportFile,
    taskStatus,
  };
};

export default DocumentUnloadErrorsViewModel;
