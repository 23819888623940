import { makeStyles } from '@material-ui/styles';

import type { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    margin: '0',
    '& > ul': {
      maxHeight: '300px',
    },
  },
  listbox: {
    padding: '5px 0px',
  },
  option: {
    padding: '6px 20px',
    '&[data-focus="true"]': {
      backgroundColor: '#f7f7f7',
    },
    '&[aria-selected="true"]': {
      backgroundColor: 'none !important',
    },
  },
  popupIndicator: {
    transition: theme.transitions.create('transform'),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  clearIndicator: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  endAdornment: {
    position: 'static',
    paddingRight: 10,
  },
  input: {
    padding: '16px 16px 0 16px !important',
  },
  inputRoot: {
    padding: '0 !important',
    flexWrap: 'nowrap',
  },
}));
