import React, { useEffect, useMemo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import useClipboard from 'react-use-clipboard';

import { Icon } from '@crpt-ui/core';
import { toast } from '@crpt/shared/toast';

import { NOTIFICATION_OPERATOR_TYPES } from '../NotificationOperatorDocumentsList/NotificationOperatorDocumentsList.constants';

import { fetchNotificationOperatorDocument } from './ducks/NotificationOperatorDocument.actions';
import {
  getContentNotificationOperatorDocument,
  getInfoNotificationOperatorDocument,
  getLoading,
} from './ducks/NotificationOperatorDocument.selectors';
import { NotificationOperatorDocumentView } from './NotificationOperatorDocument.view';

const NotificationOperatorDocument = ({
  fetchNotificationOperatorDocument,
  match,
  content,
  documentInfo,
  loading,
}) => {
  const id = useMemo(() => match.params.id.toUpperCase(), [match.params.id]);
  const [, setCopied] = useClipboard(id);

  useEffect(() => {
    fetchNotificationOperatorDocument(id);
  }, [fetchNotificationOperatorDocument, id]);

  const documentTitle = useMemo(
    () => `${NOTIFICATION_OPERATOR_TYPES[documentInfo.type]} ${id}`,
    [documentInfo.type, id]
  );

  const additionalActions = useMemo(
    () => [
      {
        label: 'Скопировать номер документа',
        onClick: () => {
          setCopied();
          toast.success('Номер документа скопирован');
        },
        icon: <Icon name="Copy" />,
      },
    ],
    [loading, setCopied]
  );

  return React.createElement(NotificationOperatorDocumentView, {
    documentTitle,
    content,
    documentInfo,
    additionalActions,
    loading,
  });
};

const mapStateToProps = (state) => ({
  content: getContentNotificationOperatorDocument(state),
  documentInfo: getInfoNotificationOperatorDocument(state),
  loading: getLoading(state),
});

const mapDispatchToProps = {
  fetchNotificationOperatorDocument,
};

export const NotificationOperatorDocumentConnected = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(NotificationOperatorDocument);
