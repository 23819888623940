import { createActions } from 'redux-actions';

export const {
  setEditing,
  setProfileData,
  setRoles,
  setGroups,
  setAccounts,
  sendProfileData,
  sendFinalize,
} = createActions(
  {
    SET_EDITING: (payload) => payload,
    SET_PROFILE_DATA: (payload) => payload,
    SET_ROLES: (payload) => payload,
    SET_GROUPS: (payload) => payload,
    SET_ACCOUNTS: (payload) => payload,

    SEND_PROFILE_DATA: (payload) => payload,
    SEND_FINALIZE: () => {},
  },
  {
    prefix: 'COMPANY',
  }
);
