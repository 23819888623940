type GetLimittedStringParams = {
  lastPartLength?: number;
  limit?: number;
  value?: string;
  isLastPart?: boolean;
  withStarsSymbolsSeparator?: boolean;
};

export const getLimittedString = (params: GetLimittedStringParams) => {
  const {
    value,
    limit,
    lastPartLength,
    withStarsSymbolsSeparator,
    isLastPart = true,
  } = params || {};
  if (!value || typeof value !== 'string' || !value.length) {
    return null;
  }

  const minLimit = 9;
  const minFirstPartLength = 3;
  const minLastPartLength = 3;
  const separator = withStarsSymbolsSeparator ? '***' : '...';

  let correctedLimit = limit || minLimit;
  correctedLimit = correctedLimit < minLimit ? minLimit : correctedLimit;

  if (value.length <= correctedLimit) {
    return value;
  }

  let correctedLastPartLength = lastPartLength || minLastPartLength;
  if (correctedLastPartLength < minLastPartLength) {
    correctedLastPartLength = minLastPartLength;
  } else if (correctedLastPartLength >= correctedLimit) {
    correctedLastPartLength =
      correctedLimit - separator.length - minFirstPartLength;
  }

  const firstPartLength =
    correctedLimit - separator.length - correctedLastPartLength;

  const lastPart = isLastPart ? value.slice(-correctedLastPartLength) : '';
  const firstPart = value.slice(0, firstPartLength);

  return `${firstPart}${separator}${lastPart}`;
};

export const cutStringUsingEllipsis = (str: string, limit: number) =>
  getLimittedString({
    value: str,
    limit,
    lastPartLength: 6,
  });
