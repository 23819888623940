import React from 'react';

import { makeTextCell } from '../../../utils/Table.utils';

import { ProductionLinesCell } from './ProductionLinesCell/ProductionLinesCell';

export const columnsTable = [
  {
    Header: 'Наименование',
    id: 'productionPlaceName',
    accessor: 'productionPlaceName',
    width: null,
    Cell: makeTextCell(),
  },
  {
    Header: 'Страна производства',
    id: 'productionCountryName',
    accessor: 'productionCountryName',
  },
  {
    Header: 'Адрес производства',
    id: 'productionAddress',
    accessor: 'productionAddress',
  },
  {
    Header: 'Производственные линии',
    id: 'productionLines',
    accessor: (row) => <ProductionLinesCell productionLine={row} />,
  },
  // {
  //   Header: 'КПП',
  //   id: 'productionKpp',
  //   accessor: 'productionKpp',
  //   Cell: makeTextCell(),
  // },
];

export const RUSSIA_COUNTRY_CODE = '643';
