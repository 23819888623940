import { handleActions } from 'redux-actions';

import {
  closeModal,
  openEventModal,
  setCancellationLoading,
  setLoading,
} from './EventModal.actions';

const isOpenModal = handleActions(
  {
    [openEventModal]: () => true,
    [closeModal]: () => false,
  },
  false
);

const loading = handleActions(
  {
    [setLoading]: (_, { payload }) => payload,
    [closeModal]: () => false,
  },
  false
);

const cancellationLoading = handleActions(
  {
    [setCancellationLoading]: (_, { payload }) => payload,
  },
  false
);

const data = handleActions(
  {
    [openEventModal]: (_, { payload }) => payload,
    [closeModal]: () => ({}),
  },
  {}
);

export const reducer = {
  data,
  isOpenModal,
  loading,
  cancellationLoading,
};
