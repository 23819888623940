import React, { useCallback, useEffect, useMemo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { Icon } from '@crpt-ui/core';

import { withContentWrapper } from '../../../common_components/hocs/withContentWrapper/withContentWrapper';
import { translate } from '../../Translate/Translate';

import { openUploadNotificationOperatorDialog } from './UploadNotificationOperatorDialog/ducks/UploadNotificationOperatorDialog.actions';
import { NOTIFICATION_OPERATOR_TYPES } from './NotificationOperatorDocumentsList.constants';
import {
  clearFilter,
  clearSort,
  fetchNotificationOperatorDocuments,
} from './ducks/NotificationOperatorDocumentsList.actions';
import { openDocumentTemplatesDialog } from './DocumentTemplatesDialog/ducks/DocumentTemplatesDialog.actions';
import {
  getDataUploadNotificationOperatorDialog,
  getLoading,
  getManualSortBy,
  getPage,
  hasSelectedFilters,
} from './ducks/NotificationOperatorDocumentsList.selectors';
import { NotificationOperatorDocumentsListView } from './NotificationOperatorDocumentsList.view';

const NODocumentsList = ({
  openUploadNotificationOperatorDialog,
  fetchNotificationOperatorDocuments,
  clearSort,
  clearFilter,
  openDocumentTemplatesDialog,
  data,
  manualSortBy,
  page,
  loading,
  hasSelectedFilters,
}) => {
  const NUMBER_ITEMS_ON_PAGE = 10;

  useEffect(() => {
    fetchNotificationOperatorDocuments();
  }, [fetchNotificationOperatorDocuments]);

  const mainActions = useMemo(
    () => [
      {
        label: 'Загрузить документ',
        actionsList: Object.entries(NOTIFICATION_OPERATOR_TYPES).map(
          ([id, label]) => ({
            label,
            onClick: () =>
              openUploadNotificationOperatorDialog({
                id,
                label,
              }),
          })
        ),
      },
    ],
    [openUploadNotificationOperatorDialog]
  );

  const additionalActions = useMemo(
    () => [
      {
        label: translate('Шаблоны документов'),
        onClick: () => openDocumentTemplatesDialog(),
        icon: <Icon name="Draft" />,
      },
      {
        label: translate('Сбросить фильтры'),
        onClick: () => clearFilter(),
        isShow: hasSelectedFilters,
        icon: <Icon name="Trash" />,
      },
    ],
    [clearFilter, hasSelectedFilters, openDocumentTemplatesDialog]
  );

  const onSortDatatable = useCallback(
    (sorting) => {
      const sort = get(sorting[0], 'id');
      if (sort) {
        fetchNotificationOperatorDocuments({ sort, loading: false });
      }

      if (sorting.length === 0) {
        clearSort();
      }
    },
    [clearSort, fetchNotificationOperatorDocuments]
  );

  const onFiltersChange = useCallback(
    (filters) => {
      if (filters.length === 0) {
        clearFilter();
        return;
      }

      fetchNotificationOperatorDocuments({
        filters,
        loading: false,
      });
    },
    [clearFilter, fetchNotificationOperatorDocuments]
  );

  const noData = useMemo(
    () => !hasSelectedFilters && !loading && data.items.length === 0,
    [data.items.length, hasSelectedFilters, loading]
  );

  const onPageChange = useCallback(
    (page) => {
      fetchNotificationOperatorDocuments({ page, loading: false });
    },
    [fetchNotificationOperatorDocuments]
  );

  const pagesCount = useMemo(() => {
    return Math.ceil(data.count / NUMBER_ITEMS_ON_PAGE);
  }, [data]);

  const isShowPagination = useMemo(() => {
    return data.count > NUMBER_ITEMS_ON_PAGE;
  }, [data]);

  return React.createElement(NotificationOperatorDocumentsListView, {
    data,
    mainActions,
    additionalActions,
    manualSortBy,
    onSortDatatable,
    onFiltersChange,
    clearFilter,
    loading,
    noData,
    pagination: {
      onPageChange,
      pagesCount,
      page,
      isShowPagination,
    },
  });
};

const mapStateToProps = (state) => ({
  data: getDataUploadNotificationOperatorDialog(state),
  loading: getLoading(state),
  page: getPage(state),
  manualSortBy: getManualSortBy(state),
  hasSelectedFilters: hasSelectedFilters(state),
});

const mapDispatchToProps = {
  openUploadNotificationOperatorDialog,
  fetchNotificationOperatorDocuments,
  clearSort,
  clearFilter,
  openDocumentTemplatesDialog,
};

export const NotificationOperatorDocumentsList = compose(
  withContentWrapper({
    style: {
      height: 'calc(100% - 70px)',
      padding: '16px 0',
    },
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(NODocumentsList);
