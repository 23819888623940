import { find } from 'lodash/fp';

import { getCookie } from '../utils/cookieHelpers';

export const getCertSerial = () => {
  const serial = getCookie('certSerial');
  if (!serial) {
    throw new Error('unknown serial number');
  }
  return serial;
};

export const getCertBySerial = (certs) => {
  const serial = getCertSerial();
  const cert = find((i) => i.info[3] === serial, certs);

  try {
    return cert;
  } catch (e) {
    throw new Error('cert not found');
  }
};

export const createStatic = (filename) => {
  return encodeURI(`https://static.crpt.tech/documents/${filename}`);
};
