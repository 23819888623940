import { crytoproErrorsDataList } from './errors';

export const getErrorMessage = (message) => {
  const errorCode = getErrorCodeFromMessage(message);
  return errorCode
    ? crytoproErrorsDataList[errorCode]
    : 'Не удается подписать, убедитесь что выбранный сертификат подходит для подписи';
};

export const getErrorCodeFromMessage = (message) =>
  (message && message.match(/\d{1}x[a-zA-Z0-9]{8}/gm)[0]) || '';
