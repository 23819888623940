import axios from 'axios';

import { createRequestSignature } from '@crpt/shared/utils';

import { getToken } from '../utils/userUtils';

const authInstance = axios.create({
  timeout: 120000,
  headers: {
    'content-type': 'application/json;charset=UTF-8',
    'Cache-Control': 'no-cache',
  },
  withCredentials: true,
  mode: 'no-cors',
  crossdomain: true,
  request: {
    withCredentials: true,
  },
});

authInstance.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    Object.assign(config.headers, {
      Authorization: `Bearer ${token}`,
    });
  }

  Object.assign(config.headers, {
    'X-Source-App': createRequestSignature(),
  });

  return config;
});

export { authInstance };
