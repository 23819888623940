import { useBooleanState } from '@crpt/shared/hooks';
import { documentReprocessingService } from '@crpt/shared/services';
import toast from '@crpt/shared/toast';
import moment from 'moment';
import { useCallback, useMemo } from 'react';

interface DocumentReprocessingViewOptions {
  id: string;
  receivedAt: string;
}

const DocumentReprocessingViewModel = ({
  id,
  receivedAt,
}: DocumentReprocessingViewOptions) => {
  const [disabled, { on: disable, off: enable }] = useBooleanState(false);
  const [loading, { on: startLoading, off: endLoading }] =
    useBooleanState(false);

  const timeBeforeDocumentReprocessing = useMemo(
    () => moment(receivedAt).add(4, 'hours').format('DD.MM.YYYY в HH:mm'),
    [receivedAt]
  );

  const reprocessDocument = useCallback(async () => {
    try {
      startLoading();
      await documentReprocessingService.startReprocessing(id);
      toast.success('Запрос на повторную обработку документа отправлен');
      disable();
    } catch (error) {
      toast.error(
        'Не удалось отправить запрос на повторную обработку документа. Попробуйте повторить позже.'
      );
      enable();
    } finally {
      endLoading();
    }
  }, [disable, enable, endLoading, id, startLoading]);

  const getButtonProps = useCallback(
    () => ({
      loading,
      disabled,
      onClick: reprocessDocument,
    }),
    [disabled, loading, reprocessDocument]
  );

  return {
    disabled,
    getButtonProps,
    timeBeforeDocumentReprocessing,
  } as const;
};

export default DocumentReprocessingViewModel;
