import { createActions } from 'redux-actions';

export const { push } = createActions(
  {
    push: (payload) => payload,
  },
  {
    prefix: 'CUSTOM_ROUTER_LINK',
  }
);
