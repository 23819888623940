import { withStyles, WithStyles } from '@material-ui/core';
import React, { FC } from 'react';
import VersionsHistoryStyles from './VersionsHistory.styles';
import { Button, Icon, Divider } from '@crpt-ui/core';
import { generateVersionsList } from './VersionsHistory.utils';

type VersionsHistoryProps = {
  onClose: () => void;
  onVersionClick: (timestamp: number) => void;
  versions: number[];
  selectedVersion?: number;
} & WithStyles<typeof VersionsHistoryStyles>;

const VersionsHistory: FC<VersionsHistoryProps> = ({
  classes,
  onClose,
  onVersionClick,
  versions,
  selectedVersion,
}) => {
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <span className={classes.title}>История версий</span>
        <Button
          startIcon={<Icon name="Close" />}
          variant="text"
          onClick={onClose}
        ></Button>
      </div>
      <Divider />
      <div className={classes.content}>
      {
        generateVersionsList({ versions, selectedVersion, onVersionClick })
      }
      </div>
    </div>
  );
};

export default withStyles(VersionsHistoryStyles)(VersionsHistory);
