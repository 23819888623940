import { EventEmitter } from 'events';
import { generateUUID } from '@crpt/shared/utils/generateUUID';

import {
  FileQueueCollection,
  FileQueueCollectionMap,
  FileQueueItem,
} from './FileQueue.types';

class FileQueueManager extends EventEmitter {
  private readonly list: FileQueueCollectionMap;

  constructor() {
    super();
    this.list = new Map();
  }

  create(state: Pick<FileQueueItem, 'label' | 'fileSize'>): {
    fileQueueId: string;
  } {
    const id = generateUUID();

    this.list.set(id, {
      ...state,
      loadedSize: 0,
      loadingPercent: 0,
      loaded: false,
      hasStop: false,
    });

    this.emitChange();

    return {
      fileQueueId: id,
    };
  }

  update(
    id: string,
    state: Pick<FileQueueItem, 'loadedSize' | 'cancelRequest'>
  ) {
    const prevState = this.list.get(id);

    if (!prevState) {
      return;
    }

    const loadingPercent = (state.loadedSize / (prevState.fileSize ?? 1)) * 100;

    this.list.set(id, {
      ...prevState,
      ...state,
      loadingPercent,
      hasStop: typeof state.cancelRequest === 'function',
    });

    this.emitChange();
  }

  success(id: string) {
    const prevState = this.list.get(id);

    if (!prevState) {
      return;
    }

    this.list.set(id, {
      ...prevState,
      loaded: true,
    });

    this.emitChange();

    setTimeout(() => {
      this.remove(id, false);
    }, 1500);
  }

  remove(id: string, needCancelRequest = true) {
    const state = this.list.get(id);

    if (!state) {
      return;
    }

    if (needCancelRequest && state.cancelRequest) {
      state.cancelRequest();
    }

    this.list.delete(id);
    this.emitChange();
  }

  private emitChange() {
    this.emit('change', this.list);
  }

  addChangeListener(callback: (collection: FileQueueCollection) => void) {
    this.addListener('change', callback);
  }

  removeChangeListener(callback: (collection: FileQueueCollection) => void) {
    this.removeListener('change', callback);
  }
}

export default new FileQueueManager();
