import styled from 'styled-components';

export const TableWrapper = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
`;

export const CellWrapper = styled.div`
  display: table-cell;
`;

export const CellContentWrapper = styled.div`
  display: flex;
  overflow-x: auto;
`;
