import React, { useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import { compose } from 'redux';

import { createForm } from '@crpt-ui/form';

import { FormWrapper } from '../../../common_components/Form/FormWrapper';
import { PreloaderWrapper } from '../../../common_components/PreloaderWrapper/PreloaderWrapper';
import { withContentWrapper } from '../../../common_components/hocs/withContentWrapper/withContentWrapper';
import * as companySelectors from '../Company/ducks/Company.selectors';

import {
  getEdoRegistrationData,
  getLoading,
} from './ducks/EdoRegistration.selectors';
import {
  startEdoRegistrationSaga,
  setEditing,
} from './ducks/EdoRegistration.actions';
import { EdoRegistrationView } from './EdoRegistration.view';
import { schema } from './EdoRegistration.schema';

const EdoRegistration = ({
  data,
  loading,
  startEdoRegistrationSaga,
  setEditing,
  isRegistered,
}) => {
  useEffect(() => {
    startEdoRegistrationSaga();

    return () => {
      setEditing(false);
    };
  }, [setEditing, startEdoRegistrationSaga]);

  const form = useMemo(() => {
    return createForm({
      schema,
      onSubmit: () => {},
      mutators: { ...arrayMutators },
      initialValues: data,
    });
  }, [data]);

  return (
    <PreloaderWrapper isLoading={loading}>
      <FormWrapper form={form}>
        {React.createElement(EdoRegistrationView, {
          loading,
          isRegistered,
        })}
      </FormWrapper>
    </PreloaderWrapper>
  );
};

const mapStateToProps = (state) => ({
  data: getEdoRegistrationData(state),
  loading: getLoading(state),
  isRegistered: companySelectors.getRegistered(state),
});

const mapDispatchToProps = {
  startEdoRegistrationSaga,
  setEditing,
};

EdoRegistration.propTypes = {
  data: PropTypes.shape({}).isRequired,
  formValues: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  startEdoRegistrationSaga: PropTypes.func.isRequired,
  isRegistered: PropTypes.bool.isRequired,
};

export const EdoRegistrationConnected = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withContentWrapper()
)(EdoRegistration);
