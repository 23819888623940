import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { Api } from '../../../common_components/Api/Api';

import {
  fetchPaymentStatus,
  setLoading,
  setPaymentStatus,
} from './PaymentStatus.actions';

function* fetchPaymentStatusSaga({ payload: orderId }) {
  yield put(setLoading(true));

  const requestPayload = {
    url: `/billing-api/orders/${orderId}`,
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    yield put(setPaymentStatus(success.data));
  }

  if (error) {
    yield put(push('/dashboard'));
  }

  yield put(setLoading(false));
}

export const saga = function* watch() {
  yield takeEvery(fetchPaymentStatus, fetchPaymentStatusSaga);
};
