import React from 'react';

import PropTypes from 'prop-types';

import { useStyles } from './PrimitiveStatus.styles';

export const PrimitiveStatus = ({
  children,
  color = null,
  icon = null,
  style = null,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{
        color,
        ...style,
      }}
      {...rest}
    >
      {icon && <span className={classes.icon}>{icon}</span>}
      {children ? children : '—'}
    </div>
  );
};

PrimitiveStatus.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  icon: PropTypes.elementType,
  style: PropTypes.object,
};
