import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Filter, FilterSet } from '@ibox/ui';

import { loading } from '../../../../common_components/Preloader/ducks/Preloader.selectors';
import {
  initFromUrl,
  parseDateFilterFromUrlStr,
} from '../../../../utils/url-utils';
import {
  deleteSavedFilterFromStorage,
  editSavedFilterStorage,
  newFilter,
} from '../../../../helpers/filterHistory';
import { mergeFilters } from '../../../../utils/filters';

import {
  selectedPageSelector,
  resultsSelector,
  lastSelector,
} from './ducks/Documents.selectors';
import {
  fetch as fetchData,
  setHistoryFilters,
  setSavedFilters,
  unmounted,
} from './ducks/Documents.actions';
import {
  filtersHistoryName,
  getFilters,
  savedFiltersName,
} from './documents.constants';
import { DocumentsView } from './documentsView';

type DocumentListProps = {
  location: Record<string, unknown>;
  inn: string;
  history: Record<string, unknown>;
  match: Record<string, unknown>;
  selectedParticipant: Record<string, unknown>;
  staticContext?: unknown;
};

export const DocumentList = (props: DocumentListProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => loading(state));
  const results = useSelector((state) => resultsSelector(state));
  const last = useSelector((state) => lastSelector(state));
  const selectedPage = useSelector((state) => selectedPageSelector(state));
  const { t } = useTranslation();

  const filterNames = getFilters(t)
    .map((filter) =>
      filter.id === 'docDate'
        ? [filter.config?.keyFrom, filter.config?.keyTo]
        : filter.id
    )
    .flat();

  const initialFilterState = {
    tableParams: initFromUrl(
      props.location.search,
      filterNames,
      parseDateFilterFromUrlStr
    ),
    senderInnFilter: { id: 'senderInn', value: props.inn },
  };

  useEffect(() => {
    const { location, inn } = props;
    const { page } = initFromUrl(location.search, filterNames);
    const innFilter = [
      { id: 'senderInn', value: inn },
      { id: 'receiverInn', value: inn },
    ];
    const { filters } = initialFilterState.tableParams;
    const requestFilters = [...filters, ...innFilter];
    dispatch(fetchData({ filters: requestFilters, ...page }));
    return () => {
      dispatch(unmounted());
    };
  }, []);

  const getPaginationParams = (results) => {
    const current = results[results.length - 1];
    const { number, senderInn, docDate } = current;
    return {
      pageDir: 'NEXT',
      number,
      senderInn,
      receiptDate: new Date(docDate).toISOString(),
    };
  };

  const onPageChange = () => {
    const { location } = props;
    const {
      tableParams: { filters },
      senderInnFilter,
    } = initialFilterState;
    dispatch(
      fetchData({
        filters: mergeFilters({ target: filters, source: [senderInnFilter] }),
        page: selectedPage + 1,
        pathname: location.pathname,
        paginationPagesParams: getPaginationParams(results),
      })
    );
  };

  const onFilterApply = (filters: unknown) => {
    const { location, history, inn } = props;
    const innFilter = [
      { id: 'senderInn', value: inn },
      { id: 'receiverInn', value: inn },
    ];
    const requestFilters = [...filters, ...innFilter];
    dispatch(
      fetchData({
        filters: requestFilters,
        page: 1,
        updateHistory: true,
        pathname: location.pathname,
        history,
      })
    );
  };

  const getHistoryFilters = () => {
    if (localStorage.getItem(filtersHistoryName)) {
      const allHistoryFilters = JSON.parse(
        localStorage.getItem(filtersHistoryName) || ''
      );
      dispatch(setHistoryFilters(allHistoryFilters));
    } else {
      dispatch(setHistoryFilters([]));
    }
  };

  const getSavedFilters = () => {
    if (localStorage.getItem(savedFiltersName)) {
      const allSavedFilters = JSON.parse(
        localStorage.getItem(savedFiltersName) || ''
      );
      dispatch(setSavedFilters(allSavedFilters));
    } else {
      dispatch(setSavedFilters([]));
    }
  };

  const handleAddFiltersHistory = (value: Filter[]) => {
    if (value.length) {
      newFilter(value, filtersHistoryName, '');
      getHistoryFilters();
    }
  };

  const deleteSavedFilter = (filterId: string) => {
    deleteSavedFilterFromStorage(filterId, savedFiltersName);
    getSavedFilters();
  };

  const editSavedFilter = (
    value: Filter[],
    filterName: string,
    filter: FilterSet
  ) => {
    const changedFilter = {
      id: filter.id,
      name: filterName && filterName,
      filters: value,
    };
    editSavedFilterStorage(changedFilter, savedFiltersName);
    getSavedFilters();
  };

  const addSavedFilters = (value: Filter[], filterName: string) => {
    if (value.length) {
      newFilter(value, savedFiltersName, filterName);
      getSavedFilters();
    }
  };

  return (
    <>
      <DocumentsView
        addSavedFilters={addSavedFilters}
        deleteSavedFilter={deleteSavedFilter}
        editSavedFilter={editSavedFilter}
        handleAddFiltersHistory={handleAddFiltersHistory}
        isLoading={isLoading}
        last={last}
        results={results}
        selectedPage={selectedPage}
        onFilterApply={onFilterApply}
        onPageChange={onPageChange}
        getSavedFilters={getSavedFilters}
        getHistoryFilters={getHistoryFilters}
      />
    </>
  );
};
