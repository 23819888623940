import React, { useRef } from 'react';

import { get } from 'lodash/fp';
import cn from 'classnames';
import { Tooltip } from '@mui/material';

import css from './data-tooltip.module.scss';

type DataTooltip = {
  render: () => string | React.ReactNode;
  title: string | React.ReactNode;
  isWhiteColor?: boolean;
  alwaysDisplay?: boolean;
  arrow?: boolean;
  placement?: string;
};

export const DataTooltip: React.FC<DataTooltip> = ({
  render,
  title,
  isWhiteColor = false,
  alwaysDisplay = false,
  arrow = true,
  placement = 'bottom',
  ...rest
}) => {
  const wrapRef = useRef();
  const contentRef = useRef();
  const wrapWidth = get('current.offsetWidth', wrapRef) || 0;
  const contentWidth = get('current.offsetWidth', contentRef) || 0;
  const isShow =
    wrapWidth > 0 && contentWidth > 0 && contentWidth > wrapWidth - 10;

  const getView = () => {
    return (
      <div className={css.container} ref={wrapRef}>
        <div className={css.content} ref={contentRef}>
          {render()}
        </div>
      </div>
    );
  };

  return isShow || alwaysDisplay ? (
    <Tooltip
      arrow={arrow}
      classes={{
        tooltip: cn(css.tooltip, {
          [css.tooltipIsWhiteColor]: isWhiteColor,
        }),
      }}
      placement={placement}
      title={title}
      {...rest}
    >
      {getView()}
    </Tooltip>
  ) : (
    getView()
  );
};
