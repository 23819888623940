import { call, put, select, takeEvery } from 'redux-saga/effects';

import { HttpMethodEnum } from '../../../../../constants/index';
import { Api } from '../../../../../common_components/Api/Api';
import { signContent } from '../../../../../common_components/Cades/Cades.saga';
import { fetchNotificationOperatorDocuments } from '../../ducks/NotificationOperatorDocumentsList.actions';

import { getType } from './UploadNotificationOperatorDialog.selectors';
import {
  closeUploadNotificationOperatorDialog,
  sendNotificationOperatorDocument,
  setSubmitting,
} from './UploadNotificationOperatorDialog.actions';

function* sendNotificationOperatorDocumentSaga({ payload }) {
  yield put(setSubmitting(true));

  let formData = new FormData();

  const signature = yield call(signContent, payload.data);
  const type = yield select(getType);

  formData.append(
    'content',
    new Blob([payload.data], { type: 'application/pdf' }),
    encodeURI(payload.filename)
  );
  formData.append('signature', signature);
  formData.append('type', type.id);

  const requestPayload = {
    url: '/edo-api/outgoing-documents',
    method: HttpMethodEnum.POST,
    data: formData,
  };

  const [success] = yield call(Api.request, requestPayload, {
    successMessage: `Документ «${type.label}» загружен.`,
    preloading: false,
  });

  if (success) {
    yield put(fetchNotificationOperatorDocuments());
    yield put(closeUploadNotificationOperatorDialog());
  }

  yield put(setSubmitting(false));
}

export const saga = function* watch() {
  yield takeEvery(
    sendNotificationOperatorDocument,
    sendNotificationOperatorDocumentSaga
  );
};
