import { DocumentStatus } from '@crpt/shared/constants';
import { FileFormat } from '../../constants';

export const fileFormatOptions = Object.values(FileFormat).map((item) => ({
  value: item,
  label: item,
}));

export const availableDocumentStatuses = [
  DocumentStatus.CHECKED_OK,
  DocumentStatus.CHECKED_NOT_OK,
  DocumentStatus.CANCELLED,
];

export const MAX_DATE_PERIOD_DAYS = 90;
export const MAX_YEARS_AGO = 5;

export const validateStringRegExp = {
  inn: /^(\d{10}|\d{12})$/,
};
