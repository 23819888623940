import { makeStyles } from '@material-ui/core';

export const useAgreementDocumentsListStyles = makeStyles({
  link: {
    fontWeight: 600,

    '& svg': {
      fontSize: 22,
    },
    '& svg g': {
      fill: '#4aa5e8',
    },
  },
  documentCount: {
    marginLeft: 'auto',
    paddingRight: 20,
    color: '#b8b8b8',
    fontSize: 12,
  },
  contentWrapper: {
    height: 'calc(100% - 70px)',
    padding: '16px 0',
  },
});
