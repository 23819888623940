import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Autocomplete, AutocompleteProps, Box, Throbber, useTheme } from '..';
import { debounce } from 'lodash';

export interface AsyncLookupInputProps<
  Value,
  Option,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined = false
> extends Omit<
    AutocompleteProps<Value, Multiple, DisableClearable, false>,
    | 'options'
    | 'filterOptions'
    | 'getOptionLabel'
    | 'renderOptionItem'
    | 'inputValue'
    | 'loading'
    | 'freeSolo'
  > {
  searchOptions: (inputValue: string, signal: AbortSignal) => Promise<Option[]>;
  fetchValue: (
    value: Value,
    signal: AbortSignal
  ) => Promise<Option | undefined | null>;
  searchTrigger?: (inputValue: string) => boolean;
  getOptionValue?: (option: Option) => Value;
  getOptionLabel?: (option: Option) => string;
  renderOptionItem?: (option: Option) => React.ReactNode;
  delay?: number;
  errorText?: React.ReactNode;
}

interface LookupInputState<T> {
  optionValues: T[];
  loading: boolean;
  error?: boolean;
}

const defaultState: LookupInputState<any> = {
  loading: false,
  error: false,
  optionValues: [],
};

export interface AsyncLookupInputDefaultOption<Value> {
  label?: string;
  name?: string;
  value: Value;
}

const AsyncLookupInput = <
  Value,
  Option extends Record<string, any> = AsyncLookupInputDefaultOption<Value>,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false
>(
  props: AsyncLookupInputProps<Value, Option, Multiple, DisableClearable>
) => {
  const {
    delay = 350,
    fetchValue,
    searchOptions,
    searchTrigger = (inputValue) => inputValue.length >= 3,
    getOptionValue = ({ value }) => value,
    getOptionLabel = ({ label, name, value }) => label || name || value,
    renderOptionItem,
    errorText = <ErrorText />,
    noOptionsText,
    InputProps = (props) => props,
    ...autocompleteProps
  } = props;
  const [lookupInputState, setLookupInputState] =
    useState<LookupInputState<Value>>(defaultState);
  const { loading, error, optionValues } = lookupInputState;
  const abortCtrlRef = useRef<AbortController>();
  const initValuesRef = useRef<boolean>(false);
  const [optionRegistry, setOptionRegistry] = useState<Map<Value, Option>>(
    new Map()
  );
  const [inputValue, setInputValue] = useState<string>('');

  const addOptionsToRegistry = useCallback(
    (options: Option[]) => {
      if (options.length > 0) {
        setOptionRegistry((optionRegistry) => {
          return options.reduce<Map<Value, Option>>(
            (map, option) => map.set(getOptionValue(option), option),
            new Map(optionRegistry)
          );
        });
      }
    },
    [getOptionValue]
  );

  const createSetStateCtrl = useCallback(
    (abortCtrl: AbortController) => {
      function setState(state: LookupInputState<Value>) {
        if (abortCtrlRef.current === abortCtrl) {
          setLookupInputState(state);
        }
      }
      return {
        setLoading: () => {
          setState({ optionValues: [], loading: true });
        },
        setOptions: (options: Option[]) => {
          addOptionsToRegistry(options);
          const optionValues = options.map(getOptionValue);
          setState({ optionValues, loading: false });
        },
        setError: (error: unknown) => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(error);
          }
          setState({ optionValues: [], loading: false, error: true });
        },
      };
    },
    [addOptionsToRegistry, getOptionValue]
  );

  useEffect(() => {
    if (!initValuesRef.current) {
      initValuesRef.current = true;
      const abortCtrl = new AbortController();
      abortCtrlRef.current = abortCtrl;
      const requests = (valueToArray(props.value) as Value[])
        .filter((value) => !optionRegistry.has(value))
        .map((value) => fetchValue(value, abortCtrl.signal));
      if (requests.length > 0) {
        const { setLoading, setOptions, setError } =
          createSetStateCtrl(abortCtrl);
        setLoading();
        Promise.all(requests)
          .then((options) => {
            const optionList = options.filter((option): option is Option =>
              hasValue(option)
            );
            setOptions(optionList);
          })
          .catch(setError);
      }
      return () => abortCtrl.abort();
    }
  }, [createSetStateCtrl, fetchValue, optionRegistry, props.value]);

  const debouncedFetchOptions = useMemo(() => {
    async function fetch(inputValue: string, abortCtrl: AbortController) {
      const { setLoading, setOptions, setError } =
        createSetStateCtrl(abortCtrl);
      setLoading();
      const normalizedValue = (inputValue || '').trim();
      const isTriggered = searchTrigger?.(normalizedValue);
      try {
        const options = isTriggered
          ? await searchOptions(normalizedValue, abortCtrl.signal)
          : [];
        setOptions(options);
      } catch (error) {
        setError(error);
      }
    }
    return debounce(fetch, delay);
  }, [createSetStateCtrl, delay, searchOptions, searchTrigger]);

  const componentsProps = useMemo(() => {
    const isTriggered = searchTrigger?.(inputValue.trim());
    return !isTriggered || loading
      ? { paper: { sx: { display: 'none' } } }
      : props.componentsProps;
  }, [inputValue, loading, props.componentsProps, searchTrigger]);

  return (
    <Autocomplete
      {...autocompleteProps}
      InputProps={(props) => {
        return InputProps?.({
          ...props,
          endAdornment: loading ? (
            <Throbber sx={{ position: 'absolute', right: 16 }} variant="blue" />
          ) : (
            props.endAdornment
          ),
        });
      }}
      componentsProps={componentsProps}
      filterOptions={(optionValues) => optionValues}
      getOptionLabel={(optionValue) => {
        if (!loading) {
          const option = optionRegistry.get(optionValue);
          return option ? getOptionLabel(option) : optionValue;
        }
        return '';
      }}
      inputValue={inputValue}
      loading={loading}
      noOptionsText={error ? errorText : noOptionsText}
      onClose={(event, reason) => {
        abortCtrlRef.current?.abort();
        props.onClose?.(event, reason);
      }}
      onInputChange={(event, value, reason) => {
        setInputValue(value);
        if (['input', 'clear'].includes(reason)) {
          abortCtrlRef.current?.abort();
          const abortCtrl = new AbortController();
          abortCtrlRef.current = abortCtrl;
          debouncedFetchOptions(value, abortCtrl);
        }
        props.onInputChange?.(event, value, reason);
      }}
      options={optionValues}
      renderOptionItem={(optionValue) => {
        const option = optionRegistry.get(optionValue);
        return option ? renderOptionItem?.(option) : null;
      }}
    />
  );
};

function hasValue<T>(value?: T | null | ''): boolean {
  return value != null && value !== '';
}

function valueToArray<T>(value?: T | T[] | null): T[] {
  if (Array.isArray(value)) {
    return value;
  }
  return hasValue(value) ? [value as T] : [];
}

function ErrorText() {
  const theme = useTheme();
  const color = theme.palette.red[70];
  return <Box color={color}>Возникла ошибка при загрузке данных</Box>;
}

export default memo(AsyncLookupInput) as any as typeof AsyncLookupInput;
