import { createSelector } from 'reselect';

export const NotificationOperatorListState = (state) =>
  state.Documents.NotificationOperatorDocumentsList;

export const getDataUploadNotificationOperatorDialog = createSelector(
  [NotificationOperatorListState],
  (state) => state.data
);

export const getLoading = createSelector(
  [NotificationOperatorListState],
  (state) => state.loading
);

export const getPage = createSelector(
  [NotificationOperatorListState],
  (state) => state.page
);

export const getOffset = createSelector([getPage], (page) => {
  const NUMBER_ITEMS_ON_PAGE = 10;

  return (page - 1) * NUMBER_ITEMS_ON_PAGE;
});

export const getSort = createSelector(
  [NotificationOperatorListState],
  (state) => state.sort
);

export const getFilter = createSelector(
  [NotificationOperatorListState],
  (state) => state.filter
);

export const hasSelectedFilters = createSelector(
  [getFilter],
  (filters) => Object.keys(filters).length > 0
);

export const getManualSortBy = createSelector([getSort], (sort) => ({
  sortBy: [
    {
      id: sort.sortBy,
      desc: !sort.asc,
    },
  ],
}));
