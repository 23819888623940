import { push } from 'react-router-redux';
import { call, put, takeEvery } from 'redux-saga/effects';

import * as linkActions from './CustomRouterLink.actions';

function* updateUrlParams({ urlString }) {
  yield put(push(urlString));
}

function* pushNewRoute({ payload = {} }) {
  const { to = '/' } = payload;
  yield call(updateUrlParams, { urlString: to });
}

export const saga = function* watch() {
  yield takeEvery(linkActions.push, pushNewRoute);
};
