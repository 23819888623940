import React from 'react';

import { TabsComponent } from '@ibox/ui';

export const ParticipantDetailsTabs = ({ items, value }) => {
  return (
    <TabsComponent
      items={items}
      value={value}
      variant="standard"
      onChangeTab={() => {}}
      onClick={(e) => {
        e.preventDefault();
      }}
    />
  );
};
