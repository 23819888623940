export const getFrontendTaskUrl = (absolutePath: string): string => {
  const locationOrigin = window.location.origin;
  return /localhost/.test(locationOrigin)
    ? `https://preprod.lp.crpt.tech${absolutePath}`
    : `${locationOrigin}${absolutePath}`;
};

export const getStaticFileUrl = (filename: string): string => {
  return encodeURI(`https://static.crpt.ru/documents/${filename}`);
};

export const getCRPTStaticFileUrl = (filename: string): string =>
  `https://xn--80ajghhoc2aj1c8b.xn--p1ai/business/doc/?id=${encodeURI(
    filename
  )}`;
