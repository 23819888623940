import { createSelector } from 'reselect';

export const getManuallyAgreementDocument = (state) =>
  state.Documents.AgreementDocumentsList.ManuallyAgreementDocument;

export const getOpenManualModeModal = createSelector(
  [getManuallyAgreementDocument],
  (manuallyAgreementDocument) => manuallyAgreementDocument.open
);

export const getManuallyAutocompleteList = createSelector(
  [getManuallyAgreementDocument],
  (manuallyAgreementDocument) => manuallyAgreementDocument.autocompleteList
);

export const getLoadingAutocomplete = createSelector(
  [getManuallyAgreementDocument],
  (manuallyAgreementDocument) => manuallyAgreementDocument.isLoadingAutocomplete
);

export const getLoading = createSelector(
  [getManuallyAgreementDocument],
  (manuallyAgreementDocument) => manuallyAgreementDocument.loading
);
