import React, { FC, useCallback } from 'react';
import clsx from 'clsx';
import { Button, Icon } from '@crpt-ui/core';
import { useBooleanState } from '@crpt/shared/hooks';

import { useStyles } from './FileQueue.styles';
import FileQueueManager from './FileQueueManager';
import { FileQueueMini } from './FileQueueMini/FileQueueMini';
import { FileQueueItem } from './FileQueueItem';
import type { FileQueueCollection } from './FileQueue.types';

interface FileQueueProps {
  collection: FileQueueCollection;
}

export const FileQueue: FC<FileQueueProps> = ({ collection }) => {
  const [isViewMinimized, { on: openViewMinimized, off: closeViewMinimized }] =
    useBooleanState();
  const classes = useStyles();

  const onRemove = useCallback((id) => {
    FileQueueManager.remove(id);
  }, []);

  if (isViewMinimized) {
    return (
      <FileQueueMini
        closeViewMinimized={closeViewMinimized}
        collection={collection}
      />
    );
  }

  return (
    <div
      className={clsx(classes.wrapper, {
        [classes.open]: collection.length !== 0,
      })}
    >
      <div className={classes.header}>
        Подготовка к скачиванию
        <Button
          icon={<Icon name="Chevron" />}
          onClick={openViewMinimized}
          variant="text"
        />
      </div>

      {collection.map(({ name: id, value: item }) => (
        <FileQueueItem
          id={id}
          item={item}
          key={`${item.label}_${item.fileSize}`}
          onRemove={onRemove}
        />
      ))}
    </div>
  );
};
