import { handleAction } from 'redux-actions';

import {
  setAccounts,
  setEditing,
  setGroups,
  setProfileData,
  setRoles,
} from './Company.actions';

export type AccountsItem = {
  balance: number;
  contractId: number;
  organisationId: number;
  productGroupId: number;
};

export type GroupItem = {
  code: string;
  description?: string;
  id: number;
  name: string;
  productGroupStatus: string;
  startDate: number;
  tnvedDtoSet: unknown[];
  url: null | string;
};

export type RolesItem = {
  code: string;
  name: string;
};

export type ProductGroupsAndRolesItem = {
  code: string;
  status: string;
  startDate: number;
  contractId: number;
  license: string;
  statusName: string;
  types: string[];
};

export type Data = {
  id: number;
  inn: string;
  name: string;
  shortName: string;
  fullName: string;
  status: string;
  head: string[];
  roles: string[];
  legalAddress: string;
  actualAddress: string;
  phone: string;
  afp: boolean;
  organizationForm: string;
  dissolved: boolean;
  productGroupsAndRoles: ProductGroupsAndRolesItem[];
  physicalPerson: {
    fio: string;
    socialCardId: string;
  };
  foreignOrganisation: unknown;
  needCheckProfile: boolean;
  emails: unknown[];
  phones: string[];
  statusName: string;
  registrationDate: string;
  type: string;
};

const data: Data = handleAction(
  setProfileData,
  (_state, { payload }) => payload,
  {}
);

const roles: RolesItem[] = handleAction(
  setRoles,
  (_state, { payload }) => payload,
  []
);

const groups: GroupItem[] = handleAction(
  setGroups,
  (_state, { payload }) => (payload ? payload : []),
  []
);

const editing: boolean = handleAction(
  setEditing,
  (_, { payload }) => payload,
  false
);

const accounts: AccountsItem[] = handleAction(
  setAccounts,
  (_, { payload }) => payload,
  []
);

export const reducer = {
  accounts,
  data,
  editing,
  groups,
  roles,
};
