import React, { Fragment } from 'react';

import { connect, useDispatch } from 'react-redux';
import { Box, Grid } from '@mui/material';

// TODO: Добавить необходимые иконки на замену crpt
// eslint-disable-next-line no-restricted-imports
import { Icon } from '@crpt-ui/core';
import { Button } from '@ibox/ui';

import { Form } from '../../../../common_components/Form/form/form';
import { FormContext } from '../../../../common_components/Form/Form.constants';
import { req } from '../../../../common_components/Form/Form.validations';
import { MultiSelect } from '../../../../common_components/Form/MuiFields/MultiSelect/MultiSelect';
import {
  setFinishStep,
  setPreviousStep,
  setProductGroupList,
  setSelectedProductGroup,
} from '../../ducks/StartPage.actions';
import { TitleConnected } from '../Common/Title/Title';

import css from './index.module.scss';

type ProductGroup = {
  productGroup?: unknown;
};

export const ProductGroup = ({ productGroup }: ProductGroup) => {
  const dispatch = useDispatch();
  /**
   * Select EDO item
   * @param {number} value - EDO id
   */
  function handleChange(value: unknown) {
    dispatch(setSelectedProductGroup(value));
  }

  const onBackClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    dispatch(setPreviousStep(e));
  };

  /**
   * Set next step if form completed
   */
  const nextStep = () => {
    dispatch(setFinishStep());
  };

  let isError = true;
  /* принцып такой же как и на предыдущем шаге */
  if (
    Object.prototype.hasOwnProperty.call(productGroup, 'activeProductGroup') &&
    productGroup.activeProductGroup.length > 0
  ) {
    isError = false;
  }

  return (
    <Fragment>
      <TitleConnected title="Укажите свою товарную группу" />

      <Box mt={4}>
        <Form>
          <FormContext.Consumer>
            {() => (
              <Fragment>
                <MultiSelect
                  required
                  defaultValue={productGroup.activeProductGroup}
                  items={productGroup.list}
                  label="Выберите одну или несколько товарных групп"
                  name="edo_operators"
                  validate={req}
                  onChange={handleChange}
                />
              </Fragment>
            )}
          </FormContext.Consumer>
        </Form>
      </Box>

      <Box mt={4}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button size="large" variant="outlined" onClick={onBackClick}>
              <div className={css.button}>
                <Icon name="ArrowLeft" />
                <span>Назад</span>
              </div>
            </Button>
          </Grid>
          <Grid item>
            <Button disabled={isError} size="large" onClick={nextStep}>
              <div className={css.button}>
                <span>Далее</span>
                <Icon name="ArrowRight" />
              </div>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

const mapDispatchToProps = {
  setSelectedProductGroup,
  setProductGroupList,
};

const mapStateToProps = (store: unknown) => ({
  productGroup: store.StartPage.productGroup,
  setProductGroupList: setProductGroupList(store),
  skipGS1Step: store.StartPage.skipGS1Step,
});

export const ProductGroupConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductGroup);
