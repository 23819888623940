import React, { ChangeEvent, FC, useEffect } from 'react';
import { RadioGroup as RadioGroupCRPT } from '@crpt-ui/core';
import FieldHoc from '../FieldHoc';

import type { FieldRenderProps } from 'react-final-form';
import type { RadioGroupProps } from '@crpt-ui/core/src/RadioGroup/RadioGroup';

interface RadioGroupAdapterProps
  extends FieldRenderProps<any, HTMLDivElement>,
    RadioGroupProps {
  keepValue?: boolean;
  onChange?: (event: ChangeEvent) => void;
}

const RadioGroupAdapter: FC<RadioGroupAdapterProps> = ({
  input,
  meta,
  keepValue,
  ...fieldRest
}) => {
  useEffect(() => {
    if (!keepValue) {
      return () => {
        input.onChange('');
      };
    }
  }, []);

  // повторяет поведение input.onChange, отключает ошибку FinalForm
  // You must pass type="radio" prop to your Field(perishable) component.
  const onChangeHandle = (event: ChangeEvent<HTMLInputElement>) => {
    input.onChange(event.target.value);
  };

  return <RadioGroupCRPT {...input} onChange={onChangeHandle} {...fieldRest} />;
};

export default FieldHoc(RadioGroupAdapter);
