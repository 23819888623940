import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Form as FinalForm } from 'react-final-form';

import { FormContext, formMutators } from '../Form.constants';
import { deepOmit, deepRangePickerPrepare } from '../Form.utils';

import css from './form.module.scss';

export class Form extends Component {
  static propTypes = {
    data: PropTypes.shape({}),
    onSubmit: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
      .isRequired,
    disabled: PropTypes.bool,
    validate: PropTypes.func,
    decorators: PropTypes.arrayOf(PropTypes.func),
    style: PropTypes.object,
  };

  static defaultProps = {
    data: {},
    onSubmit: undefined,
    disabled: false,
    validate: undefined,
    decorators: undefined,
  };

  onSubmit = (vals) => {
    const { onSubmit } = this.props;
    const formName = vals.__form__nameForm;

    const preparedRangePicker = deepRangePickerPrepare(
      vals,
      /^__form_RangePicker_.*$/
    );

    const prepared = deepOmit(preparedRangePicker, [/^__form_.*$/]);

    if (onSubmit) {
      onSubmit(prepared, { formName });
    }
  };

  render() {
    const { data, children, disabled, validate, decorators, style } =
      this.props;

    return (
      <FinalForm
        decorators={decorators}
        initialValues={data}
        mutators={formMutators}
        validate={validate}
        onSubmit={this.onSubmit}
      >
        {({ handleSubmit, values, errors, form }) => (
          <form className={css.form} style={style} onSubmit={handleSubmit}>
            <FormContext.Provider
              value={{
                values,
                errors,
                mutators: form.mutators,
                reset: form.reset,
                disabled,
                formChange: form.change,
                form,
              }}
            >
              {children}
            </FormContext.Provider>
          </form>
        )}
      </FinalForm>
    );
  }
}
