import { ProductGroupIdEnum } from '../../../constants';

type SetGtin = {
  gtin: string;
  quantity?: number;
};

export interface StorageConditionDTO {
  storageConditionName?: string;
  storageConditionId?: number;
}

export type Product = Partial<{
  babyFoodProduct: string;
  brakeSystemType: string;
  brand: string;
  carbonationMethod: string;
  color: string;
  consumerAge: string;
  country: string;
  enginePower: string;
  engineType: string;
  explain: string;
  fat: string;
  frameConstructionType: string;
  frameMaterial: string;
  frameSealingType: string;
  frameSize: string;
  frontForkType: string;
  furType: string;
  gender: string;
  goodMarkFlag: boolean;
  goodSignedFlag: boolean;
  goodStatus: string;
  goodTurnFlag: boolean;
  gtin: string;
  heightWidthRatio: string;
  id: number;
  inn: string;
  innerUnitCount: number;
  isDeleted: boolean;
  isDyedFur: string;
  isKit: boolean;
  isSet: boolean;
  isShelfLife40Days: string;
  isSpecializedFoodProduct: string;
  isTechGtin: boolean;
  isVarQuantity?: boolean;
  level: string;
  mainGtin: string;
  materialDown: string;
  materialLining: string;
  materialUpper: string;
  mineralization: string;
  model: string;
  multiplier: number;
  name: string;
  okpd2Group: string;
  okpd2Code: string;
  packageType: string;
  packMaterial: string;
  permittedInns: string[];
  productGroup: number;
  productGroupId: number;
  productionYear: string;
  productKind: string;
  productSize: string;
  publicationDate: number;
  rawOrigin: string;
  rimDiameter: string;
  seasonality: string;
  sectionWidth: string;
  setGtin: SetGtin[];
  size: string;
  speedCategory: string;
  setDescription?: string;
  standardNumber: string;
  storageConditions?: StorageConditionDTO[];
  structure: string;
  textileType: string;
  tireLoadIndex: string;
  tnVedCode: string;
  tnVedCode10: string;
  volume: string;
  quantityInPackType: string;
  quantityInPack?: string;
  volumeWeight: string;
  veterinaryControl?: string;
  weight: string;
}>;

export interface ProductExporterDTO {
  exporterName: string;
  exporterTaxpayerId: string;
  gcp: string;
  gln: string;
  id: number;
  importerAccount: string;
  kpp: string;
  manufactureAddress: string;
  ncId: number;
}

export interface ForeignProductProducerDTO {
  name: string;
  inn: string;
  kpp: string;
  gcp: string;
  gln: string;
  address: string;
}

export interface ProductDTO {
  brand: string;
  explain?: string;
  exporter?: ProductExporterDTO;
  foreignProducer?: ForeignProductProducerDTO;
  goodMarkFlag?: boolean;
  goodSignedFlag?: boolean;
  goodStatus?: string;
  goodTurnFlag?: boolean;
  gtin: string;
  id: number;
  inn: string;
  innerUnitCount: number;
  isDeleted?: boolean;
  isKit?: boolean;
  isSet?: boolean;
  isTechGtin?: boolean;
  isVarQuantity?: boolean;
  level?: string;
  mainGtin?: string;
  model?: string;
  multiplier?: number;
  name: string;
  packageType: string;
  permittedInns?: string[];
  productGroup?: ProductGroupIdEnum;
  productGroupId?: ProductGroupIdEnum;
  publicationDate?: number;
  setGtin?: { gtin: string; quantity: number }[];
  // далее параметры специфические для каждой Товарной группы
  storageCondition?: StorageConditionDTO[];
  storageConditions?: string;
  storageConditionUnpacked?: string;
  alcoholicProductTypeCode?: string;
  authDocNumber?: string;
  babyFoodProduct?: string;
  backHeight?: string;
  basicUnit?: string;
  brakeSystemType?: string;
  carbonationMethod?: string;
  certNumber?: string;
  codeTSR?: string;
  color?: string;
  compositionOption?: string;
  consumerAge?: string;
  consumerPackageVolume?: string;
  country?: string;
  declNumber?: string;
  enginePower?: string;
  engineType?: string;
  exciseProduct?: string;
  exciseProductCode?: string;
  fat?: string;
  filtrationType?: string;
  frameConstructionType?: string;
  frameMaterial?: string;
  frameSealingType?: string;
  frameSize?: string;
  frontForkType?: string;
  fullName?: string;
  furType?: string;
  gearType?: string;
  gender?: string;
  heightWidthRatio?: string;
  isBad?: string;
  isCombinedProduct?: string;
  isDyedFur?: string;
  isPasteurized?: string;
  isShelfLife40Days?: string;
  isSpecializedFoodProduct?: string;
  massConcentrationSugars?: string;
  materialDown?: string;
  materialLining?: string;
  materialUpper?: string;
  maximumLoad?: string;
  methodProduction?: string;
  mineralization?: string;
  okpd2Code?: string;
  okpd2Group?: string;
  otherFurType?: string;
  packMaterial?: string;
  perfumeryType?: string;
  productAreaSize?: string;
  productKind?: string;
  productSize?: string;
  productType?: string;
  productTypeDesc?: string;
  productionYear?: string;
  quantityInPackType?: string;
  rawOrigin?: string;
  rawTobaccoWeight?: string;
  regNumber?: string;
  releaseForm?: string;
  rimDiameter?: string;
  seasonality?: string;
  seatDepth?: string;
  seatHeight?: string;
  seatWidth?: string;
  sectionWidth?: string;
  setDescription?: string;
  size?: string;
  speedCategory?: string;
  standardNumber?: string;

  structure?: string;
  textileType?: string;
  tireLoadIndex?: string;
  tireSectionWidth?: string;
  tnVedCode10?: string;
  tnVedCode?: string;
  tobaccoType?: string;
  veterinaryControl?: string;
  vetisId?: string;
  volume?: string;
  volumeFractionEthylAlcohol?: string;
  volumeTradeUnit?: string;
  volumeWeight?: string;
  weight?: string;
  weightGross?: string;
  zoologicalName?: string;
}
export enum ProductStatusEnum {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
}

export interface ProductListResponse {
  results: ProductDTO[];
  total: number;
}