import { WithStyles, withStyles } from '@material-ui/core';
import React, { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';

import RecordsCountStyles from './styles';

export interface RecordsCountProps
  extends ComponentProps<'div'>,
    WithStyles<typeof RecordsCountStyles> {
  count: number;
}

export const RecordsCount: FC<RecordsCountProps> = ({ count, classes }) => {
  const { t } = useTranslation();
  const title = t('RECORDS', {
    count: count,
  });

  return <div className={classes.root}>{title}</div>;
};

export default withStyles(RecordsCountStyles)(RecordsCount);
