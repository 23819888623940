import { takeEvery, put, call } from 'redux-saga/effects';

import { Api } from '../../../common_components/Api/Api';

import {
  onDataLoaded,
  onUsersLoaded,
  clearUsers,
  fetchData,
  fetchGetUsers,
  fetchAddUser,
  setAddedUser,
} from './RegistryOIVPage.actions';

function* addUserSaga(action) {
  const { payload = {} } = action;
  // const { inn } = payload;
  const requestPayload = {
    url: `/api/v3/org/registration/oiv/users`,
    method: 'post',
    params: payload,
  };
  const [success] = yield call(Api.request, requestPayload, {
    preloading: false,
  });
  if (success) {
    yield put(clearUsers());
    yield call(requestUsersSaga, {
      payload: { id: payload.oivId, page: 0 },
    });
    yield put(setAddedUser(success.data));
  } else {
    yield put(onDataLoaded());
  }
}

function* requestSaga(action) {
  const { payload = {} } = action;
  const { inn } = payload;
  const requestPayload = {
    url: `/api/v3/org/organisation/search-oiv`,
    method: 'get',
    params: {
      inn,
      limit: 20,
      page: 0,
    },
  };
  const [success] = yield call(Api.request, requestPayload, {
    preloading: false,
  });
  if (success) {
    if (success.data.results[0]) {
      yield put(onDataLoaded(success.data.results[0]));
    } else {
      yield put(onDataLoaded({}));
      yield put(onUsersLoaded({}));
    }
    // yield put(onDataLoaded(mockDto));
  } else {
    yield put(onDataLoaded());
  }
}

function* requestUsersSaga(action) {
  const { payload = {} } = action;
  const { id, page } = payload;

  const requestPayload = {
    url: `/api/v3/org/user/org/oiv/${id}`,
    method: 'get',
    params: {
      limit: 10,
      page,
    },
  };
  const [success] = yield call(Api.request, requestPayload, {
    preloading: false,
  });
  if (success) {
    yield put(onUsersLoaded({ ...success.data, page }));
  } else {
    yield put(onUsersLoaded({}));
  }
}

export const saga = function* watch() {
  yield takeEvery(fetchData, requestSaga);
  yield takeEvery(fetchAddUser, addUserSaga);
  yield takeEvery(fetchGetUsers, requestUsersSaga);
};
