import { handleActions } from 'redux-actions';

import {
  clearDocumentsList,
  setClosingDocumentsList,
  setLoading,
  setPage,
  setPageCounts,
} from './ClosingDocumentsList.actions';

const documents = handleActions(
  {
    [setClosingDocumentsList]: (_, { payload }) => payload,
    [clearDocumentsList]: () => [],
  },
  []
);

const pagesCount = handleActions(
  {
    [setPageCounts]: (_, { payload }) => payload,
    [clearDocumentsList]: () => [],
  },
  []
);

const page = handleActions(
  {
    [setPage]: (_, { payload }) => payload,
    [clearDocumentsList]: () => [],
  },
  1
);

const loading = handleActions(
  {
    [setLoading]: (_, { payload }) => payload,
    [clearDocumentsList]: () => false,
  },
  false
);

export const reducer = {
  documents,
  pagesCount,
  page,
  loading,
};
