import moment from 'moment';
import { Format, TASK_TYPE, startDay, endDay } from '../../constants';
import { getFrontendTaskUrl } from '../../utils';

import type { CreateTaskDataCisUnloads } from '@crpt/shared/services/UnloadTasksService.types';
import type { FormValues } from './CisUnloads.types';
import { isEmpty, omitBy } from 'lodash';
import { Gtin } from './CisUnloads.viewmodel';

interface CreateTaskExtraData {
  pathToUnloadServiceRegistry: string;
  productGroupCode: number;
  includeGtins: Gtin[];
}

export const createTask = (
  data: FormValues,
  {
    pathToUnloadServiceRegistry,
    productGroupCode,
    includeGtins,
  }: CreateTaskExtraData
): CreateTaskDataCisUnloads => {
  const {
    emissionPeriodStart,
    emissionPeriodEnd,
    appliedPeriodStart,
    appliedPeriodEnd,
    productionPeriodStart,
    productionPeriodEnd,
    meta,
    name,
    periodicity,
    users,
    ...restData
  } = data;
  const params: any = {
    ...restData,
    emissionPeriod: {},
    appliedPeriod: {},
    productionPeriod: {},
  };

  if (emissionPeriodStart) {
    params.emissionPeriod.start = moment(emissionPeriodStart)
      .set(startDay)
      .toISOString();
  }
  if (emissionPeriodEnd) {
    params.emissionPeriod.end = moment(emissionPeriodEnd)
      .set(endDay)
      .toISOString();
  }
  if (appliedPeriodStart) {
    params.appliedPeriod.start = moment(appliedPeriodStart)
      .set(startDay)
      .toISOString();
  }
  if (appliedPeriodEnd) {
    params.appliedPeriod.end = moment(appliedPeriodEnd)
      .set(endDay)
      .toISOString();
  }
  if (productionPeriodStart) {
    params.productionPeriod.start = moment(productionPeriodStart)
      .set(startDay)
      .toISOString();
  }
  if (productionPeriodEnd) {
    params.productionPeriod.end = moment(productionPeriodEnd)
      .set(endDay)
      .toISOString();
  }
  if (includeGtins.length) {
    params.includeGtin = includeGtins.map(({ gtin }) => gtin);
  }

  return {
    params: JSON.stringify(omitBy(params, isEmpty)),
    taskTypeId: TASK_TYPE.CIS_UNLOADES.taskTypeId,
    format: Format.CSV,
    frontendTaskUrl: getFrontendTaskUrl(pathToUnloadServiceRegistry),
    periodicity: periodicity,
    name: name.trim(),
    productGroupCode,
    ...(users?.length
      ? {
          users,
        }
      : null),
  };
};
