import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { without } from 'lodash/fp';

import { Button } from '@ibox/ui';

import { HeaderActionsWrap } from '../../ParticipantDetails.styled';
import { getUserInfo } from '../../../../common_components/Auth/ducks/Auth.selectors';
import { results } from '../../ducks/ParticipantDetails.selectors';
import { fetchUpdateUot } from '../../ducks/ParticipantDetails.actions';
import { translate } from '../../../Translate/Translate';
import {
  Data,
  ProductGroupsAndRolesItem,
} from '../../../_Profile/Company/ducks/Company.store';

type FetchUpdateUotPayload = Record<string, unknown>;

type FooterActionsComponentProps = {
  canEdit: boolean;
  canSave: boolean;
  editMode: boolean;
  fetchUpdateUot: (obj: FetchUpdateUotPayload) => void;
  setEditMode: (mode: boolean) => void;
  formErrors: Record<string, unknown>;
  form: Record<string, unknown>;
  org_id: number;
  values: Data;
  initialProductGroupsAndRoles: ProductGroupsAndRolesItem[];
};

const FooterActionsComponent: React.FC<FooterActionsComponentProps> = (
  props
) => {
  const { form, values, fetchUpdateUot, canEdit, editMode, canSave } = props;
  const { formErrors, initialProductGroupsAndRoles, setEditMode } = props;

  const cancel = () => {
    form.reset();
    setEditMode(false);
  };

  const save = () => {
    if (Object.keys(formErrors).length === 0) {
      const preparedProductGroupsAndRoles = values.productGroupsAndRoles.map(
        (item) => ({
          ...item,
          types: [
            ...(values?.hiddenRoles ? values.hiddenRoles : []),
            ...item.types.map(({ value }) => value),
          ],
        })
      );
      const data = {
        ...values,
        productGroupsAndRoles: preparedProductGroupsAndRoles.map((el) => ({
          ...el,
          roleToDelete: without(el.types)(
            initialProductGroupsAndRoles.find(
              (initPg) => initPg.code === el.code
            )?.types
          ),
          roleToAdd: without(
            initialProductGroupsAndRoles.find(
              (initPg) => initPg.code === el.code
            )?.types || []
          )(el.types),
        })),
      };
      delete data.hiddenRoles;
      fetchUpdateUot(data);
    }
  };

  return (
    <HeaderActionsWrap>
      <Grid container spacing={2}>
        {editMode && (
          <>
            <Grid item>
              <Button variant="text" onClick={cancel}>
                {translate('Отменить')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                className="whiteColor"
                disabled={!canSave}
                variant="contained"
                onClick={save}
              >
                {translate('Сохранить')}
              </Button>
            </Grid>
          </>
        )}
        {canEdit && !editMode && (
          <Grid item>
            <Button
              className="whiteColor"
              variant="contained"
              onClick={() => setEditMode(true)}
            >
              {translate('Редактировать')}
            </Button>
          </Grid>
        )}
      </Grid>
    </HeaderActionsWrap>
  );
};

const mapStateToProps = (state) => ({
  userInfo: getUserInfo(state),
  org_id: results(state).id,
});

const mapDispatchToProps = {
  fetchUpdateUot,
};

export const FooterActions = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(FooterActionsComponent);
