import React, { Fragment } from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import { ChipItem, ChipText, ChipWrapper } from './Chip.styled';

export const Chip = ({ selected, items, textView }) => {
  const chipData = selected.map(
    (select) => items.find((item) => item.id === select) || {}
  );

  /**
   * Returns the title of tooltips if there are more than 3 items in the chip data
   * @returns {string} - Tooltip string
   */
  function getTooltipTitle() {
    let title = '';

    if (chipData.length > 2) {
      title = chipData.map((item) => ` ${item.title}`);
    }

    return String(title);
  }

  return (
    <Tooltip title={getTooltipTitle()}>
      <ChipWrapper textView={textView}>
        {chipData.map((value, index) => (
          <Fragment key={value.title}>
            {textView ? (
              <span>
                {index > 0 && ', '}
                {value.title}
              </span>
            ) : (
              <ChipItem>
                <ChipText>{value.title}</ChipText>
              </ChipItem>
            )}
          </Fragment>
        ))}
      </ChipWrapper>
    </Tooltip>
  );
};

Chip.defaultProps = {
  removeItem: null,
};
