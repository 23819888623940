import React, { FC } from 'react';
import FieldHoc from '../FieldHoc';
import { SelectAdapter, SelectAdapterProps } from './Select';


export const SelectMultipleAdapter: FC<SelectAdapterProps> = (props) => {
  return (
    <SelectAdapter
      {...props}
      multiple
    />
  );
};

export default FieldHoc(SelectMultipleAdapter);
