import { useEffect, useMemo, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createForm } from '@crpt-ui/form';
import moment from 'moment';
import toast from '@crpt/shared/toast';
import { unloadTasksService } from '@crpt/shared/services';
import { useBooleanState } from '@crpt/shared/hooks';
import { AxiosError } from '@crpt/shared/axios';
import { Periodicity, russianDateFormat } from '../../constants';
import {
  FieldIds,
  FormValues,
  MetaFieldIds,
  PackageTypeOption,
} from './CisUnloads.types';
import schema from './CisUnloads.schema';
import { createTask as createTaskAdapter } from './CisUnloads.adapters';
import { startDay } from '../../constants';
import { prepareUserListOptions } from '../../utils';
import { PRODUCT_GROUP } from '../../../../constants';

import type { CreateTaskDataCisUnloads } from '../../../../services/UnloadTasksService.types';
import type { UserListOption } from '../../utils';

export interface Gtin {
  gtin: string;
  name: string;
}

export interface EditGtinsState {
  gtins: Gtin[];
  title?: string;
}

export interface CisUnloadsViewModelOptions {
  isFoivOrOperator: boolean;
  pathToUnloadServiceRegistry: string;
  participantInn?: string;
  productGroupCode: number;
  userData?: any;
  packageTypeOptions: PackageTypeOption[];
}

const dateToStart = (date: string | null) => {
  return moment(date).isValid()
    ? moment(date, 'YYYY-MM-DD').set(startDay).toDate()
    : undefined;
};

const blankGtins = [
  {
    gtin: '',
    name: '',
  },
];

const CisUnloadsViewModel = ({
  isFoivOrOperator,
  pathToUnloadServiceRegistry,
  participantInn,
  userData,
  productGroupCode,
  packageTypeOptions,
}: CisUnloadsViewModelOptions) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [usersList, setUsersList] = useState<UserListOption[]>([]);
  const [includeGtins, setIncludeGtins] = useState<Gtin[]>([]);
  const [editGtinsState, setEditGtinsState] = useState<EditGtinsState>({
    gtins: blankGtins,
    title: '',
  });

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [
    isEditGtinModalOpen,
    { on: openEditGtinModal, off: closeEditGtinModal },
  ] = useBooleanState(false);

  useEffect(() => {
    unloadTasksService.getUsersList().then(({ data }) => {
      setUsersList(prepareUserListOptions(data));
    });
  }, []);

  const onSubmit = async (values: FormValues) => {
    setIsUploading(true);

    try {
      await unloadTasksService.createTaskV1<CreateTaskDataCisUnloads>(
        createTaskAdapter(values, {
          pathToUnloadServiceRegistry,
          productGroupCode,
          includeGtins,
        })
      );

      history.push(pathToUnloadServiceRegistry);
    } catch (error) {
      toast.error(
        (error as AxiosError).message ??
          t('UnloadService.Task.CisUnloads.Messages.createError')
      );
    } finally {
      setIsUploading(false);
    }
  };

  const packageType = useMemo(
    () => new URLSearchParams(history.location.search).get('cisPackageType'),
    [history.location.search]
  );

  const filteredPackageTypeOptions = useMemo(() => {
    return packageTypeOptions?.filter((item) => {
      if (
        productGroupCode === PRODUCT_GROUP.FURS.id &&
        item.value === 'LEVEL1'
      ) {
        return false;
      }

      return true;
    });
  }, [packageTypeOptions]);

  const metaTranslation = useMemo(() => {
    const packageTypes = filteredPackageTypeOptions?.reduce(
      (types, { value, label }) => ({
        ...types,
        [value]: label,
      }),
      {}
    );
    return {
      statuses: t('Cis.StatusOld', {
        returnObjects: true,
      }),
      packageTypes,
      fieldErrors: {
        statuses: {
          onePackageType: (statusName: string, packageTypeName: string) =>
            t('UnloadService.Task.CisUnloads.Errors.status.onePackageType', {
              statusName,
              packageTypeName,
            }),
          multiplePackageType: (statusName: string, packageTypeName: string) =>
            t(
              'UnloadService.Task.CisUnloads.Errors.status.multiplePackageType',
              {
                statusName,
                packageTypeName,
              }
            ),
        },
      },
    };
  }, [t, filteredPackageTypeOptions]);

  const initialValues = useMemo(
    () => ({
      meta: {
        [MetaFieldIds.IS_FOIV_OR_OPERATOR]: isFoivOrOperator,
        [MetaFieldIds.TRANSLATION]: metaTranslation,
      },
      [FieldIds.NAME]: `${t(
        'UnloadService.Task.CisUnloads.TitleShort'
      )} ${moment(new Date()).format(russianDateFormat)}`,
      [FieldIds.PERIODICITY]: Periodicity.SINGLE,
      [FieldIds.PARTICIPANT_INN]: participantInn || '',
      [FieldIds.PACKAGE_TYPE]: packageType ? [packageType] : [],
      [FieldIds.STATUS]:
        new URLSearchParams(history.location.search).get('cisStatus') ||
        new URLSearchParams(history.location.search).get('uituStatus') ||
        '',
      [FieldIds.EMISSION_PERIOD_START]: dateToStart(
        new URLSearchParams(history.location.search).get('emissionDateFrom')
      ),
      [FieldIds.EMISSION_PERIOD_END]: dateToStart(
        new URLSearchParams(history.location.search).get('emissionDateTo')
      ),
    }),
    [
      isFoivOrOperator,
      packageType,
      participantInn,
      t,
      history.location.search,
      metaTranslation,
    ]
  );

  const formInstance = useMemo(
    () =>
      createForm({
        schema,
        initialValues,
        onSubmit: () => {},
      }),
    [initialValues]
  );

  useEffect(() => {
    if (usersList.length > 0) {
      const userFullName = [userData?.SN?.trim(), userData?.G?.trim()]
        .join(' ')
        ?.trim();
      const currentUserIdFromUserList = usersList.find((item) => {
        return (
          userFullName &&
          item.label?.trim() === userFullName &&
          item.additionalLabel
        );
      })?.value;

      if (currentUserIdFromUserList) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formInstance.change(FieldIds.USERS, [currentUserIdFromUserList]);
      }
    }
  }, [usersList]);

  const resetForm = useCallback(() => {
    setIncludeGtins([]);

    formInstance.reset({
      ...initialValues,
      [FieldIds.NAME]: '',
    });
  }, [formInstance, initialValues]);

  const handleEditGtinOpen = useCallback(
    () => {
      const gtins = includeGtins;

      setEditGtinsState({
        gtins: gtins.length > 0 ? gtins : blankGtins,
        title: t('UnloadService.Task.CisUnloads.Fields.includeGtin'),
      });
      openEditGtinModal();
    },
    [setEditGtinsState, openEditGtinModal, includeGtins, t]
  );

  const handleEditGtinSubmit = useCallback(
    (gtins) => {
      setIncludeGtins(gtins);
      closeEditGtinModal();
    },
    [closeEditGtinModal, setIncludeGtins]
  );

  const statusOptions = useMemo(() => {
    const statuses: { [key: string]: string } = t('Cis.StatusOld', {
      returnObjects: true,
    });

    delete statuses.OTHER;
    return Object.keys(statuses)
      .filter((item) => {
        if (
          productGroupCode === PRODUCT_GROUP.FURS.id &&
          item === 'DISAGGREGATION'
        ) {
          return false;
        }

        return true;
      })
      .map((status) => ({
        value: status,
        label: statuses[status],
      }));
  }, [t]);

  const isGtinsAdded = useMemo(() => {
    return includeGtins.length > 0;
  }, [includeGtins]);

  return {
    usersList,
    isEditGtinModalOpen,
    onSubmit,
    openEditGtinModal,
    closeEditGtinModal,
    isUploading,
    editGtinsState,
    handleEditGtinOpen,
    handleEditGtinSubmit,
    formInstance,
    resetForm,
    statusOptions,
    filteredPackageTypeOptions,
    includeGtins,
    t,
    isFoivOrOperator,
    isGtinsAdded,
  } as const;
};

export default CisUnloadsViewModel;
