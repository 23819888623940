import {
  call,
  cancel,
  put,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { isMobile } from 'react-device-detect';
import { replace } from 'connected-react-router';

import { UserStatusEnum } from '../../../constants/index';
import { checkMobileDevice } from '../../App/App.actions';
import { fetchProfileData } from '../../../components/_Profile/Company/ducks/Company.saga';
import {
  getCompanyStatus,
  getReRegistrationStatus,
} from '../../../components/_Profile/Company/ducks/Company.selectors';
import {
  getToken,
  getUserFromToken,
  isTokenValidAndNotExpired,
} from '../../../utils/userUtils';
import {
  deleteAllCookies,
  getCookie,
  setLanguage,
} from '../../../utils/cookieHelpers';
import { AuthState } from '../Auth.constants';
import {
  startSetCertificates,
  clearCertificates,
} from '../../Cades/Cades.actions';

import {
  clearAuth,
  setAuthState,
  setUserInfo,
  startAuthSaga,
  startLogoutSaga,
} from './Auth.actions';

function* checkingMobileDevice() {
  if (isMobile) {
    yield put(checkMobileDevice);
  }
}

function* checkingToken(token) {
  const validToken = yield call(isTokenValidAndNotExpired, token);
  if (!validToken) {
    yield call(logout);

    yield cancel();
  }
}

/**
 * Definition of redirect by user status
 * @param {string} status - Company status
 * @param {{state: {from: {pathname: string}}}} location - Router data
 */
export function* definitionOfRedirect(status, location) {
  let previousPage = '';

  if (location.state) {
    previousPage = location.state.from.pathname;
  }
  const shouldRepeatRegistration = yield select(getReRegistrationStatus);
  const url = status === UserStatusEnum.REGISTERED ? '/dashboard' : '/profile';

  switch (status) {
    case UserStatusEnum.PRE_REGISTERED:
      return yield put(replace('/start-page'));
    case UserStatusEnum.READY_TO_REGISTER:
      return yield put(replace('/message'));
    case UserStatusEnum.REGISTERED:
      if (shouldRepeatRegistration) {
        // yield put(replace('/profile'));
        //
        // return yield put(notificationActions.open());
      }

      if (previousPage) {
        return yield put(replace(previousPage));
      }
      return yield put(replace('/dashboard'));
    default:
      if (previousPage) {
        return yield put(replace(previousPage));
      }
      return yield put(replace(url));
  }
}

export function* AuthSaga({ payload: location }) {
  yield call(checkingMobileDevice);

  yield put(startSetCertificates());

  const token = yield call(getToken);
  yield call(checkingToken, token);

  const user = yield call(getUserFromToken, token);
  const showRMTLink = user.authorities.some(
    (elem) => elem === 'ROLE_ADMIN' || elem.startsWith('ROLE_RMT_')
  );
  const showDashboard = user.authorities.some(
    (elem) =>
      elem === 'ROLE_ADMIN' || elem === 'ROLE_USER' || elem === 'ROLE_ORG_OGV'
  );
  const showProfileLink = user.authorities.some(
    (elem) => elem === 'ROLE_ADMIN' || elem === 'ROLE_USER'
  );

  yield put(
    setUserInfo({ ...user, showRMTLink, showDashboard, showProfileLink })
  );
  yield put(setAuthState(AuthState.AUTHORIZED));

  yield call(fetchProfileData);

  const companyStatus = yield select(getCompanyStatus);

  if (companyStatus) {
    yield call(definitionOfRedirect, companyStatus, location);
  }
}

export function* logout() {
  let lng = getCookie('language') || 'hy';
  if (lng === 'am') {
    lng = 'hy';
  }
  yield call(deleteAllCookies);
  yield put(clearAuth());
  yield put(setAuthState(AuthState.NOT_AUTHORIZED));
  yield put(clearCertificates());
  yield call(setLanguage, lng);
  yield put(replace('/login-kep'));
}

export const saga = function* watch() {
  yield takeLatest(startAuthSaga, AuthSaga);
  yield takeEvery(startLogoutSaga, logout);
};
