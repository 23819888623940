import React, { ReactElement, memo } from 'react';

import clsx from 'clsx';
import { Typography, TypographyVariant } from '@mui/material';
import styles from './index.module.scss';

export type GroupInfoTitleProp = {
  title: string;
  renderRightSide?: ReactElement | null;
  classes: string;
  variant: TypographyVariant;
  half?: boolean;
};

export const GroupInfoTitle = memo(
  ({
    title,
    renderRightSide = null,
    classes = '',
    variant = 'h5',
    half = false,
  }: GroupInfoTitleProp) => {
    return (
      <div className={`${styles.wrapper} ${classes}`}>
        <div className={clsx(styles.titlePart, { [styles.halfField]: half })}>
          <Typography variant={variant}>{title}</Typography>
        </div>
        <div className={clsx(styles.rightSide, { [styles.halfField]: half })}>
          {renderRightSide && renderRightSide}
        </div>
      </div>
    );
  }
);
