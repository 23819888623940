import { handleActions } from 'redux-actions';

import {
  clearSupportList,
  setIncidentItems,
  setIncidentTotal,
  setPage,
} from './SupportList.actions';

const data = handleActions(
  {
    [setIncidentItems]: (_, { payload }) => payload || [],
    [clearSupportList]: () => [],
  },
  []
);

const page = handleActions(
  {
    [setPage]: (_, { payload }) => payload,
    [clearSupportList]: () => 1,
  },
  1
);

const total = handleActions(
  {
    [setIncidentTotal]: (_, { payload }) => payload,
    [clearSupportList]: () => 0,
  },
  0
);

const paramsDefaultState = {
  limit: 10,
};

const params = handleActions(
  {
    [setPage]: (state, { payload: page }) => {
      const offset = (page - 1) * state.limit + 1;

      return {
        ...state,
        offset: offset > 0 ? offset : null,
      };
    },
    [clearSupportList]: () => paramsDefaultState,
  },
  paramsDefaultState
);

export const reducer = {
  data,
  page,
  params,
  total,
};
