import styled from 'styled-components';

export const Wrap = styled.div`
  padding: 20px 22px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
`;

export const TextWrap = styled.div`
  overflow: hidden;
`;

export const IconWrap = styled.div`
  height: 100%;
  padding: 0 20px 0 0;
`;

export const Text = styled.p`
  margin: ${({ setMargin }) => (setMargin ? setMargin : '0')};
  font-size: ${({ setFontSize }) => (setFontSize ? setFontSize : '14px')};
  font-weight: ${({ setFontWeight }) =>
    setFontWeight ? setFontWeight : '400'};
  color: ${({ isDisabled }) => (isDisabled ? '#FF4B4B' : '#63666A')};
  font-family: Calibri, sans-serif;
  line-height: ${({ setLineHeight }) =>
    setLineHeight ? setLineHeight : '1.2'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
