import styled, { css } from 'styled-components';

export const ChipWrapper = styled.div`
  display: flex;
  margin-right: 40px;
  overflow: hidden;
  color: #888888;
  padding: 10px;

  ${(props) =>
    props.textView &&
    css`
      padding: 24px 19px;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`;

export const ChipItem = styled.div`
  background: #888888;
  border-radius: 3px;
  margin-right: 5px;
  padding: 5px 10px;
`;

export const ChipText = styled.span`
  color: #fff;
  font-size: 12px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  display: inline-block;
`;
