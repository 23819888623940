/**
 * The check is performed if the string contains only Cyrillic characters
 *
 * @example
 *    Correct value:
 *    'а' => true
 *    'бвгд' => true
 *    'Ы' => true
 *    'Через-дефис' => true
 *    'Через пробел' => true
 *
 *    Incorrect value:
 *    'J' => false
 *    'слоwо' => false
 *    'd' => false
 * @type {RegExp}
 */
export const cyrillicRegex = /^[-а-яА-ЯёЁ0-9\s]+$/g;
