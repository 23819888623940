import React from 'react';

import { WithStyles, withStyles } from '@material-ui/core';
import { Tooltip, Typography } from '@crpt-ui/core';
import { styles } from './HelpCard.styles';
import { useTextWrapState } from '../../hooks/useTextWrapState';

export type CardTitleProps = {
  title: string;
} & WithStyles<typeof styles>;

const CardTitle: React.FC<CardTitleProps> = ({ title, classes }) => {
  const {
    state: isTooltipOpen,
    onCheckWrap: onMouseEnterHandle,
    off: onMouseLeaveHandle,
  } = useTextWrapState('lineclamp');

  return (
    <Typography
      className={classes.title}
      onMouseEnter={onMouseEnterHandle}
      onMouseLeave={onMouseLeaveHandle}
      variant="h6"
    >
      <Tooltip open={isTooltipOpen} placement="bottom-start" title={title}>
        <span>{title}</span>
      </Tooltip>
    </Typography>
  );
};

export default withStyles(styles)(CardTitle);
