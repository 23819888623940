import { handleActions } from 'redux-actions';

import { mountedLoginKep } from '../../../components/_LoginFormKep/ducks/LoginFormKep.actions';

import { closeMilkNotificationDialog } from './MilkNotification.actions';

const defaultState = !window.localStorage.getItem('MilkNotificationDetached');

const open = handleActions(
  {
    [closeMilkNotificationDialog]: () => false,
    [mountedLoginKep]: () => defaultState,
  },
  defaultState
);

export const reducer = {
  open,
};
