import React from 'react';
import { Tooltip, Button } from '@crpt-ui/core';
import type { ButtonProps } from '@crpt-ui/core';

export type ActionButtonProps = {
  title: string;
} & Omit<ButtonProps, 'classes'>;

const ActionButton: React.FC<ActionButtonProps> = ({ title, ...rest }) => {
  return (
    <Tooltip title={title}>
      <Button {...rest} />
    </Tooltip>
  );
};

export default ActionButton;
