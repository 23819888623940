import React, { useCallback } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { getOpenRejectionModal } from './ducks/NotificationOfRejection.selectors';
import { closeNotificationOfRejectionModal } from './ducks/NotificationOfRejection.actions';
import { NotificationOfRejectionView } from './NotificationOfRejection.view';

const NotificationOfRejection = ({
  isOpen,
  closeNotificationOfRejectionModal,
}) => {
  const onCloseModal = useCallback(() => {
    closeNotificationOfRejectionModal();
  });

  return React.createElement(NotificationOfRejectionView, {
    isOpen,
    onCloseModal,
  });
};

NotificationOfRejection.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeNotificationOfRejectionModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isOpen: getOpenRejectionModal(state),
});

const mapDispatchToProps = {
  closeNotificationOfRejectionModal,
};

export const NotificationOfRejectionConnected = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(NotificationOfRejection);
